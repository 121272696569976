const actorSchedule = {
    createEffect: ({ effectSettings, newEffectId }) => {
        const newEffect = {
            id: newEffectId,
            type: effectSettings.type,
            question: effectSettings.question || effectSettings.label || '',
            label: effectSettings.label || effectSettings.question,
            display: effectSettings.display,
            preProductionSpecifyLimitNone: effectSettings.preProductionSpecifyLimitNone || '',
            preProductionSpecifyLimitSpecifyAdditionalServicesFeeNone: effectSettings.preProductionSpecifyLimitSpecifyAdditionalServicesFeeNone || '<span class="solida-effect actorSchedule-preProduction-specifiedLimit-output mceNonEditable">&nbsp;&bull; [ LIMITE DE DIÁRIAS DE PRÉ-PRODUÇÃO ] &bull;&nbsp;</span>',
            preProductionSpecifyLimitSpecifyAdditionalServicesFee: effectSettings.preProductionSpecifyLimitSpecifyAdditionalServicesFee || '<span class="solida-effect actorSchedule-preProduction-specifiedLimit-output mceNonEditable">&nbsp;&bull; [ LIMITE DE DIÁRIAS DE PRÉ-PRODUÇÃO ] &bull;&nbsp;</span><span class="solida-effect actorSchedule-preProduction-specifiedAdditionalServicesFee-output mceNonEditable">&nbsp;&bull; [ VALOR DA DIÁRIA EXTRA DE PRÉ-PRODUÇÃO ] &bull;&nbsp;</span>',
            principalPhotographySpecifyLimitNone: effectSettings.principalPhotographySpecifyLimitNone || '',
            principalPhotographySpecifyLimitSpecifyAdditionalServicesFeeNone: effectSettings.principalPhotographySpecifyLimitSpecifyAdditionalServicesFeeNone || '<span class="solida-effect actorSchedule-principalPhotography-specifiedLimit-output mceNonEditable">&nbsp;&bull; [ LIMITE DE DIÁRIAS DE FILMAGEM ] &bull;&nbsp;</span>',
            principalPhotographySpecifyLimitSpecifyAdditionalServicesFee: effectSettings.principalPhotographySpecifyLimitSpecifyAdditionalServicesFee || '<span class="solida-effect actorSchedule-principalPhotography-specifiedLimit-output mceNonEditable">&nbsp;&bull; [ LIMITE DE DIÁRIAS DE FILMAGEM ] &bull;&nbsp;</span><span class="solida-effect actorSchedule-principalPhotography-specifiedAdditionalServicesFee-output mceNonEditable">&nbsp;&bull; [ VALOR DA DIÁRIA EXTRA DE FILMAGEM ] &bull;&nbsp;</span>',
            postProductionSpecifyLimitNone: effectSettings.postProductionSpecifyLimitNone || '',
            postProductionSpecifyLimitSpecifyAdditionalServicesFeeNone: effectSettings.postProductionSpecifyLimitSpecifyAdditionalServicesFeeNone || '<span class="solida-effect actorSchedule-postProduction-specifiedLimit-output mceNonEditable">&nbsp;&bull; [ LIMITE DE DIÁRIAS DE PÓS-PRODUÇÃO ] &bull;&nbsp;</span>',
            postProductionSpecifyLimitSpecifyAdditionalServicesFee: effectSettings.postProductionSpecifyLimitSpecifyAdditionalServicesFee || '<span class="solida-effect actorSchedule-postProduction-specifiedLimit-output mceNonEditable">&nbsp;&bull; [ LIMITE DE DIÁRIAS DE PÓS-PRODUÇÃO ] &bull;&nbsp;</span><span class="solida-effect actorSchedule-postProduction-specifiedAdditionalServicesFee-output mceNonEditable">&nbsp;&bull; [ VALOR DA DIÁRIA EXTRA DE PÓS-PRODUÇÃO ] &bull;&nbsp;</span>',
            tbd: effectSettings.tbd || '',
            other: effectSettings.other || '<span class="solida-effect actorSchedule-other-output mceNonEditable">&nbsp;&bull; [ RESPOSTA ] &bull;&nbsp;</span>',
        };
        return { newEffect };
    },
    newEffectWindowHandleEffectTypeChange: () => {
        const data = {};
        data.question = '';
        return data;
    },
    setEffectOutput: ({ documentEditorValue, selectedEffect, selectedEffectOutput }) => {
        selectedEffect[selectedEffectOutput.optionIndex] = documentEditorValue;
        return selectedEffect;
    },
    getSelectedEffectOutput: ({ selectedEffect, selectedEffectOutput }) => {
        let newValue = selectedEffect[selectedEffectOutput.optionIndex] || '';
        return newValue;
    },
    checkValidation: (data) => {
        let questionValid = false;
        if(data){
            questionValid = true;
            const { schedule } = data;
            if(!schedule) questionValid = false;
        }
        return questionValid;
    }
};

export {
    actorSchedule
}