import { FormProvider } from '../../../Form/context/FormContext';
import Main from './Main';

const DocsForm = () => {
    return (
        <FormProvider>
            <Main />
        </FormProvider>
    );
};

export default DocsForm;