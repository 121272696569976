import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';

const PromptDialog = ({
    open, setOpen,
    title, text, label,
    onClickConfirmButton, confirmButtonDisabled,
    inputValueRef
}) => {
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if(open){
            setInputValue('');
            inputValueRef.current = '';
        }
    }, [open]);

    const handleInputChange = (newValue) => {
        setInputValue(newValue);
        inputValueRef.current = newValue;
    };

    const handleKeyPress = (e) => {
        if(e.key === 'Enter') onClickConfirmButton();
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{text}</DialogContentText>
                <TextField
                    variant="standard"
                    fullWidth
                    autoFocus
                    label={label}
                    value={inputValue}
                    onChange={(e) => handleInputChange(e.target.value)}
                    onKeyPress={handleKeyPress} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button disabled={confirmButtonDisabled} onClick={onClickConfirmButton} variant="outlined" color="primary">Salvar</Button>
            </DialogActions>
        </Dialog>
    );
};

export default PromptDialog;