import axios from 'axios';
import { ASSIGNABLE_FIELDS, ERROR_MESSAGE_UNKNOWN, INPITypes, SERVER_PATH, SPECIAL_STRINGS_ALL } from './constants';
import { sortByKey } from './filters';

const capitalizeFirstLetter = (phrase) => {
    const phraseWords = phrase.split(' ').map(phraseWord => {
        phraseWord = phraseWord.toLowerCase();
        if(/^(das?|de|dos?)$/.test(phraseWord)){
            return phraseWord;
        }
        return phraseWord.charAt(0).toUpperCase() + phraseWord.slice(1);
    });
    const newValue = phraseWords.join(' ');
    return newValue;
}

const catchRequestError = (err) => {
    let error = {
        message: ERROR_MESSAGE_UNKNOWN
    };
    if(err.response){
        error.status = err.response.status;
        error.message = err.response.data;
    }
    return error;
};

const copy = (string, toast, toastMessage) => {
    navigator.clipboard.writeText(string);
    if(toast) toast(toastMessage || 'Agora é só colar o texto onde você quiser');
};

const filterTable = (value, tableBodyId, colIndex) => {
    let filter, table, tr, td, i, txtValue;
    filter = value.toUpperCase();
    table = document.getElementById(tableBodyId);
    tr = table.getElementsByTagName('tr');
    for (i = 0; i < tr.length; i++) {
        if(colIndex){
            td = tr[i].getElementsByTagName('td')[colIndex];
            if (td) {
                txtValue = td.textContent || td.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = '';
                } else {
                    tr[i].style.display = 'none';
                }
            }
        } else {
            let show = false;
            txtValue = tr[i].textContent || tr[i].innerText;
            if(txtValue.toUpperCase().indexOf(filter) > -1) show = true;
            tr[i].style.display = (show ? '' : 'none');
        }
    }
};

const flagDocument = async (flag, flagComment, activeUser, selectedDocument, updateDocument, socket, setLoading, toast) => {
    if(setLoading) setLoading(true);

    let selectedDocumentCopy = {...selectedDocument};

    const now = new Date();
    
    let updates = {
        flag,
        flagComment,
        flaggedAt: now,
        flaggedBy: activeUser._id,
        flaggedByName: activeUser.fullName
    };

    let updatedDocument = {...selectedDocument, ...updates};
    
    const newCommentAuto = !!flag ? 'sinalizou o documento' : '';
    if(flagComment || newCommentAuto){
        const newComment = {
            createdBy: activeUser._id,
            createdByName: activeUser.fullName,
            createdAt: now,
            comment: flagComment,
            readBy: [activeUser._id],
            auto: newCommentAuto
        };
        if(!Array.isArray(updatedDocument.comments)) updatedDocument.comments = [];
        updatedDocument.comments.push(newComment);
    }

    updateDocument(updatedDocument);

    try { 
        await serverRequest({path: `/data/documents/${selectedDocument._id}/flag`, method: 'post', data: updates});
        if(!!flag && toast) toast(`${flag === 2 ? 'O Jurídico' : 'O(a) solicitante'} foi notificado.`);
        if(socket) socket.emit('DOCUMENT FLAGGED', { actionUserId: activeUser._id, actionUserName: activeUser.fullName, updatedDocument }); 
    } catch (error) {
        updateDocument(selectedDocumentCopy);
        if(toast) toast(ERROR_MESSAGE_UNKNOWN);
    }

    if(setLoading) setLoading(false);
};

const formatCurrency = (number) => {
    return new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(number);
};

const getAssignedFieldNameById = (id) => {
    let field = ASSIGNABLE_FIELDS.find(f => f.id === id);
    return field.name;
};

const getCaseLawyersByCaseId = (cases, caseId) => {
    if(cases){
        let caseData = cases.find(i => i._id === caseId);
        if(caseData){
            let lawyers = caseData.lawyer;
            try {
                lawyers = JSON.parse(lawyers);
                return lawyers;
            } catch (error) {
                return lawyers;
            }
        }
    }
    return null;
};

const getCaseLawyersNames = (users, lawyers) => {
    let names = '';
    try {
        names = lawyers.map(id => getUserNameById(users, id)); 
        names = names.join(', ');
    } catch (error) {
        names = '?';
    }
    return names;
};

const getCaseLawyersNamesByCaseId = (cases, caseId, users) => {
    let names = '?';
    let caseData = cases.find(i => i._id === caseId);
    if(caseData){
        let lawyers = caseData.lawyer;
        names = getCaseLawyersNames(users, lawyers);
    }
    return names;
};

const getChartDataFoldersTime = (timesheet, setDataCallback, projects) => {
    let folders = [];
    let counter = 0;
    timesheet.forEach(i => {
        counter += i.minutes;
        let folder = i.project;
        let folderIndex = folders.findIndex(j => j[0] === folder);
        if(folderIndex > -1){
            folders[folderIndex][1] += i.minutes;
        } else {
            folders.push([folder, i.minutes]);
        }
    });
    folders = folders.sort(function(a, b) {
        if (a[1] === b[1]) {
            return 0;
        } else {
            return (a[1] < b[1]) ? 1 : -1;
        }
    });
    folders = folders.map(i => {
        let projectIndex = projects?.findIndex(j => j._id === i[0]);
        let folderName = '';
        if(projectIndex > -1){
            folderName = projects[projectIndex].name;
        }
        return [folderName, i[1]/60];
    });
    folders.unshift(['Projeto', 'Horas']);
    setDataCallback(folders);
    return counter;
};

const getDocumentClientLink = (document, serverPath) => {
    let version = getDocumentLastVersion(document);
    if(version){
        let link = getDocumentVersionLink(version, serverPath);
        return link;
    } else {
        return null;
    }
};

const getDocumentFieldLastArrayItem = (document, field) => {
    let item;
    if(document){
        let array = document[field];
        if(Array.isArray(array) && array.length >= 1){
            item = array[array.length - 1];
        }
    }
    return item;
};

const getDocumentFolderName = (document, projects) => {
    return getProjectNameById(projects, document.project || document.client);
};

const getDocumentGoogleDocExportLink = (googleDocUrl, exportFormat) => {
    let googleId = googleDocUrl.match(/[-\w]{25,}/);
    return `https://docs.google.com/feeds/download/documents/export/Export?id=${googleId}&exportFormat=${exportFormat}`;
}

const getDocumentLastCommentIfHuman = (selectedDocument) => {
    let documentComment = null;
    let documentNoHtmlComment = '';
    const documentComments = selectedDocument.comments;
    if(documentComments){
        const lastComment = documentComments[documentComments.length - 1];
        if(lastComment?.comment){
            documentComment = lastComment;
            documentNoHtmlComment = documentComment.comment.replace(/<[^>]+>/g, '').replace(/\n/g, ' ');
            // if(documentNoHtmlComment.length > 200) documentNoHtmlComment = `${documentNoHtmlComment.substring(0, 200)}...`;
        }
    }
    return { documentComment, documentNoHtmlComment };
};

const getDocumentLastVersion = (document) => {
    let documents = document?.documents;
    let version;
    if(documents && documents !== '~empty;' && documents !== '&empty'){
        if(Array.isArray(documents)){
            if(documents.length >= 1) version = documents[documents.length - 1];
        } else {
            version = documents;
        }
    }
    return version;
};

const getDocumentVersionLink = (version, serverPath) => {
    let link = ''; 
    if(version.fileFormat){
        if(version.serverFile){
            link = `${serverPath}/${version.link}`;
        } else {
            if(version.fileFormat === 'googleDoc'){
                link = version.link || version.googleDoc;
            } else {
                link = version.link;
            }
        }
    } else {
        if(version.type === 'download' || version.type === 'link'){
            if(!serverPath) serverPath = '';
            if(version.link?.match('https://') || version.link?.match('http://')){
                link = version.link;
            } else {
                link = `${serverPath}/${version.link}`;
            }
        } else {
            link = version.googleDoc;
        }
    }
    return link;
};

const getFolderCompanyName = (projects, folder) => {
    if(folder){
        if(folder.client){
            return getProjectNameById(projects, folder.client);
        } else {
            return folder.name;
        }
    }
    return null;
};

const getFolderCustomDocumentFields = (folder) => {
    let folderCustomDocumentFields = folder.documentsCustomColumns || [];
    if(folderCustomDocumentFields.length === 0){
        folderCustomDocumentFields.push({
            id: '1',
            name: 'Anotações',
            type: 'text'
        });
        if(!folder?.eSignature) folderCustomDocumentFields.unshift({
            id: '0',
            name: 'Situação',
            type: 'select',
            options: ['Não assinado', 'Assinado', 'Outra']
        });
    }
    return folderCustomDocumentFields;
};

const getFolderGroups = (folder, folderUsers) => {
    let folderGroups = [];
    if(folder && folder.useGroups){
        folderGroups = folder.groups || [];
        // if(!folderGroups.find(group => group.id === '2' || group.id === 2)){
        //     folderGroups.push({id: 2, name: 'Administradores', users: []});
        // }
        if(!folderGroups.find(group => group.id === '1' || group.id === 1)){
            folderGroups.push({id: 1, name: 'Gerentes', users: []});
        }
        if(!folderGroups.find(group => group.id === '~all;' || group.id === '&all')){
            folderGroups.push({id: '&all', name: 'Todos', users: []});
        }
        if(!folderGroups.find(group => group.id === '~none;' || group.id === '&none')){
            folderGroups.push({id: '&none', name: 'Nenhum', users: []});
        }

        folderUsers.forEach(folderUser => {
            let userGroup = null;
            folderGroups.forEach(group => {
                if(group.users.includes(folderUser)) userGroup = group;
            });
            let group_all_index = folderGroups.findIndex(group => group.id === '~all;' || group.id === '&all');
            let group_none_index = folderGroups.findIndex(group => group.id === '~none;' || group.id === '&none');
            if(!userGroup && folderGroups.length > 4){
                folderGroups[group_none_index].users.push(folderUser);
            } else if(!userGroup && folderGroups.length <= 4){
                folderGroups[group_all_index].users.push(folderUser);
            }
        });

        return {
            folderGroups,
            userGroups: folderGroups
        };
    }
    return {
        userPosition: 2
    };
};

const getFolderTemplates = (folder, templateLists) => {
    let allowedTemplates = [];
    if(folder){
        if(folder.templates.lists?.length >= 1){
            folder.templates.lists.forEach(list => {
                list = templateLists.find(l => l._id === list);
                if(list) allowedTemplates = [...list.list];
            });
        }
        if(folder.templates.include?.length >= 1){
            folder.templates.include.forEach(t => {
                if(!allowedTemplates.includes(t)) allowedTemplates.push(t);
            });
        }
        if(folder.templates.exclude?.length >= 1){
            folder.templates.exclude.forEach(t => {
                if(allowedTemplates.includes(t)){
                    let index = allowedTemplates.findIndex(a_t => a_t == t);
                    if(index > -1) allowedTemplates = [...allowedTemplates.slice(0, index), ...allowedTemplates.slice(index + 1)];
                }
            });
        }
    }
    return allowedTemplates;
};

const getGroupNameById = (groupId, groups) => {
    if(groupId){
        if(groupId === '' || groupId === '~any;' || groupId === '&any' || groupId === '&any;' || groupId === '&none;' || groupId === '~none;' ){
            return 'Nenhum';
        } else if(groupId === 2 || groupId === '2'){
            return 'Administradores';
        } else if(groupId === 1 || groupId === '1'){
            return 'Gerentes';
        } else {
            if(groups){
                const foundGroup = groups.find(group => group.id.toString() === groupId.toString());
                if(foundGroup) return foundGroup.name;
            }
        }
    }
    return 'Nenhum';
};

const getGroupQuery = (group, method) => {
    const postData = {};
    let getData = '';
    postData.group = '';
    if(group === '~all;' || group === '&all'){ //?
        postData.group = '';
        if(method === 'get'){
            if(getData) getData += '&';
            getData += `groupId=`;
        }
    } else if(group === '~none;' || group === '&none'){ // ['', nones, anys].includes(group)
        postData.group = '~none;';
        if(method === 'get'){
            if(getData) getData += '&';
            getData += `groupId=none`;
        }
    } else {
        postData.group = group;
        if(method === 'get'){
            if(getData) getData += '&';
            getData += `groupId=${group}`;
        }
    }
    if(method === 'get'){
        return getData;
    }
    return postData;
};

const getGroupsByFolderId = (folderId, projects) => {
    if(projects){
        let folder = projects.find(p => p._id === folderId);
        if(folder && folder.useGroups){
            return folder.groups;
        }
    }
    return false;
};

const getInpiRecordNameById = (inpi, id) => {
    let name = '';
    let record = inpi.find(i => i._id == id);
    if(record){
        name = `"${record.name}"`;
        let recordType = INPITypes.find(i => i.id === record.type);
        let recordTypeName = recordType ? `${recordType.name} ` : '';
        name = recordTypeName + name;
    }
    return name;
};

const getLawsuitNameById = (id, lawsuits, projects, type = 'lawsuits') => {
    let caseName = '';
    if(id && lawsuits){
        const types = {
            lawsuitsShort(caseData){
                let result = '';
                if(caseData.caseNumber !== '') result += caseData.caseNumber +'<br />';
                result += caseData.clientName !== '' ? caseData.clientName : getProjectNameById(projects, caseData.project);
                if(caseData.position !== '') result += ' ('+caseData.position+')';
                if(caseData.versus !== '') result += ' X '+caseData.versus;
                return result;
            },
            lawsuitsShortNoBreak(caseData){
                let result = '';
                if(caseData.caseNumber !== '') result += caseData.caseNumber + ' - ';
                result += caseData.clientName !== '' ? caseData.clientName : getProjectNameById(projects, caseData.project);
                if(caseData.position !== '') result += ' ('+caseData.position+')';
                if(caseData.versus !== '') result += ' X '+caseData.versus;
                return result;
            },
            lawsuits(caseData){
                let result = '';
                if(caseData.caseNumber !== '') result += caseData.caseNumber +' - ';
                result += caseData.clientName !== '' ? caseData.clientName : getProjectNameById(projects, caseData.project);
                if(caseData.position !== '') result += ' (' + caseData.position + ')';
                if(caseData.versus !== '') result += ' X ' + caseData.versus;
                result += ' - ' + caseData.name;
                return result;
            }
        };
    
        let caseData = lawsuits.find(l => l._id === id);
        if(caseData) caseName = types[type](caseData);
    }
    return caseName;
};

const getProjectNameById = (projects, id) => {
    if(projects){
        const foundProject = projects.find(p => p._id === id);
        if(foundProject) return foundProject.name;
    }
    return '';
};

const getTemplateNameById = (id, templates, includeDescription, includeIndex) => {
    let name = '';
    if(id){
        if(id === '~empty;' || id === '~empty;' || id === '&empty'){
            name = 'Nenhum';
        } else if(id === '~special;' || id === '&special'){
            name = 'Especial';
        } else if(id === '~review;' || id === '&review'){
            name = 'Revisão';
        } else {
            if(templates){
                const templateIndex = templates.findIndex(template => template._id === id);
                if(templateIndex !== -1){
                    const template = templates[templateIndex];
                    if(template){
                        name = template.name;
                        if(includeDescription && template.description) name += ` (${template.description})`;
                        if(includeIndex) name = `${templateIndex + 1}) ${name}`;
                    } else {
                        name = id;
                    }
                }
            }
        }
    }
    return name;
};

const getUserImageById = (users, id) => {
    if(users && id){
        let user = users.find(u => u._id === id);
        if(user) return user.img;
    }
    return null;
};

const getUserNameById = (users, id, fullName = false) => {
    let name = ''; //? name = id
    if(id){
        if(id === '~empty;' || id === '&empty'){
            name = '(Não especificado)';
        } else {
            if(users){
                let user = users.find(u => u._id === id || u.username === id);
                if(user) name = user[fullName ? 'fullName' : 'screenName'];
            }
        }
    }
    return name;
};

const getUserProjects = (selectedUser, projects, activeClient, FIRST_CLIENT_ID) => {
    if(selectedUser && projects && activeClient){
        const workspaceIndex = selectedUser.clients.findIndex(userClient => userClient.id === activeClient._id);
        let workspaceFolders = workspaceIndex !== -1 ? selectedUser.clients[workspaceIndex].folders : [];
        if(workspaceFolders.length === 0 && activeClient._id === FIRST_CLIENT_ID) workspaceFolders = selectedUser.permissions || []; //?TODO
        let userFolders = projects.filter(project => {
            const foundProject = workspaceFolders.find(folder => folder.id === project._id);
            if(foundProject) return project.client;
            return false;
        });
    
        let userCompanies = projects.filter(project => {
            const foundProject = workspaceFolders.find(folder => folder.id === project._id);
            if(foundProject) return !project.client;
            return false;
        });

        return { folders: userFolders, companies: userCompanies };
    }
    return { folders: [], companies: [] };
};

const logError = (error) => {
    console.log('Logging error...');
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
    }
};

const mapFormList = (array) => {
    let currentList = [];
    array.forEach(item => {
        const cnaes = item.cnaes || [];
        const meiAllowed = item.meiAllowed || false;
        const mainItem = {
            id: item.id,
            key: item.key,
            value: item.value,
            cnaes,
            meiAllowed
        }
        if(Array.isArray(item.aliases)) mainItem.aliases = item.aliases;
        currentList.push(mainItem);
        const aliases = item.aliases;
        if(Array.isArray(aliases)){
            aliases.forEach((alias, index) => {
                if(alias){
                    alias = {
                        id: `${item.id}.${index}`,
                        key: alias,
                        value: item.value,
                        cnaes,
                        meiAllowed
                    };
                    currentList.push(alias);
                }
            })
        }
    });
    currentList = currentList.sort(sortByKey('key'));
    return currentList;
};

const minutesToStringHoursAndMinutes = (mins) => {
    mins = parseInt(mins);
    let string = '';
    let hours = Math.floor(mins / 60);
    let minutes = mins % 60;
    if(hours > 0) string = hours + 'h';
    if(minutes > 0){
        if(hours > 0) string += ' ';
        string += minutes + 'min';
    }
    if(string.length === 0) string = '0';
    return string;
};

const removeDiacritics = (string) => {
    return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

const resetCustomDocumentFieldsMapOptions = (options) => {
    let newValue = [];
    if(options){
        newValue = options.map(field => ({...field, value: SPECIAL_STRINGS_ALL}));
    }
    return newValue;
};

const scrollToBottom = (elementId, element) => {
    if(!element) element = document.getElementById(elementId);
    if(element) element.scrollTop = element.scrollHeight;
};

const scrollToTop = (elementId, element) => {
    if(!element) element = document.getElementById(elementId);
    if(element) element.scrollTop = 0;
};

const serverRequest =  ({path, method: declaredMethod, data, isMounted, cancelTokenSource}) => {
    return new Promise((resolve, reject) => {
        const config = { url: SERVER_PATH + path };
        if(data){
            config.method = 'post';
            if(declaredMethod === 'multipart/form-data'){
                config.headers = {'Content-Type': 'multipart/form-data'};
            }
        } else if(declaredMethod === 'download'){
            config.method = 'get';
            config.responseType = 'blob';
        } else if(declaredMethod === 'delete'){
            config.method = 'delete';
        } else {
            config.method = 'get';
        }
        if(data) config.data = data;
        if(cancelTokenSource) config.cancelToken = cancelTokenSource.token;
        let filename = '';
        axios(config)
        .then(res => {
            if(isMounted === false) return;
            if(declaredMethod === 'download'){
                const disposition = res.headers['content-disposition'];
                filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
                if (filename.toLowerCase().startsWith("utf-8''"))
                   filename = decodeURIComponent(filename.replace("utf-8''", ''));
                else
                   filename = filename.replace(/['"]/g, '');
            }
            return res.data;
        })
        .then(res => {
            if(declaredMethod === 'download'){
                var url = window.URL.createObjectURL(res);
                var a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a); // append the element to the dom
                a.click();
                a.remove(); // afterwards, remove the element
                return resolve(true);
            }
            resolve(res);
        })
        .catch(err => {
            if(isMounted === false) return;
            reject(err?.response?.data || err?.message || ERROR_MESSAGE_UNKNOWN);
        });
    });
};

const updateStateAddArrayItem = (item, setState) => {
    setState(prevState => [...prevState, item]);
};

const updateStateAddPositionedArrayItem = (item, positionIndex, setState) => {
    setState(prevState => [...prevState.slice(0, positionIndex), item, ...prevState.slice(positionIndex)]);
};

const updateStateChangeArrayItemWith_id = (updatedItem, setState, _idKey = '_id') => {
    setState(prevState => {
        const index = prevState.findIndex(item => item[_idKey] === updatedItem[_idKey]);
        if(index !== -1){
            return [...prevState.slice(0, index), {...updatedItem, updatedAt: new Date()}, ...prevState.slice(index + 1)];
        }
        return prevState;
    });
};

const updateStateDeleteArrayItemBy_id = (id, setState) => {
    setState(prevState => {
        const itemIndex = prevState.findIndex(item => item._id === id);
        if(itemIndex !== -1){
            return [...prevState.slice(0, itemIndex), ...prevState.slice(itemIndex + 1)];
        }
        return prevState;
    });
};

const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};

export {
    capitalizeFirstLetter,
    catchRequestError,
    copy,
    filterTable,
    flagDocument,
    formatCurrency,
    getAssignedFieldNameById,
    getCaseLawyersByCaseId,
    getCaseLawyersNames,
    getCaseLawyersNamesByCaseId,
    getChartDataFoldersTime,
    getDocumentClientLink,
    getDocumentFieldLastArrayItem,
    getDocumentFolderName,
    getDocumentGoogleDocExportLink,
    getDocumentLastCommentIfHuman,
    getDocumentLastVersion,
    getDocumentVersionLink,
    getFolderCompanyName,
    getFolderCustomDocumentFields,
    getFolderGroups,
    getFolderTemplates,
    getGroupNameById,
    getGroupQuery,
    getGroupsByFolderId,
    getInpiRecordNameById,
    getLawsuitNameById,
    getProjectNameById,
    getTemplateNameById,
    getUserImageById,
    getUserNameById,
    getUserProjects,
    logError,
    mapFormList,
    minutesToStringHoursAndMinutes,
    removeDiacritics,
    resetCustomDocumentFieldsMapOptions,
    scrollToBottom,
    scrollToTop,
    serverRequest,
    updateStateAddArrayItem,
    updateStateAddPositionedArrayItem,
    updateStateChangeArrayItemWith_id,
    updateStateDeleteArrayItemBy_id,
    validateEmail
}