import { useState } from 'react';
import FormDialog from '../../../components/FormDialog';
import { SERVER_PATH } from '../../../utils/constants';
import { useAppCtxAPI, useAppCtxLoading } from '../../../context/SystemContext';
import axios from 'axios';

const ForgotPassword = ({open, setOpen, errorMessage, setErrorMessage}) => {
    const { setLoading, toast } = useAppCtxAPI();
    const loading = useAppCtxLoading();
    const [textFieldValue, set_textFieldValue] = useState('');
    
    const handle_okClick = () => {
        setLoading(true);
        setOpen(false);
        toast('Aguarde...');
        axios.post(SERVER_PATH + '/data/retrieve-password', {email: textFieldValue.trim()})
        .then(res => {
            set_textFieldValue('');
            setLoading(false);
            toast('Enviamos instruções para o seu e-mail.');
        })
        .catch(err => {
            let errorStatus = err.response.status;
            if(errorStatus === 403){
                setErrorMessage('Não foi possível encontrar esse e-mail na nossa base de dados.');
                setOpen(true);
            } else {
                setErrorMessage('Erro no servidor. Tente novamente mais tarde.')
                setOpen(true);
            }
            setLoading(false);
        });
    };

    return (
        <FormDialog
            still
            id="retrieve-pw-dialog"
            open={open} onClose={() => setOpen(false)}
            title="Esqueceu sua senha?" text="Informe o endereço de e-mail abaixo." textFieldLabel="E-mail"
            textFieldValue={textFieldValue} textFieldOnChange={(e) => set_textFieldValue(e.target.value)}
            okButtonOnClick={handle_okClick}
            okButtonDisabled={textFieldValue === '' || loading}
            errorMessage={errorMessage}
        />
    );
}

export default ForgotPassword;