import { Box, Grid, Typography } from '@mui/material';

const SectionTitle = ({title, startIcon, mt}) => (
    <Box mt={mt || 2} mb={2}>
        <Grid container spacing={1} alignItems="center">
            {
                startIcon &&
                <Grid item>
                    {startIcon}
                </Grid>
            }
            <Grid item xs>
                <Typography variant="h4">{title}</Typography>
            </Grid>
        </Grid>
    </Box>
);

export default SectionTitle;