import React, { useState } from 'react';
import { Box, Button, Collapse, Divider, Grid } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import SideBarSectionTitle from './SideBarSectionTitle';

const SideBarSection = ({children, title, showDone, setShowDone}) => {
    const [showSection, setShowSection] = useState(true);

    const handleShowDone = (newValue) => {
        setShowDone(newValue);
    };

    return (
        <Box mb={3}>
            <SideBarSectionTitle title={title} showSection={showSection} setShowSection={setShowSection} />
            <Divider />
            <Collapse in={showSection}>
                {
                    setShowDone &&
                    <Box my={2}>
                        <Grid container spacing={1} alignItems="center" justifyContent="center">
                            <Grid item>
                                <Button
                                    variant={showDone ? 'text' : 'outlined'}
                                    color={showDone ? 'inherit' : 'primary'}
                                    onClick={() => handleShowDone(false)}
                                ><CheckBoxOutlineBlankIcon /></Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant={showDone ? 'outlined' : 'text'}
                                    color={showDone ? 'primary' : 'inherit'}
                                    onClick={() => handleShowDone(true)}
                                ><CheckBoxIcon /></Button>
                            </Grid>
                        </Grid>
                    </Box>
                }
                <Box p={1}>
                    {children}
                </Box>
            </Collapse>
        </Box>
    );
};

export default SideBarSection;