import { useEffect, useRef, useState } from 'react';
import FormQuestionText from '../../../../components/FormQuestionText';
import { useAppContext } from '../../../../context/SystemContext';
import { useForm } from '../../context/FormContext';

const TextQuestion = ({question}) => {
    const { formEffects } = useAppContext();
    const { initialForm, questionNameToCNPJ, questionsStatus, selectedSupplier, setFormValidation, setFoundCNPJs, userForm, set_userForm } = useForm();
    const [value, setValue] = useState(null);
    const [showRepresentativeError, setShowRepresentativeError] = useState(false);
    const [userAwareOfErrors, setUserAwareOfErrors] = useState(false);
    const [showConsentingSignatoryError, setShowConsentingSignatoryError] = useState(false);
    const [foundCNPJ, setFoundCNPJ] = useState(null);
    const touchedField = useRef(false);
    const [userFormQuestion, setUserFormQuestion] = useState(null);

    useEffect(() => {
        if(userFormQuestion){
            set_userForm(prevState => ({
                ...prevState,
                [question.name]: userFormQuestion
            }));
        }
    }, [userFormQuestion]);
    
    useEffect(() => {
        setUserFormQuestion(prevState => ({
            ...prevState,
            id: question.id,
            type: 'text',
            assignedField: question.assignedField,
        }));
    }, []);

    useEffect(() => {
        setValue(initialForm[question.name]);
    }, [initialForm]);

    useEffect(() => {
        if(question.corporationIdQuestion){
            setFoundCNPJ(questionNameToCNPJ[question.corporationIdQuestion] || null);
        }
    }, [questionNameToCNPJ]);

    const checkRepresentativeError = (questionLegalRepresentatives, newValue) => {
        if(questionLegalRepresentatives){
            const shouldShowRepresentativeError = question.assignedField === 4 && questionLegalRepresentatives.length >= 1 && !questionLegalRepresentatives.includes(newValue);
            setShowRepresentativeError(shouldShowRepresentativeError);
        }
    };

    const checkConsentingSignatoryError = (questionPartners, newValue) => {
        if(questionPartners){
            const shouldShowConsentingSignatoryError = question.assignedField === 'consentingSignatoryName' && questionPartners.length >= 1 && !questionPartners.includes(newValue);
            setShowConsentingSignatoryError(shouldShowConsentingSignatoryError);
        }
    };

    const handleCheckCorporationIdDetailsChange = (actionKeyValuePairs) => {
        if(foundCNPJ && question.corporationIdQuestion){
            const userFormCNPJQuestion = userForm[question.corporationIdQuestion];
            if(userFormCNPJQuestion){
                const currentCNPJ = userFormCNPJQuestion.input;
                setFoundCNPJs(prevState => {
                    if(!prevState.checkCorporationIdDetails) prevState.checkCorporationIdDetails = [];
                    const questionId = question.id;
                    const questionName = question.name;
                    if(question.assignedField === 4){
                        const foundQuestionIndex = prevState.checkCorporationIdDetails.findIndex(prevItem => prevItem.questionName === question.name && prevItem.action === 'CHECK IF CNPJ MANAGING PARTNERS INCLUDE PERSON');
                        if(foundQuestionIndex !== -1){
                            if(actionKeyValuePairs['CHECK IF CNPJ MANAGING PARTNERS INCLUDE PERSON']){
                                for(let key in actionKeyValuePairs['CHECK IF CNPJ MANAGING PARTNERS INCLUDE PERSON']){
                                    prevState.checkCorporationIdDetails[foundQuestionIndex][key] = actionKeyValuePairs['CHECK IF CNPJ MANAGING PARTNERS INCLUDE PERSON'][key];
                                }
                            }
                        } else {
                            const newItem = {
                                questionId,
                                questionName,
                                action: 'CHECK IF CNPJ MANAGING PARTNERS INCLUDE PERSON',
                                corporationIdQuestion: question.corporationIdQuestion,
                                CNPJ: currentCNPJ,
                            };
                            if(actionKeyValuePairs['CHECK IF CNPJ MANAGING PARTNERS INCLUDE PERSON']){
                                for(let key in actionKeyValuePairs['CHECK IF CNPJ MANAGING PARTNERS INCLUDE PERSON']){
                                    newItem[key] = actionKeyValuePairs['CHECK IF CNPJ MANAGING PARTNERS INCLUDE PERSON'][key];
                                }
                            }
                            prevState.checkCorporationIdDetails.push(newItem);
                        }
                    } else if(question.assignedField === 'consentingSignatoryName'){
                        const foundQuestionIndex = prevState.checkCorporationIdDetails.findIndex(prevItem => prevItem.questionName === question.name && prevItem.action === 'CHECK IF CNPJ PARTNERS INCLUDE PERSON');
                        if(foundQuestionIndex !== -1){
                            if(actionKeyValuePairs['CHECK IF CNPJ PARTNERS INCLUDE PERSON']){
                                for(let key in actionKeyValuePairs['CHECK IF CNPJ PARTNERS INCLUDE PERSON']){
                                    prevState.checkCorporationIdDetails[foundQuestionIndex][key] = actionKeyValuePairs['CHECK IF CNPJ PARTNERS INCLUDE PERSON'][key];
                                }
                            }
                        } else {
                            const newItem = {
                                questionId,
                                questionName,
                                action: 'CHECK IF CNPJ PARTNERS INCLUDE PERSON',
                                corporationIdQuestion: question.corporationIdQuestion,
                                CNPJ: currentCNPJ,
                            };
                            if(actionKeyValuePairs['CHECK IF CNPJ PARTNERS INCLUDE PERSON']){
                                for(let key in actionKeyValuePairs['CHECK IF CNPJ PARTNERS INCLUDE PERSON']){
                                    newItem[key] = actionKeyValuePairs['CHECK IF CNPJ PARTNERS INCLUDE PERSON'][key];
                                }
                            }
                            prevState.checkCorporationIdDetails.push(newItem);
                        }
                    }
                    return prevState;
                });
            }
        }
    };
    
    useEffect(() => {
        let questionPartners = [];
        let questionLegalRepresentatives = [];
        if(foundCNPJ){
            questionPartners = foundCNPJ.partnerNames || [];
            questionLegalRepresentatives = foundCNPJ.legalRepresentativeNames || [];
        }
        setUserFormQuestion(prevState => ({
            ...prevState,
            checkCorporationIdPartners: questionPartners,
            checkCorporationIdLegalRepresentatives: questionLegalRepresentatives
        }));

        handleCheckCorporationIdDetailsChange({
            'CHECK IF CNPJ MANAGING PARTNERS INCLUDE PERSON': {
                CNPJManagingPartners: questionLegalRepresentatives
            },
            'CHECK IF CNPJ PARTNERS INCLUDE PERSON': {
                CNPJPartners: questionPartners
            }
        });
        
        if(userFormQuestion){
            checkRepresentativeError(questionLegalRepresentatives, userFormQuestion.input);
            checkConsentingSignatoryError(questionPartners, userFormQuestion.input);
        }
    }, [foundCNPJ]);

    useEffect(() => {
        const userNotAwareOfRepresentativeError = showRepresentativeError && !userAwareOfErrors;
        const userNotAwareOfConsentingSignatoryError = showConsentingSignatoryError && !userAwareOfErrors;
        let checkCorporationIdCorporationIdContainsLegalRepresentative, checkCorporationIdCorporationIdContainsConsentingSignatory;
        if(question.assignedField === 4){
            checkCorporationIdCorporationIdContainsLegalRepresentative = !showRepresentativeError;
        } else if(question.assignedField === 'consentingSignatoryName'){
            checkCorporationIdCorporationIdContainsConsentingSignatory = !showConsentingSignatoryError;
        }
        setUserFormQuestion(prevState => ({
            ...prevState,
            userNotAwareOfRepresentativeError,
            userNotAwareOfConsentingSignatoryError,
            checkCorporationIdCorporationIdContainsLegalRepresentative,
            checkCorporationIdCorporationIdContainsConsentingSignatory,
        }));

        handleCheckCorporationIdDetailsChange({
            'CHECK IF CNPJ MANAGING PARTNERS INCLUDE PERSON': {
                CNPJManagingPartnersIncludePerson: checkCorporationIdCorporationIdContainsLegalRepresentative
            },
            'CHECK IF CNPJ PARTNERS INCLUDE PERSON': {
                CNPJPartnersIncludePerson: checkCorporationIdCorporationIdContainsConsentingSignatory
            }
        });

    }, [showRepresentativeError, showConsentingSignatoryError, userAwareOfErrors]);

    const checkValidation = () => {
        const questionValid = formEffects.text.checkValidation(userFormQuestion);
        setFormValidation(prevState => ({...prevState, [question.name]: !questionValid}));
    };
    
    useEffect(() => {
        if((userFormQuestion && userFormQuestion.input) || touchedField.current){
            touchedField.current = true;
            checkValidation();
        }
    }, [userFormQuestion]);

    const handleChange = (questionName, updates) => {
        let foundCorporationId = 'false';
        let retrievedFromCorporationId = false;
        if(foundCNPJ && !!question.assignedField){
            if(question.assignedField === 1){
                retrievedFromCorporationId = foundCNPJ.companyName === updates.input;
                foundCorporationId = 'active';
            } else if(question.assignedField === 3){
                retrievedFromCorporationId = foundCNPJ.fullAddress === updates.input;
                foundCorporationId = 'active';
            } else if(question.assignedField === 4){
                retrievedFromCorporationId = foundCNPJ.legalRepresentativeNames && foundCNPJ.legalRepresentativeNames.includes(updates.input);
                foundCorporationId = 'active';
            } else if(question.assignedField === 'consentingSignatoryName'){
                retrievedFromCorporationId = foundCNPJ.partnerNames && foundCNPJ.partnerNames.includes(updates.input);
                foundCorporationId = 'active';
            }
        }
        setUserFormQuestion(prevState => ({
            ...prevState,
            input: updates.input,
            retrievedFromCorporationId,
            foundCorporationId, // should set upon foundCNPJ
        }));
        if(userFormQuestion){
            checkRepresentativeError(userFormQuestion.checkCorporationIdLegalRepresentatives, updates.input);
            checkConsentingSignatoryError(userFormQuestion.checkCorporationIdPartners, updates.input);
        }

        handleCheckCorporationIdDetailsChange({
            'CHECK IF CNPJ MANAGING PARTNERS INCLUDE PERSON': {
                personName: updates.input
            },
            'CHECK IF CNPJ PARTNERS INCLUDE PERSON': {
                personName: updates.input
            }
        });
    };

    const handleUserAwareErrorsChange = (newUserAwareOfErrorsValue) => {
        setUserAwareOfErrors(newUserAwareOfErrorsValue);
    };
    
    return (
        <FormQuestionText
            CNPJStatus={questionsStatus[question.corporationIdQuestion]}
            disabled={question.corporationIdQuestion && questionsStatus[question.corporationIdQuestion] === 'retrievingData'}
            foundCNPJ={foundCNPJ}
            handleUserAwareErrorsChange={handleUserAwareErrorsChange}
            question={question}
            selectedSupplier={selectedSupplier}
            showConsentingSignatoryError={showConsentingSignatoryError} setShowConsentingSignatoryError={setShowConsentingSignatoryError}
            showRepresentativeError={showRepresentativeError} setShowRepresentativeError={setShowRepresentativeError}
            userAwareOfErrors={userAwareOfErrors}
            initialValue={value}
            onChange={handleChange}
        />
    );
}

export default TextQuestion;