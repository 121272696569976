import { createContext, useContext, useMemo, useReducer } from 'react';

const ManagementCtxAPI = createContext();
const ManagementCtxSidenavMobileOpen = createContext();

const defaultState = {
    sidenavMobileOpen: false
};

const reducer = (state, action) => {
    const { payload, type } = action;

    switch (type) {
        case 'SET STATE':
            return { ...state, [payload.key]: payload.value };
        case 'SET STATE + UPDATED AT':
            return { ...state, [payload.key]: payload.value, [payload.updatedAt]: new Date() };
        case 'UPDATE STATE':
            return { ...state, ...payload };
        default: return state;
    }
    
};

const ManagementProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, {...defaultState});

    const api = useMemo(() => {

        const setSidenavMobileOpen = (newValue) => {
            dispatch({ type: 'SET STATE', payload: { key: 'sidenavMobileOpen', value: newValue } });
        };
        
        const setState = (key, value) => {
            dispatch({ type: 'SET STATE', payload: { key, value } });
        };

        return {
            setSidenavMobileOpen,
            setState
        };
    }, []);

    return (
        <ManagementCtxAPI.Provider value={api}>
        <ManagementCtxSidenavMobileOpen.Provider value={state.sidenavMobileOpen}>
            {children}
        </ManagementCtxSidenavMobileOpen.Provider>
        </ManagementCtxAPI.Provider>
    );
};

const useManagementCtxAPI = () => useContext(ManagementCtxAPI);
const useManagementCtxSidenavMobileOpen = () => useContext(ManagementCtxSidenavMobileOpen);

export {
    ManagementProvider,
    useManagementCtxAPI,
    useManagementCtxSidenavMobileOpen
};