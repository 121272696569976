import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';
import FlagIcon from '@mui/icons-material/Flag';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import Tooltip from './Tooltip';
import moment from 'moment';

const Span = styled('span')(unstable_styleFunctionSx);

const DocumentFlag = ({activeUserIsOperator, iconClass, data}) => {
    const {
        flag,
        flaggedAt,
        flaggedBy,
        flaggedByUserName
    } = data;
    
    const StyledFlagIcon = () => (
        <FlagIcon fontSize="small" sx={{color: theme => theme.palette.secondary.main}} />
    );

    const Flag2Span = ({children}) => (
        <Span sx={{color: theme => theme.palette.secondary.main}}>{children}</Span>
    );

    if(!!flag){
        let user = flaggedBy ? `por ${flaggedByUserName}` : 'pelo solicitante';
        let currentflaggedAt = flaggedAt ? moment(flaggedAt).format('D/M/YY H:mm') : '';
        const flagDescription = flag === 2 ? (activeUserIsOperator ? `Sinalizado ${user}${currentflaggedAt ? ` em ${currentflaggedAt}` : ''}` : `Aguardando ${currentflaggedAt ? ` desde ${currentflaggedAt}` : ''}`) : (activeUserIsOperator ? `Aguardando ${currentflaggedAt ? ` desde ${currentflaggedAt}` : ''}` : `Sinalizado ${currentflaggedAt ? ` em ${currentflaggedAt}` : ''}`);
        return (
            <Tooltip text={flagDescription} enterDelay={400}>
                <Grid container alignItems="center" wrap="nowrap" style={{width: '100%'}}>
                    <Grid item style={{width: 24}}>
                        {flag === 2 ? (activeUserIsOperator ? <StyledFlagIcon /> : <HourglassFullIcon fontSize="small" className={iconClass} />) : (activeUserIsOperator ? <HourglassFullIcon fontSize="small" className={iconClass} /> : <StyledFlagIcon />)}
                    </Grid>
                    <Grid item style={{width: `calc(100% - 24px)`}}>
                        <Typography variant="body2" noWrap>{flag === 2 ? (activeUserIsOperator ? <Flag2Span>{flagDescription}</Flag2Span> : flagDescription)  : (activeUserIsOperator ? <em>{flagDescription}</em> : <Span sx={{color: theme => theme.palette.secondary.main}}>{flagDescription}</Span>)}</Typography>
                    </Grid>
                </Grid>
            </Tooltip>
        );
    }
    
    return null;
};

export default DocumentFlag;