import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import AlertDialog from '../AlertDialog';
import Input from '../Input';
import Select from '../Select';
import Switch from '../Switch';
import Window from '../Window';
import { SERVER_PATH } from '../../utils/constants';
import { useAppCtxAPI, useAppCtxActiveUser, useAppCtxLoading } from '../../context/SystemContext';
import axios from 'axios';

const ChangeSignerWindow = ({open, setOpen, data, selectedDocumentRef, updateDocument}) => {
    const { selectedSigner, subDocument, setSelectedSubDocumentParentDocument } = data;
    const { setLoading, toast } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const loading = useAppCtxLoading();
    const defaultSigner = {
        id: '',
        name: '',
        email: '',
        mobile: '',
        requireEmailToken: true,
        type: 'party',
        locked: false
    };
    const [signer, set_signer] = useState(defaultSigner);
    const [changeSignerConfirmDialogOpen, setChangeSignerConfirmDialogOpen] = useState(false);

    useEffect(() => {
        if(open){
            let currentSigner = {...defaultSigner};
            if(selectedSigner){
                currentSigner = {
                    ...currentSigner,
                    
                    ...selectedSigner
                };
            }
            set_signer(currentSigner);
        }
    }, [open]);

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };
    const handleSaveButtonClick = () => {
        setChangeSignerConfirmDialogOpen(true);
    };
    const handleSubmit = async () => {
        setChangeSignerConfirmDialogOpen(false);
        setLoading(true); 
        let postData = {
            documentId: selectedDocumentRef.current._id,
            documentName: selectedDocumentRef.current.name,
            eSignaturePlatform: subDocument.eSignaturePlatform,
            eSignaturePlatformId: subDocument.eSignaturePlatformId,
            eSignatureSigners: subDocument.eSignatureSigners,
            senderName: activeUser.fullName,
            signerId: selectedSigner.id,
            signer,
            subDocumentId: subDocument._id,
        };
        await axios.post(SERVER_PATH + '/data/documents/sign/signer/update', postData)
        .then(res => {
            let newValue = res.data;
            updateDocument(newValue);
            setSelectedSubDocumentParentDocument(newValue);
        })
        .catch(err => {
            toast(err.response.data);
        });
        setOpen(false);
        setLoading(false);
    };

    const handleWindowClose = () => {
        setOpen(false);
    };
    
    return (
        <>
            <Window open={open} onClose={handleWindowClose}
                submitButtonDisabled={loading || !signer.name || !validateEmail(signer.email.trim())}
                submitButtonStartIcon={loading ? <CircularProgress size={20} /> : <CheckIcon />}
                handleSubmitButtonClick={handleSaveButtonClick}
                closeButtonText="Cancelar"
            >
                <Box mb={2}>
                    <Grid container spacing={1} alignItems="center" justifyContent="center">
                        <Grid item>
                            <Typography variant="h2">Alterar signatário(a)</Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box mb={2}>
                    <Typography variant="body1">Você deseja alterar o nome ou o e-mail do(a) signatário(a)? Não é possível reposicionar as assinaturas.</Typography>
                </Box>
                <Box mb={2}>
                    <Grid container spacing={1} alignItems="center" justifyContent="center">
                        <Grid item xs={12} container>
                            <Grid item xs={6} sm={5} md={3}>
                                <Select
                                    disabled={signer.type === 'contratante' || loading}
                                    label="Assinar como"
                                    options={[
                                        {value: 'party', label: 'Parte'},
                                        {value: 'witness', label: 'Testemunha'},
                                        ...(signer.type === 'contratante' ? [{value: 'contratante', label: 'Parte (padrão)'}] : [])
                                    ]}
                                    value={signer.type} onChange={(e) => set_signer({...signer, type: e.target.value})}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Input
                                fullWidth
                                disabled={loading}
                                label="Nome do(a) signatário(a)"
                                value={signer.name} onChange={(e) => set_signer({...signer, name: e.target.value})}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Input
                                fullWidth
                                disabled={loading}
                                label="E-mail"
                                value={signer.email} onChange={(e) => set_signer({...signer, email: e.target.value})}
                            />
                        </Grid>
                        {
                            subDocument.eSignaturePlatform === 'zapSign' &&
                            <>
                                <Grid item xs={12}>
                                    <Switch
                                        label={`Exigir token por e-mail para assinar o documento${signer.requireEmailToken ? '' : '?'}`}
                                        noWrap
                                        checked={signer.requireEmailToken} onChange={(e) => set_signer({...signer, requireEmailToken: e.target.checked})}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Collapse in={!signer.requireEmailToken}>
                                        <FormHelperText error>Gentileza orientar o signatário a assinar o documento usando a opção "Desenhar na tela". Caso contrário, o ZapSign exigirá o token por e-mail mesmo que essa funcionalidade esteja desativada.</FormHelperText>
                                    </Collapse>
                                </Grid>
                            </>
                        }
                    </Grid>
                </Box>
            </Window>
            <AlertDialog
                open={changeSignerConfirmDialogOpen} onClose={() => setChangeSignerConfirmDialogOpen(false)}
                text={`Tem certeza? Essa ação alterará os dados de ${selectedSigner ? `${selectedSigner.name} (${selectedSigner.email})` : ''}.`}
                okButtonOnClick={handleSubmit}
            />
        </>
    );
};

export default ChangeSignerWindow;