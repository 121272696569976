import React, { useRef, useState } from 'react';
import { Box, Button, Checkbox, Radio, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import HtmlBox from '../HtmlBox';
import SectionTitle from '../SectionTitle';
import { getForm } from '../../utils/talents';

const TalentForm = ({type, talentRef, handleQuestionChange, noTitle}) => {

    const QuestionBox = ({children, question}) => (
        <Box mb={3}>
            <Box mb={1}>
                <Typography variant="h6">{question.name || question.sectionTitle}</Typography>
            </Box>
            {children}
            {
                question.description &&
                <Box mt={2}>
                    {
                        question.description.map((item, descriptionIndex) => (
                            <Box key={descriptionIndex} mb={1}>
                                <Typography variant="body2" color="error"><HtmlBox html={item.replace(/\n/g,'<br />')} /></Typography>
                            </Box>
                        ))
                    }
                </Box>
            }
        </Box>
    );

    const TalentFormTitle = ({question}) => (
        <QuestionBox question={question} questionValid />
    );
    
    const TalentFormText = ({question}) => {
        let initialState = talentRef.current[question.id] || question.defaultValue || { input: '' }
        const [textState, setTextState] = useState({...initialState, type: 'text'});
        const timeout = useRef();

        const handleChange = (newValue) => {
            newValue = {...textState, input: newValue };
            setTextState(newValue);
            
            talentRef.current = {...talentRef.current, [question.id]: newValue};

            if(handleQuestionChange){
                if(timeout.current) clearTimeout(timeout.current);
                timeout.current = setTimeout(() => {
                    handleQuestionChange(question.id, newValue);
                }, 3000);
            }
        };
        return (
            <QuestionBox question={question} questionValid>
                <TextField
                    variant="standard"
                    fullWidth
                    multiline
                    placeholder="Sua resposta"
                    value={textState.input}
                    onChange={(e) => handleChange(e.target.value)} />
            </QuestionBox>
        );
    };

    const TalentFormRadio = ({question}) => {
        let initialState = talentRef.current[question.id]?.choice ? talentRef.current[question.id] : (question.defaultValue || {});
        const [radioState, setRadioState] = useState({...initialState, type: 'radio'});
        const timeout = useRef();
        const handleRadioChange = (optionId, optionDefaultInputValue) => {
            let currentRadioState = {...radioState};
            if(!currentRadioState.input) currentRadioState.input = optionDefaultInputValue ? (optionDefaultInputValue === true ? '' : optionDefaultInputValue) : '';
            currentRadioState.choice = optionId;
            setRadioState(currentRadioState);
            talentRef.current = {...talentRef.current, [question.id]: currentRadioState};
            
            if(handleQuestionChange) handleQuestionChange(question.id, currentRadioState);
        };
        const handleInputChange = (newValue) => {
            let currentRadioState = {...radioState};
            currentRadioState.input = newValue;
            setRadioState(currentRadioState);
            talentRef.current = {...talentRef.current, [question.id]: currentRadioState};

            if(handleQuestionChange){
                if(timeout.current) clearTimeout(timeout.current);
                timeout.current = setTimeout(() => {
                    handleQuestionChange(question.id, currentRadioState);
                }, 3000);
            }
        };
        const handleInputClick = (e, optionId) => {
            if(radioState.choice === optionId){
                e.stopPropagation();
            }
        };
        return (
            <QuestionBox question={question} questionValid>
                <Grid container spacing={1} alignItems="center">
                    {
                        question.options.map(option => {
                            let defaultTextFieldValue = option.other === true ? '' : (option.other || '');
                            if(question.defaultValue?.choice === option.value){
                                defaultTextFieldValue = question.defaultValue.input;
                            }
                            let talentFormQuestion = talentRef.current[question.id];
                            if(talentFormQuestion && talentFormQuestion.choice === option.value && talentFormQuestion.input){
                                defaultTextFieldValue = talentFormQuestion.input;
                            }
                            
                            return (
                                <Grid key={option.value} item xs={12} container wrap={option.other ? 'nowrap' : 'wrap'} spacing={1} alignItems="center" style={{cursor: 'pointer'}} onClick={() => handleRadioChange(option.value, option.other)}>
                                    <Grid item xs={2} sm={1}>
                                        <Radio checked={radioState.choice === option.value} />
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="body1">{option.label}</Typography>
                                    </Grid>
                                    {
                                        option.other &&
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                variant="standard"
                                                fullWidth
                                                multiline
                                                placeholder={radioState.choice === option.value ? 'Sua resposta' : ''}
                                                defaultValue={defaultTextFieldValue}
                                                // value={radioState.input}
                                                onChange={(e) => handleInputChange(e.target.value)}
                                                onClick={(e) => handleInputClick(e, option.value)} />
                                        </Grid>
                                    }
                                </Grid>
                            );
                        })
                    }
                </Grid>
                {
                    radioState.choice &&
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Button
                                onClick={() => handleRadioChange('')}
                            >Desmarcar</Button>
                        </Grid>
                    </Grid>
                }
            </QuestionBox>
        );
    };
    
    const TalentFormCheckBox = ({question}) => {
        let initialState = talentRef.current[question.id] || question.defaultValue || {};
        const [checkBoxesState, setCheckBoxesState] = useState({...initialState, type: 'checkbox'});
        const timeout = useRef();
        const handleCheckBoxChange = (optionId, optionDefaultInputValue) => {
            optionDefaultInputValue = optionDefaultInputValue ? (optionDefaultInputValue === true ? '' : optionDefaultInputValue) : '';
            let currentCheckBoxesState = {...checkBoxesState};
            if(!currentCheckBoxesState[optionId]) currentCheckBoxesState[optionId] = { checked: false, input: optionDefaultInputValue };
            currentCheckBoxesState[optionId] = { ...currentCheckBoxesState[optionId], checked: currentCheckBoxesState[optionId]?.checked ? false : true };
            setCheckBoxesState(currentCheckBoxesState);
            talentRef.current = {...talentRef.current, [question.id]: currentCheckBoxesState};

            if(handleQuestionChange) handleQuestionChange(question.id, currentCheckBoxesState);
        };
        const handleInputChange = (optionId, optionDefaultInputValue, newValue) => {
            optionDefaultInputValue = optionDefaultInputValue ? (optionDefaultInputValue === true ? '' : optionDefaultInputValue) : '';
            let currentCheckBoxesState = {...checkBoxesState};
            if(!currentCheckBoxesState[optionId]) currentCheckBoxesState[optionId] = { checked: false, input: optionDefaultInputValue };
            currentCheckBoxesState[optionId] = { ...currentCheckBoxesState[optionId], input: newValue };
            setCheckBoxesState(currentCheckBoxesState);
            talentRef.current = {...talentRef.current, [question.id]: currentCheckBoxesState};

            if(handleQuestionChange){
                if(timeout.current) clearTimeout(timeout.current);
                timeout.current = setTimeout(() => {
                    handleQuestionChange(question.id, currentCheckBoxesState);
                }, 3000);
            }
        };
        const handleInputClick = (e, optionId) => {
            if(checkBoxesState[optionId]?.checked){
                e.stopPropagation();
            }
        };
        return (
            <QuestionBox question={question} questionValid>
                <Grid container spacing={1} alignItems="center">
                    {
                        question.options.map(option => (
                            <Grid key={option.value} item xs={12} container wrap={option.other ? 'nowrap' : 'wrap'} spacing={1} alignItems="center" style={{cursor: 'pointer'}} onClick={() => handleCheckBoxChange(option.value, option.other)}>
                                <Grid item xs={2} sm={1}>
                                    <Checkbox checked={checkBoxesState[option.value]?.checked || false} />
                                </Grid>
                                <Grid item xs>
                                    <Typography variant="body1">{option.label}</Typography>
                                </Grid>
                                {
                                    option.other &&
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="standard"
                                            fullWidth
                                            multiline
                                            placeholder={checkBoxesState[option.value]?.checked ? 'Sua resposta' : ''}
                                            defaultValue={option.other === true ? '' : option.other}
                                            value={checkBoxesState[option.value]?.input}
                                            onChange={(e) => handleInputChange(option.value, option.other, e.target.value)}
                                            onClick={(e) => handleInputClick(e, option.value)} />
                                    </Grid>
                                }
                            </Grid>
                        ))
                    }
                </Grid>
            </QuestionBox>
        );
    };
    
    const types = {
        sectionTitle: (question) => <TalentFormTitle question={question} />,
        text: (question) => <TalentFormText question={question} />,
        radio: (question) => <TalentFormRadio question={question} />,
        checkbox: (question) => <TalentFormCheckBox question={question} />,
        none: () => null
    };

    return (
        <Box>
            {
                !noTitle &&
                <SectionTitle title="Talento" />
            }
            <Grid container spacing={1}>
                {
                    getForm(type)
                    .map(question => (
                        <Grid key={question.id} item xs={12}>
                            {types[question.type](question)}
                        </Grid>
                    ))
                }
            </Grid>
        </Box>
    );
};

export default TalentForm;