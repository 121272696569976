import { useEffect, useState } from 'react';
import CommentLine from '../../../../../../../../components/Documents/CommentLine';

const PendingDocumentComment = (props) => {
    const { getDocumentLastCommentIfHuman, pendingDocument } = props;
    const [pendingDocumentComment, setPendingDocumentComment] = useState({ comment: '', noHtmlComment: '' });

    useEffect(() => {
        if(pendingDocument){
            const { documentComment, documentNoHtmlComment } = getDocumentLastCommentIfHuman(pendingDocument);
            return setPendingDocumentComment({ comment: documentComment, noHtmlComment: documentNoHtmlComment })
        }
    }, [pendingDocument]);

    return (
        <CommentLine {...pendingDocumentComment} />
    );
};

export default PendingDocumentComment;