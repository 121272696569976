import React, { useState } from 'react';
import Select from '../../../Select';

const FolderField = ({addDocumentForm, folderOptions, handleSelectedFolderChange}) => {
    const [selectedFolder, setSelectedFolder] = useState(addDocumentForm.current.folderId || '');
    const handleChange = (newValue) => {
        setSelectedFolder(newValue);
        handleSelectedFolderChange(newValue);
    };
    return (
        <Select
            label="Pasta"
            options={[{value: 'none', label: 'Nenhum'}, ...folderOptions]}
            value={selectedFolder} onChange={(e) => handleChange(e.target.value)}
        />
    );
};

export default FolderField;