import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useAppCtxAPI, useAppCtxActiveUser } from '../context/SystemContext';

const avatarClass = {
    cursor: 'pointer',
    opacity: .8,
    transition: 'opacity .4s linear',
    '&:hover': {
        opacity: 1,
    },
    zIndex: theme => theme.zIndex.modal + 1
};

function Account({children, size}){
    const navigate = useNavigate();
    const ref = useRef();
    const { logout, setLoading } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const [menuOpen, set_menuOpen] = useState(false);
    
    const handleOpen = () => {
        set_menuOpen(true);
    };
    const handleClose = () => {
        set_menuOpen(false);
    };
    let style = {};
    if(size){
        size = size * 8;
        style = {
            width: size,
            height: size,
        }
    }
    
    return (
        <>
            {
                activeUser
                ?
                <Avatar ref={ref} onClick={handleOpen} sx={avatarClass} style={style} alt={activeUser.screenName} src={activeUser && activeUser.img !== '' ? `/uploads/avatars/${activeUser.img}` : undefined} />
                :
                <Avatar ref={ref} onClick={handleOpen}  sx={avatarClass} style={style} />
            }
            <Menu
                anchorEl={ref.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                getContentAnchorEl={undefined}
                open={menuOpen}
                onClose={handleClose}
                onClick={handleClose}
            >
                {children}
                <MenuItem onClick={() => logout(navigate, setLoading)}>Sair</MenuItem>
            </Menu>
        </>
    );
}

export default Account;