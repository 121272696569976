import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import ClearIcon from '@mui/icons-material/Clear';
import FilterListIcon from '@mui/icons-material/FilterList';
import FoldersList from '../../../components/FoldersList';
import Input from '../../../components/Input';
import ViewBox from '../../../components/ViewBox';
import { ERROR_MESSAGE_UNKNOWN, SERVER_PATH } from '../../../utils/constants';
import { useAppCtxAPI, useAppCtxActiveUser } from '../../../context/SystemContext';
import { useFolderUserCtxLoadFolder, useFolderUserCtxAPI } from '../context/ClientContext';

const Home = () => {
    const { workspaceId } = useParams();
    const { handleNavigate, setSelectedFolder, toast } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const loadFolder = useFolderUserCtxLoadFolder();
    const { setProjects } = useFolderUserCtxAPI();
    const [loadingPage, setLoadingPage] = useState(true);
    const [userFolders, set_userFolders] = useState([]);
    const [filterTextValue, set_filterTextValue] = useState('');

    useEffect(() => {
        let isMounted = true;
        setSelectedFolder(null);

        const fetchOnMount = async () => {
            await axios.get(SERVER_PATH + '/data/client/overview')
            .then(res => {
                if(isMounted){
                    setProjects(res.data.projects);
                    getUserFolders(res.data.projects);
                }
            })
            .catch(err => {
                toast(ERROR_MESSAGE_UNKNOWN);
            });
            setLoadingPage(false);
        };

        if(activeUser){
            fetchOnMount();
        }
        return () => { isMounted = false };
    }, [activeUser]);

    const getUserFolders = (userProjects) => {
        set_userFolders(userProjects);
    };
    
    const handleFolderClick = (folder) => {
        setLoadingPage(true);
        loadFolder(folder.shortName || folder._id, () => {
            const path = `/${workspaceId}/pastas/${folder.shortName || folder._id}/contratos`;
            handleNavigate(path);
        });
    };

    return (
        <ViewBox isLoadingBackdropOpen={loadingPage} setLoadingBackdropOpen={setLoadingPage}>
            <Container maxWidth="xs">
                <Box mb={2}>
                    <Grid container alignItems="center" justifyContent="center">
                        <Grid item xs={12}>
                            <Input
                                disabled={loadingPage}
                                fullWidth
                                placeholder="Filtrar"
                                value={filterTextValue} onChange={(e) => set_filterTextValue(e.target.value)}
                                startIcon={<FilterListIcon />}
                                endIcon={<ClearIcon />}
                                endIconOnClick={() => set_filterTextValue('')}
                                endIconTitle="Limpar"
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Fade in={userFolders.length >= 1} timeout={300}>
                    <Box>
                        <FoldersList
                            foldersList={userFolders.filter(f => f.name.toUpperCase().includes(filterTextValue.toUpperCase()))}
                            handleFolderClick={handleFolderClick}
                        />
                    </Box>
                </Fade>
            </Container>
        </ViewBox>
    );
}

export default Home;