import { memo } from 'react';
import { Grid, Typography } from '@mui/material';
import DataListItem from './DataListItem';

// const TinyTableListItem = memo(({handleMoreOptionsShownOnMouseOverFab, head, onClick, onClickFab, row}) => (
const TinyTableListItem = ({handleMoreOptionsShownOnMouseOverFab, head, onClick, onClickFab, row}) => (
    <DataListItem
        onClick={(e) => onClick(e, row)}
        showMoreOptionsFab={!!handleMoreOptionsShownOnMouseOverFab}
        handleMoreOptionsShownOnMouseOverFab={(e) => onClickFab(e, row)}
        className={`${row.className ? ` ${row.className}` : ''}`}
    > 
        <Grid container spacing={1} alignItems="center">
            {
                row.columns.map((column, columnIndex) => (
                    <Grid key={columnIndex} item xs={12} container spacing={1} alignItems="center">
                        <Grid item>
                            <Typography variant="h6">{head[columnIndex].content}</Typography>
                        </Grid>
                        <Grid item xs>{column.content}</Grid>
                        {
                            column.endContent &&
                            <Grid item>
                                {column.endContent}
                            </Grid>
                        }
                    </Grid>
                ))
            }
        </Grid>
    </DataListItem>
)
// ), (prevProps, nextProps) => {
//     if(nextProps.row.updatedAt !== prevProps.row.updatedAt){
//         return false;
//     }
//     return true;
// });

export default TinyTableListItem;