import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const AlertDialog = ({id, cancelButtonOnClick, cancelButtonText, disableBackdropClick, disabled, okButtonOnClick, okButtonText, onClose, open, text, title = 'Tem certeza?'}) => (
    <Dialog open={open} onClose={onClose} aria-labelledby={id}>
        <DialogTitle id={id}>{title || 'Tem certeza?'}</DialogTitle>
        <DialogContent>
            <DialogContentText>{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={cancelButtonOnClick || onClose}>{cancelButtonText || 'Não'}</Button>
            <Button onClick={okButtonOnClick} color="primary" disabled={disabled} variant="outlined">{okButtonText || 'Sim'}</Button>
        </DialogActions>
    </Dialog>
);

export default AlertDialog;