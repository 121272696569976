import { useMemo, useEffect } from 'react';
import { Avatar, Chip } from '@mui/material';
import { Box, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ChangeUserView from './components/DataDrawer';
import Table2 from '../../../components/Table2/Table';
import Table2CustomTopToolbar from '../../../components/Table2/components/Table2CustomTopToolbar';
import ViewBox from '../../../components/ViewBox';
import { useAppCtxAPI, useAppCtxActiveUser } from '../../../context/SystemContext';
import { useClientCtxActiveClient } from '../../../context/ClientContext';
import { useOperatorCtxProjects, useOperatorCtxUsers } from '../context/OperatorContext';
import { useOperatorUsersCtxAPI, useOperatorCtxVisibleUsers } from '../../../context/OperatorUsersContext';
import { getUserProjects } from '../../../utils/common';
import { FIRST_CLIENT_ID } from '../../../utils/constants';
import { sortByKey } from '../../../utils/filters';

const RowChangeAction = ({row}) => {
    const { showChangeUserView } = useOperatorUsersCtxAPI();

    const handleClick = () => {
        const selectedUser = row.original;
        showChangeUserView(selectedUser);
    };

    return (
        <IconButton size="small" onClick={handleClick}>
            <EditIcon />
        </IconButton>
    )
}

function Users(){
    const { getAvailableAppClientsAction, getAvailableAppFolders } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const activeClient = useClientCtxActiveClient();
    const projects = useOperatorCtxProjects();
    const { users } = useOperatorCtxUsers();
    const { setVisibleUsers, setVisibleUsersLoading, showAddUserView } = useOperatorUsersCtxAPI();
    const { isLoadingVisibleUsers, visibleUsers } = useOperatorCtxVisibleUsers();

    useEffect(() => {
        if(activeUser?.type >= 9){
            getAvailableAppClientsAction();
            getAvailableAppFolders();
        }
    }, [activeUser]);

    useEffect(() => {
        if(users){
            const currentUsers = users.sort(sortByKey('fullName')).map(currentUser => {
                currentUser.active = !currentUser.disabled;
                return currentUser;
            });
            setVisibleUsers(currentUsers);
            setVisibleUsersLoading(false);
        }
    }, [users]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'fullName',
                header: 'Nome',
                Cell: ({ renderedCellValue, row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        {
                            row.original.img
                            ? <Avatar alt={row.original.fullName} src={`/uploads/avatars/${row.original.img}`} imgProps={{loading: 'lazy'}}>{row.original.fullName.substring(0, 1)}</Avatar>
                            : <Avatar alt={row.original.fullName}>{row.original.fullName.substring(0, 1)}</Avatar>
                        }
                        <span>{renderedCellValue}</span>
                    </Box>
                ),
                size: 270,
                grow: false
            },
            {
                accessorKey: 'email',
                enableClickToCopy: true,
                filterVariant: 'autocomplete',
                header: 'Email',
                size: 270,
                grow: false
            },
            {
                id: 'clients',
                accessorFn: (row) => {
                    const { folders, companies } = getUserProjects(row, projects, activeClient, FIRST_CLIENT_ID);
                    const folderNames = folders.map(folder => `"${folder.name}"`);
                    return folderNames.join(', ');
                },
                filterVariant: 'text',
                filterFn: 'contains',
                header: 'Pastas',
            },
            {
                accessorKey: 'active',
                filterVariant: 'checkbox',
                header: 'Situação',
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
                muiTableFooterCellProps: {
                    align: 'center',
                },
                Cell: ({ cell }) => (
                    <Chip color={cell.getValue() === true ? 'success' : 'error'} label={cell.getValue() === true ? 'Ativo' : 'Inativo'} />
                ),
                size: 180,
                grow: false
            }
        ],
        [projects, activeClient],
    );

    const renderTopToolbar = ({ table }) => {
        
        const handleAddUserButtonClick = () => {
            showAddUserView();
        };
    
        return (
            <Table2CustomTopToolbar
                buttons={[
                    <Button variant="contained" minWidth={198.64} onClick={handleAddUserButtonClick} startIcon={<AddIcon />}>Cadastrar pessoa</Button>,
                ]}
                table={table}
            />
        );
    };

    const renderRowActions = ({ row }) => (
        <Box>
            <RowChangeAction row={row} />
        </Box>
    );

    return (
        <ViewBox style={{ height: '100%' }}>

            <Table2
                columns={columns}
                data={visibleUsers || []}
                enablePagination={false}
                enableRowVirtualization
                initialStateDensity="comfortable"
                isLoading={isLoadingVisibleUsers}
                renderRowActions={renderRowActions}
                renderTopToolbar={renderTopToolbar}
            />

            <ChangeUserView />
            
        </ViewBox>
    );
}

export default Users;