import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';
import Notifications from './Notifications';
import MenuIcon from '@mui/icons-material/Menu';

const sidenavWidth = 60;
const headerHeight = 50;

const classes = {
    header: {
        zIndex: theme => theme.zIndex.drawer - 1,
        height: `${headerHeight}px`,
        maxHeight: `${headerHeight}px`,
        overflow: 'hidden',
        '&>div': {
            minHeight: `${headerHeight}px`
        },

        width: '100%',

        backgroundColor: '#fbfbfc',

        /* From https://css.glass */
        // background: 'rgba(255, 255, 255, 0.2)',
        // boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        // backdropFilter: 'blur(6px)',
        // WebkitBackdropFilter: 'blur(6px)',
        // borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
        // position: 'fixed',
        // top: 0,
        // width: ['100%', `calc(100% - ${sidenavWidth}px)`],
    },
    menuButton: {
        display: ['block', 'none'],
    }
};

const StyledHeader = styled('header')(unstable_styleFunctionSx);

function Header({leftBox, onlineUsers, account, data}){
    const { sidenavMobileOpen, set_sidenavMobileOpen } = data;
    const minRightBoxWidth = 122;

    return (
        <StyledHeader sx={classes.header}>
            <Container maxWidth="xl">
                <Box style={{display: 'flex', alignItems: 'center'}}>
                    <Box sx={classes.menuButton}>
                        <IconButton
                            edge="start"
                            onClick={() => set_sidenavMobileOpen(!sidenavMobileOpen)}
                            size="large"><MenuIcon /></IconButton>
                    </Box>
                    <Box style={{display: 'flex', alignItems: 'center', maxWidth: `calc(100% - ${minRightBoxWidth}px)`}}>
                        {leftBox || null}
                    </Box>
                    <Box style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                        <Box style={{display: 'flex', alignItems: 'center'}}>
                            <Notifications />
                        </Box>
                        <Box style={{display: 'flex', alignItems: 'center'}} sx={{ display: { xs: 'none', md: 'block' } }}>
                            {onlineUsers || null}
                        </Box>
                    </Box>
                    <Box>
                        {account || null}
                    </Box>
                </Box>
            </Container>
        </StyledHeader>
    );
}

export default Header;