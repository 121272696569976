import Box from '@mui/material/Box';
import ActionMenu from './ActionMenu';
import FloatingNotification from './FloatingNotification';
import LoadingBackdrop from './LoadingBackdrop';

const classes = {
    root: {
        padding: [`8px 8px 8px 8px`, '8px 16px 8px 16px', `16px 24px 16px 24px`],
    },
};

const ViewBox = ({
    additionalSx = [],
    children, id, style,
    isLoadingBackdropOpen, setLoadingBackdropOpen, loadingBackdropText, loadingBackdropContent, loadingBackdropBackgroundColor,
    message,
    actionMenuOpen, setActionMenuOpen, actionMenuAnchorEl, actionMenuAnchorPosition, actionMenuItems
}) => {
    
    const handleCloseLoadingBackdrop = () => {
        setLoadingBackdropOpen(false);
    };

    return (
        <Box
            id={id}
            sx={[classes.root, ...additionalSx]}
            style={style}
        >
            {
                message &&
                <FloatingNotification notification={message} />
            }
            {children}
            {
                setActionMenuOpen &&
                <ActionMenu
                    open={actionMenuOpen} setOpen={setActionMenuOpen} anchorEl={actionMenuAnchorEl} anchorPosition={actionMenuAnchorPosition}
                    actionMenuItems={actionMenuItems}
                />
            }
            <LoadingBackdrop open={isLoadingBackdropOpen} onClose={handleCloseLoadingBackdrop} text={loadingBackdropText} content={loadingBackdropContent} backgroundColor={loadingBackdropBackgroundColor} />
        </Box>
    );
};

export default ViewBox;