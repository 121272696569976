import { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { Box, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import Table2 from '../../../../components/Table2/Table';
import Table2CustomTopToolbar from '../../../../components/Table2/components/Table2CustomTopToolbar';
import ViewBox from '../../../../components/ViewBox';
import AddUserFieldWindow from './components/AddUserFieldWindow';
import { getTemplateNameById } from '../../../../utils/common';
import { SERVER_PATH } from '../../../../utils/constants';
import { useClient } from '../../../../context/ClientContext';
import { sortByKey } from '../../../../utils/filters';

const RowChangeAction = ({onRowClick, row}) => {
    
    const handleClick = () => {
        const selectedItem = row.original;
        onRowClick(selectedItem);
        // showChangeCompanyView(selectedItem);
    };

    return (
        <IconButton size="small" onClick={handleClick}>
            <EditIcon />
        </IconButton>
    )
}

const userCreatedFieldTypes = {
    'string': {name: 'Texto'},
    'boolean': {name: 'Verdadeiro ou falso'},
    'select': {name: 'Múltipla escolha'}
};

function UserCreatedFields(){
    const { userTemplateFields } = useClient();
    const [visibleItems, setVisibleItems] = useState([]);
    const [selectedField, set_selectedField] = useState(null);
    const [addUserFieldWindowOpen, set_addUserFieldWindowOpen] = useState(false);
    const [templates, setTemplates] = useState(null);
    const [templatesOptions, setTemplatesOptions] = useState([]);
    const [usedTemplatesOptions, setUsedTemplatesOptions] = useState([]);
    
    useEffect(() => {
        axios.post(SERVER_PATH + '/data/documents/templates/fields', { fields: ['name', 'description'], includeDisabled: false })
        .then(res => {
            setTemplates(res.data.templates);
        })
        .catch(err => {
            console.log(err);
        });
    }, []);

    useEffect(() => {
        if(templates){
            const currentTemplatesOptions = templates.sort((a, b) => a.name > b.name ? 1 : (a.name < b.name ? -1 : 0)).map(template => ({value: template._id, label: `${template.name}${template.description ? ` (${template.description})` : ''}`}));
            setTemplatesOptions(currentTemplatesOptions);
        }
    }, [templates]);

    useEffect(() => {
        if(userTemplateFields){
            const currentVisibleItems = userTemplateFields.sort(sortByKey('name'));
            setVisibleItems(currentVisibleItems);
        };
    }, [userTemplateFields]);
    
    const addField = () => {
        set_selectedField(null);
        set_addUserFieldWindowOpen(true);
    };
    const handleFieldSelect = (field) => {
        set_selectedField(field);
        set_addUserFieldWindowOpen(true);
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: 'name',
                filterVariant: 'text',
                header: 'Nome',
            },
            {
                id: 'userCreatedFieldType',
                accessorFn: (row) => userCreatedFieldTypes[row.type]?.name || '',
                filterVariant: 'text',
                header: 'Tipo',
            },
            {
                header: 'Matrizes',
                id: 'templates', 
                accessorFn: (row) => {
                    const templateIds = row.template;
                    let templateNames = '';
                    if(templateIds && templates){
                        if(Array.isArray(templateIds)){
                            let templateNamesArray = [];
                            templateIds.forEach(templateId => {
                                let templateName = getTemplateNameById(templateId, templates, true);
                                if(templateName) templateNamesArray.push(templateName);
                            });
                            templateNames = templateNamesArray.join('; ');
                        } else {
                            templateNames = getTemplateNameById(templateIds, templates, true);
                        }
                    }
                    return templateNames;
                },
                filterVariant: 'text',
                filterFn: 'contains',
            },
        ],
        [templates],
    );

    const renderTopToolbar = ({ table }) => {
        
        const handleAddItemButtonClick = () => {
            addField();
        };
    
        return (
            <Table2CustomTopToolbar
                buttons={[
                    <Button variant="contained" minWidth={264.41} onClick={handleAddItemButtonClick} startIcon={<AddIcon />}>Nova informação especial</Button>,
                ]}
                table={table}
            />
        );
    };

    
    const renderRowActions = ({ row }) => (
        <Box>
            <RowChangeAction row={row} onRowClick={handleFieldSelect} />
        </Box>
    );
    
    return (
        <ViewBox additionalSx={[{ height: '100%' }]}>

            <Table2
                columns={columns}
                data={visibleItems}
                enablePagination={false}
                enableRowVirtualization
                initialStateColumnPinningLeft={['name']}
                initialStateDensity="comfortable"
                isLoading={!templates}
                renderRowActions={renderRowActions}
                renderTopToolbar={renderTopToolbar}
            />

            <AddUserFieldWindow
                open={addUserFieldWindowOpen} setOpen={set_addUserFieldWindowOpen} selectedField={selectedField}
                data={{
                    templatesOptions
                }}
            />

        </ViewBox>
    );
}

export default UserCreatedFields;