import { useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Autocomplete from '../Autocomplete';
import Input from '../Input';
import Select from '../Select';
import Switch from '../Switch';
import { CLEARANCE_RISK_OPTIONS } from '../../utils/constants';
import { sortByKey } from '../../utils/filters';

const ClearanceWindowRiskFields = ({open, selectedProjectTemplate, clearanceItem, selectedReport, handleFieldChange}) => {
    const [useTypeOptions, setUseTypeOptions] = useState([]);
    const descriptionRef = useRef(null);
    const [useTypeComments, setUseTypeComments] = useState('');

    useEffect(() => {
        let newUseTypeComments = '';
        if(clearanceItem?.rightType){
            const itemType = selectedProjectTemplate.rightTypes.find(rightType => rightType._id === clearanceItem.rightType);
            newUseTypeComments = itemType?.comments || '';
            const riskLevels = itemType.riskLevels;
            let options = [];
            for(const riskLevelKey in riskLevels){
                const riskLevel = riskLevels[riskLevelKey];
                const riskOption = CLEARANCE_RISK_OPTIONS.find(riskOption => riskOption.value === riskLevelKey);
                options = [
                    ...options,
                    ...riskLevel.map(item => ({
                        value: item._id, label: `Risco ${riskOption.label} - ${item.name}`, riskLevel: riskLevelKey,
                        guidelines: item.guidelines || '',
                        comments: item.comments || ''
                    })).sort(sortByKey('label'))
                ];
            }
            setUseTypeOptions(options);
        }
        setUseTypeComments(newUseTypeComments);
    }, [clearanceItem]);

    useEffect(() => {
        if(open){
            if(descriptionRef.current){
                descriptionRef.current.focus();
                descriptionRef.current.selectionStart = descriptionRef.current.selectionEnd = 10000;
            }
        }
    }, [open]);

    const handleRightTypeSelectChange = (newValue) => {
        handleFieldChange('rightType', newValue);
        handleFieldChange('useType', '');
        handleFieldChange('riskLevel', '');
    };
    
    const handleUseTypeSelectChange = (newValue) => {
        handleFieldChange('useType', newValue);
        const useType = useTypeOptions.find(option => option.value === newValue);
        handleFieldChange('riskLevel', useType.riskLevel);
        if(!clearanceItem.riskDescription){
            let newRiskDescription = useType.label;
            const rightType = selectedProjectTemplate.rightTypes.find(rightType => rightType._id === clearanceItem.rightType);
            if(rightType && rightType.guidelines) newRiskDescription = rightType.guidelines;
            if(useType.guidelines){
                if(rightType && rightType.guidelines){
                    newRiskDescription += `\n\n${useType.guidelines}`;
                } else {
                    newRiskDescription = useType.guidelines;
                }
            }
            handleFieldChange('riskDescription',  newRiskDescription);
        }
    };

    const handleRequiresAdditionalInformationChange = (newValue) => {
        handleFieldChange('requiresAdditionalInformation', newValue);
        if(!!newValue) handleFieldChange('riskLevel', 'three');
    };

    const handleLicensedChange = (newValue) => {
        handleFieldChange('licensed', newValue);
        if(!!newValue) handleFieldChange('riskLevel', 'one');
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} container spacing={1}>
                <Grid item xs={12} sm={2}>
                    {
                        selectedReport.type === 'script'
                        ?
                        <Input
                            fullWidth
                            type="number"
                            placeholder="Número"
                            inputProps={{min: 1, step: 1}}
                            label="Cena"
                            value={clearanceItem.scriptScene} onChange={(e) => handleFieldChange('scriptScene', e.target.value)}
                        />
                        :
                        <TextField
                            variant="standard"
                            fullWidth
                            label="Minuto"
                            value={clearanceItem.cutTimeCode}
                            onChange={(e) => handleFieldChange('cutTimeCode', e.target.value)} />
                    }
                </Grid>
                <Grid item xs>
                    <TextField
                        variant="standard"
                        inputRef={descriptionRef}
                        fullWidth
                        multiline
                        label="Descrição"
                        placeholder="Descreva o direito"
                        value={clearanceItem.description}
                        onChange={(e) => handleFieldChange('description', e.target.value)}
                        InputLabelProps={{ shrink: true }} />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Switch
                    label={`Aguardando informações adicionais da produção para concluir a orientação${clearanceItem.requiresAdditionalInformation ? '' : '?'}`}
                    variant="body1"
                    noWrap
                    checked={clearanceItem.requiresAdditionalInformation}
                    onChange={(e) => handleRequiresAdditionalInformationChange(e.target.checked)}
                />
            </Grid>
            <Grid item xs={12}>
                <Switch
                    label={`A produção informou que este direito foi licenciado${clearanceItem.licensed ? '' : '?'}`}
                    variant="body1"
                    noWrap
                    checked={clearanceItem.licensed}
                    onChange={(e) => handleLicensedChange(e.target.checked)}
                />
            </Grid>
            {
                clearanceItem.licensed &&
                <Grid item xs={12}>
                    <TextField
                        variant="standard"
                        fullWidth
                        multiline
                        label="Link da licença assinada"
                        placeholder="Indique o link para acesso à licença assinada"
                        value={clearanceItem.licenseLink}
                        onChange={(e) => handleFieldChange('licenseLink', e.target.value)}
                        InputLabelProps={{ shrink: true }} />
                </Grid>
            }
            <Grid item xs={12} sm={5}>
                <Autocomplete
                    label="Direito envolvido"
                    options={selectedProjectTemplate.rightTypes.sort(sortByKey('name')).map(rightType => rightType._id)}
                    getOptionLabel={(option) => selectedProjectTemplate.rightTypes.find(rightType => rightType._id === option)?.name}
                    value={clearanceItem.rightType} onChange={(e, newValue) => handleRightTypeSelectChange(newValue)}
                />
            </Grid>
            {
                (clearanceItem.rightType) &&
                <Grid item xs={12} sm={5}>
                    <Select
                        required={!clearanceItem.requiresAdditionalInformation}
                        optionsStyle={{fontSize: 14}} multiline
                        label="Condição de uso"
                        options={useTypeOptions}
                        value={clearanceItem.useType} onChange={(e) => handleUseTypeSelectChange(e.target.value)}
                    />
                </Grid>
            }
            {
                (clearanceItem.useType) &&
                <Grid item xs={12} sm={2}>
                    <Select
                        required={!clearanceItem.requiresAdditionalInformation}
                        label="Nível de risco"
                        options={CLEARANCE_RISK_OPTIONS.map(option => ({...option, label: <span><span style={{color: option.color}}>&#11044;</span>&nbsp;{option.label}</span>}))}
                        value={clearanceItem.riskLevel} onChange={(e) => handleFieldChange('riskLevel', e.target.value)}
                    />
                </Grid>
            }
            {
                useTypeComments &&
                <Grid item xs={12}>
                    <Typography variant="body2">Atenção: {useTypeComments}</Typography>
                </Grid>
            }
            <Grid item xs={12}>
                <TextField
                    variant="standard"
                    required={!clearanceItem.requiresAdditionalInformation}
                    fullWidth
                    multiline
                    label="Justificativa do risco"
                    placeholder="Detalhe a escolha do nível de risco"
                    value={clearanceItem.riskDescription}
                    onChange={(e) => handleFieldChange('riskDescription', e.target.value)}
                    InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    variant="standard"
                    fullWidth
                    multiline
                    label="Orientação"
                    placeholder="Apresente uma recomendação ou outras orientações jurídicas"
                    value={clearanceItem.legalRecommendation}
                    onChange={(e) => handleFieldChange('legalRecommendation', e.target.value)}
                    InputLabelProps={{ shrink: true }} />
            </Grid>
        </Grid>
    );
};

export default ClearanceWindowRiskFields;