import React, { useEffect, useMemo, useState } from 'react';
import { Link, ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Table2 from '../../../../components/Table2/Table';
import Table2CustomTopToolbar from '../../../../components/Table2/components/Table2CustomTopToolbar';
import ViewBox from '../../../../components/ViewBox';
import { getDocumentClientLink, getProjectNameById, getUserNameById, updateStateChangeArrayItemWith_id } from '../../../../utils/common';
import { SERVER_PATH } from '../../../../utils/constants';
import { useAppCtxAPI, useAppCtxActiveUser } from '../../../../context/SystemContext';
import { useOperatorCtxProjects, useOperatorCtxUsers } from '../../context/OperatorContext';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

function DocsNotifications(){
    const { deleteForever, setLoading, toast, updateOneOperators } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const projects = useOperatorCtxProjects();
    const { users } = useOperatorCtxUsers();
    const [alerts, set_alerts] = useState([]);
    const [visibleAlerts, setVisibleAlerts] = useState([]);
    const [loadingPage, setLoadingPage] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            await axios.get(SERVER_PATH + '/data/operator/docs/alerts')
            .then(res => {
                const currentAlerts = res.data.alerts.map(listAlert => {
                    listAlert.createdByName = getUserNameById(users, listAlert.user, true);
                    listAlert.document = res.data.docs.find(listDocument => listDocument._id === listAlert.documentId);
                    if(listAlert.document){
                        listAlert.documentName = listAlert.document.name;
                        listAlert.companyName = getProjectNameById(projects, listAlert.document.client);
                        listAlert.folderName = listAlert.document.project ? getProjectNameById(projects, listAlert.document.project) : '';
                    }
                    return listAlert;
                });
                set_alerts(currentAlerts);
            })
            .catch(err => {
                toast(err.response.data);
            });
            setLoadingPage(false);
        };
        fetchData();
    }, []);

    useEffect(() => {
        const currentVisibleAlerts = alerts.filter(alert => !alert.deleted).sort((a, b) => a.alert > b.alert ? 1 : (a.alert < b.alert ? -1 : 0));
        setVisibleAlerts(currentVisibleAlerts);
    }, [alerts]);

    const deleteForeverCallback = (callbackData) => {
        let { documentId } = callbackData;
        const alertIndex = alerts.findIndex(alert => alert._id === documentId);
        set_alerts(prevState => [...prevState.slice(0, alertIndex), ...prevState.slice(alertIndex + 1)]);
        setLoading(false);
        toast('Alerta excluído permanentemente.');
    };

    const deleteAlertForever = async (collection, documentId, callback) => {
        if(window.confirm('Tem certeza? Essa ação é irreversível.')){
            setLoading(true);
            try {
                await deleteForever(collection, documentId);
                if(callback) callback({documentId});
            } catch (error) {
                toast('Erro: Não foi possível excluir os dados.');
            }
        }
    };

    const deleteAlert = async (alertId) => {
        try {
            setLoading(true);
            let updates = {
                deleted: true,
                deletedOn: new Date(),
                deletedBy: activeUser._id
            };
            let newValue = await updateOneOperators('Alerts', alertId, updates);
            updateStateChangeArrayItemWith_id(newValue, set_alerts);
            toast('Alerta deletado.');
        } catch (error) {
            toast('Erro: Não foi possível deletar o alerta.');
        }
        setLoading(false);
    };

    const columns = useMemo(
        () => [
            {
                id: 'createdAt', 
                accessorFn: (row) => row.timestamp ? new Date(row.timestamp) : null, //convert to Date for sorting and filtering
                filterVariant: 'date',
                filterFn: 'greaterThan',
                sortingFn: 'datetime',
                Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(), //render Date as a string
                header: 'Criado em'
            },
            {
                accessorKey: 'createdByName',
                filterVariant: 'text',
                header: 'Criado por',
            },
            {
                id: 'folder',
                accessorFn: (row) => row.folderName || row.companyName,
                filterVariant: 'text',
                header: 'Pasta',
            },
            {
                accessorKey: 'documentName',
                Cell: ({ cell, row }) => (
                    <Link
                        href={getDocumentClientLink(row.original.document, SERVER_PATH)}
                        target="_blank"
                        underline="hover"
                    >{cell.getValue()}</Link>
                ),
                filterVariant: 'text',
                header: 'Contrato',
            },
            {
                accessorKey: 'description',
                filterVariant: 'text',
                header: 'Descrição',
            },
            {
                id: 'alertAt', 
                accessorFn: (row) => row.alert ? new Date(row.alert) : null, //convert to Date for sorting and filtering
                filterVariant: 'date',
                filterFn: 'greaterThan',
                sortingFn: 'datetime',
                Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(), //render Date as a string
                header: 'Alerta em',
                grow: false,
                size: 240,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
            },
            {
                id: 'status',
                accessorFn: (row) => {
                    const status = !!row.sent;
                    return status;
                },
                Cell: ({ row }) => {
                    return (
                        <Grid container spacing={1} alignItems="center" justifyContent="center" wrap="nowrap">
                            <Grid item>
                                <FontAwesomeIcon icon={faCircle}
                                    color={!row.original.sent ? 'orange' : (!row.original.deleted ? 'green' : 'red')}
                                />
                            </Grid>
                            <Grid item xs>
                                {
                                    !row.original.sent
                                    ? <Typography variant="body2">Pronto</Typography>
                                    :
                                    (
                                        !row.original.deleted
                                        ?
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item>
                                                    <NotificationsIcon />
                                                </Grid>
                                                <Grid item xs>
                                                    <Typography variant="body2">Enviado</Typography>
                                                </Grid>
                                            </Grid>
                                        :
                                        <Typography variant="body2">Cancelado</Typography>
                                    )
                                }
                            </Grid>
                        </Grid>
                    );
                },
                filterVariant: 'text',
                header: 'Situação',
            },
        ],
        [],
    );

    const renderTopToolbar = ({ table }) => {
        return (
            <Table2CustomTopToolbar
                table={table}
            />
        );
    };

    const renderRowActionMenuItems = ({ closeMenu, row }) => {
        const selectedAlert = row.original;

        const handleDeleteAlertClick = () => {
            deleteAlert(selectedAlert._id);
            closeMenu();
        };

        const handleDeleteAlertForeverClick = () => {
            deleteAlertForever('alerts', selectedAlert._id, deleteForeverCallback);
            closeMenu();
        };

        return [
            <MenuItem
                key={0}
                onClick={handleDeleteAlertClick}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <ClearIcon />
                </ListItemIcon>
                <ListItemText primary="Alterar configurações gerais" />
            </MenuItem>,
            activeUser.type >= 9 &&
            <MenuItem
                key={1}
                onClick={handleDeleteAlertForeverClick}
            >
                <ListItemIcon><DeleteForeverIcon /></ListItemIcon>
                <ListItemText primary="Excluir" />
            </MenuItem>
        ]
    };

    return (
        <ViewBox additionalSx={[{ height: '100%' }]}>

            <Table2
                columns={columns}
                data={visibleAlerts}
                enablePagination={false}
                enableRowVirtualization
                initialStateDensity="comfortable"
                isLoading={loadingPage}
                renderRowActionMenuItems={renderRowActionMenuItems}
                renderTopToolbar={renderTopToolbar}
            />
            
        </ViewBox>
    );
}

export default DocsNotifications;