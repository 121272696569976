import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

const classes = {
    label: {
        color: 'rgba(0, 0, 0, 0.7)',
        transition: 'color .2s'
    },
    checkedLabel: {
        color: 'rgba(0, 0, 0, 1)'
    }
};

const StyledSwitch = ({checked, color, disabled, label, labelPosition = 'right', name, noWrap, onChange, required, variant = 'body2'}) => {

    const StyledLabel = () => (
        <Grid item style={{cursor: 'pointer'}}>
            <Typography variant={variant} sx={[classes.label, checked && classes.checkedLabel]}>{label}</Typography>
        </Grid>
    )

    return (
        <FormGroup row>
            <Grid component="label" container spacing={1} alignItems="center" wrap={noWrap ? 'nowrap' : 'wrap'}>
                {
                    (label && labelPosition === 'left') &&
                    <StyledLabel />
                }
                <Grid item>
                    <Switch
                        disabled={disabled}
                        required={required}
                        checked={checked}
                        onChange={onChange}
                        name={name || 'switch'}
                        color={color || 'primary'}
                    />
                </Grid>
                {
                    (label && labelPosition === 'right') &&
                    <StyledLabel />
                }
            </Grid>
        </FormGroup>
    );
}

export default StyledSwitch;