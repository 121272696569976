import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, TextField } from '@mui/material';
import SectionTitle from '../../../../../components/SectionTitle';
import Window from '../../../../../components/Window';
import { useAppCtxAPI } from '../../../../../context/SystemContext';
import { updateStateAddArrayItem } from '../../../../../utils/common';
import { ERROR_MESSAGE_UNKNOWN, ERROR_MESSAGE_MANDATORY_FIELD_EMPTY, SERVER_PATH } from '../../../../../utils/constants';

const NewClientWindow = ({open, setOpen}) => {
    const { setAvailableClients, toast } = useAppCtxAPI();
    const [loading, setLoading] = useState(false);

    const defaultNewClient = {
        name: '',
        screenName: '',
        shortName: ''
    };
    const [newClient, setNewClient] = useState(defaultNewClient);

    useEffect(() => {
        if(open){
            clearForm();
        }
    }, [open]);

    const handleSubmit = async () => {
        if(!newClient.name || !newClient.screenName || !newClient.shortName){
            toast(ERROR_MESSAGE_MANDATORY_FIELD_EMPTY);
        } else {
            setLoading(true);
            handleClose();
            
            const postData = {
                newClient
            };

            await axios.post(SERVER_PATH + '/data/system/clients/create', postData)
            .then(res => {
                const createdClient = res.data.createdClient;
                updateStateAddArrayItem(createdClient, setAvailableClients);
            })
            .catch(err => {
                console.log(err);
                toast(err?.response?.data || ERROR_MESSAGE_UNKNOWN);
                setOpen(true);
            });
            setLoading(false);
        }
    }
    
    const clearForm = () => {
        setNewClient(defaultNewClient);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Window
            open={open} onClose={handleClose}
            title="Novo cliente"
            handleSubmitButtonClick={handleSubmit}
            submitButtonDisabled={loading}
        >
            <SectionTitle title="Geral" />
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                    <TextField
                        variant="standard"
                        label="Nome do cliente"
                        fullWidth
                        required
                        value={newClient.name}
                        onChange={(e) => setNewClient(prevState => ({...prevState, name: e.target.value}))} />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        variant="standard"
                        label="Nome de exibição"
                        fullWidth
                        required
                        value={newClient.screenName}
                        onChange={(e) => setNewClient(prevState => ({...prevState, screenName: e.target.value}))} />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        variant="standard"
                        label="URL amigável"
                        fullWidth
                        required
                        value={newClient.shortName}
                        onChange={(e) => setNewClient(prevState => ({...prevState, shortName: e.target.value}))} />
                </Grid>
            </Grid>
        </Window>
    );
}

export default NewClientWindow;