import { useEffect, useState } from 'react';

const useWindowSize = () => {
    const [windowWidth, setWindowWidth] = useState(null);

    useEffect(() => {
        const getWindowWidth = () => {
            setWindowWidth(window.innerWidth);
        };
        getWindowWidth();
        window.addEventListener('resize', getWindowWidth);
        return () => window.removeEventListener('resize', getWindowWidth);
    }, []);

    return { windowWidth };
};

export default useWindowSize;