import { useState } from 'react';
import FormDialog from '../../../../../../components/FormDialog';
import { useOperatorTemplatesCtxAPI } from '../../context/TemplatesContext';
import { useFormEditor } from '../FormEditorContext';

const UpdateTitleDialog = () => {
    const { updateFormEditorQuestion } = useOperatorTemplatesCtxAPI();
    const { clickedQuestion, updateTitleDialogOpen, set_updateTitleDialogOpen } = useFormEditor();
    const [textFieldValue, set_textFieldValue] = useState('');

    const handle_updateTitleDialogOkClick = () => {
        set_updateTitleDialogOpen(false);
        const newValue = {...clickedQuestion, name: textFieldValue};
        updateFormEditorQuestion(newValue);
    };
    
    return (
        <FormDialog
            id="update-title-dialog"
            open={updateTitleDialogOpen} onEnter={() => set_textFieldValue(clickedQuestion.name)} onClose={() => set_updateTitleDialogOpen(false)}
            title="Alterar título" text="Alterar esse título?" textFieldLabel="Título"
            textFieldValue={textFieldValue} textFieldOnChange={(e) => set_textFieldValue(e.target.value)}
            okButtonOnClick={handle_updateTitleDialogOkClick}
            okButtonDisabled={textFieldValue === ''}
        />
    );
}

export default UpdateTitleDialog;