import { useEffect, useState } from 'react';
import { Fab, Zoom } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import LoaderEllipsis from '../../../components/LoaderEllipsis';
import ViewBox from '../../../components/ViewBox';
import UsersGrid from '../Tasks/UsersGrid';
import { useOperatorCtxProjects } from '../context/OperatorContext';
import { useTasksCtxAPI, useTasksCtxActiveUserTasks } from '../../../context/TasksContext';

const classes = {
    fab: {
        position: 'absolute',
        bottom: '16px',
        right: '16px',
    },
};

function TasksTeam(){
    const projects = useOperatorCtxProjects();
    const { handleAddTaskButtonPress } = useTasksCtxAPI();
    const activeUserTasks = useTasksCtxActiveUserTasks()
    const [loading, setLoading] = useState(true);
    const [projectOptions, set_projectOptions] = useState([]);

    useEffect(() => {
        if(activeUserTasks){
            setLoading(false);
        }
    }, [activeUserTasks]);

    useEffect(() => {
        if(projects && projects.length >= 1){
            let currentProjectOptions = projects.map(project => {
                let projectName = project.name;
                if(project.client){
                    let projectClient = projects.find(client => client._id == project.client);
                    if(projectClient) projectName = `${projectClient.name} - ${projectName}`;
                }
                project = {...project, name: projectName};
                return project;
            });
            let clientsAllProjects = projects.filter(project => !project.client).map(client => ({...client, _id: `all_${client._id}`, name: `${client.name} - Todos`}));
            set_projectOptions([...currentProjectOptions, ...clientsAllProjects]);
        }
    }, [projects]);

    return (
        <ViewBox style={{height: '100%', paddingTop: 8, paddingBottom: 0}}>
            {
                loading
                ? <LoaderEllipsis primary />
                :
                <UsersGrid
                    layout="team"
                    projectOptions={projectOptions} setProjectOptions={set_projectOptions}
                />
            }
            
            <Zoom in>
                <Fab
                    variant="circular" color="primary"
                    sx={classes.fab}
                    onClick={handleAddTaskButtonPress}
                >
                    <AddIcon />
                </Fab>
            </Zoom>
            
        </ViewBox>
    );
}

export default TasksTeam;