import { useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Profile from '../../../components/Profile';
import ChangeUserBooleanField from '../../../components/ChangeUserBooleanField';
import LoaderEllipsis from '../../../components/LoaderEllipsis';
import { SERVER_PATH } from '../../../utils/constants';
import { useAppCtxActiveUser } from '../../../context/SystemContext';

const ClientProfile = () => {
    const activeUser = useAppCtxActiveUser();
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if(activeUser?._id){
            const fetchData = async () => {
                await axios.post(SERVER_PATH + '/data/users/one', {
                    userId: activeUser._id,
                    fields: ['documentsSignaturesStatusExpanded', 'emailsDocumentReadyDisable', 'emailsDocumentReadyOnlyUserRequests', 'emailsDocumentSigned', 'emailsDocumentFullyExecutedDisable']
                })
                .then(res => {
                    setCurrentUser({
                        _id: res.data._id,
                        documentsSignaturesStatusExpanded: !!res.data.documentsSignaturesStatusExpanded,
                        emailsDocumentReadyDisable: !!res.data.emailsDocumentReadyDisable,
                        emailsDocumentReadyOnlyUserRequests: !!res.data.emailsDocumentReadyOnlyUserRequests,
                        emailsDocumentSigned: !!res.data.emailsDocumentSigned,
                        emailsDocumentFullyExecutedDisable: !!res.data.emailsDocumentFullyExecutedDisable,
                    });
                })
                .catch(err => {});
                setLoading(false);
            };
            return fetchData();
        }
        setCurrentUser(null);
    }, [activeUser]);

    return (
        <Profile>
            <Box my={2}>
                <Box mb={2}>
                    <Typography variant="h5">Opções</Typography>
                </Box>
                {
                    loading
                    ? <LoaderEllipsis primary />
                    :
                    <Box>
                        <ChangeUserBooleanField currentUser={currentUser} setCurrentUser={setCurrentUser} fieldKey="emailsDocumentReadyDisable" />
                        <ChangeUserBooleanField currentUser={currentUser} setCurrentUser={setCurrentUser} fieldKey="emailsDocumentReadyOnlyUserRequests" />
                        <ChangeUserBooleanField currentUser={currentUser} setCurrentUser={setCurrentUser} fieldKey="emailsDocumentSigned" />
                        <ChangeUserBooleanField currentUser={currentUser} setCurrentUser={setCurrentUser} fieldKey="emailsDocumentFullyExecutedDisable" />
                        <ChangeUserBooleanField currentUser={currentUser} setCurrentUser={setCurrentUser} fieldKey="documentsSignaturesStatusExpanded" />

                        <ChangeUserBooleanField currentUser={currentUser} setCurrentUser={setCurrentUser} fieldKey="disableClearanceEmails" />
                        <ChangeUserBooleanField currentUser={currentUser} setCurrentUser={setCurrentUser} fieldKey="disableClearanceEmailsExceptIfCreatedByUser" />
                    </Box>
                }
            </Box>
        </Profile>
    );
}

export default ClientProfile;