import AlertDialog from '../../../../../../components/AlertDialog';
import { useSocket } from '../../../../../../context/SocketContext';
import { useDocumentsAPI, useDocumentsCtxCancelScheduledSendDocumentView } from '../../../../../../context/DocumentsContext';
import { serverRequest } from '../../../../../../utils/common';

const CancelScheduledSendDialog = () => {
    const { createSocketConnection } = useSocket();
    const socket = createSocketConnection();
    const { hideCancelScheduledSendDocumentView, updatePendingDocument } = useDocumentsAPI();
    const { cancelScheduledSendDocumentViewOpen, cancelScheduledSendDocumentViewSelectedDocument: selectedDocument } = useDocumentsCtxCancelScheduledSendDocumentView();

    const handleCancelScheduledSendConfirm = async () => {
        handleClose();
        try {
            const data = await serverRequest({ path: `/data/documents/one/${selectedDocument._id}/cancel-scheduled-send`, method: 'post' });
            const updatedDocument = data.updatedDocument;
            updatePendingDocument(updatedDocument);
            socket.emit('DOCUMENT SCHEDULED SEND CANCELLED', { updatedDocument });
        } catch (error) {
        }
    };

    const handleClose = () => {
        hideCancelScheduledSendDocumentView();
    };

    return (
        <AlertDialog
            open={cancelScheduledSendDocumentViewOpen} onClose={handleClose}
            title="Tem certeza?"
            text={`Quer cancelar o envio programado do documento "${selectedDocument?.name}"?`}
            okButtonOnClick={handleCancelScheduledSendConfirm}
        />
    )
};

export default CancelScheduledSendDialog;