import { useEffect, useState } from 'react';
import { Box, Button, Fab, Grid, IconButton, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LabelIcon from '@mui/icons-material/Label';
import RemoveIcon from '@mui/icons-material/Remove';
import SearchIcon from '@mui/icons-material/Search';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import SubjectIcon from '@mui/icons-material/Subject';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import AlertDialog from '../../../../../../components/AlertDialog';
import FormDialog from '../../../../../../components/FormDialogUncontrolled';
import Select from '../../../../../../components/Select';
import Switch from '../../../../../../components/Switch';
import Tooltip from '../../../../../../components/Tooltip';
import Window from '../../../../../../components/Window';
import { getAssignedFieldNameById } from '../../../../../../utils/common';
import { allEffectTypes, SPECIAL_STRINGS_NONE } from '../../../../../../utils/constants';
import { useAppCtxAPI, useAppCtxListServicosDoAudiovisual } from '../../../../../../context/SystemContext';
import { useClient } from '../../../../../../context/ClientContext';
import { useOperatorTemplatesCtxAPI, useOperatorTemplatesCtxTemplateEffects, useOperatorTemplatesCtxTemplateFormEditorLogicalBranchingOptions, useOperatorTemplatesCtxTemplateFormEditorQuestions } from '../../context/TemplatesContext';
import { useFormEditor } from '../FormEditorContext';
import { v4 as uuidv4 } from 'uuid';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import update from 'immutability-helper';

const classes = {
    actionWindow: {
        width: ['95%', '85%', '75%']
    },
    heading: {
        fontSize: theme => theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme => theme.typography.pxToRem(15),
        color: theme => theme.palette.text.secondary,
    },
    multipleChoiceOption: {
        '&:hover': {
            backgroundColor: theme => theme.palette.action.hover
        }
    }
};

const FormEditorQuestionWindow = ({open, setOpen, question, setQuestion}) => {
    const { toast } = useAppCtxAPI();
    const special_list_audiovisual_services = useAppCtxListServicosDoAudiovisual();
    const { getListById, userTemplateFields } = useClient();
    const { setFormEditorLogicalBranchingOptions, setTemplateEffects, updateFormEditorQuestion } = useOperatorTemplatesCtxAPI();
    const templateEffects = useOperatorTemplatesCtxTemplateEffects();
    const formEditorLogicalBranchingOptions = useOperatorTemplatesCtxTemplateFormEditorLogicalBranchingOptions();
    const formEditorQuestions = useOperatorTemplatesCtxTemplateFormEditorQuestions();
    const {
        corporationIdQuestions, set_clickedQuestion, set_updateTitleDialogOpen, set_updateDescriptionDialogOpen,
        setAssignFieldMenuAnchorEl, setDeleteTitleDialogOpen, setAssignFieldMenuOpen
    } = useFormEditor();
    const lists = ['customList', 'list_name_servicosDoAudiovisual', 'list_description_servicosDoAudiovisual'];
    const [expandedAccordion, setExpandedAccordion] = useState('logicalBranching');
    const [selectedMultipleChoiceOptionIndex, setSelectedMultipleChoiceOptionIndex] = useState(null);
    const [addMultipleChoiceOptionDialogOpen, setAddMultipleChoiceOptionDialogOpen] = useState(false);
    const [changeMultipleChoiceOptionDialogOpen, setChangeMultipleChoiceOptionDialogOpen] = useState(false);
    const [deleteMultipleChoiceOptionDialogOpen, setDeleteMultipleChoiceOptionDialogOpen] = useState(false);
    
    useEffect(() => {
        setExpandedAccordion('logicalBranching');
    }, [open]);
    
    const getQuestionType = (type) => {
        return allEffectTypes.find(i => i.value === type)?.label;
    };
    const setLogicalBranchingQuestion = (question, value, logicalBranchingConditionId, concurrentConditionGroupIndex, logicalBranchingConditionIndex) => {
        if(question && value){
            if(SPECIAL_STRINGS_NONE.includes(value)){
                const newValue = {
                    ...question,
                    logicalBranching: [
                        ...question.logicalBranching.slice(0, concurrentConditionGroupIndex),
                        {
                            ...question.logicalBranching[concurrentConditionGroupIndex],
                            concurrentConditions: [
                                ...question.logicalBranching[concurrentConditionGroupIndex].concurrentConditions.slice(0, logicalBranchingConditionIndex),
                                {
                                    ...question.logicalBranching[concurrentConditionGroupIndex].concurrentConditions[logicalBranchingConditionIndex],
                                    question: SPECIAL_STRINGS_NONE[0],
                                    type: null, typeId: null, choice: null, label: null
                                },
                                ...question.logicalBranching[concurrentConditionGroupIndex].concurrentConditions.slice(logicalBranchingConditionIndex + 1),
                            ]
                        },
                        ...question.logicalBranching.slice(concurrentConditionGroupIndex + 1)
                    ]
                };
                setQuestion(newValue);
                updateFormEditorQuestion(newValue);
            } else {
                let loop = false;
                let isUserCreatedField = userTemplateFields.find(field => field.id === value);
                if(isUserCreatedField){
                    if(isUserCreatedField.type === 'boolean'){
                        const newValue = {
                            ...question,
                            logicalBranching: [
                                ...question.logicalBranching.slice(0, concurrentConditionGroupIndex),
                                {
                                    ...question.logicalBranching[concurrentConditionGroupIndex],
                                    concurrentConditions: [
                                        ...question.logicalBranching[concurrentConditionGroupIndex].concurrentConditions.slice(0, logicalBranchingConditionIndex),
                                        {
                                            ...question.logicalBranching[concurrentConditionGroupIndex].concurrentConditions[logicalBranchingConditionIndex],
                                            question: value,
                                            type: 'userCreatedBooleanField',
                                            choice: 'true', label: 'Se verdadeiro'
                                        },
                                        ...question.logicalBranching[concurrentConditionGroupIndex].concurrentConditions.slice(logicalBranchingConditionIndex + 1),
                                    ]
                                },
                                ...question.logicalBranching.slice(concurrentConditionGroupIndex + 1)
                            ]
                        };
                        setQuestion(newValue);
                        updateFormEditorQuestion(newValue);
                        setFormEditorLogicalBranchingOptions({
                            ...formEditorLogicalBranchingOptions, [question.name]: {
                                ...formEditorLogicalBranchingOptions[question.name],
                                [logicalBranchingConditionId]: [{choice: 'true', label: 'Se verdadeiro'}, {choice: 'false', label: 'Se falso'}]
                            }
                        });
                    }
                } else {
                    let logicalBranchingQuestion = formEditorQuestions.find(formEditorQ => formEditorQ.name === value);
                    let logicalBranchingQuestionOptions;
                    if(logicalBranchingQuestion.type === 'list'){
                        logicalBranchingQuestionOptions = [];
                        let listId = logicalBranchingQuestion.typeId;
                        let currentList = getListById(listId);
                        if(currentList){
                            currentList = currentList.list;
                            logicalBranchingQuestionOptions = currentList.map(i => ({choice: i.key, label: i.key}));
                        }
                    } else if(logicalBranchingQuestion.type === 'special' && (lists.includes(logicalBranchingQuestion.special) || logicalBranchingQuestion.name === 'Serviço')){ //?
                        logicalBranchingQuestionOptions = special_list_audiovisual_services.map(i => ({choice: i.key, label: i.key}));
                    } else {
                        logicalBranchingQuestionOptions = logicalBranchingQuestion.options;
                    }
                    /*
                    let logicalBranching = logicalBranchingQuestion.logicalBranching;
                    while (!logicalBranching.some(logicalBranchingCondition => !SPECIAL_STRINGS_NONE.includes(logicalBranchingCondition.question))) {
                    // while (!SPECIAL_STRINGS_NONE.includes(logicalBranching.question)) {
                        if(logicalBranching.some(logicalBranchingCondition => logicalBranchingCondition.question === questionName)){
                        // if(logicalBranching.question === questionName){
                            loop = true;
                            break;
                        }
                        logicalBranchingQuestion = formEditorQuestions.find(formEditorQ => formEditorQ.name === logicalBranching.question);
                        logicalBranching = logicalBranchingQuestion.logicalBranching;
                    }
                    */
                    if(!loop){
                        const newValue = {
                            ...question,
                            logicalBranching: [
                                ...question.logicalBranching.slice(0, concurrentConditionGroupIndex),
                                {
                                    ...question.logicalBranching[concurrentConditionGroupIndex],
                                    concurrentConditions: [
                                        ...question.logicalBranching[concurrentConditionGroupIndex].concurrentConditions.slice(0, logicalBranchingConditionIndex),
                                        {
                                            ...question.logicalBranching[concurrentConditionGroupIndex].concurrentConditions[logicalBranchingConditionIndex],
                                            question: value,
                                            type: logicalBranchingQuestion.type,
                                            typeId: logicalBranchingQuestion.typeId,
                                            choice: null, label: null
                                        },
                                        ...question.logicalBranching[concurrentConditionGroupIndex].concurrentConditions.slice(logicalBranchingConditionIndex + 1),
                                    ]
                                },
                                ...question.logicalBranching.slice(concurrentConditionGroupIndex + 1)
                            ]
                        };
                        setQuestion(newValue);
                        updateFormEditorQuestion(newValue);
                        setFormEditorLogicalBranchingOptions({
                            ...formEditorLogicalBranchingOptions, [question.name]: {
                                ...formEditorLogicalBranchingOptions[question.name],
                                [logicalBranchingConditionId]: logicalBranchingQuestionOptions
                            }
                        });
                    } else {
                        toast('Você não pode fechar o ciclo em uma ramificação lógica');
                        const newValue = {
                            ...question,
                            logicalBranching: [
                                ...question.logicalBranching.slice(0, concurrentConditionGroupIndex),
                                {
                                    ...question.logicalBranching[concurrentConditionGroupIndex],
                                    concurrentConditions: [
                                        ...question.logicalBranching[concurrentConditionGroupIndex].concurrentConditions.slice(0, logicalBranchingConditionIndex),
                                        {
                                            ...question.logicalBranching[concurrentConditionGroupIndex].concurrentConditions[logicalBranchingConditionIndex],
                                            question: SPECIAL_STRINGS_NONE[0],
                                            type: null, typeId: null, choice: null, label: null
                                        },
                                        ...question.logicalBranching[concurrentConditionGroupIndex].concurrentConditions.slice(logicalBranchingConditionIndex + 1),
                                    ]
                                },
                                ...question.logicalBranching.slice(concurrentConditionGroupIndex + 1)
                            ]
                        };
                        setQuestion(newValue);
                        updateFormEditorQuestion(newValue);
                    }
                }
            }
        }
    };
    const setLogicalBranchingChoice = (question, choice, concurrentConditionGroupIndex, logicalBranchingConditionIndex) => {
        const newValue = {
            ...question,
            logicalBranching: [
                ...question.logicalBranching.slice(0, concurrentConditionGroupIndex),
                {
                    ...question.logicalBranching[concurrentConditionGroupIndex],
                    concurrentConditions: [
                        ...question.logicalBranching[concurrentConditionGroupIndex].concurrentConditions.slice(0, logicalBranchingConditionIndex),
                        {
                            ...question.logicalBranching[concurrentConditionGroupIndex].concurrentConditions[logicalBranchingConditionIndex],
                            choice: choice, label: choice
                        },
                        ...question.logicalBranching[concurrentConditionGroupIndex].concurrentConditions.slice(logicalBranchingConditionIndex + 1),
                    ]
                },
                ...question.logicalBranching.slice(concurrentConditionGroupIndex + 1)
            ]
        };
        setQuestion(newValue);
        updateFormEditorQuestion(newValue);
    };
    const setLogicalBranchingOperation = (question, operation, concurrentConditionGroupIndex, logicalBranchingConditionIndex) => {
        const newValue = {
            ...question,
            logicalBranching: [
                ...question.logicalBranching.slice(0, concurrentConditionGroupIndex),
                {
                    ...question.logicalBranching[concurrentConditionGroupIndex],
                    concurrentConditions: [
                        ...question.logicalBranching[concurrentConditionGroupIndex].concurrentConditions.slice(0, logicalBranchingConditionIndex),
                        {
                            ...question.logicalBranching[concurrentConditionGroupIndex].concurrentConditions[logicalBranchingConditionIndex],
                            operation
                        },
                        ...question.logicalBranching[concurrentConditionGroupIndex].concurrentConditions.slice(logicalBranchingConditionIndex + 1),
                    ]
                },
                ...question.logicalBranching.slice(concurrentConditionGroupIndex + 1)
            ]
        };
        setQuestion(newValue);
        updateFormEditorQuestion(newValue);
    };
    const handleDeleteLogicalBranchingConditionsGroupButtonPress = (question, concurrentConditionGroupIndex) => {
        const newQuestionValue = {
            ...question,
            logicalBranching: [
                ...question.logicalBranching.slice(0, concurrentConditionGroupIndex),
                ...question.logicalBranching.slice(concurrentConditionGroupIndex + 1)
            ]
        };
        setQuestion(newQuestionValue);
        updateFormEditorQuestion(newQuestionValue);
    }
    const handleDeleteLogicalBranchingConditionButtonPress = (question, concurrentConditionGroupIndex, logicalBranchingConditionIndex) => {
        const newValue = {
            ...question,
            logicalBranching: [
                ...question.logicalBranching.slice(0, concurrentConditionGroupIndex),
                {
                    ...question.logicalBranching[concurrentConditionGroupIndex],
                    concurrentConditions: [
                        ...question.logicalBranching[concurrentConditionGroupIndex].concurrentConditions.slice(0, logicalBranchingConditionIndex),
                        ...question.logicalBranching[concurrentConditionGroupIndex].concurrentConditions.slice(logicalBranchingConditionIndex + 1),
                    ]
                },
                ...question.logicalBranching.slice(concurrentConditionGroupIndex + 1)
            ]
        };
        setQuestion(newValue);
        updateFormEditorQuestion(newValue);
    };
    const handleUpdateTitleButtonClick = (question) => {
        set_clickedQuestion(question);
        set_updateTitleDialogOpen(true);
    };
    const handleDeleteTitleButtonClick = (question) => {
        set_clickedQuestion(question);
        setDeleteTitleDialogOpen(true);
    };
    const handleUpdateDescriptionButtonClick = (question) => {
        set_clickedQuestion(question);
        set_updateDescriptionDialogOpen(true);
    };
    const handleAssignFieldButtonClick = (element, question) => {
        set_clickedQuestion(question);
        setAssignFieldMenuAnchorEl(element);
        setAssignFieldMenuOpen(true);
    };
    const handle_requiredClick = () => {
        const newValue = {
            ...question,
            mandatory: !question.mandatory
        };
        setQuestion(newValue);
        updateFormEditorQuestion(newValue);

    };
    const handleShowListDescriptionChange = () => {
        const newValue = {
            ...question,
            showListDescription: !question.showListDescription
        };
        setQuestion(newValue);
        updateFormEditorQuestion(newValue);
    };
    const handle_favoriteClick = () => {
        const newValue = {...question, starred: !question.starred};
        setQuestion(newValue);
        updateFormEditorQuestion(newValue);
    };
    const textCaseOptions = [
        { value: 'default', label: 'Sem alterações' },
        { value: 'uppercase', label: 'TODAS AS LETRAS MAIÚSCULAS' },
        { value: 'lowercase', label: 'todas as letras minúsculas' },
        { value: 'uppercaseInitials', label: 'Todas As Iniciais Maiúsculas' },
    ];
    const handleTextCaseButtonClick = () => {
        let nextOptionValue = 'uppercase';
        if(question.textCase){
            let currentOptionIndex = textCaseOptions.findIndex(option => option.value === question.textCase);
            if(currentOptionIndex === -1) currentOptionIndex = 0;
            let nextOptionIndex = currentOptionIndex + 1;
            if(nextOptionIndex >= textCaseOptions.length) nextOptionIndex = 0;
            nextOptionValue = textCaseOptions[nextOptionIndex].value;
        }
        const newValue = {
            ...question,
            textCase: nextOptionValue
        };
        setQuestion(newValue);
        updateFormEditorQuestion(newValue);
    };
    const getTextCaseOptionLabel = () => {
        let currentOptionValue = question.textCase || 'default';
        let currentOption = textCaseOptions.find(option => option.value === currentOptionValue);
        return currentOption.label;
    };
    const getNextTextCaseOptionLabel = () => {
        let label = textCaseOptions[1].label;
        if(question.textCase){
            let currentOptionIndex = textCaseOptions.findIndex(option => option.value === question.textCase);
            if(currentOptionIndex === -1) currentOptionIndex = 0;
            let nextOptionIndex = currentOptionIndex + 1;
            if(nextOptionIndex >= textCaseOptions.length) nextOptionIndex = 0;
            label = textCaseOptions[nextOptionIndex].label;
        }
        return label;
    };

    const handleAddLogicalBranchingConditionsGroupButtonPress = (question) => {
        const newValue = {
            ...question,
            logicalBranching: [
                ...question.logicalBranching,
                {
                    id: uuidv4(),
                    concurrentConditions: [
                        {
                            id: uuidv4(),
                            choice: '',
                            label: '',
                            question: '~none;',
                            type: '',
                            typeId: '',
                            operation: 'if'
                        }
                    ]
                }
            ]
        };
        setQuestion(newValue);
        updateFormEditorQuestion(newValue);
    };
    const handleAddLogicalBranchingConditionButtonPress = (question, concurrentConditionGroupIndex) => {
        const newValue = {
            ...question,
            logicalBranching: [
                ...question.logicalBranching.slice(0, concurrentConditionGroupIndex),
                {
                    ...question.logicalBranching[concurrentConditionGroupIndex],
                    concurrentConditions: [
                        ...question.logicalBranching[concurrentConditionGroupIndex].concurrentConditions,
                        {
                            id: uuidv4(),
                            choice: '',
                            label: '',
                            question: '~none;',
                            type: '',
                            typeId: '',
                            operation: 'if'
                        }
                    ]
                },
                ...question.logicalBranching.slice(concurrentConditionGroupIndex + 1),
            ]
        };
        setQuestion(newValue);
        updateFormEditorQuestion(newValue);
    }

    const handleAccordionChange = (clickedAccordion) => {
        setExpandedAccordion(prevState => {
            let nextState = '';
            if(clickedAccordion !== prevState) nextState = clickedAccordion;
            return nextState;
        });
    }

    const handleChangeOptionLabelButtonClick = (optionIndex) => {
        setSelectedMultipleChoiceOptionIndex(optionIndex);
        setChangeMultipleChoiceOptionDialogOpen(true);
    };
    const handleChangeOptionLabelConfirm = (newValue) => {
        setChangeMultipleChoiceOptionDialogOpen(false);
        if(newValue){
            const questionIndex = formEditorQuestions.findIndex(formEditorQuestion => formEditorQuestion.name === question.name);
            if(questionIndex !== -1){

                const templateEffectsNextState = templateEffects.map(templateEffect => {
                    if(templateEffect.question === question.name){
                        const templateEffectOptionIndex = templateEffect.options.findIndex(option => option.option === formEditorQuestions[questionIndex].options[selectedMultipleChoiceOptionIndex].choice);
                        if(templateEffectOptionIndex !== -1){
                            templateEffect.options = [
                                ...templateEffect.options.slice(0, templateEffectOptionIndex),
                                {
                                    ...templateEffect.options[templateEffectOptionIndex],
                                    option: newValue
                                },
                                ...templateEffect.options.slice(templateEffectOptionIndex + 1)
                            ];

                        }
                    }
                    return templateEffect;
                });
                setTemplateEffects(templateEffectsNextState);

                const nextQuestion = {
                    ...formEditorQuestions[questionIndex],
                    options: [
                        ...formEditorQuestions[questionIndex].options.slice(0, selectedMultipleChoiceOptionIndex),
                        {
                            ...formEditorQuestions[questionIndex].options[selectedMultipleChoiceOptionIndex],
                            choice: newValue,
                            label: newValue
                        },
                        ...formEditorQuestions[questionIndex].options.slice(selectedMultipleChoiceOptionIndex + 1)
                    ]
                }
                setQuestion(nextQuestion);

                updateFormEditorQuestion(nextQuestion);
            }
        }
    };

    const handleDeleteOptionButtonClick = (optionIndex) => {
        setSelectedMultipleChoiceOptionIndex(optionIndex);
        setDeleteMultipleChoiceOptionDialogOpen(true);
    };
    const handleDeleteOptionConfirm = () => {
        setDeleteMultipleChoiceOptionDialogOpen(false);
        const questionIndex = formEditorQuestions.findIndex(formEditorQuestion => formEditorQuestion.name === question.name);
        if(questionIndex !== -1){

            const templateEffectsNextState = templateEffects.map(templateEffect => {
                if(templateEffect.question === question.name){
                    const templateEffectOptionIndex = templateEffect.options.findIndex(option => option.option === formEditorQuestions[questionIndex].options[selectedMultipleChoiceOptionIndex].choice);
                    if(templateEffectOptionIndex !== -1){
                        templateEffect.options = [
                            ...templateEffect.options.slice(0, templateEffectOptionIndex),
                            ...templateEffect.options.slice(templateEffectOptionIndex + 1)
                        ];
                    }
                }
                return templateEffect;
            });
            setTemplateEffects(templateEffectsNextState);

            const nextQuestion = {
                ...formEditorQuestions[questionIndex],
                options: [
                    ...formEditorQuestions[questionIndex].options.slice(0, selectedMultipleChoiceOptionIndex),
                    ...formEditorQuestions[questionIndex].options.slice(selectedMultipleChoiceOptionIndex + 1)
                ]
            }
            setQuestion(nextQuestion);
            
            updateFormEditorQuestion(nextQuestion);
        }
    };
    
    const handleAddOptionButtonClick = () => {
        setAddMultipleChoiceOptionDialogOpen(true);
    };
    const handleAddOptionConfirm = (newValue) => {
        setAddMultipleChoiceOptionDialogOpen(false);
        if(newValue){
            const nextState = templateEffects.map(templateEffect => {
                if(templateEffect.question === question.name){
                    templateEffect.options = [
                        ...templateEffect.options,
                        {
                            option: newValue,
                            output: ''
                        }
                    ];
                }
                return templateEffect;
            });
            setTemplateEffects(nextState);
            const questionIndex = formEditorQuestions.findIndex(formEditorQuestion => formEditorQuestion.name === question.name);
            if(questionIndex !== -1){
                const nextQuestion = {
                    ...formEditorQuestions[questionIndex],
                    options: [
                        ...formEditorQuestions[questionIndex].options,
                        {
                            id: uuidv4(),
                            choice: newValue,
                            label: newValue
                        }
                    ]
                }
                setQuestion(nextQuestion);
                
                updateFormEditorQuestion(nextQuestion);
            }
        }
    };

    const handleMultipleChoiceOptionDragEnd = (data) => {
        const questionIndex = formEditorQuestions.findIndex(formEditorQuestion => formEditorQuestion.name === question.name);
        if(questionIndex !== -1){
            const nextQuestion = {
                ...formEditorQuestions[questionIndex],
                options: update(formEditorQuestions[questionIndex].options, {
                    $splice: [
                        [data.source.index, 1],
                        [data.destination.index, 0, formEditorQuestions[questionIndex].options[data.source.index]],
                    ],
                })
            }
            setQuestion(nextQuestion);

            updateFormEditorQuestion(nextQuestion);
        }
    };
    
    if(!question) return null;

    return <>
        <Window open={open} onClose={() => setOpen(false)} modalInnerBoxSx={classes.actionWindow}>
            <Grid container alignItems="center" justifyContent="space-between" spacing={1}>
                <Grid item xs={9}>
                    <Box>
                        <Grid container alignItems="center" spacing={2}>
                            {
                                question.type !== 'heading' &&
                                <Grid item>
                                    <IconButton size="small" onClick={handle_favoriteClick}>{question.starred ? <StarIcon /> : <StarBorderIcon />}</IconButton>
                                </Grid>
                            }
                            <Grid item>
                                <Typography variant="h6">{question.name}<Typography variant="body2" component="span"> ({getQuestionType(question.type)})</Typography></Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    {
                        question.description &&
                        <Box>
                            <Typography variant="body2" color="textPrimary">{question.description}</Typography>
                        </Box>
                    }
                </Grid>
                <Grid item xs={3} container justifyContent="flex-end" alignItems="center" spacing={1}>
                    {
                        question.type === 'text' &&
                        <Grid item>
                            <Tooltip text={`Alterar para ${getNextTextCaseOptionLabel()}`}>
                                <Fab size="small" color="primary" onClick={() => handleTextCaseButtonClick()}><TextFieldsIcon /></Fab>
                            </Tooltip>
                        </Grid>
                    }
                    {
                        question.type === 'heading' &&
                        <Grid item>
                            <Tooltip text="Alterar o título">
                                <Fab size="small" color="primary" onClick={() => handleUpdateTitleButtonClick(question)}><EditIcon /></Fab>
                            </Tooltip>
                        </Grid>
                    }
                    <Grid item>
                            <Tooltip text="Alterar descrição">
                                <Fab size="small" color="primary" onClick={() => handleUpdateDescriptionButtonClick(question)}><SubjectIcon /></Fab>
                            </Tooltip>
                    </Grid>
                    {
                        question.type === 'heading' &&
                        <Grid item>
                            <Tooltip text="Excluir o título">
                                <Fab size="small" color="primary" onClick={() => handleDeleteTitleButtonClick(question)}><DeleteIcon /></Fab>
                            </Tooltip>
                        </Grid>
                    }
                    {
                        ['text', 'corporationId', 'individualId'].includes(question.type) &&
                        <Grid item>
                            <Tooltip text="Vincular a um campo da ficha cadastral">
                                <Fab size="small" color="primary" onClick={(e) => handleAssignFieldButtonClick(e.target, question)}><LabelIcon /></Fab>
                            </Tooltip>
                        </Grid>
                    }
                </Grid>
            </Grid>
            {
                question.type !== 'heading' &&
                <Box>
                    {
                        (question.textCase && question.textCase !== 'default') &&
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <TextFieldsIcon fontSize="small" />
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">{getTextCaseOptionLabel()}</Typography>
                            </Grid>
                        </Grid>
                    }
                    <Box mb={1}>
                        <Switch checked={question.mandatory} onChange={handle_requiredClick} label={<Typography variant="body2">Obrigatória?</Typography>} labelPosition="left" />
                    </Box>
                    {
                        ['list', 'special'].includes(question.type) &&
                        <Box mb={1}>
                            <Switch checked={question.showListDescription} onChange={handleShowListDescriptionChange} label={<Typography variant="body2">Mostrar "detalhamento" no formulário?</Typography>} labelPosition="left" />
                        </Box>
                    }
                    {
                        (question.assignedField && question.assignedField !== 0) &&
                        <Box mb={2}>
                            <Box mb={1}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item>
                                        <LabelIcon fontSize="small" />
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="h6">Vincular a um campo da ficha cadastral</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box>
                                <Typography variant="body2">{getAssignedFieldNameById(question.assignedField)}</Typography>
                            </Box>
                        </Box>
                    }
                    {
                        (corporationIdQuestions.length >= 1 && ['text', 'list', 'special'].includes(question.type)) &&
                        <Box mb={2}>
                            <Box mb={1}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item>
                                        <SearchIcon fontSize="small" />
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="h6">Vincular a pergunta de CNPJ</Typography>
                                        <Typography variant="body2">Se você vincular a pergunta a outra do tipo "CNPJ", os dados do CNPJ registrados na Receita Federal, se encontrados, serão automaticamente lançados nessa pergunta.</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box>
                                <Grid container>
                                    <Grid item xs={12} sm={6} md={2}>
                                        <Select
                                            label="Escolha uma pergunta"
                                            options={[
                                                {value: 'none', label: 'Nenhuma'},
                                                ...corporationIdQuestions.map(corporationIdQuestion => ({value: corporationIdQuestion.name, label: corporationIdQuestion.name}))
                                            ]}
                                            value={question.corporationIdQuestion || 'none'}
                                            onChange={(e) => {
                                                const newValue = {
                                                    ...question,
                                                    corporationIdQuestion: e.target.value
                                                };
                                                setQuestion(newValue);
                                                updateFormEditorQuestion(newValue);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    }
                </Box>
            }
            <Accordion expanded={expandedAccordion === 'logicalBranching'} onChange={() => handleAccordionChange('logicalBranching')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                    <Typography sx={classes.heading}>Ramificação lógica</Typography>
                    <Typography sx={classes.secondaryHeading}>Definir condições para exibição da pergunta</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box>
                        <Box mb={1}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <AccountTreeIcon fontSize="small" />
                                </Grid>
                                <Grid item xs>
                                    <Typography variant="body2">A pergunta deve aparecer se qualquer um dos grupos de condições abaixo for cumprido, desde que todas as condições dentro de um mesmo grupo sejam cumpridas simultaneamente.</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mb={1}>
                            {
                                question.logicalBranching.map((concurrentConditionGroup, concurrentConditionGroupIndex) => {
                                    return (
                                        <Box key={concurrentConditionGroup.id}
                                            style={{
                                                backgroundColor: 'rgba(255, 255, 255, 0.25)',
                                                border: '1px solid rgba(0, 0, 0, 0.08)',
                                                borderRadius: 8,
                                                boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.08)',
                                                padding: '8px 16px',
                                                marginBottom: 8
                                            }}
                                        >
                                            <Box mb={1}>
                                                <Typography variant="h6">Grupo {concurrentConditionGroupIndex + 1}</Typography>
                                            </Box>
                                            {
                                                concurrentConditionGroup.concurrentConditions.map((logicalBranchingCondition, logicalBranchingConditionIndex) => {
                                                    return (
                                                        <Grid key={logicalBranchingCondition.id} container spacing={1} alignItems="flex-end" wrap="nowrap">
                                                            <Grid item>
                                                                <IconButton size="small" onClick={() => handleDeleteLogicalBranchingConditionButtonPress(question, concurrentConditionGroupIndex, logicalBranchingConditionIndex)}><ClearIcon /></IconButton>
                                                            </Grid>
                                                            <Grid item xs container spacing={1} alignItems="flex-end" wrap="nowrap">
                                                                <Grid item xs={12} sm={5}>
                                                                    <Select
                                                                        // label="Escolha uma pergunta"
                                                                        options={[
                                                                            {value: SPECIAL_STRINGS_NONE[0], label: 'Nenhuma'},

                                                                            //? (logicalBranchingQuestion.name === 'Serviço')

                                                                            ...formEditorQuestions.filter(formEditorQ => (formEditorQ.type === 'radio' || formEditorQ.type === 'list' ||  (formEditorQ.type === 'special' && (lists.includes(formEditorQ.special) || formEditorQ.name === 'Serviço'))) && formEditorQ.name !== question.name).map(option => ({value: option.name, label: option.name})),
                                                                            ...userTemplateFields.filter(field => field.type === 'boolean').map(field => ({value: field.id, label: '[VERDADEIRO OU FALSO] ' + field.name}))
                                                                        ]}
                                                                        value={logicalBranchingCondition.question}
                                                                        onChange={(e) => setLogicalBranchingQuestion(question, e.target.value, logicalBranchingCondition.id, concurrentConditionGroupIndex, logicalBranchingConditionIndex)}
                                                                    />
                                                                </Grid>
                                                                {
                                                                    (!SPECIAL_STRINGS_NONE.includes(logicalBranchingCondition.question) && formEditorLogicalBranchingOptions[question.name]) &&
                                                                    <Grid item xs container spacing={1} alignItems="flex-end" wrap="nowrap">
                                                                        {
                                                                            logicalBranchingCondition.type !== 'userCreatedBooleanField' &&
                                                                            <Grid item xs={4} sm={3}>
                                                                                <Select
                                                                                    // label="Se a resposta for"
                                                                                    options={[
                                                                                        {value: 'if', label: 'IGUAL a'},
                                                                                        {value: 'ifNot', label: 'DIFERENTE de' }
                                                                                    ]}
                                                                                    value={logicalBranchingCondition.operation || 'if'}
                                                                                    onChange={(e) => setLogicalBranchingOperation(question, e.target.value, concurrentConditionGroupIndex, logicalBranchingConditionIndex)}
                                                                                />
                                                                            </Grid>
                                                                        }
                                                                        <Grid item xs>
                                                                            <Select
                                                                                // label="Escolha uma resposta"
                                                                                options={formEditorLogicalBranchingOptions[question.name][logicalBranchingCondition.id].map(option => ({value: option.choice, label: option.label}))}
                                                                                value={logicalBranchingCondition.choice}
                                                                                onChange={(e) => setLogicalBranchingChoice(question, e.target.value, concurrentConditionGroupIndex, logicalBranchingConditionIndex)}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                            <Grid container justifyContent="flex-end" wrap="nowrap">
                                                <Grid item>
                                                    <Button
                                                        startIcon={<AddIcon />}
                                                        onClick={() => handleAddLogicalBranchingConditionButtonPress(question, concurrentConditionGroupIndex)}
                                                    >Condição simultânea</Button>
                                                    <Button
                                                        startIcon={<RemoveIcon />}
                                                        onClick={() => handleDeleteLogicalBranchingConditionsGroupButtonPress(question, concurrentConditionGroupIndex)}
                                                    >Descartar grupo</Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    );
                                })
                            }
                        </Box>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Button startIcon={<AddIcon />}
                                    onClick={() => handleAddLogicalBranchingConditionsGroupButtonPress(question, formEditorLogicalBranchingOptions[question.name])}
                                >Grupo de condições</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </AccordionDetails>
            </Accordion>
            {
                question.type === 'radio' &&
                <Accordion expanded={expandedAccordion === 'options'} onChange={() => handleAccordionChange('options')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
                        <Typography sx={classes.heading}>Opções</Typography>
                        <Typography sx={classes.secondaryHeading}>Alterar opções da pergunta de múltipla escolha</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box style={{width: '100%'}}>

                            <DragDropContext onDragEnd={handleMultipleChoiceOptionDragEnd}>
                                <Droppable droppableId={'form-editor-question-window-droppable-options-container'}>
                                    {(provided) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            {
                                                question.options.map((option, optionIndex) => {
                                                    return (
                                                        <Draggable
                                                            key={option.id.toString()}
                                                            draggableId={option.id.toString()}
                                                            index={optionIndex}
                                                            isDragDisabled={false}
                                                        >
                                                            {(provided) => (
                                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>

                                                                    <Box>
                                                                        <Grid container spacing={1} alignItems="center" wrap="nowrap" sx={classes.multipleChoiceOption}>
                                                                            <Grid item>
                                                                                <IconButton
                                                                                    onClick={() => handleChangeOptionLabelButtonClick(optionIndex)}
                                                                                    size="large"><EditIcon /></IconButton>
                                                                            </Grid>
                                                                            <Grid item xs>
                                                                                <Typography variant="body2">{option.choice}</Typography>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <IconButton onClick={() => handleDeleteOptionButtonClick(optionIndex)} size="large"><ClearIcon /></IconButton>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Box>

                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                })
                                            }
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Button startIcon={<AddIcon />} onClick={handleAddOptionButtonClick}>Opção</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            }
        </Window>
        <AlertDialog
            open={deleteMultipleChoiceOptionDialogOpen} onClose={() => {setSelectedMultipleChoiceOptionIndex(null); setDeleteMultipleChoiceOptionDialogOpen(false)}}
            text={`Quer excluir a opção${question && selectedMultipleChoiceOptionIndex !== null && selectedMultipleChoiceOptionIndex !== -1 ? ` ${selectedMultipleChoiceOptionIndex + 1} ("${question.options[selectedMultipleChoiceOptionIndex].label}") da pergunta "${question.name}"` : ''}?`}
            okButtonOnClick={handleDeleteOptionConfirm}
        />
        <FormDialog
            open={addMultipleChoiceOptionDialogOpen} onClose={() => {setSelectedMultipleChoiceOptionIndex(null); setAddMultipleChoiceOptionDialogOpen(false)}}
            title="Incluir opção em uma pergunta de múltipla escolha"
            text={`Quer incluir uma opção${question ? ` para a pergunta "${question.name}"` : ''}?`}
            onConfirm={handleAddOptionConfirm}
            disabledValues={question && Array.isArray(question.options) ? question.options.map(option => option.choice) : null}
        />
        <FormDialog
            open={changeMultipleChoiceOptionDialogOpen} onClose={() => {setSelectedMultipleChoiceOptionIndex(null); setChangeMultipleChoiceOptionDialogOpen(false)}}
            title="Alterar opção em uma pergunta de múltipla escolha"
            text={`Quer alterar a opção${question && selectedMultipleChoiceOptionIndex !== null && selectedMultipleChoiceOptionIndex !== -1 ? ` ${selectedMultipleChoiceOptionIndex + 1} ("${question.options[selectedMultipleChoiceOptionIndex].label}") da pergunta "${question.name}"` : ''}?`}
            onConfirm={handleChangeOptionLabelConfirm}
            defaultValue={question && selectedMultipleChoiceOptionIndex !== null && selectedMultipleChoiceOptionIndex !== -1 ? question.options[selectedMultipleChoiceOptionIndex].choice : ''}
            disabledValues={question && Array.isArray(question.options) ? question.options.map(option => option.choice) : null}
        />
    </>;
};

export default FormEditorQuestionWindow;