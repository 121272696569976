import Fab from '@mui/material/Fab';
import { styled } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const Div = styled('div')(unstable_styleFunctionSx);

const MoreOptionsShownOnMouseOverFab = ({moreOptionsFabStyle, mouseIsOver, onClick}) => {
    
    let styles = {
        visibility: mouseIsOver ? 'visible' : 'hidden'
    };
    if(moreOptionsFabStyle) styles = {...styles, ...moreOptionsFabStyle};
    
    return (
        <Div
            sx={{
                position: 'absolute',
                top: 4,
                right: 4,
                visibility: ['visible', 'hidden'],
                zIndex: 2
            }}
            style={styles}>
            <Fab color="primary" size="small" onClick={onClick}><MoreHorizIcon /></Fab>
        </Div>
    )
};

export default MoreOptionsShownOnMouseOverFab;