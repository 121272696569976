import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import Select from '../../../../components/Select';
import Typography from '@mui/material/Typography';
import { getUserNameById, updateStateChangeArrayItemWith_id } from '../../../../utils/common';
import { useAppCtxAPI, useAppCtxActiveUser } from '../../../../context/SystemContext';
import { useSocket } from '../../../../context/SocketContext';
import moment from 'moment';

const CustomSelect = ({col, doc, setDocuments, users}) => {
    const { toast, update } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const { createSocketConnection } = useSocket();
    const socket = createSocketConnection();
    const [lastValue, set_lastValue] = useState(null);
    const [selectedValue, set_selectedValue] = useState('');
    
    useEffect(() => {
        let customCol = getColumnById();
        if(customCol){
            set_lastValue(customCol);
            set_selectedValue(customCol.value);
        }
    }, []);

    useEffect(() => {
        const documentFolderUserAnnotationChangedEventName = 'DOCUMENT FOLDER USER ANNOTATION CHANGED';

        const handleDocumentFolderUserAnnotationChangedEvent = (data) => {
            const { updatedDocument } = data;
            if(updatedDocument._id === doc._id){
                const annotation = getColumnById(updatedDocument.folderUserAnnotation || []);
                if(annotation){
                    set_lastValue(annotation);
                    set_selectedValue(annotation.value);
                }
            }
        };

        socket.on(documentFolderUserAnnotationChangedEventName, handleDocumentFolderUserAnnotationChangedEvent);
        return () => {
            socket.off(documentFolderUserAnnotationChangedEventName, handleDocumentFolderUserAnnotationChangedEvent);
        };
    }, []);

    const getColumnById = (data) => {
        const annotations = data || doc.customCols;
        if(Array.isArray(annotations)){
            const fieldIndex = annotations.findIndex(field => field.id === col.id);
            if(annotations[fieldIndex]) return annotations[fieldIndex]; //object
        }
        return null;
    };
    const handleChange = async (e) => {
        let value = e.target.value;
        set_selectedValue(value)
        let recordCopy = {...doc};
        let newRecord = {...doc};

        let newValue;
        if(Array.isArray(newRecord.customCols)){
            newValue = [...newRecord.customCols];
        } else {
            newValue = [];
        }
        let currentColumn = getColumnById();
        if(!currentColumn) newValue.push({id: col.id, value: ''});
        
        let newLastValue = {value: value, valueBy: activeUser._id, valueByName: activeUser.fullName, valueOn: moment().format()};
        let colIndex = newValue.findIndex(c => c.id === col.id);
        newValue[colIndex] = {
            ...newValue[colIndex],
            ...newLastValue
        };

        newRecord.customCols = newValue;
        updateStateChangeArrayItemWith_id(newRecord, setDocuments);
        try {
            await update('Documents', newRecord._id, {customCols: newValue});
            set_lastValue(newLastValue);
            socket.emit('DOCUMENT FOLDER USER ANNOTATION CHANGED', { updatedDocument: {...newRecord, folderUserAnnotation: newValue} });
        } catch (error) {
            updateStateChangeArrayItemWith_id(recordCopy, setDocuments);
            toast('Erro! As alterações foram desfeitas.');
        }
    };

    return (
        <Box>
            <Box>
                <Select style={{fontSize: 12}} optionsStyle={{fontSize: 12}} options={col.options.map(option => ({value: option, label: option}))} value={selectedValue} onChange={handleChange} />
            </Box>
            {
                (lastValue && (lastValue.valueOn || lastValue.valueBy)) &&
                <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <Box>
                        <EditIcon style={{fontSize: 11}} />
                    </Box>
                    <Box>
                        <Typography variant="body2" style={{fontSize: 9}}>
                            <em>
                                {lastValue.valueOn ? moment(lastValue.valueOn).format('L LT') : ''}
                                {(lastValue.valueBy && users?.length >= 1) ? ` por ${getUserNameById(users, lastValue.valueBy)}` : ''}
                            </em>
                        </Typography>
                    </Box>
                </Box>
            }
        </Box>
    );
};

export default CustomSelect;