import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Box, Button, CircularProgress, Grid } from '@mui/material';
import CompanyField from './Documents/AddDocumentWindow/components/CompanyField';
import FolderField from './Documents/AddDocumentWindow/components/FolderField';
import GroupField from './Documents/AddDocumentWindow/components/GroupField';
import DocumentNameField from './Documents/AddDocumentWindow/DocumentNameField';
import DocumentUrlField from './Documents/AddDocumentWindow/DocumentUrlField';
import CenteredCircularProgress from './CenteredCircularProgress';
import Dropzone from './Dropzone';
import Window from './Window';
import LinkIcon from '@mui/icons-material/Link';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useAppCtxAPI } from '../context/SystemContext';
import { useSocket, useSocketCtxConnectedAt } from '../context/SocketContext';
import { useDocumentsAPI, useDocumentsCtxAddDocumentView, useDocumentsCtxLoadingTemplates, useDocumentsCtxTemplates } from '../context/DocumentsContext';
import { SERVER_PATH } from '../utils/constants';
import { sortByKey } from '../utils/filters';

const AddDocumentWindow = ({actionCallback, activeUserIsOperator, addDocument}) => { 
    const { toast } = useAppCtxAPI();
    const { shouldUpdateTemplates } = useSocket();
    const socketConnectedAt = useSocketCtxConnectedAt();
    const { dispatch: dispatchDocuments, hideAddDocumentView } = useDocumentsAPI();
    const { addDocumentViewOpen, addDocumentViewProjects: projects, addDocumentViewSelectedFolder: selectedFolder } = useDocumentsCtxAddDocumentView();
    const loadingTemplates = useDocumentsCtxLoadingTemplates();
    const templates = useDocumentsCtxTemplates();
    const [loadingPage, setLoadingPage] = useState(false);
    const [loadingProject, setLoadingProject] = useState(false);
    const [loading, setLoading] = useState(false);
    const [visibleTemplates, setVisibleTemplates] = useState([]);
    const [folderOptions, setFolderOptions] = useState(null);
    const [groupOptions, setGroupOptions] = useState(null);
    const [uploadTypeUrl, setUploadTypeUrl] = useState(false);
    const defaultAddDocumentForm = {
        companyId: '',
        folderId: '',
        groupId: '',
        templateId: '~special;',
        documentName: '',
        documentUrl: ''
    };
    const addDocumentForm = useRef(defaultAddDocumentForm);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
    const acceptedFilesRef = useRef([]);

    useEffect(() => {
        setLoadingPage(loadingTemplates);
    }, [loadingTemplates]);

    useEffect(() => {
        if(shouldUpdateTemplates){
            dispatchDocuments({type: 'LOAD TEMPLATES'});
        }
    }, [socketConnectedAt]);
    
    useEffect(() => {
        if(templates){
            const currentTemplates = templates.sort(sortByKey('name'));
            setVisibleTemplates(currentTemplates);
        }
    }, [templates]);
    
    useEffect(() => {
        if(addDocumentViewOpen && visibleTemplates.length >= 1){
            addDocumentForm.current = defaultAddDocumentForm;
            setSubmitButtonDisabled(true);
            if(selectedFolder){
                if(selectedFolder.client){
                    handleSelectedCompanyChange(selectedFolder.client);
                    handleSelectedFolderChange(selectedFolder._id);
                } else {
                    handleSelectedCompanyChange(selectedFolder);
                    handleSelectedFolderChange('none');
                }
            } else {
                clearForm();
            }
        }
    }, [addDocumentViewOpen, visibleTemplates]);

    useEffect(() => {
        checkSubmitButtonDisabled();
    }, [uploadTypeUrl])

    const handleSelectedCompanyChange = (newValue) => {
        addDocumentForm.current.companyId = newValue;
        if(newValue){
            const companyFolders = projects.filter(project => project.client === newValue);
            let newProjectOptions = companyFolders.map(project => ({value: project._id, label: project.name}));
            setFolderOptions(newProjectOptions);
        } else {
            setFolderOptions(null);
        }
        setGroupOptions(null);
        addDocumentForm.current.folderId = '';
        addDocumentForm.current.groupId = '';
        
        checkSubmitButtonDisabled();
    };
    const getFolder = async (projectId) => {
        setLoadingProject(true);
        projectId = projectId !== 'none' ? projectId : addDocumentForm.current.companyId;
        let project = null;
        await axios.post(SERVER_PATH + '/data/projects/one', {projectId})
        .then(res => {
            project = res.data.project;
        })
        .catch(err => {
            // throw err;
        });
        setLoadingProject(false);
        return project;
    };
    const handleSelectedFolderChange = async (newValue) => {
        addDocumentForm.current.folderId = newValue;

        try {
            const folder = await getFolder(newValue);

            let currentGroupOptions = null;
            if(folder){
                if(folder.useGroups){
                    let groups = [];
                    let group_any = {value: '~any;', label: 'Sem grupo (disponível para todos)'};
                    let folderGroups = folder.groups;
                    folderGroups.forEach(group => {
                        if(group.id !== '&all' && group.id !== '&none') groups.push({value: group.id, label: group.name});
                    });
                    groups.unshift(group_any);
                    currentGroupOptions = groups;
                }
                
            }
            setGroupOptions(currentGroupOptions);

            addDocumentForm.current.groupId = '';

            checkSubmitButtonDisabled();
        } catch (error) {
            console.log(error);
        }
    };

    const handleSubmit = () => {
        const { companyId, folderId, groupId, templateId, documentName, documentUrl } = addDocumentForm.current;
        
        setLoading(true);

        const showError = (message) => {
            toast(message);
            setLoading(false);
        };

        let usesGroups = getFolder(projects, folderId).useGroups;
        if((usesGroups && !groupId)) return showError('Selecione um grupo.');

        let formData = new FormData();

        if(selectedFolder && !activeUserIsOperator){
            formData.append('sent', true);
        }
        formData.append('client', companyId);
        formData.append('project', folderId !== 'none' ? folderId : '');
        formData.append('template', templateId);
        formData.append('name', documentName);
        formData.append('groupId', groupId !== '~any;' ? groupId : '');

        if(uploadTypeUrl){
            if(documentUrl){
                formData.append('link', documentUrl);
            } else {
                return showError('Insira um link.');
            }
        } else {
            if(acceptedFilesRef.current.length > 0){
                toast('Verificando arquivos... isso pode levar alguns segundos...');
                formData.append('file', acceptedFilesRef.current[0]);
            } else {
                return showError('Escolha um arquivo');
            }
        }

        axios.post(SERVER_PATH + '/data/operator/docs/save/save', formData, {headers: {'Content-Type': 'multipart/form-data'}})
        .then(res => {
            clearForm();
            handleClose();
            setLoading(false);
            toast(`Sucesso!${activeUserIsOperator ? ' O documento foi lançado nos contratos pendentes.' : ' O documento foi gravado.'}`);
            if(addDocument){
                let newDocument = res.data.newDocument;
                if(newDocument) addDocument(newDocument);
            }
            if(actionCallback) actionCallback();
        })
        .catch(err => {
            console.log(err);
            setLoading(false);
        });
    }
    const clearForm = () => {
        addDocumentForm.current = defaultAddDocumentForm;
        setGroupOptions(null);
        setFolderOptions(null);
    };

    const checkSubmitButtonDisabled = () => {
        const { companyId, folderId, groupId, templateId, documentName, documentUrl } = addDocumentForm.current;
        if(!companyId || !folderId || (groupOptions && !groupId) || !templateId || !documentName || (uploadTypeUrl && !documentUrl)){
            return setSubmitButtonDisabled(true);
        }
        setSubmitButtonDisabled(false);
    };

    const handleClose = () => {
        hideAddDocumentView();
    };

    return (
        <Window open={addDocumentViewOpen} onClose={handleClose}
            title="Adicionar documento"
            handleSubmitButtonClick={handleSubmit}
            submitButtonDisabled={loading || submitButtonDisabled}
            submitButtonText="Confirmar"
        >
            {
                (loading || loadingPage)
                ? <CenteredCircularProgress />
                :
                <Box mb={3}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} container spacing={1}>
                            {
                                !selectedFolder &&
                                <>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <CompanyField addDocumentForm={addDocumentForm} projects={projects} handleSelectedCompanyChange={handleSelectedCompanyChange} />
                                    </Grid>
                                    {
                                        folderOptions &&
                                        <Grid item xs={12} sm={6} md={4} container spacing={1} alignItems="center">
                                            {
                                                loadingProject &&
                                                <Grid item>
                                                    <CircularProgress size={20} />
                                                </Grid>
                                            }
                                            <Grid item xs>
                                                <FolderField addDocumentForm={addDocumentForm} folderOptions={folderOptions} handleSelectedFolderChange={handleSelectedFolderChange} />
                                            </Grid>
                                        </Grid>
                                    }
                                </>
                            }
                            {
                                groupOptions &&
                                <Grid item xs={12} sm={6} md={4}>
                                    <GroupField addDocumentForm={addDocumentForm} groupOptions={groupOptions} checkSubmitButtonDisabled={checkSubmitButtonDisabled} />
                                </Grid>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <DocumentNameField addDocumentForm={addDocumentForm} checkSubmitButtonDisabled={checkSubmitButtonDisabled} />
                        </Grid>
                        <Grid item xs={12} container spacing={1} justifyContent="center">
                            <Grid item>
                                <Button
                                    variant={uploadTypeUrl ? 'outlined' : 'text'}
                                    color={uploadTypeUrl ? 'primary' : 'inherit'}
                                    startIcon={<LinkIcon />}
                                    onClick={() => setUploadTypeUrl(!uploadTypeUrl)}
                                >Link</Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant={uploadTypeUrl ? 'text' : 'outlined'}
                                    color={uploadTypeUrl ? 'inherit' : 'primary'}
                                    startIcon={<AttachFileIcon />}
                                    onClick={() => setUploadTypeUrl(!uploadTypeUrl)}
                                >Arquivo</Button>
                            </Grid>
                        </Grid>
                        {
                            uploadTypeUrl
                            ?
                            <Grid item xs={12}>
                                <DocumentUrlField addDocumentForm={addDocumentForm} checkSubmitButtonDisabled={checkSubmitButtonDisabled} />
                            </Grid>
                            :
                            <Grid item xs={12}>
                                <Dropzone
                                    acceptedFilesRef={acceptedFilesRef}
                                    accept={{
                                        'application/pdf': ['.pdf'],
                                        'application/msword': ['.doc'],
                                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
                                    }}
                                />
                            </Grid>
                        }
                    </Grid>
                </Box>
            }
        </Window>
    );
};

export default AddDocumentWindow;