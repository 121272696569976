//MRT Imports
import {
    MaterialReactTable,
    MRT_TablePaper,
    useMaterialReactTable,
} from 'material-react-table';

//Import Material React Table Translations
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';

import { darken } from '@mui/material';

const getMuiTableHeadCellProps = (props, customMuiTableHeadCellProps) => {
    if(customMuiTableHeadCellProps) return customMuiTableHeadCellProps(props);
    return ({
        sx: {
            background: 'linear-gradient(#9e9e9e, #bdbdbd)',
        },
    });
};

const getMuiTableBodyRowProps = (props, customMuiTableBodyRowProps) => {
    if(customMuiTableBodyRowProps) return customMuiTableBodyRowProps(props);
    return ({
        hover: false,
        sx: {
            backgroundColor: '#fbfbfc',
            transition: 'background-color .2s ease-out',
            '&:hover': {
                backgroundColor: darken('#fbfbfc', 0.03),
            },
        }
    });
};

const HiddenToolbar = ({ render, table }) => {
    if(render) return render(table);
    return null;
};

const SpeedDial = ({ render, table }) => {
    if(render) return render(table);
    return null;
};

const Table = ({
    columns,
    data,
    enableColumnActions,
    enableColumnFilterModes,
    enableColumnOrdering,
    enableColumnPinning,
    enableColumnResizing,
    enableEditing,
    enableFacetedValues,
    enableGrouping,
    enableHiding = false,
    enablePagination,
    enableRowSelection,
    enableRowVirtualization,
    globalFilter,
    initialStateColumnPinningLeft = [],
    initialStateDensity,
    isLoading,
    manualFiltering,
    manualPagination,
    muiTableBodyRowProps,
    muiTableHeadCellProps,
    onGlobalFilterChange,
    onPaginationChange,
    pageCount,
    pagination,
    renderDetailPanel,
    renderEmptyRowsFallback,
    renderHiddenToolbar,
    renderRowActions,
    renderRowActionMenuItems,
    renderSpeedDial,
    renderTopToolbar,
    rowCount,
    rowsPerPageOptions,
}) => {

    const state = {
        showSkeletons: isLoading
    };
    if(globalFilter) state.globalFilter = globalFilter;
    if(pagination) state.pagination = pagination;

    const tableOptions = {}
    
    const table = useMaterialReactTable({
        columns,
        data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
        displayColumnDefOptions: {
            'mrt-row-actions': {
                grow: false,
                size: 45,
                muiTableHeadCellProps: {
                    sx: {
                        background: 'linear-gradient(#9e9e9e, #bdbdbd)',
                        padding: '12px 2px 0'
                    }
                },
                muiTableBodyCellProps: {
                    sx: {
                        padding: '8px 2px',
                    }
                }
            },
            'mrt-row-expand': {
                grow: false,
                size: 40,
                muiTableHeadCellProps: {
                    sx: {
                        background: 'linear-gradient(#9e9e9e, #bdbdbd)',
                        padding: '12px 2px 0'
                    }
                },
                muiTableBodyCellProps: {
                    sx: {
                        padding: '8px 2px'
                    }
                }
            }
        },
        enableBottomToolbar: enablePagination,
        enableColumnActions,
        enableColumnFilterModes,
        enableColumnOrdering,
        enableColumnPinning,
        enableColumnResizing,
        enableDensityToggle: false,
        enableEditing,
        enableFacetedValues,
        enableFullScreenToggle: false,
        enableGrouping,
        enableHiding,
        enablePagination,
        enableRowActions: !!renderRowActions || !!renderRowActionMenuItems,
        enableRowSelection,
        enableRowVirtualization,
        enableStickyHeader: true,
        enableTopToolbar: !renderTopToolbar,
        initialState: {
            density: initialStateDensity,
            showColumnFilters: false,
            showGlobalFilter: true,
            columnPinning: {
                left: ['mrt-row-expand', 'mrt-row-select', ...initialStateColumnPinningLeft],
                right: ['mrt-row-actions'],
            },
            pagination: {
                pageSize: 50
            },
        },
        manualFiltering,
        manualPagination,
        onPaginationChange,
        pageCount,
        paginationDisplayMode: 'pages',
        positionActionsColumn: 'last',
        positionToolbarAlertBanner: 'bottom',
        rowCount,
        state,
        muiSearchTextFieldProps: {
            fullWidth: true,
            variant: 'standard',
            InputProps: {
                disableUnderline: true
            }
        },
        muiPaginationProps: {
            color: 'primary',
            rowsPerPageOptions: rowsPerPageOptions || [10, 20, 30],
            shape: 'rounded',
            variant: 'outlined',
        },
        muiTablePaperProps: ({ table }) => ({
            elevation: table.getState().isFullScreen ? 0 : 1,
            sx: {
                borderRadius: table.getState().isFullScreen ? undefined : '8px',
                height: `calc(100% - 56px)`,

                display: 'flex',
                flexDirection: 'column'
            },
            style: {
                zIndex: table.getState().isFullScreen ? 1201 : undefined // drawer (1200) + 1
            }
        }),
        muiTableContainerProps: {
            sx: {
                flex: 1
            }
        },
        muiTopToolbarProps: {
            sx: {
                backgroundColor: '#eef2f5'
            }
        },
        muiTableHeadCellProps: (props) => getMuiTableHeadCellProps(props, muiTableHeadCellProps),
        muiTableBodyRowProps: (props) => getMuiTableBodyRowProps(props, muiTableBodyRowProps),
        muiFilterTextFieldProps: {
            sx: {
                minWidth: '0px'
            }
        },
        muiTableBodyCellProps: {
            sx: {
                whiteSpace: 'normal',
            }
        },
        muiBottomToolbarProps: {
            sx: {
                backgroundColor: '#eef2f5'
            }
        },
        renderDetailPanel,
        renderEmptyRowsFallback,
        renderRowActions,
        renderRowActionMenuItems,
        renderTopToolbar,
        localization: MRT_Localization_PT_BR
    });

    if(onGlobalFilterChange) table.options.onGlobalFilterChange = onGlobalFilterChange;

    if(renderTopToolbar){
        return (
            <>
                {renderTopToolbar({table})}
                <MRT_TablePaper table={table} />
                <SpeedDial render={renderSpeedDial} table={table} />
                <HiddenToolbar render={renderHiddenToolbar} table={table} />
            </>
        )
    };

    return (
        <MaterialReactTable table={table} />
    );
};

export default Table;