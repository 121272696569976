import { OperatorProvider } from './context/OperatorContext';
import { TasksProvider } from '../../context/TasksContext';
import Main from './Main';

function Operator(){
    return (
        <OperatorProvider>
            <TasksProvider>
                <Main />
            </TasksProvider>
        </OperatorProvider>
    );
}

export default Operator;