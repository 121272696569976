const date = {
    createEffect: ({ effectSettings, newEffectId, templateEffects }) => {
        const newEffect = {
            id: newEffectId,
            type: effectSettings.type,
            question: effectSettings.question || effectSettings.label || '',
            label: effectSettings.label || effectSettings.question
        };
        const foundEffect = templateEffects.find(templateEffect => templateEffect.type === effectSettings.type && templateEffect.question === effectSettings.question);
        return { foundEffect, newEffect };
    },
    newEffectWindowHandleEffectTypeChange: () => {
        const data = {
            question: ''
        };
        return data;
    },
    checkValidation: (data) => {
        let questionValid = false;
        if(data){
            questionValid = true;
            const { value } = data;
            if(!value) questionValid = false;
        }
        return questionValid;
    }
};

export {
    date
}