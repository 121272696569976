import { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Divider } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';
import ClearIcon from '@mui/icons-material/Clear';
import CommentIcon from '@mui/icons-material/Comment';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import AlertDialog from './AlertDialog';
import DocumentFilesList from './DocumentFilesList';
import MultilineText from './MultilineText';
import PopOver from './PopOver';
import Tooltip from './Tooltip';
import AddDocumentVersionPopOver from './AddDocumentVersionPopOver';
import moment from 'moment';
import { getDocumentVersionLink, getUserNameById } from '../utils/common';
import { REMOTE_SERVER_PATH, SERVER_PATH } from '../utils/constants';
import { useAppCtxAPI, useAppCtxActiveUser, useAppCtxLoading } from '../context/SystemContext';
import { useDocumentsAPI, useDocumentsCtxHistoryWindow } from '../context/DocumentsContext';

const classes = {
    timeline: {
        padding: '4px',
        '& li::before': {
            flex: 0,
            padding: 0
        }
    },
    timelineItem: {
        // borderRadius: 4,
        // transition: 'background-color .2s ease-out',
        // WebkitTransition: 'background-color .2s ease-out',
        // MozTransition: 'background-color .2s ease-out',
        // msTransition: 'background-color .2s ease-out',
        // '&:hover': {
        //     backgroundColor: theme.palette.action.hover
        // }
    },
    timelineItemContent: {
        position: 'relative',
        padding: '4px 2px 4px 8px'
    },
    link: {
        color: theme => theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
};

const Em = styled('em')(unstable_styleFunctionSx);

const HistoryPopOver = ({
    handleReadComment,
    updateDocument,
    users,
}) => {
    const { setLoading, toast, updateOneManagers } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const loading = useAppCtxLoading();
    const { setHistoryWindowOpen, setHistoryWindowSelectedDocument, showDocumentFormView } = useDocumentsAPI();
    const {
        documentHistoryViewActiveUserIsManager: activeUserIsManager,
        documentHistoryViewActiveUserIsOperator: activeUserIsOperator,
        documentHistoryViewFolderId,
        historyWindowAnchorPosition, historyWindowOpen, historyWindowSelectedDocument: selectedDocument
    } = useDocumentsCtxHistoryWindow();
    const [addDocumentVersionPopOverOpen, set_addDocumentVersionPopOverOpen] = useState(false);
    const [addDocumentVersionPopOverAnchorPosition, set_addDocumentVersionPopOverAnchorPosition] = useState(null);
    const [selectedDocumentFiles, setSelectedDocumentFiles] = useState(null);
    const [selectedVersionIndex, setSelectedVersionIndex] = useState(-1);
    const [deleteDocumentVersionDialogOpen, setDeleteDocumentVersionDialogOpen] = useState(false);
    const [deleteDocumentVersionESignatureDialogOpen, setDeleteDocumentVersionESignatureDialogOpen] = useState(false);

    useEffect(() => {
        if(selectedDocument){
            // set_record(selectedDocument);
            let files;
            try {
                files = selectedDocument.files;
                if(files.length === 0) files = null;
            } catch (error) {
                files = null;
            }
            setSelectedDocumentFiles(files);
        }
    }, [historyWindowOpen, selectedDocument]);

    const handle_docFormViewOpenButtonClick = () => {
        showDocumentFormView(selectedDocument);
        handleClose();
    };

    const handleDeleteVersionButtonClick = (versionId) => {
        const versions = selectedDocument.documents;
        const versionIndex = versions.findIndex(version => version._id === versionId);
        setSelectedVersionIndex(versionIndex);
        const currentVersion = selectedDocument.documents[selectedVersionIndex];
        if(currentVersion){
            if(currentVersion.eSignature && currentVersion.eSignatureStatus !== 'signed'){
                setDeleteDocumentVersionESignatureDialogOpen(true);
            } else {
                setDeleteDocumentVersionDialogOpen(true);
            }
        }
    };

    const handleDeleteVersionAction = async (shouldDeleteESignatureDocument = false) => {
        if(selectedVersionIndex !== -1){
            const currentVersion = selectedDocument.documents[selectedVersionIndex];
            if(currentVersion){
                setLoading(true);
                if(shouldDeleteESignatureDocument){
                    const postData = {
                        actionUserName: activeUser.fullName,
                        projectId: selectedDocument.project,
                        currentDocument: selectedDocument,
                        versionIndex: selectedVersionIndex,
                        documentToken: currentVersion.eSignaturePlatformId,
                        eSignaturePlatform: currentVersion.eSignaturePlatform,
                        shouldDeleteDocumentVersion: true
                    };
                    await axios.post(SERVER_PATH + '/data/documents/sign/delete', postData)
                    .then(res => {
                        const updatedDocument = res.data;
                        updateDocument(updatedDocument);
                        setHistoryWindowSelectedDocument(updatedDocument);
                        toast(`O documento enviado para assinatura foi excluído`);
                    })
                    .catch(err => {
                        toast('Não foi possível excluir o documento. Tente novamente mais tarde.');
                    });
                } else {
                    const versions = selectedDocument.documents;
                    const documents = [...versions.slice(0, selectedVersionIndex), ...versions.slice(selectedVersionIndex + 1)];
                    try {
                        await updateOneManagers('Documents', selectedDocument._id, {documents}, documentHistoryViewFolderId);
                        const updatedDocument = {...selectedDocument, documents};
                        updateDocument(updatedDocument);
                        setHistoryWindowSelectedDocument(updatedDocument); 
                    } catch (error) {
                        console.log(error);
                        toast(error.response.data);
                    }
                }
                setLoading(false);
            }
        }
    };

    const handleDeleteVersionConfirm = () => {
        setDeleteDocumentVersionDialogOpen(false);
        handleDeleteVersionAction(false);
    };

    const handleDeleteVersionDialogClose = () => {
        setDeleteDocumentVersionDialogOpen(false);
    };

    const handleDeleteVersionWithSignatureConfirm = () => {
        setDeleteDocumentVersionESignatureDialogOpen(false);
        handleDeleteVersionAction(true);
    };

    const handleDeleteVersionWithSignatureDialogClose = () => {
        setDeleteDocumentVersionESignatureDialogOpen(false);
    };

    const Version = ({content, document, dotColor, index, last, itemType}) => {

        const handleClick = () => {
            handleDeleteVersionButtonClick(document._id);
        };

        return (
            <TimelineItem sx={classes.timelineItem}>
                <TimelineSeparator>
                    <TimelineDot color={dotColor || 'grey'} />
                    {
                        !last &&
                        <TimelineConnector />
                    }
                </TimelineSeparator>
                <TimelineContent sx={classes.timelineItemContent}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs>
                            <Box mb={1}>
                                {content}
                            </Box>
                        </Grid>
                        {
                            (document && itemType !== 'comment' && document.eSignatureStatus !== 'signed' && (activeUserIsOperator || (activeUserIsManager && index !== 0))) &&
                            <Grid item>
                                <Tooltip text="Excluir documento">
                                    <IconButton size="small" edge="end" onClick={handleClick}>
                                        <ClearIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        }
                    </Grid>
                </TimelineContent>
            </TimelineItem>
        )
    };
    
    const VersionSentAtBy = ({timestamp, userId}) => (
        <Box style={{fontSize: 10}}>
            <em>{moment(timestamp).format('L') !== '31/12/1969' ? moment(timestamp).format('L LT') : ''}{userId && ` - ${getUserNameById(users, userId, true)}`}</em>
        </Box>
    );

    const Versions = () => {
        const getDotColor = (version) => {
            if(version.fileFormat){
                if(version.serverFile){
                    let fileExtension = version.link.split('.').pop();
                    if(fileExtension === 'pdf'){
                        return 'secondary';
                    } else if(fileExtension === 'docx'){
                        return 'primary';
                    }
                } else {
                    if(version.fileFormat === 'pdf') return 'secondary';
                    let documentLink = version.link || version.googleDoc || '';
                    let fileExtension = documentLink.split('.').pop();
                    // let fileExtension = version.googleDoc ? version.link.split('.').pop() : version.link.split('.').pop();
                    if(fileExtension === 'pdf'){
                        return 'secondary';
                    }
                    return 'primary';
                }
            } else {
                if(version.link){
                    let fileExtension = version.link.split('.').pop();
                    if(fileExtension === 'pdf'){
                        return 'secondary';
                    } else if(fileExtension === 'docx'){
                        return 'primary';
                    }
                    return 'grey';
                } else if(version.googleDoc){
                    let fileExtension = version.googleDoc.split('.').pop();
                    if(fileExtension === 'pdf'){
                        return 'secondary';
                    }
                    return 'primary';
                }
            }
            return 'grey';
        }
        const isValidHttpUrl = (string) => {
            let url;
            
            try {
              url = new URL(string);
            } catch (_) {
              return false;  
            }
          
            return url.protocol === "http:" || url.protocol === "https:";
        };
        const textTypes = {
            download: (version) => (
                <Link
                    href={getDocumentVersionLink(version, SERVER_PATH)}
                    target="_blank"
                    underline="hover">
                    {version.name || <em>Primeira versão</em>}
                </Link>
            ),
            googleDoc: (version) => (
                <Link
                    href={getDocumentVersionLink(version, SERVER_PATH)}
                    target="_blank"
                    underline="hover">
                    {version.name || <em>Primeira versão</em>}
                </Link>
            ),
            link: (version) => {
                const documentVersionLink = getDocumentVersionLink(version, REMOTE_SERVER_PATH);
                if(isValidHttpUrl(documentVersionLink)){
                    return (
                        <Link
                            href={documentVersionLink}
                            target="_blank"
                            underline="hover">
                            {version.name || <em>Primeira versão</em>}
                        </Link>
                    );
                }
                return (version.name || <em>Primeira versão</em>);
            },
        };

        const getNoHtmlComment = (comment) => {
            let noHtmlComment = comment.replace(/<[^>]+>/g, '').replace(/\n/g, ' ');
            if(noHtmlComment.length > 200) noHtmlComment = `${noHtmlComment.substring(0, 200)}...`;
            return noHtmlComment;
        };

        if(selectedDocument){
            let documentVersions = selectedDocument.documents || [];
            if(!Array.isArray(documentVersions)) documentVersions = [documentVersions];
            const documentComments = selectedDocument.comments || [];
            const mappedDocumentComments = documentComments.map(comment => ({...comment, type: 'comment', timestamp: comment.createdAt}));
            const historyItems = [...mappedDocumentComments, ...documentVersions].sort((a, b) => a.timestamp > b.timestamp ? 1 : (a.timestamp < b.timestamp ? -1 : 0));
            return historyItems.map((item, index) => (
                <Version
                    key={index}
                    index={index}
                    last={historyItems.length === index + 1}
                    dotColor={getDotColor(item)}
                    document={item}
                    itemType={item.type}
                    content={
                        <Box>
                            <Divider />
                            {
                                item.type === 'comment'
                                ?
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <CommentIcon fontSize="small" />
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="body2">{item.createdBy ? getUserNameById(users, item.createdBy, true) : 'Alguém'}{item.auto ? ` ${item.auto}` : ''}{item.comment ? <span>{item.auto ? ' e comentou' : ''}: “<Em sx={classes.link} onClick={() => handleReadComment(item)}>{getNoHtmlComment(item.comment)}</Em>”</span> : ''}</Typography>
                                        <Typography style={{fontSize: 10}}><em>{moment(item.timestamp).format('L') !== '31/12/1969' ? moment(item.timestamp).format('L LT') : ''}</em></Typography>
                                    </Grid>
                                </Grid>
                                :
                                <>
                                    {
                                        item.type !== 'text' &&
                                        <Typography variant="body2">
                                            {textTypes[item.type || 'googleDoc'](item)}
                                        </Typography>
                                    }
                                    {
                                        item.text &&
                                        <Box>
                                            <Typography variant="body2">Comentário do(a) revisor(a):</Typography>
                                            <Typography variant="body2">
                                                <MultilineText text={item.text} />
                                            </Typography>
                                        </Box>
                                    }
                                    <VersionSentAtBy
                                        timestamp={index === 0 && !item.timestamp ? selectedDocument.sentOn : item.timestamp}
                                        userId={item.user || (index === 0 ? selectedDocument.sentBy : null)}
                                    />
                                </>
                            }
                        </Box>
                    }
                />
            ));
        }
        return null;
    };

    const handle_uploadExecutedAgreementClick = (event) => {
        set_addDocumentVersionPopOverAnchorPosition({
            left: event.clientX - 2,
            top: event.clientY - 4,
        });
        set_addDocumentVersionPopOverOpen(true);
    };

    const handleClose = () => {
        setHistoryWindowOpen(false);
    };

    return (
        <>
            <PopOver open={historyWindowOpen} onClose={handleClose} anchorPosition={historyWindowAnchorPosition}
                transformOrigin={{vertical: 'center', horizontal: 'left'}}
                ellipsisTitle={selectedDocument?.name}
            >
                <Box>
                    <Timeline align="left" sx={classes.timeline}>
                        {
                            selectedDocument?.form !== '~empty;' &&
                            <Version
                                timestamp={selectedDocument?.timestamp} icon={<QuestionAnswerIcon />}
                                content={
                                    <Box>
                                        <Grid container spacing={1}>
                                            <Grid item>
                                                <IconButton size="small" onClick={() => handle_docFormViewOpenButtonClick(selectedDocument)}>
                                                    <QuestionAnswerIcon />
                                                </IconButton>
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="body2">Formulário</Typography>
                                                <VersionSentAtBy
                                                    timestamp={selectedDocument?.timestamp} userId={selectedDocument?.user}
                                                />
                                            </Grid>
                                        </Grid>
                                        {
                                            selectedDocumentFiles &&
                                            <DocumentFilesList doc={selectedDocument} />
                                        }
                                    </Box>
                                }
                            />
                        }
                        {
                            (activeUserIsOperator || (selectedDocument?.sent && !selectedDocument?.deleted)) &&
                            <Versions />
                        }
                    </Timeline>
                </Box>
                {
                    (activeUserIsOperator || (selectedDocument?.sent && !selectedDocument?.deleted)) &&
                    <Grid container justifyContent="center">
                        <Grid item>
                            <IconButton
                                disabled={loading}
                                color="primary"
                                onClick={(e) => handle_uploadExecutedAgreementClick(e)}
                                size="large">
                                {loading ? <CircularProgress /> : <NoteAddIcon />}
                            </IconButton>
                        </Grid>
                    </Grid>
                }

                <AddDocumentVersionPopOver
                    open={addDocumentVersionPopOverOpen} onClose={() => set_addDocumentVersionPopOverOpen(false)} anchorPosition={addDocumentVersionPopOverAnchorPosition}
                    record={selectedDocument} setSelectedDocument={setHistoryWindowSelectedDocument}
                    updateDocument={updateDocument}
                />
            </PopOver>
            <AlertDialog
                open={deleteDocumentVersionDialogOpen} onClose={handleDeleteVersionDialogClose}
                text={`Tem certeza que quer excluir esse documento? Essa ação é irreversível.`}
                okButtonOnClick={handleDeleteVersionConfirm}
                cancelButtonOnClick={handleDeleteVersionDialogClose}
            />
            <AlertDialog
                open={deleteDocumentVersionESignatureDialogOpen} onClose={handleDeleteVersionWithSignatureDialogClose}
                text={`Tem certeza que quer excluir esse documento? Isso excluirá também o processo de assinatura eletrônica em andamento. Essa ação é irreversível.`}
                okButtonOnClick={handleDeleteVersionWithSignatureConfirm}
                cancelButtonOnClick={handleDeleteVersionWithSignatureDialogClose}
            />
        </>
    );
};

export default HistoryPopOver;