import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import ActiveProjectsStatusBox from './ActiveProjectsStatusBox/ActiveProjectsStatusBox';
import ActiveProjectsWorkTypeBoxes from './ActiveProjectsWorkTypeBoxes/ActiveProjectsWorkTypeBoxes';
import AlertDialog from '../../../../../components/AlertDialog';

const ActiveProjectView = (props) => {
    const { open, setOpen, activeProjectViewSelectedBox, setActiveProjectViewSelectedBox, activeProjectStatusOptions, activeProjectTypes, handleAddActiveProjectConfirm, handleDeleteActiveProjectConfirm } = props;
    const defaultActiveProjectState = {
        name: '',
        nameError: false,
        notes: '',
        workType: 'atendimento',
        status: 'accepted'
    };
    const [activeProjectState, setActiveProjectState] = useState(defaultActiveProjectState);
    const [deleteActiveProjectDialogOpen, setDeleteActiveProjectDialogOpen] = useState(false);
    const nameInputRef = useRef(null);

    const getActiveProjectState = () => {
        if(open && activeProjectViewSelectedBox){
            return setActiveProjectState({...defaultActiveProjectState, ...activeProjectViewSelectedBox});
        }
        setActiveProjectState(defaultActiveProjectState);
    };
    
    useEffect(() => {
        getActiveProjectState();
    }, [open, activeProjectViewSelectedBox]);

    useEffect(() => {
        if(open){
            if(nameInputRef.current){
                nameInputRef.current.focus();
            }
        }
    }, [open]);

    const handleSubmit = async () => {
        if(!activeProjectState.name) return setActiveProjectState(prevState => ({...prevState, nameError: true}));
        setActiveProjectState(prevState => ({...prevState, nameError: false}));
        handleAddActiveProjectConfirm(activeProjectState);
        handleClose();
    };

    const handleNameInputKeyUp = (e) => {
        if(e.key === 'Enter'){
            handleSubmit();
        }
    };

    const handleDeleteClick = async () => {
        setDeleteActiveProjectDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        setDeleteActiveProjectDialogOpen(false);
        handleDeleteActiveProjectConfirm(activeProjectState);
        handleClose();
    };

    const handleClose = () => {
        setActiveProjectViewSelectedBox(null);
        setOpen(false);
        // hideAddDocumentAlertView();
    };
    
    return (
        <>
            <Dialog open={open} onClose={handleClose} keepMounted>
                <DialogTitle noWrap>{activeProjectState._id ? activeProjectState.name : 'Novo projeto'}</DialogTitle>
                <DialogContent>
                    <Box mb={2}>
                        <TextField
                            variant="standard"
                            fullWidth
                            inputProps={{ref: nameInputRef}}
                            label="Nome"
                            placeholder="Nome do projeto"
                            value={activeProjectState.name}
                            onChange={(e) => setActiveProjectState(prevState => ({...prevState, name: e.target.value}))}
                            onKeyUp={handleNameInputKeyUp}
                        />
                        <Collapse in={activeProjectState.nameError}>
                            <FormHelperText error>Gentileza especificar um nome para o projeto.</FormHelperText>
                        </Collapse>
                    </Box>
                    <Box mb={2}>
                        <Grid container spacing={1}>
                            <ActiveProjectsWorkTypeBoxes
                                activeProjectTypes={activeProjectTypes}
                                onClick={(activeProjectType) => setActiveProjectState(prevState => ({...prevState, workType: activeProjectType.value}))}
                                style={(activeProjectType) => !activeProjectState.workType || activeProjectState.workType === activeProjectType.value || activeProjectState.workType === 'all' ? ({border: '2px solid #313330', opacity: 1}) : undefined} // ({ border: '2px solid transparent', opacity: 0.6 })}
                                textStyle={(activeProjectType) => !activeProjectState.workType || activeProjectState.workType === activeProjectType.value || activeProjectState.workType === 'all' ? ({opacity: 1}) : undefined} // ({ opacity: 0.6 })}
                            />
                        </Grid>
                    </Box>
                    <Box mb={2}>
                        <Grid container spacing={1} alignItems="center">
                            {
                                activeProjectStatusOptions.map(statusOption => (
                                    <Grid key={statusOption.value}>
                                        <ActiveProjectsStatusBox
                                            activeProjectStatus={statusOption}
                                            onClick={() => setActiveProjectState(prevState => ({...prevState, status: statusOption.value}))}
                                            style={(activeProjectStatus) => !activeProjectState.status || activeProjectState.status === activeProjectStatus.value || activeProjectState.status === 'all' ? ({border: '2px solid #313330', opacity: 1}) : undefined} // ({ border: '2px solid transparent', opacity: 0.6 })}
                                            textStyle={(activeProjectStatus) => !activeProjectState.status || activeProjectState.status === activeProjectStatus.value || activeProjectState.status === 'all' ? ({opacity: 1}) : undefined} // ({ opacity: 0.6 })}
                                        />
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Box>
                    <Box>
                        <TextField
                            variant="standard"
                            multiline fullWidth
                            label="Anotações"
                            placeholder="Anotações sobre o projeto"
                            value={activeProjectState.notes}
                            onChange={(e) => setActiveProjectState(prevState => ({...prevState, notes: e.target.value}))}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="text"
                        onClick={handleClose}
                    >Cancelar</Button>
                    {
                        activeProjectState._id &&
                        <Button
                            variant="outlined" color="primary"
                            onClick={handleDeleteClick}
                            startIcon={<DeleteIcon />}
                        >Excluir</Button>
                    }
                    <Button
                        variant="contained" color="primary"
                        onClick={handleSubmit}
                        endIcon={<CheckIcon />}
                    >Ok</Button>
                </DialogActions>
            </Dialog>
            <AlertDialog
                open={deleteActiveProjectDialogOpen} onClose={() => setDeleteActiveProjectDialogOpen(false)}
                text="Você quer excluir esse projeto? Essa ação é irreversível."
                okButtonOnClick={handleDeleteConfirm}
            />
        </>
    );
};

export default ActiveProjectView;