import axios from 'axios';
import moment from 'moment';
import { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import { ERROR_MESSAGE_CHANGES_UNDONE, ERROR_MESSAGE_UNKNOWN, SERVER_PATH } from '../utils/constants';
import { useAppCtxAPI, useAppCtxActiveUser } from './SystemContext';
import { useSocketCtxAPI, useSocket, useSocketCtxConnectedAt } from './SocketContext';
import { useClientCtxActiveClient } from './ClientContext';
import { useOperatorCtxOperators, useOperatorCtxProjects } from '../pages/Operator/context/OperatorContext';
import CompleteTaskWindow from '../pages/Operator/Tasks/CompleteTaskDialog';
import TaskWindow from '../pages/Operator/Tasks/TaskWindow';
import { v4 as uuidv4 } from 'uuid';
import mongoose from 'mongoose';

const TasksCtxAPI = createContext();

const TasksCtxActiveProjectBoards = createContext();
const TasksCtxActiveUserTasks = createContext();
const TasksCtxCompleteTaskView = createContext();
const TasksCtxDragDisabled = createContext();
const TasksCtxFilters = createContext();
const TasksCtxNewTasks = createContext();
const TasksCtxSelectedTask = createContext();
const TasksCtxTasks = createContext();
const TasksCtxTasksPendingDocuments = createContext();
const TasksCtxTaskView = createContext();
const TasksCtxTeamTasks = createContext();

const defaultFiltersValue = {client: null, project: ''};
const defaultState = {
    activeProjectBoards: [],
    activeUserTasks: null,
    completeTaskViewOpen: false,
    completeTaskViewSelectedTask: null,
    dragDisabled: false,
    filters: defaultFiltersValue,
    newTasks: null,
    nextActiveUserTask: null,
    tasks: null,
    tasksPendingDocuments: {},
    taskViewOpen: false,
    taskViewSelectedTask: null,
    tasksTeam: null,
    teamTasks: null,
};

const reducer = (state, action) => {
    const { payload, type } = action;

    switch (type) {
        case 'HIDE COMPLETE TASK VIEW':
            return { ...state, completeTaskViewOpen: false };
        case 'HIDE TASK VIEW':
            return { ...state, taskViewOpen: false };
        case 'SET STATE':
            let newValue = payload.value;
            if(typeof newValue === 'function'){
                newValue = newValue(state[payload.key]);
            }
            return { ...state, [payload.key]: newValue };
        case 'SHOW COMPLETE TASK VIEW':
            return {
                ...state,
                completeTaskViewOpen: true,
                completeTaskViewSelectedTask: payload.clickedTask,
                completeTaskViewSelectedTaskUpdatedAt: new Date()
            };
        case 'SHOW TASK VIEW':
            return {
                ...state,
                taskViewOpen: !state.taskViewOpen,
                taskViewSelectedTask: payload.clickedTask,
                taskViewSelectedTaskUpdatedAt: new Date()
            };
        default: return state;
    }
};

const TasksProvider = ({children}) => {
    const { floatingAlert, toast } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const activeClient = useClientCtxActiveClient();
    const { setShouldUpdateTasks } = useSocketCtxAPI();
    const { createSocketConnection, shouldUpdateTasks } = useSocket();
    const socket = createSocketConnection();
    const socketConnectedAt = useSocketCtxConnectedAt();
    const operators = useOperatorCtxOperators();
    const projects = useOperatorCtxProjects();
    
    const activeUserBoardsIds = [
        'activeUserHighPriorityTasks',
        'activeUserMediumPriorityTasks',
        'activeUserLowPriorityTasks',
        'activeUserUnreadTasks'
    ];
    const activeUserBoardsPriorityLevels = {
        activeUserHighPriorityTasks: 2,
        activeUserMediumPriorityTasks: 1,
        activeUserLowPriorityTasks: 0,
        activeUserUnreadTasks: 1
    };
    
    const shouldOrganizeBoards = useRef(true);

    const [state, dispatch] = useReducer(reducer, {...defaultState});

    const bulkUpdateTasks = (updates, errorCallback, successCallback) => {
        axios.post(SERVER_PATH + `/data/tasks/order`, { updates })
        .then(res => {
            if(successCallback) successCallback();
        })
        .catch(err => {
            toast(ERROR_MESSAGE_CHANGES_UNDONE);
            if(errorCallback) errorCallback();
        });
    }

    const deleteTeamTask = (updatedBoardId, deletedTaskIndex, deletedTaskId) => {
        setTeamTasks(prevState => {
            const updatedBoardIndex = prevState.findIndex(board => board._id === updatedBoardId);
            const updatedBoard = prevState[updatedBoardIndex];
            if(deletedTaskIndex === null) deletedTaskIndex = updatedBoard.tasks.findIndex(task => task._id === deletedTaskId);
            return [
                ...prevState.slice(0, updatedBoardIndex),
                {
                    ...prevState[updatedBoardIndex],
                    tasks: [
                        ...prevState[updatedBoardIndex].tasks.slice(0, deletedTaskIndex),
                        ...prevState[updatedBoardIndex].tasks.slice(deletedTaskIndex + 1)
                    ]
                },
                ...prevState.slice(updatedBoardIndex + 1)
            ];
        });
    };

    const handleAddTaskButtonPress = () => {
        showTaskView(null);
    };

    const handleTaskChangeButtonPress = (e, task) => {
        if(task.viewed){
            showTaskView(task);
        }
    };

    const handleCompleteTaskConfirmButtonPress = useCallback(async (taskDescription, taskMinutes) => {
        hideCompleteTaskView();
        const prevActiveUserTasks = {...state.activeUserTasks};
        const taskMessages = state.completeTaskViewSelectedTask.messages;
        const now = new Date();
        const newMessage = {
            timestamp: now,
            user: activeUser._id,
            userIsOperator: true,
            content: `Concluída em ${moment(now).format('L LTS')}`
        };
        taskMessages.push(newMessage);
        const updates = {
            messages: taskMessages,
            done: true,
            doneOn: now
        };

        setCompleteTaskViewSelectedTask(prevState => ({...prevState, deleted: true}));
        
        setTimeout(async () => {
            setActiveUserTasks(prevState => {
                const taskIndex = prevState[state.completeTaskViewSelectedTask.boardId].findIndex(task => task._id === state.completeTaskViewSelectedTask._id);
                return {
                    ...prevState,
                    [state.completeTaskViewSelectedTask.boardId]: [
                        ...prevState[state.completeTaskViewSelectedTask.boardId].slice(0, taskIndex),
                        ...prevState[state.completeTaskViewSelectedTask.boardId].slice(taskIndex + 1)
                    ]
                };
            });
    
            axios.post(SERVER_PATH + '/data/tasks/complete', {
                completedTaskId: state.completeTaskViewSelectedTask._id,
                updates,
                taskDescription,
                minutes: taskMinutes
            })
            .then(res => {
                socket.emit('TASK COMPLETED', { actionUserName: activeUser.fullName, completedTask: state.completeTaskViewSelectedTask });
            })
            .catch(err => {
                floatingAlert(ERROR_MESSAGE_CHANGES_UNDONE, 'error');
                setActiveUserTasks(prevActiveUserTasks);
                setCompleteTaskViewSelectedTask(prevState => ({...prevState, deleted: false}));
            });
        }, 800);
    }, [activeUser?._id, activeUser?.fullName, state.activeUserTasks, state.completeTaskViewSelectedTask]);

    const handleTaskDoneButtonPress = (e, task, boardId, index) => {
        e.stopPropagation();

        showCompleteTaskView({...task, boardId, taskIndex: index});
    };

    const handleTaskDragEnd = useCallback(async (data) => {
        const { destination, source, draggableId } = data;

        if(!destination) return;

        if(destination.droppableId === source.droppableId && destination.index === source.index) return;
        
        const prevActiveUserTasks = {...state.activeUserTasks};
        const prevTeamTasks = [...state.teamTasks];

        if(activeUserBoardsIds.includes(destination.droppableId)){ // dropped in my board
            if(activeUserBoardsIds.includes(source.droppableId)){
                //sort
                setActiveUserTasks(prevState => {
                    let updatedTask;
                    const bulkUpdates = [];
                    const sourceBoardTasks = prevState[source.droppableId];
                    const sameBoard = source.droppableId === destination.droppableId;
                    const draggedTask = {...sourceBoardTasks[source.index]};
                    const updatedSourceBoardTasks = [];
                    let updatedSourceBoardTaskIndex = 0;
                    const addSameBoardDraggedTask = () => {
                        if(sameBoard && updatedSourceBoardTaskIndex === destination.index){
                            draggedTask.orderNumber = updatedSourceBoardTaskIndex;
                            updatedSourceBoardTasks.push(draggedTask);
                            bulkUpdates.push({ id: draggedTask._id, updates: { orderNumber: updatedSourceBoardTaskIndex } });
                            updatedSourceBoardTaskIndex++
                        }
                    };
                    sourceBoardTasks.forEach((sourceBoardTask, sourceBoardTaskIndex) => {
                        addSameBoardDraggedTask();
                        if(sourceBoardTaskIndex !== source.index){
                            if(sourceBoardTaskIndex !== updatedSourceBoardTaskIndex){
                                sourceBoardTask.orderNumber = updatedSourceBoardTaskIndex;
                                bulkUpdates.push({ id: sourceBoardTask._id, updates: { orderNumber: updatedSourceBoardTaskIndex } });
                            }
                            updatedSourceBoardTasks.push(sourceBoardTask);
                            updatedSourceBoardTaskIndex++
                        }
                        addSameBoardDraggedTask();
                    });
                    if(!sameBoard){
                        const destinationBoardTasks = prevState[destination.droppableId];
                        const updatedDestinationBoardTasks = [];
                        let updatedDestinationBoardTaskIndex = 0;
                        const addDestinationBoardDraggedTask = () => {
                            if(updatedDestinationBoardTaskIndex === destination.index){
                                draggedTask.orderNumber = updatedDestinationBoardTaskIndex;
                                draggedTask.priority = activeUserBoardsPriorityLevels[destination.droppableId];
                                const updates = { orderNumber: updatedDestinationBoardTaskIndex, priority: activeUserBoardsPriorityLevels[destination.droppableId] };
                                if(source.droppableId === 'activeUserUnreadTasks' && !draggedTask.viewed){
                                    draggedTask.viewed = true;
                                    draggedTask.viewedOn = new Date();
                                    updates.viewed = true;
                                    updates.viewedOn = new Date();
                                }
                                updatedDestinationBoardTasks.push(draggedTask);
                                bulkUpdates.push({ id: draggedTask._id, updates });
                                updatedTask = draggedTask;
                                updatedDestinationBoardTaskIndex++
                            }
                        };
                        if(destinationBoardTasks.length === 0){
                            addDestinationBoardDraggedTask();
                        } else {
                            destinationBoardTasks.forEach((destinationBoardTask, destinationBoardTaskIndex) => {
                                addDestinationBoardDraggedTask();
                                if(destinationBoardTaskIndex !== updatedDestinationBoardTaskIndex){
                                    destinationBoardTask.orderNumber = updatedDestinationBoardTaskIndex;
                                    destinationBoardTask.updatedAt = new Date();
                                    bulkUpdates.push({ id: destinationBoardTask._id, updates: { orderNumber: updatedDestinationBoardTaskIndex } });
                                }
                                updatedDestinationBoardTasks.push(destinationBoardTask);
                                updatedDestinationBoardTaskIndex++
                                addDestinationBoardDraggedTask();
                            });
                        }
                        
                        bulkUpdateTasks(bulkUpdates, () => {
                            setActiveUserTasks(prevActiveUserTasks);
                        }, () => {
                            if(updatedTask){
                                socket.emit('TASK UPDATED', { actionUserName: activeUser.fullName, updatedTask });
                            }
                        });
                        return {
                            ...prevState,
                            [source.droppableId]: updatedSourceBoardTasks,
                            [destination.droppableId]: updatedDestinationBoardTasks
                        }
                    }
                    bulkUpdateTasks(bulkUpdates, () => {
                        setActiveUserTasks(prevActiveUserTasks);
                    });
                    return {
                        ...prevState,
                        [source.droppableId]: updatedSourceBoardTasks
                    };
                });
            } else {
                // dragged from team member board to active user board
                if(source.droppableId === 'new-tasks'){ // is new task
                    
                } else { // is not new task
                    const updatedBoardIndex = state.teamTasks.findIndex(board => board._id === source.droppableId);
                    const updatedBoard = state.teamTasks[updatedBoardIndex];
                    const deletedTask = updatedBoard.tasks[source.index];

                    deleteTeamTask(source.droppableId, source.index, draggableId);
                    
                    setActiveUserTasks(prevState => {
                        const bulkUpdates = [];
                        const updates = {operator: activeUser._id, viewed: false, viewedOn: null, priority: 1, orderNumber: 0};
                        const updatedTask = { ...deletedTask, ...updates };
                        bulkUpdates.push({ id: deletedTask._id, updates });
                        const destinationBoardTasks = prevState.activeUserUnreadTasks;
                        const updatedDestinationBoardTasks = [];
                        updatedDestinationBoardTasks.push(updatedTask);
                        let updatedDestinationBoardTaskIndex = 1;
                        destinationBoardTasks.forEach((destinationBoardTask) => {
                            destinationBoardTask.orderNumber = updatedDestinationBoardTaskIndex;
                            updatedDestinationBoardTasks.push(destinationBoardTask);
                            bulkUpdates.push({ id: destinationBoardTask._id, updates: { orderNumber: updatedDestinationBoardTaskIndex } });
                            updatedDestinationBoardTaskIndex++;
                        });
                        bulkUpdateTasks(bulkUpdates, () => {
                            setTeamTasks(prevTeamTasks);
                            setActiveUserTasks(prevActiveUserTasks);
                        }, () => {
                            socket.emit('TASK ASSIGNED', {
                                actionUserId: activeUser._id, actionUserName: activeUser.fullName,
                                clientId: activeClient._id, workspaceName: activeClient.name, 
                                removedTask: deletedTask,
                                addedTask: updatedTask
                            });
                        });
                        return {
                            ...prevState,
                            activeUserUnreadTasks: updatedDestinationBoardTasks
                        };
                    });
                }
            }
        } else { // dropped in someone else's board
            const destinationBoardIndex = state.teamTasks.findIndex(board => board._id === destination.droppableId);
            if(destination.droppableId !== source.droppableId){
                if(source.droppableId === 'new-tasks'){ // is new task
                    //
                } else if(activeUserBoardsIds.includes(source.droppableId)){
                    const sourceBoardTasks = state.activeUserTasks[source.droppableId];
                    const draggedTask = sourceBoardTasks[source.index];

                    setActiveUserTasks(prevState => ({
                        ...prevState,
                        [source.droppableId]: [
                            ...prevState[source.droppableId].slice(0, source.index),
                            ...prevState[source.droppableId].slice(source.index + 1)
                        ]
                    }));

                    setTeamTasks(prevState => {
                        const bulkUpdates = [];
                        let orderNumber = 0;
                        const firstTask = prevState[destinationBoardIndex].tasks[0];
                        if(firstTask) orderNumber = (firstTask.orderNumber || 0) - 1;
                        const updates = {operator: destination.droppableId, viewed: false, viewedOn: null, priority: 1, orderNumber};
                        const updatedTask = { ...draggedTask, ...updates };
                        bulkUpdates.push({ id: draggedTask._id, updates });

                        bulkUpdateTasks(bulkUpdates, () => {
                            setActiveUserTasks(prevActiveUserTasks);
                            setTeamTasks(prevTeamTasks);
                        }, () => {
                            socket.emit('TASK ASSIGNED', {
                                actionUserId: activeUser._id, actionUserName: activeUser.fullName,
                                clientId: activeClient._id, workspaceName: activeClient.name, 
                                removedTask: draggedTask,
                                addedTask: updatedTask
                            });
                        });

                        return [
                            ...prevState.slice(0, destinationBoardIndex),
                            {
                                ...prevState[destinationBoardIndex],
                                tasks: [
                                    updatedTask,
                                    ...prevState[destinationBoardIndex].tasks
                                ]
                            },
                            ...prevState.slice(destinationBoardIndex + 1)
                        ];
                    });
                } else {  // source is someone else's board
                    setTeamTasks(prevState1 => {
                        const sourceBoardIndex = prevState1.findIndex(board => board._id === source.droppableId);
                        const updatedBoard = prevState1[sourceBoardIndex];
                        const deletedTask = updatedBoard.tasks[source.index];

                        let updatedTask;
                        const bulkUpdates = [];
                        const nextState = [];
                        prevState1.forEach((board, boardIndex) => {
                            if(boardIndex === sourceBoardIndex){
                                board = {
                                    ...prevState1[sourceBoardIndex],
                                    tasks: [
                                        ...prevState1[sourceBoardIndex].tasks.slice(0, source.index),
                                        ...prevState1[sourceBoardIndex].tasks.slice(source.index + 1)
                                    ]
                                };
                            }
                            if(boardIndex === destinationBoardIndex){
                                let orderNumber = 0;
                                const firstTask = prevState1[destinationBoardIndex].tasks[0];
                                if(firstTask) orderNumber = (firstTask.orderNumber || 0) - 1;
                                const updates = {operator: destination.droppableId, viewed: false, viewedOn: null, priority: 1, orderNumber};
                                updatedTask = { ...deletedTask, ...updates };
                                bulkUpdates.push({ id: deletedTask._id, updates });
                                board = {
                                    ...prevState1[destinationBoardIndex],
                                    tasks: [
                                        updatedTask,
                                        ...prevState1[destinationBoardIndex].tasks
                                    ]
                                };
                            }
                            nextState.push(board);
                        });

                        bulkUpdateTasks(bulkUpdates, () => {
                            setTeamTasks(prevTeamTasks);
                        }, () => {
                            socket.emit('TASK ASSIGNED', {
                                actionUserId: activeUser._id, actionUserName: activeUser.fullName,
                                clientId: activeClient._id, workspaceName: activeClient.name, 
                                removedTask: deletedTask,
                                addedTask: updatedTask
                            });
                        });

                        return nextState;
                    });
                }
            }
        }
    }, [activeClient?._id, activeClient?.name, activeUser?._id, activeUser?.fullName, state.activeUserTasks, state.teamTasks]);

    const handleTaskReadButtonPress = useCallback(async (e, clickedTask, unreadTaskIndex) => {
        e.stopPropagation();
        const prevActiveUserTasks = {...state.activeUserTasks};
        const now = new Date();
        
        setActiveUserTasks(prevState => {
            const updates = { viewed: true, viewedOn: now, priority: 1 }
            const updatedTask = {
                ...prevState.activeUserUnreadTasks[unreadTaskIndex],
                ...updates
            };
            const bulkUpdates = [];
            bulkUpdates.push({ id: updatedTask._id, updates });
            const updatedActiveUserMediumPriorityTasks = [];
            updatedActiveUserMediumPriorityTasks.push(updatedTask);
            let updatedActiveUserMediumPriorityTaskIndex = 1;
            prevState.activeUserMediumPriorityTasks.forEach((activeUserMediumPriorityTask) => {
                activeUserMediumPriorityTask.orderNumber = updatedActiveUserMediumPriorityTaskIndex;
                bulkUpdates.push({ id: activeUserMediumPriorityTask._id, updates: { orderNumber: updatedActiveUserMediumPriorityTaskIndex } });
                updatedActiveUserMediumPriorityTasks.push(activeUserMediumPriorityTask);
                updatedActiveUserMediumPriorityTaskIndex++
            });
            bulkUpdateTasks(bulkUpdates, () => {
                setActiveUserTasks(prevActiveUserTasks);
            }, () => {
                socket.emit('TASK UPDATED', { actionUserName: activeUser.fullName, updatedTask });
            });
            return {
                ...prevState,
                activeUserUnreadTasks: [
                    ...prevState.activeUserUnreadTasks.slice(0, unreadTaskIndex),
                    ...prevState.activeUserUnreadTasks.slice(unreadTaskIndex + 1)
                ],
                activeUserMediumPriorityTasks: updatedActiveUserMediumPriorityTasks
            }
        });
    }, [activeUser?.fullName, state.activeUserTasks]);

    const handleTaskSubmit = useCallback(async (data, timesheet) => {
        const {
            deadline,
            hiddenTask,
            numberOfHoursRequired,
            questionDescription,
            questionSubject,
            selectedClient,
            selectedProject,
            taskOperatorId,
            taskPriority,
            taskUrl,
        } = data;

        const prevActiveUserTasks = {...state.activeUserTasks};
        const prevTeamTasks = [...state.teamTasks];
        if(state.taskViewSelectedTask){
            const prevTaskHidden = state.taskViewSelectedTask.hidden;
            const updates = {
                project: selectedProject !== 'none' ? selectedProject : selectedClient,
                subject: questionSubject,
                description: questionDescription,
                deadline: deadline ? moment(deadline).format() : null,
                numberOfHoursRequired: numberOfHoursRequired,
                taskUrl,
                operator: taskOperatorId || '',
                hidden: hiddenTask,
            };
            const updatedTask = {
                ...state.taskViewSelectedTask,
                ...updates
            };
            const boardId = state.taskViewSelectedTask.boardId;
            setActiveUserTasks(prevState => {
                const taskIndex = prevState[boardId].findIndex(task => task._id === state.taskViewSelectedTask._id);
                return {
                    ...prevState,
                    [boardId]: [
                        ...prevState[boardId].slice(0, taskIndex),
                        {...updatedTask, updatedAt: new Date()},
                        ...prevState[boardId].slice(taskIndex + 1)
                    ]
                };
            });

            axios.post(SERVER_PATH + '/data/tasks/update', { updatedTaskId: state.taskViewSelectedTask._id, updates })
            .then(res => {
                socket.emit('TASK UPDATED', {
                    clientId: activeClient._id,
                    updatedTask, actionUserName: activeUser.fullName,
                    prevTaskHidden
                });
            })
            .catch(err => {
                floatingAlert(err.response.data, 'error');
                setActiveUserTasks(prevActiveUserTasks);
            });
        } else {

            if(timesheet){
                return axios.post(SERVER_PATH + '/data/tasks/timesheet', {
                    project: selectedProject !== 'none' ? selectedProject : selectedClient,
                    description: questionSubject,
                    minutes: numberOfHoursRequired,
                })
                .then(res => {})
                .catch(err => {
                    floatingAlert('Ocorreu um erro. Tente novamente.', 'error');
                });
            }

            const ObjectId = mongoose.Types.ObjectId;
            const newTaskId = new ObjectId().toString();
            const newTaskUuid = uuidv4();
            const newTask = {
                _id: newTaskId,
                uuid: newTaskUuid,
                clientId: activeClient._id,
                createdAt: new Date(),
                createdBy: activeUser._id,
                project: selectedProject !== 'none' ? selectedProject : selectedClient,
                subject: questionSubject,
                description: questionDescription,
                deadline: deadline ? moment(deadline).format() : null,
                numberOfHoursRequired: numberOfHoursRequired,
                taskUrl: taskUrl,
                operator: taskOperatorId || '',
                priority: taskPriority,
                hidden: hiddenTask,
                orderNumber: 0,
            };
            if(taskOperatorId === activeUser._id){
                newTask.viewed = true;
                newTask.viewedOn = new Date();
                setActiveUserTasks(prevState => {
                    if(taskPriority === 2){
                        if(prevState.activeUserHighPriorityTasks[0]) newTask.orderNumber = prevState.activeUserHighPriorityTasks[0].orderNumber - 1;
                        return {
                            ...prevState,
                            activeUserHighPriorityTasks: [
                                { ...newTask, _id: newTaskUuid },
                                ...prevState.activeUserHighPriorityTasks
                            ]
                        };
                    } else if(taskPriority === 1){
                        if(prevState.activeUserMediumPriorityTasks[0]) newTask.orderNumber = prevState.activeUserMediumPriorityTasks[0].orderNumber - 1;
                        return {
                            ...prevState,
                            activeUserMediumPriorityTasks: [
                                { ...newTask, _id: newTaskUuid },
                                ...prevState.activeUserMediumPriorityTasks
                            ]
                        };
                    }
                    if(prevState.activeUserLowPriorityTasks[0]) newTask.orderNumber = prevState.activeUserLowPriorityTasks[0].orderNumber - 1;
                    return {
                        ...prevState,
                        activeUserLowPriorityTasks: [
                            { ...newTask, _id: newTaskUuid },
                            ...prevState.activeUserLowPriorityTasks
                        ]
                    };
                });
            } else {
                setTeamTasks(prevState => {
                    const updatedBoardIndex = prevState.findIndex(board => board._id === taskOperatorId);
                    return [
                        ...prevState.slice(0, updatedBoardIndex),
                        {
                            ...prevState[updatedBoardIndex],
                            tasks: [
                                { ...newTask, _id: newTaskUuid },
                                ...prevState[updatedBoardIndex].tasks
                            ]
                        },
                        ...prevState.slice(updatedBoardIndex + 1)
                    ];
                });
            }

            axios.post(SERVER_PATH + '/data/tasks/create', { newTask })
            .then(res => {
                const updatedNewTask = res.data.updatedNewTask;
                if(updatedNewTask){
                    if(taskOperatorId === activeUser._id){
                        setActiveUserTasks(prevState => {
                            const bulkUpdates = [];
                            const updatedActiveUserTasks = [];
                            updatedActiveUserTasks.push({...updatedNewTask, orderNumber: 0});
                            let updatedActiveUserTaskIndex = 1;
                            if(taskPriority === 2){
                                prevState.activeUserHighPriorityTasks.slice(1).forEach((activeUserTask) => {
                                    activeUserTask.orderNumber = updatedActiveUserTaskIndex;
                                    updatedActiveUserTasks.push(activeUserTask);
                                    bulkUpdates.push({ id: activeUserTask._id, updates: { orderNumber: updatedActiveUserTaskIndex } });
                                    updatedActiveUserTaskIndex++;
                                });
                            } else if(taskPriority === 1){
                                prevState.activeUserMediumPriorityTasks.slice(1).forEach((activeUserTask) => {
                                    activeUserTask.orderNumber = updatedActiveUserTaskIndex;
                                    updatedActiveUserTasks.push(activeUserTask);
                                    bulkUpdates.push({ id: activeUserTask._id, updates: { orderNumber: updatedActiveUserTaskIndex } });
                                    updatedActiveUserTaskIndex++;
                                });
                            } else {
                                prevState.activeUserLowPriorityTasks.slice(1).forEach((activeUserTask) => {
                                    activeUserTask.orderNumber = updatedActiveUserTaskIndex;
                                    updatedActiveUserTasks.push(activeUserTask);
                                    bulkUpdates.push({ id: activeUserTask._id, updates: { orderNumber: updatedActiveUserTaskIndex } });
                                    updatedActiveUserTaskIndex++;
                                });
                            }
                            bulkUpdateTasks(bulkUpdates, () => {
                                floatingAlert('Ocorreu um erro. Recarregue a página.', 'error');
                                setActiveUserTasks(prevActiveUserTasks);
                            }, () => {
                                socket.emit('TASK CREATED', {
                                    actionUserId: activeUser._id, actionUserName: activeUser.fullName, createdTask: updatedNewTask,
                                    newTaskUserName: null
                                });
                            });
                            if(taskPriority === 2){
                                return {
                                    ...prevState,
                                    activeUserHighPriorityTasks: updatedActiveUserTasks
                                };
                            } else if(taskPriority === 1){
                                return {
                                    ...prevState,
                                    activeUserMediumPriorityTasks: updatedActiveUserTasks
                                };
                            }
                            return {
                                ...prevState,
                                activeUserLowPriorityTasks: updatedActiveUserTasks
                            };
                        });
                    } else {
                        setTeamTasks(prevState => {
                            const updatedBoardIndex = prevState.findIndex(board => board._id === taskOperatorId);
                            return [
                                ...prevState.slice(0, updatedBoardIndex),
                                {
                                    ...prevState[updatedBoardIndex],
                                    tasks: [
                                        updatedNewTask,
                                        ...prevState[updatedBoardIndex].tasks.slice(1)
                                    ]
                                },
                                ...prevState.slice(updatedBoardIndex + 1)
                            ];
                        });
                        socket.emit('TASK CREATED', {
                            actionUserId: activeUser._id, actionUserName: activeUser.fullName, createdTask: updatedNewTask,
                            newTaskUserName: null,
                            workspaceName: activeClient.name
                        });
                    }
                }
            })
            .catch(err => {
                floatingAlert(err.response.data, 'error');
                if(taskOperatorId === activeUser._id){
                    setActiveUserTasks(prevActiveUserTasks);
                } else {
                    setTeamTasks(prevTeamTasks);
                }
            });
        }
    }, [activeClient?._id, activeClient?.name, activeUser?._id, activeUser?.fullName, state.activeUserTasks, state.taskViewSelectedTask, state.teamTasks]);

    const hideCompleteTaskView = (newValue) => {
        dispatch({type: 'HIDE COMPLETE TASK VIEW', payload: newValue});
    };

    const hideTaskView = (newValue) => {
        dispatch({type: 'HIDE TASK VIEW', payload: newValue});
    };
    
    const setState = (key, newValue) => {
        dispatch({type: 'SET STATE', payload: { key, value: newValue }});
    };

    const setActiveProjectBoards = (newValue) => {
        setState('activeProjectBoards', newValue);
    };
    
    const setActiveUserTasks = (newValue) => {
        setState('activeUserTasks', newValue);
    };
    
    const setCompleteTaskViewSelectedTask = (newValue) => {
        setState('completeTaskViewSelectedTask', newValue);
    };

    const setFilters = (newValue) => {
        setState('filters', newValue);
    };

    const setNewTasks = (newValue) => {
        setState('newTasks', newValue);
    };

    const setNextActiveUserTask = (newValue) => {
        setState('nextActiveUserTask', newValue);
    };

    const setTasks = (newValue) => {
        setState('tasks', newValue);
    };

    const setTasksPendingDocuments = (newValue) => {
        setState('tasksPendingDocuments', newValue);
    };

    const setTasksTeam = (newValue) => {
        setState('tasksTeam', newValue);
    };

    const setTaskViewSelectedTask = (newValue) => {
        setState('taskViewSelectedTask', newValue);
    };

    const setTeamTasks = (newValue) => {
        setState('teamTasks', newValue);
    };

    const showCompleteTaskView = (clickedTask) => {
        dispatch({type: 'SHOW COMPLETE TASK VIEW', payload: { clickedTask }});
    };

    const showTaskView = (clickedTask) => {
        dispatch({type: 'SHOW TASK VIEW', payload: { clickedTask }});
    };

    const sortTasks = (a, b) => {
        if(a.viewed && !b.viewed) return 1;
        if(!a.viewed && b.viewed) return -1;
        if(a.priority < b.priority) return 1;
        if(a.priority > b.priority) return -1;
        return (a.orderNumber > b.orderNumber) ? 1 : ((b.orderNumber > a.orderNumber) ? -1 : 0);
    };

    const api = useMemo(() => {

        const resetFilters = () => {
            setFilters(defaultFiltersValue);
        };

        return {
            dispatch,

            handleAddTaskButtonPress,
            handleCompleteTaskConfirmButtonPress,
            handleTaskChangeButtonPress,
            handleTaskDoneButtonPress,
            handleTaskDragEnd,
            handleTaskReadButtonPress,
            handleTaskSubmit,
            hideCompleteTaskView,
            hideTaskView,
            resetFilters,
            setActiveProjectBoards,
            setFilters,
            showCompleteTaskView,
            showTaskView,
            sortTasks
        };
    }, [handleTaskSubmit, handleCompleteTaskConfirmButtonPress, handleTaskDragEnd, handleTaskReadButtonPress]);

    useEffect(() => {
        const keyPress = (e) => {
            if(projects && activeClient.modules.includes('tasks') && (activeUser?.type >= 4 || activeUser?.canWriteTasks)){
                if((e.altKey || e.ctrlKey) && e.key === '1'){
                    e.preventDefault();
                    showTaskView(null);
                }
            }
        };
        document.addEventListener('keydown', keyPress);
        return () => {
            document.removeEventListener('keydown', keyPress);
        };
    }, [activeClient?.modules, activeUser?.canWriteTasks, activeUser?.type, projects]);
    
    useEffect(() => {
        if(operators.length >= 1) setTasksTeam(operators);
    }, [operators]);

    const loadTasks = async () => {
        if(activeClient.modules.includes('tasks') && (activeUser?.type >= 4 || activeUser?.canReadTasks)){
            setShouldUpdateTasks(false);
            console.log(`Carregando TAREFAS em ${moment().format('L LTS')}`);
            await axios.get(SERVER_PATH + `/data/tasks`)
            .then(res => {
                setTasksPendingDocuments(res.data.numberOfPendingDocuments || {});
                shouldOrganizeBoards.current = true;
                setTasks(res.data.tasks || []);
            })
            .catch(err => {
                floatingAlert(err?.data?.response || err?.message || ERROR_MESSAGE_UNKNOWN);
            });
        }
    };

    useEffect(() => {
        if(shouldUpdateTasks && state.tasksTeam){
            loadTasks();
        }
    }, [activeClient?.modules, activeUser?.canWriteTasks, activeUser?.type, state.tasksTeam, socketConnectedAt]);

    const organizeBoards = (tasks, tasksTeam) => {
        const currentNewTasks = [];
        const currentActiveUserTasks = [];
        const currentActiveUnreadTasks = [];
        const currentActiveUserHighPriorityTasks = [];
        const currentActiveUserMediumPriorityTasks = [];
        const currentActiveUserLowPriorityTasks = [];
        let teamTasksUserIndex = 0;
        const currentTeamTasksUserIndex = {};
        const currentTeamTasks = [];
        tasksTeam.forEach(operator => {
            if(operator._id !== activeUser._id){
                if(currentTeamTasksUserIndex[operator._id] === undefined){
                    currentTeamTasksUserIndex[operator._id] = teamTasksUserIndex;
                    teamTasksUserIndex++;
                    currentTeamTasks.push({...operator, tasks: []});
                }
            }
        });
        tasks.forEach(task => {
            if(!task.deleted && !task.done){
                if(!task.operator){
                    return currentNewTasks.push(task);
                }
                if(task.operator === activeUser._id){
                    if(!task.viewed){
                        currentActiveUnreadTasks.push(task);
                    } else {
                        if(task.priority === 2){
                            currentActiveUserHighPriorityTasks.push(task);
                        } else if(task.priority === 1 || task.priority === true){
                            currentActiveUserMediumPriorityTasks.push(task);
                        } else {
                            currentActiveUserLowPriorityTasks.push(task); // !task.priority
                        }
                    }
                    return currentActiveUserTasks.push(task);
                }
                if(!task.hidden){
                    if(currentTeamTasksUserIndex[task.operator] === undefined){
                        const foundOperator = tasksTeam.find(operator => operator._id === task.operator);
                        if(foundOperator){
                            currentTeamTasksUserIndex[task.operator] = teamTasksUserIndex;
                            teamTasksUserIndex++;
                            currentTeamTasks.push({...foundOperator, tasks: []});
                        }
                    }
                    if(currentTeamTasksUserIndex[task.operator] !== undefined) currentTeamTasks[currentTeamTasksUserIndex[task.operator]].tasks.push(task);
                }
            }
        });
        setNewTasks(currentNewTasks.length > 0 ? currentNewTasks : null);
        setActiveUserTasks({
            activeUserHighPriorityTasks: currentActiveUserHighPriorityTasks,
            activeUserMediumPriorityTasks: currentActiveUserMediumPriorityTasks,
            activeUserLowPriorityTasks: currentActiveUserLowPriorityTasks,
            activeUserUnreadTasks: currentActiveUnreadTasks,
        });
        if(currentActiveUserTasks[0]) setNextActiveUserTask(currentActiveUserTasks[0]);
        setTeamTasks(currentTeamTasks.sort((a, b) => a.fullName > b.fullName ? 1 : a.fullName < b.fullName ? -1 : 0));

        if(state.taskViewSelectedTask || state.completeTaskViewSelectedTask){
            let selectedTask = null;
            if(state.taskViewOpen){
                selectedTask = state.taskViewSelectedTask;
            } else if(state.completeTaskViewOpen){
                selectedTask = state.completeTaskViewSelectedTask;
            }
            if(selectedTask){
                const foundSelectedTask = state.tasks.find(task => task._id === selectedTask._id);
                if(foundSelectedTask){
                    if(foundSelectedTask.operator === activeUser._id){
                        if(state.taskViewOpen){
                            setTaskViewSelectedTask(foundSelectedTask);
                        } else if(state.completeTaskViewOpen){
                            setCompleteTaskViewSelectedTask(foundSelectedTask);
                        }
                    } else {
                        setTaskViewSelectedTask(null);
                        setCompleteTaskViewSelectedTask(null);
                        hideTaskView();
                        hideCompleteTaskView();
                    }
                }
            }
        }
    };

    useEffect(() => {
        if(state.tasksTeam && state.tasks && shouldOrganizeBoards.current){
            shouldOrganizeBoards.current = false;
            organizeBoards(state.tasks, state.tasksTeam);
        }
    }, [activeUser?._id, state.tasksTeam, state.tasks]);

    useEffect(() => {
        const activeProjectsUpdatedSocketEventName = 'ACTIVE PROJECTS UPDATED';

        const taskAssignedSocketEventName = 'TASK ASSIGNED';
        const taskBoardUpdatedSocketEventName = 'TASK BOARD UPDATED';
        const taskCompletedSocketEventName = 'TASK COMPLETED';
        const taskCreatedSocketEventName = 'TASK CREATED';
        const taskRemovedSocketEventName = 'TASK REMOVED';
        const taskUpdatedSocketEventName = 'TASK UPDATED';

        const onActiveProjectsUpdatedSocketEvent = (data) => {
            const { updatedActiveProjects } = data;
            setActiveProjectBoards(updatedActiveProjects);
        };

        const onTaskAssignedSocketEvent = (data) => {
            const { actionUserName, addedTask } = data;

            const updatedBoardId = addedTask.operator;
            if(updatedBoardId === activeUser._id){
                setActiveUserTasks(prevState => {
                    if(prevState){
                        return ({
                            ...prevState,
                            activeUserUnreadTasks: [addedTask, ...prevState.activeUserUnreadTasks]
                        })
                    }
                    return prevState;
                });
            } else {
                setTeamTasks(prevState => {
                    if(prevState){
                        const updatedBoardIndex = prevState.findIndex(board => board._id === updatedBoardId);
                        return [
                            ...prevState.slice(0, updatedBoardIndex),
                            {
                                ...prevState[updatedBoardIndex],
                                tasks: [
                                    addedTask,
                                    ...prevState[updatedBoardIndex].tasks
                                ]
                            },
                            ...prevState.slice(updatedBoardIndex + 1)
                        ];
                    }
                    return prevState;
                });
            }
            console.log(`${actionUserName} transferiu a tarefa "${addedTask.subject}" para outra pessoa em ${moment(new Date()).format('L LTS')}.`);
        };

        const onTaskBoardUpdatedSocketEvent = (data) => {
            const { actionUserName, updatedBoardId, updatedBoardTasks } = data;
            setTeamTasks(prevState => {
                if(prevState){
                    const updatedBoardIndex = prevState.findIndex(board => board._id === updatedBoardId);
                    return [
                        ...prevState.slice(0, updatedBoardIndex),
                        {
                            ...prevState[updatedBoardIndex],
                            tasks: updatedBoardTasks
                        },
                        ...prevState.slice(updatedBoardIndex + 1)
                    ];
                }
                return prevState;
            });
            console.log(`As tarefas de ${actionUserName} foram alteradas em ${moment(new Date()).format('L LTS')}.`);
        };

        const onTaskCompletedSocketEvent = (data) => {
            const { actionUserName, completedTask } = data;
            const updatedBoardId = completedTask.operator;
            if(updatedBoardId === activeUser._id){
                setActiveUserTasks(prevState => {
                    if(prevState){
                        let activeUserBoardId = 'activeUserLowPriorityTasks';
                        if(!completedTask.viewed){
                            activeUserBoardId = 'activeUserUnreadTasks';
                        } else if(completedTask.priority === 2){
                            activeUserBoardId = 'activeUserHighPriorityTasks';
                        } else if(completedTask.priority === 1){
                            activeUserBoardId = 'activeUserMediumPriorityTasks';
                        }
                        if(activeUserBoardId){
                            const updatedBoard = prevState[activeUserBoardId];
                            const updatedTaskIndex = updatedBoard.findIndex(task => task._id === completedTask._id);
                            if(updatedTaskIndex !== -1){
                                return {
                                    ...prevState,
                                    [activeUserBoardId]: [
                                        ...prevState[activeUserBoardId].slice(0, updatedTaskIndex),
                                        ...prevState[activeUserBoardId].slice(updatedTaskIndex + 1)
                                    ]
                                };
                            }
                        }
                    }
                    return prevState;
                });
            } else {
                setTeamTasks(prevState => {
                    if(prevState){
                        const updatedBoardIndex = prevState.findIndex(board => board._id === updatedBoardId);
                        const updatedBoard = prevState[updatedBoardIndex];
                        const updatedTaskIndex = updatedBoard.tasks.findIndex(task => task._id === completedTask._id);
                        return [
                            ...prevState.slice(0, updatedBoardIndex),
                            {
                                ...prevState[updatedBoardIndex],
                                tasks: [
                                    ...prevState[updatedBoardIndex].tasks.slice(0, updatedTaskIndex),
                                    ...prevState[updatedBoardIndex].tasks.slice(updatedTaskIndex + 1)
                                ]
                            },
                            ...prevState.slice(updatedBoardIndex + 1)
                        ];
                    }
                    return prevState;
                });
                toast(`${actionUserName} completou a tarefa "${completedTask.subject}".`);
            }
            console.log(`${actionUserName} completou a tarefa "${completedTask.subject}" em ${moment(new Date()).format('L LTS')}.`);
        };

        const onTaskCreatedSocketEvent = (data) => {
            const { actionUserName, createdTask, newTaskUserName } = data;
            const updatedBoardId = createdTask.operator;
            if(updatedBoardId === activeUser._id){
                setActiveUserTasks(prevState => {
                    if(prevState){
                        let activeUserBoardId = 'activeUserLowPriorityTasks';
                        if(!createdTask.viewed){
                            activeUserBoardId = 'activeUserUnreadTasks';
                        } else if(createdTask.priority === 2){
                            activeUserBoardId = 'activeUserHighPriorityTasks';
                        } else if(createdTask.priority === 1){
                            activeUserBoardId = 'activeUserMediumPriorityTasks';
                        }
                        if(activeUserBoardId){
                            const updatedBoard = prevState[activeUserBoardId];
                            const updatedTaskIndex = updatedBoard.findIndex(task => task._id === createdTask._id);
                            if(updatedTaskIndex === -1){
                                return {
                                    ...prevState,
                                    [activeUserBoardId]: [
                                        createdTask,
                                        ...prevState[activeUserBoardId]
                                    ]
                                };
                            }
                        }
                    }
                    return prevState;
                });
            } else {
                setTeamTasks(prevState => {
                    if(prevState){
                        const updatedBoardIndex = prevState.findIndex(board => board._id === updatedBoardId);
                        return [
                            ...prevState.slice(0, updatedBoardIndex),
                            {
                                ...prevState[updatedBoardIndex],
                                tasks: [
                                    createdTask,
                                    ...prevState[updatedBoardIndex].tasks
                                ]
                            },
                            ...prevState.slice(updatedBoardIndex + 1)
                        ];
                    }
                    return prevState;
                });
            }
            console.log(`${actionUserName} criou a tarefa "${createdTask.subject}"${newTaskUserName ? ` para ${newTaskUserName}` : ''} em ${moment(new Date()).format('L LTS')}.`);
        };

        const onTaskRemovedSocketEvent = (data) => {
            const { ignoreIfTaskOperator, removedTask } = data;
            const updatedBoardId = removedTask.operator;
            if(!ignoreIfTaskOperator || updatedBoardId !== activeUser._id){
                if(updatedBoardId === activeUser._id){
                    setActiveUserTasks(prevState => {
                        if(prevState){
                            let boardId;
                            if(!removedTask.viewed){
                                boardId = 'activeUserUnreadTasks';
                            } else {
                                if(removedTask.priority === 2){
                                    boardId = 'activeUserHighPriorityTasks';
                                } else if(removedTask.priority === 1){
                                    boardId = 'activeUserMediumPriorityTasks';
                                } else {
                                    boardId = 'activeUserLowPriorityTasks';
                                }
                            }
                            const updatedBoard = prevState[boardId];
                            const removedTaskIndex = updatedBoard.findIndex(task => task._id === removedTask._id);
                            return {
                                ...prevState,
                                [boardId]: [
                                    ...prevState[boardId].slice(0, removedTaskIndex),
                                    ...prevState[boardId].slice(removedTaskIndex + 1),
                                ]
                            }
                        }
                        return prevState;
                    });
                } else {
                    setTeamTasks(prevState => {
                        if(prevState){
                            const updatedBoardIndex = prevState.findIndex(board => board._id === updatedBoardId);
                            const updatedBoard = prevState[updatedBoardIndex];
                            const removedTaskIndex = updatedBoard.tasks.findIndex(task => task._id === removedTask._id);
                            return [
                                ...prevState.slice(0, updatedBoardIndex),
                                {
                                    ...prevState[updatedBoardIndex],
                                    tasks: [
                                        ...prevState[updatedBoardIndex].tasks.slice(0, removedTaskIndex),
                                        ...prevState[updatedBoardIndex].tasks.slice(removedTaskIndex + 1)
                                    ]
                                },
                                ...prevState.slice(updatedBoardIndex + 1)
                            ];
                        }
                        return prevState;
                    });
                }
            }
        };

        const onTaskUpdatedSocketEvent = (data) => {
            const { actionUserName, updatedTask } = data;
            const updatedBoardId = updatedTask.operator;
            if(updatedBoardId === activeUser._id){
                setActiveUserTasks(prevState => {
                    if(prevState){
                        let sourceBoardId, prevTaskIndex;
                        activeUserBoardsIds.forEach(activeUserBoardsId => {
                            const sourceBoard = prevState[activeUserBoardsId];
                            if(sourceBoard){
                                const sourceBoardTaskIndex = sourceBoard.findIndex(task => task._id === updatedTask._id);
                                if(sourceBoardTaskIndex !== -1){
                                    sourceBoardId = activeUserBoardsId;
                                    prevTaskIndex = sourceBoardTaskIndex;
                                    return;
                                }
                            }
                        });
                        if(sourceBoardId){
                            let destinationBoardId = 'activeUserLowPriorityTasks';
                            if(!updatedTask.viewed){
                                destinationBoardId = 'activeUserUnreadTasks';
                            } else if(updatedTask.priority === 2){
                                destinationBoardId = 'activeUserHighPriorityTasks';
                            } else if(updatedTask.priority === 1){
                                destinationBoardId = 'activeUserMediumPriorityTasks';
                            }
                            if(sourceBoardId !== destinationBoardId){
                                const destinationBoard = prevState[destinationBoardId];
                                if(destinationBoard){
                                    console.log('...', {
                                        ...prevState,
                                        [sourceBoardId]: [
                                            ...prevState[sourceBoardId].slice(0, prevTaskIndex),
                                            ...prevState[sourceBoardId].slice(prevTaskIndex + 1)
                                        ],
                                        [destinationBoardId]: [
                                            updatedTask,
                                            ...prevState[destinationBoardId]
                                        ]
                                    })
                                    return {
                                        ...prevState,
                                        [sourceBoardId]: [
                                            ...prevState[sourceBoardId].slice(0, prevTaskIndex),
                                            ...prevState[sourceBoardId].slice(prevTaskIndex + 1)
                                        ],
                                        [destinationBoardId]: [
                                            updatedTask,
                                            ...prevState[destinationBoardId]
                                        ]
                                    };
                                }
                            }
                            return {
                                ...prevState,
                                [sourceBoardId]: [
                                    ...prevState[sourceBoardId].slice(0, prevTaskIndex),
                                    updatedTask,
                                    ...prevState[sourceBoardId].slice(prevTaskIndex + 1)
                                ],
                            };
                        }
                    }
                    return prevState;
                });
            } else {
                setTeamTasks(prevState => {
                    if(prevState){
                        const updatedBoardIndex = prevState.findIndex(board => board._id === updatedBoardId);
                        const updatedBoard = prevState[updatedBoardIndex];
                        const updatedTaskIndex = updatedBoard.tasks.findIndex(task => task._id === updatedTask._id);
                        return [
                            ...prevState.slice(0, updatedBoardIndex),
                            {
                                ...prevState[updatedBoardIndex],
                                tasks: [
                                    ...prevState[updatedBoardIndex].tasks.slice(0, updatedTaskIndex),
                                    updatedTask,
                                    ...prevState[updatedBoardIndex].tasks.slice(updatedTaskIndex + 1)
                                ]
                            },
                            ...prevState.slice(updatedBoardIndex + 1)
                        ];
                    }
                    return prevState;
                });
            }
            console.log(`${actionUserName} alterou a tarefa "${updatedTask.subject}" em ${moment(new Date()).format('L LTS')}.`);
        };

        if(activeClient.modules.includes('tasks') && (activeUser?.type >= 4 || activeUser?.canReadTasks)){
            socket.on(activeProjectsUpdatedSocketEventName, onActiveProjectsUpdatedSocketEvent);

            socket.on(taskAssignedSocketEventName, onTaskAssignedSocketEvent);
            socket.on(taskBoardUpdatedSocketEventName, onTaskBoardUpdatedSocketEvent);
            socket.on(taskCompletedSocketEventName, onTaskCompletedSocketEvent);
            socket.on(taskCreatedSocketEventName, onTaskCreatedSocketEvent);
            socket.on(taskRemovedSocketEventName, onTaskRemovedSocketEvent);
            socket.on(taskUpdatedSocketEventName, onTaskUpdatedSocketEvent);
        }
        return () => {
            socket.off(activeProjectsUpdatedSocketEventName, onActiveProjectsUpdatedSocketEvent);

            socket.off(taskAssignedSocketEventName, onTaskAssignedSocketEvent);
            socket.off(taskBoardUpdatedSocketEventName, onTaskBoardUpdatedSocketEvent);
            socket.off(taskCompletedSocketEventName, onTaskCompletedSocketEvent);
            socket.off(taskCreatedSocketEventName, onTaskCreatedSocketEvent);
            socket.off(taskRemovedSocketEventName, onTaskRemovedSocketEvent);
            socket.off(taskUpdatedSocketEventName, onTaskUpdatedSocketEvent);
        };
    }, [activeUser?._id]);

    const tasksCtxCompleteTaskViewValue = useMemo(() => ({
        completeTaskViewOpen: state.completeTaskViewOpen,
        completeTaskViewSelectedTask: state.completeTaskViewSelectedTask,
    }), [state.completeTaskViewOpen, state.completeTaskViewSelectedTask]);

    const tasksCtxTaskViewValue = useMemo(() => ({
        taskViewOpen: state.taskViewOpen,
        taskViewSelectedTask: state.taskViewSelectedTask,
    }), [state.taskViewOpen, state.taskViewSelectedTask]);
    
    return (
        <TasksCtxAPI.Provider value={api}>
            <TasksCtxActiveProjectBoards.Provider value={state.activeProjectBoards}>
            <TasksCtxActiveUserTasks.Provider value={state.activeUserTasks}>
            <TasksCtxCompleteTaskView.Provider value={tasksCtxCompleteTaskViewValue}>
            <TasksCtxDragDisabled.Provider value={state.dragDisabled}>
            <TasksCtxFilters.Provider value={state.filters}>
            <TasksCtxNewTasks.Provider value={state.newTasks}>
            <TasksCtxTasks.Provider value={state.tasks}>
            <TasksCtxTasksPendingDocuments.Provider value={state.tasksPendingDocuments}>
            <TasksCtxTaskView.Provider value={tasksCtxTaskViewValue}>
            <TasksCtxTeamTasks.Provider value={state.teamTasks}>

                {children}

                <TaskWindow />
                <CompleteTaskWindow />

            </TasksCtxTeamTasks.Provider>
            </TasksCtxTaskView.Provider>
            </TasksCtxTasksPendingDocuments.Provider>
            </TasksCtxTasks.Provider>
            </TasksCtxNewTasks.Provider>
            </TasksCtxFilters.Provider>
            </TasksCtxDragDisabled.Provider>
            </TasksCtxCompleteTaskView.Provider>
            </TasksCtxActiveUserTasks.Provider>
            </TasksCtxActiveProjectBoards.Provider>
        </TasksCtxAPI.Provider>
    );
};

const useTasksCtxAPI = () => useContext(TasksCtxAPI);
const useTasksCtxActiveProjectBoards = () => useContext(TasksCtxActiveProjectBoards);
const useTasksCtxActiveUserTasks = () => useContext(TasksCtxActiveUserTasks);
const useTasksCtxCompleteTaskView = () => useContext(TasksCtxCompleteTaskView);
const useTasksCtxDragDisabled = () => useContext(TasksCtxDragDisabled);
const useTasksCtxFilters = () => useContext(TasksCtxFilters);
const useTasksCtxNewTasks = () => useContext(TasksCtxNewTasks);
const useTasksCtxSelectedTask = () => useContext(TasksCtxSelectedTask);
const useTasksCtxTasks = () => useContext(TasksCtxTasks);
const useTasksCtxTasksPendingDocuments = () => useContext(TasksCtxTasksPendingDocuments);
const useTasksCtxTaskView = () => useContext(TasksCtxTaskView);
const useTasksCtxTeamTasks = () => useContext(TasksCtxTeamTasks);

export {
    TasksProvider,
    
    useTasksCtxAPI,
    
    useTasksCtxActiveProjectBoards,
    useTasksCtxActiveUserTasks,
    useTasksCtxCompleteTaskView,
    useTasksCtxDragDisabled,
    useTasksCtxFilters,
    useTasksCtxNewTasks,
    useTasksCtxSelectedTask,
    useTasksCtxTasks,
    useTasksCtxTasksPendingDocuments,
    useTasksCtxTaskView,
    useTasksCtxTeamTasks
};