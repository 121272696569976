import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import Modal from '../Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileWord, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { useDocumentsAPI } from '../../context/DocumentsContext';
import { SERVER_PATH } from '../../utils/constants';
import { checkDocumentVersion, storeStringifiedDocument } from '../../utils/eSignature';
import axios from 'axios';
import moment from 'moment';

const classes = {
    documentVersion: {
        border: '1px solid transparent',
        borderRadius: 8,
        cursor: 'pointer',
        padding: '8px 0',
        transition: 'border-color, background-color .1s ease-out',
        '&:hover': {
            borderColor: theme => theme.palette.primary.main
        }
    },
    selectedDocumentVersion: {
        backgroundColor: theme => theme.palette.background.transparency,
        borderColor: theme => theme.palette.primary.main
    }
};

const ChangeDocumentWindow = (props) => {
    const {
        activeUserIsOperator, changeDocumentWindowOpen, setChangeDocumentWindowOpen, selectedDocument, selectedDocumentVersion,
        updateDocument
    } = props;
    const theme = useTheme();
    const { dispatch: dispatchDocuments, hideSignDocumentView, showSignDocumentView } = useDocumentsAPI();
    const [selectedVersionId, setSelectedVersionId] = useState('');
    const [errorDescription, setErrorDescription] = useState('');
    const [signing, setSigning] = useState(false);
    const shouldOpenSignDocumentWindow = useRef(false);

    useEffect(() => {
        setSelectedVersionId('');
    }, [changeDocumentWindowOpen]);

    const handleDocumentVersionClick = (documentVersionId) => {
        setErrorDescription('');
        setSelectedVersionId(prevState => prevState === documentVersionId ? '' : documentVersionId);
    }

    const handleConfirmButtonClick = async () => {
        setSigning(true);
        if(selectedVersionId){
            let shouldStartESignatureProcess = true;
            const signDocumentViewAction = 'UPDATE DOCUMENT';
            const signDocumentViewSignaturePlatform = selectedDocumentVersion.eSignaturePlatform || 'zapSign';
            let newSignatureEnvelope = checkDocumentVersion({
                action: signDocumentViewAction,
                dispatchDocuments,
                eSignatureCallback: () => {
                    hideSignDocumentView();
                    setChangeDocumentWindowOpen(false);
                },
                eSignaturePlatform: signDocumentViewSignaturePlatform,
                selectedDocument,
                selectedVersionId,
                setDocuments: updateDocument,
                setErrorDescription,
                updateDocument,
                updatedVersion: selectedDocumentVersion
            });
            if(newSignatureEnvelope){
                newSignatureEnvelope = storeStringifiedDocument({ dispatchDocuments, eSignatureEnvelope: newSignatureEnvelope, selectedDocument, selectedVersionId, type: 'document' });

                let foundFolders = null // folders;
                if(activeUserIsOperator){
                    await axios.post(SERVER_PATH + '/data/projects/one', {
                        projectId: selectedDocument.project,
                        fields: ['client', 'info', 'signers', 'useClientESignatureSigners']
                    })
                    .then(res => {
                        const foundFolder = res.data.project;
                        foundFolders = [foundFolder];
                    })
                    .catch(err => {
                        shouldStartESignatureProcess = false;
                    });
                }
                if(shouldStartESignatureProcess){
                    let foundTemplates = null //? templates;
                    await axios.post(SERVER_PATH + '/data/templates/one', {
                        templateId: selectedDocument.template,
                        fields: ['form']
                    })
                    .then(res => {
                        const foundTemplate = res.data;
                        if(foundTemplate) foundTemplates = [foundTemplate];
                    })
                    .catch(err => {
                        shouldStartESignatureProcess = false;
                    });
                    if(shouldStartESignatureProcess){
                        setSigning(false);

                        shouldOpenSignDocumentWindow.current = true;

                        newSignatureEnvelope = {
                            ...newSignatureEnvelope,
                            signers: selectedDocumentVersion.eSignatureSigners.map(mappedSigner => ({
                                ...mappedSigner,
                                fullName: mappedSigner.name,
                                documentation: mappedSigner.taxpayerNumber,
                                brazilian: !!mappedSigner.taxpayerNumber,
                                // type:
                                // rubricas
                            })),
                            tabs: selectedDocumentVersion.eSignatureTabs,
                            selectedDocument: selectedDocument
                        };

                        dispatchDocuments({
                            type: 'SET SIGNATURE ENVELOPE',
                            payload: newSignatureEnvelope
                        });
                        
                        const documentName = selectedDocument.name;
                        const signDocumentViewFolderId = selectedDocument.project;

                        const documentFolder = null; //?TODO
                        const signDocumentViewFolderFolder = documentFolder ? (documentFolder.shortName || documentFolder._id) : undefined;
                        const signDocumentViewFolderName = '';
                        const signDocumentViewDefaultSigners = [];

                        showSignDocumentView({
                            action: signDocumentViewAction,
                            document: selectedDocument,
                            documentName,
                            documentLocation: signDocumentViewFolderFolder,
                            folderName: signDocumentViewFolderName,
                            folderId: signDocumentViewFolderId,
                            initialSigners: signDocumentViewDefaultSigners,
                            platform: signDocumentViewSignaturePlatform,
                            envelope: newSignatureEnvelope
                        });
                        
                        return;
                    }
                }
            }

            return;
        }
        setErrorDescription('Selecione um documento acima.');
        setSigning(false);
    };

    const VersionIcon = ({version}) => {
        let fileExtension = '';
        if(version.fileFormat){
            if(version.serverFile){
                fileExtension = version.link.split('.').pop();
            } else {
                fileExtension = version.googleDoc ? 'googleDoc' : version.link.split('.').pop();
                if(fileExtension !== 'pdf' && fileExtension !== 'docx') fileExtension = 'googleDoc';
            }
        } else {
            if(version.link){
                fileExtension = version.link.split('.').pop();
            } else if(version.googleDoc){
                fileExtension = 'googleDoc';
            }
        }

        if(fileExtension === 'pdf'){
            return <FontAwesomeIcon icon={faFilePdf} size="1x" color={theme.palette.secondary.main} />;
        } else if(fileExtension === 'docx'){
            return <FontAwesomeIcon icon={faFileWord} size="1x" color={theme.palette.primary.main} />;
        } else if(fileExtension === 'googleDoc'){
            return <FontAwesomeIcon icon={faGoogle} size="1x" color={theme.palette.primary.main} />;
        }

        return null;
    };
    
    return <>
        <Modal open={changeDocumentWindowOpen} onClose={() => setChangeDocumentWindowOpen(false)}>
            <Box mb={2}>
                <Grid container spacing={1} alignItems="center" justifyContent="center">
                    <Grid>
                        <Typography variant="h2">Alterar documento</Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box mb={2}>
                <Typography variant="body1">Você deseja alterar o documento? Escolha um documento abaixo para substituir o documento já enviado nesse envelope.</Typography>
            </Box>
            {
                changeDocumentWindowOpen &&
                <Box mb={2}>
                    {
                        selectedDocument.documents
                        .filter(scannedVersion => scannedVersion._id !== selectedDocumentVersion._id)
                        .map(mappedVersion => {
                            return (
                                <Box key={mappedVersion._id} mb={1}>
                                    <Grid
                                        sx={[classes.documentVersion, selectedVersionId === mappedVersion._id && classes.selectedDocumentVersion]} container spacing={1} alignItems="center" justifyContent="center"
                                        onClick={() => handleDocumentVersionClick(mappedVersion._id)}
                                    >
                                        <Grid>
                                            <VersionIcon version={mappedVersion} />
                                        </Grid>
                                        <Grid xs={5}>
                                            <Typography variant="body1">{mappedVersion.name || 'Primeira versão'}</Typography>
                                        </Grid>
                                        <Grid xs>
                                            <Typography variant="body1">{moment(mappedVersion.timestamp).format('L LTS')}</Typography>
                                        </Grid>
                                        <Grid xs={3}>
                                            <Typography variant="body1">{mappedVersion.createdByName}</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            );
                        })
                    }
                </Box>
            }
            {
                errorDescription &&
                <FormHelperText error>{errorDescription}</FormHelperText>
            }
            <Box>
                <Grid container spacing={1} alignItems="center" justifyContent="center">
                    <Grid>
                        <Button
                            startIcon={signing ? <CircularProgress size={20} /> : undefined}
                            disabled={signing}
                            variant="contained" color="primary"
                            onClick={handleConfirmButtonClick}
                        >Confirmar</Button>
                    </Grid>
                    <Grid>
                        <Button variant="text" onClick={() => setChangeDocumentWindowOpen(false)}>Cancelar</Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    </>;
};

export default ChangeDocumentWindow;