import { useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import Input from '../../../../../components/Input';
import Dialog from '../../../../../components/Dialog/Dialog';
import { useAppCtxAPI } from '../../../../../context/SystemContext';
import { useSocket } from '../../../../../context/SocketContext';
import { useClient } from '../../../../../context/ClientContext';
import { serverRequest } from '../../../../../utils/common';

const ListWindow = ({open, setOpen, data}) => {
    const { selectedListId } = data;
    const { toast } = useAppCtxAPI();
    const { createSocketConnection } = useSocket();
    const socket = createSocketConnection();
    const { getListById, setLists } = useClient();
    const defaultList = {
        name: {
            value: '',
            dirty: false
        },
        description: {
            value: '',
            dirty: false
        }
    };
    const [selectedList, set_selectedList] = useState(null);
    const [listValue, set_listValue] = useState(defaultList);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if(open){
            let currentList = null;
            if(selectedListId){
                currentList = getListById(selectedListId);
            }
            set_selectedList(currentList);

            let currentName = '', currentDescription = '';
            if(currentList){
                currentName = currentList.name;
                currentDescription = currentList.description;
            }
            set_listValue({
                name: {value: currentName, dirty: false},
                description: {value: currentDescription, dirty: false}
            })
        }
    }, [open, selectedListId, getListById]);

    const handleSave = async () => {
        setSaving(true);
        const listId = selectedList?.shortName;
        try {
            const data = await serverRequest({path: '/data/lists/save', method: 'post', data: {
                listId,
                list: {
                    name: listValue.name.value,
                    description: listValue.description.value
                }
            }});
            setLists(data.lists);
            socket.emit('CLIENT LISTS UPDATED', { updatedClient: data });
            handleClose();
        } catch (error) {
            toast(error);
        }
        setSaving(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            onClose={handleClose}
            onSaveClick={handleSave}
            open={open}
            saveButtonDisabled={!listValue.name.value || !listValue.description.value}
            saving={saving}
            title={selectedList ? selectedList.name : 'Nova lista'}
        >
        
            <Container maxWidth="xs">
                <Box mb={2}>
                    <Input
                        label="Nome" fullWidth required
                        value={listValue.name.value} onChange={(e) => set_listValue(prevState => ({...prevState, name: {...prevState['name'], value: e.target.value}}))}
                        error={listValue.name.dirty && !listValue.name.value}
                        helperText={listValue.name.dirty && !listValue.name.value ? 'Esta pergunta é obrigatória' : ''}
                        onBlur={() => set_listValue(prevState => ({...prevState, name: {...prevState['name'], dirty: true}}))}
                    />
                </Box>
                <Box mb={2}>
                    <Input
                        label="Descrição" fullWidth required multiline
                        value={listValue.description.value} onChange={(e) => set_listValue(prevState => ({...prevState, description: {...prevState['description'], value: e.target.value}}))}
                        error={listValue.description.dirty && !listValue.description.value}
                        helperText={listValue.description.dirty && !listValue.description.value ? 'Esta pergunta é obrigatória' : ''}
                        onBlur={() => set_listValue(prevState => ({...prevState, description: {...prevState['description'], dirty: true}}))}
                    />
                </Box>
            </Container>
            
        </Dialog>
    );
};

export default ListWindow;