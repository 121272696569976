import { useEffect, useRef, useState } from 'react';
import { Box, Button, IconButton, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Unstable_Grid2';
import useTheme from '@mui/material/styles/useTheme';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faEnvelope, faFilePdf, faFileSignature } from '@fortawesome/free-solid-svg-icons';
import { faGoogleDrive } from '@fortawesome/free-brands-svg-icons';
import AlertDialog from '../AlertDialog';
import ChangeDocumentWindow from './ChangeDocumentWindow';
import ChangeSignerWindow from './ChangeSignerWindow';
import Tooltip from '../Tooltip';
import UpdateSignatureStatusButton from '../UpdateSignatureStatusButton';
import { useAppCtxAPI, useAppCtxActiveClient, useAppCtxActiveUser, useAppCtxLoading } from '../../context/SystemContext';
import { useDocumentsAPI } from '../../context/DocumentsContext';
import { copy, serverRequest } from '../../utils/common';
import { FIRM_NAME, SERVER_PATH } from '../../utils/constants';
import { checkDocumentVersion, storeStringifiedDocument } from '../../utils/eSignature';
import axios from 'axios';

const classes = {
    box: {
        backgroundColor: 'rgba(255, 255, 255, 0.25)',
        border: '1px solid rgba(0, 0, 0, 0.08)',
        borderRadius: '8px',
        boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.08)',
        maxWidth: '100%',
        position: 'relative',
    },
    link: {
        color: theme => theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    signedButton: {
        // backgroundColor: theme.palette.background.paper,
        color: 'green',
        '&:hover': {
            cursor: 'default',
            // background: theme.palette.background.paper
        }
    }
};

const StyledAccordion = (props) => <Accordion sx={{
    root: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
}} {...props} />;

const SignatureStatus = (props) => {
    const {
        activeUserIsManager, activeUserIsOperator, data, documentType, hideSignButton, loadingSignature, updateDocument
    } = props;
    const {
        disabled,
        folderId,
        folderName,
        handleSignDocumentWindowOpenClick,
        project,
        subDocument,
        subDocumentParentDocument,
        setSelectedSubDocumentParentDocument
    } = data;
    const theme = useTheme();
    const { setLoading, toast } = useAppCtxAPI();
    const activeClient = useAppCtxActiveClient();
    const activeUser = useAppCtxActiveUser();
    const loading = useAppCtxLoading();
    const { dispatch: dispatchDocuments, hideSignDocumentView, showSignDocumentView } = useDocumentsAPI();
    const [changeDocumentWindowOpen, setChangeDocumentWindowOpen] = useState(false);
    const [changeSignerWindowOpen, set_changeSignerWindowOpen] = useState(false);
    const [selectedSigner, set_selectedSigner] = useState(null);
    const [signers, setSigners] = useState([]);
    const [signersWhoSigned, setSignersWhoSigned] = useState([]);
    const [signersWhoShouldSign, setSignersWhoShouldSign] = useState([]);
    const [numberOfSigners, setNumberOfSigners] = useState(0);
    const [numberOfSignersWhoSigned, setNumberOfSignersWhoSigned] = useState(0);
    const [signersVisible, setSignersVisible] = useState(subDocument?.eSignatureStatus !== 'signed' ? activeUser?.documentsSignaturesStatusExpanded || false : false);
    const [deleteSignaturesDialogOpen, setDeleteSignaturesDialogOpen] = useState(false);
    const shouldOpenSignDocumentWindow = useRef(false);
    const selectedDocumentRef = useRef(null);

    useEffect(() => {
        let currentSigners = [];
        let currentSignersWhoSigned = [];
        let currentSignersWhoShouldSign = [];
        let currentNumberOfSigners = 0;
        let currentNumberOfSignersWhoSigned = 0;
        if(subDocument){
            if(subDocument.eSignature){
                currentSigners = subDocument.eSignatureSigners;
                subDocument.eSignatureSigners.forEach((eSignatureSigner) => {
                    currentNumberOfSigners++;
                    eSignatureSigner.uid = eSignatureSigner.id;
                    if(eSignatureSigner.status === 'signed'){
                        currentSignersWhoSigned.push(eSignatureSigner);
                        currentNumberOfSignersWhoSigned++;
                    } else {
                        currentSignersWhoShouldSign.push(eSignatureSigner);
                    }
                });
            }
        }
        setSigners(currentSigners);
        setSignersWhoSigned(currentSignersWhoSigned);
        setSignersWhoShouldSign(currentSignersWhoShouldSign);
        setNumberOfSigners(currentNumberOfSigners);
        setNumberOfSignersWhoSigned(currentNumberOfSignersWhoSigned);
    }, [subDocument]);

    const handleCopyLink = (signer) => {
        const signerLink = `https://app.zapsign.com.br/verificar/${signer.id}`;

        if(signer.email === activeUser.email){
            window.open(signerLink, '_blank').focus();
        } else {
            copy(signerLink, toast);
        }
    };

    const handleDeleteSignaturePlatformDocument = () => {
        setDeleteSignaturesDialogOpen(true);
    };
    const handleDeleteSignaturePlatformDocumentConfirm = async () => {
        setDeleteSignaturesDialogOpen(false);
        ///?
        const versionIndex = subDocumentParentDocument.documents.findIndex(version => version._id === subDocument._id);
        if(versionIndex !== -1){
            setLoading(true);
            let postData = {
                actionUserName: activeUser.fullName,
                projectId: folderId || project?._id,
                currentDocument: subDocumentParentDocument,
                versionIndex,
                documentToken: subDocument.eSignaturePlatformId,
                eSignaturePlatform: subDocument.eSignaturePlatform,
            }; 
            await axios.post(SERVER_PATH + '/data/documents/sign/delete', postData)
            .then(res => {
                let documentNewValue = res.data;

                updateDocument(documentNewValue);
                    
                setSelectedSubDocumentParentDocument(documentNewValue);
                toast(`O documento enviado para assinatura foi excluído`);
            })
            .catch(err => {
                toast('Não foi possível excluir o documento. Tente novamente mais tarde.');
            });
            setLoading(false);
        }
    };

    const handleChangeSignerWindowOpenClick = (signerId, signer) => {
        selectedDocumentRef.current = subDocumentParentDocument;
        // setSelectedSubDocumentParentDocument(subDocumentParentDocument);
        set_selectedSigner(signer);
        set_changeSignerWindowOpen(true);
    };

    const handleReSendSignDocumentEmail = async (signerId, signer) => {
        setLoading(true);
        const postData = {
            eSignaturePlatform: subDocument.eSignaturePlatform,
            eSignaturePlatformId: subDocument.eSignaturePlatformId,
            documentName: subDocument.name,
            signerLink: subDocument.eSignaturePlatform !== 'docuSign' ? `https://app.zapsign.com.br/verificar/${signer.id}` : '',
            signerId: signer.id,
            signerEmail: signer.email,
            signerName: signer.name,
            senderEmail: activeUser?.email,
            senderName: activeUser?.fullName,
            projectName: folderName || project?.name
        };
        await axios.post(SERVER_PATH + '/data/documents/sign/email', postData)
        .then(res => {
            toast(`O e-mail com o link para assinatura foi disparado para ${signer.email}`);
        })
        .catch(err => {
            toast('Não foi possível enviar o e-mail. Tente novamente mais tarde.');
        });
        setLoading(false);
    };

    const handleUpdateDocument = () => {
        setChangeDocumentWindowOpen(true);
    }

    const handlePositionTabsButtonPress = async () => {
        updateDocument({...subDocumentParentDocument, loadingUpdateSignatureTabsInterface: true });
        const selectedVersionId = subDocument._id;
        if(selectedVersionId){
            let shouldStartESignatureProcess = true;
            const signDocumentViewAction = 'UPDATE TABS';
            let newSignatureEnvelope = checkDocumentVersion({
                action: signDocumentViewAction,
                dispatchDocuments,
                eSignatureCallback: () => {
                    hideSignDocumentView();
                },
                eSignaturePlatform: subDocument.eSignaturePlatform,
                selectedDocument: subDocumentParentDocument,
                selectedVersionId,
                setDocuments: updateDocument,
                updateDocument,
                updatedVersion: subDocument
            });
            if(newSignatureEnvelope){
                newSignatureEnvelope = storeStringifiedDocument({ dispatchDocuments, eSignatureEnvelope: newSignatureEnvelope, selectedDocument: subDocumentParentDocument, selectedVersionId, type: 'document' });

                let foundFolders = null // folders;
                if(activeUserIsOperator){
                    try {
                        const data = await serverRequest({path: '/data/projects/one', method: 'post', data: {
                            projectId: subDocumentParentDocument.project,
                            fields: ['client', 'info', 'signers', 'useClientESignatureSigners']
                        }});
                        const foundFolder = data.project;
                        foundFolders = [foundFolder];
                    } catch (error) {
                        shouldStartESignatureProcess = false;
                    }
                }
                if(shouldStartESignatureProcess){
                    let foundTemplates = null //? templates;
                    try {
                        const foundTemplate = await serverRequest({path: '/data/templates/one', method: 'post', data: {
                            templateId: subDocumentParentDocument.template,
                            fields: ['form']
                        }});
                        if(foundTemplate) foundTemplates = [foundTemplate];
                    } catch (error) {
                        shouldStartESignatureProcess = false;
                    }
                    if(shouldStartESignatureProcess){
                        updateDocument({...subDocumentParentDocument, loadingUpdateSignatureTabsInterface: false });
                        
                        shouldOpenSignDocumentWindow.current = true;

                        newSignatureEnvelope = {
                            ...newSignatureEnvelope,
                            signers: subDocument.eSignatureSigners.map((mappedSigner) => ({
                                ...mappedSigner,
                                uid: mappedSigner.id,
                                fullName: mappedSigner.name,
                                documentation: mappedSigner.taxpayerNumber,
                                brazilian: !!mappedSigner.taxpayerNumber,
                                // type:
                                // rubricas
                            })),
                            tabs: subDocument.eSignatureTabs,
                            selectedDocument: subDocumentParentDocument
                        };

                        dispatchDocuments({
                            type: 'SET SIGNATURE ENVELOPE',
                            payload: newSignatureEnvelope
                        });

                        let documentName, signDocumentViewFolderFolder, signDocumentViewFolderId, signDocumentViewFolderName, signDocumentViewSignaturePlatform, signDocumentViewDefaultSigners;
                        if(documentType === 'proposal'){
                            documentName = `Contrato - ${subDocumentParentDocument.description}`;
                            signDocumentViewFolderFolder = activeClient?.shortName || activeClient?._id;
                            signDocumentViewFolderName = FIRM_NAME;
                            signDocumentViewSignaturePlatform = 'zapSign';
                        } else {
                            documentName = subDocumentParentDocument.name;
                            signDocumentViewFolderId = subDocumentParentDocument.project;
                            signDocumentViewSignaturePlatform = subDocument.eSignaturePlatform || 'zapSign';
                            
                            const documentFolder = null; //?TODO
                            signDocumentViewFolderFolder = documentFolder ? (documentFolder.shortName || documentFolder._id) : undefined;
                            signDocumentViewFolderName = '';
                        }

                        showSignDocumentView({
                            action: signDocumentViewAction,
                            document: subDocumentParentDocument,
                            documentName,
                            documentLocation: signDocumentViewFolderFolder,
                            folderId: signDocumentViewFolderId,
                            folderName: signDocumentViewFolderName,
                            initialSigners: signDocumentViewDefaultSigners,
                            platform: signDocumentViewSignaturePlatform,
                            envelope: newSignatureEnvelope
                        });
                        return;
                    }
                }
            }

            return;
        }
        updateDocument({...subDocumentParentDocument, loadingUpdateSignatureTabsInterface: false });
    };
    
    const SignDocumentButton = () => {
        return (
            <Grid container justifyContent="center">
                <Grid>
                    <Button
                        startIcon={loadingSignature ? <CircularProgress size={20} /> : <FontAwesomeIcon icon={faFileSignature} />}
                        variant="outlined" size="small" color="primary"
                        disabled={loadingSignature || disabled || loading}
                        onClick={handleSignDocumentWindowOpenClick}
                    >Enviar para assinatura</Button>
                </Grid>
            </Grid>
        );
    };

    return <>
        {
            subDocument
            ?
            <Box>
                {
                    subDocument?.eSignature
                    ?
                        (
                            <Box sx={classes.box}>
                                {
                                    subDocument.eSignatureStatus === 'signed'
                                    ?
                                    <StyledAccordion expanded={false}>
                                        <AccordionSummary>
                                            <Grid container spacing={1} alignItems="center" wrap="nowrap">
                                                <Grid style={{height: 20, padding: 0}}>
                                                    <CheckCircleIcon fontSize="small" htmlColor="green" />
                                                </Grid>
                                                <Grid>
                                                    <Typography variant="h6" noWrap>Assinado</Typography>
                                                </Grid>
                                                {
                                                    subDocument.googleDriveFileUrl &&
                                                    <>
                                                        <Grid>
                                                            <Tooltip text="Acessar local do arquivo no Google Drive">
                                                                <IconButton size="small" href={`https://drive.google.com/drive/folders/${subDocument.googleDriveFolderId}`} target="_blank">
                                                                    <FontAwesomeIcon icon={faGoogleDrive} size="1x" color={theme.palette.primary.main} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid>
                                                            <Tooltip text="Acessar arquivo no Google Drive">
                                                                <IconButton size="small" href={subDocument.googleDriveFileUrl} target="_blank">
                                                                    <FontAwesomeIcon icon={faFilePdf} size="1x" color={theme.palette.primary.main} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    </>
                                                }
                                            </Grid>
                                        </AccordionSummary>
                                    </StyledAccordion>
                                    :
                                    <StyledAccordion expanded={signersVisible} onChange={() => setSignersVisible(!signersVisible)}>
                                        <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                                            <Grid container spacing={1} alignItems="center" wrap="nowrap">
                                                <Grid style={{height: 20, padding: 0}}>
                                                    <FontAwesomeIcon icon={faCircle} color="orange" />
                                                </Grid>
                                                <Grid>
                                                    <Typography variant="h6">{numberOfSignersWhoSigned}/{numberOfSigners} assinatura{numberOfSigners >= 2 ? 's' : ''}</Typography>
                                                </Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Box style={{width: '100%'}}>
                                                {
                                                    subDocument.eSignatureSigners.map((signer) => (
                                                        <Box key={signer.id} style={{width: '100%'}}>
                                                            <Grid container spacing={1} alignItems="center" wrap="nowrap" style={{width: '100%'}}>
                                                                <Grid style={{width: 22}}>
                                                                    {
                                                                        signer.type !== 'observer'
                                                                        ?
                                                                        <FontAwesomeIcon icon={faCircle} color={signer.status === 'signed' ? 'green' : 'orange'} />
                                                                        :
                                                                        <VisibilityIcon fontSize="small" />
                                                                    }
                                                                </Grid>
                                                                <Grid style={{width: `calc(100% - 22px${signer.status !== 'signed' ? ' - 30px' : ''}${activeUserIsManager && signer.status !== 'signed' ? ' - 30px' : ''})`}}>
                                                                    {
                                                                        (signer.status !== 'signed' && subDocument.eSignaturePlatform !== 'docuSign')
                                                                        ?
                                                                        <Box>
                                                                            <Tooltip text={signer.email === activeUser?.email ? 'Assinar' : `Copiar link para assinatura para ${signer.name} (${signer.email})`}>
                                                                                <Typography variant="body2" sx={classes.link} onClick={() => handleCopyLink(signer)} noWrap>{signer.name}</Typography>
                                                                            </Tooltip>
                                                                            <Typography variant="body2" style={{fontSize: 10}} noWrap>{signer.email}</Typography>
                                                                        </Box>
                                                                        :
                                                                        <Tooltip text={`${signer.name} (${signer.email})`}>
                                                                            <Typography variant="body2" noWrap>{signer.name}</Typography>
                                                                        </Tooltip>
                                                                    }
                                                                </Grid>
                                                                {
                                                                    (signer.type !== 'observer' && signer.status !== 'signed') &&
                                                                    <>
                                                                        <Grid style={{width: 30}}>
                                                                            <Tooltip text="Reenviar e-mail">
                                                                                <Box>
                                                                                    <IconButton
                                                                                        disabled={disabled || loading}
                                                                                        size="small" onClick={() => handleReSendSignDocumentEmail(signer.id, signer)}><FontAwesomeIcon icon={faEnvelope} /></IconButton>
                                                                                </Box>
                                                                            </Tooltip>
                                                                        </Grid>
                                                                        {
                                                                            activeUserIsManager &&
                                                                            <Grid style={{width: 30}}>
                                                                                <Tooltip text="Editar pessoa">
                                                                                    <Box>
                                                                                        <IconButton
                                                                                            disabled={disabled || loading}
                                                                                            size="small" onClick={() => handleChangeSignerWindowOpenClick(signer.id, signer)}><EditIcon /></IconButton>
                                                                                    </Box>
                                                                                </Tooltip>
                                                                            </Grid>
                                                                        }
                                                                    </>
                                                                }
                                                            </Grid>
                                                        </Box>
                                                    ))
                                                }
                                                {
                                                    (subDocument.eSignatureLink || activeUserIsManager) &&
                                                    <Box mt={1}>
                                                        <Grid container justifyContent="center" alignItems="center">
                                                            {
                                                                subDocument.eSignatureLink &&
                                                                <Grid>
                                                                    <Tooltip text="Documento parcialmente assinado">
                                                                        <IconButton
                                                                            size="small" color="primary"
                                                                            href={subDocument.eSignatureLink} target="_blank"
                                                                        ><CloudDownloadIcon /></IconButton>
                                                                    </Tooltip>
                                                                </Grid>
                                                            }
                                                            {
                                                                subDocument.eSignaturePlatform === 'docuSign' &&
                                                                <>
                                                                    {
                                                                        !subDocument.eSignatureSigners.some((signer) => signer.status === 'signed') &&
                                                                        <Grid>
                                                                            <Tooltip text="Substituir documento no envelope">
                                                                                <IconButton
                                                                                    size="small"
                                                                                    onClick={() => handleUpdateDocument()}
                                                                                ><EditIcon /></IconButton>
                                                                            </Tooltip>
                                                                        </Grid>
                                                                    }
                                                                    <Grid>
                                                                        {
                                                                            subDocumentParentDocument.loadingUpdateSignatureTabsInterface
                                                                            ? <CircularProgress size={20} />
                                                                            :
                                                                            <Tooltip text="Alterar posicionamento das assinaturas">
                                                                                <IconButton
                                                                                    size="small"
                                                                                    onClick={handlePositionTabsButtonPress}
                                                                                ><OpenWithIcon /></IconButton>
                                                                            </Tooltip>
                                                                        }
                                                                    </Grid>
                                                                </>
                                                                
                                                            }

                                                            <Grid xs></Grid>
                                                            <Grid>
                                                                <UpdateSignatureStatusButton document={subDocumentParentDocument} version={subDocument} updateDocument={updateDocument} />
                                                            </Grid>
                                                            {
                                                                activeUserIsManager &&
                                                                <Grid>
                                                                    <Tooltip text="Cancelar assinaturas">
                                                                        <IconButton
                                                                            disabled={disabled || loading}
                                                                            size="small" color="secondary"
                                                                            onClick={handleDeleteSignaturePlatformDocument}
                                                                        ><DeleteIcon /></IconButton>
                                                                    </Tooltip>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    </Box>
                                                }
                                            </Box>
                                        </AccordionDetails>
                                    </StyledAccordion>
                                }
                                <ChangeSignerWindow
                                    open={changeSignerWindowOpen} setOpen={set_changeSignerWindowOpen}
                                    data={{
                                        selectedSigner,
                                        subDocument,
                                        subDocumentParentDocument, setSelectedSubDocumentParentDocument
                                    }}
                                    selectedDocumentRef={selectedDocumentRef}
                                    updateDocument={updateDocument}
                                />
                                <ChangeDocumentWindow
                                    {...{
                                        activeUserIsOperator,
                                        changeDocumentWindowOpen, setChangeDocumentWindowOpen,
                                        selectedDocument: subDocumentParentDocument, setSelectedDocument: setSelectedSubDocumentParentDocument,
                                        selectedDocumentVersion: subDocument,
                                        updateDocument
                                    }}
                                />
                            </Box>
                        )
                    :
                    <>
                        {
                            !hideSignButton &&
                            <>
                                {
                                    documentType === 'document'
                                    ? <SignDocumentButton />
                                    :
                                        (
                                            (subDocument?.fileFormat === 'googleDoc' || subDocument?.fileFormat === 'pdf')
                                            ? <SignDocumentButton />
                                            :
                                            <Tooltip text="Você só pode assinar um Google Doc ou um PDF.">
                                                <IconButton size="small" color="primary" disabled>
                                                    <FontAwesomeIcon icon={faFileSignature} />
                                                </IconButton>
                                            </Tooltip>
                                        )
                                }
                            </>
                        }
                    </>
                }
            </Box>
            :
            null
        }
        <AlertDialog
            open={deleteSignaturesDialogOpen} onClose={() => setDeleteSignaturesDialogOpen(false)}
            text={`Você quer cancelar o processo de assinatura do documento "${subDocumentParentDocument ? subDocumentParentDocument.name || subDocumentParentDocument.description : ''}"? Essa ação é irreversível.`}
            okButtonOnClick={handleDeleteSignaturePlatformDocumentConfirm}
        />
    </>;
};

export default SignatureStatus;