import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Grid, IconButton, Link, Slide, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import AddOrSubtractDial from './AddOrSubtractDial';
import Dialog from './Dialog/Dialog';
import FilesField from './FilesField';
import FilesList from './FilesList';
import LoaderEllipsis from './LoaderEllipsis';
import Select from './Select';
import Switch from './Switch';
import { CLEARANCE_RISK_OPTIONS, ERROR_MESSAGE_UNKNOWN, SERVER_PATH } from '../utils/constants';
import { useAppCtxAPI } from '../context/SystemContext';
import { useOperatorFoldersCtxAPI, useOperatorFoldersCtxFolderClearanceView } from '../context/OperatorFoldersContext';
import axios from 'axios';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ChangeProjectClearanceInformationWindow = ({setFloatingNotification}) => {
    const { toast } = useAppCtxAPI();
    const { hideFolderClearanceView, setChangeFolderViewFoundFolder } = useOperatorFoldersCtxAPI();
    const { folderClearanceViewOpen, folderClearanceViewSelectedFolder: selectedFolder, folderUsers } = useOperatorFoldersCtxFolderClearanceView();
    const [loading, setLoading] = useState(true);
    const defaultClearanceAuthority = { type: 'user', authorityId: '', maximumRiskLevel: 'four' };
    const defaultClearanceNumberOfApprovalsRequired = {
        one: 0,
        two: 0,
        three: 0,
        four: 0
    };
    const defaultFolderState = {
        clearanceApprovalAuthorities: [],
        clearanceDashboardPassword: '',
        clearanceFiles: [],
        clearanceGuidelinesUrl: '',
        clearanceGroupsBehavior: 'restrict',
        clearanceNumberOfApprovalsRequired: defaultClearanceNumberOfApprovalsRequired,
        clearanceReportsRestrictedToManagers: false,
        clearanceRequireApproval: false,
        clearanceTemplate: '',
        clearanceType: ''
    };
    const [folderState, setFolderState] = useState(defaultFolderState);
    const [clearanceFiles, setClearanceFiles] = useState([]);
    const [saving, setSaving] = useState(false);
    const [templates, setTemplates] = useState(null);
    const [workTypeOptions, setWorkTypeOptions] = useState(null);

    useEffect(() => {
        if(folderClearanceViewOpen && selectedFolder){
            if(!templates) getTemplates();
            setFolderState(prevState => ({
                ...prevState,
                clearanceApprovalAuthorities: selectedFolder.clearanceApprovalAuthorities || [],
                clearanceDashboardPassword: selectedFolder.clearanceDashboardPassword || '',
                clearanceFiles: selectedFolder.clearanceFiles.map(file => file.path),
                clearanceGuidelinesUrl: selectedFolder.clearanceGuidelinesUrl || '',
                clearanceGroupsBehavior: selectedFolder.clearanceGroupsBehavior || 'restrict',
                clearanceNumberOfApprovalsRequired: {...defaultClearanceNumberOfApprovalsRequired, ...selectedFolder.clearanceNumberOfApprovalsRequired},
                clearanceReportsRestrictedToManagers: !!selectedFolder.clearanceReportsRestrictedToManagers,
                clearanceTemplate: selectedFolder.clearanceTemplate || '',
                clearanceType: selectedFolder.clearanceType || '',
            }));
        }
    }, [folderClearanceViewOpen, selectedFolder]);

    useEffect(() => {
        let approvalRequired = false;
        for(let riskLevelKey in folderState.clearanceNumberOfApprovalsRequired){
            if(folderState.clearanceNumberOfApprovalsRequired[riskLevelKey] >= 1) approvalRequired = true;
        }
        setFolderState(prevState => ({...prevState, clearanceRequireApproval: approvalRequired}));
    }, [folderState.clearanceNumberOfApprovalsRequired]);

    const getTemplates = async () => {
        await axios.get(SERVER_PATH + '/data/clearance/templates?shouldReturnObject=yes')
        .then(res => {
            setTemplates(res.data.templates);
            setWorkTypeOptions(res.data.workTypeOptions || null);
        })
        .catch(err => {
            setFloatingNotification({message: err?.response?.data || ERROR_MESSAGE_UNKNOWN, severity: 'error'});
        });
        setLoading(false);
    };

    const handleSubmit = async () => {
        setSaving(true);
        
        let formData = new FormData();
        if(selectedFolder) formData.append('updatedProjectId', selectedFolder._id);
        formData.append('clearanceDashboardPassword', folderState.clearanceDashboardPassword);
        formData.append('clearanceGuidelinesUrl', folderState.clearanceGuidelinesUrl);
        formData.append('clearanceGroupsBehavior', folderState.clearanceGroupsBehavior);
        if(folderState.clearanceRequireApproval) formData.append('clearanceRequireApproval', folderState.clearanceRequireApproval);
        formData.append('clearanceApprovalAuthorities', JSON.stringify(folderState.clearanceApprovalAuthorities));
        formData.append('clearanceNumberOfApprovalsRequired', JSON.stringify(folderState.clearanceNumberOfApprovalsRequired));
        if(folderState.clearanceReportsRestrictedToManagers) formData.append('clearanceReportsRestrictedToManagers', folderState.clearanceReportsRestrictedToManagers);
        formData.append('clearanceTemplate', folderState.clearanceTemplate);
        formData.append('clearanceType', folderState.clearanceType);
        
        const filesInput = document.getElementById('filesField');
        if(clearanceFiles.length >= 1){
            let files = filesInput.files;
            if(files.length > 0){
                for (let i = 0; i < files.length; i++) {
                    formData.append('file' + i, files[i]);
                }
            }
        }
        await axios.post(SERVER_PATH + '/data/operator/folders/projects/save', formData, {headers: {'Content-Type': 'multipart/form-data'}})
        .then(res => {
            if(clearanceFiles.length >= 1){
                if(filesInput) filesInput.value = '';
                setClearanceFiles([]);
            }
            setChangeFolderViewFoundFolder(res.data);
            setFolderState(defaultFolderState);
            toast('As informações foram salvas.');
            handleClose();
        })
        .catch(err => {
            setFloatingNotification({message: err?.response?.data || ERROR_MESSAGE_UNKNOWN, severity: 'error'});
        });
        setSaving(false);
    };

    const handleClose = () => {
        hideFolderClearanceView();
    };

    return (
        <Dialog
            onClose={handleClose}
            onSaveClick={handleSubmit}
            open={folderClearanceViewOpen}
            saving={saving}
            title={`${selectedFolder ? `${selectedFolder.name} | ` : ''}Clearance`}
            TransitionComponent={Transition}
        >

            <Container maxWidth="md">
                {
                    folderClearanceViewOpen &&
                    <>
                        {
                            !selectedFolder || !workTypeOptions || !templates || loading
                            ? <LoaderEllipsis primary />
                            :
                            <Box>
                                <Box mb={3}>
                                    <Box mb={2}>
                                        {/* <Typography variant="h6">Endereço da central de clearance</Typography> */}
                                    </Box>
                                    <Box mb={1}>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item xs={6}>
                                                <Select
                                                    disabled={loading || folderState.clearanceType}
                                                    label="Tipo"
                                                    options={workTypeOptions}
                                                    value={folderState.clearanceType} onChange={(e) => setFolderState(prevState => ({...prevState, clearanceType: e.target.value}))}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Select
                                                    disabled={loading || folderState.clearanceTemplate}
                                                    label="Matriz"
                                                    options={templates.map(template => ({value: template._id, label: template.name}))}
                                                    value={folderState.clearanceTemplate} onChange={(e) => setFolderState(prevState => ({...prevState, clearanceTemplate: e.target.value}))}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>

                                {
                                    (folderState.clearanceType && folderState.clearanceTemplate) &&
                                    <Box>
                                        <Box mb={3}>
                                            <Box mb={2}>
                                                <Typography variant="h6">Endereço da central de clearance</Typography>
                                            </Box>
                                            <Box mb={1}>
                                                <Typography variant="body1" noWrap><Link
                                                    href={`https://app.solidasistemas.com.br/clearance/${selectedFolder._id}`}
                                                    target="_blank"
                                                    underline="hover">https://app.solidasistemas.com.br/clearance/{selectedFolder._id}</Link></Typography>
                                            </Box>
                                        </Box>

                                        <Box mb={3}>
                                            <Box mb={2}>
                                                <Typography variant="h6">Configurações</Typography>
                                            </Box>
                                            <Box mb={1}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} sm={6} md={4}>
                                                        <TextField
                                                            variant="standard"
                                                            label="Senha da central de clearance"
                                                            fullWidth
                                                            value={folderState.clearanceDashboardPassword}
                                                            onChange={(e) => setFolderState(prevState => ({...prevState, clearanceDashboardPassword: e.target.value}))} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={8}>
                                                        <TextField
                                                            variant="standard"
                                                            label="Link para diretrizes de clearance"
                                                            fullWidth
                                                            value={folderState.clearanceGuidelinesUrl}
                                                            onChange={(e) => setFolderState(prevState => ({...prevState, clearanceGuidelinesUrl: e.target.value}))} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={9} md={6}>
                                                        <Select
                                                            label="Comportamento dos grupos"
                                                            options={[
                                                                {value: 'restrict', label: 'Restringir acesso'},
                                                                {value: 'categorize', label: 'Categorização apenas (com modo "somente leitura")'}
                                                            ]}
                                                            value={folderState.clearanceGroupsBehavior} onChange={(e) => setFolderState(prevState => ({...prevState, clearanceGroupsBehavior: e.target.value}))}
                                                        />
                                                    </Grid>
                                                    {
                                                        selectedFolder.useGroups &&
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={1} alignItems="center">
                                                                <Grid item>
                                                                    <Switch checked={folderState.clearanceReportsRestrictedToManagers} onChange={(e) => setFolderState(prevState => ({...prevState, clearanceReportsRestrictedToManagers: e.target.checked}))} />
                                                                </Grid>
                                                                <Grid item xs>
                                                                    <Typography variant="body1">Relatórios de clearance visíveis apenas para Gerentes</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Box>
                                        </Box>

                                        <Box mb={3}>
                                            <Box mb={2}>
                                                <Typography variant="h6">Arquivos</Typography>
                                            </Box>
                                            <Box mb={1}>
                                                <Box mb={2}>
                                                    <FilesList list={folderState.clearanceFiles} />
                                                </Box>
                                                <Box mb={1}>
                                                    <FilesField
                                                        attachFileButtonComponent="Button" horizontalAlign="flex-start"
                                                        accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*"
                                                        filesList={clearanceFiles} setFilesList={setClearanceFiles}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>

                                        <Box mb={3}>
                                            <Box mb={2}>
                                                <Typography variant="h6">Número de aprovações exigidas por nível de risco</Typography>
                                            </Box>
                                            <Box mb={1}>
                                                <Box mb={1}>
                                                    {
                                                        CLEARANCE_RISK_OPTIONS.map(option => {
                                                            return (
                                                                <Box key={option.value}>
                                                                    <Grid container spacing={1} alignItems="center">
                                                                        <Grid item>
                                                                            <span><span style={{color: option.color}}>&#11044;</span>&nbsp;{option.label}</span>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <AddOrSubtractDial
                                                                                subtractDisabled={folderState.clearanceNumberOfApprovalsRequired[option.value] === 0}
                                                                                subtractOnClick={() => setFolderState(prevState => ({...prevState, clearanceNumberOfApprovalsRequired: {...prevState.clearanceNumberOfApprovalsRequired, [option.value]: folderState.clearanceNumberOfApprovalsRequired[option.value] - 1}}))}
                                                                                number={folderState.clearanceNumberOfApprovalsRequired[option.value]}
                                                                                addOnClick={() => setFolderState(prevState => ({...prevState, clearanceNumberOfApprovalsRequired: {...prevState.clearanceNumberOfApprovalsRequired, [option.value]: folderState.clearanceNumberOfApprovalsRequired[option.value] + 1}}))}
                                                                                shouldShowNumber
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>
                                                            )
                                                        })
                                                    }
                                                </Box>
                                            </Box>
                                        </Box>

                                        {
                                            folderState.clearanceRequireApproval &&
                                            <Box mb={3}>
                                                <Box mb={2}>
                                                    <Typography variant="h6">Autoridades que aprovam riscos</Typography>
                                                </Box>
                                                <Box mb={1}>
                                                    <Box mb={2}>
                                                        <Typography variant="body1">Se houver mais uma autoridade, qualquer uma delas poderá aprovar anotações de clearance do mesmo tipo. Se a autoridade for um grupo, qualquer pessoa do grupo poderá realização a aprovação.</Typography>
                                                    </Box>
                                                    <Box mb={1}>
                                                        {
                                                            folderState.clearanceApprovalAuthorities.map((authority, authorityIndex) => (
                                                                <Box key={authorityIndex}>
                                                                    <Grid container spacing={1} alignItems="flex-end">
                                                                        <Grid item xs={6} sm={6} md={2} lg={2}>
                                                                            <Select
                                                                                label="Tipo"
                                                                                options={[{ value: 'user', label: 'Pessoa' }, { value: 'group', label: 'Grupo' }]}
                                                                                value={authority.type} onChange={(e) => setFolderState(prevState => ({...prevState, clearanceApprovalAuthorities: [...prevState.clearanceApprovalAuthorities.slice(0, authorityIndex), {...prevState[authorityIndex], type: e.target.value}, ...prevState.clearanceApprovalAuthorities.slice(authorityIndex + 1)]}))}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={6} sm={6} md={3} lg={3}>
                                                                            <Select
                                                                                label={authority.type === 'user' ? 'Pessoa' : 'Grupo'}
                                                                                options={authority.type === 'user' ? folderUsers.map(user => ({ value: user._id, label: user.fullName })) : selectedFolder.groups.map(group => ({ value: group.id, label: group.name }))}
                                                                                value={authority.authorityId} onChange={(e) => setFolderState(prevState => ({...prevState, clearanceApprovalAuthorities: [...prevState.clearanceApprovalAuthorities.slice(0, authorityIndex), {...prevState.clearanceApprovalAuthorities[authorityIndex], authorityId: e.target.value}, ...prevState.clearanceApprovalAuthorities.slice(authorityIndex + 1)]}))}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={6} sm={6} md={6} lg={5}>
                                                                            <Select
                                                                                label="Nível de risco que pode aprovar"
                                                                                options={CLEARANCE_RISK_OPTIONS.map(option => ({...option, label: <span><span style={{color: option.color}}>&#11044;</span>&nbsp;{option.label}</span>}))}
                                                                                value={authority.maximumRiskLevel} onChange={(e) => setFolderState(prevState => ({...prevState, clearanceApprovalAuthorities: [...prevState.clearanceApprovalAuthorities.slice(0, authorityIndex), {...prevState.clearanceApprovalAuthorities[authorityIndex], maximumRiskLevel: e.target.value}, ...prevState.clearanceApprovalAuthorities.slice(authorityIndex + 1)]}))}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <IconButton size="small"
                                                                                onClick={() => setFolderState(prevState => ({...prevState, clearanceApprovalAuthorities: [...prevState.clearanceApprovalAuthorities.slice(0, authorityIndex), ...prevState.clearanceApprovalAuthorities.slice(authorityIndex + 1)]}))}
                                                                            ><ClearIcon /></IconButton>
                                                                        </Grid>
                                                                    </Grid>
                                                                    {/* <Divider /> */}
                                                                </Box>
                                                            ))
                                                        }
                                                    </Box>
                                                    <Box mb={1}>
                                                        <Grid container justifyContent="flex-end">
                                                            <Grid item>
                                                                <Button
                                                                    startIcon={<AddIcon />}
                                                                    onClick={() => setFolderState(prevState => ({...prevState, clearanceApprovalAuthorities: [...prevState.clearanceApprovalAuthorities, defaultClearanceAuthority]}))}
                                                                >Cadastrar autoridade</Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        }
                                    </Box>
                                }
                            </Box>
                        }
                    </>
                }
            </Container>

        </Dialog>
    );
};

export default ChangeProjectClearanceInformationWindow;