import { Box, Divider } from '@mui/material';
import UserComment from './UserComment';
import Window from './Window';
import { getUserImageById, getUserNameById } from '../utils/common';

const QuestionHistoryWindow = ({open, onClose, selectedQuestion, users}) => {
    return (
        <Window open={open} onClose={onClose}
            title={selectedQuestion?.subject}
        >
            {
                selectedQuestion?.comments.map((comment, index) => {
                    const userName = comment.createdByFullName || getUserNameById(users, comment.createdBy, true);
                    const userImage = comment.createdByImg || getUserImageById(users, comment.createdBy);
                    const commentFiles = comment.files;
                    const hasFiles = commentFiles && commentFiles.length >= 1;
                    return (
                        <Box key={index} mb={1}>
                            <UserComment
                                comment={comment}
                                commentFiles={commentFiles}
                                hasFiles={hasFiles}
                                userImage={userImage}
                                userName={userName}
                            />
                            <Divider />
                        </Box>
                    )
                })
            }
        </Window>
    );
};

export default QuestionHistoryWindow;