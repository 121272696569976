import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const NewEffect = ({ handleChange, setDisabled }) => {
    const [value, setValue] = useState({ question: '' });

    useEffect(() => {
        handleChange(value);
        if(setDisabled){
            let isDisabled = false;
            if(!value.question) isDisabled = true;
            setDisabled(isDisabled);
        }
    }, [value]);

    return (
        <Box>
            <Box mb={1}>
                <Typography variant="h6">Pergunta</Typography>
            </Box>
            <TextField
                variant="standard"
                fullWidth
                placeholder="Sua pergunta"
                value={value.question}
                onChange={(e) => setValue(prevState => ({...prevState, question: e.target.value}))} />
        </Box>
    );
};

export default NewEffect;