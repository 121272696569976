import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import EditorEffectsTypes from '../../../../../../documents/templates/editor-effect-components/EditorEffectsTypes';
import Window from '../../../../../../components/Window';
import { useDocumentEditor } from '../context/DocumentEditorContext';

const EffectsWindow = ({open, setOpen, selectedEffect}) => {
    const { handleCopyEffect } = useDocumentEditor(); 
    
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Window open={open} onClose={handleClose}>
            {
                selectedEffect &&
                <Box>
                    <EditorEffectsTypes selectedEffect={selectedEffect} type={selectedEffect.type} />

                    <Box mt={2}>
                        <Box mb={1} style={{textAlign: 'center'}}>
                            <Button disabled={!selectedEffect} variant="contained" color="primary" startIcon={<FileCopyIcon />} onClick={() => handleCopyEffect(selectedEffect)}>Copiar</Button>
                        </Box>
                    </Box>
                </Box>
            }
        </Window>
    );
}

export default EffectsWindow;