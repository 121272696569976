import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import InputMask from 'react-input-mask';

const IndividualIdQuestion = ({initialValue, onChange, question}) => {
    const defaultValue = { input: '' };
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        setValue(initialValue || defaultValue);
    }, [initialValue]);

    useEffect(() => {
        if(onChange) onChange(question.name, value);
    }, [value]);

    const handleChange = (newValue) => {
        setValue(prevState => ({...prevState, input: newValue}));
    };
    
    return (
        <InputMask
            mask="999.999.999-99"
            value={value.input} onChange={(e) => handleChange(e.target.value)}
            maskChar=""
        >
            {() => (
                <TextField variant="standard" fullWidth placeholder="Sua resposta" />
            )}
        </InputMask>
    );
};

export default IndividualIdQuestion;