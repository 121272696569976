import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { getUserNameById, updateStateChangeArrayItemWith_id } from '../../../../utils/common';
import { useAppCtxAPI, useAppCtxActiveUser } from '../../../../context/SystemContext';
import { useSocket } from '../../../../context/SocketContext';
import moment from 'moment';

const classes = {
    annotationWarningContainer: {
        position: 'absolute', top: 0, right: 0, transform: 'translate(-105%, -50%)',
    },
    annotationWarning: {
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        border: '1px solid rgba(0, 0, 0, 0.08)',
        borderRadius: '8px',
        boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.08)',
        padding: '8px'
    }
};

const CustomText = ({col, doc, setDocuments, users}) => {
    const { toast, update } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const { createSocketConnection } = useSocket();
    const socket = createSocketConnection();
    const [lastValue, set_lastValue] = useState(null);
    const [value, setValue] = useState('');
    const [showAnnotationWarning, setShowAnnotationWarning] = useState(false);

    useEffect(() => {
        let customCol = getColumnById();
        if(customCol){
            set_lastValue(customCol);
            setValue(customCol.value);
        }
    }, []);

    useEffect(() => {
        const documentFolderUserAnnotationChangedEventName = 'DOCUMENT FOLDER USER ANNOTATION CHANGED';

        const handleDocumentFolderUserAnnotationChangedEvent = (data) => {
            const { updatedDocument } = data;
            if(updatedDocument._id === doc._id){
                const annotation = getColumnById(updatedDocument.folderUserAnnotation || []);
                if(annotation){
                    set_lastValue(annotation);
                    setValue(annotation.value);
                }
            }
        };

        socket.on(documentFolderUserAnnotationChangedEventName, handleDocumentFolderUserAnnotationChangedEvent);
        return () => {
            socket.off(documentFolderUserAnnotationChangedEventName, handleDocumentFolderUserAnnotationChangedEvent);
        };
    }, []);

    const getColumnById = (data) => {
        const annotations = data || doc.customCols;
        if(Array.isArray(annotations)){
            const fieldIndex = annotations.findIndex(field => field.id === col.id);
            if(annotations[fieldIndex]) return annotations[fieldIndex]; //object
        }
        return null;
    };
    const handleBlur = async () => {
        setShowAnnotationWarning(false);
        let customCol = getColumnById();
        if(customCol && customCol.value !== value){
            let recordCopy = {...doc};
            let newRecord = {...doc};
            
            let newValue;
            if(Array.isArray(newRecord.customCols)){
                newValue = [...newRecord.customCols];
            } else {
                newValue = [];
            }
            let currentColumn = getColumnById();
            if(!currentColumn) newValue.push({id: col.id, value: ''});
            
            let newLastValue = {value: value, valueBy: activeUser._id, valueByName: activeUser.fullName, valueOn: moment().format()};
            let colIndex = newValue.findIndex(c => c.id === col.id);
            newValue[colIndex] = {
                ...newValue[colIndex],
                ...newLastValue
            };
            
            newRecord.customCols = newValue;
            updateStateChangeArrayItemWith_id(newRecord, setDocuments);
            try {
                await update('Documents', newRecord._id, {customCols: newValue});
                set_lastValue(newLastValue);
                socket.emit('DOCUMENT FOLDER USER ANNOTATION CHANGED', { updatedDocument: {...newRecord, folderUserAnnotation: newValue} });
            } catch (error) {
                updateStateChangeArrayItemWith_id(recordCopy, setDocuments);
                toast('Erro! As alterações foram desfeitas.');
            }
        }
    };

    return (
        <Box>
            <Box style={{position: 'relative'}}>
                <TextField
                    variant="standard"
                    fullWidth
                    multiline
                    InputProps={{style: {fontSize: 12}}}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    onBlur={handleBlur}
                    onFocus={() => setShowAnnotationWarning(true)} />
                <Box sx={classes.annotationWarningContainer}>
                    <Collapse in={showAnnotationWarning}>
                        <Box sx={classes.annotationWarning}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <HelpOutlineIcon style={{color: 'rgba(0, 0, 0, 0.54)'}} />
                                </Grid>
                                <Grid item xs>
                                    <Typography variant="body2" style={{color: 'rgba(0, 0, 0, 0.90)'}}>As anotações são para uso interno e não notificam os revisores do documento.</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Collapse>
                </Box>
            </Box>
            {
                (lastValue && (lastValue.valueOn || lastValue.valueBy)) &&
                <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <Box>
                        <EditIcon style={{fontSize: 11}} />
                    </Box>
                    <Box>
                        <Typography variant="body2" style={{fontSize: 9}}>
                            <em>
                                {lastValue.valueOn ? moment(lastValue.valueOn).format('L LT') : ''}
                                {(lastValue.valueBy && users?.length >= 1) ? ` por ${getUserNameById(users, lastValue.valueBy)}` : ''}
                            </em>
                        </Typography>
                    </Box>
                </Box>
            }
        </Box>
    );
};

export default CustomText;