import Box from '@mui/material/Box';
import FormQuestionBox from './FormQuestionBox';
import CheckboxQuestion from './FormQuestionCheckbox';
import CorporationIdQuestion from './FormQuestionCorporationId';
import CurrencyQuestion from './FormQuestionCurrency';
import DateQuestion from './FormQuestionDate';
import IndividualIdQuestion from './FormQuestionIndividualId';
import FormQuestionList from './FormQuestionList';
import FormQuestionMovablePropertyLeaseDetails from './FormQuestionMovablePropertyLeaseDetails';
import NumberQuestion from './FormQuestionNumber';
import FormQuestionPaymentSchedule from './FormQuestionPaymentSchedule';
import RadioQuestion from './FormQuestionRadio';
import FormQuestionRadioGrid from '../documents/templates/form-question-skeleton-components/FormQuestionRadioGrid';
import ScheduleQuestion from './FormQuestionSchedule';
import ActorScheduleQuestion from './FormQuestionActorSchedule';
import SelectQuestion from './FormQuestionSelect';
import TextQuestion from './FormQuestionText';

const Question = ({formActiveClient, initialValue, onChange, question, style}) => {
    const acceptedComponents = {
        checkbox: CheckboxQuestion,
        corporationId: CorporationIdQuestion,
        currency: CurrencyQuestion,
        date: DateQuestion,
        individualId: IndividualIdQuestion,
        list: FormQuestionList,
        movablePropertyLeaseDetails: FormQuestionMovablePropertyLeaseDetails,
        number: NumberQuestion,
        paymentSchedule: FormQuestionPaymentSchedule,
        radio: RadioQuestion,
        radioGrid: FormQuestionRadioGrid,
        schedule: ScheduleQuestion,
        actorSchedule: ActorScheduleQuestion,
        select: SelectQuestion,
        special: FormQuestionList,
        text: TextQuestion,
        t: TextQuestion,
        me: RadioQuestion,
        e: FormQuestionList,
    }

    if(question && question.type){
        const QuestionType = acceptedComponents[question.type];
        if(QuestionType){
            return (
                <FormQuestionBox
                    type={question.type}
                    name={question.name}
                    description={question.description}
                    mandatory={question.mandatory}
                    style={style}
                >
                    <Box mt={2}>
                        <QuestionType formActiveClient={formActiveClient} question={question} onChange={onChange} initialValue={initialValue} />
                    </Box>
                </FormQuestionBox>
            );
        }
    }
    return null;
};

export default Question;