import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

const ActiveProjectsWorkTypeBoxes = ({activeProjectTypes, onClick, style, textStyle}) => {
    
    const handleActiveProjectWorkTypeBoxClick = (activeProjectType) => {
        onClick(activeProjectType);
    };

    const getBoxStyle = (activeProjectType) => {
        if(style){
            if(typeof style === 'function'){
                return style(activeProjectType);
            }
            return style;
        }
        return undefined;
    };
    
    const getTextStyle = (activeProjectType) => {
        if(textStyle){
            if(typeof textStyle === 'function'){
                return textStyle(activeProjectType);
            }
            return textStyle;
        }
        return undefined;
    };

    return (
        <>
            {
                activeProjectTypes.map(activeProjectType => (
                    <Grid sx={{ minWidth: activeProjectType.minWidth }}>
                        <Box
                            sx={{
                                backgroundColor: activeProjectType.color,
                                border: '2px solid transparent',
                                borderRadius: '4px',
                                boxShadow: '0 0 8px rgba(0, 0, 0, 0.08)',
                                cursor: 'pointer',
                                opacity: 0.6,
                                padding: '4px 4px',
                                textAlign: 'center',
                                transition: 'border .2s ease-out, opacity .2s ease-out',
                                '&:hover': {
                                    border: '2px solid #313330',
                                    opacity: 1,
                                },
                                '&:hover .active-project-work-type-box-text': {
                                    opacity: 1
                                },
                            }}
                            style={getBoxStyle(activeProjectType)}
                            onClick={() => handleActiveProjectWorkTypeBoxClick(activeProjectType)}
                            title={activeProjectType.label}
                        >
                            <Typography variant="body2" noWrap className="active-project-work-type-box-text" sx={{color: theme => theme.palette.getContrastText(activeProjectType.color), opacity: 0.7, transition: 'opacity .2s ease-out'}} style={getTextStyle(activeProjectType)}><strong>{activeProjectType.label}</strong></Typography>
                        </Box>
                    </Grid>
                ))
            }
        </>
    );
}

export default ActiveProjectsWorkTypeBoxes;