import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { formEffectTypes } from '../../../utils/constants';
import { number } from '../settings/documents-templates-number';

const EditorEffect = (props) => {
    const { selectedEffect } = props;
    const typeSpecifics = selectedEffect.typeSpecifics;
    const typeSpecificsOption = number.typeSpecificsOptions.find(typeSpecificsOption => typeSpecificsOption.value === typeSpecifics);

    return (
        <Box>
            <Box mb={2}>
                <Typography variant="h3">{selectedEffect.label || selectedEffect.question}</Typography>
            </Box>
            <Box mb={1}>
                <Typography variant="body1"><strong>Tipo:</strong> {formEffectTypes.find(formEffectType => formEffectType.value === selectedEffect.type).label}</Typography>
            </Box>
            <Box mb={1}>
                <Typography variant="body1"><strong>Pergunta:</strong> {selectedEffect.question}</Typography>
            </Box>
            <Box mb={1}>
                <Typography variant="body1"><strong>Exibir:</strong> {typeSpecificsOption.label}</Typography>
            </Box>
        </Box>
    )
};

export default EditorEffect;