import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PopOver from '../../../../../components/PopOver';
import Select from '../../../../../components/Select';
import Tooltip from '../../../../../components/Tooltip';
import { useAppCtxActiveUser } from '../../../../../context/SystemContext';
import { useDocumentsAPI } from '../../../../../context/DocumentsContext';
import { useOperatorCtxOperators } from '../../../context/OperatorContext';

const FiltersPopOver = ({data}) => {
    const {
        filtersPopOverOpen, set_filtersPopOverOpen, filtersPopOverAnchorEl,
        clientOptions,
        handleClientChange,
        loadingPage,
        projectOptions,
        selectedClient,
        selectedProject,
        selectedReviewer,
        set_selectedProject,
        set_selectedReviewer
    } = data;
    const activeUser = useAppCtxActiveUser();
    const { setState: setDocumentsCtxState } = useDocumentsAPI();
    const operators = useOperatorCtxOperators();

    const clearFilters = () => {
        handleClose();
        handleClientChange('all');
        set_selectedReviewer('~all;');
    };
    const handleClose = () => {
        set_filtersPopOverOpen(false);
    };
    
    const handleFiltersCompanyChange = (newValue) => {
        handleClose();
        handleClientChange(newValue);
    };
    const handleFiltersFolderChange = (newValue) => {
        handleClose();
        set_selectedProject(newValue);
    };
    const handleFiltersReviewerChange = (newValue) => {
        handleClose();

        setDocumentsCtxState('selectedPendingDocumentsReviewer', newValue);
    };

    return (
        <PopOver open={filtersPopOverOpen} onClose={handleClose} anchorEl={filtersPopOverAnchorEl}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
        >
            <Box mb={2}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs>
                        <Typography variant="h5">Filtros</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={clearFilters}
                        >Limpar</Button>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Select
                            disabled={loadingPage}
                            label="Empresa"
                            value={selectedClient} onChange={(e) => handleFiltersCompanyChange(e.target.value)}
                            options={[{value: 'all', label: 'Todos'}, ...clientOptions]}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Select
                            disabled={loadingPage}
                            label="Pasta"
                            value={selectedProject} onChange={(e) => handleFiltersFolderChange(e.target.value)}
                            options={[{value: 'all', label: 'Todos'}, {value: 'none', label: 'Nenhum'}, ...projectOptions]}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Select
                            disabled={loadingPage}
                            label="Revisor"
                            options={[{value: '~all;', label: 'Todos'}, {value: '~none;', label: 'Nenhum'}, ...operators.map(o => ({value: o._id, label: o.screenName}))]}
                            value={selectedReviewer} onChange={(e) => handleFiltersReviewerChange(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} container alignItems="flex-end" justifyContent="flex-start">
                        <Grid item>
                            <Tooltip text="Nenhum">
                                <IconButton disabled={loadingPage} size="small" color="primary" onClick={() => handleFiltersReviewerChange('~none;')}><PersonOutlineIcon /></IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip text={activeUser?.screenName}>
                                <IconButton disabled={loadingPage} size="small" color="primary" onClick={() => handleFiltersReviewerChange(activeUser?._id)}><PersonIcon /></IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip text="Todos">
                                <IconButton disabled={loadingPage} size="small" color="primary" onClick={() => handleFiltersReviewerChange('~all;')}><PeopleIcon /></IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </PopOver>
    );
};

export default FiltersPopOver;