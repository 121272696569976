import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Box, Button, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DeleteIcon from '@mui/icons-material/Delete';
import AlertDialog from '../../../../components/AlertDialog';
import CenteredCircularProgress from '../../../../components/CenteredCircularProgress';
import Checkbox from '../../../../components/Checkbox';
import NoResults from '../../../../components/NoResults';
import Select from '../../../../components/Select';
import TinyTable from '../../../../components/TinyTable';
import Tooltip from '../../../../components/Tooltip';
import ViewBox from '../../../../components/ViewBox';
import { updateStateChangeArrayItemWith_id, getGroupNameById, getTemplateNameById, getUserNameById } from '../../../../utils/common';
import moment from 'moment';
import { ERROR_MESSAGE_UNKNOWN, SERVER_PATH } from '../../../../utils/constants';
import { useAppCtxAPI, useAppCtxActiveUser, useAppCtxSelectedFolder, useAppCtxUseCompanyRootFolder, useAppCtxUserGroups } from '../../../../context/SystemContext';
import { useFolderUserCtxFolderGroups, useFolderUserCtxTemplates, useFolderUserCtxUsers } from '../../context/ClientContext';
import { useForm } from '../../../Form/context/FormContext'; 

function SavedRequestDocForms(){
    const { workspaceId } = useParams();
    const { handleNavigate, setLoading, setSelectedFolder, toast } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const selectedFolder = useAppCtxSelectedFolder();
    const useCompanyRootFolder = useAppCtxUseCompanyRootFolder();
    const { activeUserGroups, operatorStatus, userPosition } = useAppCtxUserGroups();
    const folderGroups = useFolderUserCtxFolderGroups();
    const templates = useFolderUserCtxTemplates();
    const users = useFolderUserCtxUsers();
    const { resetForm, savedForm, set_savedForm, set_initialForm, set_userForm, set_selectedFormId, set_selectedForm, set_visibleQuestions } = useForm();
    const groupOptions = folderGroups.map(group => ({value: group.id, label: group.name}));
    const defaultGroup = folderGroups.find(group => group.id === '~all;' || group.id === '&all' || group.id === 'all');
    const [selectedGroup, set_selectedGroup] = useState(folderGroups.length > 1 ? defaultGroup.id : null);
    const [activeUserCheck, set_activeUserCheck] = useState(false);
    const [savedForms, set_savedForms] = useState([]);
    const [clickedRecord, set_clickedRecord] = useState(null);
    const [deleteRecordDialogOpen, set_deleteRecordDialogOpen] = useState(false);
    const [loadingPage, setLoadingPage] = useState(true);
    const [loadingUseForm, setLoadingUseForm] = useState(false);
    const [filterInputValue, setFilterInputValue] = useState('');

    useEffect(() => {
        set_savedForm(null);
        set_userForm({});
        set_initialForm({});
        set_selectedFormId('');
        set_selectedForm(null);
        set_visibleQuestions([]);
        resetForm();
    }, []);

    useEffect(() => {
        set_savedForms([]);
        if(selectedFolder && activeUserGroups){
            let forms = selectedFolder.forms;
            if(selectedFolder.useGroups){
                const filterGroup = (groupId) => {
                    let allowedGroups = ['~all;', '&all', '~none;', '&none', '~any;', '', undefined];
                    if(allowedGroups.includes(groupId)){
                        return true;
                    } else {
                        let show = activeUserGroups.find(g => g.id === groupId);
                        return show;
                    }
                };

                if(activeUserGroups.length >= 1){
                    forms = forms.filter(form => filterGroup(form.groupId))
                    set_savedForms(forms);
                    set_selectedGroup(defaultGroup.id);
                    setLoadingPage(false);
                }
            } else {
                set_savedForms(forms);
                setLoadingPage(false);
            }
        }
    }, [selectedFolder, activeUserGroups]);

    const handle_selectedGroupChange = (newValue) => {
        set_selectedGroup(newValue);
        filter(null, newValue);
    };
    
    const filter = (activeUserCheckboxNewValue = activeUserCheck, selectedGroupNewValue = selectedGroup) => {
        const filterActiveUserCheckbox = (item) => {
            if(activeUserCheckboxNewValue && item.user !== activeUser?._id) return false;
            return true;
        };
        const filterGroup = (item) => {
            if(selectedFolder.useGroups){
                let groupId = item.groupId;
                if(selectedGroupNewValue !== '~all;' && selectedGroupNewValue !== '&all'){
                    return selectedGroupNewValue === groupId;
                }
            }
            return true;
        };
        const filterText = (listElement, text) => {
            let show = true;
            if(text){
                show = false;
                text = text.toUpperCase();
                txtValue = listElement.textContent || listElement.innerText;
                if(txtValue.toUpperCase().indexOf(text) > -1) show = true;
            }
            return show;
        };
        
        let elementsList, listElements, i, txtValue;
        elementsList = document.getElementById('forms-list');
        listElements = elementsList.getElementsByTagName('li');
        let text = document.getElementById('forms-filter-input').value;
        for (i = 0; i < listElements.length; i++) {
            let itemId = listElements[i].getAttribute('data-id');
            let item = savedForms.find(f => f._id === itemId);
            let show = filterActiveUserCheckbox(item) && filterGroup(item) && filterText(listElements[i], text);
            listElements[i].style.display = (show ? '' : 'none');
        }
    };
    const handle_activeUserCheckChange = (newValue) => {
        set_activeUserCheck(newValue);
        filter(newValue);
    };
    const handle_useFormClick = async (form) => {
        setLoadingUseForm(true);
        updateStateChangeArrayItemWith_id({...form, updatedAt: new Date()}, set_savedForms);
        set_savedForm(form);
        set_selectedFormId(form.template);
        let userForm = JSON.parse(form.form);
        set_userForm(userForm);
        set_initialForm(userForm);

        const postData = {
            templateId: form.template,
            fields: ['name', 'form', 'useEffects']
        }
        await axios.post(SERVER_PATH + '/data/templates/one', postData)
        .then(res => {
            set_selectedForm(res.data);
            setLoadingUseForm(false);
            handleNavigate(`/${workspaceId}/pastas/${selectedFolder?._id}/formularios?id=${form._id}`);
        })
        .catch(err => {
            setLoadingUseForm(false);
        });
    };
    const handle_deleteFormClick = (record) => {
        set_clickedRecord(record);
        set_deleteRecordDialogOpen(true);
    };
    const handle_deleteRecord = () => {
        setLoading(true);
        set_deleteRecordDialogOpen(false);
        let postData = {
            collection: 'Projects',
            documentId: selectedFolder._id,
            subDocumentKey: 'forms',
            subDocumentId: clickedRecord._id,
            projectId: selectedFolder?._id
        }
        axios.post(SERVER_PATH + '/data/delete/nested', postData)
        .then(res => {
            let projectNewValue = res.data;
            updateStateChangeArrayItemWith_id({...clickedRecord, deleted: true}, set_savedForms);
            setSelectedFolder({...selectedFolder, forms: projectNewValue.forms});
            setLoading(false);
        })
        .catch(err => {
            console.log(err)
            setLoading(false);
            toast(ERROR_MESSAGE_UNKNOWN);
        });
    };

    const tableHead = [
        {content: 'Data'},
        {content: 'Solicitante'}
    ];
    if(useCompanyRootFolder){
        tableHead.push({content: 'Projeto'});
        tableHead.push({content: 'Grupo'});
    } else if(selectedFolder?.useGroups){
        tableHead.push({content: 'Grupo'});
    }
    tableHead.push({content: 'Formulário'});
    tableHead.push({content: 'Nome'});
    tableHead.push({content: 'Detalhamento'});
    tableHead.push({content: 'Completar'});
    if(savedForm?.user == activeUser?._id || userPosition || operatorStatus){
        tableHead.push({content: 'Excluir'});
    }

    return (
        <ViewBox>
            {
                (loadingPage || !selectedFolder || users.length === 0 || templates.length === 0)
                ? <CenteredCircularProgress />
                :
                <Box>
                {
                    savedForms.filter(record => !record.deleted).length === 0
                    ? <NoResults text="Não há formulários salvos." />
                    :
                    <Box>
                        <Box mb={2}>
                            <Grid container spacing={1} alignItems="flex-end">
                                {
                                    selectedFolder?.useGroups &&
                                    <Grid item xs={12} sm={4}>
                                        <Select label="Grupo" options={groupOptions} value={selectedGroup} onChange={(e) => handle_selectedGroupChange(e.target.value)} />
                                    </Grid>
                                }
                                <Grid item xs={12} sm={2} container alignItems="flex-end" justifyContent="center">
                                    <Checkbox checked={activeUserCheck} onChange={(e) => handle_activeUserCheckChange(e.target.checked)} label={activeUser?.screenName} />
                                </Grid>
                                {/* <Grid item xs>
                                    <Input label="Filtrar" value={filterInputValue} onChange={(e) => setFilterInputValue(e.target.value)} />
                                </Grid> */}
                                {/* <Grid item xs>
                                    <FilterTextField id="forms-filter-input" filterFunction={() => filter()} />
                                </Grid> */}
                            </Grid>
                        </Box>
                        <TinyTable
                            head={tableHead}
                            body={
                                savedForms.filter(item => !item.deleted)
                                .map((item) => {
                                    const userForm = JSON.parse(item.form);
                                    let visibleForm = '';
                                    for(const question in userForm){
                                        if(question !== 'linkFormUser' && !userForm[question].choice) visibleForm += `${question}: ${userForm[question].input}; `;
                                    }
                                    const columns = [
                                        {content: <Typography variant="body2">{moment(item.timestamp).format('D/M/YY H:mm')}</Typography>},
                                        {content: <Typography variant="body2">{getUserNameById(users, item.user, true)}</Typography>},
                                    ];
                                    if(useCompanyRootFolder){
                                        columns.push({content: <Typography variant="body2">{item.project}</Typography>});
                                        columns.push({content: <Typography variant="body2">{getGroupNameById(item.groupId, folderGroups)}</Typography>});
                                    } else if(selectedFolder.useGroups){
                                        columns.push({content: <Typography variant="body2">{getGroupNameById(item.groupId, folderGroups)}</Typography>});
                                    }
                                    columns.push({content: <Typography variant="body2">{getTemplateNameById(item.template, templates)}</Typography>});
                                    columns.push({content: <Typography variant="body2">{item.name}</Typography>});
                                    columns.push({content: <Typography variant="body2">{visibleForm}</Typography>});
                                    columns.push({
                                        content: 
                                            <Box>
                                                <Grid container spacing={1} alignItems="center">
                                                    <Grid item>
                                                        <Button
                                                            endIcon={loadingUseForm && savedForm._id == item._id ? <CircularProgress size={20} /> : <ArrowForwardIcon />}
                                                            disabled={loadingUseForm}
                                                            variant="outlined" color="primary"
                                                            onClick={() => handle_useFormClick(item)}
                                                        >Completar</Button>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                    });
                                    if(item?.user == activeUser?._id || userPosition || operatorStatus){
                                        columns.push({
                                            content:
                                                <Box>
                                                    <Grid container justifyContent="center">
                                                        <Grid item>
                                                            <Tooltip text="Excluir">
                                                                <IconButton size="medium" onClick={() => handle_deleteFormClick(item)}><DeleteIcon /></IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                        });
                                    }
                                    return ({
                                        updatedAt: item.updatedAt,
                                        columns
                                    });
                                })
                            }
                        />
                    </Box>
                }
                </Box>
            }
            <AlertDialog
                title="Tem certeza?"
                text={`Quer excluir o formulário "${clickedRecord?.name}"? Essa ação é irreversível.`}
                open={deleteRecordDialogOpen} onClose={() => set_deleteRecordDialogOpen(false)}
                okButtonOnClick={handle_deleteRecord}
            />
        </ViewBox>
    );
}

export default SavedRequestDocForms;