import { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import AddClearanceQuestionWindow from './AddClearanceQuestionWindow';
import AlertDialog from '../AlertDialog';
import ChangeOperatorMenu from '../ChangeOperatorMenu';
import ClearanceQuestionsList from './ClearanceQuestionsList';
import FiltersMenu from '../ClearanceQuestionsList/components/FiltersMenu';
import ImageWindow from '../ImageWindow';
import Input from '../Input';
import LoaderEllipsis from '../LoaderEllipsis';
import MenuChip from '../MenuChip';
import Pagination from '../Pagination';
import QuestionHistoryWindow from '../QuestionHistoryWindow';
import ReplyQuestionWindow from './ReplyQuestionWindow';
import ViewBox from '../ViewBox';
import { useAppCtxAPI, useAppCtxActiveUser } from '../../context/SystemContext';
import { useSocket } from '../../context/SocketContext';
import { useClearanceCtxAPI, useClearanceCtxActiveClient, useClearanceCtxClearanceQuestionsListFilters, useClearanceCtxClearanceQuestionsListFiltersMenuOptions } from '../../context/ClearanceContext';
import { updateStateChangeArrayItemWith_id, updateStateDeleteArrayItemBy_id } from '../../utils/common';
import { SERVER_PATH, SUCCESS_MESSAGE_SAVED } from '../../utils/constants';
import queryString from 'query-string'; 

const classes = {
    fab: {
        position: 'absolute',
        bottom: '16px',
        right: '16px',
    },
    extendedIcon: {
        marginRight: '8px',
    },
    searchValueBox: {
        maxWidth: '200px',
        overflowX: 'auto',
        textAlign: ['center', 'left'],
    },
    smallAvatar: {
        width: '24px',
        height: '24px',
    }
};

const ClearanceQuestionsListPage = ({
    activeUserIsOperator, authorityRiskLevel, initialClearanceFolderInformation, initialSelectedTemplate,
    numberOfApprovalsRequired,
    projects, restrictedUserFolder, selectedFolderUsers, selectedFolderType, users, operators,
    setPendingClearanceCount
}) => {
    const { showOperatorsMenu } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const { getClearanceQuestionsListFiltersMenuOptions, resetClearanceQuestionsListFilters, setState: setClearanceCtxState, showClearanceQuestionsListFiltersMenu } = useClearanceCtxAPI();
    const clearanceCtxActiveClient = useClearanceCtxActiveClient();
    const {createSocketConnection} = useSocket();
    const socket = createSocketConnection();
    const [loading, setLoading] = useState(true);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [clearanceQuestions, setClearanceQuestions] = useState(null);
    const [notification, setNotification] = useState({});
    const [addQuestionWindowOpen, setAddQuestionWindowOpen] = useState(false);
    const [questionHistoryWindowOpen, setQuestionHistoryWindowOpen] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [selectedQuestionFolderId, setSelectedQuestionFolderId] = useState('');
    const [replyQuestionWindowOpen, setReplyQuestionWindowOpen] = useState(false);
    const [templates, setTemplates] = useState(null);
    const [clearanceFolderInformation, setClearanceFolderInformation] = useState(initialClearanceFolderInformation || null);
    const [selectedTemplateId, setSelectedTemplateId] = useState(initialSelectedTemplate ? initialSelectedTemplate._id : '');
    const [selectedTemplate, setSelectedTemplate] = useState(initialSelectedTemplate || null);
    const filters = useClearanceCtxClearanceQuestionsListFilters();
    const filtersMenuOptions = useClearanceCtxClearanceQuestionsListFiltersMenuOptions();
    const [filterActive, setFilterActive] = useState(false);
    const usedSearchId = useRef(false);
    const [isSetQuestionDoneDialogOpen, setSetQuestionDoneDialogOpen] = useState(false);
    const [visibleImageUrl, setVisibleImageUrl] = useState('');
    const [clearanceFolderOptions, setClearanceFolderOptions] = useState(null);
    const [downloadingPdf, setDownloadingPdf] = useState(false);
    const [clearanceQuestionsUpdatedAt, setClearanceQuestionsUpdatedAt] = useState(null);
    const [searchInput, setSearchInput] = useState('');
    const [count, setCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        const clearanceQuestionAssignedSocketEventName = 'CLEARANCE QUESTION ASSIGNED';

        const onClearanceQuestionAssignedSocketEvent = (data) => {
            setClearanceQuestions(prevState => {
                const { clearanceQuestionId, clearanceQuestionOperatorId, clearanceQuestionOperatorName } = data;
                const clearanceQuestionIndex = prevState.findIndex(clearanceQuestion => clearanceQuestion._id === clearanceQuestionId);
                if(clearanceQuestionIndex !== -1){
                    return [
                        ...prevState.slice(0, clearanceQuestionIndex),
                        {
                            ...prevState[clearanceQuestionIndex],
                            operatorId: clearanceQuestionOperatorId,
                            operatorFullName: clearanceQuestionOperatorName
                        },
                        ...prevState.slice(clearanceQuestionIndex + 1)
                    ];
                }
                return prevState;
            });
        };

        socket.on(clearanceQuestionAssignedSocketEventName, onClearanceQuestionAssignedSocketEvent);

        return () => {
            socket.off(clearanceQuestionAssignedSocketEventName, onClearanceQuestionAssignedSocketEvent);
        };
    }, []);

    useEffect(() => {
        if(initialSelectedTemplate && initialClearanceFolderInformation){
            setClearanceFolderInformation(initialClearanceFolderInformation);
            setSelectedTemplateId(initialSelectedTemplate._id || '');
            setSelectedTemplate(initialSelectedTemplate);
        }
    }, [initialSelectedTemplate, initialClearanceFolderInformation]);

    useEffect(() => {
        if(!activeUserIsOperator){ // if client, set initialSelectedFolder
            setSelectedFolder(restrictedUserFolder);
        }
    }, [restrictedUserFolder]);

    useEffect(() => {
        if(activeUserIsOperator){
            setClearanceCtxState('clearanceQuestionsListFilters', (prevState) => ({...prevState, status: 'pending'}));
        }
    }, []);

    useEffect(() => {
        const source = axios.CancelToken.source();

        if(clearanceCtxActiveClient && (selectedFolder || activeUserIsOperator)){ // if selectedFolder, loading folder questions. Otherwise load all questions.
            setLoading(true);

            const fetchData = async () => {
                const sortKey = '';
                const query = `?${getQuery('get')}&shouldReturnObject=yes&page=${currentPage || 1}${sortKey ? `&sortKey=${sortKey}` : ''}`;
                axios.get(SERVER_PATH + `/data/clearance/question/find/${activeUserIsOperator ? 'folders' : `folder/${restrictedUserFolder._id}`}${query}`, {
                    cancelToken: source.token
                })
                .then(res => {
                    if(res.data.pendingClearanceCount){
                        const pendingClearanceQuestionsCount = res.data.pendingClearanceCount;
                        setPendingClearanceCount(pendingClearanceQuestionsCount);
                        if(socket) socket.emit('NUMBER OF CLEARANCE QUESTIONS UPDATED', {count: pendingClearanceQuestionsCount, clientId: clearanceCtxActiveClient._id});
                    }
    
                    const rowCount = res.data.count;
                    setCount(rowCount);
    
                    setClearanceQuestions(res.data.foundQuestions);
    
                    const currentClearanceFolderOptions = res.data.foundFolders || null;
                    setClearanceFolderOptions(currentClearanceFolderOptions);
                    getClearanceQuestionsListFiltersMenuOptions({currentClearanceFolderOptions});
                })
                .catch(err => {})
                .finally(() => setLoading(false));
            }
            fetchData();
        }
        return () => {
            source.cancel('Requisição anterior cancelada.');
        };
    }, [clearanceCtxActiveClient, selectedFolder, filters, currentPage]);

    useEffect(() => {
        let currentFilterActive = false;
        for(const key in filters){
            if(!['', 'all'].includes(filters[key])) currentFilterActive = true;
        }
        setFilterActive(currentFilterActive);
    }, [filters]);

    useEffect(() => {
        if(!initialSelectedTemplate && !initialClearanceFolderInformation) getTemplates();
    }, []);

    useEffect(() => {
        if(activeUserIsOperator){
            if(selectedQuestion){
                setSelectedQuestionFolderId(selectedQuestion.folderId);
            }
        } else {
            setSelectedQuestionFolderId(restrictedUserFolder._id);
        }
    }, [selectedQuestion]);

    useEffect(() => {
        if(!initialSelectedTemplate && !initialClearanceFolderInformation && selectedQuestionFolderId){
            getClearanceFolderInformation();
        }
    }, [selectedQuestionFolderId]);

    useEffect(() => {
        if(!initialClearanceFolderInformation && !initialSelectedTemplate && clearanceFolderInformation){
            setSelectedTemplateId(clearanceFolderInformation.clearanceTemplate);
        }
    }, [clearanceFolderInformation]);

    useEffect(() => {
        if(!initialClearanceFolderInformation && !initialSelectedTemplate && templates && selectedTemplateId){
            const currentSelectedTemplate = templates.find(template => template._id === selectedTemplateId);
            setSelectedTemplate(currentSelectedTemplate);
        }
    }, [templates, selectedTemplateId]);

    useEffect(() => {
        getClearanceQuestionsListFiltersMenuOptions({numberOfApprovalsRequired});
    }, [numberOfApprovalsRequired]);
    useEffect(() => {
        getClearanceQuestionsListFiltersMenuOptions({selectedFolder});
    }, [selectedFolder]);
    useEffect(() => {
        getClearanceQuestionsListFiltersMenuOptions({selectedFolderUsers});
    }, [selectedFolderUsers]);

    useEffect(() => {
        getClearanceQuestionsListFiltersMenuOptions({selectedTemplate});
    }, [selectedTemplate]);

    useEffect(() => {
        if(!usedSearchId.current && clearanceQuestions?.length >= 1){
            const search = queryString.parse(window.location.search);
            const searchId = search.id;
            const foundQuestion = clearanceQuestions.find(clearanceQuestion => clearanceQuestion._id == searchId);
            usedSearchId.current = true;
            if(foundQuestion){
                handleReplyQuestionClick(foundQuestion);
            }
        }
    }, [clearanceQuestions]);

    const getTemplates = async () => { // move to common file (use function in different clearance files)
        await axios.get(SERVER_PATH + '/data/clearance/templates')
        .then(res => {
            setTemplates(res.data);
        })
        .catch(err => {

        });
    };

    const getClearanceFolderInformation = () => { // move to common file (use function in different clearance files)
        axios.get(SERVER_PATH + `/data/clearance/folder/${selectedQuestionFolderId}`)
        .then(res => {
            setClearanceFolderInformation(res.data);
        })
        .catch(err => {});
    };

    const getQuery = (method) => {
        const query = {};

        if(filters.createdBy !== 'all') query.createdBy = filters.createdBy;
        if(filters.folder !== 'all') query.folder = filters.folder;
        if(filters.folderGroup !== 'all') query.folderGroup = filters.folderGroup;
        if(filters.rightType !== 'all') query.rightType = filters.rightType;
        if(filters.riskLevel !== 'all') query.riskLevel = filters.riskLevel;
        if(filters.status !== 'all') query.status = filters.status;
        if(filters.search !== 'all') query.search = filters.search;

        if(method === 'get'){
            let queryArray = [];
            for(const queryKey in query){
                queryArray.push(`${queryKey}=${query[queryKey]}`);
            }
            return queryArray.join('&');
        }
        return query;
    };

    const handleReplyQuestionClick = useCallback((question) => {
        setSelectedQuestion(question);
        setReplyQuestionWindowOpen(true);
    }, []);

    const handleOperatorMenuButtonClick = useCallback((e, question) => {
        e.preventDefault();
        e.stopPropagation();
        showOperatorsMenu(e.target, question);
    }, []);

    const handleAddQuestion = () => {
        setAddQuestionWindowOpen(true);
    };

    const handleClearanceQuestionBodyRow = useCallback((clearanceQuestion) => {
        if(clearanceQuestion){ 
            handleReplyQuestionClick(clearanceQuestion);
        }
    }, []);

    const handleQuestionDoneButtonPress = (e, question) => {
        e.stopPropagation();
        setSelectedQuestion(question);
        setSetQuestionDoneDialogOpen(true);
    };

    const markAsDone = async () => {
        setSetQuestionDoneDialogOpen(false);
        const selectedQuestionCopy = {...selectedQuestion};
        updateStateChangeArrayItemWith_id({ ...selectedQuestion, savingDone: true }, setClearanceQuestions);
        await axios.get(SERVER_PATH + `/data/clearance/question/${selectedQuestion.isReportItemComment ? selectedQuestion.reportItemId : selectedQuestion._id}/done${selectedQuestion.isReportItemComment ? '?isReportItemComment=true' : ''}`)
        .then(res => {
            setNotification({message: SUCCESS_MESSAGE_SAVED});
            let updates = {
                done: true,
                savingDone: false,
                updatedAt: new Date()
            }
            if(!selectedQuestion.isReportItemComment){
                updates.operatorId = activeUser._id;
                updates.firstReplyDeliveredByOperator = true;
                updates.operatorFullName = activeUser.fullName;
                updates.operatorImg = activeUser.img;
            }
            updateStateChangeArrayItemWith_id({
                ...selectedQuestion,
                ...updates
            }, setClearanceQuestions);
            if(filters.status === 'pending' || selectedQuestion.isReportItemComment){
                updateStateDeleteArrayItemBy_id(selectedQuestion._id, setClearanceQuestions);
                setClearanceQuestionsUpdatedAt(new Date());
                if(selectedQuestion.isReportItemComment){
                    const comments = selectedQuestion.comments;
                    const unreadComments = comments.filter(comment => !comment.readBy.includes(activeUser._id) && !!comment._id);
                    if(unreadComments.length >= 1){
                        markCommentsAsRead(unreadComments.map(comment => comment._id));
                    }
                }
            }

            if(res.data.pendingClearanceCount){
                setPendingClearanceCount(res.data.pendingClearanceCount);
                if(socket) socket.emit('NUMBER OF CLEARANCE QUESTIONS UPDATED', {count: res.data.pendingClearanceCount, clientId: clearanceCtxActiveClient._id});
            }
        })
        .catch(err => {
            updateStateChangeArrayItemWith_id(selectedQuestionCopy, setClearanceQuestions);
            setNotification({message: err.response.data, severity: 'error'});
        });
    };

    const markCommentsAsRead = (comments) => {
        axios.post(SERVER_PATH + '/data/clearance/comments/read', { reportItemId: selectedQuestion.reportItemId, comments })
        .then(res => {})
        .catch(err => {});
    }
    
    const handlePdfButtonPress = async () => {
        setDownloadingPdf(true);
        setNotification({ message: 'Gerando o relatório... isso levará alguns segundos...' });
        const selectedFolderId = selectedFolder ? (filters.folder !== 'all' ? filters.folder : selectedFolder._id) : filters.folder;
        const query = getQuery('get');
        let filename = '';
        await axios.get(SERVER_PATH + `/data/clearance/${selectedFolderId}/pdf${query ? `?${query}` : ''}`, {
            responseType: 'blob'
        })
        .then(response => {
            const disposition = response.headers['content-disposition'];
            filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
            if (filename.toLowerCase().startsWith("utf-8''"))
               filename = decodeURIComponent(filename.replace("utf-8''", ''));
            else
               filename = filename.replace(/['"]/g, '');
            return response.data;
         })
         .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a); // append the element to the dom
            a.click();
            a.remove(); // afterwards, remove the element
            setNotification({ message: '' });
        })
        .catch(err => {
            setNotification({ severity: 'error', message: err.response.data });
        });
        setDownloadingPdf(false);
    };

    const handleOperatorChanged = (operatorId, questionSubject, clearanceQuestionId, operatorName) => {

        if(socket) socket.emit('CLEARANCE QUESTION ASSIGNED', {
            actionUserId: activeUser._id,
            assignedByName: activeUser.fullName,
            assignedToId: operatorId,
            description: questionSubject,
            clearanceQuestionId,
            clearanceQuestionOperatorName: operatorName,
            clientId: clearanceCtxActiveClient._id,
            workspaceName: clearanceCtxActiveClient.name,
        });
    }

    const handleCommentInputKeyPress = useCallback((e) => {
        // if(e.key === '@'){
        //     setFolderUsersMenuAnchorEl(e.target);
        //     setFolderUsersMenuOpen(true);
        // }
    }, []);

    const filterSearch = (newValue) => {
        setClearanceCtxState('clearanceQuestionsListFilters', (prevState) => ({...prevState, search: newValue}));
        setSearchInput('');
    };

    const filterOnKeyDown = (e) => {
        const pressedKey = e.key;
        if(pressedKey === 'Enter'){
            filterSearch(e.target.value);
        }
    };

    const handleFilterClick = (e, optionId) => {
        showClearanceQuestionsListFiltersMenu(e.target, optionId);
    };

    const getSelectedFilterLabelById = (filterOptionId, filterValue) => {
        if(filterOptionId && filterValue){
            if(filtersMenuOptions[filterOptionId]){
                const selectedFilterOption = filtersMenuOptions[filterOptionId].find(option => option.value === filterValue);
                if(selectedFilterOption){
                    return selectedFilterOption.description || selectedFilterOption.label;
                }
            }
            return '';
        }
    }

    const handlePagePress = useCallback((page) => {
        setCurrentPage(page);
    }, []);

    return (
        <ViewBox style={{ height: '100%' }} message={notification}>

            <Box mb={2}>
                <Grid container alignItems="flex-end" spacing={1}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Input
                            disabled={loading}
                            fullWidth
                            placeholder="Busca"
                            value={searchInput} onChange={(e) => setSearchInput(e.target.value)}
                            endIcon={<SearchIcon />}
                            endIconTitle="Busca"
                            endIconOnClick={() => filterSearch(searchInput)}
                            onKeyDown={filterOnKeyDown}
                        />
                    </Grid>
                    <Grid item className={classes.searchValueBox}>
                        <Collapse in={filters.search}>
                            <Chip
                                label={filters.search}
                                color="primary"
                                onDelete={() => setClearanceCtxState('clearanceQuestionsListFilters', (prevState) => ({...prevState, search: ''}))}
                            />
                        </Collapse>
                    </Grid>
                    <Grid item xs container spacing={1} alignItems="center">
                        <Grid item>
                            <MenuChip
                                label="Situação"
                                onPress={(e) => handleFilterClick(e, 'status')}
                                value={filters.status} valueLabel={getSelectedFilterLabelById('status', filters.status)}
                            />
                        </Grid>
                        {
                            clearanceFolderOptions &&
                            <Grid item>
                                <MenuChip
                                    label="Pasta"
                                    onPress={(e) => handleFilterClick(e, 'folder')}
                                    value={filters.folder} valueLabel={getSelectedFilterLabelById('folder', filters.folder)}
                                />
                            </Grid>
                        }
                        {
                            selectedFolder?.useGroups &&
                            <Grid item>
                                <MenuChip
                                    label="Grupo"
                                    onPress={(e) => handleFilterClick(e, 'folderGroup')}
                                    value={filters.folderGroup} valueLabel={getSelectedFilterLabelById('folderGroup', filters.folderGroup)}
                                />
                            </Grid>

                        }
                        {
                            selectedFolderUsers &&
                            <Grid item>
                                <MenuChip
                                    label="Pessoa"
                                    onPress={(e) => handleFilterClick(e, 'createdBy')}
                                    value={filters.createdBy} valueLabel={getSelectedFilterLabelById('createdBy', filters.createdBy)}
                                />
                            </Grid>
                        }
                        {
                            selectedTemplate &&
                            <Grid item>
                                <MenuChip
                                    label="Direito"
                                    onPress={(e) => handleFilterClick(e, 'rightType')}
                                    value={filters.rightType} valueLabel={getSelectedFilterLabelById('rightType', filters.rightType)}
                                />
                            </Grid>
                        }
                        <Grid item>
                            <MenuChip
                                label="Risco"
                                onPress={(e) => handleFilterClick(e, 'riskLevel')}
                                value={filters.riskLevel} valueLabel={getSelectedFilterLabelById('riskLevel', filters.riskLevel)}
                            />
                        </Grid>
                        {
                            filterActive &&
                            <Grid item>
                                <IconButton size="small" onClick={resetClearanceQuestionsListFilters}><ClearIcon /></IconButton>
                            </Grid>
                        }
                    </Grid>
                    {
                        (clearanceQuestions?.length >= 1 && (selectedFolder || filters.folder !== 'all')) &&
                        <Grid item>
                            <Button variant="outlined" size="small"
                                disabled={downloadingPdf}
                                startIcon={downloadingPdf ? <CircularProgress size={20} /> : null}
                                onClick={handlePdfButtonPress}
                            >XLSX</Button>
                        </Grid>
                    }

                </Grid>
            </Box>

            <Pagination count={count} page={currentPage} onChange={(e, page) => handlePagePress(page)} />

            {
                loading
                ? <LoaderEllipsis primary />
                :
                <Box pb={4}>
                    <ClearanceQuestionsList
                        activeUserIsOperator={activeUserIsOperator}
                        handleBodyRow={handleClearanceQuestionBodyRow}
                        handleOperatorMenuButtonPress={handleOperatorMenuButtonClick}
                        handleQuestionDoneButtonPress={handleQuestionDoneButtonPress}
                        list={clearanceQuestions}
                        numberOfApprovalsRequired={numberOfApprovalsRequired}
                        projects={projects}
                        selectedFolder={selectedFolder}
                        users={users}
                        updatedAt={clearanceQuestionsUpdatedAt}
                    />
                </Box>
            }

            <AddClearanceQuestionWindow
                activeUserIsOperator={activeUserIsOperator}
                open={addQuestionWindowOpen} onClose={() => setAddQuestionWindowOpen(false)}
                handleCommentInputKeyPress={handleCommentInputKeyPress}
                projects={projects}
                selectedFolder={selectedFolder}
                selectedFolderType={selectedFolderType}
                selectedTemplate={selectedTemplate}
                setQuestions={setClearanceQuestions} 
                users={users}
            />

            <QuestionHistoryWindow
                open={questionHistoryWindowOpen} onClose={() => setQuestionHistoryWindowOpen(false)}
                selectedQuestion={selectedQuestion}
                users={users}
            />

            <ReplyQuestionWindow
                activeUserIsOperator={activeUserIsOperator}
                open={replyQuestionWindowOpen} onClose={() => setReplyQuestionWindowOpen(false)}
                authorityRiskLevel={authorityRiskLevel}
                handleCommentInputKeyPress={handleCommentInputKeyPress}
                numberOfApprovalsRequired={numberOfApprovalsRequired}
                selectedFolderType={selectedFolderType}
                selectedQuestion={selectedQuestion}
                selectedTemplate={selectedTemplate}
                setVisibleImageUrl={setVisibleImageUrl}
                setQuestions={setClearanceQuestions}
            />

            {
                activeUserIsOperator &&
                <ChangeOperatorMenu
                    collection="ClearanceQuestions" operatorIdKey="operatorId"
                    setDocuments={setClearanceQuestions}
                    operatorChangedCallback={handleOperatorChanged}
                />
            }

            {
                activeUser &&
                <Fab
                    variant="extended" color="primary"
                    sx={classes.fab}
                    onClick={handleAddQuestion}
                >
                    <AddIcon className={classes.extendedIcon} />
                    Consulta
                </Fab>
            }

            <FiltersMenu />

            <AlertDialog
                open={isSetQuestionDoneDialogOpen} onClose={() => setSetQuestionDoneDialogOpen(false)}
                title="Tem certeza?" text={`Você quer marcar ${selectedQuestion?.isReportItemComment ? 'o comentário' : 'a consulta'} "${selectedQuestion?.subject || ''}" como respondid${selectedQuestion?.isReportItemComment ? 'o' : 'a'} sem enviar uma resposta?`}
                okButtonOnClick={markAsDone}
            />

            <ImageWindow imageUrl={visibleImageUrl} setImageUrl={setVisibleImageUrl} />

        </ViewBox>
    );
};

export default ClearanceQuestionsListPage;