import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import SaveIcon from '@mui/icons-material/Save';
import FormSigners from './components/FormSigners';
import LinkFormUser from './components/LinkFormUser';
import Question from './components/Question';
import QuestionBox from '../../components/QuestionBox';
import SavedFormNameDialog from './components/SavedFormNameDialog';
import SuppliersWindow from './components/SuppliersWindow';
import { updateStateAddArrayItem } from '../../utils/common';
import { ERROR_MESSAGE_UNKNOWN, SERVER_PATH } from '../../utils/constants';
import { useAppCtxAPI, useAppContext, useAppCtxActiveUser, useAppCtxLoading, useAppCtxSelectedFolder, useAppCtxUserGroups } from '../../context/SystemContext';
import { useSocket } from '../../context/SocketContext';
import { useForm } from './context/FormContext';
import useEffectSkipFirst from '../../hooks/useEffectSkipFirst';
import Transition from './components/Transition';
import { v4 as uuidv4 } from 'uuid';

const classes = {
    title: {
        padding: '24px',
        borderRadius: '8px',
        marginBottom: '16px',
        borderWidth: '4px 1px 1px 1px',
        borderStyle: 'solid',
        borderColor: theme => theme.palette.text.primary,
        backgroundColor: theme => theme.palette.background.paper
    },
};

function Form({
    data, form, hideSuppliersButton, hideSaveFormButton, hideTitle, linkForm, onFormSubmit, replaceTitle,
    
    isTest
}){
    const { onFormSave, operatorStatus } = data; 
    const { getSuppliers, setLoading, setReturnedFormFile, toast, updateSelectedFolder } = useAppCtxAPI();
    const { formEffects } = useAppContext();
    const activeUser = useAppCtxActiveUser();
    const loading = useAppCtxLoading();
    const selectedFolder = useAppCtxSelectedFolder();
    const { activeUserGroups, userPosition } = useAppCtxUserGroups();
    const {createSocketConnection} = useSocket();
    const socket = createSocketConnection();
    const {
        formActiveClient,
        setFormValidation, foundCNPJs, questionElements, savedForm, set_savedForm,
        set_initialForm, set_selectedForm, set_selectedFormId,
        set_userForm,
        setSelectedSupplier, visibleQuestions, set_visibleQuestions, usedSupplierInformation, userForm
    } = useForm();
    const [suppliersList, set_suppliersList] = useState([]);
    const [suppliersListLoading, set_suppliersListLoading] = useState(false);
    const [suppliersListOpen, set_suppliersListOpen] = useState(false);
    const [savedFormNameDialogOpen, set_savedFormNameDialogOpen] = useState(false);

    const [allQuestions, set_allQuestions] = useState([]);
    const [logicalBranchingQuestions, set_logicalBranchingQuestions] = useState([]);

    const signersRef = useRef();
    const [reCAPTCHAToken, setReCAPTCHAToken] = useState('');
    const captchaRef = useRef(null);

    useEffect(() => {
        if(formActiveClient){
            const initialQuestions = form.form.map((question, questionIndex) => {
                question.id = questionIndex;
                return question;
            });
            set_allQuestions(initialQuestions);
            const currentLogicalBranchingQuestions = initialQuestions.filter(initialQuestion => {
                if(!Array.isArray(initialQuestion.logicalBranching)){
                    const concurrentConditionGroups = [];
                    if(initialQuestion.logicalBranching && initialQuestion.logicalBranching.question !== '~none;'){
                        const concurrentConditionGroup = {
                            id: uuidv4(),
                            concurrentConditions: [initialQuestion.logicalBranching]
                        };
                        concurrentConditionGroups.push(concurrentConditionGroup);
                    }
                    initialQuestion.logicalBranching = concurrentConditionGroups;
                } else {
                    const concurrentConditionGroups = [];
                    if(!initialQuestion.logicalBranching.some(logicalBranchingCondition => logicalBranchingCondition.concurrentConditions)){
                        const concurrentConditionGroup = {
                            id: uuidv4(),
                            concurrentConditions: initialQuestion.logicalBranching
                        };
                        concurrentConditionGroups.push(concurrentConditionGroup);
                        initialQuestion.logicalBranching = concurrentConditionGroups;
                    }
                }
                return initialQuestion.logicalBranching.some(logicalBranchingConditionsGroup => {
                    return logicalBranchingConditionsGroup.concurrentConditions.some(logicalBranchingCondition => logicalBranchingCondition.question !== '~none;');
                });
            });
            set_logicalBranchingQuestions(currentLogicalBranchingQuestions);
            const currentVisibleQuestions = [];
            initialQuestions.forEach(initialQuestion => {
                const showQuestion = checkLogicalBranching(initialQuestion, currentLogicalBranchingQuestions);
                if(showQuestion) currentVisibleQuestions.push(initialQuestion);
            });
            set_visibleQuestions(currentVisibleQuestions);
        }
    }, [formActiveClient]);

    useEffectSkipFirst(() => applyLogicalBranching(), [userForm]);

    const applyLogicalBranching = () => {
        logicalBranchingQuestions.forEach(question => {
            const questionVisible = visibleQuestions.some(visibleQuestion => visibleQuestion.id === question.id);
            const showQuestion = checkLogicalBranching(question, logicalBranchingQuestions);
            if(showQuestion){
                if(!questionVisible) updateStateAddArrayItem(question, set_visibleQuestions);
            } else {
                if(questionVisible){
                    set_visibleQuestions(prevState => {
                        const visibleQuestionIndex = prevState.findIndex(visibleQuestion => visibleQuestion.id === question.id);
                        if(visibleQuestionIndex !== -1) return [...prevState.slice(0, visibleQuestionIndex), ...prevState.slice(visibleQuestionIndex + 1)];
                        return prevState;
                    });
                }
            }
        });
    };
    const checkLogicalBranching = (question, logicalBranchingQuestions) => {
        
        const checkLogicalBranchingAction = (questionLogicalBranchingConditions) => {
            if(questionLogicalBranchingConditions){
                if(!Array.isArray(questionLogicalBranchingConditions)){
                    const concurrentConditionGroups = [];
                    if(questionLogicalBranchingConditions && questionLogicalBranchingConditions.question !== '~none;'){
                        const concurrentConditionGroup = {
                            id: uuidv4(),
                            concurrentConditions: [questionLogicalBranchingConditions]
                        };
                        concurrentConditionGroups.push(concurrentConditionGroup);
                    }
                    questionLogicalBranchingConditions = concurrentConditionGroups;
                } else {
                    const concurrentConditionGroups = [];
                    if(!questionLogicalBranchingConditions.some(logicalBranchingCondition => logicalBranchingCondition.concurrentConditions)){
                        const concurrentConditionGroup = {
                            id: uuidv4(),
                            concurrentConditions: questionLogicalBranchingConditions
                        };
                        concurrentConditionGroups.push(concurrentConditionGroup);
                        questionLogicalBranchingConditions = concurrentConditionGroups;
                    }
                }
                const validQuestionLogicalBranchingConditionGroups = questionLogicalBranchingConditions.filter(logicalBranchingConditionsGroup => {
                    return logicalBranchingConditionsGroup.concurrentConditions.some(logicalBranchingCondition => logicalBranchingCondition.question !== '~none;');
                });

                if(validQuestionLogicalBranchingConditionGroups.length === 0){
                    return true;
                } else {
                    for(const questionLogicalBranchingConditionsGroup of validQuestionLogicalBranchingConditionGroups){
                        let conditionTest = true;
                        const concurrentConditions = questionLogicalBranchingConditionsGroup.concurrentConditions;
                        for(const questionLogicalBranchingCondition of concurrentConditions){
                            if(!conditionTest) break;
                            
                            if(questionLogicalBranchingCondition.type === 'userCreatedBooleanField'){
                                const userCreatedFieldsValues = selectedFolder.userCreatedFieldsValues;
                                let userCreatedFieldValue;
                                if(userCreatedFieldsValues){
                                    userCreatedFieldValue = userCreatedFieldsValues.find(field => field.id === questionLogicalBranchingCondition.question);
                                }
        
                                if(questionLogicalBranchingCondition.choice !== 'false'){
                                    if(!userCreatedFieldValue || !userCreatedFieldValue.value) conditionTest = false;
                                } else {
                                    if(userCreatedFieldValue && userCreatedFieldValue.value) conditionTest = false;
                                }
                            } else {
                                const questionLogicalBranchingConditionQuestion = questionLogicalBranchingCondition.question;
                                const questionLogicalBranchingConditionChoice = questionLogicalBranchingCondition.choice;
                                const questionLogicalBranchingConditionOperation = questionLogicalBranchingCondition.operation || 'if';
                                const userFormLogicalBranchingQuestion = userForm[questionLogicalBranchingConditionQuestion];
                                if(!userFormLogicalBranchingQuestion){
                                    if(questionLogicalBranchingConditionOperation === 'if') conditionTest = false;
                                } else {
                                    // type 'special' and typeId 'special_list_audiovisual_services' because some old forms are not correctly set up
                                    if(questionLogicalBranchingCondition.type === 'list' || questionLogicalBranchingCondition.type === 'special'){
                                        const listId = questionLogicalBranchingCondition.typeId || 'special_list_audiovisual_services';
                                        const currentLists = formActiveClient.lists;
                                        let currentList;
                                        if(currentLists) currentList = currentLists.find(list => list.shortName === listId || list._id == listId);
                                        if(currentList){
                                            currentList = currentList.list;
                                            const listItem = currentList.find(item => item.key === userFormLogicalBranchingQuestion.input || (Array.isArray(item.aliases) && item.aliases.includes(userFormLogicalBranchingQuestion.input)));
                                            if(!listItem){
                                                if(questionLogicalBranchingConditionOperation === 'if') conditionTest = false;
                                            } else {
                                                if(questionLogicalBranchingConditionOperation === 'if' && listItem.key !== questionLogicalBranchingConditionChoice){
                                                    conditionTest = false;
                                                } else if(questionLogicalBranchingConditionOperation === 'ifNot' && listItem.key === questionLogicalBranchingConditionChoice){
                                                    conditionTest = false;
                                                }
                                            }
                                        } 
                                    } else {
                                        if(questionLogicalBranchingConditionOperation === 'if' && userFormLogicalBranchingQuestion.choice !== questionLogicalBranchingConditionChoice){
                                            conditionTest = false;
                                        } else if(questionLogicalBranchingConditionOperation === 'ifNot' && userFormLogicalBranchingQuestion.choice === questionLogicalBranchingConditionChoice){
                                            conditionTest = false;
                                        }
                                    }
                                }
                                
                                if(!conditionTest) break;
        
                                const nextQuestionLogicalBranchingConditionQuestion = logicalBranchingQuestions.find(item => item.name === questionLogicalBranchingConditionQuestion);
                                if(nextQuestionLogicalBranchingConditionQuestion){
                                    const currentGroupTest = checkLogicalBranchingAction(nextQuestionLogicalBranchingConditionQuestion.logicalBranching);
                                    if(!currentGroupTest) return false;
                                }
                            }
                        }
                        if(conditionTest){
                            return true;
                        }
                    }
                }
            }
            return false;
        };

        return checkLogicalBranchingAction(question.logicalBranching);
    };
    
    const handleSubmit = () => {
        setLoading(true);
        submitForm(false,
            (res) => {
                set_userForm({});
                set_selectedForm(null);
                set_selectedFormId('');
                set_visibleQuestions([]);
                setTimeout(() => {
                    set_visibleQuestions([]);

                    set_initialForm({});
                }, 1500);
                setLoading(false);
                if(!linkForm) toast('O formulário foi enviado.');
                let currentSavedForm = {...savedForm};
                set_savedForm(null);
                if(socket){
                    let actionUserId, actionUserName;
                    if(activeUser){
                        actionUserId = activeUser._id;
                        actionUserName = activeUser.fullName;
                    } else {
                        if(userForm.linkFormUser){
                            actionUserName = userForm.linkFormUser.name;
                        }
                    }
                    if(!isTest){
                        socket.emit('DOCUMENT FORM SENT', { actionUserId, actionUserName, updatedDocument: res.data.record});
                    }
                }
                
                if(onFormSubmit) onFormSubmit({ savedForm: currentSavedForm });
                if(res.data.download){
                    setReturnedFormFile(res.data.download);
                }

                if(isTest){
                    setReturnedFormFile(res.data);
                }
                resetReCAPTCHA();
            },
            (err) => {
                setLoading(false);
                toast(err.response?.data || err.message);
                resetReCAPTCHA();
            });
    };
    const handleSave = (savedFormName) => {
        set_savedFormNameDialogOpen(false);
        setLoading(true);
        submitForm(savedFormName,
            (res) => {
                let projectNewValue = res.data.project;
                updateSelectedFolder({forms: projectNewValue.forms});
                set_userForm({});
                set_selectedForm(null);
                set_selectedFormId('');
                set_visibleQuestions([]);
                setTimeout(() => {
                    set_visibleQuestions([]);

                    set_initialForm({});
                }, 1500);
                setLoading(false);
                toast('O formulário foi enviado.');
                set_savedForm(null);

                if(onFormSave) onFormSave();
                resetReCAPTCHA();
            },
            (err) => {
                setLoading(false);
                toast(err.response?.data || err.message);
                resetReCAPTCHA();
            });
    };
    const submitForm = (savedFormName = null, successCallback, errorCallback) => {
        let allValid = true;
        const invalidQuestions = [];
        if(!savedFormName){
            visibleQuestions.forEach(visibleQuestion => {
                if(!['h', 'heading'].includes(visibleQuestion.type)){
                    const questionValid = formEffects[visibleQuestion.type].checkValidation({...userForm[visibleQuestion.name]});
                    setFormValidation(prevState => ({...prevState, [visibleQuestion.name]: !questionValid}));
                    if(!questionValid){
                        invalidQuestions.push(visibleQuestion.name);
                        allValid = false;
                    }
                }
            });
        }
        if(!allValid){
            const firstInvalidQuestion = questionElements.current[invalidQuestions[0]];
            firstInvalidQuestion.scrollIntoView({block: 'start',  behavior: 'smooth'});
            errorCallback(new Error('Preencha os campos obrigatórios.'));
        } else {
            let finishedForm = {...userForm};
            const formElement = document.getElementById('form');

            let formData = new FormData();
            formData.append('client', selectedFolder.client ? selectedFolder.client : selectedFolder._id);
            formData.append('project', selectedFolder.client ? selectedFolder._id : '');
            let group = '';
            if(selectedFolder.useGroups && finishedForm['Grupo']){
                group = finishedForm['Grupo'].choice;
                delete finishedForm['Grupo'];
            }
            formData.append('groupId', group);
            formData.append('template', formElement.getAttribute('data-form-id'));
            const fileQuestions = [];
            for(let key in finishedForm){
                if(key !== 'linkFormUser'){
                    const formQuestion = finishedForm[key];
                    if(formQuestion.type === 'file') fileQuestions.push(formQuestion);
                    if(!visibleQuestions.find(visibleQuestion => visibleQuestion.name === key)) delete finishedForm[key];
                }
            }
            formData.append('form', JSON.stringify(finishedForm));
            if(savedForm) formData.append('savedFormId', savedForm._id);
            if(savedFormName) formData.append('savedFormName', savedFormName);
            if(operatorStatus) formData.append('isOperator', true);
            if(signersRef.current) formData.append('signer', signersRef.current);
            formData.append('foundCNPJs', JSON.stringify(foundCNPJs));
            if(isTest) formData.append('isTest', JSON.stringify(isTest));
            let fileIndex = 0;
            for(const fileQuestion of fileQuestions){
                if(fileQuestion.files.length > 0){
                    for (let fileQuestionFileIndex = 0; fileQuestionFileIndex < fileQuestion.files.length; fileQuestionFileIndex++) {
                        formData.append('file' + fileIndex, fileQuestion.files[fileQuestionFileIndex]);
                        fileIndex++;
                    }
                }
            }
            axios.post(SERVER_PATH + `/data/form/${!savedFormName ? 'submit' : 'save'}`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
            .then(successCallback)
            .catch(errorCallback)

            // let postData = {};
            // postData.client = selectedFolder.client ? selectedFolder.client : selectedFolder._id;
            // postData.project = selectedFolder.client ? selectedFolder._id : '';
            // let group = '';
            // if(selectedFolder.useGroups && finishedForm['Grupo']){
            //     group = finishedForm['Grupo'].choice;
            //     delete finishedForm['Grupo'];
            // } 
            // postData.groupId = group;
            // postData.template = formElement.getAttribute('data-form-id');
            // for(let key in finishedForm){
            //     if(key !== 'linkFormUser'){
            //         if(!visibleQuestions.find(visibleQuestion => visibleQuestion.name === key)) delete finishedForm[key];
            //     }
            // }
            // postData.form = JSON.stringify(finishedForm);
            // if(savedForm) postData.savedFormId = savedForm._id;
            // if(savedFormName) postData.savedFormName = savedFormName;
            
            // if(operatorStatus) postData.isOperator = true;

            // postData.signer = signersRef.current;

            // postData.foundCNPJs = foundCNPJs;

            // if(isTest) postData.isTest = isTest;
            
            // axios.post(SERVER_PATH + `/data/form/${!savedFormName ? 'submit' : 'save'}`, postData)
            // .then(successCallback)
            // .catch(errorCallback)
        }
    };
    const handle_openSuppliersListClick = async () => {
        set_suppliersListLoading(true);
        set_suppliersList([]);
        let currentSuppliers = [];
        if(selectedFolder.client){
            try {
                currentSuppliers = await getSuppliers(selectedFolder.client);
            } catch (error) {
                toast(ERROR_MESSAGE_UNKNOWN);
            }
        } else {
            currentSuppliers = selectedFolder.suppliers
        }
        set_suppliersList(currentSuppliers);
        set_suppliersListOpen(true);
        set_suppliersListLoading(false);
    };
    const setSupplier = (supplier) => {
        set_suppliersListOpen(false);
        setSelectedSupplier(supplier);
        usedSupplierInformation.current = false;
        let formClone = {...userForm};
        allQuestions.forEach(question => {
            let assignedField = question.assignedField;
            if(assignedField !== 0){
                let fieldValue = null;
                switch (assignedField) {
                    case 1:
                        fieldValue = supplier.name;
                        break;
                    case 2:
                        fieldValue = supplier.identity || supplier.taxpayerNumber;
                        break;
                    case 3:
                        fieldValue = supplier.address;
                        break;
                    case 4:
                        fieldValue = supplier.representative;
                        break;
                    case 5:
                        fieldValue = supplier.repId || supplier.representativeTaxpayerNumber;
                        break;
                    case 'email':
                        fieldValue = supplier.email;
                        break;
                    case 'supplierEmail':
                        fieldValue = supplier.email;
                        break;
                    case 'mobile':
                        fieldValue = supplier.mobile;
                        break;
                    case 'supplierMobile':
                        fieldValue = supplier.mobile;
                        break;
                }
                if(fieldValue){
                    if(!formClone[question.name]) formClone[question.name] = {};
                    if(['t', 'text', 'corporationId', 'individualId'].includes(question.type)){
                        formClone[question.name].input = fieldValue;
                    } 
                }
            } else {
                const partyTypeQuestions = [
                    'A parte contratada é pessoa física ou jurídica?',
                    'A outra parte é pessoa física ou jurídica?',
                    'A parte contratada é pessoa física ou pessoa jurídica?',
                    'A outra parte é pessoa física ou pessoa jurídica?',
                    
                    'A outra parte é pessoa física ou pessoa jurídica'
                ];
                if(partyTypeQuestions.includes(question.name)){
                    const fieldValue = supplier.representative ? 'Pessoa jurídica' : 'Pessoa física';
                    if(!formClone[question.name]) formClone[question.name] = {};
                    formClone[question.name].choice = fieldValue;
                }
            }
        });
        set_initialForm(formClone);
        set_userForm(formClone);
    };

    const resetReCAPTCHA = () => {
        if(captchaRef.current){
            captchaRef.current.reset();
            setReCAPTCHAToken('');
        }
    };

    const FormQuestionBoxItem = ({name, type, mandatory, children}) => (
        <QuestionBox
            name={name}
            questionValid
            type={type || 'heading'}
            mandatory={!!mandatory}
        >
            {children}
        </QuestionBox>
    );

    return (
        <Container maxWidth="md">
            {
                (!hideTitle && visibleQuestions[0]) &&
                <Box sx={classes.title}>
                    <Typography variant="h2" color="primary">{replaceTitle || form.name}</Typography>
                    <Typography variant="body2" color="secondary">* Perguntas obrigatórias</Typography>
                    {
                        savedForm &&
                        <Box mt={2}>
                            <Typography variant="body1" color="textSecondary">Você está preenchendo o formulário salvo como <span style={{fontSize: 18, fontWeight: 'bold'}}>{savedForm.name}</span>.</Typography>
                        </Box>
                    }
                </Box>
            }
            <form id="form" data-form-id={form._id} noValidate>
                {
                    (activeUserGroups && selectedFolder && selectedFolder.useGroups) &&
                    <Question
                        question={{
                            id: -1,
                            type: 'select',
                            name: 'Grupo',
                            mandatory: true,
                            options: activeUser.type >= 4 || userPosition || operatorStatus || activeUserGroups.length === 0 ? [...activeUserGroups.map(g => ({value: g.id, label: g.name})), {value: '~any;', label: 'Sem grupo (disponível para todos)'}] : activeUserGroups.map(g => ({value: g.id, label: g.name}))
                        }}
                    />
                }

                {
                    !hideSuppliersButton &&
                    <FormQuestionBoxItem name="Você quer usar uma ficha cadastral?">
                        <Box mt={2}>
                            <Box mb={1}>
                                <Typography variant="body1">Caso você já tenha cadastrado fichas, use o botão a seguir para escolher uma.</Typography>
                            </Box>
                            <Grid container spacing={1} justifyContent="center">
                                <Grid item>
                                    <Button
                                        disabled={suppliersListLoading}
                                        variant="contained" color="primary"
                                        startIcon={suppliersListLoading ? <CircularProgress size={20} /> : <FileCopyIcon />}
                                        onClick={handle_openSuppliersListClick}
                                    >Fichas</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </FormQuestionBoxItem>
                }

                {
                    visibleQuestions.sort((a, b) => a.id - b.id).map((question) => (
                        <Transition key={question.name}>
                            <Question question={question} />
                        </Transition>
                    ))
                }

                <LinkFormUser captchaRef={captchaRef} linkForm={linkForm} setReCAPTCHAToken={setReCAPTCHAToken} setUserForm={set_userForm} />

                {
                    (!isTest && visibleQuestions[0] && selectedFolder?.documentsAllowOutsideReview) &&
                    <FormSigners signersRef={signersRef} />
                }

                <Grid container justifyContent="center" spacing={2}>
                    {
                        visibleQuestions[0] &&
                        <Grid item>
                            <Button
                                variant="contained" color="primary"
                                disabled={loading || (linkForm && (!reCAPTCHAToken || (!activeUser && (!userForm.linkFormUser || !userForm.linkFormUser.name || !userForm.linkFormUser.email))))}
                                startIcon={loading ? <CircularProgress size={20} /> : undefined}
                                onClick={handleSubmit}
                            >Enviar</Button>
                        </Grid>
                    }
                    {
                        !hideSaveFormButton &&
                        <Grid item>
                            <Button
                                variant="text"
                                disabled={loading}
                                onClick={() => set_savedFormNameDialogOpen(true)}
                                startIcon={<SaveIcon />}>Concluir depois</Button>
                        </Grid>
                    }
                </Grid>
            </form>
            <SuppliersWindow open={suppliersListOpen} onClose={() => set_suppliersListOpen(false)} setSupplier={setSupplier} suppliersList={suppliersList} />
            <SavedFormNameDialog open={savedFormNameDialogOpen} setOpen={set_savedFormNameDialogOpen} handleOk={handleSave} />
        </Container>
    );
}

export default Form;