
import { useEffect, useRef, useState } from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LockIcon from '@mui/icons-material/Lock';
import Input from '../../components/Input';
import Guidelines from '../../components/clearance/ClearanceGuidelines';
import Header from './components/Header';
import Navigation from './components/Navigation';
import Questions from './Questions';
import Reports from './Reports';
import Search from './Search';
import { useAppCtxAPI } from '../../context/SystemContext';
import { useClearanceCtxAPI } from '../../context/ClearanceContext';
import { SERVER_PATH, SIDENAV_WIDTH } from '../../utils/constants';
import axios from 'axios';

const classes = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowX: 'hidden',
        background: '#f5f6fa',
    }
};

const ClearanceDashboard = () => {
    const { setLoadingSystem } = useAppCtxAPI();
    const { setState: setClearanceCtxState } = useClearanceCtxAPI();
    const [password, setPassword] = useState('');
    const [loadingDashboardInformation, setLoadingDashboardInformation] = useState(true);
    const [loading, setLoading] = useState(false);
    const [dashboardOpen, setDashboardOpen] = useState(false);
    const [initialSelectedFolder, setInitialSelectedFolder] = useState(null);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [selectedFolderType, setSelectedFolderType] = useState('');
    const [selectedFolderUsers, setSelectedFolderUsers] = useState(null);
    const [templates, setTemplates] = useState(null);
    const [clearanceFolderInformation, setClearanceFolderInformation] = useState(null);
    const [clearanceApprovalAuthorities, setClearanceApprovalAuthorities] = useState(null);
    const [clearanceNumberOfApprovalsRequired, setClearanceNumberOfApprovalsRequired] = useState({
        one: 0, two: 0, three: 0, four: 0
    });
    const [authorityRiskLevel, setAuthorityRiskLevel] = useState(0);
    const [selectedFolderTemplate, setSelectedFolderTemplate] = useState(null);
    const [selectedFolderTemplateId, setSelectedFolderTemplateId] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [shouldReload, setShouldReload] = useState(false);
    const passwordInputRef = useRef();
    const params = useParams();
    const folderId = params.folderId;

    useEffect(() => {
        setLoadingSystem(false);
    }, []);

    useEffect(() => {
        if(!folderId){
            setShouldReloadErrorMessage();
        } else {
            getFolder(folderId);
            getTemplates();
        }
    }, [folderId]);

    useEffect(() => {
        if(initialSelectedFolder && templates){
            getClearanceFolderInformation();
        }
    }, [initialSelectedFolder, templates]);
    
    useEffect(() => {
        if(clearanceFolderInformation){
            setSelectedFolderType(clearanceFolderInformation.clearanceType);
            setSelectedFolderTemplateId(clearanceFolderInformation.clearanceTemplate);
            setAuthorityRiskLevel(clearanceFolderInformation.authorityRiskLevel);
            setClearanceNumberOfApprovalsRequired(clearanceFolderInformation.clearanceNumberOfApprovalsRequired);
            setClearanceApprovalAuthorities(clearanceFolderInformation.clearanceApprovalAuthorities);
        }
    }, [clearanceFolderInformation]);

    useEffect(() => {
        if(templates && selectedFolderTemplateId){
            const currentSelectedFolderTemplate = templates.find(template => template._id === selectedFolderTemplateId);
            setSelectedFolderTemplate(currentSelectedFolderTemplate);
        }
    }, [templates, selectedFolderTemplateId]);

    useEffect(() => {
        if(clearanceFolderInformation && selectedFolderTemplate){
            setLoadingDashboardInformation(false);
            if(clearanceFolderInformation.autoOpen){
                handlePasswordForwardPress(clearanceFolderInformation.autoOpen);
            }
        }
    }, [clearanceFolderInformation, selectedFolderTemplate]);

    useEffect(() => {
        if(!loadingDashboardInformation){
            if(passwordInputRef.current) passwordInputRef.current.focus();
        }
    }, [loadingDashboardInformation]);

    const setShouldReloadErrorMessage = (newErrorMessage) => {
        setShouldReload(true);
        setErrorMessage(newErrorMessage || `Não foi possível carregar as informações do projeto. Por favor, recarregue a página.`);
        setLoadingDashboardInformation(false);
    };

    const getFolder = (folderId) => {
        axios.post(SERVER_PATH + '/data/projects/one', {projectId: folderId, fields: ['clientId', 'name', 'projectOperator', 'useGroups'], shouldGetFolderClient: true})
        .then(res => {
            setInitialSelectedFolder(res.data.project);
            setClearanceCtxState('clearanceCtxActiveClient', res.data.folderClient);
        })
        .catch(err => {
            setShouldReloadErrorMessage();
        });
    };

    const getTemplates = async () => {
        await axios.get(SERVER_PATH + `/data/clearance/templates?folderId=${folderId}`)
        .then(res => {
            setTemplates(res.data);
        })
        .catch(err => {
            setShouldReloadErrorMessage();
        });
    };

    const getClearanceFolderInformation = async () => {
        await axios.get(SERVER_PATH + `/data/clearance/folder/${folderId}`)
        .then(res => {
            setClearanceFolderInformation(res.data);
        })
        .catch(err => {
            setShouldReloadErrorMessage(err.response.data);
        });
    };

    const handlePasswordForwardPress = async (newValue) => {
        if(!templates || !clearanceFolderInformation || !clearanceFolderInformation.clearanceGroupsBehavior || !selectedFolderTemplate){
            return setErrorMessage('Não foi possível carregar os dados do projeto. Tente novamente.');
        }
        setLoading(true);
        setErrorMessage('');
        await axios.post(SERVER_PATH + '/data/clearance/dashboard/login', { folderId, password: newValue || password })
        .then(res => {
            setSelectedFolder(res.data.folder);
            setSelectedFolderUsers(res.data.users);
            setDashboardOpen(true);
        })
        .catch(err => {
            setErrorMessage(err?.response?.data || 'Ocorreu um erro.')
        });
        setLoading(false);
    };

    return (
        <Box sx={classes.root}>
            <Navigation dashboardOpen={dashboardOpen} />
            
            <Box sx={{ left: `${SIDENAV_WIDTH}px`, position: 'relative', width: `calc(100% - ${SIDENAV_WIDTH}px)` }}>
                <Header dashboardOpen={dashboardOpen} initialSelectedFolder={initialSelectedFolder} />

                {
                    (!dashboardOpen)
                    ?
                    <Box style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingTop: '50px'
                    }}>
                        <Box mb={2}>
                            <Box mb={1}>
                                <Grid container alignItems="center" justifyContent="center">
                                    <Grid item>
                                        <Collapse in={loadingDashboardInformation}>
                                            <LinearProgress />
                                        </Collapse>
                                        <Typography variant="h2">CENTRAL DE CLEARANCE</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Collapse in={initialSelectedFolder?.name}>
                                <Box mb={2}>
                                    <Grid container alignItems="center" justifyContent="center">
                                        <Grid item>
                                            <Typography variant="h4">&ldquo;{initialSelectedFolder?.name}&rdquo;</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Collapse>
                        </Box>
                        <Collapse in={!loadingDashboardInformation}>
                            <Box>
                                <Grid container alignItems="center" justifyContent="center">
                                    <Grid item>
                                        <Input
                                            autoFocus
                                            inputRef={passwordInputRef}
                                            disabled={loading || shouldReload}
                                            label="Senha"
                                            value={password} onChange={(e) => setPassword(e.target.value)}
                                            startIcon={<LockIcon />}
                                            endIcon={loading ? <CircularProgress size={20} /> : <ArrowForwardIcon />}
                                            endIconOnClick={() => handlePasswordForwardPress()}
                                            onKeyPress={(e) => {
                                                if(e.key === 'Enter') handlePasswordForwardPress();
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Collapse in={errorMessage}>
                                    <FormHelperText error>{errorMessage}</FormHelperText>
                                </Collapse>
                            </Box>
                        </Collapse>
                    </Box>
                    :
                    <Box>
                        <Routes>
                            <Route
                                path={`/`}
                                element={<Guidelines folderClearanceInformation={clearanceFolderInformation} />}
                            />
                            <Route
                                path={`/consultas`}
                                element={
                                    <Questions
                                        approvalAuthorities={clearanceApprovalAuthorities}
                                        authorityRiskLevel={authorityRiskLevel}
                                        initialClearanceFolderInformation={clearanceFolderInformation}
                                        initialSelectedTemplate={selectedFolderTemplate}
                                        numberOfApprovalsRequired={clearanceNumberOfApprovalsRequired}
                                        selectedFolder={selectedFolder}
                                        selectedFolderType={selectedFolderType}
                                        selectedFolderUsers={selectedFolderUsers}
                                    />
                                }
                            />
                            <Route
                                path={`/relatorios`}
                                element={
                                    <Reports
                                        approvalAuthorities={clearanceApprovalAuthorities}
                                        authorityRiskLevel={authorityRiskLevel}
                                        initialClearanceFolderInformation={clearanceFolderInformation}
                                        initialSelectedTemplate={selectedFolderTemplate}
                                        numberOfApprovalsRequired={clearanceNumberOfApprovalsRequired}
                                        selectedFolder={selectedFolder}
                                    />
                                }
                            />
                            <Route
                                path={`/pesquisa`}
                                element={
                                    <Search
                                        approvalAuthorities={clearanceApprovalAuthorities}
                                        authorityRiskLevel={authorityRiskLevel}
                                        numberOfApprovalsRequired={clearanceNumberOfApprovalsRequired}
                                        selectedFolder={selectedFolder}
                                        selectedFolderType={selectedFolderType}
                                        selectedTemplate={selectedFolderTemplate}
                                    />
                                }
                            />
                        </Routes>
                    </Box>
                }
            </Box>
        </Box>
    );
};

export default ClearanceDashboard;