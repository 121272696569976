import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';

function StyledDatePicker({inputStyle = {}, label, onChange, minDate, maxDate, placeholder, disabled, shrink, style = {}, value, variant}){
    if(shrink) style.width = 100;

    return (
        <DatePicker
            format="DD/MM/YYYY"
            minDate={minDate}
            maxDate={maxDate}
            autoOk
            disableToolbar
            margin="none"
            value={value} onChange={onChange}
            disabled={disabled}
            style={style}
            InputProps={{style: inputStyle}}
            renderInput={(props) => <TextField variant={variant || 'standard'} label={label} placeholder={placeholder} {...props} /> }
        />
    );
}

export default StyledDatePicker;