import { DocumentEditorProvider } from './context/DocumentEditorContext';
import Main from './Main';

function DocumentEditor(){
    return (
        <DocumentEditorProvider>
            <Main />
        </DocumentEditorProvider>
    );
}

export default DocumentEditor;