import React, { useEffect, useMemo, useRef, useState } from 'react';
import axios from 'axios';
import { Collapse } from '@mui/material';
import { Alert } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import LinkIcon from '@mui/icons-material/Link';
import CenteredCircularProgress from '../../../components/CenteredCircularProgress';
import Dropzone from '../../../components/Dropzone';
import InputField from './components/InputField';
import QuestionBox from '../../../components/QuestionBox';
import Select from '../../../components/Select';
import TalentForm from '../../../components/talents/TalentForm';
import TwoButtonsSwitch from '../../../components/TwoButtonsSwitch';
import ViewBox from '../../../components/ViewBox';
import { ERROR_MESSAGE_UNKNOWN, SERVER_PATH } from '../../../utils/constants';
import { useAppCtxAPI, useAppCtxActiveUser, useAppCtxLoading, useAppCtxSelectedFolder, useAppCtxUserGroups } from '../../../context/SystemContext';
import { useSocket } from '../../../context/SocketContext';
import { getForm } from '../../../utils/talents';
import useTalents from '../../../hooks/useTalents';

function RequestReview(){
    const { setLoading, toast } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const loading = useAppCtxLoading();
    const selectedFolder = useAppCtxSelectedFolder();
    const { activeUserGroups, operatorStatus, userPosition } = useAppCtxUserGroups();
    const {createSocketConnection} = useSocket();
    const socket = createSocketConnection();
    const question1Name = 'Nome ou razão social da outra parte';
    const question2Name = 'Contrato de...';
    const question3Name = 'Considerações para revisão';
    const question4Name = 'Anexo - documento a revisar, se for o caso (PDF, DOC ou DOCX, no máximo 15 MB por arquivo)';
    const [groupOptions, set_groupOptions] = useState([]);
    const [selectedGroup, set_selectedGroup] = useState('');
    const [errorText, setErrorText] = useState('');
    const acceptedFilesRef = useRef([]);
    const defaultReviewDocumentForm = {
        [question1Name]: {id: 1, input: ''},
        [question2Name]: {id: 2, input: ''},
        [question3Name]: {id: 3, input: ''},
    };
    const reviewDocumentFormRef = useRef(defaultReviewDocumentForm);
    const defaultAdditionalDocumentFieldsRef = {
        fileUrl: {input: ''}
    };
    const additionalDocumentFieldsRef = useRef(defaultAdditionalDocumentFieldsRef);
    const [userWillUploadFile, setUserWillUploadFile] = useState(true);

    const talentModuleActive = useTalents(selectedFolder);
    const talentRef = useRef({});
    const [loadingProjectTalent, setLoadingProjectTalent] = useState(false);
    const [formSent, setFormSent] = useState(false);
    const [talentType, setTalentType] = useState('');

    useEffect(() => {
        if(selectedFolder){
            if(activeUserGroups){
                let knownGroupOptions = selectedFolder.useGroups ? (userPosition || operatorStatus || activeUserGroups.length === 0 ? [{value: '~any;', label: 'Sem grupo (disponível para todos)'}, ...activeUserGroups.map(g => ({value: g.id, label: g.name}))] : activeUserGroups.map(g => ({value: g.id, label: g.name}))) : []
                set_groupOptions(knownGroupOptions);
                set_selectedGroup(selectedFolder.useGroups ? knownGroupOptions[0].value : null);
            }
        }
    }, [selectedFolder, activeUserGroups]);

    useEffect(() => {
        if(talentType){
            setLoadingProjectTalent(true);
            reviewDocumentFormRef.current[question1Name] = {id: 1, input: ''};
            reviewDocumentFormRef.current[question3Name] = {id: 3, input: ''};
            talentRef.current = {};
            setLoadingProjectTalent(false);
        }
    }, [talentModuleActive, talentType]);

    const handleSubmit = () => {
        if(userWillUploadFile){
            if(acceptedFilesRef.current.length === 0) return setErrorText('Selecione um arquivo.');
        } else {
            if(!additionalDocumentFieldsRef.current.fileUrl.input) return setErrorText('Indique o link do arquivo.');
        }
        if(!talentType){
            if(!reviewDocumentFormRef.current[question2Name].input) return setErrorText(`Você deve responder a pergunta "${question2Name}".`);
        }

        setLoading(true);

        let formData = new FormData();
        formData.append('client', selectedFolder.client || selectedFolder._id);
        formData.append('project', selectedFolder.client ? selectedFolder._id : '');
        formData.append('groupId', selectedGroup);
        formData.append('form', JSON.stringify({...reviewDocumentFormRef.current}));

        if(talentModuleActive){
            const talentForms = JSON.stringify({
                [talentType]: getForm(talentType)
            });
            formData.append('talentForms', talentForms);
            let talentState = JSON.stringify(talentRef.current);
            formData.append('talent', talentState);
            formData.append('talentType', talentType);
        }

        if(userWillUploadFile){
            if(acceptedFilesRef.current.length > 0){
                for (let i = 0; i < acceptedFilesRef.current.length; i++) {
                    formData.append('file' + i, acceptedFilesRef.current[i]);
                }
            }
        } else {
            formData.append('fileUrl', additionalDocumentFieldsRef.current.fileUrl.input);
        }

        axios.post(SERVER_PATH + '/data/client/docs/review', formData, {headers: {'Content-Type': 'multipart/form-data'}})
        .then(res => { 
            clearForm();
            setLoading(false);
            if(socket){
                socket.emit('DOCUMENT FORM SENT', { actionUserId: activeUser._id, actionUserName: activeUser.fullName, updatedDocument: res.data.record});
            }
        })
        .catch(err => {
            setLoading(false);
            toast(ERROR_MESSAGE_UNKNOWN);
        });
    };

    const clearForm = () => {
        reviewDocumentFormRef.current = defaultReviewDocumentForm;
        additionalDocumentFieldsRef.current = defaultAdditionalDocumentFieldsRef;
        talentRef.current = {};
        setUserWillUploadFile(true);
        setTalentType('');
        setFormSent(true);
    };

    const handleNewFormButton = () => {
        setFormSent(false);
    };

    const selectedTalentForm = useMemo(() => <TalentForm noTitle form="document" type={talentType} talentRef={talentRef} />, [talentType]);

    return (
        <ViewBox>
            <Container maxWidth="md">
                {
                    formSent
                    ?
                    <Box>
                        <Box mb={3}>
                            <Collapse in={true}>
                                <Alert variant="filled" severity="success">O formulário foi enviado.</Alert>
                            </Collapse>
                        </Box>
                        <Box>
                            <Grid container justifyContent="center">
                                <Button
                                    variant="contained" color="primary"
                                    onClick={handleNewFormButton}
                                >Novo formulário</Button>
                            </Grid>
                        </Box>
                    </Box>
                    :
                    <Box>
                        {
                            talentModuleActive &&
                            <Box mb={2}>
                                <Container maxWidth="xs">
                                    <Select
                                        options={[
                                            { value: 'castComplete', label: 'Elenco (completo)' },
                                            { value: 'castSimple', label: 'Elenco (simples)' },
                                            { value: 'creativesComplete', label: 'Roteirista/Diretor (completo)' },
                                            { value: 'creativesSimple', label: 'Roteirista/Diretor (simples)' },
                                            { value: 'option', label: 'Contrato de opção' },
                                        ]}
                                        value={talentType || 'select'} onChange={(e) => setTalentType(e.target.value)}
                                    />
                                </Container>
                            </Box>
                        }
                        {
                            selectedFolder?.useGroups &&
                            <QuestionBox name="Grupo" mandatory questionValid>
                                <Select options={groupOptions} value={selectedGroup} onChange={(e) => set_selectedGroup(e.target.value)} />
                            </QuestionBox>
                        }
                        {
                            !talentType &&
                            <>
                                <QuestionBox name={question1Name} mandatory questionValid>
                                    <InputField formRef={reviewDocumentFormRef} questionKey={question1Name} />
                                </QuestionBox>
                                <QuestionBox name={question2Name} mandatory questionValid>
                                    <InputField formRef={reviewDocumentFormRef} questionKey={question2Name} />
                                </QuestionBox>
                                <QuestionBox name={question3Name} questionValid>
                                    <InputField formRef={reviewDocumentFormRef} questionKey={question3Name} />
                                </QuestionBox>
                            </>
                        }
                        {
                            (talentModuleActive && talentType) &&
                            <Box mb={3}>
                                {
                                    loadingProjectTalent
                                    ? <CenteredCircularProgress />
                                    :
                                    <QuestionBox questionValid>
                                        {selectedTalentForm}
                                    </QuestionBox>
                                }
                            </Box>
                        }

                        <Box mb={2}>
                            <TwoButtonsSwitch
                                boolean={userWillUploadFile}
                                setBoolean={setUserWillUploadFile}
                                firstButtonStartIcon={<AttachFileIcon />}
                                firstButtonText="Arquivo"
                                secondButtonStartIcon={<LinkIcon />}
                                secondButtonText="Link"
                            />
                        </Box>
                        
                        {
                            userWillUploadFile
                            ?
                            <QuestionBox name={question4Name} questionValid>
                                <Dropzone
                                    acceptedFilesRef={acceptedFilesRef}
                                    accept={{
                                        'application/pdf': ['.pdf'],
                                        'application/msword': ['.doc'],
                                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
                                    }}
                                    multiple
                                />
                            </QuestionBox>
                            :
                            <QuestionBox name="Link" questionValid>
                                <InputField formRef={additionalDocumentFieldsRef} questionKey="fileUrl" />
                            </QuestionBox>
                        }

                        <Box>
                            {
                                errorText &&
                                <FormHelperText error>{errorText}</FormHelperText>
                            }
                            <Grid container justifyContent="center">
                                <Button
                                    variant="contained" color="primary"
                                    disabled={loading}
                                    onClick={handleSubmit}
                                >Enviar</Button>
                            </Grid>
                            <Grid container justifyContent="center">
                                <Typography variant="body1" color="secondary">* Perguntas obrigatórias</Typography>
                            </Grid>
                        </Box>
                    </Box>
                }
            </Container>
        </ViewBox>
    );
}

export default RequestReview;