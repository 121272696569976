import React, { useState } from 'react';
import Select from '../../../Select';

const GroupField = ({groupOptions, addDocumentForm, checkSubmitButtonDisabled}) => {
    const [selectedGroup, setSelectedGroup] = useState('');
    const handleChange = (newValue) => {
        setSelectedGroup(newValue);
        addDocumentForm.current['groupId'] = newValue;

        checkSubmitButtonDisabled();
    };
    return (
        <Select
            label="Grupo"
            options={groupOptions}
            value={selectedGroup} onChange={(e) => handleChange(e.target.value)}
        />
    );
};

export default GroupField;