import Snackbar from '@mui/material/Snackbar';
import { useAppCtxAPI, useAppCtxToast } from '../context/SystemContext';

const Toast = () => {
    const { setToastVisible } = useAppCtxAPI();
    const { toastVisible, toastProps } = useAppCtxToast();
    return (
        <Snackbar
            anchorOrigin={
                {
                    vertical: toastProps.vertical || 'top',
                    horizontal: 'center',
                }
            }
            open={toastVisible}
            onClose={() => setToastVisible(false)}
            autoHideDuration={3000}
            message={toastProps.message || ''}
        />
    );
}

export default Toast;