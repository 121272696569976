import { Backdrop, Box, Grid, CircularProgress, Typography } from '@mui/material';

const LoadingBackdrop = ({backgroundColor, content, onClick, open, text}) => {
    let backdropStyle = {};
    if(backgroundColor) backdropStyle.backgroundColor = backgroundColor;
    return (
        <Backdrop
            sx={{
                zIndex: theme => theme.zIndex.drawer + 1 + 9999,
                color: theme => theme.palette.background.paper,
            }}
            open={open || false} onClick={onClick} style={backdropStyle}
        >
            <Box>
                {
                    text &&
                    <Box mb={2}>
                        <Grid container justifyContent="center">
                            <Grid item>
                                <Typography variant="body1" color="inherit">{text}</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                }
                {
                    content &&
                    <Box mb={2}>
                        <Grid container justifyContent="center">
                            <Grid item>
                                {content}
                            </Grid>
                        </Grid>
                    </Box>
                }
                <Grid container justifyContent="center">
                    <Grid item>
                        <CircularProgress color="inherit" />
                    </Grid>
                </Grid>
            </Box>
        </Backdrop>
    );
};

export default LoadingBackdrop;