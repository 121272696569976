import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import Autocomplete from '../../components/Autocomplete';
import DataListItem from '../../components/DataListItem';
import NewClientWindow from './components/NewClientWindow';
import SectionTitle from '../../components/SectionTitle';
import ViewBox from '../../components/ViewBox';
import { useAppCtxAPI, useAppCtxAvailableClientsCompaniesFolders } from '../../context/SystemContext';
import { sortByKey } from '../../utils/filters';
import { serverRequest } from '../../utils/common';
import { CLIENT_MODULES } from '../../utils/constants';

const classes = {
    fab: {
        position: 'fixed',
        bottom: '8px',
        right: '8px',
        zIndex: theme => theme.zIndex.modal + 1
    },
};

function AppClients(){
    const { getAvailableAppClientsAction, toast } = useAppCtxAPI();
    const { availableAppClients } = useAppCtxAvailableClientsCompaniesFolders();
    const [selectedClient, setSelectedClient] = useState(null);
    const [foundClient, setFoundClient] = useState(null);
    const [newClientWindowOpen, setNewClientWindowOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getAvailableAppClientsAction();
        setLoading(false);
    }, []);

    const loadClient = async () => {
        setLoading(true);
        try {
            const data = await serverRequest({path: `/data/clients/one?selectedClientId=${selectedClient._id}`});
            setFoundClient(data);
        } catch (error) {
            toast(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        if(selectedClient){
            loadClient();
        } else {
            setFoundClient(null);
        }
    }, [selectedClient]);

    const addClient = () => {
        setSelectedClient(null);
        setFoundClient(null);
        setNewClientWindowOpen(true);
    };

    const changeClient = async (modules) => {
        const updates = {
            modules
        };
        const foundClientCopy = {...foundClient};
        setFoundClient(prevState => ({...prevState, ...updates}));

        setLoading(true);
        try {
            const data = await serverRequest({path: '/data/clients/one/update', method: 'post', data: {
                updatedClientId: foundClient._id,
                updates
            }});
            // const updatedClient = data;
            // if(activeClient._id === foundClient._id){
            //     // setModules(modules);
            //     socket.emit('CLIENT MODULES UPDATED', { updatedClient }); 
            // }
        } catch (error) {
            // toast(error);
            setFoundClient(foundClientCopy);
        }
        setLoading(false);
    };

    const addModule = (modules = [], addedModule) => {
        modules.push(addedModule);
        changeClient(modules);
    };

    const removeModule = (modules = [], removedModule) => {
        const moduleIndex = modules.findIndex(module => module === removedModule);
        modules = [...modules.slice(0, moduleIndex), ...modules.slice(moduleIndex + 1)];
        changeClient(modules);
    };

    const ModuleButton = ({id, name, modules}) => (
        <Grid item>
            {
                modules.includes(id)
                ?
                <Button variant="outlined" color="primary" onClick={() => removeModule(modules, id)}>{name}</Button>
                :
                <Button onClick={() => addModule(modules, id)}>{name}</Button>
            }
        </Grid>
    );

    const ClientItem = ({client}) => (
        <DataListItem>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12}>
                    <Typography variant="h2">{client.name}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <SectionTitle title={`${client.screenName} - ${client.shortName}`} />
                </Grid>
                <Grid item xs={12}>
                    <SectionTitle title="Módulos" />
                    <Grid container spacing={1} alignItems="center">
                        {
                            CLIENT_MODULES.map(module => (
                                <ModuleButton key={module.id} id={module.id} name={module.name} modules={client.modules} />
                            ))
                        }
                    </Grid>
                </Grid>
            </Grid>
        </DataListItem>
    );

    return (
        <ViewBox>
            <Box mb={2}>
                <Container maxWidth="xs">
                    <Grid container alignItems="flex-end" spacing={1}>
                        {
                            loading &&
                            <Grid item>
                                <CircularProgress size={20} />
                            </Grid>
                        }
                        <Grid item xs>
                            <Autocomplete
                                disabled={loading}
                                placeholder="Escolher"
                                options={availableAppClients?.sort(sortByKey('name'))}
                                value={selectedClient}
                                onChange={(e, newValue) => setSelectedClient(newValue)}
                                getOptionLabel={(option) => `${option.name}`}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            {
                foundClient &&
                <ClientItem client={foundClient} />
            }
            <NewClientWindow open={newClientWindowOpen} setOpen={setNewClientWindowOpen} />
            <Fab
                color="primary"
                sx={classes.fab}
                onClick={addClient}
            ><AddIcon /></Fab>
        </ViewBox>
    );
}

export default AppClients;