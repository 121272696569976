import { Routes, Route } from 'react-router-dom';
import { FormProvider } from '../../Form/context/FormContext';
import RequestDoc from './RequestDoc';
import SavedRequestDocForms from './SavedRequestDocForms';

function Forms(){
    return (
        <FormProvider>
            <Routes>
                <Route path="/" element={<RequestDoc />} />
                <Route path="/salvos" element={<SavedRequestDocForms />} />
                <Route path="*" element={<RequestDoc />} />
            </Routes>
        </FormProvider>
    );
}

export default Forms;