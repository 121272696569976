import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import LinkIcon from '@mui/icons-material/Link';
import SaveIcon from '@mui/icons-material/Save';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import PopOver from './PopOver';
import TwoButtonsSwitch from './TwoButtonsSwitch';
import { ERROR_MESSAGE_UNKNOWN, SERVER_PATH, SUCCESS_MESSAGE_SAVED } from '../utils/constants';
import { useAppCtxAPI } from '../context/SystemContext';
import axios from 'axios';

const AddDocumentVersionPopOver = ({open, onClose, anchorPosition, record, setSelectedDocument, updateDocument}) => {
    const { setLoading, toast } = useAppCtxAPI();
    const [versionName, set_versionName] = useState('');
    const [uploadTypeUrl, set_uploadTypeUrl] = useState(false);
    const [googleUrl, set_googleUrl] = useState('');
    
    const handle_uploadExecutedAgreementClick = () => {
        if(!versionName){
            toast('Insira uma descrição.');
        } else {
            if(uploadTypeUrl){
                if(!googleUrl){
                    toast('Insira um link.');
                } else {
                    handle_saveNewVersion(false);
                }
            } else {
                triggerFileButton();
            }
        }
    };
    const triggerFileButton = () => {
        document.getElementById('docFile').click();
    };
    const handle_saveNewVersion = (hasFile) => {
        setLoading(true);
        onClose();
        let valid = true;
        let formData = new FormData();
        formData.append('docId', record._id);
        formData.append('docName', record.name);
        formData.append('name', versionName);
        if(!hasFile){
            formData.append('link', googleUrl);
        } else {
            const filesInput = document.getElementById('docFile');
            let files = filesInput.files;
            if(files.length > 0){
                toast('Verificando arquivos... isso pode levar alguns segundos...');
                formData.append('file', files[0]);
            } else {
                valid = false;
            }
        }
        if(valid){
            axios.post(SERVER_PATH + '/data/client/docs/file/upload', formData, {headers: {'Content-Type': 'multipart/form-data'}})
            .then(res => {
                let documentNewValue = res.data;
                updateDocument(documentNewValue);
                setSelectedDocument(documentNewValue);
                set_versionName('');
                set_googleUrl('');
                const filesInput = document.getElementById('docFile');
                if(filesInput) filesInput.value = '';
                setLoading(false);
                toast(SUCCESS_MESSAGE_SAVED);
            })
            .catch(err => {
                setLoading(false);
                toast(ERROR_MESSAGE_UNKNOWN);
            });
        }
    };

    return (
        <PopOver open={open} onClose={onClose} anchorPosition={anchorPosition}
            title="Nova versão"
        >
            <Box mb={2}>
                <TextField
                    variant="standard"
                    fullWidth
                    label="Descrição"
                    value={versionName}
                    onChange={(e) => set_versionName(e.target.value)} />
            </Box>
            <Box mb={2}>
                <TwoButtonsSwitch
                    boolean={uploadTypeUrl}
                    setBoolean={set_uploadTypeUrl}
                    firstButtonStartIcon={<LinkIcon />}
                    firstButtonText="Link"
                    secondButtonStartIcon={<AttachFileIcon />}
                    secondButtonText="Arquivo"
                />
            </Box>
            {
                uploadTypeUrl
                ?
                <Box>
                    <Box mb={2}>
                        <TextField
                            variant="standard"
                            fullWidth
                            label="Link"
                            value={googleUrl}
                            onChange={(e) => set_googleUrl(e.target.value)} />
                    </Box>
                    <Box mb={2} style={{textAlign: 'center'}}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<SaveIcon />}
                            onClick={() => handle_uploadExecutedAgreementClick()}
                        >Salvar</Button>
                    </Box>
                </Box>
                :
                <Box mb={2} style={{textAlign: 'center'}}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<FontAwesomeIcon icon={faFileUpload} />}
                        onClick={() => handle_uploadExecutedAgreementClick()}
                    >Escolher PDF ou DOC</Button>
                    <input style={{display: 'none'}} id="docFile" type="file" accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={() => handle_saveNewVersion(true)} />
                </Box>
            }
        </PopOver>
    );
};

export default AddDocumentVersionPopOver;