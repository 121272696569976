import { useEffect, useState } from 'react';
import Dropzone from './Dropzone';

const FormQuestionFile = ({acceptedFilesRef, initialValue, onChange, question}) => {
    const defaultValue = { files: [] };
    const [value, setValue] = useState(defaultValue);
    
    useEffect(() => {
        setValue(initialValue || defaultValue);
    }, [initialValue]);

    useEffect(() => {
        if(onChange) onChange(question.name, value);
    }, [value]);

    const handleChange = (newValue) => {
        setValue(prevState => ({...prevState, files: newValue}));
    };
    
    return (
        <Dropzone
            acceptedFilesRef={acceptedFilesRef}
            accept={{
                'application/pdf': ['.pdf'],
                // 'application/msword': ['.doc'],
                // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
            }}
            acceptedExtensions={['PDF']}
            multiple
            onChange={handleChange}
        />
    );
};

export default FormQuestionFile;