import { useEffect, useState } from 'react';
import { Box, Button, Grid, IconButton, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Select from '../../../../../components/Select';
import Window from '../../../../../components/Window';
import { v4 as uuidv4 } from 'uuid';

const TitlePatternElementWindow = ({dateTitlePatternElementValueOptions, open, setOpen, selectedTitlePatternElement, setTitlePattern, templateQuestions, templateUserFields}) => {
    const defaultTitlePatternElement = {
        type: 'text',
        value: ' - '
    };
    const [titlePatternElement, setTitlePatternElement] = useState(defaultTitlePatternElement);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

    useEffect(() => {
        if(open){
            setTitlePatternElement(selectedTitlePatternElement || defaultTitlePatternElement);
        }
    }, [open, selectedTitlePatternElement]);

    useEffect(() => {
        let currentSaveButtonDisabled = false;
        if(!titlePatternElement.type){
            currentSaveButtonDisabled = true;
        } else {
            if(titlePatternElement.type === 'date'){
                if(!titlePatternElement.value) currentSaveButtonDisabled = true;
            } else if(titlePatternElement.type === 'ifQuestionResponseIs' || titlePatternElement.type === 'ifQuestionResponseIsNot'){
                if(!titlePatternElement.value.conditions){
                    currentSaveButtonDisabled = true;
                } else {
                    titlePatternElement.value.conditions.forEach(condition => {
                        if(!condition.questionName || condition.questionName === 'select'){
                            currentSaveButtonDisabled = true;
                        } else {

                        }
                    });
                }
                if(!titlePatternElement.value.outputType || titlePatternElement.outputType === 'select'){
                    currentSaveButtonDisabled = true;
                } else {
                    if(titlePatternElement.value.outputType === 'text' && !titlePatternElement.value.output) currentSaveButtonDisabled = true;
                }
            } else if(titlePatternElement.type === 'userTemplateField'){
                if(!titlePatternElement.value) currentSaveButtonDisabled = true;
            }
        }
        setSaveButtonDisabled(currentSaveButtonDisabled);
    }, [titlePatternElement]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleTypeChange = (newValue) => {
        const defaultTypeValues = {
            count: 'folder',
            date: 'Y',
            ifQuestionResponseIs: {
                conditions: [{ questionName: '' }]
            },
            ifQuestionResponseIsNot: {
                conditions: [{ questionName: '' }]
            },
            text: ' - ',
        };
        setTitlePatternElement(() => {
            const nextState = {};
            nextState.type = newValue;
            nextState.value = defaultTypeValues[newValue] || '';
            return nextState;
        });
    };

    const handleOutputTypeChange = (newValue) => {
        setTitlePatternElement(prevState => {
            const nextState = {...prevState};
            nextState.value.outputType = newValue;
            if(newValue === 'text'){
                nextState.value.output = '';
            } else if(newValue === 'response'){
                nextState.value.output = prevState.value.questionName;
            }
            nextState.value.elseOutputType = 'text';
            nextState.value.elseOutput = '';
            return nextState;
        });
    };

    const handleElseOutputTypeChange = (newValue) => {
        setTitlePatternElement(prevState => {
            const nextState = {...prevState};
            nextState.value.elseOutputType = newValue;
            if(newValue === 'text'){
                nextState.value.elseOutput = '';
            } else if(newValue === 'response'){
                nextState.value.elseOutput = prevState.value.questionName;
            }
            return nextState;
        });
    };

    const handleDeleteElement = () => {
        setTitlePattern(prevState => {
            const prevStateElementIndex = prevState.findIndex(prevStateElement => prevStateElement.id === titlePatternElement.id);
            if(prevStateElementIndex !== -1){
                return [...prevState.slice(0, prevStateElementIndex), ...prevState.slice(prevStateElementIndex + 1)];
            }
            return prevState;
        });
        handleClose();
    };

    const handleDeleteCondition = (conditionIndex) => {
        setTitlePatternElement(prevState => ({
            ...prevState,
            value: {
                ...prevState.value,
                conditions: [
                    ...prevState.value.conditions.slice(0, conditionIndex),
                    ...prevState.value.conditions.slice(conditionIndex + 1)
                ]
            }
        }));
    };

    const handleSave = () => {
        if(selectedTitlePatternElement){
            setTitlePattern(prevState => {
                const nextState = [...prevState];
                const titlePatternElementIndex = nextState.findIndex(prevTitlePatternElement => prevTitlePatternElement.id === selectedTitlePatternElement.id);
                if(titlePatternElementIndex !== -1){
                    nextState[titlePatternElementIndex] = {...nextState[titlePatternElementIndex], ...titlePatternElement};
                }
                return nextState;
            });
        } else {
            setTitlePattern(prevState => [...prevState, {...titlePatternElement, id: uuidv4()}]);
        }
        handleClose();
    };

    return (
        <Window
            open={open} onClose={handleClose}
            modalInnerBoxSx={{ width: ['75%', '50%', '20%'] }}
            handleSubmitButtonClick={handleSave}
            submitButtonDisabled={saveButtonDisabled}
        >
            <Box mb={2}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs>
                        <Box mb={1}>
                            <Typography variant="h6">Elemento</Typography>
                        </Box>
                        <Select
                            options={[
                                {value: 'text', label: 'Texto'},
                                {value: 'date', label: 'Data'},
                                {value: 'count', label: 'Quantidade na pasta'},
                                {value: 'question', label: 'Resposta do formulário'},
                                {value: 'ifQuestionResponseIs', label: 'Lógica "Se"'},
                                {value: 'ifQuestionResponseIsNot', label: 'Lógica "Se não"'},
                                {value: 'userTemplateField', label: 'Informação especial'},
                                {value: 'shortId', label: 'ID do documento'},
                            ]}
                            value={titlePatternElement.type} onChange={(e) => handleTypeChange(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <IconButton onClick={handleDeleteElement} size="large">
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
            {
                titlePatternElement.type === 'text' &&
                <Box mb={2}>
                    <Box mb={1}>
                        <Typography variant="h6">Texto</Typography>
                    </Box>
                    <TextField
                        variant="standard"
                        fullWidth
                        value={titlePatternElement.value}
                        onChange={(e) => setTitlePatternElement(prevState => ({...prevState, value: e.target.value}))} />
                </Box>
            }
            {
                titlePatternElement.type === 'date' &&
                <Box mb={2}>
                    <Box mb={1}>
                        <Typography variant="h6">Formato</Typography>
                    </Box>
                    <Select
                        options={dateTitlePatternElementValueOptions}
                        value={titlePatternElement.value} onChange={(e) => setTitlePatternElement(prevState => ({...prevState, value: e.target.value}))}
                    />
                </Box>
            }
            {
                titlePatternElement.type === 'question' &&
                <Box mb={2}>
                    <Box mb={1}>
                        <Typography variant="h6">Escolha uma pergunta do formulário</Typography>
                    </Box>
                    <Select
                        options={templateQuestions}
                        value={titlePatternElement.value.questionName || 'select'}
                        onChange={(e) => setTitlePatternElement(prevState => ({...prevState, value: {...prevState.value, questionName: e.target.value}}))}
                    />
                </Box>
            }
            {
                ['ifQuestionResponseIs', 'ifQuestionResponseIsNot'].includes(titlePatternElement.type) &&
                <Box>
                    {
                        Array.isArray(titlePatternElement.value.conditions) &&
                        <Box>
                            {
                                titlePatternElement.value.conditions.map((condition, conditionIndex) => (
                                    <Box key={conditionIndex}>
                                        <Box mb={2}>
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item xs>
                                                    <Typography variant="h6">Condição {titlePatternElement.type === 'ifQuestionResponseIs' ? 'alternativa' : 'simultânea'} {conditionIndex + 1}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => handleDeleteCondition(conditionIndex)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box ml={2}>
                                            <Box mb={2}>
                                                <Box mb={1}>
                                                    <Typography variant="h6">Escolha uma pergunta do formulário</Typography>
                                                </Box>
                                                <Select
                                                    options={templateQuestions}
                                                    value={condition.questionName || 'select'}
                                                    onChange={(e) => setTitlePatternElement(prevState => ({...prevState, value: {...prevState.value, conditions: [...prevState.value.conditions.slice(0, conditionIndex), {...prevState.value.conditions[conditionIndex], questionName: e.target.value}, ...prevState.value.conditions.slice(conditionIndex + 1)]}}))}
                                                />
                                            </Box>
                                            {
                                                condition.questionName &&
                                                <Box mb={2}>
                                                    <Box mb={1}>
                                                        <Typography variant="h6">Se a resposta a essa pergunta for {titlePatternElement.type === 'ifQuestionResponseIs' ? 'igual à' : 'diferente da'} seguinte...</Typography>
                                                    </Box>
                                                    <TextField
                                                        variant="standard"
                                                        fullWidth
                                                        value={condition.response || ''}
                                                        onChange={(e) => setTitlePatternElement(prevState => ({...prevState, value: {...prevState.value, conditions: [...prevState.value.conditions.slice(0, conditionIndex), {...prevState.value.conditions[conditionIndex], response: e.target.value}, ...prevState.value.conditions.slice(conditionIndex + 1)]}}))} />
                                                </Box>
                                            }
                                        </Box>
                                    </Box>       
                                ))
                            }
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Button
                                        startIcon={<AddIcon />}
                                        onClick={() => setTitlePatternElement(prevState => ({...prevState, value: {...prevState.value, conditions: [...prevState.value.conditions, {questionName: ''}]}}))}
                                    >Condição</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    }
                    {
                        titlePatternElement.value.conditions[0]?.questionName &&
                        <Box mb={2}>
                            <Box mb={1}>
                                <Typography variant="h6">Então, aplicar o seguinte efeito:</Typography>
                            </Box>
                            <Select
                                options={[
                                    {value: 'text', label: 'Texto'},
                                    {value: 'response', label: 'Resposta do formulário'},
                                ]}
                                value={titlePatternElement.value.outputType || 'select'}
                                onChange={(e) => handleOutputTypeChange(e.target.value)}
                            />
                        </Box>
                    }
                    {
                        titlePatternElement.value.outputType === 'text' &&
                        <Box mb={2}>
                            <Box mb={1}>
                                <Typography variant="h6">Texto</Typography>
                            </Box>
                            <TextField
                                variant="standard"
                                placeholder="(em branco)"
                                fullWidth
                                value={titlePatternElement.value.output || ''}
                                onChange={(e) => setTitlePatternElement(prevState => ({...prevState, value: {...prevState.value, output: e.target.value}}))} />
                        </Box>
                    }
                    {
                        titlePatternElement.value.outputType === 'response' &&
                        <Box mb={2}>
                            <Box mb={1}>
                                <Typography variant="h6">Escolha uma pergunta do formulário</Typography>
                            </Box>
                            <Select
                                options={templateQuestions}
                                value={titlePatternElement.value.output || 'select'}
                                onChange={(e) => setTitlePatternElement(prevState => ({...prevState, value: {...prevState.value, output: e.target.value}}))}
                            />
                        </Box>
                    }
                    {
                        titlePatternElement.value.outputType &&
                        <Box mb={2}>
                            <Box mb={1}>
                                <Typography variant="h6">Caso contrário, aplicar o seguinte efeito:</Typography>
                            </Box>
                            <Select
                                options={[
                                    {value: 'text', label: 'Texto'},
                                    {value: 'response', label: 'Resposta do formulário'},
                                ]}
                                value={titlePatternElement.value.elseOutputType || 'select'}
                                onChange={(e) => handleElseOutputTypeChange(e.target.value)}
                            />
                        </Box>
                    }
                    {
                        titlePatternElement.value.elseOutputType === 'text' &&
                        <Box mb={2}>
                            <Box mb={1}>
                                <Typography variant="h6">Texto</Typography>
                            </Box>
                            <TextField
                                variant="standard"
                                placeholder="(em branco)"
                                fullWidth
                                value={titlePatternElement.value.elseOutput || ''}
                                onChange={(e) => setTitlePatternElement(prevState => ({...prevState, value: {...prevState.value, elseOutput: e.target.value}}))} />
                        </Box>
                    }
                    {
                        titlePatternElement.value.elseOutputType === 'response' &&
                        <Box mb={2}>
                            <Box mb={1}>
                                <Typography variant="h6">Escolha uma pergunta do formulário</Typography>
                            </Box>
                            <Select
                                options={templateQuestions}
                                value={titlePatternElement.value.elseOutput || 'select'}
                                onChange={(e) => setTitlePatternElement(prevState => ({...prevState, value: {...prevState.value, elseOutput: e.target.value}}))}
                            />
                        </Box>
                    }
                </Box>
            }
            {
                titlePatternElement.type === 'userTemplateField' &&
                <Box>
                    <Box mb={1}>
                        <Typography variant="h6">Informação especial</Typography>
                    </Box>
                    <Select
                        options={templateUserFields}
                        value={titlePatternElement.value || 'select'}
                        onChange={(e) => setTitlePatternElement(prevState => ({...prevState, value: e.target.value}))}
                    />
                </Box>
            }
        </Window>
    );
};

export default TitlePatternElementWindow;