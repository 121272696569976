import { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import FilterListIcon from '@mui/icons-material/FilterList';
import Autocomplete from '../../../../components/Autocomplete';
import FavoriteProjects from '../../../../components/FavoriteProjects';
import FiltersPopOver from './components/FiltersPopOver';
import OperatorDocumentsPage from '../components/OperatorDocumentsPage';
import SearchEngineTextField from '../../../../components/SearchEngineTextField';
import { useAppCtxAPI } from '../../../../context/SystemContext';
import { useSocket, useSocketCtxConnectedAt } from '../../../../context/SocketContext';
import { useDocumentsAPI } from '../../../../context/DocumentsContext';
import { useOperatorCtxProjects } from '../../context/OperatorContext';
import queryString from 'query-string';
import { serverRequest } from '../../../../utils/common';
import { sortByKey } from '../../../../utils/filters';
import { Typography } from '@mui/material';
import useWindowSize from '../../../../hooks/useWindowSize';

function Sent(){
    const { floatingAlert } = useAppCtxAPI();
    const { shouldUpdateTemplates } = useSocket();
    const socketConnectedAt = useSocketCtxConnectedAt();
    const { dispatch: dispatchDocuments } = useDocumentsAPI();
    const projects = useOperatorCtxProjects();
    const [loading, setLoading] = useState(false);
    const [visibleDocuments, setVisibleDocuments] = useState([]);
    const [documentAlerts, setDocumentAlerts] = useState([]);
    const defaultFilters = {
        company: {_id: 'all', name: '(Todas)'},
        folder: {_id: 'none', name: '(Todas)'},
        text: '',
        status: 'sent',
        signatureStatus: '',
        orderBy: 'sentOn'
    };
    const [filters, setFilters] = useState(defaultFilters);
    const [projectOptions, setProjectOptions] = useState([]);
    const [documentsCount, set_documentsCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const filtersButtonRef = useRef(null);
    const [filtersPopOverAnchorEl, set_filtersPopOverAnchorEl] = useState(null);
    const [filtersPopOverOpen, set_filtersPopOverOpen] = useState(false);
    const shouldFetchDocuments = useRef(false);
    const shouldFetchRecentDocuments = useRef(false);
    const { windowWidth } = useWindowSize();

    useEffect(() => {
        if(shouldUpdateTemplates){
            dispatchDocuments({type: 'LOAD TEMPLATES'});
        }
    }, [socketConnectedAt]);

    const loadPage = async (page) => {
        setLoading(true);
        setVisibleDocuments([]);
        const postData = {
            shouldFetchRecentDocuments: shouldFetchRecentDocuments.current
        };
        if(filters.signatureStatus !== 'none'){
            postData.signature = filters.signatureStatus;
        }
        if(filters.folder && filters.folder._id !== 'none' && filters.folder._id !== 'all') postData.project = filters.folder._id;
        if(filters.company && filters.company._id !== '~none;' && filters.company._id !== 'all') postData.client = filters.company._id;
        if(filters.text) postData.text = filters.text;
        if(filters.status && filters.status !== 'sent'){
            postData.status = filters.status;
            postData.orderBy = 'timestamp';
        } else {
            if(filters.orderBy && filters.orderBy !== 'sentOn') postData.orderBy = filters.orderBy;
        }
        postData.page = page;
        try {
            const data = await serverRequest({path: '/data/operator/docs/search', method: 'post', data: postData});
            set_documentsCount(data.count || data.docs.length);
            setVisibleDocuments(data.docs);
            setDocumentAlerts(data.docsAlerts);
        } catch (error) {
            floatingAlert(error, 'error', 0);
        }
        shouldFetchRecentDocuments.current = false;
        setLoading(false);
    };
    
    const handle_pageClick = (page) => {
        setCurrentPage(page);
        loadPage(page);
    };
    
    useEffect(() => {
        if(shouldFetchDocuments.current){
            handle_pageClick(1);
        }
        shouldFetchDocuments.current = true;
    }, [filters]);

    useEffect(() => {
        if(projects){
            const windowLocationSearch = queryString.parse(window.location.search);
            const companyId = windowLocationSearch.c;
            if(companyId){
                shouldFetchDocuments.current = true;
                setFilters(prevState => {
                    const nextState = {...prevState};
                    const foundCompany = projects.find(project => project._id === companyId);
                    nextState.company = foundCompany;
                    const folderId = windowLocationSearch.p;
                    const foundFolder = folderId ? projects.find(project => project._id === folderId) : {_id: 'none', name: '(Todas)'};
                    nextState.folder = foundFolder;
                    return nextState;
                });
            }
        }
    }, []);

    useEffect(() => {
        let currentFolderOptions = [];
        if(filters.company && projects){
            if(filters.company._id === 'all'){
                currentFolderOptions = projects.filter(project => !!project.client).sort(sortByKey('name')).map((mappedFolder, mappedFolderIndex) => ({...mappedFolder, name: `${mappedFolderIndex + 1}) ${mappedFolder.name}`}));
            } else {
                currentFolderOptions = projects.filter(project => project.client === filters.company._id).sort(sortByKey('name')).map((mappedFolder, mappedFolderIndex) => ({...mappedFolder, name: `${mappedFolderIndex + 1}) ${mappedFolder.name}`}));
            }
            currentFolderOptions.unshift({_id: 'none', name: '(Todas)'});
        }
        setProjectOptions(currentFolderOptions);
    }, [filters.company]);

    const setFavoriteFolder = (type, folderId) => {
        if(type === 'clients'){
            let favoriteClient = projects.find(i => i._id === folderId);
            setFilters(prevState => {
                const nextState = {...prevState};
                nextState.company = favoriteClient;
                nextState.folder = {_id: 'none', name: '(Todas)'};
                return nextState;
            });
        } else {
            let favoriteProject = projects.find(i => i._id === folderId);
            let favoriteClient = projects.find(i => i._id === favoriteProject.client);
            setFilters(prevState => {
                const nextState = {...prevState};
                nextState.company = favoriteClient;
                nextState.folder = favoriteProject;
                return nextState;
            });
        }
    };
    const handleClientChange = (newValue) => {
        setFilters(prevState => ({
            ...prevState,
            company: newValue,
            folder: null
        }));
    };
    const handleProjectChange = (newValue) => {
        setFilters(prevState => ({
            ...prevState,
            folder: newValue
        }));
    };
    const handle_lastItemsClick = () => {
        shouldFetchRecentDocuments.current = true;
        setFilters(defaultFilters);
    };
    
    const handleFiltersButtonClick = () => {
        set_filtersPopOverAnchorEl(filtersButtonRef.current);
        set_filtersPopOverOpen(true);
    };

    const handleEndIconClick = () => {
        handleFiltersButtonClick();
    };

    const handleSearch = (newValue) => {
        setFilters(prevState => ({
            ...prevState,
            text: newValue
        }));
    };

    return (
        <OperatorDocumentsPage
            currentPage={currentPage}
            documentAlerts={documentAlerts} setDocumentAlerts={setDocumentAlerts}
            documentsCount={documentsCount}
            handlePageClick={handle_pageClick}
            loading={loading} setLoading={setLoading}
            selectedFolderId={filters.folder?._id}
            visibleDocuments={visibleDocuments} setVisibleDocuments={setVisibleDocuments}
        >
            <FavoriteProjects handleFavoriteClick={setFavoriteFolder} />
            <Box mb={2}>
                <Grid container alignItems="flex-end" justifyContent="center" spacing={1}>
                    <Grid xs={12} md={2} style={{textAlign: 'center'}}>
                        <Button disabled={loading} variant="contained" color="primary" onClick={handle_lastItemsClick}>Recentes</Button>
                    </Grid>
                    {
                        (windowWidth && windowWidth < 960)
                        ?
                        <>
                            <Grid>
                                <Button
                                    disabled={loading}
                                    ref={filtersButtonRef}
                                    variant="outlined" startIcon={<FilterListIcon />}
                                    onClick={handleFiltersButtonClick}
                                >Filtros</Button>
                            </Grid>
                            <Grid xs={12} sm={6}>
                                <SearchEngineTextField
                                    disabled={loading}
                                    onEndIconClick={handleEndIconClick}
                                    onSearch={handleSearch}
                                />
                            </Grid>
                        </>
                        :
                        <>
                            {
                                projects &&
                                <Grid xs={12} sm={2}>
                                    <Autocomplete
                                        disabled={loading}
                                        label="Empresa"
                                        options={[{_id: 'all', name: '(Todas)'}, ...projects.sort(sortByKey('name')).filter(project => !project.client)]}
                                        getOptionLabel={(option) => option.name}
                                        value={filters.company} onChange={(e, newValue) => handleClientChange(newValue)}
                                    />
                                </Grid>
                            }
                            <Grid xs={12} sm={2}>
                                <Autocomplete
                                    disabled={loading}
                                    label="Pasta" options={projectOptions} getOptionLabel={(option) => option.name}
                                    value={filters.folder} onChange={(e, newValue) => handleProjectChange(newValue)}
                                />
                            </Grid>
                            <Grid xs ref={filtersButtonRef}>
                                <SearchEngineTextField
                                    disabled={loading}
                                    onEndIconClick={handleEndIconClick}
                                    onSearch={handleSearch}
                                />
                            </Grid>
                        </>
                    }
                </Grid>
            </Box>
            {
                (!loading && !!documentsCount) &&
                <Grid container justifyContent="center">
                    <Grid>
                        <Typography variant="h6">({documentsCount} documentos{filters.status === 'sent' ? ' enviados' : filters.status === 'scheduledSent' ? ' enviados automaticamente' : filters.status === 'deleted' ? ' cancelados' : ''})</Typography>
                    </Grid>
                </Grid>
            }
            <FiltersPopOver
                data={{
                    filtersPopOverOpen, set_filtersPopOverOpen, filtersPopOverAnchorEl,
                    filters, setFilters,
                    handleClientChange,
                    handleProjectChange,
                    loadingPage: loading,
                    projectOptions,
                    projects,
                    
                }}
            />
        </OperatorDocumentsPage>
    );
}
export default Sent;