import { useEffect, useState } from 'react';
import Select from './Select';

function SelectQuestion({initialValue, question, onChange}){
    const defaultValue = { choice: '' };
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        setValue(initialValue || defaultValue);
    }, [initialValue]);

    useEffect(() => {
        if(onChange) onChange(question.name, value);
    }, [value]);
    
    const handleChange = (newValue) => {
        setValue(prevState => ({...prevState, choice: newValue}));
    };

    return (
        <Select
            defaultValue=""
            value={value.choice} onChange={(e) => handleChange(e.target.value)}
            options={question.options}
        />
    );
}

export default SelectQuestion;