import axios from 'axios';
import { Box, Button, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchEngineTextField from '../../../../../components/SearchEngineTextField';
import { useAppCtxAPI, useAppCtxSelectedFolder, useAppCtxUseCompanyRootFolder } from '../../../../../context/SystemContext';
import { useDocumentsAPI } from '../../../../../context/DocumentsContext';
import { useFolderUserCtxUseAddDocument } from '../../../context/ClientContext';
import { SERVER_PATH } from '../../../../../utils/constants';

const classes = {
    buttonsBox: {
        justifyContent: ['space-evenly', 'flex-end'],
    },
};

const DocumentsTop = (props) => {
    const {
        filtersButtonRef,
        getQuery,
        loadingPage,
        searchValue, setSearchValue
    } = props;
    const { floatingAlert } = useAppCtxAPI();
    const selectedFolder = useAppCtxSelectedFolder();
    const useCompanyRootFolder = useAppCtxUseCompanyRootFolder();
    const { showAddDocumentView, showDocumentFiltersView } = useDocumentsAPI();
    const useAddDocument = useFolderUserCtxUseAddDocument();

    const customFilterButtonClick = (event) => {
        showDocumentFiltersView(filtersButtonRef.current);
    };

    const handle_addDocumentClick = () => {
        showAddDocumentView([selectedFolder], selectedFolder);
    };

    const handleEndIconClick = () => {
        customFilterButtonClick();
    };

    const handleSearch = (newValue) => {
        setSearchValue(newValue);
    };

    const handleExportButtonClick = async () => {
        floatingAlert('Gerando o relatório... isso pode levar alguns minutos...', 'warning', 0);
        let filename = '';

        let getData = getQuery('get');
        if(selectedFolder){
            if(getData) getData += '&';
            getData += `folderId=${selectedFolder._id}`;
        }
        await axios.get(SERVER_PATH + `/data/client/documents/export${getData ? `?${getData}` : ''}`, {
            responseType: 'blob'
        })
        .then(response => {
            const disposition = response.headers['content-disposition'];
            filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
            if (filename.toLowerCase().startsWith("utf-8''"))
               filename = decodeURIComponent(filename.replace("utf-8''", ''));
            else
               filename = filename.replace(/['"]/g, '');
            return response.data;
         })
         .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a); // append the element to the dom
            a.click();
            a.remove(); // afterwards, remove the element
            floatingAlert('');
        })
        .catch(err => {
            floatingAlert(err.response.data, 'error', 0);
        });
    };

    return (
        <Box mb={1}>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={searchValue ? 8 : 12} md={4} ref={filtersButtonRef}>
                    <SearchEngineTextField
                        disabled={loadingPage}
                        onEndIconClick={handleEndIconClick}
                        onSearch={handleSearch}
                    />
                </Grid>
                <Grid item xs container spacing={1} sx={classes.buttonsBox} alignItems="center">
                    {
                        (useAddDocument && !useCompanyRootFolder) &&
                        <Grid item>
                            <Button disabled={loadingPage} size="small" variant="contained" color="primary" startIcon={<AddIcon />} onClick={handle_addDocumentClick}>Adicionar documento (UPLOAD)</Button>
                        </Grid>
                    }
                    <Grid item>
                        <Button
                            disabled={loadingPage}
                            variant="outlined" size="small"
                            onClick={handleExportButtonClick}
                        >XLS</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

export default DocumentsTop;