import { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Container, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormHelperText from '@mui/material/FormHelperText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import AlertDialog from '../../../../../components/AlertDialog';
import FormEditorQuestion from './components/FormEditorQuestion';
import FormEditorQuestionWindow from './components/FormEditorQuestionWindow';
import TestTemplateWindow from '../components/TestTemplateWindow';
import ViewBox from '../../../../../components/ViewBox';
import NewTitleDialog from './components/NewTitleDialog';
import UpdateTitleDialog from './components/UpdateTitleDialog';
import UpdateDescriptionDialog from './components/UpdateDescriptionDialog';
import { ASSIGNABLE_FIELDS, SERVER_PATH } from '../../../../../utils/constants';
import { useAppCtxAPI, useAppCtxLoading } from '../../../../../context/SystemContext';
import { useSocket } from '../../../../../context/SocketContext';
import { useClientCtxActiveClient } from '../../../../../context/ClientContext';
import { useDocumentsAPI } from '../../../../../context/DocumentsContext';
import { useOperatorTemplatesCtxAPI, useOperatorTemplatesCtxTemplateName, useOperatorTemplatesCtxTemplateDocumentEditorValue, useOperatorTemplatesCtxTemplateEditorSelectedTemplate, useOperatorTemplatesCtxTemplateFormEditorQuestions, useOperatorTemplatesCtxTemplateEffects } from '../context/TemplatesContext';
import { useFormEditor } from './FormEditorContext';
import { FormProvider } from '../../../../Form/context/FormContext';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { logError } from '../../../../../utils/common';

const classes = {
    questionsListBox: {
        borderRadius: theme => theme.spacing(1),
        padding: theme => theme.spacing(1),
        transition: 'background-color 0.2s ease',
    },
};

const Div = styled('div')(unstable_styleFunctionSx);

function FormEditor(){
    const { handleNavigate, setLoading } = useAppCtxAPI();
    const loading = useAppCtxLoading();
    const { createSocketConnection } = useSocket();
    const socket = createSocketConnection();
    const activeClient = useClientCtxActiveClient();
    const { dispatch: dispatchDocuments } = useDocumentsAPI();
    const { deleteFormEditorQuestion, positionFormEditorQuestion, updateFormEditorQuestion } = useOperatorTemplatesCtxAPI();
    const templateName = useOperatorTemplatesCtxTemplateName();
    const documentEditorValue = useOperatorTemplatesCtxTemplateDocumentEditorValue();
    const templateEditorSelectedTemplate = useOperatorTemplatesCtxTemplateEditorSelectedTemplate();
    
    const formEditorQuestions = useOperatorTemplatesCtxTemplateFormEditorQuestions();
    const templateEffects = useOperatorTemplatesCtxTemplateEffects();
    const {
        clickedQuestion, setCorporationIdQuestions, deleteTitleDialogOpen, setDeleteTitleDialogOpen, assignFieldMenuOpen, setAssignFieldMenuOpen, assignFieldMenuAnchorEl,
        orderingForm, set_orderingForm
    } = useFormEditor();
    const [errorMessage, set_errorMessage] = useState('');
    const [testTemplateWindowOpen, setTestTemplateWindow] = useState(false);
    const [selectedFormEditorQuestion, setSelectedFormEditorQuestion] = useState(null);
    const [formEditorQuestionWindowOpen, setFormEditorQuestionWindowOpen] = useState(false);

    useEffect(() => {
        let currentCorporationIdQuestions = [];
        for(const formEditorQuestion of formEditorQuestions){
            if(formEditorQuestion.type === 'corporationId'){
                currentCorporationIdQuestions.push(formEditorQuestion);
            }
        }
        setCorporationIdQuestions(currentCorporationIdQuestions);
    }, [formEditorQuestions]);

    const handle_formEditorSectionBackClick = () => {
        handleNavigate(`/${activeClient.shortName}/juridico/documentos/matrizes/documento`);
    };
    
    const handle_formEditorSaveClick = () => {
        setLoading(true);
        set_errorMessage('');
        let postData = {};
        if(templateEditorSelectedTemplate){
            postData.currentTemplateId = templateEditorSelectedTemplate._id;
            postData.name = templateEditorSelectedTemplate.name;
        } else {
            postData.name = templateName;
        }
        let jsonForm = getJSONForm();
        postData.form = jsonForm;
        postData.html = documentEditorValue;
        postData.mainSheet = documentEditorValue;
        postData.effects = templateEffects;
        axios.post(SERVER_PATH + '/data/operator/docs/templates/save', postData)
        .then(res => { 
            if(!templateEditorSelectedTemplate){
                dispatchDocuments({type: 'ADD TEMPLATE', payload: res.data});
                socket.emit('TEMPLATE CREATED', { activeClientId: activeClient._id, createdTemplate: res.data});
            } else {
                dispatchDocuments({type: 'UPDATE TEMPLATE', payload: {...templateEditorSelectedTemplate, form: jsonForm}});
                socket.emit('TEMPLATE UPDATED', { activeClientId: activeClient._id, updatedTemplate: {...templateEditorSelectedTemplate, form: jsonForm}});
            }
            setLoading(false);
            handleNavigate(`/${activeClient.shortName}/juridico/documentos/matrizes`);
        })
        .catch(err => {
            logError(err);
            setLoading(false);
            set_errorMessage(err?.response?.data || 'Erro.');
        });
    };
    const getJSONForm = () => {
        return formEditorQuestions.map((question, questionIndex) => {
            const newQuestion = {...question};
            newQuestion.id = question.id || questionIndex;
            newQuestion.type = question.type;
            if(['text', 'list', 'special'].includes(newQuestion.type)){
                newQuestion.corporationIdQuestion = question.corporationIdQuestion;
            }
            if(['list', 'special'].includes(newQuestion.type)){
                newQuestion.showListDescription = !!question.showListDescription;
            }
            if(newQuestion.type === 'currency' || newQuestion.type === 'number'){
                newQuestion.typeSpecifics = question.typeSpecifics;
            }
            newQuestion.name = question.name;
            if(question.options) newQuestion.options = question.options.map(o => ({choice: o.choice, label: o.label}));
            if(question.description) newQuestion.description = question.description;
            if(question.logicalBranching) newQuestion.logicalBranching = question.logicalBranching;
            newQuestion.mandatory = question.mandatory ? question.mandatory : false;
            newQuestion.assignedField = question.assignedField ? question.assignedField : 0;
            newQuestion.starred = question.starred || false;
            if(newQuestion.type === 'special') newQuestion.special = question.special;
            newQuestion.typeId = question.typeId;
            newQuestion.textCase = question.textCase;
            return newQuestion;
        });
    };
    const handleDeleteTitleDialogOkClick = () => {
        setFormEditorQuestionWindowOpen(false);
        setDeleteTitleDialogOpen(false);
        deleteFormEditorQuestion(clickedQuestion.id);
    };

    const handleAssignField = (fieldId) => {
        setAssignFieldMenuOpen(false);
        const newValue = {...clickedQuestion, assignedField: fieldId !== 0 ? fieldId : null};
        setSelectedFormEditorQuestion(newValue);
        updateFormEditorQuestion(newValue);
    };

    const handleQuestionDragEnd = (data) => {
        positionFormEditorQuestion(data.source.index, data.destination.index);
    };

    const handleFormEditorQuestionWindowOpenButtonPress = (question) => {
        setSelectedFormEditorQuestion(question);
        setFormEditorQuestionWindowOpen(true);
    };

    return (
        <ViewBox style={{height: '100%', padding: '50px 0 0 0', overflow: 'hidden'}}>
            <Box mb={2} style={{height: 50}} paddingX={2}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item>
                        <Button
                            variant="outlined"
                            startIcon={<ArrowBackIcon />}
                            onClick={handle_formEditorSectionBackClick}>Voltar</Button>
                    </Grid>
                    <Grid item xs></Grid>
                    <Grid item>
                        <Button startIcon={<SettingsIcon />} onClick={() => setTestTemplateWindow(true)}>Testar</Button>
                    </Grid>
                    <Grid item>
                        <Button disabled={loading} variant="contained" color="primary" endIcon={<SaveAltIcon />} onClick={handle_formEditorSaveClick}>Salvar</Button>
                        {
                            errorMessage &&
                            <FormHelperText error>
                                {errorMessage}
                            </FormHelperText>
                        }
                    </Grid>
                </Grid>
            </Box>
            <Box style={{height: `calc(100% - 50px)`, overflowY: 'auto'}}>
                <Container maxWidth={orderingForm ? 'xs' : 'lg'}>
                    <DragDropContext onDragEnd={handleQuestionDragEnd}>
                        <Droppable droppableId={'form-editor-droppable-container'}>
                            {(provided, snapshot) => (
                                <Div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    sx={classes.questionsListBox}
                                    style={
                                        orderingForm
                                        ?
                                            (
                                                snapshot.isDraggingOver
                                                ? { backgroundColor: '#C0CCD1' }
                                                : {backgroundColor: 'white'}
                                            )
                                        : null
                                    }
                                >
                                    <Box mb={2}>
                                        <Typography variant="h6">{templateName}</Typography>
                                    </Box>
                                    {
                                        formEditorQuestions.map((question, questionIndex) => (
                                            <FormEditorQuestion
                                                key={question.id} question={question} questionIndex={questionIndex}
                                                handleFormEditorQuestionWindowOpenButtonPress={handleFormEditorQuestionWindowOpenButtonPress}
                                            />
                                        ))
                                    }
                                    {provided.placeholder}
                                </Div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Container>
            </Box>
            <NewTitleDialog setFormEditorQuestionWindowOpen={setFormEditorQuestionWindowOpen} />
            <UpdateTitleDialog />
            <UpdateDescriptionDialog setSelectedFormEditorQuestion={setSelectedFormEditorQuestion} />
            <AlertDialog
                id="form-editor-delete-title-dialog"
                open={deleteTitleDialogOpen} onClose={() => setDeleteTitleDialogOpen(false)}
                text={`Você quer excluir o título "${clickedQuestion && clickedQuestion.name}"?`}
                okButtonOnClick={handleDeleteTitleDialogOkClick}
            />
            <FormProvider>
                <TestTemplateWindow
                    open={testTemplateWindowOpen} setOpen={setTestTemplateWindow}
                    form={formEditorQuestions}
                    formName={templateName}
                    effects={templateEffects}
                    mainSheet={documentEditorValue}
                />
            </FormProvider>
            <Menu
                anchorEl={assignFieldMenuAnchorEl}
                open={assignFieldMenuOpen}
                onClose={() => setAssignFieldMenuOpen(false)}
            >
                <MenuItem onClick={() => handleAssignField(0)}>
                    <Typography variant="inherit">&bull; Nenhuma</Typography>
                </MenuItem>
                <Divider />
                {
                    ASSIGNABLE_FIELDS.map(i => (
                        <MenuItem key={i.id} onClick={() => handleAssignField(i.id)}>
                            <Typography variant="inherit">{i.name}</Typography>
                        </MenuItem>
                    ))
                }
            </Menu>
            <FormEditorQuestionWindow
                open={formEditorQuestionWindowOpen} setOpen={setFormEditorQuestionWindowOpen}
                question={selectedFormEditorQuestion} setQuestion={setSelectedFormEditorQuestion}
            />
        </ViewBox>
    );
}

export default FormEditor;