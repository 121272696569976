import React from 'react';
import { useNavigate } from 'react-router-dom';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import SidenavMobileMenuButton from './SidenavMobileMenuButton';
import { Box, Divider, Typography } from '@mui/material';
import { useAppCtxAPI } from '../context/SystemContext';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const windowWidth = window.innerWidth;

const SidenavMobile = ({children, data}) => {
    const navigate = useNavigate();
    const { logout, setLoading } = useAppCtxAPI();
    const { sidenavMobileOpen, set_sidenavMobileOpen } = data;

    const handleClose = () => {
        set_sidenavMobileOpen(false);
    };

    const handleOpen = () => {
        set_sidenavMobileOpen(true);
    };

    return (
        <SwipeableDrawer
            open={sidenavMobileOpen}
            onClose={handleClose}
            onOpen={handleOpen}
        >
            <Box
                sx={{
                    width: `${windowWidth}px`,
                    overflowX: 'hidden'
                }}
            >
                {children}
                <Divider />
                <SidenavMobileMenuButton
                    label={<Typography color="primary">Sair do SOLIDA</Typography>}
                    onClick={() => logout(navigate, setLoading)}
                    endIcon={<ExitToAppIcon />}
                />
            </Box>
        </SwipeableDrawer>
    );
};

export default SidenavMobile;