import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import Select from '../../../components/Select';

const NewEffect = ({ handleChange, setDisabled, specialEffects, templateEffects }) => {
    const [value, setValue] = useState({ typeId: '', typeSpecifics: '', input: [], output: '', elseOutput: '', form: false });
    const typeIdOptions = [
        {value: 'special', label: 'Importar dados'},
        {value: 'question', label: 'Pergunta'}
    ];
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        handleChange(value);
        if(setDisabled){
            let isDisabled = false;
            if(!value.typeId || !value.typeSpecifics){
                isDisabled = true;
            } else {
                if(value.input.length === 0){
                    isDisabled = true;
                }
            }
            setDisabled(isDisabled);
        }
    }, [value]);

    useEffect(() => {
        if(templateEffects){
            const questionIds = [];
            const questions = [];
            templateEffects.forEach(question => {
                if([
                    'text',
                    'number',
                    'currency',
                    'date',
                    'individualId',
                    'corporationId',
                    'list'
                ].includes(question.type)){
                    if(!questionIds.includes(question.question)){
                        questions.push({value: question.question, label: question.question});
                        questionIds.push(question.question);
                    }
                }
            });
            const currentQuestions = questions.sort((a, b) => a.label > b.label ? 1 : a.label < b.label ? -1 : 0);
            setQuestions(currentQuestions);
        }
    }, [templateEffects]);

    const handleConditionChange = (conditionIndex, newValue) => {
        setValue(prevState => ({...prevState, input: [...prevState.input.slice(0, conditionIndex), newValue, ...prevState.input.slice(conditionIndex + 1)]}));
    };

    const handleConditionDelete = (conditionIndex) => {
        setValue(prevState => ({...prevState, input: [...prevState.input.slice(0, conditionIndex), ...prevState.input.slice(conditionIndex + 1)]}));
    };
    
    const handleAddCondition = () => {
        setValue(prevState => ({...prevState, input: [...prevState.input, '']}));
    };

    if(specialEffects && templateEffects){
        return (
            <Box>
                <Box mb={2}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12} sm={6}>
                            <Box mb={1}>
                                <Typography variant="h6">Pergunta ou dados?</Typography>
                            </Box>
                            <Select
                                options={typeIdOptions}
                                value={value.typeId || 'select'}
                                onChange={(e) => setValue(prevState => ({...prevState, typeId: e.target.value}))}
                            />
                        </Grid>
                        {
                            value.typeId &&
                            <Grid item xs={12} sm={6}>
                                <Box mb={1}>
                                    <Typography variant="h6">{value.typeId === 'special' ? 'Dados' : 'Pergunta'}</Typography>
                                </Box>
                                <Select
                                    options={value.typeId === 'special' ? specialEffects : questions}
                                    value={value.typeSpecifics || 'select'}
                                    onChange={(e) => setValue(prevState => ({...prevState, typeSpecifics: e.target.value}))}
                                />
                            </Grid>
                        }
                    </Grid>
                </Box>
                <Box mb={1}>
                    <Typography variant="h6">Se o efeito resultar em qualquer uma das respostas a seguir...</Typography>
                </Box>
                {
                    value.input.map((option, optionIndex) => (
                        <Grid key={optionIndex} container spacing={1} alignItems="center">
                            <Grid item xs>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    placeholder="Resposta"
                                    value={option}
                                    onChange={(e) => handleConditionChange(optionIndex, e.target.value)} />
                            </Grid>
                            <Grid item>
                                <IconButton size="small" onClick={() => handleConditionDelete(optionIndex)}><ClearIcon /></IconButton>
                            </Grid>
                        </Grid>
                    ))
                }
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <Button startIcon={<AddIcon />} onClick={handleAddCondition}>Adicionar resposta</Button>
                    </Grid>
                </Grid>
            </Box>
        );
    }
    return null;
};

export default NewEffect;