import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const NewEffect = ({ handleChange, setDisabled }) => {
    const [value, setValue] = useState({ question: '', typeSpecifics: 'numberAndInWords' });
    const typeSpecificsOptions = [
        {value: 'numberAndInWords', label: 'Em numeral e por extenso'},
        {value: 'number', label: 'Em numeral'},
        {value: 'inWords', label: 'Por extenso'},
    ];

    useEffect(() => {
        handleChange(value);
        if(setDisabled){
            let isDisabled = false;
            if(!value.question || !value.typeSpecifics) isDisabled = true;
            setDisabled(isDisabled);
        }
    }, [value]);

    return (
        <Box>
            <Box mb={2}>
                <Box mb={1}>
                    <Typography variant="h6">Pergunta</Typography>
                </Box>
                <TextField
                    variant="standard"
                    fullWidth
                    placeholder="Sua pergunta"
                    value={value.question}
                    onChange={(e) => setValue(prevState => ({...prevState, question: e.target.value}))} />
            </Box>
            <RadioGroup
                value={value.typeSpecifics}
                onChange={(e) => setValue(prevState => ({...prevState, typeSpecifics: e.target.value}))}
            >
                <Grid container spacing={1} alignItems="center">
                    {
                        typeSpecificsOptions.map((option, optionIndex) => (
                            <Grid item key={optionIndex}>
                                <FormControlLabel
                                    value={option.value}
                                    control={<Radio />}
                                    label={option.label}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            </RadioGroup>
        </Box>
    );
};

export default NewEffect;