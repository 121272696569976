import React, { useEffect, useState } from 'react';
import { Box, Collapse, Container, Grid, TextField, Typography } from '@mui/material';
import LoaderEllipsis from '../../../../components/LoaderEllipsis';
import Dialog from '../../../../components/Dialog/Dialog';
import MultipleAutocomplete from '../../../../components/MultipleAutocomplete';
import Select from '../../../../components/Select';
import SettingsSwitches from './SettingsSwitches';
import { useAppCtxAPI, useAppCtxActiveUser, useAppCtxLoading } from '../../../../context/SystemContext';
import { useClientCtxActiveClient } from '../../../../context/ClientContext';
import { useOperatorCtxRefs, useOperatorCtxAPI, useOperatorCtxProjects, useOperatorCtxUsers } from '../../context/OperatorContext';
import { useOperatorUsersCtxAPI, useOperatorCtxChangeUserView, useOperatorCtxUserTypeOptions } from '../../../../context/OperatorUsersContext';
import { getProjectNameById, getUserProjects, serverRequest } from '../../../../utils/common';
import { EMAIL_REGEXP, FIRST_CLIENT_ID } from '../../../../utils/constants';

const DataDrawer = () => {
    const { toast } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const loading = useAppCtxLoading();
    const activeClient = useClientCtxActiveClient();
    const { addUser, updateUser } = useOperatorCtxAPI();
    const { checkIfShouldUpdateOperators, shouldEmitUpdatedUsers, updatedUser } = useOperatorCtxRefs(); //projects (id, client)
    const projects = useOperatorCtxProjects();
    const { users } = useOperatorCtxUsers();
    const { hideChangeUserView, setChangeUserViewFoundUser } = useOperatorUsersCtxAPI();
    const { changeUserViewOpen, changeUserViewClickedUser, changeUserViewFoundUser: selectedUser } = useOperatorCtxChangeUserView();
    const userTypeOptions = useOperatorCtxUserTypeOptions();
    const [email, set_email] = useState('');
    const [screenName, set_screenName] = useState('');
    const [fullName, set_fullName] = useState('');
    const [type, set_type] = useState(1);
    const [user_projects, set_user_projects] = useState([]);
    const [user_clients, set_user_clients] = useState([]);
    const [saving, setSaving] = useState(false);
    
    useEffect(() => {
        if(changeUserViewOpen){
            if(!selectedUser){
                clearForm();
            } else {
                set_email(selectedUser.email);
                set_screenName(selectedUser.screenName);
                set_fullName(selectedUser.fullName);
                let currentUserType = 1;
                let userClients = selectedUser.clients;
                if(activeClient){
                    let currentAppClient = userClients.find(i => i.id === activeClient._id);
                    currentUserType = currentAppClient.type || 1;
                }
                set_type(currentUserType);
                
                if(currentUserType <= 1){
                    
                    const { folders, companies } = getUserProjects(selectedUser, projects, activeClient, FIRST_CLIENT_ID);
                    
                    set_user_projects(folders);
    
                    set_user_clients(companies);
                } else {
                    set_user_projects([]);
                    set_user_clients([]);
                }
            }
        }
    }, [changeUserViewOpen, selectedUser]);

    useEffect(() => {
        if(users && !shouldEmitUpdatedUsers.current && updatedUser.current && changeUserViewClickedUser && updatedUser.current._id === changeUserViewClickedUser._id){
            setChangeUserViewFoundUser(updatedUser.current);
            updatedUser.current = null;
        }
    }, [users]);

    const handleSubmit = async () => {
        setSaving(true);
        let postData = {
            email,
            screenName,
            fullName,
            type,
            permissions: type === 1 ? [...user_clients.map(i => ({id: i._id})), ...user_projects.map(i => ({id: i._id}))] : [],
            currentDocument: selectedUser
        };
        try {
            const data = await serverRequest({path: '/data/operator/users/save', method: 'post', data: postData});
            updatedUser.current = data;
            checkIfShouldUpdateOperators();
            if(selectedUser){
                updateUser(data);
            } else {
                addUser(data);
            }
            setChangeUserViewFoundUser(data);
            clearForm();
            toast('As informações foram salvas.');
            handleClose();
        } catch (error) {
            toast(error);
        }
        setSaving(false);
    };
    const clearForm = () => {
        set_email('');
        set_screenName('');
        set_fullName('');
        set_type(1);
        set_user_projects([]);
        set_user_clients([]);
    };

    const handleClose = () => {
        hideChangeUserView();
    };
    
    return (
        <Dialog
            onClose={handleClose}
            onSaveClick={handleSubmit}
            open={changeUserViewOpen}
            saveButtonDisabled={!email || !EMAIL_REGEXP.test(email) || !screenName || !fullName || !type || loading}
            saving={saving}
            title={!!changeUserViewClickedUser && !!selectedUser ? selectedUser.fullName : !changeUserViewClickedUser && !selectedUser ? 'Cadastrar pessoa' : ''}
        >

            <Container maxWidth="sm">
                <Collapse in={!!changeUserViewClickedUser && !selectedUser}>
                    <LoaderEllipsis primary />
                </Collapse>
                <Collapse in={(!changeUserViewClickedUser && !selectedUser) || (!!changeUserViewClickedUser && !!selectedUser)}>
                    <Box>
                        <Box my={2}>
                            <Box mb={1}>
                                <Typography variant="h5">Dados gerais</Typography>
                            </Box>
                            <Box mb={1}>
                                <Grid container spacing={1}>
                                    {
                                        !selectedUser &&
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                variant="standard"
                                                label="E-mail"
                                                fullWidth
                                                required
                                                value={email}
                                                onChange={(e) => set_email(e.target.value)}
                                                error={(!!email && !EMAIL_REGEXP.test(email))} />
                                        </Grid>
                                    }
                                    <Grid item xs>
                                        <TextField
                                            variant="standard"
                                            label="Nome de exibição"
                                            fullWidth
                                            required
                                            value={screenName}
                                            onChange={(e) => set_screenName(e.target.value)} />
                                    </Grid>
                                    <Grid item xs={12} sm={selectedUser ? 6 : undefined}>
                                        <TextField
                                            variant="standard"
                                            label="Nome completo"
                                            fullWidth
                                            required
                                            value={fullName}
                                            onChange={(e) => set_fullName(e.target.value)} />
                                    </Grid>
                                    {
                                        activeUser?.type >= 6 &&
                                        <Grid item xs={12}>
                                            <Select
                                                label="Tipo" required
                                                value={type} onChange={(e) => set_type(e.target.value)}
                                                options={userTypeOptions}
                                            />
                                        </Grid>
                                    }
                                    {
                                        (projects && (type === 1)) &&
                                        <Grid item xs={12}>
                                            <MultipleAutocomplete
                                                label={`Permissão para acessar pastas`}
                                                options={
                                                    projects.filter(project => project.client)
                                                    .map((folder, folderIndex) => {
                                                        folder.autocompleteOption = `${folderIndex + 1}) ${getProjectNameById(projects, folder.client)} - ${folder.name}`;
                                                        return folder;
                                                    })
                                                }
                                                getOptionLabel={(option) => option.autocompleteOption}
                                                value={user_projects} onChange={(e, newValue) => set_user_projects(newValue)}
                                            />
                                        </Grid>
                                    }
                                </Grid>
                            </Box>
                        </Box>
                        {
                            selectedUser &&
                            <Box my={2}>
                                <Box mb={1}>
                                    <Typography variant="h5">Opções</Typography>
                                </Box>
                                <Box mb={1}>
                                    <SettingsSwitches />
                                </Box>
                            </Box>
                        }
                        {
                            activeUser?.type >= 9 &&
                            <Box my={2}>
                                <Box mb={1}>
                                    <Typography variant="h5">Configurações adicionais</Typography>
                                </Box>
                                <Box mb={1}>
                                    <Grid container spacing={1}>
                                        {
                                            selectedUser &&
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    variant="standard"
                                                    label="E-mail"
                                                    fullWidth
                                                    required
                                                    value={email}
                                                    onChange={(e) => set_email(e.target.value)} />
                                            </Grid>
                                        }
                                        {
                                            projects &&
                                            <Grid item xs={12}>
                                                <MultipleAutocomplete
                                                    label="Permissão para acessar empresas" options={projects.filter(p => !p.client)} getOptionLabel={(option) => option.name}
                                                    value={user_clients} onChange={(e, newValue) => set_user_clients(newValue)}
                                                />
                                            </Grid>
                                        }
                                    </Grid>  
                                </Box>
                            </Box>
                        }
                    </Box>
                </Collapse>
            </Container>

        </Dialog>
    );
}

export default DataDrawer;