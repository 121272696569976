import React, { useState } from 'react';
import axios from 'axios';
import { Box, CircularProgress, Button, Grid } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import { updateStateChangeArrayItemWith_id } from '../../../../utils/common';
import { ERROR_MESSAGE_UNKNOWN, SERVER_PATH, SUCCESS_MESSAGE_SAVED } from '../../../../utils/constants';
import { useAppCtxAPI } from '../../../../context/SystemContext';
import { useOperatorCtxAPI } from '../../context/OperatorContext';
import Tooltip from '../../../../components/Tooltip';

const ChangeLogoButton = ({selectedProject, setSelectedProject}) => {
    const { toast } = useAppCtxAPI();
    const { updateProject } = useOperatorCtxAPI();
    const [loading, setLoading] = useState(false);

    const fileButtonId = 'logoFile';

    const triggerFileButton = () => {
        document.getElementById(fileButtonId).click();
    };

    const handleSelectedFileChange = async () => {
        const filesInput = document.getElementById(fileButtonId);
        let files = filesInput.files;
        if(files.length > 0){
            setLoading(true);
            toast('Verificando arquivos... isso pode levar alguns segundos...');
            let formData = new FormData();
            formData.append('projectId', selectedProject._id);
            formData.append('file', files[0]);
            await axios.post(SERVER_PATH + '/data/projects/logo', formData, {headers: {'Content-Type': 'multipart/form-data'}})
            .then(res => {
                if(res.data){
                    setSelectedProject({...selectedProject, logo: res.data.logo});
                    updateProject({...selectedProject, logo: res.data.logo});
                    toast(SUCCESS_MESSAGE_SAVED);
                }
            })
            .catch(err => {
                toast(ERROR_MESSAGE_UNKNOWN);
            });
            setLoading(false);
        }
    };

    if(selectedProject){
        return (
            <Box>
                <Grid container spacing={1} justifyContent="flex-end">
                    {
                        loading
                        ?
                        <Grid item>    
                            <CircularProgress />
                        </Grid>
                        :
                        <Grid item>
                            {
                                selectedProject?.logo
                                ?
                                <Tooltip text="Alterar logo">
                                    <img
                                        src={`/uploads/logos/${selectedProject.logo}`} alt={selectedProject.name}
                                        style={{
                                            maxWidth: 100,
                                            cursor: 'pointer'
                                        }}
                                        onClick={triggerFileButton}
                                    />
                                </Tooltip>
                                :
                                <Button
                                    variant="outlined" color="primary"
                                    startIcon={<ImageIcon />}
                                    onClick={triggerFileButton}
                                >Logo</Button>
                            }
                            <input style={{display: 'none'}} id={fileButtonId} type="file" accept="image/*" onChange={handleSelectedFileChange} />
                        </Grid>
                    }
                </Grid>
            </Box>
        );
    }
    return null;
};

export default ChangeLogoButton;