import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import AttachmentIcon from '@mui/icons-material/Attachment';
import Tooltip from './Tooltip';
import { SERVER_PATH } from '../utils/constants';

const DocumentFilesList = ({doc}) => {
    const [docFiles, set_docFiles] = useState(null);

    useEffect(() => {
        if(doc){
            getDocFiles(doc);
        } else {
            set_docFiles(null);
        }
    }, [doc]);

    const getDocFiles = (doc) => {
        if(doc && Array.isArray(doc.files) && doc.files.length >= 1){
            set_docFiles(doc.files);
        } else {
            set_docFiles(null);
        }
    };

    return (
        docFiles
        ?
        <Box mt={1}>
            {
                docFiles.map((file, index) => (
                    <Tooltip key={index} text={`Anexo ${index + 1} - ${file}`}>
                        <IconButton size="small" color="primary"
                            href={`${SERVER_PATH}/${file}`} target="_blank"
                        ><AttachmentIcon /></IconButton>
                    </Tooltip>
                ))
            }
        </Box>
        :
        null
    );
};

export default DocumentFilesList;