import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Select from '../../../components/Select';

const NewEffect = ({ handleChange, listOptions, setDisabled }) => {
    const [value, setValue] = useState({ question: '', typeId: '', typeSpecifics: 'key' });
    const typeSpecificsOptions = [
        {value: 'key', label: 'Nome'},
        {value: 'value', label: 'Descrição'},
        {value: 'custom', label: 'Personalizar'},
    ];

    useEffect(() => {
        handleChange(value);
        if(setDisabled){
            let isDisabled = false;
            if(!value.question || !value.typeId || !value.typeSpecifics) isDisabled = true;
            setDisabled(isDisabled);
        }
    }, [value]);

    if(listOptions){
        return (
            <Box>
                <Box mb={2}>
                    <Box mb={1}>
                        <Typography variant="h6">Pergunta</Typography>
                    </Box>
                    <TextField
                        variant="standard"
                        fullWidth
                        placeholder="Sua pergunta"
                        value={value.question}
                        onChange={(e) => setValue(prevState => ({...prevState, question: e.target.value}))} />
                </Box>
                <Box mb={2}>
                    <Box mb={1}>
                        <Typography variant="h6">Lista</Typography>
                    </Box>
                    <Select
                        options={listOptions}
                        value={value.typeId || 'select'} onChange={(e) => setValue(prevState => ({...prevState, typeId: e.target.value}))}
                    />
                </Box>
                <RadioGroup
                    value={value.typeSpecifics}
                    onChange={(e) => setValue(prevState => ({...prevState, typeSpecifics: e.target.value}))}
                >
                    <Grid container spacing={1} alignItems="center">
                        {
                            typeSpecificsOptions.map((option, optionIndex) => (
                                <Grid item key={optionIndex}>
                                    <FormControlLabel
                                        value={option.value}
                                        control={<Radio />}
                                        label={option.label}
                                    />
                                </Grid>
                            ))
                        }
                    </Grid>
                </RadioGroup>
            </Box>
        );
    }
    return null;
};

export default NewEffect;