import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import Window from '../../../../../components/Window';
import Form from '../../../../Form';
import { useAppCtxAPI, useAppContext, useAppCtxReturnedFormFile } from '../../../../../context/SystemContext';
import { useClientCtxActiveClient } from '../../../../../context/ClientContext';
import { useOperatorCtxProjects } from '../../../context/OperatorContext';
import { useForm } from '../../../../Form/context/FormContext';
import moment from 'moment';
import { linkStyles } from '../../../../../styles/index';
 
const TestTemplateWindow = ({open, setOpen, form, formName, effects, mainSheet}) => {
    const { setReturnedFormFile, setSelectedFolder } = useAppCtxAPI();
    const { formEffects } = useAppContext();
    const returnedFormFile = useAppCtxReturnedFormFile();
    const activeClient = useClientCtxActiveClient();
    const projects = useOperatorCtxProjects();
    const { resetForm, set_initialForm, set_userForm, setFormActiveClient } = useForm();
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        setFormActiveClient(activeClient);
    }, []);

    useEffect(() => {
        if(open){
            setReturnedFormFile(null);
            if(form){
                resetForm();

                const currentInitialForm = {};
                form.forEach(question => {
                    const newQuestion = {
                        id: question.id,
                        type: question.type
                    };
                    if(question.type === 'text'){
                        newQuestion.input = `[${question.name}]`;
                    } else if(question.type === 'date'){
                        const now = new Date();
                        newQuestion.input = moment(now).format('L');
                        newQuestion.value = now;
                    } else if(question.type === 'currency'){
                        const typeSpecifics = question.typeSpecifics;

                        const max = 1000000;
                        const min = 1;
                        const newValue = Math.floor(Math.random() * (max - min + 1) ) + min;
                        
                        newQuestion.typeSpecifics = typeSpecifics;
                        newQuestion.value = newValue * 100;

                        newQuestion.input = formEffects.currency.getTypeSpecificInput(newValue, typeSpecifics);
                    } else if(question.type === 'radio'){
                        newQuestion.choice = question.options[0].choice;
                        if(question.options[0].choice === '~t;'){
                            newQuestion.input = '[Preencher]';
                        }
                    } else if(question.type === 'number'){
                        const max = 999;
                        const min = 9;
                        const newValue = Math.floor(Math.random() * (max - min + 1) ) + min;
                        newQuestion.value = newValue;
                        const typeSpecifics = question.typeSpecifics;
                        newQuestion.typeSpecifics = typeSpecifics;
                        newQuestion.input = formEffects.number.getTypeSpecificInput(newValue, typeSpecifics);
                    } else if(question.type === 'individualId'){
                        newQuestion.input = '123.456.789-10';
                    } else if(question.type === 'corporationId'){
                        newQuestion.input = '12.345.678/0001-99';
                    }
                    if(newQuestion.input || newQuestion.choice) currentInitialForm[question.name] = newQuestion;
                });
                set_userForm(currentInitialForm);
                set_initialForm(currentInitialForm);

                const foundFolder = projects.find(project => project._id === '61070bae270bd24b6c793c07');
                setSelectedFolder(foundFolder || {});

                setShowForm(true);
            }
        }
    }, [open]);

    const handleClickLink = (url) => {
        window.open(url, '_blank');
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Window
            id="test-template-window"
            open={open} onClose={handleClose}
            modalInnerBoxSx={{
                width: ['100%', '90%', '75%']
            }}
        >
            {
                returnedFormFile &&
                <Box style={{textAlign: 'center'}}>
                    <Typography variant="body1" sx={linkStyles} onClick={() => handleClickLink(returnedFormFile)}>{returnedFormFile}</Typography>
                </Box>
            }
            {
                showForm &&
                <Form
                    form={{ useEffects: true, name: formName, form}}
                    data={{
                        operatorStatus: true
                    }}
                    hideSaveFormButton
                    hideSuppliersButton
                    isTest={{
                        effects,
                        form,
                        logo: null,
                        mainSheet,
                        useEffects: true,
                        useEffectsVersion: 2,
                    }}
                />
            }
        </Window>
    );
};

export default TestTemplateWindow;