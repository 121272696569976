import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Box, List } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ActionMenu from './components/ActionMenu';
import AddDocumentWindow from '../../../components/AddDocumentWindow';
import AddAlertWindow from '../../../components/AddAlertWindow';
import LoaderEllipsis from '../../../components/LoaderEllipsis';
import ChangeStarredInfoPopOver from './components/ChangeStarredInfoPopOver';
import CustomFiltersPopOver from './components/CustomFiltersPopOver';
import DeleteDocDialog from '../../../components/DeleteDocDialog';
import DocFormView from '../../../components/DocFormView';
import DocumentAlertsPopOver from '../../../components/DocumentAlertsPopOver';
import DocumentListItem from '../../../components/DocumentListItem';
import DocumentsTop from './components/DocumentsTop';
import HistoryPopOver from '../../../components/HistoryPopOver';
import NewStarredInfoPopOver from '../../../components/NewStarredInfoPopOver';
import NoResults from '../../../components/NoResults';
import Pagination from '../../../components/Pagination';
import SignDocumentWindow from '../../../components/eSignature/SignDocumentWindow';
import SubmitDocDialog from '../../../components/SubmitDocDialog';
import UpdateNameDialog from '../../../components/UpdateNameDialog';
import ViewBox from '../../../components/ViewBox';
import { getGroupNameById, getGroupQuery } from '../../../utils/common';
import { ERROR_MESSAGE_UNKNOWN, SERVER_PATH } from '../../../utils/constants';
import { useAppCtxAPI, useAppCtxActiveUser, useAppCtxSelectedFolder, useAppCtxUseCompanyRootFolder, useAppCtxUserGroups } from '../../../context/SystemContext';
import { useSocket } from  '../../../context/SocketContext';
import { useDocumentsAPI } from '../../../context/DocumentsContext';
import { useFolderUserCtxCompanyFolders, useFolderUserCtxFolderGroups, useFolderUserCtxFolderTemplates, useFolderUserCtxProjectClient, useFolderUserCtxTemplates, useFolderUserCtxUsers } from '../context/ClientContext';
import moment from 'moment';
import FlagDocumentWindow from '../../../components/Documents/FlagDocumentWindow';
import { getFolderCustomDocumentFields, getFolderGroups, getUserNameById, resetCustomDocumentFieldsMapOptions } from  '../../../utils/common';
import CommentWindow from '../../../components/CommentWindow';
import { updateStateChangeArrayItemWith_id, updateStateDeleteArrayItemBy_id, getDocumentLastCommentIfHuman } from '../../../utils/common';
import { onDocumentDeletedEvent, onDocumentFlaggedEvent, onDocumentNameChangedEvent, onDocumentSentEvent, onDocumentSignedEvent } from '../../../utils/socket-events';

const DocumentsPagination = (props) => {
    const { currentPage, documentsCount, handle_pageClick, loadingPage } = props;
    return (
        <Pagination disabled={loadingPage} count={documentsCount} page={currentPage} perPage={25} onChange={(e, page) => handle_pageClick(page)} my={0.5} />
    );
};

function Docs(){
    const theme = useTheme();
    const filtersButtonRef = useRef();
    const { floatingAlert, setLoading, toast } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const selectedFolder = useAppCtxSelectedFolder();
    const useCompanyRootFolder = useAppCtxUseCompanyRootFolder();
    const { userPosition } = useAppCtxUserGroups();
    const {createSocketConnection} = useSocket();
    const socket = createSocketConnection();
    const { setActionMenuAnchorEl, setSelectedDocument, showCommentView, showFlagDocumentView } = useDocumentsAPI();
    const clientProjects = useFolderUserCtxCompanyFolders();
    const folderGroups = useFolderUserCtxFolderGroups();
    const folderTemplates = useFolderUserCtxFolderTemplates();
    const projectClient = useFolderUserCtxProjectClient();
    const templates = useFolderUserCtxTemplates();
    const users = useFolderUserCtxUsers();
    const [visibleDocuments, setVisibleDocuments] = useState([]);
    const [loadingPage, set_loadingPage] = useState(true);
    const [selectedProject, set_selectedProject] = useState('all');
    const [selectedGroup, set_selectedGroup] = useState('~all;');
    const [selectedForm, set_selectedForm] = useState('~all;');
    const [selectedStatus, set_selectedStatus] = useState('~all;');
    const [selectedSignatureStatus, set_selectedSignatureStatus] = useState('~all;');
    const [activeUserCheck, set_activeUserCheck] = useState('all');
    const [documentsCount, set_documentsCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [changeStarredInfoList, set_changeStarredInfoList] = useState([]);
    const [customFiltersValues, set_customFiltersValues] = useState([]);
    const [customFiltersCount, set_customFiltersCount] = useState(0);
    const [searchValue, set_searchValue] = useState('');
    const [documentAlerts, setDocumentAlerts] = useState([]);
    const [currentFolderClientFields, set_currentFolderClientFields] = useState([]);

    const [csvData, set_csvData] = useState('');

    const defaultFilterValue = {
        productionCompany: '',
        workPlayer: '',

    };
    const [filters, setFilters] = useState(defaultFilterValue);

    useEffect(() => {
        setLoading(false);
    }, []);

    const deleteDocumentWithMessage = (payload) => {
        setVisibleDocuments(prevState => {
            if(prevState){
                const foundDocumentIndex = prevState.findIndex(foundDocument => foundDocument._id === payload.data);
                if(foundDocumentIndex !== -1){
                    if(typeof payload.message.setMessage === 'function' && payload.message.ifFound) payload.message.setMessage(payload.message.ifFound);
                    return [...prevState.slice(0, foundDocumentIndex), ...prevState.slice(foundDocumentIndex + 1)];
                }
            }
            return prevState;
        });
    };
    
    const updateDocumentWithMessage = (payload) => {
        setVisibleDocuments(prevState => {
            if(prevState){
                const foundDocumentIndex = prevState.findIndex(foundDocument => foundDocument._id === payload.data._id);
                if(foundDocumentIndex !== -1){
                    if(typeof payload.message.setMessage === 'function' && payload.message.ifFound) payload.message.setMessage(payload.message.ifFound);
                    return [...prevState.slice(0, foundDocumentIndex), {...payload.data, updatedAt: new Date()}, ...prevState.slice(foundDocumentIndex + 1)];
                }
            }
            return prevState;
        });
    };

    useEffect(() => {
        const documentDeletedSocketEventName = 'DOCUMENT DELETED';
        const documentFlaggedEventName = 'DOCUMENT FLAGGED';
        const documentFormSentSocketEventName = 'DOCUMENT FORM SENT';
        const documentNameChangedSocketEventName = 'DOCUMENT NAME CHANGED';
        const documentSentSocketEventName = 'DOCUMENT SENT';
        const documentSignedSocketEventName = 'DOCUMENT SIGNED';

        const handleDocumentDeletedEvent = (data) => onDocumentDeletedEvent(data, deleteDocumentWithMessage, floatingAlert);
        const handleDocumentFlaggedEvent = (data) => onDocumentFlaggedEvent(data, updateDocumentWithMessage, toast);
        const handleDocumentNameChangedEvent = (data) => onDocumentNameChangedEvent(data, updateDocumentWithMessage, toast);
        const handleDocumentSentEvent = (data) => onDocumentSentEvent(data, updateDocumentWithMessage, floatingAlert);
        const handleDocumentSignedEvent = (data) => onDocumentSignedEvent(data, updateDocumentWithMessage, floatingAlert, true);

        const handleDocumentFormSentEvent = (data) => {
            // const { actionUserName, updatedDocument } = data;
            // setAlertMessage({message: `${actionUserName} completou um formulário para o documento "${updatedDocument.name}".`});
        }

        socket.on(documentDeletedSocketEventName, handleDocumentDeletedEvent);
        socket.on(documentFlaggedEventName, handleDocumentFlaggedEvent);
        socket.on(documentFormSentSocketEventName, handleDocumentFormSentEvent);
        socket.on(documentNameChangedSocketEventName, handleDocumentNameChangedEvent);
        socket.on(documentSentSocketEventName, handleDocumentSentEvent);
        socket.on(documentSignedSocketEventName, handleDocumentSignedEvent);
        return () => {
            socket.off(documentDeletedSocketEventName, handleDocumentDeletedEvent);
            socket.off(documentFlaggedEventName, handleDocumentFlaggedEvent);
            socket.off(documentFormSentSocketEventName, handleDocumentFormSentEvent);
            socket.off(documentNameChangedSocketEventName, handleDocumentNameChangedEvent);
            socket.off(documentSentSocketEventName, handleDocumentSentEvent);
            socket.off(documentSignedSocketEventName, handleDocumentSignedEvent);
        };
    }, []);

    useEffect(() => {
        if(selectedFolder){
            const folderCustomDocumentFields = getFolderCustomDocumentFields(selectedFolder);
            set_currentFolderClientFields(folderCustomDocumentFields);

            const options = currentFolderClientFields.filter(i => i.type === 'select');
            const customDocumentFieldsValues = resetCustomDocumentFieldsMapOptions(options);
            set_customFiltersValues(customDocumentFieldsValues);
        }
    }, [selectedFolder]);

    useEffect(() => {
        if(selectedFolder){
            handle_pageClick(1);
        }
    }, [filters, selectedFolder, selectedProject, selectedGroup, selectedForm, selectedStatus, selectedSignatureStatus, activeUserCheck, searchValue, customFiltersValues]);

    const handle_pageClick = (page) => {
        setCurrentPage(page);
        loadPage(page);
    };

    const getQuery = (method) => {
        const retrievedGroupQuery = getGroupQuery(selectedGroup, method);
        let postData = {}, getData = '';
        if(method === 'get'){
            getData = retrievedGroupQuery;
        } else {
            postData = retrievedGroupQuery;
        }
        if(selectedForm !== '~all;'){
            postData.template = selectedForm;
            if(method === 'get'){
                if(getData) getData += '&';
                getData += `templateId=${selectedForm}`;
            }
        }
        if(searchValue){
            postData.search = searchValue;
            if(method === 'get'){
                if(getData) getData += '&';
                getData += `text=${searchValue}`;
            }
        }
        if(selectedStatus !== '~all;'){
            postData.status = selectedStatus;
            if(method === 'get'){
                if(getData) getData += '&';
                getData += `status=${selectedStatus}`;
            }
        }
        if(selectedSignatureStatus !== '~all;'){
            postData.signature = selectedSignatureStatus;
            if(method === 'get'){
                if(getData) getData += '&';
                getData += `signature=${selectedSignatureStatus}`;
            }
        }
        if(activeUserCheck !== 'all'){
            postData.user = activeUser._id;
            if(method === 'get'){
                if(getData) getData += '&';
                getData += `user=${activeUser._id}`;
            }
        }
        if(customFiltersValues.length >= 1){
            postData.custom = customFiltersValues.map(i => i.value === '~all;' ? '' : `${i.col}-${i.value},`);
        }
        if(selectedProject !== 'all'){
            postData.project = selectedProject;
            if(method === 'get'){
                if(getData) getData += '&';
                getData += `project=${selectedProject}`;
            }
        }
        postData.filters = filters;
        if(method === 'get'){
            return getData;
        } else {
            return postData;
        }
    };

    const loadPage = (page) => {
        if(selectedFolder){
            set_loadingPage(true);
            setVisibleDocuments([]); 
            const postData = getQuery();
            postData.folderId = selectedFolder._id;
            postData.page = page;
            axios.post(SERVER_PATH + '/data/client/docs', postData)
            .then(res => { 
                set_documentsCount(res.data.documentsCount || res.data.docs.length);
                setVisibleDocuments(res.data.docs);
                setDocumentAlerts(res.data.docsAlerts);
                set_loadingPage(false);
            })
            .catch(err => {
                set_loadingPage(false);
                toast(ERROR_MESSAGE_UNKNOWN);
            });
        }
    };
    const handle_flagDocClick = (record) => {
        showFlagDocumentView(record);
    };
    
    const handleActionMenuClick = (event, clickedDocument) => {
        setActionMenuAnchorEl(event.target, clickedDocument);
    };
    
    const handleSetSelectedDocument = (selectedDocument) => {
        let documentFolder = selectedFolder;
        if(useCompanyRootFolder){
            const folderId = selectedDocument.project;
            documentFolder = clientProjects.find(folder => folder._id === folderId);
        }
        setSelectedDocument(selectedDocument);
    };

    const handleReadComment = (comment) => {
        showCommentView(comment);
    };

    const addDocument = (data) => {
        setVisibleDocuments(prevState => [...prevState, data]);
    };
    
    const deleteDocument = (data) => {
        updateStateDeleteArrayItemBy_id(data, setVisibleDocuments);
    };

    const updateDocument = (data) => {
        updateStateChangeArrayItemWith_id(data, setVisibleDocuments);
    };

    return (
        <ViewBox>
            <DocumentsTop
                filtersButtonRef={filtersButtonRef}
                getQuery={getQuery}
                loadingPage={loadingPage}
                searchValue={searchValue} setSearchValue={set_searchValue}
            />
            <DocumentsPagination currentPage={currentPage} documentsCount={documentsCount} handle_pageClick={handle_pageClick} loadingPage={loadingPage} />
            {
                loadingPage
                ? <LoaderEllipsis primary />
                :
                <Box>
                    {
                        visibleDocuments.length >= 1
                        ?
                        <List id="docs-list" dense>
                            {
                                visibleDocuments
                                .filter(visibleDocument => !visibleDocument.deleted)
                                .sort((a, b) => {
                                    if(a.flag === 1 && b.flag !== 1){
                                        return -1;
                                    } else if(a.flag !== 1 && b.flag === 1){
                                        return 1;
                                    }
                                    return a.timestamp > b.timestamp ? -1 : (a.timestamp < b.timestamp ? 1 : 0);
                                })
                                .map((item, index) => {
                                    let documentFolder = selectedFolder;
                                    let documentFolderName = '';
                                    let useGroups = selectedFolder?.useGroups;
                                    let documentFolderGroups = folderGroups;
                                    let folderCustomDocumentFields = currentFolderClientFields;
                                    let groupName = '';
                                    if(useCompanyRootFolder){
                                        const folderId = item.project;
                                        documentFolder = clientProjects.find(folder => folder._id === folderId);
                                        documentFolderName = documentFolder?.name;
                                        useGroups = documentFolder?.useGroups;
                                        documentFolderGroups = getFolderGroups(documentFolder, documentFolder?.team?.all);
                                        documentFolderGroups = documentFolderGroups?.folderGroups || [];
                                        folderCustomDocumentFields = getFolderCustomDocumentFields(documentFolder);
                                    } else {
                                        documentFolderName = selectedFolder?.name;
                                    }
                                    if(useGroups) groupName = getGroupNameById(item.groupId, documentFolderGroups);
                                    
                                    const { documentComment, documentNoHtmlComment } = getDocumentLastCommentIfHuman(item);
                                    
                                    return (
                                        <DocumentListItem
                                            key={item._id}
                                            activeUserIsManager={documentFolder && (!documentFolder.useGroups || userPosition === 1 || userPosition === 2)}
                                            boxStyle={item.flag === 1 ? {border: `2px solid ${theme.palette.secondary.main}`} : null}
                                            comment={documentComment}
                                            //companyName
                                            docsAlerts={documentAlerts}
                                            documentFolder={documentFolder}
                                            eSignature={documentFolder?.eSignature}
                                            folderCustomDocumentFields={folderCustomDocumentFields}
                                            folderName={documentFolderName}
                                            groupName={groupName}
                                            handleActionMenuOpen={handleActionMenuClick}
                                            handleFlagDocumentButtonClick={handle_flagDocClick}
                                            listDocument={item}
                                            noHtmlComment={documentNoHtmlComment}
                                            projects={useCompanyRootFolder ? [selectedFolder, ...clientProjects] : (projectClient ? [selectedFolder, projectClient] : [selectedFolder])}
                                            setChangeStarredInfoList={set_changeStarredInfoList}
                                            setDocuments={setVisibleDocuments}
                                            setSelectedDocument={handleSetSelectedDocument}
                                            showCompany={useCompanyRootFolder}
                                            templates={templates}
                                            updateDocument={updateDocument}
                                            useGroups={useGroups}
                                            users={users}
                                        />
                                    )
                                })
                            }
                        </List>
                        : <NoResults text="Nenhum documento foi encontrado." />
                    }
                    <DocumentsPagination currentPage={currentPage} documentsCount={documentsCount} handle_pageClick={handle_pageClick} loadingPage={loadingPage} />
                </Box>
            }

            <HistoryPopOver
                updateDocument={updateDocument}
                users={users}
                handleReadComment={handleReadComment}
            />
            <DocFormView />
            <SubmitDocDialog updateDocument={updateDocument} />
            <FlagDocumentWindow updateDocument={updateDocument}/>
            <ActionMenu
                deleteDocument={deleteDocument}
                updateDocument={updateDocument}
            />
            <AddAlertWindow data={{setDocsAlerts: setDocumentAlerts}} />
            <DeleteDocDialog updateDocument={updateDocument} />
            <UpdateNameDialog updateDocument={updateDocument} />
            <ChangeStarredInfoPopOver
                list={changeStarredInfoList}
                data={{set_changeStarredInfoList, setDocs: setVisibleDocuments}}
            />
            <NewStarredInfoPopOver
                list={changeStarredInfoList}
                data={{setDocs: setVisibleDocuments}}
            />
            <DocumentAlertsPopOver availableDocumentAlerts={documentAlerts} />
            <AddDocumentWindow
                actionCallback={() => handle_pageClick(1)}
                addDocument={addDocument}
            />
            <CommentWindow users={users} />
            <CustomFiltersPopOver
                selectCols={currentFolderClientFields.filter(i => i.type === 'select')} 
                values={customFiltersValues} setValues={set_customFiltersValues}
                data={{
                    activeUserCheck, set_activeUserCheck,
                    selectedSignatureStatus, set_selectedSignatureStatus,
                    selectedStatus, set_selectedStatus,
                    templates, folderTemplates,
                    selectedForm, set_selectedForm,
                    selectedFolder,
                    selectedGroup, set_selectedGroup,
                    selectedProject, set_selectedProject, clientProjects,
                    customFiltersCount, set_customFiltersCount
                }}
                filters={filters}
                setFilters={setFilters}
            />
            
            <SignDocumentWindow />
        </ViewBox>
    );
}

export default Docs;