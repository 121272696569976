import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const CenteredCircularProgress = () => {
    return (
        <Box style={{textAlign: 'center'}}>
            <CircularProgress />
        </Box>
    );
};

export default CenteredCircularProgress;