import { useEffect, useRef, useState } from 'react';
import FormQuestionMovablePropertyLeaseDetails from '../../../../components/FormQuestionMovablePropertyLeaseDetails';
import { useAppContext } from '../../../../context/SystemContext';
import { useForm } from '../../context/FormContext';

const MovablePropertyLeaseDetailsQuestion = ({question}) => {
    const { formEffects } = useAppContext();
    const { initialForm, set_userForm, setFormValidation } = useForm();
    const [value, setValue] = useState(null);
    const touchedField = useRef(false);
    const [userFormQuestion, setUserFormQuestion] = useState(null);

    useEffect(() => {
        setUserFormQuestion({
            id: question.id,
            type: 'movablePropertyLeaseDetails'
        });
    }, []);

    useEffect(() => {
        if(userFormQuestion){
            set_userForm(prevState => ({
                ...prevState,
                [question.name]: userFormQuestion
            }));
        }
    }, [userFormQuestion]);
    
    useEffect(() => {
        setValue(initialForm[question.name]);
    }, [initialForm]);

    const checkValidation = () => {
        const questionValid = formEffects.schedule.checkValidation(userFormQuestion);
        setFormValidation(prevState => ({...prevState, [question.name]: !questionValid}));
    };
    
    useEffect(() => {
        if((userFormQuestion && userFormQuestion.choice) || touchedField.current){
            touchedField.current = true;
            checkValidation();
        }
    }, [userFormQuestion]);

    const handleChange = (questionName, updates) => {
        setUserFormQuestion(prevState => ({
            ...prevState,
            ...updates
        }));
    };
    
    return (
        <FormQuestionMovablePropertyLeaseDetails
            onChange={handleChange}
            question={question}
            initialValue={value}
        />
    );
}

export default MovablePropertyLeaseDetailsQuestion;