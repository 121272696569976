import { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Button, CircularProgress, Collapse, Container, Grid, Link, TextField, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CreateIcon from '@mui/icons-material/Create';
import DescriptionIcon from '@mui/icons-material/Description';
import MovieIcon from '@mui/icons-material/Movie';
import PeopleIcon from '@mui/icons-material/People';
import Autocomplete from '../../../../../components/Autocomplete';
import ChangeLogoButton from '../../components/ChangeLogoButton';
import Dialog from '../../../../../components/Dialog/Dialog';
import LoaderEllipsis from '../../../../../components/LoaderEllipsis';
import ProjectWindowImportSettingsSection from '../../components/ProjectWindowImportSettingsSection';
import ResponsiveButton from '../../../../../components/ResponsiveButton/ResponsiveButton';
import Select from '../../../../../components/Select';
import SettingsSwitches from '../../components/SettingsSwitches';
import Tooltip from '../../../../../components/Tooltip';
import { ERROR_MESSAGE_UNKNOWN, SERVER_PATH } from '../../../../../utils/constants';
import { useAppCtxAPI, useAppCtxActiveUser } from '../../../../../context/SystemContext';
import { useClient, useClientCtxActiveClient } from '../../../../../context/ClientContext';
import { useOperatorCtxRefs, useOperatorCtxAPI, useOperatorCtxOperators, useOperatorCtxProjects, useOperatorCtxCompanies } from '../../../context/OperatorContext';
import { useOperatorFoldersCtxAPI, useOperatorFoldersCtxChangeFolderView } from '../../../../../context/OperatorFoldersContext';
import moment from 'moment';
import useWindowSize from '../../../../../hooks/useWindowSize';

const DataDrawer = ({data}) => {
    const { setFloatingNotification } = data;
    const { showChangeFolderGroupsView, toast } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const { modules } = useClient();
    const activeClient = useClientCtxActiveClient();
    const { addProject, updateProject } = useOperatorCtxAPI();
    const { shouldEmitUpdatedProjects, updatedProject } = useOperatorCtxRefs();
    const operators = useOperatorCtxOperators();
    const projects = useOperatorCtxProjects();
    const companies = useOperatorCtxCompanies();
    const { addVisibleFolder, hideChangeFolderView, setChangeFolderViewFoundFolder, showFolderClearanceView, showFolderSignatureView, showFolderTemplatesView, updateVisibleFolder } = useOperatorFoldersCtxAPI();
    const { changeFolderViewOpen, changeFolderViewClickedFolder, changeFolderViewFoundFolder: selectedProject } = useOperatorFoldersCtxChangeFolderView();
    const [saving, setSaving] = useState(false);
    const defaultFolderState = {
        client: null,
        description: '',
        documentsDefaultPermission: 'writer',
        eSignature: false,
        emailsDocumentReadyOnlyUserRequests: false,
        folder: '',
        folderUsersCanAddDocuments: false,
        info: {},
        minimumUserLevel: 'all',
        modules: [],
        name: '',
        projectOperator: activeUser._id,
        shortName: '',
        showDocumentsStarredInformation: false,
        showFolderUserDocumentsCustomFields: false,
        useGroups: false
    };
    const [folderState, setFolderState] = useState(defaultFolderState);
    const [transferringSignedDocumentsToGoogleDrive, setTransferringSignedDocumentsToGoogleDrive] = useState(false);
    const { windowWidth } = useWindowSize();
    
    useEffect(() => {
        if(!selectedProject){
            clearForm();
        } else {
            setFolderState(prevState => ({
                ...prevState,
                client: companies.find(company => company._id === selectedProject.client),
                emailsDocumentReadyOnlyUserRequests: !!selectedProject.emailsDocumentReadyOnlyUserRequests,
                eSignature: !!selectedProject.eSignature,
                description: selectedProject.description || '',
                documentsDefaultPermission: selectedProject.documentsDefaultPermission || 'writer',
                folder: selectedProject.folder,
                folderUsersCanAddDocuments: !!selectedProject.folderUsersCanAddDocuments,
                info: selectedProject.info || {},
                minimumUserLevel: selectedProject.minimumUserLevel || 'all',
                modules: selectedProject.modules || [],
                name: selectedProject.name,
                projectOperator: selectedProject.projectOperator || '',
                shortName: selectedProject.shortName || '',
                showDocumentsStarredInformation: !!selectedProject.showDocumentsStarredInformation,
                showFolderUserDocumentsCustomFields: !!selectedProject.showFolderUserDocumentsCustomFields,
                useGroups: !!selectedProject.useGroups
            }));
        }
    }, [selectedProject]);

    useEffect(() => {
        let approvalRequired = false;
        for(let riskLevelKey in folderState.clearanceNumberOfApprovalsRequired){
            if(folderState.clearanceNumberOfApprovalsRequired[riskLevelKey] >= 1) approvalRequired = true;
        }
        setFolderState(prevState => ({...prevState, clearanceRequireApproval: approvalRequired}));
    }, [folderState.clearanceNumberOfApprovalsRequired]);

    useEffect(() => {
        if(projects && !shouldEmitUpdatedProjects.current && updatedProject.current && selectedProject && updatedProject.current._id === selectedProject._id){
            setChangeFolderViewFoundFolder(updatedProject.current);
        }
    }, [projects]);

    const handleSubmit = async () => {
        setSaving(true);

        let formData = new FormData();
        if(selectedProject) formData.append('updatedProjectId', selectedProject._id);
        formData.append('clientId', activeClient._id);
        
        formData.append('areas', JSON.stringify([]));
        
        formData.append('client', folderState.client._id);
        formData.append('description', folderState.description);
        formData.append('documentsDefaultPermission', folderState.documentsDefaultPermission);
        formData.append('eSignature', folderState.eSignature ? 'true' : 'false');
        formData.append('emailsDocumentReadyOnlyUserRequests', folderState.emailsDocumentReadyOnlyUserRequests ? 'true' : 'false');
        formData.append('folder', folderState.folder); // pasta no Google Drive
        formData.append('folderUsersCanAddDocuments', folderState.folderUsersCanAddDocuments ? 'true' : 'false');
        
        formData.append('info', JSON.stringify({...(selectedProject ? selectedProject.info || {} : {}), ...folderState.info}));
        
        if(folderState.minimumUserLevel !== 'all') formData.append('minimumUserLevel', folderState.minimumUserLevel);
        formData.append('modules', JSON.stringify(folderState.modules));
        formData.append('name', folderState.name);
        formData.append('projectOperator', folderState.projectOperator);
        formData.append('shortName', folderState.shortName);
        formData.append('showDocumentsStarredInformation', folderState.showDocumentsStarredInformation ? 'true' : 'false');
        formData.append('showDocumentsStarredInformation', folderState.showDocumentsStarredInformation ? 'true' : 'false');
        formData.append('useGroups', folderState.useGroups ? 'true' : 'false');
        
        await axios.post(SERVER_PATH + '/data/operator/folders/projects/save', formData, {headers: {'Content-Type': 'multipart/form-data'}})
        .then(res => {
            updatedProject.current = res.data;
            if(selectedProject){
                updateVisibleFolder(res.data);
                updateProject(res.data);
            } else {
                addVisibleFolder(res.data);
                addProject(res.data);
            }
            setChangeFolderViewFoundFolder(res.data);
            toast('As informações foram salvas.');
            handleClose();
        })
        .catch(err => {
            setFloatingNotification({message: err?.response?.data || ERROR_MESSAGE_UNKNOWN, severity: 'error'});
        });
        setSaving(false);
    };
    const clearForm = () => {
        setFolderState(defaultFolderState);
    };

    const handleTransferSignedDocumentsToGoogleDriveButtonPress = async () => {
        setTransferringSignedDocumentsToGoogleDrive(true);
        await axios.get(SERVER_PATH + `/data/client/documents/download?folderId=${selectedProject._id}`)
        .then(res => { 
            const updatedFolder = res.data;
            setChangeFolderViewFoundFolder(updatedFolder);
        })
        .catch(err => {
            setFloatingNotification({message: err?.response?.data || ERROR_MESSAGE_UNKNOWN, severity: 'error'});
        });
        setTransferringSignedDocumentsToGoogleDrive(false);
    };

    const handleChangeClearanceButtonPress = () => {
        showFolderClearanceView(selectedProject);
    };

    const handleChangeSignatureButtonPress = () => {
        showFolderSignatureView(selectedProject);
    };

    const handleChangeTemplatesButtonPress = () => {
        showFolderTemplatesView(selectedProject);
    };

    const handleChangeGroupsButtonPress = () => {
        showChangeFolderGroupsView(selectedProject);
    };

    const handleClose = () => {
        hideChangeFolderView();
    };

    return (
        <Dialog
            buttons={[
                selectedProject &&
                <ResponsiveButton
                    variant="outlined"
                    color="primary"
                    size="small"
                    startIcon={<DescriptionIcon />}
                    onClick={handleChangeTemplatesButtonPress}
                    label="Matrizes"
                />,
                selectedProject?.eSignature &&
                <ResponsiveButton
                    variant="outlined"
                    color="primary"
                    size="small"
                    startIcon={<CreateIcon />}
                    onClick={handleChangeSignatureButtonPress}
                    label="Assinatura"
                />,
                selectedProject?.useGroups &&
                <ResponsiveButton
                    variant="outlined"
                    color="primary"
                    size="small"
                    startIcon={<PeopleIcon />}
                    onClick={handleChangeGroupsButtonPress}
                    label="Grupos"
                />,
                selectedProject?.modules?.includes('clearance') &&
                <ResponsiveButton
                    variant="outlined"
                    color="primary"
                    size="small"
                    startIcon={<MovieIcon />}
                    onClick={handleChangeClearanceButtonPress}
                    label="Clearance"
                />
            ]}
            maxWidth="lg"
            onClose={handleClose}
            onSaveClick={handleSubmit}
            open={changeFolderViewOpen}
            saveButtonDisabled={!folderState.client || !folderState.name}
            saving={saving}
            title={!!changeFolderViewClickedFolder && !!selectedProject ? selectedProject.name : !changeFolderViewClickedFolder && !selectedProject ? 'Nova pasta' : ''}
        >

            <Container maxWidth="md">
                <Collapse in={!!changeFolderViewClickedFolder && !selectedProject}>
                    <LoaderEllipsis primary />
                </Collapse>
                <Collapse in={(!changeFolderViewClickedFolder && !selectedProject) || (!!changeFolderViewClickedFolder && !!selectedProject)}>
                    <Box>

                        {
                            selectedProject &&
                            <Box mb={3}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs></Grid>
                                    <Grid item>
                                        <ChangeLogoButton selectedProject={selectedProject} setSelectedProject={setChangeFolderViewFoundFolder} />
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                        <Box my={3}>
                            <Box mb={2}>
                                <Typography variant="h5">Dados gerais</Typography>
                            </Box>
                            <Box mb={1}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            variant="standard"
                                            label="Nome da pasta"
                                            fullWidth
                                            required
                                            value={folderState.name}
                                            onChange={(e) => setFolderState(prevState => ({...prevState, name: e.target.value}))} />
                                    </Grid>
                                    {
                                        companies &&
                                        <Grid item xs={12} sm={6}>
                                            <Autocomplete
                                                label="Empresa" required options={companies} getOptionLabel={(option) => option.name}
                                                value={folderState.client} onChange={(e, newValue) => setFolderState(prevState => ({...prevState, client: newValue}))}
                                            />
                                        </Grid>
                                    }
                                </Grid>
                            </Box>
                        </Box>
                        {
                            modules.includes('documents') &&
                            <Box my={3}>
                                <Box mb={2}>
                                    <Typography variant="h5">Documentos</Typography>
                                </Box>
                                <Box mb={1}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={6}>
                                            <Select
                                                label="Revisor responsável"
                                                options={operators.map(operator => ({value: operator._id, label: operator.screenName}))}
                                                value={folderState.projectOperator}
                                                onChange={(e) => setFolderState(prevState => ({...prevState, projectOperator: e.target.value}))}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Select
                                                label="Permissão para Google Docs"
                                                options={[{value: 'writer', label: 'Qualquer um pode editar'}, {value: 'commenter', label: 'Qualquer um pode comentar'}]}
                                                value={folderState.documentsDefaultPermission}
                                                onChange={(e) => setFolderState(prevState => ({...prevState, documentsDefaultPermission: e.target.value}))}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        }
                        {
                            selectedProject &&
                            <Box my={3}>
                                <Box mb={2}>
                                    <Typography variant="h5">Opções</Typography>
                                </Box>
                                <Box mb={1}>
                                    <SettingsSwitches
                                        selectedProject={selectedProject} setSelectedProject={setChangeFolderViewFoundFolder}
                                        {...{folderState, setFolderState}}
                                    />
                                </Box>
                            </Box>
                        }
                        {
                            /*
                            <TextField
                                label="Pasta no Google Drive" fullWidth
                                value={folder} onChange={(e) => set_folder(e.target.value)}
                            />
                            */
                        }
                        {
                            activeUser.type >= 9 &&
                            <Box my={3}>
                                <Box mb={2}>
                                    <Typography variant="h5">Configurações adicionais</Typography>
                                </Box>
                                <Box mb={1}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={6} container spacing={1} alignItems="flex-end" wrap="nowrap">
                                            <Grid item xs>
                                                <Select
                                                    label="Visibilidade"
                                                    options={[
                                                        {value: 'all', label: 'Todos os advogados'},
                                                        {value: 5, label: 'Apenas para os sócios quotistas'}
                                                    ]}
                                                    value={folderState.minimumUserLevel}
                                                    onChange={(e) => setFolderState(prevState => ({...prevState, minimumUserLevel: e.target.value}))}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={6} container spacing={1} alignItems="flex-end" wrap="nowrap">
                                            <Grid item xs>
                                                <TextField
                                                    variant="standard"
                                                    label="URL amigável"
                                                    fullWidth
                                                    value={folderState.shortName}
                                                    onChange={(e) => setFolderState(prevState => ({...prevState, shortName: e.target.value}))} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        }
                        {
                            (selectedProject && activeUser?.type >= 9) &&
                            <Box>
                                <Box my={3}>
                                    <Box mb={2}>
                                        <Typography variant="h5">Transferir documentos assinados para o Google Drive</Typography>
                                    </Box>
                                    {
                                        selectedProject?.googleDriveSignedDocumentsFolderId &&
                                        <Box mb={1}>
                                            <Typography variant="body1">Pasta criada em {moment(selectedProject.googleDriveSignedDocumentsFolderCreatedAt).format('L LTS')} - <Link
                                                variant="body2"
                                                href={`https://drive.google.com/drive/folders/${selectedProject.googleDriveSignedDocumentsFolderId}`}
                                                target="_blank"
                                                underline="hover">https://drive.google.com/drive/folders/{selectedProject.googleDriveSignedDocumentsFolderId}</Link></Typography>
                                        </Box>
                                    }
                                    <Box mb={1}>
                                        <Button
                                            variant="outlined" size="small"
                                            startIcon={transferringSignedDocumentsToGoogleDrive && <CircularProgress size={20} />}
                                            onClick={handleTransferSignedDocumentsToGoogleDriveButtonPress}
                                        >Transferir documentos assinados para Google Drive</Button>
                                    </Box>
                                </Box>
                            </Box>
                        }
                        {/* <Grid container spacing={1} alignItems="center">
                            <Grid item xs>
                                <ProjectWindowImportSettingsSection setProjectState={setFolderState} setUserCreatedFieldsValues={set_userCreatedFieldsValues} />
                            </Grid>
                        </Grid> */}
                    </Box>
                </Collapse>
            </Container>

        </Dialog>
    );
}

export default DataDrawer;