import { useEffect, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import moment from 'moment';

const DateQuestion = ({initialValue, onChange, question}) => {
    const defaultValue = { value: null };
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        setValue(initialValue || defaultValue);
    }, [initialValue]);

    useEffect(() => {
        if(onChange) onChange(question.name, value);
    }, [value]);

    const handleChange = (newValue) => {
        setValue(prevState => ({...prevState, value: newValue.toDate()}));
    };
    
    return (
        <DatePicker
            format="DD/MM/YYYY" autoOk
            value={moment(value.value)} onChange={(date) => handleChange(date)}
            invalidDateMessage="Formato de data inválido"
            renderInput={(props) => <TextField {...props} /> }
        />
    );
}

export default DateQuestion;