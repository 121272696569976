import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Unstable_Grid2';
import Tooltip from '@mui/material/Tooltip';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SectionTitle from '../SectionTitle';
import File from './File';
import { SERVER_PATH } from '../../utils/constants';

const classes = {
    fileBox: {
        backgroundColor: '#e3f2fd',
        border: '1px solid #E8E8E8',
        boxShadow: '0 0 8px rgba(0, 0, 0, 0.08)',
        padding: '8px',
        position: 'relative',
        '&:hover': {
            boxShadow: '0 2px 16px rgba(0, 0, 0, 0.24)'
        }
    }
};

const Files = (props) => {
    const { availableFiles, setAvailableFiles, files, setFiles, selectedFiles, setSelectedFiles } = props;

    const addFile = (file) => {
        setAvailableFiles(prevState => {
            const fileIndex = prevState.findIndex(availableFile => availableFile.path === file.path);
            return [...prevState.slice(0, fileIndex), ...prevState.slice(fileIndex + 1)];
        });
        setSelectedFiles(prevState => [...prevState, file]);
    };
    
    const removeFile = (file) => {
        setSelectedFiles(prevState => {
            const fileIndex = prevState.findIndex(selectedFile => selectedFile.path === file.path);
            return [...prevState.slice(0, fileIndex), ...prevState.slice(fileIndex + 1)];
        });
        setAvailableFiles(prevState => [...prevState, file]);
    };
    
    const onDragEnd = (result) => {
        const { destination, source } = result;

        if(!destination) return;

        if(destination.index === source.index) return;
        
        let currentFiles = [...files];
        const [removed] = currentFiles.splice(source.index, 1);
        currentFiles.splice(destination.index, 0, removed);
        setFiles(currentFiles);
    };

    if(files && files.length !== 0){
        return (
            <Box>
                <Grid container spacing={1} alignItems="flex-start">
                    <Grid xs={12} sm={6}>
                        <SectionTitle title="Arquivos disponíveis" />
                        <Box mb={2}>
                            {
                                availableFiles.map((file) => (
                                    <Box key={file.path} sx={classes.fileBox}>
                                        <Grid container spacing={1} alignItems="center" wrap="nowrap">
                                            <Grid xs>
                                                <Link variant="body1" noWrap href={`${SERVER_PATH}/${file.path}`} target="_blank">{file.fileName}</Link>
                                            </Grid>
                                            <Grid>
                                                <Tooltip title="Anexar ao documento para assinatura">
                                                    <IconButton size="small" onClick={() => addFile(file)}><ArrowForwardIcon /></IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ))
                            }
                        </Box>
                    </Grid>
                    <Grid xs>
                        <SectionTitle title="Arquivos anexados" />
                        <Box mb={2}>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="files-list">
                                    {(provided) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            {
                                                selectedFiles.map((file, index) => (
                                                    <File
                                                        key={file.path}
                                                        file={file}
                                                        index={index}
                                                        removeFile={removeFile}
                                                    />
                                                ))
                                            }
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        );
    }
    return null;
};

export default Files;