import { Box, Divider } from '@mui/material';
import UserComment from './UserComment';

const CommentsList = ({comments, users}) => {
    return (
        <Box>
            {
                comments.map((comment, commentIndex) => {
                    let userImage = '', userName = '';
                    const commentUser = users ? users.find(u => u._id === comment.createdBy) : comment.createdByUser;
                    if(commentUser){
                        userImage = commentUser.img;
                        userName = commentUser.fullName;
                    }

                    return (
                        <Box key={commentIndex} mb={1}>
                            <UserComment
                                comment={comment}
                                // commentFiles={commentFiles}
                                // hasFiles={hasFiles}
                                userImage={userImage}
                                userName={userName}
                            />
                            <Divider />
                        </Box>
                        
                    )
                })
            }
        </Box>
    );
};

export default CommentsList;