import { useEffect, useState } from 'react';
import FormDialog from './FormDialog';
import { useAppCtxAPI, useAppCtxActiveUser } from '../context/SystemContext';
import { useSocket } from '../context/SocketContext';
import { useDocumentsAPI, useDocumentsCtxChangeDocumentNameView } from '../context/DocumentsContext';

const UpdateNameDialog = ({updateDocument}) => {
    const { toast, updateOneOperators } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const {createSocketConnection} = useSocket();
    const socket = createSocketConnection();
    const { hideChangeDocumentNameView } = useDocumentsAPI();
    const { changeDocumentNameViewOpen, changeDocumentNameViewSelectedDocument } = useDocumentsCtxChangeDocumentNameView();
    const [textFieldValue, set_textFieldValue] = useState('');

    useEffect(() => {
        if(changeDocumentNameViewSelectedDocument){
            set_textFieldValue(changeDocumentNameViewSelectedDocument.name);
        }
    }, [changeDocumentNameViewSelectedDocument]);

    const handle_okClick = async () => {
        handleClose();
        const previousName = changeDocumentNameViewSelectedDocument.name;
        let recordCopy = {...changeDocumentNameViewSelectedDocument};
        let newRecord = {...changeDocumentNameViewSelectedDocument, name: textFieldValue};
        updateDocument(newRecord);
        try {
            await updateOneOperators('Documents', newRecord._id, {name: textFieldValue}, changeDocumentNameViewSelectedDocument.project);
            if(socket) socket.emit('DOCUMENT NAME CHANGED', {
                activeUserId: activeUser._id, activeUserName: activeUser.fullName,
                previousName, updatedDocument: newRecord
            });
        } catch (error) {
            updateDocument(recordCopy);
            toast('Erro! As alterações foram desfeitas.');
        }
    };

    const handleClose = () => {
        hideChangeDocumentNameView();
    };
    
    return (
        <FormDialog
            still
            id="update-doc-name-dialog"
            open={changeDocumentNameViewOpen} onClose={handleClose}
            title="Alterar nome" text="Você quer alterar o nome desse contrato? Essa alteração afetará advogados e clientes." textFieldLabel="Nome"
            textFieldValue={textFieldValue} textFieldOnChange={(e) => set_textFieldValue(e.target.value)}
            okButtonOnClick={handle_okClick}
            okButtonDisabled={textFieldValue === ''}
        />
    );
}

export default UpdateNameDialog;