import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Unstable_Grid2';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';

const classes = {
    row: {
        borderRadius: '8px',
        backgroundColor: '#f8f9fa'
    }
};

const RadioGridQuestion = (props) => {
    const { initialValue, question } = props;
    const defaultChoiceValue = { choice: '', rowLabel: '', columnLabel: '' }
    const defaultValue = { choices: [] };
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        setValue(initialValue || defaultValue);
    }, [initialValue]);

    useEffect(() => {
        if(props.onChange && value) props.onChange(question.name, value);
    }, [value]);

    const handleRowChange = (rowIndex, newValue) => {
        setValue(prevState => ({
            ...prevState,
            choices: question.rows.map((questionRow, questionRowIndex) => {
                let choice;
                if(prevState.choices[questionRowIndex]){
                    choice = {...prevState.choices[questionRowIndex]};
                } else {
                    choice = {...defaultChoiceValue};
                }
                if(rowIndex === questionRowIndex){
                    choice.choice = newValue;
                    choice.rowLabel = questionRow.label;
                    const foundColumn = question.columns.find(column => column.id === newValue);
                    if(foundColumn) choice.columnLabel = foundColumn.label;
                }
                return choice;
            })
        }));
    };

    return (
        <Box>
            <Box mb={1.5}>
                <Grid container alignItems="center" style={{width: '100%'}}>
                    <Grid style={{width: '20%'}}></Grid>
                    {
                        question.columns.map(column => (
                            <Grid key={column.id} container justifyContent="center" style={{width: `calc(80% / ${question.columns.length})`}}>
                                <Grid>
                                    <Typography variant="body1">{column.label}</Typography>
                                </Grid>
                            </Grid>
                        ))
                    }
                </Grid>
            </Box>
            {
                question.rows.map((row, rowIndex) => (
                    <Box mb={1.5} key={row.id}>
                        <RadioGroup
                            value={value.choices[rowIndex]?.choice}
                            onChange={(e) => handleRowChange(rowIndex, e.target.value)}
                        >
                            <Grid container spacing={1} alignItems="center" sx={classes.row} style={{width: '100%'}}>
                                <Grid style={{width: '20%'}}>
                                    <Typography variant="body1">{row.label}</Typography>
                                </Grid>
                                {
                                    question.columns.map(column => (
                                        <Grid key={`${row.id}${column.id}`} container justifyContent="center" style={{width: `calc(80% / ${question.columns.length})`}}>
                                            <Grid>
                                                <FormControlLabel
                                                    labelPlacement="bottom"
                                                    value={column.id}
                                                    control={<Radio />}
                                                />
                                            </Grid>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </RadioGroup>
                    </Box>
                ))
            }
        </Box>
    );
}

export default RadioGridQuestion;