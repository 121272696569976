import { createContext, useContext, useMemo, useReducer, useRef } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { serverRequest, scrollToTop } from '../utils/common';
import { ERROR_MESSAGE_UNKNOWN, SERVER_PATH } from '../utils/constants';
import FloatingAlert from '../components/FloatingAlert';
import LoadingSystemScreen from '../components/LoadingSystemScreen';
import NewVersionAvailable from '../components/NewVersionAvailable';
import Toast from '../components/Toast';
import { corporationId as documentsTemplatesCorporationId } from '../documents/templates/settings/documents-templates-corporation-id';
import { currency as documentsTemplatesCurrency } from '../documents/templates/settings/documents-templates-currency';
import { date as documentsTemplatesDate } from '../documents/templates/settings/documents-templates-date';
import { file as documentsTemplatesFile } from '../documents/templates/settings/documents-templates-file';
import { ifCondition as documentsTemplatesIf } from '../documents/templates/settings/documents-templates-if';
import { ifNotCondition as documentsTemplatesIfNot } from '../documents/templates/settings/documents-templates-if-not';
import { ifTrueCondition as documentsTemplatesIfTrue } from '../documents/templates/settings/documents-templates-if-true';
import { individualId as documentsTemplatesIndividualId } from '../documents/templates/settings/documents-templates-individual-id';
import { list as documentsTemplatesList } from '../documents/templates/settings/documents-templates-list';
import { movablePropertyLeaseDetails as documentsTemplatesMovablePropertyLeaseDetails } from '../documents/templates/settings/documents-templates-movable-property-lease-details';
import { number as documentsTemplatesNumber } from '../documents/templates/settings/documents-templates-number';
import { radio as documentsTemplatesRadio } from '../documents/templates/settings/documents-templates-radio';
import { radioGrid as documentsTemplatesRadioGrid } from '../documents/templates/settings/documents-templates-radio-grid';
import { paymentSchedule as documentsTemplatesPaymentSchedule } from '../documents/templates/settings/documents-templates-payment-schedule';
import { schedule as documentsTemplatesSchedule } from '../documents/templates/settings/documents-templates-schedule';
import { actorSchedule as documentsTemplatesActorSchedule } from '../documents/templates/settings/documents-templates-actor-schedule';
import { special as documentsTemplatesSpecial } from '../documents/templates/settings/documents-templates-special';
import { text as documentsTemplatesText } from '../documents/templates/settings/documents-templates-text';
import authService from '../services/authService';

const appFrontEndVersion = 71;

const AppCtxAPI = createContext();
const AppContext = createContext();
const AppCtxActiveClient = createContext();
const AppCtxActiveUser = createContext();
const AppCtxAvailableClientsCompaniesFolders = createContext();
const AppCtxChangeFolderGroupsView = createContext();
const AppCtxFloatingAlert = createContext();
const AppCtxSelectedLanguage = createContext();
const AppCtxLoading = createContext();
const AppCtxLoadingApp = createContext();
const AppCtxTemplateLists = createContext();
const AppCtxListServicosDoAudiovisual = createContext();
const AppCtxOnlineUsers = createContext();
const AppCtxOperatorsMenu = createContext();
const AppCtxReturnedFormFile = createContext();
const AppCtxSelectedFolder = createContext();
const AppCtxSidenavOpen = createContext();
const AppCtxToast = createContext();
const AppCtxUseCompanyRootFolder = createContext();
const AppCtxUserGroups = createContext();
const AppCtxUserLocation = createContext();
const AppCtxVersion = createContext();

const defaultState = {
    activeUser: null,
    activeUserGroups: null,
    activeUserGroupsUpdatedAt: null,
    appBackEndVersion: null,
    availableAppClients: null,
    availableAppClientsUpdatedAt: null,
    availableAppCompanies: null,
    availableAppFolders: null,
    availableAppCompaniesPlusFoldersUpdatedAt: null,
    changeFolderGroupsViewOpen: false,
    changeFolderGroupsViewSelectedFolder: null,
    changeFolderGroupsViewSelectedFolderId: '',
    changeFolderGroupsViewUpdatedAt: null,
    floatingAlertVisible: false,
    loading: false,
    loadingSystem: true,
    selectedLanguage: 'pt',
    mainUserGroup: null,
    onlineUsers: [],
    onlineUsersUpdatedAt: null,
    onlineOperators: [],
    onlineOperatorsUpdatedAt: null,
    operatorsMenuAnchorEl: null,
    operatorsMenuOpen: false,
    operatorsMenuSelectedDocument: null,
    operatorStatus: false,
    returnedFormFile: null,
    selectedFolder: null,
    sidenavOpen: false,
    toastVisible: false,
    special_list_audiovisual_services: [],
    systemActiveClient: null,
    templateLists: [],
    useCompanyRootFolder: false,
    userLocation: '/',
    userLocationBreadcrumbs: [],
    userPosition: null
};

const reducer = (state, action) => {
    const { payload, type } = action;

    switch (type) {
        case 'HIDE CHANGE FOLDER GROUPS VIEW':
            return { ...state, changeFolderGroupsViewOpen: false, changeFolderGroupsViewSelectedFolder: null, changeFolderGroupsViewSelectedFolderId: '' };
        case 'SET ACTIVE USER':
            return { ...state, activeUser: payload };
        case 'SET ACTIVE USER GROUPS':
            return { ...state, activeUserGroups: payload, activeUserGroupsUpdatedAt: new Date() };
        case 'SET ACTIVE USER GROUPS + MAIN USER GROUP':
            return { ...state, activeUserGroups: payload.activeUserGroups, activeUserGroupsUpdatedAt: new Date(), mainUserGroup: payload.mainActiveUserGroup };
        case 'SET ACTIVE USER GROUPS POSITION':
            return { ...state, userPosition: payload };
        case 'SET APP BACKEND VERSION':
            return { ...state, appBackEndVersion: payload };
        case 'SET ACTIVE CLIENT':
            return { ...state, systemActiveClient: payload };
        case 'SET AVAILABLE CLIENTS':
            return { ...state, availableAppClients: payload, availableAppClientsUpdatedAt: new Date() };
        case 'SET AVAILABLE COMPANIES + FOLDERS':
            return { ...state, availableAppCompanies: payload.companies, availableAppFolders: payload.folders, availableAppCompaniesPlusFoldersUpdatedAt: new Date() };
        case 'SET CHANGE FOLDER GROUPS VIEW SELECTED FOLDER':
            let selectedFolderId = '';
            if(payload) selectedFolderId = payload._id;
            return { ...state, changeFolderGroupsViewSelectedFolder: payload, changeFolderGroupsViewSelectedFolderId: selectedFolderId, changeFolderGroupsViewUpdatedAt: new Date() };
        case 'SET FLOATING ALERT VISIBLE':
            return { ...state, floatingAlertVisible: payload };
        case 'SET LIST SERVIÇOS DO AUDIOVISUAL':
            return { ...state, special_list_audiovisual_services: payload };
        case 'SET LOADING':
            return { ...state, loading: payload };
        case 'SET LOADING APP':
            return { ...state, loadingSystem: payload };
        case 'SET ONLINE OPERATORS':
            return { ...state, onlineOperators: payload, onlineOperatorsUpdatedAt: new Date() };
        case 'SET ONLINE USERS':
            return { ...state, onlineUsers: payload, onlineUsersUpdatedAt: new Date() };
        case 'SET OPERATOR STATUS':
            return { ...state, operatorStatus: payload };
        case 'SET RETURNED FORM FILE':
            return { ...state, returnedFormFile: payload };
        case 'SET SELECTED FOLDER':
            return { ...state, selectedFolder: payload };
        case 'SET SIDENAV OPEN':
            return { ...state, sidenavOpen: payload };
        case 'SET STATE':
            return { ...state, [payload.key]: payload.value };
        case 'SET TEMPLATE LISTS':
            return { ...state, templateLists: payload };
        case 'SET USE COMPANY ROOT FOLDER':
            return { ...state, useCompanyRootFolder: payload };
        case 'SET TOAST VISIBLE':
            return { ...state, toastVisible: payload };
        case 'SET USER LOCATION':
            return { ...state, userLocation: payload.path, userLocationBreadcrumbs: payload.breadcrumbs };
        case 'SET USER POSITION':
            return { ...state, userPosition: payload };
        case 'SHOW CHANGE FOLDER GROUPS VIEW':
            return { ...state, changeFolderGroupsViewOpen: true, changeFolderGroupsViewSelectedFolder: payload.selectedFolder, changeFolderGroupsViewSelectedFolderId: payload.selectedFolder._id };
        case 'SHOW OPERATORS MENU':
            if(payload.element){
                return {
                    ...state,
                    operatorsMenuAnchorEl: payload.element,
                    operatorsMenuOpen: true,
                    operatorsMenuSelectedDocument: payload.clickedDocument,
                };
            }
            return {
                ...state,
                operatorsMenuOpen: false,
            };
        case 'RESET':
            return {...defaultState};
        case 'RESET USER':
            return { ...state, selectedFolder: null, activeUserGroups: null, activeUserGroupsUpdatedAt: new Date(), userPosition: false };
        case 'UPDATE ACTIVE USER':
            return { ...state, activeUser: {...state.activeUser, ...payload} };
        case 'UPDATE SELECTED FOLDER':
            return { ...state, selectedFolder: {...state.selectedFolder, ...payload} };
        default: return state;
    }
    
};

const AppDataProvider = ({children}) => {
    const navigate = useNavigate();
    const floatingAlertProps = useRef({});
    const toastProps = useRef({});
    const location = useLocation();
    
    const [state, dispatch] = useReducer(reducer, {...defaultState});
    const api = useMemo(() => {

        const changeDocumentKey = async (collection, document, updates, setDocuments, setDocument, setLoading, permissionId) => {
            if(setLoading) setLoading(true);
            let documentCopy = {...document};
            if(setDocument) setDocument({...document, ...updates});
            if(setDocuments) setDocuments({...document, ...updates});
            try {
                if(permissionId === 'operator'){
                    await updateOneOperators(collection, document._id, updates);
                } else {
                    await update(collection, document._id, updates);
                }
            } catch (error) {
                if(setDocument) setDocument(documentCopy);
                if(setDocuments) setDocuments(documentCopy);
            }
            if(setLoading) setLoading(false);
        };

        const deleteForever = (collection, id, projectId) => {
            return new Promise((resolve, reject) => {
                axios.post(SERVER_PATH + '/data/delete', {collection, id, projectId})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err.response.data);
                });
            });
        };

        const floatingAlert = (message, messageSeverity = 'warning', messageTimeout = 1000) => {
            setFloatingAlertVisible(false);
            if(message){
                floatingAlertProps.current = {message, severity: messageSeverity};
                setTimeout(() => {
                    setFloatingAlertVisible(true);
                }, messageTimeout);
            }
        };

        const hideChangeFolderGroupsView = (payload) => {
            dispatch({type: 'HIDE CHANGE FOLDER GROUPS VIEW', payload});
        };

        const getAppBackEndVersion = async () => {
            const data = await serverRequest({path: '/version'});
            console.log('appBackEndVersion', data.appBackEndVersion);
            dispatch({ type: 'SET APP BACKEND VERSION', payload: data.appBackEndVersion });
        };

        const getAvailableAppClientsAction = (fields = ['name']) => {
            axios.post(SERVER_PATH + `/data/clients/many`, { fields })
            .then(res => {
                const currentAvailableAppClients = res.data.clients;
                dispatch({type: 'SET AVAILABLE CLIENTS', payload: currentAvailableAppClients});
            })
            .catch(err => {
                console.log(err);
            });
        };

        const getAvailableAppFolders = (fields = ['clientId', 'name', 'client']) => {
            axios.post(SERVER_PATH + `/data/folders/many`, { fields })
            .then(res => {
                let currentAvailableAppProjects = res.data.projects;
                let currentAvailableAppCompanies = currentAvailableAppProjects.filter(i => !i.client);
                let currentAvailableAppFolders = currentAvailableAppProjects.filter(i => i.client);
                dispatch({type: 'SET AVAILABLE COMPANIES + FOLDERS', payload: { companies: currentAvailableAppCompanies, folders: currentAvailableAppFolders }});
            })
            .catch(err => {
                console.log(err);
            });
        };

        const getSuppliers = (clientId) => {
            return new Promise(async (resolve, reject) => {
                try {
                    const data = await serverRequest({path: `/data/suppliers/client?clientId=${clientId}`});
                    resolve(data.suppliers);
                } catch (error) {
                    toast(ERROR_MESSAGE_UNKNOWN);
                    reject(false);
                }
            });
        };

        const getUserGroups = (activeUser, groups, isOperator = false) => {
            let mainGroup;
            let userGroups = [];
            let userPosition = isOperator ? 2 : userManagerTest(groups, activeUser._id);
            if(userPosition && userPosition === 2){
                if(groups.some(group => group.id == 2)){
                    mainGroup = 2;
                } else {
                    mainGroup = 1;
                }
                groups.forEach(g => {
                    if(g.id !== '~all;' && g.id !== '&all' && g.id !== '~none;' && g.id !== '&none') userGroups.push(g);
                });
            } else if(userPosition && userPosition === 1){
                mainGroup = 1;
                groups.forEach(g => {
                    if(g.id !== '~all;' && g.id !== '&all' && g.id !== '~none;' && g.id !== '&none' && g.id != 2) userGroups.push(g);
                });
            } else {
                let group_all = groups.find(g => g.id === '~all;' || g.id === '&all');
                groups.forEach(g => {
                    if((g.id !== '~all;' && g.id !== '&all' && g.id !== '~none;' && g.id !== '&none' && g.id != 2 && g.id != 1) && (g.users.includes(activeUser._id) || (group_all && group_all.users.includes(activeUser._id)))){
                        userGroups.push(g);
                        if(!mainGroup) mainGroup = g.id;
                    }
                });
            }
            // group_any always in first position of array
            dispatch({type: 'SET ACTIVE USER GROUPS + MAIN USER GROUP', payload: { activeUserGroups: userGroups, mainActiveUserGroup: mainGroup }});
        };

        const handleNavigate = (path, didNavigateAction, willNavigateAction) => {
            if(willNavigateAction) willNavigateAction();
            navigate(path);
            scrollToTop('main');
            if(didNavigateAction) didNavigateAction();
            stopVideo();
        };

        const logout = async (navigate, setLoading) => {
            if(setLoading) setLoading(true);
            axios.get(SERVER_PATH + '/logout');
            resetUser();
            setActiveUser(null);
            navigate('/');
            resetStates();
        };

        const resetStates = () => {
            dispatch({type: 'RESET'});
        };

        const resetUser = () => {
            dispatch({type: 'RESET USER'});
        };

        const setActiveUser = (payload) => {
            dispatch({ type: 'SET ACTIVE USER', payload });
        };

        const setActiveUserGroups = (payload) => {
            dispatch({type: 'SET ACTIVE USER GROUPS', payload});
        }

        const setAppCtxActiveClient = (payload) => {
            dispatch({type: 'SET ACTIVE CLIENT', payload});
        };

        const setAvailableClients = (payload) => {
            dispatch({type: 'SET AVAILABLE CLIENTS', payload});
        };

        const setChangeFolderGroupsViewSelectedFolder = (selectedFolder) => {
            dispatch({type: 'SET CHANGE FOLDER GROUPS VIEW SELECTED FOLDER', payload: selectedFolder});
        };

        const setFloatingAlertVisible = (payload) => {
            dispatch({ type: 'SET FLOATING ALERT VISIBLE', payload });
        };

        const setListServicosDoAudiovisual = (payload) => {
            dispatch({ type: 'SET LIST SERVIÇOS DO AUDIOVISUAL', payload });
        };

        const setLoading = (payload) => {
            dispatch({ type: 'SET LOADING', payload });
        };

        const setLoadingSystem = (payload) => {
            dispatch({ type: 'SET LOADING APP', payload });
        };

        const setOnlineOperators = (payload) => {
            dispatch({ type: 'SET ONLINE OPERATORS', payload });
        };
        
        const setOnlineUsers = (payload) => {
            dispatch({ type: 'SET ONLINE USERS', payload });
        };

        const setOperatorStatus = (payload) => {
            dispatch({ type: 'SET OPERATOR STATUS', payload });
        };

        const setReturnedFormFile = (payload) => {
            dispatch({ type: 'SET RETURNED FORM FILE', payload });
        };

        const setSelectedFolder = (payload) => {
            dispatch({ type: 'SET SELECTED FOLDER', payload });
        };

        const setSidenavOpen = (payload) => {
            dispatch({ type: 'SET SIDENAV OPEN', payload });
        };

        const setTemplateLists = (payload) => {
            dispatch({ type: 'SET TEMPLATE LISTS', payload });
        };

        const setToastVisible = (payload) => {
            dispatch({ type: 'SET TOAST VISIBLE', payload });
        };

        const setUseCompanyRootFolder = (payload) => {
            dispatch({ type: 'SET USE COMPANY ROOT FOLDER', payload });
        };

        const setUserLocation = ({ breadcrumbs, path }) => {
            dispatch({type: 'SET USER LOCATION', payload: { breadcrumbs, path }})
        };

        const setUserPosition = (payload) => {
            dispatch({ type: 'SET USER POSITION', payload });
        };

        const showChangeFolderGroupsView = (selectedFolder) => {
            dispatch({type: 'SHOW CHANGE FOLDER GROUPS VIEW', payload: { selectedFolder }});
        };

        const showOperatorsMenu = (element, clickedDocument) => {
            dispatch({type: 'SHOW OPERATORS MENU', payload: { element, clickedDocument }});
        };

        const signIn = async (id, password, remember) => {
            const user = await authService.login(SERVER_PATH, id, password, remember);
            return user;
        };

        const stopVideo = () => {
            if(activeUserVideo.current){
                const stream = activeUserVideo.current.srcObject;
                if(stream){
                    const tracks = stream.getTracks();
                    tracks.forEach((track) => {
                        track.stop();
                    });
                }
            }
        };

        const toast = (message, vertical) => {
            toastProps.current = {message, vertical};
            setToastVisible(true);
        };

        const update = (collection, id, updates) => {
            return new Promise((resolve, reject) => {
                axios.post(SERVER_PATH + '/data/update', {
                    collection,
                    id,
                    updates
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
            });
        };

        const updateActiveUser = (payload) => {
            dispatch({ type: 'UPDATE ACTIVE USER', payload });
        };

        const updateOneManagers = (collection, id, updates, projectId) => {
            return new Promise((resolve, reject) => {
                axios.post(SERVER_PATH + '/data/update/one/manager', {
                    collection,
                    id,
                    updates,
                    projectId
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
            });
        };

        const updateOneOperators = (collection, id, updates, projectId) => {
            return new Promise((resolve, reject) => {
                axios.post(SERVER_PATH + '/data/update/one/operator', { 
                    collection,
                    id,
                    updates,
                    projectId
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
            });
        };

        const updateOneTrueOperators = (collection, id, updates) => {
            return new Promise((resolve, reject) => {
                axios.post(SERVER_PATH + '/data/update/one/operator/true', { 
                    collection,
                    id,
                    updates
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
            });
        };

        const updateSelectedFolder = (payload) => {
            dispatch({ type: 'UPDATE SELECTED FOLDER', payload });
        };

        const userManagerTest = (groups, userId) => {
            let position = false;
            if(groups.length >= 1){
                const group_admin = groups.find(g => g.id === 2 || g.id === '2');
                if(group_admin){
                    const userAdmin = group_admin.users.includes(userId);
                    if(userAdmin) position = 2;
                }
                const group_manager = groups.find(g => g.id === 1 || g.id === '1');
                if(group_manager){
                    const userManager = group_manager.users.includes(userId);
                    if(userManager) position = 1;
                }
            }
            dispatch({type: 'SET ACTIVE USER GROUPS POSITION', payload: position})
            return position;
        };

        return {
            dispatch,
            changeDocumentKey,
            deleteForever,
            floatingAlert,
            getAppBackEndVersion, getAvailableAppClientsAction, getAvailableAppFolders, getSuppliers, getUserGroups,
            handleNavigate,
            hideChangeFolderGroupsView,
            logout,
            resetUser,
            setActiveUser, setActiveUserGroups, setAppCtxActiveClient, setAvailableClients,
            setChangeFolderGroupsViewSelectedFolder,
            setFloatingAlertVisible,
            setListServicosDoAudiovisual, setLoading, setLoadingSystem,
            setOnlineOperators, setOnlineUsers, setOperatorStatus,
            setReturnedFormFile,
            setSelectedFolder, setSidenavOpen,
            setTemplateLists, setToastVisible,
            setUseCompanyRootFolder, setUserLocation, setUserPosition,
            showChangeFolderGroupsView,
            showOperatorsMenu,
            signIn, stopVideo,
            toast,
            update, updateActiveUser, updateOneManagers, updateOneOperators, updateOneTrueOperators, updateSelectedFolder, userManagerTest
        };
    }, []);

    const activeUserVideo = useRef(null);

    const formEffects = {
        date: documentsTemplatesDate,
        file: documentsTemplatesFile,
        list: documentsTemplatesList,
        actorSchedule: documentsTemplatesActorSchedule,
        movablePropertyLeaseDetails: documentsTemplatesMovablePropertyLeaseDetails,
        paymentSchedule: documentsTemplatesPaymentSchedule,
        radio: documentsTemplatesRadio,
        radioGrid: documentsTemplatesRadioGrid,
        schedule: documentsTemplatesSchedule,
        text: documentsTemplatesText,
        currency: documentsTemplatesCurrency,
        number: documentsTemplatesNumber,
        individualId: documentsTemplatesIndividualId,
        corporationId: documentsTemplatesCorporationId,
        if: documentsTemplatesIf,
        ifNot: documentsTemplatesIfNot,
        ifTrue: documentsTemplatesIfTrue,
        special: documentsTemplatesSpecial,
    };

    const appCtxValue = useMemo(() => ({
        formEffects, activeUserVideo
    }), []);

    const appCtxToastValue = useMemo(() => ({
        toastProps: toastProps.current, toastVisible: state.toastVisible
    }), [state.toastVisible]);

    const appCtxFloatingAlertValue = useMemo(() => ({
        floatingAlertProps: floatingAlertProps.current, floatingAlertVisible: state.floatingAlertVisible
    }), [state.floatingAlertVisible]);

    const appCtxVersionValue = useMemo(() => ({
        backendVersion: state.appBackEndVersion, frontendVersion: appFrontEndVersion
    }), [state.appBackEndVersion]);
    
    const appCtxAvailableAppCompaniesPlusFoldersValue = useMemo(() => ({
        availableAppClients: state.availableAppClients,
        availableAppCompanies: state.availableAppCompanies,
        availableAppFolders: state.availableAppFolders
    }), [state.availableAppClientsUpdatedAt, state.availableAppCompaniesPlusFoldersUpdatedAt]);

    const appCtxOnlineUsersValue = useMemo(() => ({
        onlineOperators: state.onlineOperators,
        onlineUsers: state.onlineUsers
    }), [state.onlineOperatorsUpdatedAt, state.onlineUsersUpdatedAt]);

    const appCtxUserGroupsValue = useMemo(() => ({
        activeUserGroups: state.activeUserGroups,
        mainUserGroup: state.mainUserGroup,
        operatorStatus: state.operatorStatus,
        userPosition: state.userPosition
    }), [state.activeUserGroupsUpdatedAt, state.mainUserGroup, state.operatorStatus, state.userPosition]);

    const appCtxChangeFolderGroupsViewValue = useMemo(() => ({
        changeFolderGroupsViewOpen: state.changeFolderGroupsViewOpen,
        changeFolderGroupsViewSelectedFolder: state.changeFolderGroupsViewSelectedFolder,
        changeFolderGroupsViewSelectedFolderId: state.changeFolderGroupsViewSelectedFolderId,
    }), [state.changeFolderGroupsViewOpen, state.changeFolderGroupsViewUpdatedAt]);

    const appCtxOperatorsMenuValue = useMemo(() => ({
        operatorsMenuAnchorEl: state.operatorsMenuAnchorEl,
        operatorsMenuOpen: state.operatorsMenuOpen,
        operatorsMenuSelectedDocument: state.operatorsMenuSelectedDocument
    }), [state.operatorsMenuOpen]);
    
    return (
        <AppCtxAPI.Provider value={api}>
            <AppContext.Provider value={appCtxValue}>
                <AppCtxLoadingApp.Provider value={state.loadingSystem}>
                <AppCtxActiveUser.Provider value={state.activeUser}>
                <AppCtxSelectedLanguage.Provider value={state.selectedLanguage}>
                <AppCtxLoading.Provider value={state.loading}>
                    <AppCtxToast.Provider value={appCtxToastValue}>
                        <AppCtxFloatingAlert.Provider value={appCtxFloatingAlertValue}>
                            <AppCtxVersion.Provider value={appCtxVersionValue}>
                                <AppCtxSidenavOpen.Provider value={state.sidenavOpen}>
                                <AppCtxUserLocation.Provider value={{ userLocation: state.userLocation, userLocationBreadcrumbs: state.userLocationBreadcrumbs }}>
                                <AppCtxActiveClient.Provider value={state.systemActiveClient}>
                                <AppCtxTemplateLists.Provider value={state.templateLists}>
                                <AppCtxListServicosDoAudiovisual.Provider value={state.special_list_audiovisual_services}>
                                <AppCtxAvailableClientsCompaniesFolders.Provider value={appCtxAvailableAppCompaniesPlusFoldersValue}>
                                <AppCtxOnlineUsers.Provider value={appCtxOnlineUsersValue}>
                                <AppCtxUserGroups.Provider value={appCtxUserGroupsValue}>
                                <AppCtxSelectedFolder.Provider value={state.selectedFolder}>
                                <AppCtxUseCompanyRootFolder.Provider value={state.useCompanyRootFolder}>
                                <AppCtxReturnedFormFile.Provider value={state.returnedFormFile}>
                                <AppCtxChangeFolderGroupsView.Provider value={appCtxChangeFolderGroupsViewValue}>
                                <AppCtxOperatorsMenu.Provider value={appCtxOperatorsMenuValue}>
                                    {children}
                                </AppCtxOperatorsMenu.Provider>
                                </AppCtxChangeFolderGroupsView.Provider>
                                </AppCtxReturnedFormFile.Provider>
                                </AppCtxUseCompanyRootFolder.Provider>
                                </AppCtxSelectedFolder.Provider>
                                </AppCtxUserGroups.Provider>
                                </AppCtxOnlineUsers.Provider>
                                </AppCtxAvailableClientsCompaniesFolders.Provider>
                                </AppCtxListServicosDoAudiovisual.Provider>
                                </AppCtxTemplateLists.Provider>
                                </AppCtxActiveClient.Provider>
                                </AppCtxUserLocation.Provider>
                                </AppCtxSidenavOpen.Provider>

                                <NewVersionAvailable />

                            </AppCtxVersion.Provider>

                            <FloatingAlert />

                        </AppCtxFloatingAlert.Provider>
                        
                        <Toast />

                    </AppCtxToast.Provider>
                    </AppCtxLoading.Provider>
                    </AppCtxSelectedLanguage.Provider>
                    </AppCtxActiveUser.Provider>

                    <LoadingSystemScreen />

                </AppCtxLoadingApp.Provider>
            </AppContext.Provider>
        </AppCtxAPI.Provider>
    );
};

const useAppContext = () => useContext(AppContext);
const useAppCtxAPI = () => useContext(AppCtxAPI);
const useAppCtxActiveClient = () => useContext(AppCtxActiveClient);
const useAppCtxActiveUser = () => useContext(AppCtxActiveUser);
const useAppCtxAvailableClientsCompaniesFolders = () => useContext(AppCtxAvailableClientsCompaniesFolders);
const useAppCtxChangeFolderGroupsView = () => useContext(AppCtxChangeFolderGroupsView);
const useAppCtxFloatingAlert = () => useContext(AppCtxFloatingAlert);
const useAppCtxListServicosDoAudiovisual = () => useContext(AppCtxListServicosDoAudiovisual);
const useAppCtxLoading = () => useContext(AppCtxLoading);
const useAppCtxLoadingApp = () => useContext(AppCtxLoadingApp);
const useAppCtxOnlineUsers = () => useContext(AppCtxOnlineUsers);
const useAppCtxOperatorsMenu = () => useContext(AppCtxOperatorsMenu);
const useAppCtxReturnedFormFile = () => useContext(AppCtxReturnedFormFile);
const useAppCtxSelectedFolder = () => useContext(AppCtxSelectedFolder);
const useAppCtxSelectedLanguage = () => useContext(AppCtxSelectedLanguage);
const useAppCtxSidenavOpen = () => useContext(AppCtxSidenavOpen);
const useAppCtxTemplateLists = () => useContext(AppCtxTemplateLists);
const useAppCtxToast = () => useContext(AppCtxToast);
const useAppCtxUseCompanyRootFolder = () => useContext(AppCtxUseCompanyRootFolder);
const useAppCtxUserLocation = () => useContext(AppCtxUserLocation);
const useAppCtxUserGroups = () => useContext(AppCtxUserGroups);
const useAppCtxVersion = () => useContext(AppCtxVersion);

export {
    AppDataProvider,
    useAppCtxAPI,
    useAppContext,
    useAppCtxActiveClient,
    useAppCtxActiveUser,
    useAppCtxAvailableClientsCompaniesFolders,
    useAppCtxChangeFolderGroupsView,
    useAppCtxFloatingAlert,
    useAppCtxListServicosDoAudiovisual,
    useAppCtxLoading,
    useAppCtxLoadingApp,
    useAppCtxOnlineUsers,
    useAppCtxOperatorsMenu,
    useAppCtxReturnedFormFile,
    useAppCtxSelectedFolder,
    useAppCtxSelectedLanguage,
    useAppCtxSidenavOpen,
    useAppCtxTemplateLists,
    useAppCtxToast,
    useAppCtxUseCompanyRootFolder,
    useAppCtxUserGroups,
    useAppCtxUserLocation,
    useAppCtxVersion
};