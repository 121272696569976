import React, { useState } from 'react';
import Autocomplete from '../../../Autocomplete';
import { getProjectNameById } from '../../../../utils/common';
import { sortByKey } from '../../../../utils/filters';

const CompanyField = ({addDocumentForm, projects, handleSelectedCompanyChange}) => {
    const [selectedCompany, setSelectedCompany] = useState(addDocumentForm.current.companyId || '');
    const handleChange = (newValue) => {
        setSelectedCompany(newValue);
        handleSelectedCompanyChange(newValue);
    };
    return (
        <Autocomplete
            label="Empresa"
            value={selectedCompany}
            onChange={(e, newValue) => handleChange(newValue)}
            options={projects ? projects.filter(p => !p?.client).sort(sortByKey('name')).map(p => p?._id) : []}
            getOptionLabel={(option) => getProjectNameById(projects, option)}
        />
    );
};

export default CompanyField;