import { useEffect, useMemo, useState } from 'react';
import { Avatar, Box, Button, Chip, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ChangeProjectClearanceInformationWindow from '../../../../components/ChangeProjectClearanceInformationWindow';
import ChangeProjectSignersWindow from '../../../../components/ChangeProjectSignersWindow';
import ChangeProjectTemplatesWindow from '../../../../components/ChangeProjectTemplatesWindow';
import ChangeFolderView from './components/DataDrawer';
import GroupsWindow from '../../../../components/GroupsWindow';
import Table2 from '../../../../components/Table2/Table';
import Table2CustomTopToolbar from '../../../../components/Table2/components/Table2CustomTopToolbar';
import ViewBox from '../../../../components/ViewBox';
import { useOperatorCtxFolders, useOperatorCtxProjects, useOperatorCtxUsers } from '../../context/OperatorContext';
import { useOperatorFoldersCtxAPI, useOperatorFoldersCtxVisibleFolders } from '../../../../context/OperatorFoldersContext';
import { sortByKey } from '../../../../utils/filters';
import { getFolderCompanyName } from '../../../../utils/common';

const RowChangeAction = ({row}) => {
    const { showChangeFolderView } = useOperatorFoldersCtxAPI();

    const handleClick = () => {
        const selectedUser = row.original;
        showChangeFolderView(selectedUser);
    };

    return (
        <IconButton size="small" onClick={handleClick}>
            <EditIcon />
        </IconButton>
    )
}

const Folders = () => {
    const folders = useOperatorCtxFolders();
    const projects = useOperatorCtxProjects();
    const { users } = useOperatorCtxUsers();
    const { setVisibleFolders, setLoadingVisibleFolders, showAddFolderView } = useOperatorFoldersCtxAPI();
    const { isLoadingVisibleFolders, visibleFolders } = useOperatorFoldersCtxVisibleFolders();
    const [floatingNotification, setFloatingNotification] = useState({ message: '', severity: '' });

    useEffect(() => {
        if(folders){
            const currentFolders = folders.sort(sortByKey('name')).map(currentFolder => {
                currentFolder.companyName = getFolderCompanyName(projects, currentFolder);
                const foundUser = users.find(user => user._id === currentFolder.projectOperator);
                if(foundUser){
                    currentFolder.folderOperatorName = foundUser.screenName;
                    currentFolder.folderOperatorFullName = foundUser.fullName;
                    currentFolder.folderOperatorAvatar = foundUser.img;
                }
                return currentFolder;
            });
            setVisibleFolders(currentFolders);
        }
    }, [folders]);
    
    const columns = useMemo(
        () => [
            {
                accessorKey: 'name', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                filterVariant: 'text',
                header: 'Pasta',
            },
            {
                accessorKey: 'companyName',
                filterVariant: 'text',
                header: 'Empresa',
            },
            {
                id: 'folderOperator', 
                accessorFn: (row) => {
                    if(row.folderOperatorName){
                        return (
                            <Chip
                                avatar={<Avatar alt={row.folderOperatorName} src={`/uploads/avatars/${row.folderOperatorAvatar}`} />}
                                label={row.folderOperatorName}
                                variant="outlined"
                            />
                        );
                    }
                    return null;
                },
                filterVariant: 'text',
                filterFn: (row, id, filterValue) => {
                    const folderOperatorName = (row.original.folderOperatorFullName || '').toLowerCase();
                    return folderOperatorName.indexOf(filterValue.toLowerCase()) !== -1;
                },
                header: 'Responsável',
                grow: false,
                size: 170
            },
        ],
        [],
    );

    const renderTopToolbar = ({ table }) => {
        
        const handleAddFolderButtonClick = () => {
            showAddFolderView();
        };
    
        return (
            <Table2CustomTopToolbar
                buttons={[
                    <Button variant="contained" minWidth={157.48} onClick={handleAddFolderButtonClick} startIcon={<AddIcon />}>Nova pasta</Button>,
                ]}
                table={table}
            />
        );
    };

    const renderRowActions = ({ row }) => (
        <Box>
            <RowChangeAction row={row} />
        </Box>
    );

    return (
        <ViewBox message={floatingNotification} additionalSx={[{ height: '100%' }]}>
            
            <Table2
                columns={columns}
                data={visibleFolders}
                enablePagination={false}
                enableRowVirtualization
                initialStateColumnPinningLeft={['name']}
                initialStateDensity="comfortable"
                isLoading={isLoadingVisibleFolders}
                renderRowActions={renderRowActions}
                renderTopToolbar={renderTopToolbar}
            />

            <ChangeFolderView
                data={{
                    setFloatingNotification
                }}
            />
            <GroupsWindow
                isOperator={true}
                users={users}
            />
            <ChangeProjectClearanceInformationWindow setFloatingNotification={setFloatingNotification} />
            <ChangeProjectSignersWindow setFloatingNotification={setFloatingNotification} />
            <ChangeProjectTemplatesWindow />
        </ViewBox>
    );
}

export default Folders;