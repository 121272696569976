import { Box, Grid, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

const RiskLevel = ({riskLevel, color, textVariant}) => {
    if(riskLevel){
        return (
            <Box>
                <Grid container spacing={1} alignItems="center" wrap="nowrap">
                    <Grid item>
                        <FontAwesomeIcon icon={faCircle} color={riskLevel.color} />
                    </Grid>
                    <Grid item>
                        <Typography variant={textVariant || 'body2'} color={color || 'inherit'}>{riskLevel.label}</Typography>
                    </Grid>
                </Grid>
            </Box>
        );
    }
    return null;
};

export default RiskLevel;