import { styled } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';

const Span = styled('span')(unstable_styleFunctionSx);

const ButtonLink = ({children, color, onClick}) => {
    let spanStyle = null;
    if(color){
        spanStyle = {
            color
        }
    }
    return (
        <Span
            sx={{
                color: (theme) => theme.palette.primary.main,
                '&:hover': {
                    cursor: 'pointer',
                    textDecoration: 'underline',
                }
            }}
            style={spanStyle}
            onClick={onClick}
        >{children}</Span>
    )
}

export default ButtonLink;