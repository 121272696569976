import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { removeDiacritics } from '../utils/common';

const TextQuestion = (props) => {
    const {
        CNPJStatus, foundCNPJ, handleUserAwareErrorsChange,
        initialValue,
        question,
        selectedSupplier,
        showConsentingSignatoryError, setShowConsentingSignatoryError,
        showRepresentativeError, setShowRepresentativeError,
        userAwareOfErrors
    } = props;
    const defaultValue = { input: '' };
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        setValue(initialValue || defaultValue);
    }, [initialValue]);

    useEffect(() => {
        if(props.onChange) props.onChange(question.name, value);
    }, [value]);

    const handleChange = (newValue) => {
        setValue(prevState => ({...prevState, input: newValue}));
    };

    const SupplierAndAssignedFieldComparation = () => {
        if(selectedSupplier && !!question.assignedField && foundCNPJ){
            let message = '';
            let supplierInformation = '';
            if(question.assignedField === 1){
                supplierInformation = selectedSupplier.name.toUpperCase();
                if(removeDiacritics(supplierInformation) !== removeDiacritics(value.input.toUpperCase()) && removeDiacritics(supplierInformation) !== removeDiacritics(foundCNPJ.companyName.toUpperCase())){
                    const valueMatchesCNPJ = removeDiacritics(value.input.toUpperCase()) === removeDiacritics(foundCNPJ.companyName.toUpperCase());
                    message = `A razão/denominação social indicada na ficha cadastral não corresponde à informação acima${valueMatchesCNPJ ? ' (mostrando a informação extraída do CNPJ)' : ''}.`;
                }
            } else if(question.assignedField === 3){
                supplierInformation = selectedSupplier.address;
                if(supplierInformation !== value.input && supplierInformation !== foundCNPJ.fullAddress){
                    const valueMatchesCNPJ = value.input === foundCNPJ.fullAddress;
                    message = `O endereço indicado na ficha cadastral não corresponde à informação extraída do CNPJ${valueMatchesCNPJ ? ' (mostrando a informação extraída do CNPJ)' : ''}.`;
                }
            } else if(question.assignedField === 4){
                supplierInformation = selectedSupplier.representative;
                const questionLegalRepresentatives = foundCNPJ.legalRepresentativeNames || [];
                if(supplierInformation !== value.input && !questionLegalRepresentatives.includes(removeDiacritics(supplierInformation))){
                    const valueMatchesCNPJ = questionLegalRepresentatives.includes(removeDiacritics(value.input));
                    message = `O nome completo do(a) representante legal indicado na ficha cadastral não ${questionLegalRepresentatives.length === 1 ? 'é o mesmo indicado' : 'está entre os sócios-administradores da empresa indicados'} no CNPJ${valueMatchesCNPJ ? ' (mostrando a informação extraída do CNPJ)' : ''}.`;
                }
            }
            if(message && supplierInformation){
                return (
                    <Box mt={1}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={6}>
                                <Typography variant="body2">{message}</Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography variant="h6">Informação indicada na ficha:</Typography>
                                <Typography variant="body2">{supplierInformation}</Typography>
                                <Grid container spacing={1} alignItems="center" justifyContent="center">
                                    <Grid item>
                                        <Button onClick={() => handleChange(supplierInformation)}>Usar a ficha</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                );
            }
        }
        return null;
    };

    const CNPJLegalRepresentatives = () => {
        if(question.assignedField === 4 && question.corporationIdQuestion && foundCNPJ){
            const questionLegalRepresentatives = foundCNPJ.legalRepresentativeNames || [];
            if(setShowRepresentativeError && questionLegalRepresentatives.length >= 1){
                return (
                    <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="h6">{questionLegalRepresentatives.length === 1 ? 'Representante legal': 'Sócios-administradores'}:</Typography>
                        <List dense>
                        {
                            questionLegalRepresentatives.map(partnerName => (
                                <ListItem key={partnerName} dense button
                                    onClick={() => {
                                        handleChange(partnerName);
                                        setShowRepresentativeError(false);
                                    }}
                                >
                                    <ListItemText disableTypography primary={
                                        <Grid container spacing={1} alignItems="center" wrap="nowrap">
                                            <Grid item xs><Typography variant="body2">{partnerName}</Typography></Grid>
                                            {
                                                value.input === partnerName &&
                                                <Grid item>
                                                    <CheckCircleIcon fontSize="small" htmlColor="green" />
                                                </Grid>
                                            }
                                        </Grid>
                                    } />
                                </ListItem>
                            ))
                        }
                        </List>
                    </Grid>
                )
            }
        }
        return null;
    };

    const CNPJPartners = () => {
        if(question.assignedField === 'consentingSignatoryName' && question.corporationIdQuestion && foundCNPJ){
            const questionPartners = foundCNPJ.partnerNames || [];
            if(setShowConsentingSignatoryError && questionPartners.length >= 1){
                return (
                    <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="h6">{foundCNPJ.isMEI ? 'Representante legal' : 'Sócios'}:</Typography>
                        <List dense>
                        {
                            questionPartners.map(partnerName => (
                                <ListItem key={partnerName} dense button
                                    onClick={() => {
                                        handleChange(partnerName);
                                        setShowConsentingSignatoryError(false);
                                    }}
                                >
                                    <ListItemText disableTypography primary={
                                        <Grid container spacing={1} alignItems="center" wrap="nowrap">
                                            <Grid item xs><Typography variant="body2">{partnerName}</Typography></Grid>
                                            {
                                                value.input === partnerName &&
                                                <Grid item>
                                                    <CheckCircleIcon fontSize="small" htmlColor="green" />
                                                </Grid>
                                            }
                                        </Grid>
                                    } />
                                </ListItem>
                            ))
                        }
                        </List>
                    </Grid>
                )
            }
        }
        return null;
    };
    
    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs>
                    <TextField
                        variant="standard"
                        fullWidth
                        multiline
                        placeholder="Sua resposta"
                        disabled={props.disabled}
                        value={value.input}
                        onChange={(e) => handleChange(e.target.value)} />
                    <SupplierAndAssignedFieldComparation />
                </Grid>
                <CNPJLegalRepresentatives />
                <CNPJPartners />
            </Grid>
            {
                (handleUserAwareErrorsChange && CNPJStatus !== 'retrievingData' && (showRepresentativeError || showConsentingSignatoryError)) &&
                <Box mt={1}>
                    <Grid container spacing={1} alignItems="center" justifyContent="center" wrap="nowrap">
                        <Grid item>
                            <CancelIcon htmlColor="red" fontSize="small" />
                        </Grid>
                        <Grid item>
                            <Typography variant="body1" style={{color: 'red'}}>Recomendamos que você escolha um dos sócios na lista acima.</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems="center" justifyContent="center" wrap="nowrap">
                        <Grid item>
                            <Checkbox size="small" color="primary"
                                checked={userAwareOfErrors}
                                onChange={(e) => handleUserAwareErrorsChange(e.target.checked)}
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">Estou ciente, mas quero enviar o formulário mesmo assim.</Typography>
                        </Grid>
                    </Grid>
                </Box>
            }
        </Box>
    );
};

export default TextQuestion;