import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Input from '../../../../components/Input';
import PopOver from '../../../../components/PopOver';
import Select from '../../../../components/Select';
import { useAppCtxLoading, useAppCtxTemplateLists, useAppCtxUseCompanyRootFolder, useAppCtxUserGroups } from '../../../../context/SystemContext';
import { useDocumentsAPI, useDocumentsCtxDocumentFiltersView } from '../../../../context/DocumentsContext';
import useTalents from '../../../../hooks/useTalents';
import { getFolderCustomDocumentFields, getFolderTemplates, resetCustomDocumentFieldsMapOptions } from '../../../../utils/common';

const StyledSelect = ({disabled, label, options = [], value, onChange}) => (
    <Select
        disabled={disabled}
        style={{fontSize: 12}}
        optionsStyle={{fontSize: 12}}
        label={label}
        options={[
            {value: 'all', label: 'Todos'},
            ...options
        ]}
        value={value} onChange={onChange}
    />
);

const CustomFiltersPopOver = ({selectCols, values, setValues, data, filters, setFilters}) => {
    const { hideDocumentFiltersView } = useDocumentsAPI();
    const { documentFiltersViewAnchorEl, documentFiltersViewOpen } = useDocumentsCtxDocumentFiltersView();
    const {
        activeUserCheck, set_activeUserCheck,
        selectedSignatureStatus, set_selectedSignatureStatus,
        selectedStatus, set_selectedStatus,
        templates, folderTemplates,
        selectedForm, set_selectedForm,
        selectedFolder,
        selectedGroup, set_selectedGroup,
        selectedProject, set_selectedProject, clientProjects,
        customFiltersCount, set_customFiltersCount
    } = data;
    const templateLists = useAppCtxTemplateLists();
    const loading = useAppCtxLoading();
    const useCompanyRootFolder = useAppCtxUseCompanyRootFolder();
    const { activeUserGroups } = useAppCtxUserGroups();

    const allString = '~all;';
    const defaultAllOption = {value: allString, label: 'Todos'};
    const defaultNoneOption = {value: '~none;', label: 'Nenhum'};
    const defaultGroupOptions = [defaultAllOption, defaultNoneOption];
    const [folderGroupOptions, setFolderGroupOptions] = useState([]);
    const defaultFormOptions = [defaultAllOption];
    const defaultCustomFieldOptions = [defaultAllOption];
    const talentModuleActive = useTalents(selectedFolder);
    const [currentTextFields, setCurrentTextFields] = useState({
        productionCompany: '',
        workPlayer: '',
    });
 
    useEffect(() => {
        if(activeUserGroups){
            setFolderGroupOptions([
                ...defaultGroupOptions,
                ...activeUserGroups.map(group => ({value: group.id, label: group.name}))
            ]);
        }
    }, [activeUserGroups]);
    
    useEffect(() => {
        let count = 0;
        if(activeUserCheck !== 'all') count++;
        if(selectedForm !== allString) count++;
        if(selectedGroup !== allString) count++;
        if(selectedStatus !== allString) count++;
        if(selectedSignatureStatus !== allString) count++;
        if(useCompanyRootFolder && selectedProject !== 'all') count++;
        let customCount = values.filter(i => i.value !== allString);
        customCount = customCount.length;
        count = count + customCount;
        set_customFiltersCount(count);
    }, [activeUserCheck, selectedForm, selectedGroup, selectedStatus, selectedSignatureStatus, selectedProject, values]);

    const handleClose = () => {
        hideDocumentFiltersView();
    };

    const handleChange = (newValue, colName, index) => {
        setValues(prevState => [...prevState.slice(0, index), {col: colName, value: newValue}, ...prevState.slice(index + 1)]);
        handleClose();
    };
    const clearFilters = () => {
        handleClose();
        set_activeUserCheck('all');
        set_selectedForm(allString);
        set_selectedGroup(allString);
        set_selectedStatus(allString);
        set_selectedSignatureStatus(allString);
        if(useCompanyRootFolder) set_selectedProject('all');
        const customDocumentFieldsValues = resetCustomDocumentFields();
        setValues(customDocumentFieldsValues);

        setFilters({});
    };
    const handleUserChange = (newValue) => {
        handleClose();
        set_activeUserCheck(newValue);
    };
    const handleFormChange = (newValue) => {
        handleClose();
        set_selectedForm(newValue);
    };
    const handleProjectChange = (newValue) => {
        handleClose();
        set_selectedProject(newValue);
        set_selectedGroup(allString);
        set_selectedForm(allString);
        const customDocumentFieldsValues = resetCustomDocumentFields(newValue);
        setValues(customDocumentFieldsValues);
    };
    const handleGroupChange = (newValue) => {
        handleClose();
        set_selectedGroup(newValue);
    };
    const handleStatusChange = (newValue) => {
        handleClose();
        set_selectedStatus(newValue);
    };
    const handleSignatureStatusChange = (newValue) => {
        handleClose();
        set_selectedSignatureStatus(newValue);
    };
    
    const resetCustomDocumentFields = (folderId) => {
        let options = null;
        if(useCompanyRootFolder){
            if(folderId && folderId !== 'all'){
                const foundFolder = clientProjects.find(folder => folder._id === folderId);
                const folderCustomDocumentFields = getFolderCustomDocumentFields(foundFolder);
                options = folderCustomDocumentFields.filter(i => i.type === 'select')
            }
        } else {
            options = selectCols;
        }
        const newValue = resetCustomDocumentFieldsMapOptions(options);
        return newValue;
    };

    const handleInputKeyDown = (e, filtersKey) => {
        if(e.key === 'Enter'){
            setFilters(prevState => ({...prevState, [filtersKey]: currentTextFields[filtersKey]}));
            handleClose();
        }
    }

    const GroupsField = () => {
        let folderGroups = null;
        if(useCompanyRootFolder){
            if(selectedProject !== 'all'){
                const foundFolder = clientProjects.find(folder => folder._id === selectedProject);
                if(foundFolder?.useGroups){
                    folderGroups = [
                        ...defaultGroupOptions,
                        ...foundFolder.groups.map(group => ({value: group.id, label: group.name}))
                    ];
                }
            }
        } else {
            if(selectedFolder?.useGroups){
                folderGroups = folderGroupOptions;
            }
        }
        if(folderGroups){
            return (
                <Grid item xs={6}>
                    <Select
                        disabled={loading}
                        style={{fontSize: 12}} optionsStyle={{fontSize: 12}}
                        label="Grupo" options={folderGroups} value={selectedGroup}
                        onChange={(e) => handleGroupChange(e.target.value)} />
                </Grid>
            );
        }
        return null;
    };
    const FormsField = () => {
        let formsOptions = null;
        if(useCompanyRootFolder){
            if(selectedProject !== 'all'){
                const foundFolder = clientProjects.find(folder => folder._id === selectedProject);
                if(foundFolder){
                    const foundFolderTemplates = getFolderTemplates(foundFolder, templateLists);
                    formsOptions = foundFolderTemplates;
                }
            }
        } else {
            formsOptions = folderTemplates;
        }
        if(formsOptions){
            return (
                <Grid item xs={6}>
                    <Select
                        disabled={loading}
                        style={{fontSize: 12}} optionsStyle={{fontSize: 12}}
                        label="Formulário"
                        options={[
                            ...defaultFormOptions,
                            ...formsOptions.map((f_t, f_tIndex) => {
                                let f_template = templates.find(t => t._id === f_t);
                                if(!f_template){
                                    f_template = {_id: f_tIndex, name: f_tIndex };
                                }
                                return ({value: f_template._id, label: f_template.name});
                            })
                        ]}
                        value={selectedForm}
                        onChange={(e) => handleFormChange(e.target.value)}
                    />
                </Grid>
            );
        }
        return null;
    };
    const ColumnsFields = () => {
        let options = null;
        if(useCompanyRootFolder){
            if(selectedProject !== 'all'){
                const foundFolder = clientProjects.find(folder => folder._id === selectedProject);
                if(foundFolder){
                    const folderCustomDocumentFields = getFolderCustomDocumentFields(foundFolder);
                    options = folderCustomDocumentFields.filter(i => i.type === 'select');
                }
            }
        } else {
            options = selectCols;
        }
        if(options){
            return (
                options.map((col, index) => (
                    <Grid item xs={6} key={index}>
                        <Select
                            disabled={loading}
                            label={col.name} style={{fontSize: 12}} optionsStyle={{fontSize: 12}}
                            options={
                                [
                                    ...defaultCustomFieldOptions,
                                    ...col.options.map(option => ({value: option, label: option}))
                                ]
                            }
                            value={values[index]?.value || allString} onChange={(e) => handleChange(e.target.value, col.name, index)} />
                    </Grid>
                ))
            );
        }
        return null;
    };

    return (
        <PopOver open={documentFiltersViewOpen} onClose={handleClose} anchorEl={documentFiltersViewAnchorEl}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
        >
            <Box mb={2} style={{display: 'flex', alignItems: 'center'}}>
                <Box style={{flex: 1}}>
                    <Typography variant="h6">Filtros{customFiltersCount ? ` (${customFiltersCount})` : ''}</Typography>
                </Box>
                <Box>
                    <Button
                        onClick={clearFilters}
                    >Limpar</Button>
                </Box>
            </Box>
            <Box>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <StyledSelect
                            disabled={loading}
                            label="Solicitante"
                            options={[
                                {value: 'me', label: 'Eu'}
                            ]}
                            value={activeUserCheck} onChange={(e) => handleUserChange(e.target.value)}
                        />
                    </Grid>
                    {
                        useCompanyRootFolder &&
                        <Grid item xs={6}>
                            <Select
                                disabled={loading}
                                style={{fontSize: 12}} optionsStyle={{fontSize: 12}}
                                label="Projeto"
                                options={
                                    [
                                        {value: 'all', label: 'Todos'},
                                        ...clientProjects.map(p => ({value: p._id, label: p.name}))
                                    ]
                                }
                                value={selectedProject}
                                onChange={(e) => handleProjectChange(e.target.value)}
                            />
                        </Grid>
                    }
                    <FormsField />
                    <GroupsField />
                    <Grid item xs={6}>
                        <Select
                            disabled={loading}
                            style={{fontSize: 12}} optionsStyle={{fontSize: 12}}
                            label="Jurídico"
                            options={[
                                {value: allString, label: 'Todos'},
                                {value: 'pending', label: 'Em análise'},
                                {value: 'sent', label: 'Entregue'},
                                {value: 'flag1', label: 'Sinalizado pelo Jurídico'},
                                {value: 'flag2', label: 'Aguardando retorno do Jurídico'},
                            ]}
                            value={selectedStatus} onChange={(e) => handleStatusChange(e.target.value)}
                        />
                    </Grid>
                    {
                        selectedFolder?.eSignature &&
                        <Grid item xs={6}>
                            <Select
                                disabled={loading}
                                style={{fontSize: 12}} optionsStyle={{fontSize: 12}}
                                label="Assinatura"
                                options={[
                                    {value: allString, label: 'Todos'},
                                    {value: 'pending', label: 'Aguardando assinaturas'},
                                    {value: 'signed', label: 'Assinados por todos'},
                                ]}
                                value={selectedSignatureStatus} onChange={(e) => handleSignatureStatusChange(e.target.value)}
                            />
                        </Grid>
                    }
                    <ColumnsFields />
                    {
                        talentModuleActive &&
                        <>
                            <Grid item xs={6}>
                                <Input shrinkLabel fullWidth label="Produtora"
                                    value={currentTextFields.productionCompany}
                                    onChange={(e) => setCurrentTextFields(prevState => ({...prevState, productionCompany: e.target.value}))}
                                    onKeyDown={(e) => handleInputKeyDown(e, 'productionCompany')}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Input shrinkLabel fullWidth label="Player"
                                    value={currentTextFields.workPlayer}
                                    onChange={(e) => setCurrentTextFields(prevState => ({...prevState, workPlayer: e.target.value}))}
                                    onKeyDown={(e) => handleInputKeyDown(e, 'workPlayer')}
                                />
                            </Grid>
                        </>
                    }
                </Grid>
            </Box>
        </PopOver>
    );
};

export default CustomFiltersPopOver;