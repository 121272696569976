import React, { useEffect, useState } from 'react';
import { Divider, Grid, IconButton, List, ListItem, Typography } from '@mui/material';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import CenteredCircularProgress from './CenteredCircularProgress';
import Window from './Window';
import { getUserNameById } from '../utils/common';
import { SERVER_PATH } from '../utils/constants';
import { useAppCtxAPI, useAppCtxActiveUser } from '../context/SystemContext';
import { useForm } from '../pages/Form/context/FormContext';
import axios from 'axios';
import moment from 'moment';

const SavedFormsWindow = ({data}) => {
    const {
        savedFormsWindowOpen, setSavedFormsWindowOpen,
        folderId, templates, users,

        set_selectedForm, set_selectedFormId
    } = data;
    const { setLoading, toast } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const { set_savedForm, set_initialForm, set_userForm } = useForm();
    const [forms, setForms] = useState([]);
    const [loadingPage, set_loadingPage] = useState(false);
    const [folderName, set_folderName] = useState('');

    useEffect(() => {
        if(savedFormsWindowOpen){
            set_folderName('');
            if(folderId){
                setLoading(true);
                set_loadingPage(true);
                
                axios.get(SERVER_PATH + `/data/folders/forms?folderId=${folderId}`)
                .then(res => {
                    set_folderName(res.data.name);
                    setForms(res.data.forms);

                    setLoading(false);
                    set_loadingPage(false);
                })
                .catch(err => {
                    setLoading(false);
                    set_loadingPage(false);
                    toast(err.response.data);
                });
            }
        }
    }, [savedFormsWindowOpen, folderId]);

    const handleUseForm = (form) => {
        handleClose();
        set_savedForm(form);

        set_selectedFormId(form.template);

        let userForm = JSON.parse(form.form);
        set_userForm(userForm);
        set_initialForm(userForm);

        let formQuestions = templates.find(item => item._id === form.template);
        set_selectedForm(formQuestions);
    };

    const handleClose = () => {
        setSavedFormsWindowOpen(false);
    };
    
    return (
        <Window
            open={savedFormsWindowOpen} onClose={handleClose}
            title={folderName}
            subTitle="Formulários"
        >
            {
                loadingPage
                ? <CenteredCircularProgress />
                :
                <List>
                    {
                        forms
                        .sort((a, b) => a.timestamp > b.timestamp ? 1 : (a.timestamp < b.timestamp ? -1 : 0))
                        .map((form) => (
                            <React.Fragment key={form._id}>
                                <ListItem button onClick={() => handleUseForm(form)}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs container alignItems="center">
                                            <Grid item xs={6} sm={4} container spacing={1} alignItems="center">
                                                <Grid item>
                                                    <AccessTimeOutlinedIcon />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2">{moment(form.timestamp).format('L')}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={6} sm={4} container spacing={1} alignItems="center">
                                                <Grid item>
                                                    <PersonOutlinedIcon />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2">{getUserNameById(users, form.user)}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs style={{textAlign: 'center'}}>
                                                <Typography variant="h6">{form.name}</Typography>
                                            </Grid>
                                        </Grid>
                                        {
                                            activeUser?.type >= 9 &&
                                            <Grid item>
                                                <IconButton edge="end" size="large">
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Grid>
                                        }
                                    </Grid>
                                </ListItem>
                                <Divider component="li" />
                            </React.Fragment>
                        ))
                    }
                </List>
            }
        </Window>
    );
};

export default SavedFormsWindow;