import { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Grid } from '@mui/material';
import Input from '../Input';
import Select from '../Select';
import Window from '../Window';
import { SERVER_PATH } from '../../utils/constants';

const NewReportWindow = ({open, setOpen, availableClearanceReports, selectedFolder, selectedFolderType, changeAvailableClearanceReports, setSelectedReportId}) => {
    const [loading, setLoading] = useState(false);
    const [episodeNumber, setEpisodeNumber] = useState(1);
    const [reportType, setReportType] = useState('script');
    const [scriptTreatment, setScriptTreatment] = useState(1);
    const [cutVersion, setCutVersion] = useState(1);
    const [importPreviousReportId, setImportPreviousReportId] = useState('none');

    useEffect(() => {
        if(open){
            const lastReport = availableClearanceReports[availableClearanceReports.length - 1];
            if(lastReport){
                setReportType(lastReport.type);
                if(lastReport.type === 'script'){
                    setScriptTreatment(lastReport.scriptTreatment + 1);
                } else {
                    setCutVersion(lastReport.cutVersion + 1);
                }
            }
        }
    }, [open]);

    const getPreviousReportId = () => {
        if(reportType === 'script'){
            const previousReports = availableClearanceReports.filter(report => (selectedFolderType !== 'series' || report.episode === episodeNumber) && report.type === 'script' && report.scriptTreatment < scriptTreatment);
            if(previousReports.length >= 1){
                const lastPreviousReport = previousReports[previousReports.length - 1];
                return lastPreviousReport._id;
            }
        } else {
            let previousReports = availableClearanceReports.filter(report => (selectedFolderType !== 'series' || report.episode === episodeNumber) && report.type === 'cut' && report.cutVersion < cutVersion);
            if(previousReports.length === 0) previousReports = availableClearanceReports.filter(report => (selectedFolderType !== 'series' || report.episode === episodeNumber) && report.type === 'script');
            if(previousReports.length >= 1){
                const lastPreviousReport = previousReports[previousReports.length - 1];
                return lastPreviousReport._id;
            }
        }
        return 'none';
    }
    
    useEffect(() => {
        const previousReportId = getPreviousReportId();
        setImportPreviousReportId(previousReportId);
    }, [reportType, scriptTreatment, cutVersion]);

    const handleReportTypeChange = (newValue) => {
        setReportType(newValue);
        setScriptTreatment(1);
        setCutVersion(1);
    };

    const handleSubmit = async () => {
        setLoading(true);
        const postData = {
            folderId: selectedFolder._id,
            folderType: selectedFolderType,
            episodeNumber,
            reportType,
            scriptTreatment,
            cutVersion,
            importPreviousReportId
        };
        await axios.post(SERVER_PATH + `/data/clearance/reports/new`, postData)
        .then(res => {
            const clearanceReports = res.data;
            changeAvailableClearanceReports(clearanceReports);
            const newReport = clearanceReports[clearanceReports.length - 1];
            setSelectedReportId(newReport._id);
            handleClose();
        })
        .catch(err => {});
        setLoading(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Window open={open} onClose={handleClose}
            title="Novo relatório"
            submitButtonText="Novo relatório"
            handleSubmitButtonClick={handleSubmit}
            submitButtonLoading={loading}
        >
            <Grid container spacing={1}>
                {
                    selectedFolderType === 'series' &&
                    <Grid item xs={9} sm={4} md={1}>
                        <Input
                            fullWidth
                            type="number"
                            inputProps={{min: 1, step: 1}}
                            label="Episódio"
                            value={episodeNumber} onChange={(e) => setEpisodeNumber(e.target.value)}
                        />
                    </Grid>
                }
                <Grid item xs={12} sm={6} md={2}>
                    <Select
                        label="Tipo"
                        options={[{value: 'script', label: 'Roteiro'}, {value: 'cut', label: 'Corte'}]}
                        value={reportType} onChange={(e) => handleReportTypeChange(e.target.value)}
                    />
                </Grid>
                <Grid item xs={9} sm={4} md={1}>
                    {
                        reportType === 'script'
                        ?
                        <Input
                            fullWidth
                            type="number"
                            inputProps={{min: 1, step: 1}}
                            label="Tratamento"
                            value={scriptTreatment} onChange={(e) => setScriptTreatment(e.target.value)}
                        />
                        :
                        <Input
                            fullWidth
                            type="number"
                            inputProps={{min: 1, step: 1}}
                            label="Versão"
                            value={cutVersion} onChange={(e) => setCutVersion(e.target.value)}
                        />
                    }
                </Grid>
                <Grid item xs>
                    <Select
                        label="Importar anotações"
                        options={[
                            {value: 'none', label: 'Não (relatório em branco)'},
                            ...availableClearanceReports.map(report => ({value: report._id, label: `${selectedFolderType === 'series' ? `Ep. ${report.episode}, ` : ''}${report.type === 'script' ? `Tratamento ${report.scriptTreatment}` : `Corte ${report.cutVersion}`}`}))
                        ]}
                        value={importPreviousReportId} onChange={(e) => setImportPreviousReportId(e.target.value)}
                    />
                </Grid>
            </Grid>
            
            
        </Window>
    );
};

export default NewReportWindow;