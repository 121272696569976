import FolderIcon from '@mui/icons-material/Folder';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import PeopleIcon from '@mui/icons-material/People';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useAppCtxActiveUser, useAppCtxSidenavOpen } from '../../../context/SystemContext';
import { useManagementCtxAPI } from '../../../context/ManagementContext';

const useMenuItems = () => {
    const activeUser = useAppCtxActiveUser();
    const sidenavOpen = useAppCtxSidenavOpen();
    const { setSidenavMobileOpen } = useManagementCtxAPI();
    
    const handleCloseMobileSidenav = () => {
        setSidenavMobileOpen(false);
    };

    const iconColor = !sidenavOpen ? '#b2bfdc' : undefined;

    const menuItems = [
        {
            label: 'Clientes',
            path: `/admin/clientes`,
            highlight: [{ path: '/' }],
            icon: <FolderIcon htmlColor={iconColor} />,
            mobileIcon: <FolderOutlinedIcon />,
            didNavigateAction: handleCloseMobileSidenav,
            visible: true
        },
        {
            label: 'Pessoas',
            path: `/admin/pessoas`,
            icon: <PeopleIcon htmlColor={iconColor} />,
            mobileIcon: <PeopleOutlinedIcon />,
            didNavigateAction: handleCloseMobileSidenav,
            visible: true
        },
        {
            label: 'Sistema',
            path: `/admin/admin`,
            icon: <SettingsIcon htmlColor={iconColor} />,
            mobileIcon: <SettingsOutlinedIcon />,
            didNavigateAction: handleCloseMobileSidenav,
            visible: !!activeUser.allowAppManagement
        },
    ];

    return menuItems;
};

export default useMenuItems;