import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';

import SearchFieldContainer from '../../../SearchFieldContainer';

import {
    MRT_GlobalFilterTextField,
    MRT_ShowHideColumnsButton,
    MRT_ToggleFiltersButton,
} from 'material-react-table';

const Table2CustomTopToolbar = (props) => {
    const { enableHiding, buttons = [], table } = props;

    return (
        <Box
            sx={() => ({
                height: '56px',
                overflowX: 'auto',
                overflowY: 'hidden',
            })}
        >
            <Grid
                container spacing={1} justifyContent="flex-start" wrap="nowrap"
                sx={{
                    margin: 0,
                    minWidth: 'min-content',
                    padding: '0 8px 0 8px',
                }}
            >
                <Grid
                    container spacing={1} alignItems="center" wrap="nowrap"
                    sx={{
                        flexBasis: 'auto',
                        flexShrink: 0,
                    }}
                >
                    <SearchFieldContainer>
                        <MRT_GlobalFilterTextField table={table} />
                    </SearchFieldContainer>
                    <Grid
                        sx={{
                            flexShrink: 0,
                        }}
                    >
                        <MRT_ToggleFiltersButton table={table} />
                    </Grid>
                    {
                        enableHiding &&
                        <Grid
                            sx={{
                                flexShrink: 0,
                            }}
                        >
                            <MRT_ShowHideColumnsButton table={table} />
                        </Grid>
                    }
                </Grid>
                <Grid
                    xs container spacing={1} alignItems="center" justifyContent="flex-end" wrap="nowrap"
                    sx={{
                        flexBasis: 'auto',
                        flexGrow: 1,
                        flexShrink: 0,
                    }}
                >
                    {
                        buttons
                        .filter(button => !!button)
                        .map((button, buttonIndex) => (
                            <Grid
                                key={buttonIndex}
                                sx={{
                                    flexShrink: 0,
                                }}
                            >
                                {button}
                            </Grid>
                        ))
                    }
                </Grid>
            </Grid>
        </Box>
    );
};

export default Table2CustomTopToolbar;