import { useLayoutEffect, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import update from 'immutability-helper';
import { v4 as uuidv4 } from 'uuid';

const classes = {
    dragHandle: {
        opacity: 0
    },
    dragItem: {
        '&:hover .dragHandle': {
            opacity: 0.35
        },
        '&:active .dragHandle': {
            opacity: 0.35
        }
    },
};

const NewEffect = ({handleChange, setDisabled}) => {
    const displayOptions = [
        {value: 'singleLine', label: 'Mostrar em texto corrido'},
        {value: 'lowerAlphaList', label: 'Mostrar em lista'},
    ];
    const rowRefs = useRef([]);
    const shouldFocusLastRow = useRef(null);
    const defaultRowValue = { label: 'Linha 1' };
    const columnRefs = useRef([]);
    const shouldFocusLastColumn = useRef(null);
    const defaultColumnValue = { label: 'Coluna 1' };
    const [value, setValue] = useState({ question: '', rows: [], columns: [], column: '', display: 'lowerAlphaList' });

    useEffect(() => {
        setValue(prevState => {
            const nextState = {...prevState};
            nextState.rows = [{...defaultRowValue, id: uuidv4()}];
            nextState.columns = [{...defaultColumnValue, id: uuidv4()}];
            return nextState;
        });
    }, []);

    const checkDisabled = () => {
        if(!value.question || value.rows.length === 0 || value.columns.length === 0 || !value.column) return true;
        value.rows.forEach(row => {
            if(!row.label) return true;
        });
        value.columns.forEach(column => {
            if(!column.label) return true;
        });
        return false;
    };
    
    useEffect(() => {
        handleChange(value);
        if(setDisabled){
            const isDisabled = checkDisabled();
            setDisabled(isDisabled);
        }
    }, [value]);

    useLayoutEffect(() => {
        if(shouldFocusLastRow.current){
            shouldFocusLastRow.current = false;
            const rowInputs = rowRefs.current.filter(element => !!element);
            const lastElement = rowInputs[rowInputs.length - 1];
            if(lastElement){
                lastElement.focus();
                lastElement.select();
            }
        }
    }, [value.rows]);

    useLayoutEffect(() => {
        if(shouldFocusLastColumn.current){
            shouldFocusLastColumn.current = false;
            const columnInputs = columnRefs.current.filter(element => !!element);
            const lastElement = columnInputs[columnInputs.length - 1];
            if(lastElement){
                lastElement.focus();
                lastElement.select();
            }
        }
    }, [value.columns]);

    const handleChangeRow = (rowIndex, newValue) => {
        setValue(prevState => ({
            ...prevState,
            rows: [
                ...prevState.rows.slice(0, rowIndex),
                {...prevState.rows[rowIndex], label: newValue},
                ...prevState.rows.slice(rowIndex + 1),
            ]
        }));
    };

    const handleRowBlur = (rowIndex) => {
        if(!value.rows[rowIndex].label){
            setValue(prevState => ({
                ...prevState,
                rows: [
                    ...prevState.rows.slice(0, rowIndex),
                    {...prevState.rows[rowIndex], label: `Linha ${rowIndex + 1}`},
                    ...prevState.rows.slice(rowIndex + 1),
                ]
            }));
        }
    };
    
    const handleAddRowClick = () => {
        shouldFocusLastRow.current = true;
        setValue(prevState => ({
            ...prevState,
            rows: [
                ...prevState.rows,
                {...defaultRowValue, label: `Linha ${value.rows.length + 1}`, id: uuidv4()}
            ]
        }));
    };

    const handleDeleteRow = (rowIndex) => {
        setValue(prevState => ({
            ...prevState,
            rows: [
                ...prevState.rows.slice(0, rowIndex),
                ...prevState.rows.slice(rowIndex + 1),
            ]
        }));
    };

    const handleChangeColumn = (columnIndex, newValue) => {
        setValue(prevState => ({
            ...prevState,
            columns: [
                ...prevState.columns.slice(0, columnIndex),
                {...prevState.columns[columnIndex], label: newValue},
                ...prevState.columns.slice(columnIndex + 1),
            ]
        }));
    };

    const handleColumnBlur = (columnIndex) => {
        if(!value.columns[columnIndex].label){
            setValue(prevState => ({
                ...prevState,
                columns: [
                    ...prevState.columns.slice(0, columnIndex),
                    {...prevState.columns[columnIndex], label: `Coluna ${columnIndex + 1}`},
                    ...prevState.columns.slice(columnIndex + 1),
                ]
            }));
        }
    };

    const handleAddColumnClick = () => {
        shouldFocusLastColumn.current = true;
        setValue(prevState => ({
            ...prevState,
            columns: [
                ...prevState.columns,
                {...defaultColumnValue, label: `Coluna ${value.columns.length + 1}`, id: uuidv4()}
            ]
        }));
    };

    const handleDeleteColumn = (columnIndex) => {
        setValue(prevState => ({
            ...prevState,
            columns: [
                ...prevState.columns.slice(0, columnIndex),
                ...prevState.columns.slice(columnIndex + 1),
            ]
        }));
    };

    const handleRowDragEnd = (data) => {
        if(data.destination){
            setValue(prevState => ({
                ...prevState,
                rows: update(prevState.rows, {
                    $splice: [
                        [data.source.index, 1],
                        [data.destination.index, 0, prevState.rows[data.source.index]],
                    ],
                })
            }));
        }
    };

    const handleColumnDragEnd = (data) => {
        if(data.destination){
            setValue(prevState => ({
                ...prevState,
                columns: update(prevState.columns, {
                    $splice: [
                        [data.source.index, 1],
                        [data.destination.index, 0, prevState.columns[data.source.index]],
                    ],
                })
            }));
        }
    };

    return (
        <Box>
            <Box mb={2}>
                <Box mb={1}>
                    <Typography variant="h6">Pergunta</Typography>
                </Box>
                <TextField
                    variant="standard"
                    fullWidth
                    placeholder="Sua pergunta"
                    value={value.question}
                    onChange={(e) => setValue(prevState => ({...prevState, question: e.target.value}))} />
            </Box>
            <Box mb={1}>
                <Grid container spacing={1} alignItems="flex-start">
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item style={{visibility: 'hidden'}}>
                                <DragIndicatorIcon />
                            </Grid>
                            <Grid item>
                                <Typography variant="h6">Linhas</Typography>
                            </Grid>
                        </Grid>
                        <DragDropContext onDragEnd={handleRowDragEnd}>
                            <Droppable droppableId={'radio-grid-question-droppable-rows-container'}>
                                {(provided) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {
                                            value.rows.map((row, rowIndex) => {
                                                return (
                                                    <Draggable
                                                        key={row.id.toString()}
                                                        draggableId={row.id.toString()}
                                                        index={rowIndex}
                                                        isDragDisabled={false}
                                                    >
                                                        {(provided) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps}>
                                                                
                                                                <Grid key={rowIndex} container spacing={1} alignItems="center" sx={classes.dragItem}>
                                                                    <Grid item {...provided.dragHandleProps}>
                                                                        <DragIndicatorIcon sx={classes.dragHandle} className="dragHandle" />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography variant="body1">{rowIndex + 1}.</Typography>
                                                                    </Grid>
                                                                    <Grid item xs>
                                                                        <TextField
                                                                            variant="standard"
                                                                            fullWidth
                                                                            inputRef={el => (rowRefs.current[rowIndex] = el)}
                                                                            value={row.label}
                                                                            onChange={(e) => handleChangeRow(rowIndex, e.target.value)}
                                                                            onBlur={() => handleRowBlur(rowIndex)} />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <IconButton size="small" onClick={() => handleDeleteRow(rowIndex)}><ClearIcon /></IconButton>
                                                                    </Grid>
                                                                </Grid>

                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            })
                                        }

                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item style={{visibility: 'hidden'}}>
                                <DragIndicatorIcon />
                            </Grid>
                            <Grid item>
                                <Typography variant="body1">{value.rows.length + 1}.</Typography>
                            </Grid>
                            <Grid item xs>
                                <TextField
                                    variant="standard"
                                    defaultValue="Adicionar linha"
                                    inputProps={{ style: { color: 'rgba(0, 0, 0, 0.54)' } }}
                                    onFocus={handleAddRowClick} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item style={{visibility: 'hidden'}}>
                                <DragIndicatorIcon />
                            </Grid>
                            <Grid item>
                                <Typography variant="h6">Colunas</Typography>
                            </Grid>
                        </Grid>
                        <DragDropContext onDragEnd={handleColumnDragEnd}>
                            <Droppable droppableId={'radio-grid-question-droppable-columns-container'}>
                                {(provided) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {
                                            value.columns.map((column, columnIndex) => {
                                                return (
                                                    <Draggable
                                                        key={column.id.toString()}
                                                        draggableId={column.id.toString()}
                                                        index={columnIndex}
                                                        isDragDisabled={false}
                                                    >
                                                        {(provided) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps}>

                                                                <Grid container spacing={1} alignItems="center" sx={classes.dragItem}>
                                                                    <Grid item {...provided.dragHandleProps}>
                                                                        <DragIndicatorIcon sx={classes.dragHandle} className="dragHandle" />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <RadioButtonUncheckedIcon style={{color: 'rgba(0, 0, 0, 0.35)'}} />
                                                                    </Grid>
                                                                    <Grid item xs>
                                                                        <TextField
                                                                            variant="standard"
                                                                            fullWidth
                                                                            inputRef={el => (columnRefs.current[columnIndex] = el)}
                                                                            value={column.label}
                                                                            onChange={(e) => handleChangeColumn(columnIndex, e.target.value)}
                                                                            onBlur={() => handleColumnBlur(columnIndex)} />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <IconButton size="small" onClick={() => handleDeleteColumn(columnIndex)}><ClearIcon /></IconButton>
                                                                    </Grid>
                                                                </Grid>

                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            })
                                        }

                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item style={{visibility: 'hidden'}}>
                                <DragIndicatorIcon />
                            </Grid>
                            <Grid item>
                                <RadioButtonUncheckedIcon style={{color: 'rgba(0, 0, 0, 0.35)'}} />
                            </Grid>
                            <Grid item xs>
                                <TextField
                                    variant="standard"
                                    defaultValue="Adicionar coluna"
                                    inputProps={{ style: { color: 'rgba(0, 0, 0, 0.54)' } }}
                                    onFocus={handleAddColumnClick} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Divider />
            <RadioGroup
                value={value.column}
                onChange={(e) => setValue(prevState => ({...prevState, column: e.target.value}))}
            >
                <Grid container spacing={1} alignItems="center">
                    {
                        value.columns.map((column, columnIndex) => (
                            <Grid item key={columnIndex}>
                                <FormControlLabel
                                    value={column.id}
                                    control={<Radio />}
                                    label={`Mostrar coluna "${column.label}" no documento`}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            </RadioGroup>
            <Divider />
            <RadioGroup
                value={value.display}
                onChange={(e) => setValue(prevState => ({...prevState, display: e.target.value}))}
            >
                <Grid container spacing={1} alignItems="center">
                    {
                        displayOptions.map((option, optionIndex) => (
                            <Grid item key={optionIndex}>
                                <FormControlLabel
                                    value={option.value}
                                    control={<Radio />}
                                    label={option.label}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            </RadioGroup>
        </Box>
    );
};

export default NewEffect;