import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PopOver from './PopOver';
import { updateStateChangeArrayItemWith_id } from '../utils/common';
import { ERROR_MESSAGE_CHANGES_UNDONE } from '../utils/constants';
import { useAppCtxAPI, useAppCtxActiveUser } from '../context/SystemContext';
import { useDocumentsAPI, useDocumentsCtxAddDocumentStarredInformationView } from '../context/DocumentsContext';
import moment from 'moment';

const NewStarredInfoPopOver = ({list, data}) => {
    const { setLoading, toast, update } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const { hideAddDocumentStarredInformationView } = useDocumentsAPI();
    const { addDocumentStarredInformationViewAnchorPosition, addDocumentStarredInformationViewOpen, addDocumentStarredInformationViewSelectedDocument: selectedDocument } = useDocumentsCtxAddDocumentStarredInformationView();
    const { setDocs } = data;
    const [infoKey, set_infoKey] = useState('');
    const [infoValue, set_infoValue] = useState('');

    useEffect(() => {
        set_infoKey('');
        set_infoValue('');
    }, [addDocumentStarredInformationViewOpen]);

    const handle_submit = async () => {
        handleClose();
        setLoading(true);
        let recordCopy = {...selectedDocument};
        let newInfo = {key: infoKey, value: infoValue, timestamp: moment().format(), user: activeUser._id};
        let infoId = 1;
        while (list.find(i => i.id === infoId)) {
            infoId++;
        }
        newInfo.id = infoId;
        let newValue = [...list, newInfo];
        updateStateChangeArrayItemWith_id({...selectedDocument, info: newValue}, setDocs);
        try {
            await update('Documents', selectedDocument._id, {info: newValue});
            setLoading(false);
        } catch (error) {
            updateStateChangeArrayItemWith_id(recordCopy, setDocs);
            setLoading(false);
            toast(ERROR_MESSAGE_CHANGES_UNDONE);
        }
    };
    const handleClose = () => {
        hideAddDocumentStarredInformationView();
    };

    return (
        <PopOver open={addDocumentStarredInformationViewOpen} onClose={handleClose} anchorPosition={addDocumentStarredInformationViewAnchorPosition}
            transformOrigin={{vertical: 'center', horizontal: 'right'}}
            ellipsisTitle={selectedDocument?.name}
            title="Cadastrar informação em destaque"
            handleSubmitButtonClick={handle_submit}
            firstBoxStyle={{ padding: 8 }}
        >
            <Box>
                <Typography variant="body2">Informações em destaque ficam visíveis na linha do documento para que não seja necessário acessá-lo para lembrar de informações importantes.</Typography>
            </Box>
            <Box mb={2}>
                <TextField
                    variant="standard"
                    fullWidth
                    label="Título"
                    value={infoKey}
                    onChange={(e) => set_infoKey(e.target.value)} />
            </Box>
            <Box mb={2}>
                <TextField
                    variant="standard"
                    fullWidth
                    multiline
                    label="Informação"
                    value={infoValue}
                    onChange={(e) => set_infoValue(e.target.value)} />
            </Box>
        </PopOver>
    );
};

export default NewStarredInfoPopOver;