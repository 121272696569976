import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useAppCtxAPI, useAppCtxFloatingAlert } from '../context/SystemContext';

const FloatingAlert = () => {
    const { setFloatingAlertVisible } = useAppCtxAPI();
    const { floatingAlertVisible, floatingAlertProps } = useAppCtxFloatingAlert();
    return (
        <Snackbar
            anchorOrigin={
                {
                    vertical: 'top',
                    horizontal: 'center',
                }
            }
            open={floatingAlertVisible}
            onClose={() => setFloatingAlertVisible(false)}
        >
            <Alert variant="filled" severity={floatingAlertProps.severity} onClose={() => setFloatingAlertVisible(false)}>
                {floatingAlertProps.message}
            </Alert>
        </Snackbar>
    );
}

export default FloatingAlert;