import { Grid, IconButton } from '@mui/material';
import Tooltip from './Tooltip';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ChipWithMenu from '../components/ChipWithMenu';
import { useAppCtxActiveUser } from '../context/SystemContext';

const UsersSelector = ({includeNone, label, loading, selectedUser, setSelectedUser, users}) => {
    const activeUser = useAppCtxActiveUser();
    let options = [{value: '~all;', label: 'Todos'}];
    if(includeNone) options.push({value: '~none;', label: 'Nenhum'});
    if(users) options = [...options, ...users.map(user => ({value: user._id, label: user.screenName}))];

    return (
        <Grid container spacing={1} wrap="nowrap">
            <Grid item> 
                <ChipWithMenu
                    initialValue="~all;"
                    label={label}
                    onChange={setSelectedUser}
                    options={options}
                    value={selectedUser}
                />
            </Grid>
            <Grid item xs container alignItems="flex-end" justifyContent="flex-start" wrap="nowrap">
                {
                    includeNone &&
                    <Grid item>
                        <Tooltip text="Nenhum">
                            <span>
                                <IconButton disabled={loading} size="small" color="primary" onClick={() => setSelectedUser('~none;')}><PersonOutlineIcon /></IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                }
                <Grid item>
                    <Tooltip text={activeUser?.screenName}>
                        <span>
                            <IconButton disabled={loading} size="small" color="primary" onClick={() => setSelectedUser(activeUser?._id)}><PersonIcon /></IconButton>
                        </span>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip text="Todos">
                        <span>
                            <IconButton disabled={loading} size="small" color="primary" onClick={() => setSelectedUser('~all;')}><PeopleIcon /></IconButton>
                        </span>
                    </Tooltip>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default UsersSelector;