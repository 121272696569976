import { Route, Routes } from 'react-router-dom';
import Client from './Client';
import ConnectedOperator from './ConnectedOperator';
import Dashboards from './Dashboards';
import Management from './Management';
import PageNotFound from './PageNotFound';
import { useAppCtxActiveUser } from '../context/SystemContext';
import { ClientProvider } from '../context/ClientContext';
import { DocumentsProvider } from '../context/DocumentsContext';

const ActiveUserLoggedIn = () => {
    const activeUser = useAppCtxActiveUser();

    if(!activeUser) return null;

    return (
        <Routes>
            <Route path="/paineis" element={<Dashboards />} />
            <Route path="/admin/*" element={<Management />} />
            <Route path="/:workspaceId/juridico/*" element={<ClientProvider><DocumentsProvider><ConnectedOperator /></DocumentsProvider></ClientProvider>} />
            <Route path="/:workspaceId/pastas/*" element={<ClientProvider><DocumentsProvider><Client /></DocumentsProvider></ClientProvider>} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
};

export default ActiveUserLoggedIn;