const paymentSchedule = {
    createEffect: ({ effectSettings, newEffectId }) => {
        const newEffect = {
            id: newEffectId,
            type: effectSettings.type,
            question: effectSettings.question || effectSettings.label || '',
            label: effectSettings.label || effectSettings.question
        };
        return { newEffect };
    },
    newEffectWindowHandleEffectTypeChange: () => {
        const data = {};
        data.question = '';
        return data;
    },
    checkValidation: (data) => {
        let questionValid = false;
        if(data){
            questionValid = true;
            const { choice } = data;
            if(!choice) questionValid = false;
        }
        return questionValid;
    }
};

export {
    paymentSchedule
}