import { Box, Grid, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import TitleIcon from '@mui/icons-material/Title';
import Tooltip from '../../../../../../components/Tooltip';
import { allEffectTypes } from '../../../../../../utils/constants';
import { useFormEditor } from '../FormEditorContext';
import { Draggable } from 'react-beautiful-dnd';

const FormEditorQuestion = ({handleFormEditorQuestionWindowOpenButtonPress, question, questionIndex}) => {
    const { set_clickedQuestion, set_newTitleDialogOpen } = useFormEditor();
    
    const getQuestionType = (type) => {
        return allEffectTypes.find(i => i.value === type)?.label;
    };
    
    const handleNewTitleButtonClick = (question) => {
        set_clickedQuestion(question);
        set_newTitleDialogOpen(true);
    };

    return (
        <div>
            <Draggable
                draggableId={question.id.toString()}
                index={questionIndex}
                isDragDisabled={false}
            >
                {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <Box
                            sx={{
                                backgroundColor: '#FFF5EE',
                                border: '1px solid #E8E8E8',
                                borderRadius: '3px',
                                marginBottom: '4px',
                                padding: '4px',
                            }}
                            style={
                                snapshot?.isDragging
                                ? { backgroundColor: '#434343', borderColor: '#434343', color: '#f5f5f5' }
                                : null
                            }
                        >
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs>
                                    <Typography variant={question.type === 'heading' ? 'h6' : 'body1'}>{question.name}<Typography variant="body2" component="span"> ({getQuestionType(question.type)})</Typography></Typography>
                                </Grid>
                                <Grid item>
                                    <Tooltip text="Novo título acima">
                                        <IconButton size="small" onClick={() => handleNewTitleButtonClick(question)}><TitleIcon /></IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item>
                                    <IconButton size="small"
                                        onClick={() => handleFormEditorQuestionWindowOpenButtonPress(question)}
                                    ><EditIcon /></IconButton>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                )}
            </Draggable>
        </div>
    );
}

export default FormEditorQuestion;