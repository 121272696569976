import { useState } from 'react';
import FormDialog from '../../../components/FormDialog';
import { useForm } from '../context/FormContext';

const SavedFormNameDialog = ({open, setOpen, handleOk}) => {
    const { savedForm } = useForm();
    const [textFieldValue, set_textFieldValue] = useState('');

    const getSavedFormName = (form) => {
        if(form) return form.name;
        return null;
    }

    return (
        <FormDialog
            id="saved-form-name-dialog"
            open={open} onEnter={() => set_textFieldValue(getSavedFormName(savedForm) || '')} onClose={() => setOpen(false)}
            title="Concluir depois" text={`Escolha um nome para salvar o formulário.${savedForm ? ` Isso sobrescreverá o formulário "${savedForm.name}".` : ''}`} textFieldLabel="Nome"
            textFieldValue={textFieldValue} textFieldOnChange={(e) => set_textFieldValue(e.target.value)}
            okButtonOnClick={() => handleOk(textFieldValue)}
            okButtonDisabled={textFieldValue === ''}
        />
    );
}

export default SavedFormNameDialog;