import React, { useState } from 'react';
import { TextField } from '@mui/material';

const DocumentUrlField = ({addDocumentForm, checkSubmitButtonDisabled}) => {
    const [documentUrl, setDocumentUrl] = useState('');
    const handleChange = (newValue) => {
        setDocumentUrl(newValue);
        addDocumentForm.current.documentUrl = newValue;

        checkSubmitButtonDisabled();
    };
    return (
        <TextField
            variant="standard"
            fullWidth
            label="Link do documento"
            value={documentUrl}
            onChange={(e) => handleChange(e.target.value)} />
    );
};

export default DocumentUrlField;