const CNAEs = [
    { code: 3250706, description: 'Serviços de prótese dentária' },
    { code: 3250706, description: 'Serviços de prótese dentária' },
    { code: 3250709, description: 'Serviço de laboratório óptico' },
    { code: 3250709, description: 'Serviço de laboratório óptico' },
    { code: 4399101, description: 'Administração de obras' },
    { code: 4512901, description: 'Representantes comerciais e agentes do comércio de veículos automotores' },
    { code: 4530702, description: 'Comércio por atacado de pneumáticos e câmaras-de-ar' },
    { code: 4530703, description: 'Comércio a varejo de peças e acessórios novos para veículos automotores' },
    { code: 4530704, description: 'Comércio a varejo de peças e acessórios usados para veículos automotores' },
    { code: 4530705, description: 'Comércio a varejo de pneumáticos e câmaras-de-ar' },
    { code: 4530706, description: 'Representantes comerciais e agentes do comércio de peças e acessórios novos e usados para veículos automotores' },
    { code: 4541202, description: 'Comércio por atacado de peças e acessórios para motocicletas e motonetas' },
    { code: 4541206, description: 'Comércio a varejo de peças e acessórios novos para motocicletas e motonetas' },
    { code: 4541207, description: 'Comércio a varejo de peças e acessórios usados para motocicletas e motonetas' },
    { code: 4542101, description: 'Representantes comerciais e agentes do comércio de motocicletas e motonetas, peças e acessórios' },
    { code: 4611700, description: 'Representantes comerciais e agentes do comércio de matérias-primas agrícolas e animais vivos' },
    { code: 4612500, description: 'Representantes comerciais e agentes do comércio de combustíveis, minerais, produtos siderúrgicos e químicos' },
    { code: 4613300, description: 'Representantes comerciais e agentes do comércio de madeira, material de construção e ferragens' },
    { code: 4614100, description: 'Representantes comerciais e agentes do comércio de máquinas, equipamentos, embarcações e aeronaves' },
    { code: 4615000, description: 'Representantes comerciais e agentes do comércio de eletrodomésticos, móveis e artigos de uso doméstico' },
    { code: 4616800, description: 'Representantes comerciais e agentes do comércio de têxteis, vestuário, calçados e artigos de viagem' },
    { code: 4617600, description: 'Representantes comerciais e agentes do comércio de produtos alimentícios, bebidas e fumo' },
    { code: 4618401, description: 'Representantes comerciais e agentes do comércio de medicamentos, cosméticos e produtos de perfumaria' },
    { code: 4618402, description: 'Representantes comerciais e agentes do comércio de instrumentos e materiais odonto-médico-hospitalares' },
    { code: 4618403, description: 'Representantes comerciais e agentes do comércio de jornais, revistas e outras publicações' },
    { code: 4618499, description: 'Outros representantes comerciais e agentes do comércio especializado em produtos não especificados anteriormente' },
    { code: 4619200, description: 'Representantes comerciais e agentes do comércio de mercadorias em geral não especializado' },
    { code: 4635401, description: 'Comércio atacadista de água mineral' },
    { code: 4635402, description: 'Comércio atacadista de cerveja, chope e refrigerante' },
    { code: 4637104, description: 'Comércio atacadista de pães, bolos, biscoitos e similares' },
    { code: 4637105, description: 'Comércio atacadista de massas alimentícias' },
    { code: 4637106, description: 'Comércio atacadista de sorvetes' },
    { code: 4637107, description: 'Comércio atacadista de chocolates, confeitos, balas, bombons e semelhantes' },
    { code: 4637199, description: 'Comércio atacadista especializado em outros produtos alimentícios não especificados anteriormente' },
    { code: 4639701, description: 'Comércio atacadista de produtos alimentícios em geral' },
    { code: 4641901, description: 'Comércio atacadista de tecidos' },
    { code: 4641902, description: 'Comércio atacadista de artigos de cama, mesa e banho' },
    { code: 4641903, description: 'Comércio atacadista de artigos de armarinho' },
    { code: 4642701, description: 'Comércio atacadista de artigos do vestuário e acessórios, exceto profissionais e de segurança' },
    { code: 4642702, description: 'Comércio atacadista de roupas e acessórios para uso profissional e de segurança do trabalho' },
    { code: 4643501, description: 'Comércio atacadista de calçados' },
    { code: 4643502, description: 'Comércio atacadista de bolsas, malas e artigos de viagem' },
    { code: 4646001, description: 'Comércio atacadista de cosméticos e produtos de perfumaria' },
    { code: 4646002, description: 'Comércio atacadista de produtos de higiene pessoal' },
    { code: 4647801, description: 'Comércio atacadista de artigos de escritório e de papelaria' },
    { code: 4647802, description: 'Comércio atacadista de livros, jornais e outras publicações' },
    { code: 4649401, description: 'Comércio atacadista de equipamentos elétricos de uso pessoal e doméstico' },
    { code: 4649402, description: 'Comércio atacadista de aparelhos eletrônicos de uso pessoal e doméstico' },
    { code: 4649403, description: 'Comércio atacadista de bicicletas, triciclos e outros veículos recreativos' },
    { code: 4649404, description: 'Comércio atacadista de móveis e artigos de colchoaria' },
    { code: 4649405, description: 'Comércio atacadista de artigos de tapeçaria persianas e cortinas' },
    { code: 4649406, description: 'Comércio atacadista de lustres, luminárias e abajures' },
    { code: 4649407, description: 'Comércio atacadista de filmes, cds, dvds, fitas e discos' },
    { code: 4649408, description: 'Comércio atacadista de produtos de higiene, limpeza e conservação domiciliar' },
    { code: 4649409, description: 'Comércio atacadista de produtos de higiene, limpeza e conservação domiciliar, com atividade de fracionamento e acondicionamento associada' },
    { code: 4649410, description: 'Comércio atacadista de jóias, relógios e bijuterias, inclusive pedras preciosas e semipreciosas lapidadas' },
    { code: 4649499, description: 'Comércio atacadista de outros equipamentos e artigos de uso pessoal e doméstico não especificados anteriormente' },
    { code: 4651601, description: 'Comércio atacadista de equipamentos de informática' },
    { code: 4651602, description: 'Comércio atacadista de suprimentos para informática' },
    { code: 4652400, description: 'Comércio atacadista de componentes eletrônicos e equipamentos de telefonia e comunicação' },
    { code: 4679603, description: 'Comércio atacadista de vidros, espelhos e vitrais' },
    { code: 4686902, description: 'Comércio atacadista de embalagens' },
    { code: 4691500, description: 'Comércio atacadista de mercadorias em geral, com predominância de produtos alimentícios' },
    { code: 4693100, description: 'Comércio atacadista de mercadorias em geral, sem predominância de alimentos ou de insumos agropecuários' },
    { code: 4711301, description: 'Comércio varejista de mercadorias em geral, com predominância de produtos alimentícios - hipermercados' },
    { code: 4711302, description: 'Comércio varejista de mercadorias em geral, com predominância de produtos alimentícios - supermercados' },
    { code: 4712100, description: 'Comércio varejista de mercadorias em geral, com predominância de produtos alimentícios - minimercados, mercearias e armazéns' },
    { code: 4713002, description: 'Lojas de variedades, exceto lojas de departamentos ou magazines' },
    { code: 4713004, description: 'Lojas de departamentos ou magazines, exceto lojas francas (duty free)' },
    { code: 4721102, description: 'Padaria e confeitaria com predominância de revenda' },
    { code: 4721103, description: 'Comércio varejista de laticínios e frios' },
    { code: 4721104, description: 'Comércio varejista de doces, balas, bombons e semelhantes' },
    { code: 4723700, description: 'Comércio varejista de bebidas' },
    { code: 4724500, description: 'Comércio varejista de hortifrutigranjeiros' },
    { code: 4729601, description: 'Tabacaria' },
    { code: 4729699, description: 'Comércio varejista de produtos alimentícios em geral ou especializado em produtos alimentícios não especificados anteriormente' },
    { code: 4743100, description: 'Comércio varejista de vidros' },
    { code: 4744002, description: 'Comércio varejista de madeira e artefatos' },
    { code: 4751201, description: 'Comércio varejista especializado de equipamentos e suprimentos de informática' },
    { code: 4751202, description: 'Recarga de cartuchos para equipamentos de informática' },
    { code: 4752100, description: 'Comércio varejista especializado de equipamentos de telefonia e comunicação' },
    { code: 4753900, description: 'Comércio varejista especializado de eletrodomésticos e equipamentos de áudio e vídeo' },
    { code: 4754701, description: 'Comércio varejista de móveis' },
    { code: 4754702, description: 'Comércio varejista de artigos de colchoaria' },
    { code: 4754703, description: 'Comércio varejista de artigos de iluminação' },
    { code: 4755501, description: 'Comércio varejista de tecidos' },
    { code: 4755502, description: 'Comércio varejista de artigos de armarinho' },
    { code: 4755503, description: 'Comércio varejista de artigos de cama, mesa e banho' },
    { code: 4756300, description: 'Comércio varejista especializado de instrumentos musicais e acessórios' },
    { code: 4757100, description: 'Comércio varejista especializado de peças e acessórios para aparelhos eletroeletrônicos para uso doméstico, exceto informática e comunicação' },
    { code: 4759801, description: 'Comércio varejista de artigos de tapeçaria, cortinas e persianas' },
    { code: 4759899, description: 'Comércio varejista de outros artigos de uso doméstico não especificados anteriormente' },
    { code: 4761001, description: 'Comércio varejista de livros' },
    { code: 4761002, description: 'Comércio varejista de jornais e revistas' },
    { code: 4761003, description: 'Comércio varejista de artigos de papelaria' },
    { code: 4762800, description: 'Comércio varejista de discos, cds, dvds e fitas' },
    { code: 4763601, description: 'Comércio varejista de brinquedos e artigos recreativos' },
    { code: 4763602, description: 'Comércio varejista de artigos esportivos' },
    { code: 4763603, description: 'Comércio varejista de bicicletas e triciclos peças e acessórios' },
    { code: 4763604, description: 'Comércio varejista de artigos de caça, pesca e camping' },
    { code: 4772500, description: 'Comércio varejista de cosméticos, produtos de perfumaria e de higiene pessoal' },
    { code: 4774100, description: 'Comércio varejista de artigos de óptica' },
    { code: 4781400, description: 'Comércio varejista de artigos do vestuário e acessórios' },
    { code: 4782201, description: 'Comércio varejista de calçados' },
    { code: 4782202, description: 'Comércio varejista de artigos de viagem' },
    { code: 4783102, description: 'Comércio varejista de artigos de relojoaria' },
    { code: 4785701, description: 'Comércio varejista de antigüidades' },
    { code: 4785799, description: 'Comércio varejista de outros artigos usados' },
    { code: 4789001, description: 'Comércio varejista de suvenires, bijuterias e artesanatos' },
    { code: 4789002, description: 'Comércio varejista de plantas e flores naturais' },
    { code: 4789003, description: 'Comércio varejista de objetos de arte' },
    { code: 4789007, description: 'Comércio varejista de equipamentos para escritório' },
    { code: 4789008, description: 'Comércio varejista de artigos fotográficos e para filmagem' },
    { code: 4789099, description: 'Comércio varejista de outros produtos não especificados anteriormente' },
    { code: 5611201, description: 'Restaurantes e similares' },
    { code: 5611203, description: 'Lanchonetes, casas de chá, de sucos e similares' },
    { code: 5611204, description: 'Bares e outros estabelecimentos especializados em servir bebidas, sem entretenimento' },
    { code: 5620101, description: 'Fornecimento de alimentos preparados preponderantemente para empresas' },
    { code: 5620103, description: 'Cantinas - serviços de alimentação privativos' },
    { code: 5620104, description: 'Fornecimento de alimentos preparados preponderantemente para consumo domiciliar' },
    { code: 5911101, description: 'Estúdios cinematográficos' },
    { code: 5911102, description: 'Produção de filmes para publicidade' },
    { code: 5911199, description: 'Atividades de produção cinematográfica, de vídeos e de programas de televisão não especificadas anteriormente' },
    { code: 5912001, description: 'Serviços de dublagem' },
    { code: 5912002, description: 'Serviços de mixagem sonora em produção audiovisual' },
    { code: 5912099, description: 'Atividades de pós-produção cinematográfica, de vídeos e de programas de televisão não especificadas anteriormente' },
    { code: 5913800, description: 'Distribuição cinematográfica, de vídeo e de programas de televisão' },
    { code: 5914600, description: 'Atividades de exibição cinematográfica' },
    { code: 5920100, description: 'Atividades de gravação de som e de edição de música' },
    { code: 6201501, description: 'Desenvolvimento de programas de computador sob encomenda' },
    { code: 6201501, description: 'Desenvolvimento de programas de computador sob encomenda' },
    { code: 6201502, description: 'Web design' },
    { code: 6201502, description: 'Web design' },
    { code: 6202300, description: 'Desenvolvimento e licenciamento de programas de computador customizáveis' },
    { code: 6202300, description: 'Desenvolvimento e licenciamento de programas de computador customizáveis' },
    { code: 6203100, description: 'Desenvolvimento e licenciamento de programas de computador não-customizáveis' },
    { code: 6203100, description: 'Desenvolvimento e licenciamento de programas de computador não-customizáveis' },
    { code: 6204000, description: 'Consultoria em tecnologia da informação' },
    { code: 6209100, description: 'Suporte técnico, manutenção e outros serviços em tecnologia da informação' },
    { code: 6209100, description: 'Suporte técnico, manutenção e outros serviços em tecnologia da informação' },
    { code: 6311900, description: 'Tratamento de dados, provedores de serviços de aplicação e serviços de hospedagem na internet' },
    { code: 6311900, description: 'Tratamento de dados, provedores de serviços de aplicação e serviços de hospedagem na internet' },
    { code: 6319400, description: 'Portais, provedores de conteúdo e outros serviços de informação na internet' },
    { code: 6319400, description: 'Portais, provedores de conteúdo e outros serviços de informação na internet' },
    { code: 6391700, description: 'Agências de notícias' },
    { code: 6399200, description: 'Outras atividades de prestação de serviços de informação não especificadas anteriormente' },
    { code: 6619302, description: 'Correspondentes de instituições financeiras' },
    { code: 6621501, description: 'Peritos e avaliadores de seguros' },
    { code: 6621502, description: 'Auditoria e consultoria atuarial' },
    { code: 6622300, description: 'Corretores e agentes de seguros, de planos de previdência complementar e de saúde' },
    { code: 6629100, description: 'Atividades auxiliares dos seguros, da previdência complementar e dos planos de saúde não especificadas anteriormente' },
    { code: 6821801, description: 'Corretagem na compra e venda e avaliação de imóveis' },
    { code: 6821802, description: 'Corretagem no aluguel de imóveis' },
    { code: 6911701, description: 'Serviços advocatícios' },
    { code: 6911703, description: 'Agente de propriedade industrial' },
    { code: 7020400, description: 'Atividades de consultoria em gestão empresarial, exceto consultoria técnica específica' },
    { code: 7111100, description: 'Serviços de arquitetura' },
    { code: 7112000, description: 'Serviços de engenharia' },
    { code: 7119701, description: 'Serviços de cartografia, topografia e geodésia' },
    { code: 7119702, description: 'Atividades de estudos geológicos' },
    { code: 7119703, description: 'Serviços de desenho técnico relacionados à arquitetura e engenharia' },
    { code: 7119704, description: 'Serviços de perícia técnica relacionados à segurança do trabalho' },
    { code: 7119799, description: 'Atividades técnicas relacionadas à engenharia e arquitetura não especificadas anteriormente' },
    { code: 7120100, description: 'Testes e análises técnicas' },
    { code: 7210000, description: 'Pesquisa e desenvolvimento experimental em ciências físicas e naturais' },
    { code: 7220700, description: 'Pesquisa e desenvolvimento experimental em ciências sociais e humanas' },
    { code: 7311400, description: 'Agências de publicidade' },
    { code: 7312200, description: 'Agenciamento de espaços para publicidade, exceto em veículos de comunicação' },
    { code: 7319001, description: 'Criação de estandes para feiras e exposições' },
    { code: 7319002, description: 'Promoção de vendas' },
    { code: 7319003, description: 'Marketing direto' },
    { code: 7319004, description: 'Consultoria em publicidade' },
    { code: 7319099, description: 'Outras atividades de publicidade não especificadas anteriormente' },
    { code: 7320300, description: 'Pesquisas de mercado e de opinião pública' },
    { code: 7410202, description: 'Design de interiores' },
    { code: 7410202, description: 'Design de interiores' },
    { code: 7410203, description: 'Design de produto' },
    { code: 7410299, description: 'Atividades de design não especificadas anteriormente' },
    { code: 7420001, description: 'Atividades de produção de fotografias, exceto aérea e submarina' },
    { code: 7420002, description: 'Atividades de produção de fotografias aéreas e submarinas' },
    { code: 7420003, description: 'Laboratórios fotográficos' },
    { code: 7420004, description: 'Filmagem de festas e eventos' },
    { code: 7420005, description: 'Serviços de microfilmagem' },
    { code: 7490101, description: 'Serviços de tradução, interpretação e similares' },
    { code: 7490102, description: 'Escafandria e mergulho' },
    { code: 7490103, description: 'Serviços de agronomia e de consultoria às atividades agrícolas e pecuárias' },
    { code: 7490104, description: 'Atividades de intermediação e agenciamento de serviços e negócios em geral, exceto imobiliários' },
    { code: 7490105, description: 'Agenciamento de profissionais para atividades esportivas, culturais e artísticas' },
    { code: 7490199, description: 'Outras atividades profissionais, científicas e técnicas não especificadas anteriormente' },
    { code: 7500100, description: 'Atividades veterinárias' },
    { code: 7721700, description: 'Aluguel de equipamentos recreativos e esportivos' },
    { code: 7722500, description: 'Aluguel de fitas de vídeo, dvds e similares' },
    { code: 7723300, description: 'Aluguel de objetos do vestuário, jóias e acessórios' },
    { code: 7729201, description: 'Aluguel de aparelhos de jogos eletrônicos' },
    { code: 7729202, description: 'Aluguel de móveis, utensílios e aparelhos de uso doméstico e pessoal; instrumentos musicais' },
    { code: 7729203, description: 'Aluguel de material médico' },
    { code: 7729299, description: 'Aluguel de outros objetos pessoais e domésticos não especificados anteriormente' },
    { code: 7731400, description: 'Aluguel de máquinas e equipamentos agrícolas sem operador' },
    { code: 7732201, description: 'Aluguel de máquinas e equipamentos para construção sem operador, exceto andaimes' },
    { code: 7732202, description: 'Aluguel de andaimes' },
    { code: 7733100, description: 'Aluguel de máquinas e equipamentos para escritório' },
    { code: 7739001, description: 'Aluguel de máquinas e equipamentos para extração de minérios e petróleo, sem operador' },
    { code: 7739002, description: 'Aluguel de equipamentos científicos, médicos e hospitalares, sem operador' },
    { code: 7739003, description: 'Aluguel de palcos, coberturas e outras estruturas de uso temporário, exceto andaimes' },
    { code: 7739099, description: 'Aluguel de outras máquinas e equipamentos comerciais e industriais não especificados anteriormente, sem operador' },
    { code: 7740300, description: 'Gestão de ativos intangíveis não-financeiros' },
    { code: 7911200, description: 'Agências de viagens' },
    { code: 7912100, description: 'Operadores turísticos' },
    { code: 7990200, description: 'Serviços de reservas e outros serviços de turismo não especificados anteriormente' },
    { code: 8011102, description: 'Serviços de adestramento de cães de guarda' },
    { code: 8030700, description: 'Atividades de investigação particular' },
    { code: 8121400, description: 'Limpeza em prédios e em domicílios' },
    { code: 8122200, description: 'Imunização e controle de pragas urbanas' },
    { code: 8129000, description: 'Atividades de limpeza não especificadas anteriormente' },
    { code: 8130300, description: 'Atividades paisagísticas' },
    { code: 8211300, description: 'Serviços combinados de escritório e apoio administrativo' },
    { code: 8219999, description: 'Preparação de documentos e serviços especializados de apoio administrativo não especificados anteriormente' },
    { code: 8220200, description: 'Atividades de teleatendimento' },
    { code: 8230001, description: 'Serviços de organização de feiras, congressos, exposições e festas' },
    { code: 8230002, description: 'Casas de festas e eventos' },
    { code: 8291100, description: 'Atividades de cobrança e informações cadastrais' },
    { code: 8299701, description: 'Medição de consumo de energia elétrica, gás e água' },
    { code: 8299703, description: 'Serviços de gravação de carimbos, exceto confecção' },
    { code: 8299707, description: 'Salas de acesso à internet' },
    { code: 8299799, description: 'Outras atividades de serviços prestados principalmente às empresas não especificadas anteriormente' },
    { code: 8299799, description: 'Outras atividades de serviços prestados principalmente às empresas não especificadas anteriormente' },
    { code: 8511200, description: 'Educação infantil - creche' },
    { code: 8512100, description: 'Educação infantil - pré-escola' },
    { code: 8513900, description: 'Ensino fundamental' },
    { code: 8520100, description: 'Ensino médio' },
    { code: 8531700, description: 'Educação superior - graduação' },
    { code: 8532500, description: 'Educação superior - graduação e pós-graduação' },
    { code: 8533300, description: 'Educação superior - pós-graduação e extensão' },
    { code: 8541400, description: 'Educação profissional de nível técnico' },
    { code: 8542200, description: 'Educação profissional de nível tecnológico' },
    { code: 8550302, description: 'Atividades de apoio à educação, exceto caixas escolares' },
    { code: 8591100, description: 'Ensino de esportes' },
    { code: 8591100, description: 'Ensino de esportes' },
    { code: 8592901, description: 'Ensino de dança' },
    { code: 8592901, description: 'Ensino de dança' },
    { code: 8592902, description: 'Ensino de artes cênicas, exceto dança' },
    { code: 8592903, description: 'Ensino de música' },
    { code: 8592999, description: 'Ensino de arte e cultura não especificado anteriormente' },
    { code: 8593700, description: 'Ensino de idiomas' },
    { code: 8599603, description: 'Treinamento em informática' },
    { code: 8599604, description: 'Treinamento em desenvolvimento profissional e gerencial' },
    { code: 8599605, description: 'Cursos preparatórios para concursos' },
    { code: 8599699, description: 'Outras atividades de ensino não especificadas anteriormente' },
    { code: 8610101, description: 'Atividades de atendimento hospitalar, exceto pronto-socorro e unidades para atendimento a urgências' },
    { code: 8630501, description: 'Atividade médica ambulatorial com recursos para realização de procedimentos cirúrgicos' },
    { code: 8630502, description: 'Atividade médica ambulatorial com recursos para realização de exames complementares' },
    { code: 8630503, description: 'Atividade médica ambulatorial restrita a consultas' },
    { code: 8630504, description: 'Atividade odontológica' },
    { code: 8630506, description: 'Serviços de vacinação e imunização humana' },
    { code: 8630507, description: 'Atividades de reprodução humana assistida' },
    { code: 8630599, description: 'Atividades de atenção ambulatorial não especificadas anteriormente' },
    { code: 8640201, description: 'Laboratórios de anatomia patológica e citológica' },
    { code: 8640201, description: 'Laboratórios de anatomia patológica e citológica' },
    { code: 8640202, description: 'Laboratórios clínicos' },
    { code: 8640202, description: 'Laboratórios clínicos' },
    { code: 8640203, description: 'Serviços de diálise e nefrologia' },
    { code: 8640204, description: 'Serviços de tomografia' },
    { code: 8640204, description: 'Serviços de tomografia' },
    { code: 8640205, description: 'Serviços de diagnóstico por imagem com uso de radiação ionizante, exceto tomografia' },
    { code: 8640205, description: 'Serviços de diagnóstico por imagem com uso de radiação ionizante, exceto tomografia' },
    { code: 8640206, description: 'Serviços de ressonância magnética' },
    { code: 8640206, description: 'Serviços de ressonância magnética' },
    { code: 8640207, description: 'Serviços de diagnóstico por imagem sem uso de radiação ionizante, exceto ressonância magnética' },
    { code: 8640207, description: 'Serviços de diagnóstico por imagem sem uso de radiação ionizante, exceto ressonância magnética' },
    { code: 8640208, description: 'Serviços de diagnóstico por registro gráfico - ecg, eeg e outros exames análogos' },
    { code: 8640208, description: 'Serviços de diagnóstico por registro gráfico - ecg, eeg e outros exames análogos' },
    { code: 8640209, description: 'Serviços de diagnóstico por métodos ópticos - endoscopia e outros exames análogos' },
    { code: 8640209, description: 'Serviços de diagnóstico por métodos ópticos - endoscopia e outros exames análogos' },
    { code: 8640210, description: 'Serviços de quimioterapia' },
    { code: 8640211, description: 'Serviços de radioterapia' },
    { code: 8640212, description: 'Serviços de hemoterapia' },
    { code: 8640213, description: 'Serviços de litotripsia' },
    { code: 8640214, description: 'Serviços de bancos de células e tecidos humanos' },
    { code: 8640299, description: 'Atividades de serviços de complementação diagnóstica e terapêutica não especificadas anteriormente' },
    { code: 8650001, description: 'Atividades de enfermagem' },
    { code: 8650002, description: 'Atividades de profissionais da nutrição' },
    { code: 8650003, description: 'Atividades de psicologia e psicanálise' },
    { code: 8650004, description: 'Atividades de fisioterapia' },
    { code: 8650004, description: 'Atividades de fisioterapia' },
    { code: 8650005, description: 'Atividades de terapia ocupacional' },
    { code: 8650006, description: 'Atividades de fonoaudiologia' },
    { code: 8650007, description: 'Atividades de terapia de nutrição enteral e parenteral' },
    { code: 8650099, description: 'Atividades de profissionais da área de saúde não especificadas anteriormente' },
    { code: 8660700, description: 'Atividades de apoio à gestão de saúde' },
    { code: 8690901, description: 'Atividades de práticas integrativas e complementares em saúde humana' },
    { code: 8690903, description: 'Atividades de acupuntura' },
    { code: 8690904, description: 'Atividades de podologia' },
    { code: 8690999, description: 'Outras atividades de atenção à saúde humana não especificadas anteriormente' },
    { code: 9001901, description: 'Produção teatral' },
    { code: 9001902, description: 'Produção musical' },
    { code: 9001903, description: 'Produção de espetáculos de dança' },
    { code: 9001904, description: 'Produção de espetáculos circenses, de marionetes e similares' },
    { code: 9001905, description: 'Produção de espetáculos de rodeios, vaquejadas e similares' },
    { code: 9001906, description: 'Atividades de sonorização e de iluminação' },
    { code: 9001999, description: 'Artes cênicas, espetáculos e atividades complementares não especificados anteriormente' },
    { code: 9002701, description: 'Atividades de artistas plásticos, jornalistas independentes e escritores' },
    { code: 9002702, description: 'Restauração de obras de arte' },
    { code: 9311500, description: 'Gestão de instalações de esportes' },
    { code: 9313100, description: 'Atividades de condicionamento físico' },
    { code: 9313100, description: 'Atividades de condicionamento físico' },
    { code: 9319101, description: 'Produção e promoção de eventos esportivos' },
    { code: 9319199, description: 'Outras atividades esportivas não especificadas anteriormente' },
    { code: 9329802, description: 'Exploração de boliches' },
    { code: 9329803, description: 'Exploração de jogos de sinuca, bilhar e similares' },
    { code: 9329804, description: 'Exploração de jogos eletrônicos recreativos' },
    { code: 9329899, description: 'Outras atividades de recreação e lazer não especificadas anteriormente' },
    { code: 9511800, description: 'Reparação e manutenção de computadores e de equipamentos periféricos' },
    { code: 9512600, description: 'Reparação e manutenção de equipamentos de comunicação' },
    { code: 9521500, description: 'Reparação e manutenção de equipamentos eletroeletrônicos de uso pessoal e doméstico' },
    { code: 9529101, description: 'Reparação de calçados, bolsas e artigos de viagem' },
    { code: 9529102, description: 'Chaveiros' },
    { code: 9529103, description: 'Reparação de relógios' },
    { code: 9529104, description: 'Reparação de bicicletas, triciclos e outros veículos não-motorizados' },
    { code: 9529105, description: 'Reparação de artigos do mobiliário' },
    { code: 9529106, description: 'Reparação de jóias' },
    { code: 9529199, description: 'Reparação e manutenção de outros objetos e equipamentos pessoais e domésticos não especificados anteriormente' },
    { code: 9601701, description: 'Lavanderias' },
    { code: 9601702, description: 'Tinturarias' },
    { code: 9601703, description: 'Toalheiros' },
    { code: 9602501, description: 'Cabeleireiros, manicure e pedicure' },
    { code: 9602502, description: 'Atividades de estética e outros serviços de cuidados com a beleza' },
    { code: 9609202, description: 'Agências matrimoniais' },
    { code: 9609205, description: 'Atividades de sauna e banhos' },
    { code: 9609206, description: 'Serviços de tatuagem e colocação de piercing' },
    { code: 9609207, description: 'Alojamento de animais domésticos' },
    { code: 9609208, description: 'Higiene e embelezamento de animais domésticos' },
    { code: 9609299, description: 'Outras atividades de serviços pessoais não especificadas anteriormente' },
    { code: 111301, description: 'Cultivo de arroz' },
    { code: 111302, description: 'Cultivo de milho' },
    { code: 111303, description: 'Cultivo de trigo' },
    { code: 111399, description: 'Cultivo de outros cereais não especificados anteriormente' },
    { code: 112101, description: 'Cultivo de algodão herbáceo' },
    { code: 112102, description: 'Cultivo de juta' },
    { code: 112199, description: 'Cultivo de outras fibras de lavoura temporária não especificadas anteriormente' },
    { code: 113000, description: 'Cultivo de cana-de-açúcar' },
    { code: 114800, description: 'Cultivo de fumo' },
    { code: 115600, description: 'Cultivo de soja' },
    { code: 116401, description: 'Cultivo de amendoim' },
    { code: 116402, description: 'Cultivo de girassol' },
    { code: 116403, description: 'Cultivo de mamona' },
    { code: 116499, description: 'Cultivo de outras oleaginosas de lavoura temporária não especificadas anteriormente' },
    { code: 119901, description: 'Cultivo de abacaxi' },
    { code: 119902, description: 'Cultivo de alho' },
    { code: 119903, description: 'Cultivo de batata-inglesa' },
    { code: 119904, description: 'Cultivo de cebola' },
    { code: 119905, description: 'Cultivo de feijão' },
    { code: 119906, description: 'Cultivo de mandioca' },
    { code: 119907, description: 'Cultivo de melão' },
    { code: 119908, description: 'Cultivo de melancia' },
    { code: 119909, description: 'Cultivo de tomate rasteiro' },
    { code: 119999, description: 'Cultivo de outras plantas de lavoura temporária não especificadas anteriormente' },
    { code: 121101, description: 'Horticultura, exceto morango' },
    { code: 121102, description: 'Cultivo de morango' },
    { code: 122900, description: 'Cultivo de flores e plantas ornamentais' },
    { code: 131800, description: 'Cultivo de laranja' },
    { code: 132600, description: 'Cultivo de uva' },
    { code: 133401, description: 'Cultivo de açaí' },
    { code: 133402, description: 'Cultivo de banana' },
    { code: 133403, description: 'Cultivo de caju' },
    { code: 133404, description: 'Cultivo de cítricos, exceto laranja' },
    { code: 133405, description: 'Cultivo de coco-da-baía' },
    { code: 133406, description: 'Cultivo de guaraná' },
    { code: 133407, description: 'Cultivo de maçã' },
    { code: 133408, description: 'Cultivo de mamão' },
    { code: 133409, description: 'Cultivo de maracujá' },
    { code: 133410, description: 'Cultivo de manga' },
    { code: 133411, description: 'Cultivo de pêssego' },
    { code: 133499, description: 'Cultivo de frutas de lavoura permanente não especificadas anteriormente' },
    { code: 134200, description: 'Cultivo de café' },
    { code: 135100, description: 'Cultivo de cacau' },
    { code: 139301, description: 'Cultivo de chá-da-índia' },
    { code: 139302, description: 'Cultivo de erva-mate' },
    { code: 139303, description: 'Cultivo de pimenta-do-reino' },
    { code: 139304, description: 'Cultivo de plantas para condimento, exceto pimenta-do-reino' },
    { code: 139305, description: 'Cultivo de dendê' },
    { code: 139306, description: 'Cultivo de seringueira' },
    { code: 139399, description: 'Cultivo de outras plantas de lavoura permanente não especificadas anteriormente' },
    { code: 141501, description: 'Produção de sementes certificadas, exceto de forrageiras para pasto' },
    { code: 141502, description: 'Produção de sementes certificadas de forrageiras para formação de pasto' },
    { code: 142300, description: 'Produção de mudas e outras formas de propagação vegetal, certificadas' },
    { code: 151201, description: 'Criação de bovinos para corte' },
    { code: 151202, description: 'Criação de bovinos para leite' },
    { code: 151203, description: 'Criação de bovinos, exceto para corte e leite' },
    { code: 152101, description: 'Criação de bufalinos' },
    { code: 152102, description: 'Criação de eqüinos' },
    { code: 152103, description: 'Criação de asininos e muares' },
    { code: 153901, description: 'Criação de caprinos' },
    { code: 153902, description: 'Criação de ovinos, inclusive para produção de lã' },
    { code: 154700, description: 'Criação de suínos' },
    { code: 155501, description: 'Criação de frangos para corte' },
    { code: 155502, description: 'Produção de pintos de um dia' },
    { code: 155503, description: 'Criação de outros galináceos, exceto para corte' },
    { code: 155504, description: 'Criação de aves, exceto galináceos' },
    { code: 155505, description: 'Produção de ovos' },
    { code: 159801, description: 'Apicultura' },
    { code: 159802, description: 'Criação de animais de estimação' },
    { code: 159803, description: 'Criação de escargô' },
    { code: 159804, description: 'Criação de bicho-da-seda' },
    { code: 159899, description: 'Criação de outros animais não especificados anteriormente' },
    { code: 161001, description: 'Serviço de pulverização e controle de pragas agrícolas' },
    { code: 161002, description: 'Serviço de poda de árvores para lavouras' },
    { code: 161003, description: 'Serviço de preparação de terreno, cultivo e colheita' },
    { code: 161099, description: 'Atividades de apoio à agricultura não especificadas anteriormente' },
    { code: 162802, description: 'Serviço de tosquiamento de ovinos' },
    { code: 162803, description: 'Serviço de manejo de animais' },
    { code: 162899, description: 'Atividades de apoio à pecuária não especificadas anteriormente' },
    { code: 163600, description: 'Atividades de pós-colheita' },
    { code: 170900, description: 'Caça e serviços relacionados' },
    { code: 210101, description: 'Cultivo de eucalipto' },
    { code: 210102, description: 'Cultivo de acácia-negra' },
    { code: 210103, description: 'Cultivo de pinus' },
    { code: 210104, description: 'Cultivo de teca' },
    { code: 210105, description: 'Cultivo de espécies madeireiras, exceto eucalipto, acácia-negra, pinus e teca' },
    { code: 210106, description: 'Cultivo de mudas em viveiros florestais' },
    { code: 210107, description: 'Extração de madeira em florestas plantadas' },
    { code: 210108, description: 'Produção de carvão vegetal - florestas plantadas' },
    { code: 210109, description: 'Produção de casca de acácia-negra - florestas plantadas' },
    { code: 210199, description: 'Produção de produtos não-madeireiros não especificados anteriormente em florestas plantadas' },
    { code: 220901, description: 'Extração de madeira em florestas nativas' },
    { code: 220902, description: 'Produção de carvão vegetal - florestas nativas' },
    { code: 220903, description: 'Coleta de castanha-do-pará em florestas nativas' },
    { code: 220904, description: 'Coleta de látex em florestas nativas' },
    { code: 220905, description: 'Coleta de palmito em florestas nativas' },
    { code: 220906, description: 'Conservação de florestas nativas' },
    { code: 220999, description: 'Coleta de produtos não-madeireiros não especificados anteriormente em florestas nativas' },
    { code: 230600, description: 'Atividades de apoio à produção florestal' },
    { code: 311601, description: 'Pesca de peixes em água salgada' },
    { code: 311602, description: 'Pesca de crustáceos e moluscos em água salgada' },
    { code: 311603, description: 'Coleta de outros produtos marinhos' },
    { code: 311604, description: 'Atividades de apoio à pesca em água salgada' },
    { code: 312401, description: 'Pesca de peixes em água doce' },
    { code: 312402, description: 'Pesca de crustáceos e moluscos em água doce' },
    { code: 312403, description: 'Coleta de outros produtos aquáticos de água doce' },
    { code: 312404, description: 'Atividades de apoio à pesca em água doce' },
    { code: 321301, description: 'Criação de peixes em água salgada e salobra' },
    { code: 321302, description: 'Criação de camarões em água salgada e salobra' },
    { code: 321303, description: 'Criação de ostras e mexilhões em água salgada e salobra' },
    { code: 321304, description: 'Criação de peixes ornamentais em água salgada e salobra' },
    { code: 321305, description: 'Atividades de apoio à aqüicultura em água salgada e salobra' },
    { code: 321399, description: 'Cultivos e semicultivos da aqüicultura em água salgada e salobra não especificados anteriormente' },
    { code: 322101, description: 'Criação de peixes em água doce' },
    { code: 322102, description: 'Criação de camarões em água doce' },
    { code: 322103, description: 'Criação de ostras e mexilhões em água doce' },
    { code: 322104, description: 'Criação de peixes ornamentais em água doce' },
    { code: 322105, description: 'Ranicultura' },
    { code: 322106, description: 'Criação de jacaré' },
    { code: 322107, description: 'Atividades de apoio à aqüicultura em água doce' },
    { code: 322199, description: 'Cultivos e semicultivos da aqüicultura em água doce não especificados anteriormente' },
    { code: 500301, description: 'Extração de carvão mineral' },
    { code: 500302, description: 'Beneficiamento de carvão mineral' },
    { code: 600001, description: 'Extração de petróleo e gás natural' },
    { code: 600002, description: 'Extração e beneficiamento de xisto' },
    { code: 600003, description: 'Extração e beneficiamento de areias betuminosas' },
    { code: 710301, description: 'Extração de minério de ferro' },
    { code: 710302, description: 'Pelotização, sinterização e outros beneficiamentos de minério de ferro' },
    { code: 721901, description: 'Extração de minério de alumínio' },
    { code: 721902, description: 'Beneficiamento de minério de alumínio' },
    { code: 722701, description: 'Extração de minério de estanho' },
    { code: 722702, description: 'Beneficiamento de minério de estanho' },
    { code: 723501, description: 'Extração de minério de manganês' },
    { code: 723502, description: 'Beneficiamento de minério de manganês' },
    { code: 724301, description: 'Extração de minério de metais preciosos' },
    { code: 724302, description: 'Beneficiamento de minério de metais preciosos' },
    { code: 725100, description: 'Extração de minerais radioativos' },
    { code: 729401, description: 'Extração de minérios de nióbio e titânio' },
    { code: 729402, description: 'Extração de minério de tungstênio' },
    { code: 729403, description: 'Extração de minério de níquel' },
    { code: 729404, description: 'Extração de minérios de cobre, chumbo, zinco e outros minerais metálicos não-ferrosos não especificados anteriormente' },
    { code: 729405, description: 'Beneficiamento de minérios de cobre, chumbo, zinco e outros minerais metálicos não-ferrosos não especificados anteriormente' },
    { code: 810001, description: 'Extração de ardósia e beneficiamento associado' },
    { code: 810002, description: 'Extração de granito e beneficiamento associado' },
    { code: 810003, description: 'Extração de mármore e beneficiamento associado' },
    { code: 810004, description: 'Extração de calcário e dolomita e beneficiamento associado' },
    { code: 810005, description: 'Extração de gesso e caulim' },
    { code: 810006, description: 'Extração de areia, cascalho ou pedregulho e beneficiamento associado' },
    { code: 810007, description: 'Extração de argila e beneficiamento associado' },
    { code: 810008, description: 'Extração de saibro e beneficiamento associado' },
    { code: 810009, description: 'Extração de basalto e beneficiamento associado' },
    { code: 810010, description: 'Beneficiamento de gesso e caulim associado à extração' },
    { code: 810099, description: 'Extração e britamento de pedras e outros materiais para construção e beneficiamento associado' },
    { code: 891600, description: 'Extração de minerais para fabricação de adubos, fertilizantes e outros produtos químicos' },
    { code: 892401, description: 'Extração de sal marinho' },
    { code: 892402, description: 'Extração de sal-gema' },
    { code: 892403, description: 'Refino e outros tratamentos do sal' },
    { code: 893200, description: 'Extração de gemas (pedras preciosas e semipreciosas)' },
    { code: 899101, description: 'Extração de grafita' },
    { code: 899102, description: 'Extração de quartzo' },
    { code: 899103, description: 'Extração de amianto' },
    { code: 899199, description: 'Extração de outros minerais não-metálicos não especificados anteriormente' },
    { code: 910600, description: 'Atividades de apoio à extração de petróleo e gás natural' },
    { code: 990401, description: 'Atividades de apoio à extração de minério de ferro' },
    { code: 990402, description: 'Atividades de apoio à extração de minerais metálicos não-ferrosos' },
    { code: 990403, description: 'Atividades de apoio à extração de minerais não-metálicos' },
    { code: 1011201, description: 'Frigorífico - abate de bovinos' },
    { code: 1011202, description: 'Frigorífico - abate de eqüinos' },
    { code: 1011203, description: 'Frigorífico - abate de ovinos e caprinos' },
    { code: 1011204, description: 'Frigorífico - abate de bufalinos' },
    { code: 1011205, description: 'Matadouro - abate de reses sob contrato, exceto abate de suínos' },
    { code: 1012101, description: 'Abate de aves' },
    { code: 1012102, description: 'Abate de pequenos animais' },
    { code: 1012103, description: 'Frigorífico - abate de suínos' },
    { code: 1012104, description: 'Matadouro - abate de suínos sob contrato' },
    { code: 1013901, description: 'Fabricação de produtos de carne' },
    { code: 1013902, description: 'Preparação de subprodutos do abate' },
    { code: 1020101, description: 'Preservação de peixes, crustáceos e moluscos' },
    { code: 1020102, description: 'Fabricação de conservas de peixes, crustáceos e moluscos' },
    { code: 1031700, description: 'Fabricação de conservas de frutas' },
    { code: 1032501, description: 'Fabricação de conservas de palmito' },
    { code: 1032599, description: 'Fabricação de conservas de legumes e outros vegetais, exceto palmito' },
    { code: 1033301, description: 'Fabricação de sucos concentrados de frutas, hortaliças e legumes' },
    { code: 1033302, description: 'Fabricação de sucos de frutas, hortaliças e legumes, exceto concentrados' },
    { code: 1041400, description: 'Fabricação de óleos vegetais em bruto, exceto óleo de milho' },
    { code: 1042200, description: 'Fabricação de óleos vegetais refinados, exceto óleo de milho' },
    { code: 1043100, description: 'Fabricação de margarina e outras gorduras vegetais e de óleos não-comestíveis de animais' },
    { code: 1051100, description: 'Preparação do leite' },
    { code: 1052000, description: 'Fabricação de laticínios' },
    { code: 1053800, description: 'Fabricação de sorvetes e outros gelados comestíveis' },
    { code: 1061901, description: 'Beneficiamento de arroz' },
    { code: 1061902, description: 'Fabricação de produtos do arroz' },
    { code: 1062700, description: 'Moagem de trigo e fabricação de derivados' },
    { code: 1063500, description: 'Fabricação de farinha de mandioca e derivados' },
    { code: 1064300, description: 'Fabricação de farinha de milho e derivados, exceto óleos de milho' },
    { code: 1065101, description: 'Fabricação de amidos e féculas de vegetais' },
    { code: 1065102, description: 'Fabricação de óleo de milho em bruto' },
    { code: 1065103, description: 'Fabricação de óleo de milho refinado' },
    { code: 1066000, description: 'Fabricação de alimentos para animais' },
    { code: 1069400, description: 'Moagem e fabricação de produtos de origem vegetal não especificados anteriormente' },
    { code: 1071600, description: 'Fabricação de açúcar em bruto' },
    { code: 1072401, description: 'Fabricação de açúcar de cana refinado' },
    { code: 1072402, description: 'Fabricação de açúcar de cereais (dextrose) e de beterraba' },
    { code: 1081301, description: 'Beneficiamento de café' },
    { code: 1081302, description: 'Torrefação e moagem de café' },
    { code: 1082100, description: 'Fabricação de produtos à base de café' },
    { code: 1091101, description: 'Fabricação de produtos de panificação industrial' },
    { code: 1091102, description: 'Fabricação de produtos de padaria e confeitaria com predominância de produção própria' },
    { code: 1092900, description: 'Fabricação de biscoitos e bolachas' },
    { code: 1093701, description: 'Fabricação de produtos derivados do cacau e de chocolates' },
    { code: 1093702, description: 'Fabricação de frutas cristalizadas, balas e semelhantes' },
    { code: 1094500, description: 'Fabricação de massas alimentícias' },
    { code: 1095300, description: 'Fabricação de especiarias, molhos, temperos e condimentos' },
    { code: 1096100, description: 'Fabricação de alimentos e pratos prontos' },
    { code: 1099601, description: 'Fabricação de vinagres' },
    { code: 1099602, description: 'Fabricação de pós alimentícios' },
    { code: 1099603, description: 'Fabricação de fermentos e leveduras' },
    { code: 1099604, description: 'Fabricação de gelo comum' },
    { code: 1099605, description: 'Fabricação de produtos para infusão (chá, mate, etc.)' },
    { code: 1099606, description: 'Fabricação de adoçantes naturais e artificiais' },
    { code: 1099607, description: 'Fabricação de alimentos dietéticos e complementos alimentares' },
    { code: 1099699, description: 'Fabricação de outros produtos alimentícios não especificados anteriormente' },
    { code: 1111901, description: 'Fabricação de aguardente de cana de açúcar' },
    { code: 1111902, description: 'Fabricação de outras aguardentes e bebidas destiladas' },
    { code: 1112700, description: 'Fabricação de vinho' },
    { code: 1113501, description: 'Fabricação de malte, inclusive malte uísque' },
    { code: 1113502, description: 'Fabricação de cervejas e chopes' },
    { code: 1121600, description: 'Fabricação de águas envasadas' },
    { code: 1122401, description: 'Fabricação de refrigerantes' },
    { code: 1122402, description: 'Fabricação de chá mate e outros chás prontos para consumo' },
    { code: 1122403, description: 'Fabricação de refrescos, xaropes e pós para refrescos, exceto refrescos de frutas' },
    { code: 1122404, description: 'Fabricação de bebidas isotônicas' },
    { code: 1122499, description: 'Fabricação de outras bebidas não alcoólicas não especificadas anteriormente' },
    { code: 1210700, description: 'Processamento industrial do fumo' },
    { code: 1220499, description: 'Fabricação de outros produtos do fumo, exceto cigarros, cigarrilhas e charutos' },
    { code: 1311100, description: 'Preparação e fiação de fibras de algodão' },
    { code: 1312000, description: 'Preparação e fiação de fibras têxteis naturais, exceto algodão' },
    { code: 1313800, description: 'Fiação de fibras artificiais e sintéticas' },
    { code: 1314600, description: 'Fabricação de linhas para costurar e bordar' },
    { code: 1321900, description: 'Tecelagem de fios de algodão' },
    { code: 1322700, description: 'Tecelagem de fios de fibras têxteis naturais, exceto algodão' },
    { code: 1323500, description: 'Tecelagem de fios de fibras artificiais e sintéticas' },
    { code: 1330800, description: 'Fabricação de tecidos de malha' },
    { code: 1340501, description: 'Estamparia e texturização em fios, tecidos, artefatos têxteis e peças do vestuário' },
    { code: 1340502, description: 'Alvejamento, tingimento e torção em fios, tecidos, artefatos têxteis e peças do vestuário' },
    { code: 1340599, description: 'Outros serviços de acabamento em fios, tecidos, artefatos têxteis e peças do vestuário' },
    { code: 1351100, description: 'Fabricação de artefatos têxteis para uso doméstico' },
    { code: 1352900, description: 'Fabricação de artefatos de tapeçaria' },
    { code: 1353700, description: 'Fabricação de artefatos de cordoaria' },
    { code: 1354500, description: 'Fabricação de tecidos especiais, inclusive artefatos' },
    { code: 1359600, description: 'Fabricação de outros produtos têxteis não especificados anteriormente' },
    { code: 1411801, description: 'Confecção de roupas íntimas' },
    { code: 1411802, description: 'Facção de roupas íntimas' },
    { code: 1412601, description: 'Confecção de peças do vestuário, exceto roupas íntimas e as confeccionadas sob medida' },
    { code: 1412602, description: 'Confecção, sob medida, de peças do vestuário, exceto roupas íntimas' },
    { code: 1412603, description: 'Facção de peças do vestuário, exceto roupas íntimas' },
    { code: 1413401, description: 'Confecção de roupas profissionais, exceto sob medida' },
    { code: 1413402, description: 'Confecção, sob medida, de roupas profissionais' },
    { code: 1413403, description: 'Facção de roupas profissionais' },
    { code: 1414200, description: 'Fabricação de acessórios do vestuário, exceto para segurança e proteção' },
    { code: 1421500, description: 'Fabricação de meias' },
    { code: 1422300, description: 'Fabricação de artigos do vestuário, produzidos em malharias e tricotagens, exceto meias' },
    { code: 1510600, description: 'Curtimento e outras preparações de couro' },
    { code: 1521100, description: 'Fabricação de artigos para viagem, bolsas e semelhantes de qualquer material' },
    { code: 1529700, description: 'Fabricação de artefatos de couro não especificados anteriormente' },
    { code: 1531901, description: 'Fabricação de calçados de couro' },
    { code: 1531902, description: 'Acabamento de calçados de couro sob contrato' },
    { code: 1532700, description: 'Fabricação de tênis de qualquer material' },
    { code: 1533500, description: 'Fabricação de calçados de material sintético' },
    { code: 1539400, description: 'Fabricação de calçados de materiais não especificados anteriormente' },
    { code: 1540800, description: 'Fabricação de partes para calçados, de qualquer material' },
    { code: 1610203, description: 'Serrarias com desdobramento de madeira em bruto' },
    { code: 1610204, description: 'Serrarias sem desdobramento de madeira em bruto - resserragem' },
    { code: 1621800, description: 'Fabricação de madeira laminada e de chapas de madeira compensada, prensada e aglomerada' },
    { code: 1622601, description: 'Fabricação de casas de madeira pré-fabricadas' },
    { code: 1622602, description: 'Fabricação de esquadrias de madeira e de peças de madeira para instalações industriais e comerciais' },
    { code: 1622699, description: 'Fabricação de outros artigos de carpintaria para construção' },
    { code: 1623400, description: 'Fabricação de artefatos de tanoaria e de embalagens de madeira' },
    { code: 1629301, description: 'Fabricação de artefatos diversos de madeira, exceto móveis' },
    { code: 1629302, description: 'Fabricação de artefatos diversos de cortiça, bambu, palha, vime e outros materiais trançados, exceto móveis' },
    { code: 1710900, description: 'Fabricação de celulose e outras pastas para a fabricação de papel' },
    { code: 1721400, description: 'Fabricação de papel' },
    { code: 1722200, description: 'Fabricação de cartolina e papel-cartão' },
    { code: 1731100, description: 'Fabricação de embalagens de papel' },
    { code: 1732000, description: 'Fabricação de embalagens de cartolina e papel-cartão' },
    { code: 1733800, description: 'Fabricação de chapas e de embalagens de papelão ondulado' },
    { code: 1741901, description: 'Fabricação de formulários contínuos' },
    { code: 1741902, description: 'Fabricação de produtos de papel, cartolina, papel-cartão e papelão ondulado para uso comercial e de escritório' },
    { code: 1742701, description: 'Fabricação de fraldas descartáveis' },
    { code: 1742702, description: 'Fabricação de absorventes higiênicos' },
    { code: 1742799, description: 'Fabricação de produtos de papel para uso doméstico e higiênico-sanitário não especificados anteriormente' },
    { code: 1749400, description: 'Fabricação de produtos de pastas celulósicas, papel, cartolina, papel-cartão e papelão ondulado não especificados anteriormente' },
    { code: 1811301, description: 'Impressão de jornais' },
    { code: 1811302, description: 'Impressão de livros, revistas e outras publicações periódicas' },
    { code: 1812100, description: 'Impressão de material de segurança' },
    { code: 1813001, description: 'Impressão de material para uso publicitário' },
    { code: 1813099, description: 'Impressão de material para outros usos' },
    { code: 1821100, description: 'Serviços de pré-impressão' },
    { code: 1822901, description: 'Serviços de encadernação e plastificação' },
    { code: 1822999, description: 'Serviços de acabamentos gráficos, exceto encadernação e plastificação' },
    { code: 1830001, description: 'Reprodução de som em qualquer suporte' },
    { code: 1830002, description: 'Reprodução de vídeo em qualquer suporte' },
    { code: 1830003, description: 'Reprodução de software em qualquer suporte' },
    { code: 1910100, description: 'Coquerias' },
    { code: 1921700, description: 'Fabricação de produtos do refino de petróleo' },
    { code: 1922501, description: 'Formulação de combustíveis' },
    { code: 1922502, description: 'Rerrefino de óleos lubrificantes' },
    { code: 1922599, description: 'Fabricação de outros produtos derivados do petróleo, exceto produtos do refino' },
    { code: 1931400, description: 'Fabricação de álcool' },
    { code: 1932200, description: 'Fabricação de biocombustíveis, exceto álcool' },
    { code: 2011800, description: 'Fabricação de cloro e álcalis' },
    { code: 2012600, description: 'Fabricação de intermediários para fertilizantes' },
    { code: 2013401, description: 'Fabricação de adubos e fertilizantes organo-minerais' },
    { code: 2013402, description: 'Fabricação de adubos e fertilizantes, exceto organo-minerais' },
    { code: 2014200, description: 'Fabricação de gases industriais' },
    { code: 2019301, description: 'Elaboração de combustíveis nucleares' },
    { code: 2019399, description: 'Fabricação de outros produtos químicos inorgânicos não especificados anteriormente' },
    { code: 2021500, description: 'Fabricação de produtos petroquímicos básicos' },
    { code: 2022300, description: 'Fabricação de intermediários para plastificantes, resinas e fibras' },
    { code: 2029100, description: 'Fabricação de produtos químicos orgânicos não especificados anteriormente' },
    { code: 2031200, description: 'Fabricação de resinas termoplásticas' },
    { code: 2032100, description: 'Fabricação de resinas termofixas' },
    { code: 2033900, description: 'Fabricação de elastômeros' },
    { code: 2040100, description: 'Fabricação de fibras artificiais e sintéticas' },
    { code: 2051700, description: 'Fabricação de defensivos agrícolas' },
    { code: 2052500, description: 'Fabricação de desinfestantes domissanitários' },
    { code: 2061400, description: 'Fabricação de sabões e detergentes sintéticos' },
    { code: 2062200, description: 'Fabricação de produtos de limpeza e polimento' },
    { code: 2063100, description: 'Fabricação de cosméticos, produtos de perfumaria e de higiene pessoal' },
    { code: 2071100, description: 'Fabricação de tintas, vernizes, esmaltes e lacas' },
    { code: 2072000, description: 'Fabricação de tintas de impressão' },
    { code: 2073800, description: 'Fabricação de impermeabilizantes, solventes e produtos afins' },
    { code: 2091600, description: 'Fabricação de adesivos e selantes' },
    { code: 2092402, description: 'Fabricação de artigos pirotécnicos' },
    { code: 2092403, description: 'Fabricação de fósforos de segurança' },
    { code: 2093200, description: 'Fabricação de aditivos de uso industrial' },
    { code: 2094100, description: 'Fabricação de catalisadores' },
    { code: 2099101, description: 'Fabricação de chapas, filmes, papéis e outros materiais e produtos químicos para fotografia' },
    { code: 2099199, description: 'Fabricação de outros produtos químicos não especificados anteriormente' },
    { code: 2110600, description: 'Fabricação de produtos farmoquímicos' },
    { code: 2121101, description: 'Fabricação de medicamentos alopáticos para uso humano' },
    { code: 2121102, description: 'Fabricação de medicamentos homeopáticos para uso humano' },
    { code: 2121103, description: 'Fabricação de medicamentos fitoterápicos para uso humano' },
    { code: 2122000, description: 'Fabricação de medicamentos para uso veterinário' },
    { code: 2123800, description: 'Fabricação de preparações farmacêuticas' },
    { code: 2211100, description: 'Fabricação de pneumáticos e de câmaras-de-ar' },
    { code: 2212900, description: 'Reforma de pneumáticos usados' },
    { code: 2219600, description: 'Fabricação de artefatos de borracha não especificados anteriormente' },
    { code: 2221800, description: 'Fabricação de laminados planos e tubulares de material plástico' },
    { code: 2222600, description: 'Fabricação de embalagens de material plástico' },
    { code: 2223400, description: 'Fabricação de tubos e acessórios de material plástico para uso na construção' },
    { code: 2229301, description: 'Fabricação de artefatos de material plástico para uso pessoal e doméstico' },
    { code: 2229302, description: 'Fabricação de artefatos de material plástico para usos industriais' },
    { code: 2229303, description: 'Fabricação de artefatos de material plástico para uso na construção, exceto tubos e acessórios' },
    { code: 2229399, description: 'Fabricação de artefatos de material plástico para outros usos não especificados anteriormente' },
    { code: 2311700, description: 'Fabricação de vidro plano e de segurança' },
    { code: 2312500, description: 'Fabricação de embalagens de vidro' },
    { code: 2319200, description: 'Fabricação de artigos de vidro' },
    { code: 2320600, description: 'Fabricação de cimento' },
    { code: 2330301, description: 'Fabricação de estruturas pré-moldadas de concreto armado, em série e sob encomenda' },
    { code: 2330302, description: 'Fabricação de artefatos de cimento para uso na construção' },
    { code: 2330303, description: 'Fabricação de artefatos de fibrocimento para uso na construção' },
    { code: 2330304, description: 'Fabricação de casas pré-moldadas de concreto' },
    { code: 2330305, description: 'Preparação de massa de concreto e argamassa para construção' },
    { code: 2330399, description: 'Fabricação de outros artefatos e produtos de concreto, cimento, fibrocimento, gesso e materiais semelhantes' },
    { code: 2341900, description: 'Fabricação de produtos cerâmicos refratários' },
    { code: 2342701, description: 'Fabricação de azulejos e pisos' },
    { code: 2342702, description: 'Fabricação de artefatos de cerâmica e barro cozido para uso na construção, exceto azulejos e pisos' },
    { code: 2349401, description: 'Fabricação de material sanitário de cerâmica' },
    { code: 2349499, description: 'Fabricação de produtos cerâmicos não-refratários não especificados anteriormente' },
    { code: 2391501, description: 'Britamento de pedras, exceto associado à extração' },
    { code: 2391502, description: 'Aparelhamento de pedras para construção, exceto associado à extração' },
    { code: 2391503, description: 'Aparelhamento de placas e execução de trabalhos em mármore, granito, ardósia e outras pedras' },
    { code: 2392300, description: 'Fabricação de cal e gesso' },
    { code: 2399101, description: 'Decoração, lapidação, gravação, vitrificação e outros trabalhos em cerâmica, louça, vidro e cristal' },
    { code: 2399102, description: 'Fabricação de abrasivos' },
    { code: 2399199, description: 'Fabricação de outros produtos de minerais não-metálicos não especificados anteriormente' },
    { code: 2411300, description: 'Produção de ferro-gusa' },
    { code: 2412100, description: 'Produção de ferroligas' },
    { code: 2421100, description: 'Produção de semi-acabados de aço' },
    { code: 2422901, description: 'Produção de laminados planos de aço ao carbono, revestidos ou não' },
    { code: 2422902, description: 'Produção de laminados planos de aços especiais' },
    { code: 2423701, description: 'Produção de tubos de aço sem costura' },
    { code: 2423702, description: 'Produção de laminados longos de aço, exceto tubos' },
    { code: 2424501, description: 'Produção de arames de aço' },
    { code: 2424502, description: 'Produção de relaminados, trefilados e perfilados de aço, exceto arames' },
    { code: 2431800, description: 'Produção de tubos de aço com costura' },
    { code: 2439300, description: 'Produção de outros tubos de ferro e aço' },
    { code: 2441501, description: 'Produção de alumínio e suas ligas em formas primárias' },
    { code: 2441502, description: 'Produção de laminados de alumínio' },
    { code: 2442300, description: 'Metalurgia dos metais preciosos' },
    { code: 2443100, description: 'Metalurgia do cobre' },
    { code: 2449101, description: 'Produção de zinco em formas primárias' },
    { code: 2449102, description: 'Produção de laminados de zinco' },
    { code: 2449103, description: 'Produção de ânodos para galvanoplastia' },
    { code: 2449199, description: 'Metalurgia de outros metais não-ferrosos e suas ligas não especificados anteriormente' },
    { code: 2451200, description: 'Fundição de ferro e aço' },
    { code: 2452100, description: 'Fundição de metais não-ferrosos e suas ligas' },
    { code: 2511000, description: 'Fabricação de estruturas metálicas' },
    { code: 2512800, description: 'Fabricação de esquadrias de metal' },
    { code: 2513600, description: 'Fabricação de obras de caldeiraria pesada' },
    { code: 2521700, description: 'Fabricação de tanques, reservatórios metálicos e caldeiras para aquecimento central' },
    { code: 2522500, description: 'Fabricação de caldeiras geradoras de vapor, exceto para aquecimento central e para veículos' },
    { code: 2531401, description: 'Produção de forjados de aço' },
    { code: 2531402, description: 'Produção de forjados de metais não-ferrosos e suas ligas' },
    { code: 2532201, description: 'Produção de artefatos estampados de metal' },
    { code: 2532202, description: 'Metalurgia do pó' },
    { code: 2539001, description: 'Serviços de usinagem, tornearia e solda' },
    { code: 2539002, description: 'Serviços de tratamento e revestimento em metais' },
    { code: 2541100, description: 'Fabricação de artigos de cutelaria' },
    { code: 2542000, description: 'Fabricação de artigos de serralheria, exceto esquadrias' },
    { code: 2543800, description: 'Fabricação de ferramentas' },
    { code: 2591800, description: 'Fabricação de embalagens metálicas' },
    { code: 2592601, description: 'Fabricação de produtos de trefilados de metal padronizados' },
    { code: 2592602, description: 'Fabricação de produtos de trefilados de metal, exceto padronizados' },
    { code: 2593400, description: 'Fabricação de artigos de metal para uso doméstico e pessoal' },
    { code: 2599301, description: 'Serviços de confecção de armações metálicas para a construção' },
    { code: 2599302, description: 'Serviço de corte e dobra de metais' },
    { code: 2599399, description: 'Fabricação de outros produtos de metal não especificados anteriormente' },
    { code: 2610800, description: 'Fabricação de componentes eletrônicos' },
    { code: 2621300, description: 'Fabricação de equipamentos de informática' },
    { code: 2622100, description: 'Fabricação de periféricos para equipamentos de informática' },
    { code: 2631100, description: 'Fabricação de equipamentos transmissores de comunicação, peças e acessórios' },
    { code: 2632900, description: 'Fabricação de aparelhos telefônicos e de outros equipamentos de comunicação, peças e acessórios' },
    { code: 2640000, description: 'Fabricação de aparelhos de recepção, reprodução, gravação e amplificação de áudio e vídeo' },
    { code: 2651500, description: 'Fabricação de aparelhos e equipamentos de medida, teste e controle' },
    { code: 2652300, description: 'Fabricação de cronômetros e relógios' },
    { code: 2660400, description: 'Fabricação de aparelhos eletromédicos e eletroterapêuticos e equipamentos de irradiação' },
    { code: 2670101, description: 'Fabricação de equipamentos e instrumentos ópticos, peças e acessórios' },
    { code: 2670102, description: 'Fabricação de aparelhos fotográficos e cinematográficos, peças e acessórios' },
    { code: 2680900, description: 'Fabricação de mídias virgens, magnéticas e ópticas' },
    { code: 2710401, description: 'Fabricação de geradores de corrente contínua e alternada, peças e acessórios' },
    { code: 2710402, description: 'Fabricação de transformadores, indutores, conversores, sincronizadores e semelhantes, peças e acessórios' },
    { code: 2710403, description: 'Fabricação de motores elétricos, peças e acessórios' },
    { code: 2721000, description: 'Fabricação de pilhas, baterias e acumuladores elétricos, exceto para veículos automotores' },
    { code: 2722801, description: 'Fabricação de baterias e acumuladores para veículos automotores' },
    { code: 2722802, description: 'Recondicionamento de baterias e acumuladores para veículos automotores' },
    { code: 2731700, description: 'Fabricação de aparelhos e equipamentos para distribuição e controle de energia elétrica' },
    { code: 2732500, description: 'Fabricação de material elétrico para instalações em circuito de consumo' },
    { code: 2733300, description: 'Fabricação de fios, cabos e condutores elétricos isolados' },
    { code: 2740601, description: 'Fabricação de lâmpadas' },
    { code: 2740602, description: 'Fabricação de luminárias e outros equipamentos de iluminação' },
    { code: 2751100, description: 'Fabricação de fogões, refrigeradores e máquinas de lavar e secar para uso doméstico, peças e acessórios' },
    { code: 2759701, description: 'Fabricação de aparelhos elétricos de uso pessoal, peças e acessórios' },
    { code: 2759799, description: 'Fabricação de outros aparelhos eletrodomésticos não especificados anteriormente, peças e acessórios' },
    { code: 2790201, description: 'Fabricação de eletrodos, contatos e outros artigos de carvão e grafita para uso elétrico, eletroímãs e isoladores' },
    { code: 2790202, description: 'Fabricação de equipamentos para sinalização e alarme' },
    { code: 2790299, description: 'Fabricação de outros equipamentos e aparelhos elétricos não especificados anteriormente' },
    { code: 2811900, description: 'Fabricação de motores e turbinas, peças e acessórios, exceto para aviões e veículos rodoviários' },
    { code: 2812700, description: 'Fabricação de equipamentos hidráulicos e pneumáticos, peças e acessórios, exceto válvulas' },
    { code: 2813500, description: 'Fabricação de válvulas, registros e dispositivos semelhantes, peças e acessórios' },
    { code: 2814301, description: 'Fabricação de compressores para uso industrial, peças e acessórios' },
    { code: 2814302, description: 'Fabricação de compressores para uso não-industrial, peças e acessórios' },
    { code: 2815101, description: 'Fabricação de rolamentos para fins industriais' },
    { code: 2815102, description: 'Fabricação de equipamentos de transmissão para fins industriais, exceto rolamentos' },
    { code: 2821601, description: 'Fabricação de fornos industriais, aparelhos e equipamentos não-elétricos para instalações térmicas, peças e acessórios' },
    { code: 2821602, description: 'Fabricação de estufas e fornos elétricos para fins industriais, peças e acessórios' },
    { code: 2822401, description: 'Fabricação de máquinas, equipamentos e aparelhos para transporte e elevação de pessoas, peças e acessórios' },
    { code: 2822402, description: 'Fabricação de máquinas, equipamentos e aparelhos para transporte e elevação de cargas, peças e acessórios' },
    { code: 2823200, description: 'Fabricação de máquinas e aparelhos de refrigeração e ventilação para uso industrial e comercial, peças e acessórios' },
    { code: 2824101, description: 'Fabricação de aparelhos e equipamentos de ar condicionado para uso industrial' },
    { code: 2824102, description: 'Fabricação de aparelhos e equipamentos de ar condicionado para uso não-industrial' },
    { code: 2825900, description: 'Fabricação de máquinas e equipamentos para saneamento básico e ambiental, peças e acessórios' },
    { code: 2829101, description: 'Fabricação de máquinas de escrever, calcular e outros equipamentos não-eletrônicos para escritório, peças e acessórios' },
    { code: 2829199, description: 'Fabricação de outras máquinas e equipamentos de uso geral não especificados anteriormente, peças e acessórios' },
    { code: 2831300, description: 'Fabricação de tratores agrícolas, peças e acessórios' },
    { code: 2832100, description: 'Fabricação de equipamentos para irrigação agrícola, peças e acessórios' },
    { code: 2833000, description: 'Fabricação de máquinas e equipamentos para a agricultura e pecuária, peças e acessórios, exceto para irrigação' },
    { code: 2840200, description: 'Fabricação de máquinas-ferramenta, peças e acessórios' },
    { code: 2851800, description: 'Fabricação de máquinas e equipamentos para a prospecção e extração de petróleo, peças e acessórios' },
    { code: 2852600, description: 'Fabricação de outras máquinas e equipamentos para uso na extração mineral, peças e acessórios, exceto na extração de petróleo' },
    { code: 2853400, description: 'Fabricação de tratores, peças e acessórios, exceto agrícolas' },
    { code: 2854200, description: 'Fabricação de máquinas e equipamentos para terraplenagem, pavimentação e construção, peças e acessórios, exceto tratores' },
    { code: 2861500, description: 'Fabricação de máquinas para a indústria metalúrgica, peças e acessórios, exceto máquinas-ferramenta' },
    { code: 2862300, description: 'Fabricação de máquinas e equipamentos para as indústrias de alimentos, bebidas e fumo, peças e acessórios' },
    { code: 2863100, description: 'Fabricação de máquinas e equipamentos para a indústria têxtil, peças e acessórios' },
    { code: 2864000, description: 'Fabricação de máquinas e equipamentos para as indústrias do vestuário, do couro e de calçados, peças e acessórios' },
    { code: 2865800, description: 'Fabricação de máquinas e equipamentos para as indústrias de celulose, papel e papelão e artefatos, peças e acessórios' },
    { code: 2866600, description: 'Fabricação de máquinas e equipamentos para a indústria do plástico, peças e acessórios' },
    { code: 2869100, description: 'Fabricação de máquinas e equipamentos para uso industrial específico não especificados anteriormente, peças e acessórios' },
    { code: 2910702, description: 'Fabricação de chassis com motor para automóveis, camionetas e utilitários' },
    { code: 2910703, description: 'Fabricação de motores para automóveis, camionetas e utilitários' },
    { code: 2920401, description: 'Fabricação de caminhões e ônibus' },
    { code: 2920402, description: 'Fabricação de motores para caminhões e ônibus' },
    { code: 2930101, description: 'Fabricação de cabines, carrocerias e reboques para caminhões' },
    { code: 2930102, description: 'Fabricação de carrocerias para ônibus' },
    { code: 2930103, description: 'Fabricação de cabines, carrocerias e reboques para outros veículos automotores, exceto caminhões e ônibus' },
    { code: 2941700, description: 'Fabricação de peças e acessórios para o sistema motor de veículos automotores' },
    { code: 2942500, description: 'Fabricação de peças e acessórios para os sistemas de marcha e transmissão de veículos automotores' },
    { code: 2943300, description: 'Fabricação de peças e acessórios para o sistema de freios de veículos automotores' },
    { code: 2944100, description: 'Fabricação de peças e acessórios para o sistema de direção e suspensão de veículos automotores' },
    { code: 2945000, description: 'Fabricação de material elétrico e eletrônico para veículos automotores, exceto baterias' },
    { code: 2949201, description: 'Fabricação de bancos e estofados para veículos automotores' },
    { code: 2949299, description: 'Fabricação de outras peças e acessórios para veículos automotores não especificadas anteriormente' },
    { code: 2950600, description: 'Recondicionamento e recuperação de motores para veículos automotores' },
    { code: 3011301, description: 'Construção de embarcações de grande porte' },
    { code: 3011302, description: 'Construção de embarcações para uso comercial e para usos especiais, exceto de grande porte' },
    { code: 3012100, description: 'Construção de embarcações para esporte e lazer' },
    { code: 3031800, description: 'Fabricação de locomotivas, vagões e outros materiais rodantes' },
    { code: 3032600, description: 'Fabricação de peças e acessórios para veículos ferroviários' },
    { code: 3041500, description: 'Fabricação de aeronaves' },
    { code: 3042300, description: 'Fabricação de turbinas, motores e outros componentes e peças para aeronaves' },
    { code: 3050400, description: 'Fabricação de veículos militares de combate' },
    { code: 3091102, description: 'Fabricação de peças e acessórios para motocicletas' },
    { code: 3092000, description: 'Fabricação de bicicletas e triciclos não-motorizados, peças e acessórios' },
    { code: 3099700, description: 'Fabricação de equipamentos de transporte não especificados anteriormente' },
    { code: 3101200, description: 'Fabricação de móveis com predominância de madeira' },
    { code: 3102100, description: 'Fabricação de móveis com predominância de metal' },
    { code: 3103900, description: 'Fabricação de móveis de outros materiais, exceto madeira e metal' },
    { code: 3104700, description: 'Fabricação de colchões' },
    { code: 3211601, description: 'Lapidação de gemas' },
    { code: 3211602, description: 'Fabricação de artefatos de joalheria e ourivesaria' },
    { code: 3211603, description: 'Cunhagem de moedas e medalhas' },
    { code: 3212400, description: 'Fabricação de bijuterias e artefatos semelhantes' },
    { code: 3220500, description: 'Fabricação de instrumentos musicais, peças e acessórios' },
    { code: 3230200, description: 'Fabricação de artefatos para pesca e esporte' },
    { code: 3240001, description: 'Fabricação de jogos eletrônicos' },
    { code: 3240002, description: 'Fabricação de mesas de bilhar, de sinuca e acessórios não associada à locação' },
    { code: 3240003, description: 'Fabricação de mesas de bilhar, de sinuca e acessórios associada à locação' },
    { code: 3240099, description: 'Fabricação de outros brinquedos e jogos recreativos não especificados anteriormente' },
    { code: 3250701, description: 'Fabricação de instrumentos não-eletrônicos e utensílios para uso médico, cirúrgico, odontológico e de laboratório' },
    { code: 3250702, description: 'Fabricação de mobiliário para uso médico, cirúrgico, odontológico e de laboratório' },
    { code: 3250703, description: 'Fabricação de aparelhos e utensílios para correção de defeitos físicos e aparelhos ortopédicos em geral sob encomenda' },
    { code: 3250704, description: 'Fabricação de aparelhos e utensílios para correção de defeitos físicos e aparelhos ortopédicos em geral, exceto sob encomenda' },
    { code: 3250705, description: 'Fabricação de materiais para medicina e odontologia' },
    { code: 3250707, description: 'Fabricação de artigos ópticos' },
    { code: 3291400, description: 'Fabricação de escovas, pincéis e vassouras' },
    { code: 3292201, description: 'Fabricação de roupas de proteção e segurança e resistentes a fogo' },
    { code: 3292202, description: 'Fabricação de equipamentos e acessórios para segurança pessoal e profissional' },
    { code: 3299001, description: 'Fabricação de guarda-chuvas e similares' },
    { code: 3299002, description: 'Fabricação de canetas, lápis e outros artigos para escritório' },
    { code: 3299003, description: 'Fabricação de letras, letreiros e placas de qualquer material, exceto luminosos' },
    { code: 3299004, description: 'Fabricação de painéis e letreiros luminosos' },
    { code: 3299005, description: 'Fabricação de aviamentos para costura' },
    { code: 3299006, description: 'Fabricação de velas, inclusive decorativas' },
    { code: 3299099, description: 'Fabricação de produtos diversos não especificados anteriormente' },
    { code: 3311200, description: 'Manutenção e reparação de tanques, reservatórios metálicos e caldeiras, exceto para veículos' },
    { code: 3312102, description: 'Manutenção e reparação de aparelhos e instrumentos de medida, teste e controle' },
    { code: 3312103, description: 'Manutenção e reparação de aparelhos eletromédicos e eletroterapêuticos e equipamentos de irradiação' },
    { code: 3312104, description: 'Manutenção e reparação de equipamentos e instrumentos ópticos' },
    { code: 3313901, description: 'Manutenção e reparação de geradores, transformadores e motores elétricos' },
    { code: 3313902, description: 'Manutenção e reparação de baterias e acumuladores elétricos, exceto para veículos' },
    { code: 3313999, description: 'Manutenção e reparação de máquinas, aparelhos e materiais elétricos não especificados anteriormente' },
    { code: 3314701, description: 'Manutenção e reparação de máquinas motrizes não-elétricas' },
    { code: 3314702, description: 'Manutenção e reparação de equipamentos hidráulicos e pneumáticos, exceto válvulas' },
    { code: 3314703, description: 'Manutenção e reparação de válvulas industriais' },
    { code: 3314704, description: 'Manutenção e reparação de compressores' },
    { code: 3314705, description: 'Manutenção e reparação de equipamentos de transmissão para fins industriais' },
    { code: 3314706, description: 'Manutenção e reparação de máquinas, aparelhos e equipamentos para instalações térmicas' },
    { code: 3314707, description: 'Manutenção e reparação de máquinas e aparelhos de refrigeração e ventilação para uso industrial e comercial' },
    { code: 3314708, description: 'Manutenção e reparação de máquinas, equipamentos e aparelhos para transporte e elevação de cargas' },
    { code: 3314709, description: 'Manutenção e reparação de máquinas de escrever, calcular e de outros equipamentos não-eletrônicos para escritório' },
    { code: 3314710, description: 'Manutenção e reparação de máquinas e equipamentos para uso geral não especificados anteriormente' },
    { code: 3314711, description: 'Manutenção e reparação de máquinas e equipamentos para agricultura e pecuária' },
    { code: 3314712, description: 'Manutenção e reparação de tratores agrícolas' },
    { code: 3314713, description: 'Manutenção e reparação de máquinas-ferramenta' },
    { code: 3314714, description: 'Manutenção e reparação de máquinas e equipamentos para a prospecção e extração de petróleo' },
    { code: 3314715, description: 'Manutenção e reparação de máquinas e equipamentos para uso na extração mineral, exceto na extração de petróleo' },
    { code: 3314716, description: 'Manutenção e reparação de tratores, exceto agrícolas' },
    { code: 3314717, description: 'Manutenção e reparação de máquinas e equipamentos de terraplenagem, pavimentação e construção, exceto tratores' },
    { code: 3314718, description: 'Manutenção e reparação de máquinas para a indústria metalúrgica, exceto máquinas-ferramenta' },
    { code: 3314719, description: 'Manutenção e reparação de máquinas e equipamentos para as indústrias de alimentos, bebidas e fumo' },
    { code: 3314720, description: 'Manutenção e reparação de máquinas e equipamentos para a indústria têxtil, do vestuário, do couro e calçados' },
    { code: 3314721, description: 'Manutenção e reparação de máquinas e aparelhos para a indústria de celulose, papel e papelão e artefatos' },
    { code: 3314722, description: 'Manutenção e reparação de máquinas e aparelhos para a indústria do plástico' },
    { code: 3314799, description: 'Manutenção e reparação de outras máquinas e equipamentos para usos industriais não especificados anteriormente' },
    { code: 3315500, description: 'Manutenção e reparação de veículos ferroviários' },
    { code: 3316301, description: 'Manutenção e reparação de aeronaves, exceto a manutenção na pista' },
    { code: 3316302, description: 'Manutenção de aeronaves na pista' },
    { code: 3317101, description: 'Manutenção e reparação de embarcações e estruturas flutuantes' },
    { code: 3317102, description: 'Manutenção e reparação de embarcações para esporte e lazer' },
    { code: 3319800, description: 'Manutenção e reparação de equipamentos e produtos não especificados anteriormente' },
    { code: 3321000, description: 'Instalação de máquinas e equipamentos industriais' },
    { code: 3329501, description: 'Serviços de montagem de móveis de qualquer material' },
    { code: 3329599, description: 'Instalação de outros equipamentos não especificados anteriormente' },
    { code: 3520401, description: 'Produção de gás processamento de gás natural' },
    { code: 3520402, description: 'Distribuição de combustíveis gasosos por redes urbanas' },
    { code: 3530100, description: 'Produção e distribuição de vapor, água quente e ar condicionado' },
    { code: 3600601, description: 'Captação, tratamento e distribuição de água' },
    { code: 3600602, description: 'Distribuição de água por caminhões' },
    { code: 3701100, description: 'Gestão de redes de esgoto' },
    { code: 3702900, description: 'Atividades relacionadas a esgoto, exceto a gestão de redes' },
    { code: 3811400, description: 'Coleta de resíduos não-perigosos' },
    { code: 3812200, description: 'Coleta de resíduos perigosos' },
    { code: 3821100, description: 'Tratamento e disposição de resíduos não perigosos' },
    { code: 3822000, description: 'Tratamento e disposição de resíduos perigosos' },
    { code: 3831901, description: 'Recuperação de sucatas de alumínio' },
    { code: 3831999, description: 'Recuperação de materiais metálicos, exceto alumínio' },
    { code: 3832700, description: 'Recuperação de materiais plásticos' },
    { code: 3839401, description: 'Usinas de compostagem' },
    { code: 3839499, description: 'Recuperação de materiais não especificados anteriormente' },
    { code: 3900500, description: 'Descontaminação e outros serviços de gestão de resíduos' },
    { code: 4120400, description: 'Construção de edifícios' },
    { code: 4211101, description: 'Construção de rodovias e ferrovias' },
    { code: 4211102, description: 'Pintura para sinalização em pistas rodoviárias e aeroportos' },
    { code: 4212000, description: 'Construção de obras-de-arte especiais' },
    { code: 4213800, description: 'Obras de urbanização - ruas, praças e calçadas' },
    { code: 4221901, description: 'Construção de barragens e represas para geração de energia elétrica' },
    { code: 4221902, description: 'Construção de estações e redes de distribuição de energia elétrica' },
    { code: 4221903, description: 'Manutenção de redes de distribuição de energia elétrica' },
    { code: 4221904, description: 'Construção de estações e redes de telecomunicações' },
    { code: 4221905, description: 'Manutenção de estações e redes de telecomunicações' },
    { code: 4222701, description: 'Construção de redes de abastecimento de água, coleta de esgoto e construções correlatas, exceto obras de irrigação' },
    { code: 4222702, description: 'Obras de irrigação' },
    { code: 4223500, description: 'Construção de redes de transportes por dutos, exceto para água e esgoto' },
    { code: 4291000, description: 'Obras portuárias, marítimas e fluviais' },
    { code: 4292801, description: 'Montagem de estruturas metálicas' },
    { code: 4292802, description: 'Obras de montagem industrial' },
    { code: 4299501, description: 'Construção de instalações esportivas e recreativas' },
    { code: 4299599, description: 'Outras obras de engenharia civil não especificadas anteriormente' },
    { code: 4311801, description: 'Demolição de edifícios e outras estruturas' },
    { code: 4311802, description: 'Preparação de canteiro e limpeza de terreno' },
    { code: 4312600, description: 'Perfurações e sondagens' },
    { code: 4313400, description: 'Obras de terraplenagem' },
    { code: 4319300, description: 'Serviços de preparação do terreno não especificados anteriormente' },
    { code: 4321500, description: 'Instalação e manutenção elétrica' },
    { code: 4322301, description: 'Instalações hidráulicas, sanitárias e de gás' },
    { code: 4322302, description: 'Instalação e manutenção de sistemas centrais de ar condicionado, de ventilação e refrigeração' },
    { code: 4322303, description: 'Instalações de sistema de prevenção contra incêndio' },
    { code: 4329101, description: 'Instalação de painéis publicitários' },
    { code: 4329102, description: 'Instalação de equipamentos para orientação à navegação marítima, fluvial e lacustre' },
    { code: 4329103, description: 'Instalação, manutenção e reparação de elevadores, escadas e esteiras rolantes' },
    { code: 4329104, description: 'Montagem e instalação de sistemas e equipamentos de iluminação e sinalização em vias públicas, portos e aeroportos' },
    { code: 4329105, description: 'Tratamentos térmicos, acústicos ou de vibração' },
    { code: 4329199, description: 'Outras obras de instalações em construções não especificadas anteriormente' },
    { code: 4330401, description: 'Impermeabilização em obras de engenharia civil' },
    { code: 4330402, description: 'Instalação de portas, janelas, tetos, divisórias e armários embutidos de qualquer material' },
    { code: 4330403, description: 'Obras de acabamento em gesso e estuque' },
    { code: 4330404, description: 'Serviços de pintura de edifícios em geral' },
    { code: 4330405, description: 'Aplicação de revestimentos e de resinas em interiores e exteriores' },
    { code: 4330499, description: 'Outras obras de acabamento da construção' },
    { code: 4391600, description: 'Obras de fundações' },
    { code: 4399102, description: 'Montagem e desmontagem de andaimes e outras estruturas temporárias' },
    { code: 4399103, description: 'Obras de alvenaria' },
    { code: 4399104, description: 'Serviços de operação e fornecimento de equipamentos para transporte e elevação de cargas e pessoas para uso em obras' },
    { code: 4399105, description: 'Perfuração e construção de poços de água' },
    { code: 4399199, description: 'Serviços especializados para construção não especificados anteriormente' },
    { code: 4511101, description: 'Comércio a varejo de automóveis, camionetas e utilitários novos' },
    { code: 4511102, description: 'Comércio a varejo de automóveis, camionetas e utilitários usados' },
    { code: 4511103, description: 'Comércio por atacado de automóveis, camionetas e utilitários novos e usados' },
    { code: 4511104, description: 'Comércio por atacado de caminhões novos e usados' },
    { code: 4511105, description: 'Comércio por atacado de reboques e semi-reboques novos e usados' },
    { code: 4511106, description: 'Comércio por atacado de ônibus e microônibus novos e usados' },
    { code: 4512902, description: 'Comércio sob consignação de veículos automotores' },
    { code: 4520001, description: 'Serviços de manutenção e reparação mecânica de veículos automotores' },
    { code: 4520002, description: 'Serviços de lanternagem ou funilaria e pintura de veículos automotores' },
    { code: 4520003, description: 'Serviços de manutenção e reparação elétrica de veículos automotores' },
    { code: 4520004, description: 'Serviços de alinhamento e balanceamento de veículos automotores' },
    { code: 4520005, description: 'Serviços de lavagem, lubrificação e polimento de veículos automotores' },
    { code: 4520006, description: 'Serviços de borracharia para veículos automotores' },
    { code: 4520007, description: 'Serviços de instalação, manutenção e reparação de acessórios para veículos automotores' },
    { code: 4520008, description: 'Serviços de capotaria' },
    { code: 4530701, description: 'Comércio por atacado de peças e acessórios novos para veículos automotores' },
    { code: 4541201, description: 'Comércio por atacado de motocicletas e motonetas' },
    { code: 4541203, description: 'Comércio a varejo de motocicletas e motonetas novas' },
    { code: 4541204, description: 'Comércio a varejo de motocicletas e motonetas usadas' },
    { code: 4542102, description: 'Comércio sob consignação de motocicletas e motonetas' },
    { code: 4543900, description: 'Manutenção e reparação de motocicletas e motonetas' },
    { code: 4621400, description: 'Comércio atacadista de café em grão' },
    { code: 4622200, description: 'Comércio atacadista de soja' },
    { code: 4623101, description: 'Comércio atacadista de animais vivos' },
    { code: 4623102, description: 'Comércio atacadista de couros, lãs, peles e outros subprodutos não-comestíveis de origem animal' },
    { code: 4623103, description: 'Comércio atacadista de algodão' },
    { code: 4623104, description: 'Comércio atacadista de fumo em folha não beneficiado' },
    { code: 4623105, description: 'Comércio atacadista de cacau' },
    { code: 4623106, description: 'Comércio atacadista de sementes, flores, plantas e gramas' },
    { code: 4623107, description: 'Comércio atacadista de sisal' },
    { code: 4623108, description: 'Comércio atacadista de matérias-primas agrícolas com atividade de fracionamento e acondicionamento associada' },
    { code: 4623109, description: 'Comércio atacadista de alimentos para animais' },
    { code: 4623199, description: 'Comércio atacadista de matérias-primas agrícolas não especificadas anteriormente' },
    { code: 4631100, description: 'Comércio atacadista de leite e laticínios' },
    { code: 4632001, description: 'Comércio atacadista de cereais e leguminosas beneficiados' },
    { code: 4632002, description: 'Comércio atacadista de farinhas, amidos e féculas' },
    { code: 4632003, description: 'Comércio atacadista de cereais e leguminosas beneficiados, farinhas, amidos e féculas, com atividade de fracionamento e acondicionamento associada' },
    { code: 4633801, description: 'Comércio atacadista de frutas, verduras, raízes, tubérculos, hortaliças e legumes frescos' },
    { code: 4633802, description: 'Comércio atacadista de aves vivas e ovos' },
    { code: 4633803, description: 'Comércio atacadista de coelhos e outros pequenos animais vivos para alimentação' },
    { code: 4634601, description: 'Comércio atacadista de carnes bovinas e suínas e derivados' },
    { code: 4634602, description: 'Comércio atacadista de aves abatidas e derivados' },
    { code: 4634603, description: 'Comércio atacadista de pescados e frutos do mar' },
    { code: 4634699, description: 'Comércio atacadista de carnes e derivados de outros animais' },
    { code: 4635403, description: 'Comércio atacadista de bebidas com atividade de fracionamento e acondicionamento associada' },
    { code: 4635499, description: 'Comércio atacadista de bebidas não especificadas anteriormente' },
    { code: 4636201, description: 'Comércio atacadista de fumo beneficiado' },
    { code: 4637101, description: 'Comércio atacadista de café torrado, moído e solúvel' },
    { code: 4637102, description: 'Comércio atacadista de açúcar' },
    { code: 4637103, description: 'Comércio atacadista de óleos e gorduras' },
    { code: 4639702, description: 'Comércio atacadista de produtos alimentícios em geral, com atividade de fracionamento e acondicionamento associada' },
    { code: 4644301, description: 'Comércio atacadista de medicamentos e drogas de uso humano' },
    { code: 4644302, description: 'Comércio atacadista de medicamentos e drogas de uso veterinário' },
    { code: 4645101, description: 'Comércio atacadista de instrumentos e materiais para uso médico, cirúrgico, hospitalar e de laboratórios' },
    { code: 4645102, description: 'Comércio atacadista de próteses e artigos de ortopedia' },
    { code: 4645103, description: 'Comércio atacadista de produtos odontológicos' },
    { code: 4661300, description: 'Comércio atacadista de máquinas, aparelhos e equipamentos para uso agropecuário; partes e peças' },
    { code: 4662100, description: 'Comércio atacadista de máquinas, equipamentos para terraplenagem, mineração e construção; partes e peças' },
    { code: 4663000, description: 'Comércio atacadista de máquinas e equipamentos para uso industrial partes e peças' },
    { code: 4664800, description: 'Comércio atacadista de máquinas, aparelhos e equipamentos para uso odonto-médico-hospitalar; partes e peças' },
    { code: 4665600, description: 'Comércio atacadista de máquinas e equipamentos para uso comercial partes e peças' },
    { code: 4669901, description: 'Comércio atacadista de bombas e compressores partes e peças' },
    { code: 4669999, description: 'Comércio atacadista de outras máquinas e equipamentos não especificados anteriormente partes e peças' },
    { code: 4671100, description: 'Comércio atacadista de madeira e produtos derivados' },
    { code: 4672900, description: 'Comércio atacadista de ferragens e ferramentas' },
    { code: 4673700, description: 'Comércio atacadista de material elétrico' },
    { code: 4674500, description: 'Comércio atacadista de cimento' },
    { code: 4679601, description: 'Comércio atacadista de tintas, vernizes e similares' },
    { code: 4679602, description: 'Comércio atacadista de mármores e granitos' },
    { code: 4679604, description: 'Comércio atacadista especializado de materiais de construção não especificados anteriormente' },
    { code: 4679699, description: 'Comércio atacadista de materiais de construção em geral' },
    { code: 4681801, description: 'Comércio atacadista de álcool carburante, biodiesel, gasolina e demais derivados de petróleo, exceto lubrificantes, não realizado por transportador retalhista (trr)' },
    { code: 4681802, description: 'Comércio atacadista de combustíveis realizado por transportador retalhista (trr)' },
    { code: 4681803, description: 'Comércio atacadista de combustíveis de origem vegetal, exceto álcool carburante' },
    { code: 4681804, description: 'Comércio atacadista de combustíveis de origem mineral em bruto' },
    { code: 4681805, description: 'Comércio atacadista de lubrificantes' },
    { code: 4682600, description: 'Comércio atacadista de gás liqüefeito de petróleo (glp)' },
    { code: 4683400, description: 'Comércio atacadista de defensivos agrícolas, adubos, fertilizantes e corretivos do solo' },
    { code: 4684201, description: 'Comércio atacadista de resinas e elastômeros' },
    { code: 4684202, description: 'Comércio atacadista de solventes' },
    { code: 4684299, description: 'Comércio atacadista de outros produtos químicos e petroquímicos não especificados anteriormente' },
    { code: 4685100, description: 'Comércio atacadista de produtos siderúrgicos e metalúrgicos, exceto para construção' },
    { code: 4686901, description: 'Comércio atacadista de papel e papelão em bruto' },
    { code: 4687701, description: 'Comércio atacadista de resíduos de papel e papelão' },
    { code: 4687702, description: 'Comércio atacadista de resíduos e sucatas não-metálicos, exceto de papel e papelão' },
    { code: 4687703, description: 'Comércio atacadista de resíduos e sucatas metálicos' },
    { code: 4689301, description: 'Comércio atacadista de produtos da extração mineral, exceto combustíveis' },
    { code: 4689302, description: 'Comércio atacadista de fios e fibras beneficiados' },
    { code: 4689399, description: 'Comércio atacadista especializado em outros produtos intermediários não especificados anteriormente' },
    { code: 4692300, description: 'Comércio atacadista de mercadorias em geral, com predominância de insumos agropecuários' },
    { code: 4713005, description: 'Lojas francas (duty free) de aeroportos, portos e em fronteiras terrestres' },
    { code: 4722901, description: 'Comércio varejista de carnes - açougues' },
    { code: 4722902, description: 'Peixaria' },
    { code: 4729602, description: 'Comércio varejista de mercadorias em lojas de conveniência' },
    { code: 4731800, description: 'Comércio varejista de combustíveis para veículos automotores' },
    { code: 4732600, description: 'Comércio varejista de lubrificantes' },
    { code: 4741500, description: 'Comércio varejista de tintas e materiais para pintura' },
    { code: 4742300, description: 'Comércio varejista de material elétrico' },
    { code: 4744001, description: 'Comércio varejista de ferragens e ferramentas' },
    { code: 4744003, description: 'Comércio varejista de materiais hidráulicos' },
    { code: 4744004, description: 'Comércio varejista de cal, areia, pedra britada, tijolos e telhas' },
    { code: 4744005, description: 'Comércio varejista de materiais de construção não especificados anteriormente' },
    { code: 4744006, description: 'Comércio varejista de pedras para revestimento' },
    { code: 4744099, description: 'Comércio varejista de materiais de construção em geral' },
    { code: 4763605, description: 'Comércio varejista de embarcações e outros veículos recreativos peças e acessórios' },
    { code: 4771701, description: 'Comércio varejista de produtos farmacêuticos, sem manipulação de fórmulas' },
    { code: 4771702, description: 'Comércio varejista de produtos farmacêuticos, com manipulação de fórmulas' },
    { code: 4771703, description: 'Comércio varejista de produtos farmacêuticos homeopáticos' },
    { code: 4771704, description: 'Comércio varejista de medicamentos veterinários' },
    { code: 4773300, description: 'Comércio varejista de artigos médicos e ortopédicos' },
    { code: 4783101, description: 'Comércio varejista de artigos de joalheria' },
    { code: 4784900, description: 'Comércio varejista de gás liqüefeito de petróleo (glp)' },
    { code: 4789004, description: 'Comércio varejista de animais vivos e de artigos e alimentos para animais de estimação' },
    { code: 4789005, description: 'Comércio varejista de produtos saneantes domissanitários' },
    { code: 4789006, description: 'Comércio varejista de fogos de artifício e artigos pirotécnicos' },
    { code: 4789009, description: 'Comércio varejista de armas e munições' },
    { code: 4911600, description: 'Transporte ferroviário de carga' },
    { code: 4912402, description: 'Transporte ferroviário de passageiros municipal e em região metropolitana' },
    { code: 4912403, description: 'Transporte metroviário' },
    { code: 4921301, description: 'Transporte rodoviário coletivo de passageiros, com itinerário fixo, municipal' },
    { code: 4921302, description: 'Transporte rodoviário coletivo de passageiros, com itinerário fixo, intermunicipal em região metropolitana' },
    { code: 4922103, description: 'Transporte rodoviário coletivo de passageiros, com itinerário fixo, internacional' },
    { code: 4923001, description: 'Serviço de táxi' },
    { code: 4923002, description: 'Serviço de transporte de passageiros - locação de automóveis com motorista' },
    { code: 4924800, description: 'Transporte escolar' },
    { code: 4929901, description: 'Transporte rodoviário coletivo de passageiros, sob regime de fretamento, municipal' },
    { code: 4929902, description: 'Transporte rodoviário coletivo de passageiros, sob regime de fretamento, intermunicipal, interestadual e internacional' },
    { code: 4929903, description: 'Organização de excursões em veículos rodoviários próprios, municipal' },
    { code: 4929904, description: 'Organização de excursões em veículos rodoviários próprios, intermunicipal, interestadual e internacional' },
    { code: 4930201, description: 'Transporte rodoviário de carga, exceto produtos perigosos e mudanças, municipal' },
    { code: 4930201, description: 'Transporte rodoviário de carga, exceto produtos perigosos e mudanças, municipal' },
    { code: 4930202, description: 'Transporte rodoviário de carga, exceto produtos perigosos e mudanças, intermunicipal, interestadual e internacional' },
    { code: 4930203, description: 'Transporte rodoviário de produtos perigosos' },
    { code: 4930204, description: 'Transporte rodoviário de mudanças' },
    { code: 4940000, description: 'Transporte dutoviário' },
    { code: 4950700, description: 'Trens turísticos, teleféricos e similares' },
    { code: 5011401, description: 'Transporte marítimo de cabotagem - carga' },
    { code: 5011402, description: 'Transporte marítimo de cabotagem - passageiros' },
    { code: 5012201, description: 'Transporte marítimo de longo curso - carga' },
    { code: 5012202, description: 'Transporte marítimo de longo curso - passageiros' },
    { code: 5021101, description: 'Transporte por navegação interior de carga, municipal, exceto travessia' },
    { code: 5021102, description: 'Transporte por navegação interior de carga, intermunicipal, interestadual e internacional, exceto travessia' },
    { code: 5022001, description: 'Transporte por navegação interior de passageiros em linhas regulares, municipal, exceto travessia' },
    { code: 5022002, description: 'Transporte por navegação interior de passageiros em linhas regulares, intermunicipal, interestadual e internacional, exceto travessia' },
    { code: 5030101, description: 'Navegação de apoio marítimo' },
    { code: 5030102, description: 'Navegação de apoio portuário' },
    { code: 5030103, description: 'Serviço de rebocadores e empurradores' },
    { code: 5091201, description: 'Transporte por navegação de travessia, municipal' },
    { code: 5091202, description: 'Transporte por navegação de travessia intermunicipal, interestadual e internacional' },
    { code: 5099801, description: 'Transporte aquaviário para passeios turísticos' },
    { code: 5099899, description: 'Outros transportes aquaviários não especificados anteriormente' },
    { code: 5111100, description: 'Transporte aéreo de passageiros regular' },
    { code: 5112901, description: 'Serviço de táxi aéreo e locação de aeronaves com tripulação' },
    { code: 5112999, description: 'Outros serviços de transporte aéreo de passageiros não-regular' },
    { code: 5120000, description: 'Transporte aéreo de carga' },
    { code: 5130700, description: 'Transporte espacial' },
    { code: 5211701, description: 'Armazéns gerais - emissão de warrant' },
    { code: 5211702, description: 'Guarda-móveis' },
    { code: 5211799, description: 'Depósitos de mercadorias para terceiros, exceto armazéns gerais e guarda-móveis' },
    { code: 5212500, description: 'Carga e descarga' },
    { code: 5221400, description: 'Concessionárias de rodovias, pontes, túneis e serviços relacionados' },
    { code: 5222200, description: 'Terminais rodoviários e ferroviários' },
    { code: 5223100, description: 'Estacionamento de veículos' },
    { code: 5229001, description: 'Serviços de apoio ao transporte por táxi, inclusive centrais de chamada' },
    { code: 5229002, description: 'Serviços de reboque de veículos' },
    { code: 5229099, description: 'Outras atividades auxiliares dos transportes terrestres não especificadas anteriormente' },
    { code: 5231101, description: 'Administração da infraestrutura portuária' },
    { code: 5231102, description: 'Atividades do operador portuário' },
    { code: 5231103, description: 'Gestão de terminais aquaviários' },
    { code: 5232000, description: 'Atividades de agenciamento marítimo' },
    { code: 5239701, description: 'Serviços de praticagem' },
    { code: 5239799, description: 'Atividades auxiliares dos transportes aquaviários não especificadas anteriormente' },
    { code: 5240101, description: 'Operação dos aeroportos e campos de aterrissagem' },
    { code: 5240199, description: 'Atividades auxiliares dos transportes aéreos, exceto operação dos aeroportos e campos de aterrissagem' },
    { code: 5250801, description: 'Comissaria de despachos' },
    { code: 5250802, description: 'Atividades de despachantes aduaneiros' },
    { code: 5250803, description: 'Agenciamento de cargas, exceto para o transporte marítimo' },
    { code: 5250804, description: 'Organização logística do transporte de carga' },
    { code: 5250805, description: 'Operador de transporte multimodal - otm' },
    { code: 5310502, description: 'Atividades de franqueadas e permissionárias do correio nacional' },
    { code: 5320201, description: 'Serviços de malote não realizados pelo correio nacional' },
    { code: 5320202, description: 'Serviços de entrega rápida' },
    { code: 5510801, description: 'Hotéis' },
    { code: 5510802, description: 'Apart-hotéis' },
    { code: 5510803, description: 'Motéis' },
    { code: 5590601, description: 'Albergues, exceto assistenciais' },
    { code: 5590602, description: 'Campings' },
    { code: 5590603, description: 'Pensões (alojamento)' },
    { code: 5590699, description: 'Outros alojamentos não especificados anteriormente' },
    { code: 5611205, description: 'Bares e outros estabelecimentos especializados em servir bebidas, com entretenimento' },
    { code: 5612100, description: 'Serviços ambulantes de alimentação' },
    { code: 5620102, description: 'Serviços de alimentação para eventos e recepções - bufê' },
    { code: 5620102, description: 'Serviços de alimentação para eventos e recepções - bufê' },
    { code: 5811500, description: 'Edição de livros' },
    { code: 5812301, description: 'Edição de jornais diários' },
    { code: 5812302, description: 'Edição de jornais não diários' },
    { code: 5813100, description: 'Edição de revistas' },
    { code: 5819100, description: 'Edição de cadastros, listas e outros produtos gráficos' },
    { code: 5821200, description: 'Edição integrada à impressão de livros' },
    { code: 5822101, description: 'Edição integrada à impressão de jornais diários' },
    { code: 5822102, description: 'Edição integrada à impressão de jornais não diários' },
    { code: 5823900, description: 'Edição integrada à impressão de revistas' },
    { code: 5829800, description: 'Edição integrada à impressão de cadastros, listas e outros produtos gráficos' },
    { code: 6010100, description: 'Atividades de rádio' },
    { code: 6021700, description: 'Atividades de televisão aberta' },
    { code: 6022501, description: 'Programadoras' },
    { code: 6022502, description: 'Atividades relacionadas à televisão por assinatura, exceto programadoras' },
    { code: 6110801, description: 'Serviços de telefonia fixa comutada - stfc' },
    { code: 6110802, description: 'Serviços de redes de transporte de telecomunicações - srtt' },
    { code: 6110803, description: 'Serviços de comunicação multimídia - scm' },
    { code: 6110899, description: 'Serviços de telecomunicações por fio não especificados anteriormente' },
    { code: 6120501, description: 'Telefonia móvel celular' },
    { code: 6120502, description: 'Serviço móvel especializado - sme' },
    { code: 6120599, description: 'Serviços de telecomunicações sem fio não especificados anteriormente' },
    { code: 6130200, description: 'Telecomunicações por satélite' },
    { code: 6141800, description: 'Operadoras de televisão por assinatura por cabo' },
    { code: 6142600, description: 'Operadoras de televisão por assinatura por microondas' },
    { code: 6143400, description: 'Operadoras de televisão por assinatura por satélite' },
    { code: 6190601, description: 'Provedores de acesso às redes de comunicações' },
    { code: 6190602, description: 'Provedores de voz sobre protocolo internet - voip' },
    { code: 6190699, description: 'Outras atividades de telecomunicações não especificadas anteriormente' },
    { code: 6493000, description: 'Administração de consórcios para aquisição de bens e direitos' },
    { code: 6550200, description: 'Planos de saúde' },
    { code: 6613400, description: 'Administração de cartões de crédito' },
    { code: 6619305, description: 'Operadoras de cartões de débito' },
    { code: 6619399, description: 'Outras atividades auxiliares dos serviços financeiros não especificadas anteriormente' },
    { code: 6630400, description: 'Atividades de administração de fundos por contrato ou comissão' },
    { code: 6810201, description: 'Compra e venda de imóveis próprios' },
    { code: 6822600, description: 'Gestão e administração da propriedade imobiliária' },
    { code: 6822600, description: 'Gestão e administração da propriedade imobiliária' },
    { code: 6920601, description: 'Atividades de contabilidade' },
    { code: 6920602, description: 'Atividades de consultoria e auditoria contábil e tributária' },
    { code: 7711000, description: 'Locação de automóveis sem condutor' },
    { code: 7719501, description: 'Locação de embarcações sem tripulação, exceto para fins recreativos' },
    { code: 7719502, description: 'Locação de aeronaves sem tripulação' },
    { code: 7719599, description: 'Locação de outros meios de transporte não especificados anteriormente, sem condutor' },
    { code: 7810800, description: 'Seleção e agenciamento de mão-de-obra' },
    { code: 8011101, description: 'Atividades de vigilância e segurança privada' },
    { code: 8012900, description: 'Atividades de transporte de valores' },
    { code: 8020001, description: 'Atividades de monitoramento de sistemas de segurança eletrônico' },
    { code: 8020002, description: 'Outras atividades de serviços de segurança' },
    { code: 8111700, description: 'Serviços combinados para apoio a edifícios, exceto condomínios prediais' },
    { code: 8219901, description: 'Fotocópias' },
    { code: 8292000, description: 'Envasamento e empacotamento sob contrato' },
    { code: 8299702, description: 'Emissão de vales alimentação, vales transporte e similares' },
    { code: 8299705, description: 'Serviços de levantamento de fundos sob contrato' },
    { code: 8299706, description: 'Casas lotéricas' },
    { code: 8599601, description: 'Formação de condutores' },
    { code: 8599602, description: 'Cursos de pilotagem' },
    { code: 8610102, description: 'Atividades de atendimento em pronto-socorro e unidades hospitalares para atendimento a urgências' },
    { code: 8621601, description: 'Uti móvel' },
    { code: 8621602, description: 'Serviços móveis de atendimento a urgências, exceto por uti móvel' },
    { code: 8622400, description: 'Serviços de remoção de pacientes, exceto os serviços móveis de atendimento a urgências' },
    { code: 8690902, description: 'Atividades de bancos de leite humano' },
    { code: 8711501, description: 'Clínicas e residências geriátricas' },
    { code: 8711502, description: 'Instituições de longa permanência para idosos' },
    { code: 8711503, description: 'Atividades de assistência a deficientes físicos, imunodeprimidos e convalescentes' },
    { code: 8711504, description: 'Centros de apoio a pacientes com câncer e com aids' },
    { code: 8711505, description: 'Condomínios residenciais para idosos' },
    { code: 8712300, description: 'Atividades de fornecimento de infra-estrutura de apoio e assistência a paciente no domicílio' },
    { code: 8720401, description: 'Atividades de centros de assistência psicossocial' },
    { code: 8720499, description: 'Atividades de assistência psicossocial e à saúde a portadores de distúrbios psíquicos, deficiência mental e dependência química não especificadas anteriormente' },
    { code: 8730101, description: 'Orfanatos' },
    { code: 8730102, description: 'Albergues assistenciais' },
    { code: 8730199, description: 'Atividades de assistência social prestadas em residências coletivas e particulares não especificadas anteriormente' },
    { code: 8800600, description: 'Serviços de assistência social sem alojamento' },
    { code: 9003500, description: 'Gestão de espaços para artes cênicas, espetáculos e outras atividades artísticas' },
    { code: 9101500, description: 'Atividades de bibliotecas e arquivos' },
    { code: 9102301, description: 'Atividades de museus e de exploração de lugares e prédios históricos e atrações similares' },
    { code: 9102302, description: 'Restauração e conservação de lugares e prédios históricos' },
    { code: 9103100, description: 'Atividades de jardins botânicos, zoológicos, parques nacionais, reservas ecológicas e áreas de proteção ambiental' },
    { code: 9200301, description: 'Casas de bingo' },
    { code: 9200302, description: 'Exploração de apostas em corridas de cavalos' },
    { code: 9200399, description: 'Exploração de jogos de azar e apostas não especificados anteriormente' },
    { code: 9312300, description: 'Clubes sociais, esportivos e similares' },
    { code: 9321200, description: 'Parques de diversão e parques temáticos' },
    { code: 9329801, description: 'Discotecas, danceterias, salões de dança e similares' },
    { code: 9603301, description: 'Gestão e manutenção de cemitérios' },
    { code: 9603302, description: 'Serviços de cremação' },
    { code: 9603303, description: 'Serviços de sepultamento' },
    { code: 9603304, description: 'Serviços de funerárias' },
    { code: 9603305, description: 'Serviços de somatoconservação' },
    { code: 9603399, description: 'Atividades funerárias e serviços relacionados não especificados anteriormente' },
    { code: 9609204, description: 'Exploração de máquinas de serviços pessoais acionadas por moeda' },
    { code: 9700500, description: 'Serviços domésticos' },
    { code: 9900800, description: 'Organismos internacionais e outras instituições extraterritoriais' },
    { code: 4929999, description: 'Outros transportes rodoviários de passageiros não especificados anteriormente' }
];

export { CNAEs };