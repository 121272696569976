import { useDocumentsAPI, useDocumentsCtxPendingDocumentsReviewer } from '../../../../../context/DocumentsContext';
import { useOperatorCtxOperators } from '../../../context/OperatorContext';
import UsersSelector from '../../../../../components/UsersSelector';

const PendingDocumentReviewerSelector = () => {
    const { setState: setDocumentsCtxState } = useDocumentsAPI();
    const selectedPendingDocumentsReviewer = useDocumentsCtxPendingDocumentsReviewer();
    const operators = useOperatorCtxOperators();
    
    const setSelectedUser = (newValue) => {
        setDocumentsCtxState('selectedPendingDocumentsReviewer', newValue);
    };

    return (
        <UsersSelector
            includeNone
            label="Revisor"
            // loading={loading}
            selectedUser={selectedPendingDocumentsReviewer} setSelectedUser={setSelectedUser}
            users={operators}
        />
    );
}

export default PendingDocumentReviewerSelector;