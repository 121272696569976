import React, { useEffect, useRef, useState } from 'react';
import { Box, Container, Divider, List, Slide, TextField } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ChangeFolderInitialResponsesWindow from './ChangeFolderInitialResponsesWindow';
import Dialog from './Dialog/Dialog';
import LoaderEllipsis from './LoaderEllipsis';
import MultipleAutocomplete from './MultipleAutocomplete';
import Select from './Select';
import ShadowedBox from './ShadowedBox';
import { useAppCtxAPI, useAppCtxTemplateLists } from '../context/SystemContext';
import { useSocket, useSocketCtxConnectedAt } from '../context/SocketContext';
import { useClientCtxActiveClient } from '../context/ClientContext';
import { useDocumentsAPI, useDocumentsCtxLoadingTemplates, useDocumentsCtxTemplates } from '../context/DocumentsContext';
import { useOperatorCtxRefs, useOperatorCtxAPI } from '../pages/Operator/context/OperatorContext';
import { useOperatorFoldersCtxAPI, useOperatorFoldersCtxFolderTemplatesView } from '../context/OperatorFoldersContext';
import { copy, getFolderTemplates, getTemplateNameById } from '../utils/common';
import { APP_PATH, ERROR_MESSAGE_CHANGES_UNDONE } from '../utils/constants';
import { sortByKey } from '../utils/filters';
import ShortUniqueId from 'short-unique-id';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ChangeProjectTemplatesWindow = () => {
    const templateLists = useAppCtxTemplateLists();
    const { toast, updateOneOperators } = useAppCtxAPI();
    const { shouldUpdateTemplates } = useSocket();
    const socketConnectedAt = useSocketCtxConnectedAt();
    const activeClient = useClientCtxActiveClient();
    const { dispatch: dispatchDocuments } = useDocumentsAPI();
    const loadingTemplates = useDocumentsCtxLoadingTemplates();
    const templates = useDocumentsCtxTemplates();
    const { updatedProject } = useOperatorCtxRefs();
    const { updateProject } = useOperatorCtxAPI();
    const { hideFolderTemplatesView, setChangeFolderViewFoundFolder, setFolderTemplatesViewSelectedFolder } = useOperatorFoldersCtxAPI();
    const { folderTemplatesViewOpen, folderTemplatesViewSelectedFolder: selectedFolder } = useOperatorFoldersCtxFolderTemplatesView();
    const [saving, setSaving] = useState(false);
    const [selectedLists, set_selectedLists] = useState([]);
    const [includeTemplates, set_includeTemplates] = useState([]);
    const [excludeTemplates, set_excludeTemplates] = useState([]);
    const [folderTemplates, set_folderTemplates] = useState([]);
    const [excludeTemplatesOptions, set_excludeTemplatesOptions] = useState([]);
    const [userCreatedFieldsValues, setUserCreatedFieldsValues] = useState({});
    const [folderFormShortIds, setFolderFormShortIds] = useState(null);
    const [folderTemplateFields, setFolderTemplateFields] = useState([]);
    const folderInitialFormResponses = useRef([]);
    const [selectedTemplateId, setSelectedTemplateId] = useState('');
    const [changeFolderInitialResponsesWindowOpen, setChangeFolderInitialResponsesWindowOpen] = useState(false);

    useEffect(() => {
        if(shouldUpdateTemplates){
            dispatchDocuments({type: 'LOAD TEMPLATES'});
        }
    }, [socketConnectedAt]);

    useEffect(() => {
        if(folderTemplatesViewOpen && selectedFolder){
            if(templates){
                let projectTemplates = selectedFolder.templates;
                if(projectTemplates){
                    let projectTemplatesLists = projectTemplates.lists;
                    if(projectTemplatesLists){
                        projectTemplatesLists = projectTemplatesLists.map(p_l => templateLists.find(l => l._id === p_l));
                        set_selectedLists(projectTemplatesLists);
                    } else {
                        set_selectedLists([]);
                    }
                    let projectTemplatesInclude = projectTemplates.include;
                    if(projectTemplatesInclude){
                        projectTemplatesInclude = projectTemplatesInclude.map(p_t => templates.find(t => t._id === p_t)).filter(template => !!template);
                        set_includeTemplates(projectTemplatesInclude);
                    } else {
                        set_includeTemplates([]);
                    }
                    let projectTemplatesExclude = projectTemplates.exclude;
                    if(projectTemplatesExclude){
                        projectTemplatesExclude = projectTemplatesExclude.map(excludeId => templates.find(t => t._id === excludeId) ? excludeId : null).filter(template => !!template);
                        set_excludeTemplates(projectTemplatesExclude);
                    } else {
                        set_excludeTemplates([]);
                    }
                }
            }
        }
    }, [folderTemplatesViewOpen, selectedFolder, templates]);

    useEffect(() => {
        setFolderFormShortIds(selectedFolder ? (Array.isArray(selectedFolder.formShortIds) ? selectedFolder.formShortIds : []) : null);
    }, [selectedFolder]);

    useEffect(() => {
        folderInitialFormResponses.current = selectedFolder ? selectedFolder.folderInitialFormResponses || [] : [];
    }, [selectedFolder]);

    useEffect(() => {
        if(activeClient && activeClient.userCreatedFields && templates && folderTemplates.length >= 1){
            const userCreatedFields = activeClient.userCreatedFields;
            const currentFolderTemplateFields = [];
            userCreatedFields.forEach(field => {
                if(field.template){
                    let foundFieldTemplateInFolder = false;
                    if(Array.isArray(field.template)){
                        foundFieldTemplateInFolder = folderTemplates.find(template => field.template.includes(template));
                    } else {
                        foundFieldTemplateInFolder = folderTemplates.includes(field.template);
                        field.template = [field.template];
                    }
                    if(foundFieldTemplateInFolder){
                        currentFolderTemplateFields.push({
                            ...field,
                            template: field.template.map(fieldTemplateId => getTemplateNameById(fieldTemplateId, templates, true)).join('; ')
                        });
                    }
                }
            });
            return setFolderTemplateFields(currentFolderTemplateFields);
        }
        return setFolderTemplateFields(null);
    }, [activeClient, templates, folderTemplates]);

    useEffect(() => {
        if(folderTemplatesViewOpen && selectedFolder && selectedFolder.userCreatedFieldsValues){
            let currentUserCreatedFieldsValues = {};
            selectedFolder.userCreatedFieldsValues.forEach(field => currentUserCreatedFieldsValues[field.id] = field.value);
            return setUserCreatedFieldsValues(currentUserCreatedFieldsValues);
        }
        setUserCreatedFieldsValues({});
    }, [folderTemplatesViewOpen, selectedFolder]);

    useEffect(() => {
        if(selectedFolder){
            let newValue = {...selectedFolder.templates};
            newValue.lists = selectedLists.map(t => t._id);
            newValue.include = includeTemplates.map(t => t._id);
            newValue.exclude = excludeTemplates;
            let folderTemplatesNewValue = getFolderTemplates({...selectedFolder, templates: newValue}, templateLists);
            set_folderTemplates(folderTemplatesNewValue);
        }
    }, [selectedFolder, selectedLists, includeTemplates, excludeTemplates]);

    useEffect(() => {
        let listItems = [];
        selectedLists.forEach(list => {
            listItems = [
                ...listItems,
                ...list.list
            ]
        });
        set_excludeTemplatesOptions(listItems);
    }, [selectedLists]);

    const handleTemplateListsChange = (newValue) => {
        set_selectedLists(newValue);
    };
    const handleIncludeTemplatesChange = (newValue) => {
        set_includeTemplates(newValue);
    };
    const handleExcludeTemplatesChange = (newValue) => {
        set_excludeTemplates(newValue);
    };

    const handleStringFieldChange = (fieldId, newValue) => {
        setUserCreatedFieldsValues(prevState => ({...prevState, [fieldId]: newValue}));
    };

    const handleFolderInitialFormResponsesWindowOpenButtonClick = (templateId) => {
        setSelectedTemplateId(templateId);
        setChangeFolderInitialResponsesWindowOpen(true);
    };

    const handleSelectedFolderInitialFormResponsesChange = (templateId, newValue) => {
        const templateIndex = folderInitialFormResponses.current.findIndex(item => item.templateId === templateId);
        if(templateIndex !== -1){
            folderInitialFormResponses.current[templateIndex].questions = newValue;
        } else {
            folderInitialFormResponses.current.push({templateId, questions: newValue});
        }
    };

    const handleSave = async () => {
        setSaving(true);
        let projectCopy = {...selectedFolder};
        let newValue = {
            lists: selectedLists.map(t => t._id),
            include: includeTemplates.map(t => t._id),
            exclude: excludeTemplates
        };

        const currentFolderTemplates = getFolderTemplates({...selectedFolder, templates: newValue}, templateLists);
        let currentFolderFormShortIds = [...folderFormShortIds];
        currentFolderTemplates.forEach(folderTemplateId => {
            const templateIndex = currentFolderFormShortIds.findIndex(prevStateTemplate => prevStateTemplate.templateId === folderTemplateId);
            if(templateIndex !== -1){
                currentFolderFormShortIds[templateIndex].disabled = false;
            } else {
                const shortId = new ShortUniqueId({dictionary: 'alphanum_upper'});
                currentFolderFormShortIds.push({ templateId: folderTemplateId, shortId: shortId(), disabled: false});
            }
        });
        currentFolderFormShortIds = currentFolderFormShortIds.map(formShortId => {
            if(!currentFolderTemplates.includes(formShortId.templateId)) formShortId.disabled = true;
            return formShortId;
        });
        setFolderFormShortIds(currentFolderFormShortIds);

        const currentUserCreatedFieldsValues = Object.keys(userCreatedFieldsValues).filter((key) => !!userCreatedFieldsValues[key]).map((key) => ({ id: key, value: userCreatedFieldsValues[key]}));

        const updates = {
            templates: newValue,
            folderInitialFormResponses: folderInitialFormResponses.current,
            formShortIds: currentFolderFormShortIds,
            userCreatedFieldsValues: currentUserCreatedFieldsValues
        };
        const updatedFolder = {...selectedFolder, ...updates};
        setFolderTemplatesViewSelectedFolder(updatedFolder);
        setChangeFolderViewFoundFolder(updatedFolder);
        try {
            await updateOneOperators('Projects', selectedFolder._id, updates);
            updatedProject.current = updatedFolder;
            updateProject(updatedFolder);
            toast('As informações foram salvas.');
            handleClose();
        } catch (error) {
            setFolderTemplatesViewSelectedFolder(projectCopy);
            setChangeFolderViewFoundFolder(projectCopy);
            toast(ERROR_MESSAGE_CHANGES_UNDONE);
        }
        setSaving(false);
    };

    const handleClose = () => {
        hideFolderTemplatesView();
    };

    const FolderTemplateName = ({templateId}) => {

        const handleClick = (e, foundTemplate) => {
            e.preventDefault();
            copy(`${APP_PATH}formulario/${foundTemplate.shortId}`, toast, 'Link aberto do formulário copiado para a área de transferência');
        };

        const templateName = getTemplateNameById(templateId, templates, true)
        if(folderFormShortIds){
            const foundTemplate = folderFormShortIds.find(template => template.templateId === templateId);
            if(foundTemplate){
                return (
                    <Link
                        variant="body2"
                        href="#"
                        onClick={(e) => handleClick(e, foundTemplate)}
                        underline="hover">{templateName}</Link>
                );
            }
        }
        return (
            <Typography variant="body2">{templateName}</Typography>
        );
    }
    
    const FormLinkButton = ({templateId}) => {
        if(folderFormShortIds){
            const foundTemplate = folderFormShortIds.find(template => template.templateId === templateId);
            if(foundTemplate){
                return (
                    <Grid item>
                        <IconButton size="small" href={`/formulario/${foundTemplate.shortId}`} target="_blank"><OpenInNewIcon /></IconButton>
                    </Grid>
                )
            }
        }
        return null;
    }

    return (
        <>
            <Dialog
                onClose={handleClose}
                onSaveClick={handleSave}
                open={folderTemplatesViewOpen}
                saving={saving}
                title={`${selectedFolder ? `${selectedFolder.name} | ` : ''}Matrizes`}
                TransitionComponent={Transition}
            >

                <Container maxWidth="md">
                    {
                        folderTemplatesViewOpen &&
                        <>
                            {
                                loadingTemplates
                                ? <LoaderEllipsis primary />
                                :
                                <Box>
                                    <Box mb={2}>
                                        {
                                            templates &&
                                            <>
                                                <Box mb={2}>
                                                    <ShadowedBox>
                                                        <List>
                                                            {
                                                                folderTemplates
                                                                .sort((a, b) => {
                                                                    let itemA = getTemplateNameById(a, templates, true);
                                                                    let itemB = getTemplateNameById(b, templates, true);
                                                                    itemA = itemA.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                                                                    itemB = itemB.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                                                                    return (itemA > itemB) ? 1 : ((itemB > itemA) ? -1 : 0);
                                                                })
                                                                .map(folderTemplateId => (
                                                                    <Grid key={folderTemplateId} container spacing={1} alignItems="center" wrap="nowrap">
                                                                        <Grid item xs>
                                                                            <FolderTemplateName templateId={folderTemplateId} />
                                                                        </Grid>
                                                                        <FormLinkButton templateId={folderTemplateId} />
                                                                        <Grid item>
                                                                            <IconButton size="small" onClick={() => handleFolderInitialFormResponsesWindowOpenButtonClick(folderTemplateId)}><QuestionAnswerIcon /></IconButton>
                                                                        </Grid>
                                                                    </Grid>
                                                                ))
                                                            }
                                                        </List>
                                                    </ShadowedBox>
                                                </Box>
                                                <Box>
                                                    {
                                                        templateLists?.length >= 1 &&
                                                        <Box mb={2}>
                                                            <MultipleAutocomplete
                                                                disabled={saving}
                                                                label="Listas"
                                                                value={selectedLists} onChange={(e, newValue) => handleTemplateListsChange(newValue)}
                                                                options={templateLists.sort(sortByKey('name'))} getOptionLabel={(option) => option.name}
                                                            />
                                                        </Box>
                                                    }
                                                    <Box mb={2}>
                                                        <MultipleAutocomplete
                                                            disabled={saving}
                                                            label="Incluir"
                                                            value={includeTemplates} onChange={(e, newValue) => handleIncludeTemplatesChange(newValue)}
                                                            options={templates.sort((a, b) => {
                                                                let argumentA = getTemplateNameById(a._id, templates, true);
                                                                let argumentB = getTemplateNameById(b._id, templates, true);
                                                                return argumentA > argumentB ? 1 : (argumentA < argumentB ? -1 : 0);
                                                            })}
                                                            getOptionLabel={(option) => getTemplateNameById(option._id, templates, true, true)}
                                                        />
                                                    </Box>
                                                    {
                                                        selectedLists.length >= 1 &&
                                                        <Box mb={2}>
                                                            <MultipleAutocomplete
                                                                disabled={saving}
                                                                label="Excluir"
                                                                value={excludeTemplates} onChange={(e, newValue) => handleExcludeTemplatesChange(newValue)}
                                                                options={excludeTemplatesOptions.sort((a, b) => {
                                                                    let argumentA = getTemplateNameById(a, templates, true);
                                                                    let argumentB = getTemplateNameById(b, templates, true);
                                                                    return argumentA > argumentB ? 1 : (argumentA < argumentB ? -1 : 0);
                                                                })} getOptionLabel={(option) => getTemplateNameById(option, templates, true, true)}
                                                            />
                                                        </Box>
                                                    }
                                                </Box>
                                            </>
                                        }
                                    </Box>
                                    {
                                        folderTemplateFields &&
                                        <Box mb={2}>
                                            <Box mb={2}>
                                                <Typography variant="h4">Informações especiais</Typography>
                                            </Box>
                                            {
                                                folderTemplateFields
                                                .sort(sortByKey('name'))
                                                .map((field, fieldIndex) => {
                                                    const types = {
                                                        'string': {
                                                            name: 'Texto',
                                                            component:
                                                                <Grid container spacing={1} alignItems="flex-end">
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="h6">{field.name}</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12} container spacing={1}>
                                                                        <Grid item xs>
                                                                            <TextField
                                                                                variant="standard"
                                                                                fullWidth
                                                                                multiline
                                                                                placeholder="Sua resposta"
                                                                                value={userCreatedFieldsValues[field.id]}
                                                                                onChange={(e) => setUserCreatedFieldsValues(prevState => ({...prevState, [field.id]: e.target.value}))} />
                                                                        </Grid>
                                                                        {
                                                                            field.options && field.options.length >= 1 &&
                                                                            <Grid item xs={4}>
                                                                                {
                                                                                    field.options.map(option => (
                                                                                        <Box key={option}>
                                                                                            <Grid container spacing={1} alignItems="center">
                                                                                                {
                                                                                                    option === userCreatedFieldsValues[field.id] &&
                                                                                                    <CheckCircleIcon fontSize="small" htmlColor="green" />
                                                                                                }
                                                                                                <Grid item xs>
                                                                                                    <Link
                                                                                                        component="button"
                                                                                                        variant="body1"
                                                                                                        onClick={() => handleStringFieldChange(field.id, option)}
                                                                                                        underline="hover">{option}</Link>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Box>
                                                                                    ))
                                                                                }
                                                                            </Grid>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                        },
                                                        'boolean': {
                                                            name: 'Verdadeiro ou falso',
                                                            component:
                                                                <FormGroup row>
                                                                    <Grid component="label" container spacing={1} alignItems="center">
                                                                        <Grid item xs={12}>
                                                                            <Typography variant="h6">{field.name}</Typography>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Switch
                                                                                checked={userCreatedFieldsValues[field.id]}
                                                                                onChange={(e) => setUserCreatedFieldsValues(prevState => ({...prevState, [field.id]: e.target.checked}))}
                                                                                name={'switch'}
                                                                                color={'primary'}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </FormGroup>
                                                        },
                                                        'select': {
                                                            name: 'Múltipla escolha',
                                                            component:
                                                                <Grid container spacing={1} alignItems="flex-end">
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="h6">{field.name}</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12} container spacing={1} wrap="nowrap">
                                                                        <Grid item xs>
                                                                            <Select
                                                                                options={field.options?.map(option => ({value: option, label: option}))}
                                                                                value={userCreatedFieldsValues[field.id] || 'select'}
                                                                                onChange={(e) => setUserCreatedFieldsValues(prevState => ({...prevState, [field.id]: e.target.value}))}
                                                                            />
                                                                        </Grid>
                                                                        {
                                                                            userCreatedFieldsValues[field.id] &&
                                                                            <Grid item>
                                                                                <IconButton size="small"
                                                                                    onClick={() => setUserCreatedFieldsValues(prevState => ({...prevState, [field.id]: ''}))}
                                                                                ><ClearIcon /></IconButton>
                                                                            </Grid>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                        }
                                                    };
                                                    return (
                                                        <Box key={field.id} mb={1}>
                                                            <Box mb={1}>
                                                                <Grid container spacing={1} alignItems="flex-start">
                                                                    <Grid item>
                                                                        <Typography variant="h6">{fieldIndex + 1}</Typography>
                                                                    </Grid>
                                                                    <Grid item xs>
                                                                        <Box mb={1}>
                                                                            {types[field.type]?.component}
                                                                        </Box>
                                                                        <Typography variant="body2"><em>{field.template}</em></Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                            <Divider />
                                                        </Box>
                                                    )
                                                })
                                            }
                                        </Box>
                                    }
                                </Box>
                            }
                        </>
                    }
                </Container>

            </Dialog>
            <ChangeFolderInitialResponsesWindow
                open={changeFolderInitialResponsesWindowOpen} onClose={() => setChangeFolderInitialResponsesWindowOpen(false)}
                handleSelectedFolderInitialFormResponsesChange={handleSelectedFolderInitialFormResponsesChange}
                selectedFolder={selectedFolder}
                templateId={selectedTemplateId}
            />
        </>
    );
};

export default ChangeProjectTemplatesWindow;