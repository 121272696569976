import React, { useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileSignature } from '@fortawesome/free-solid-svg-icons';
import { DndContext } from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { useDocumentsCtxLoadingSignatures } from '../../context/DocumentsContext';
import PositionSignaturesCanvas from './PositionSignaturesCanvas';
import { v4 as uuidv4 } from 'uuid';

const PositionSignatures = (props) => {
    const {
        base64String,
        boxes, setBoxes,
        handleBackButtonClick,
        handleSignDocumentButtonClick,
        loadingESignatureProgress,
        loadingESignatureStatus,
        setDocumentDimensions,
        signers
    } = props;
    const loadingSignatures = useDocumentsCtxLoadingSignatures();
    const ref = useRef(null);
    const pageRefs = useRef([]);
    const [numPages, setNumPages] = useState(null);

    const onDocumentLoadSuccess = async (props) => {
        const dimensions = [];
        for (let pageIndex = 0; pageIndex < props.numPages; pageIndex++) {
            const currentPage = await props.getPage(pageIndex + 1);
            dimensions.push({
                page: pageIndex + 1,
                height: currentPage.view[3],
                width: currentPage.view[2]
            });
        }
        setDocumentDimensions(dimensions);
        setNumPages(props.numPages);
    };

    const getPosition = ({action, clientX, clientY, left, pageLeft, pageNumber, pageTop, top}) => {
        const boxHeight = 80;

        // ALL PAGES
        const rect = ref.current.getBoundingClientRect();

        let height = rect.height;
        if(!top) top = clientY - rect.top;
        let relative_position_bottom = 100 - (((top + boxHeight) * 100) / height);
        let docuSignPositionY = ((top + (boxHeight - 48)) * 0.013888888888888888) * 72;

        let width = rect.width;
        if(!left) left = clientX - rect.left;
        let relative_position_left = (left * 100) / width;
        let docuSignPositionX = ((left + 16) * 0.013888888888888888) * 72;

        // SINGLE PAGE
        const pageRect = pageRefs.current[pageNumber - 1].getBoundingClientRect();

        let pageHeight = pageRect.height;
        if(action === 'repeatSignature') top += pageRect.top - rect.top;
        if(top && !pageTop) pageTop = top - pageRect.top + rect.top;
        if(!pageTop) pageTop = clientY - pageRect.top;
        let pageRelative_position_bottom = 100 - (((pageTop + boxHeight) * 100) / pageHeight);
        let pageDocuSignPositionY = ((pageTop + (boxHeight - 48)) * 0.013888888888888888) * 72;

        let pageWidth = pageRect.width;
        if(left && !pageLeft) pageLeft = left - pageRect.left + rect.left;
        if(!pageLeft) pageLeft = clientX - pageRect.left;
        let pageRelative_position_left = (pageLeft * 100) / pageWidth;
        let pageDocuSignPositionX = ((pageLeft + 16) * 0.013888888888888888) * 72;

        return {
            docuSignPositionX, docuSignPositionY, left, relative_position_bottom, relative_position_left, top,
            
            pageDocuSignPositionX, pageDocuSignPositionY, pageLeft, pageRelative_position_bottom, pageRelative_position_left, pageTop
        };
    };

    const handlePdfClick = (event) => {
        const clickedPageElement = event.target.parentElement;
        if(clickedPageElement.classList.contains('react-pdf__Page')){
            const clickedPage = clickedPageElement.getAttribute('data-page-number');
            if(clickedPage){
                const clickedPageNumber = parseInt(clickedPage);
                const signerValue = signers.filter(signer => signer.type !== 'observer')[0].fullName;
                let box = getPosition({
                    clientX: event.clientX, 
                    clientY: event.clientY, 
                    pageNumber: clickedPageNumber
                });
                setBoxes(prevState => [...prevState, {
                    ...box,
                    id: uuidv4(),
                    signer: signerValue, type: 'signature',
                    page: clickedPageNumber,
                    left: box.left,
                    top: box.top
                }]);
            }
        }
    };

    const handleDragEnd = (event) => {
        if (event.over && event.over.id === 'droppable') {
            setBoxes(prevState => {
                const draggedBoxIndex = prevState.findIndex((prevStateBox) => prevStateBox.id === event.active.id);
                if(draggedBoxIndex !== -1){
                    const updatedBox = {...prevState[draggedBoxIndex]};
                    updatedBox.left += event.delta.x;
                    updatedBox.top += event.delta.y;
                    const rect = ref.current.getBoundingClientRect();
                    let xPosition = updatedBox.left;
                    let yPosition = updatedBox.top;
                    let dropElements = document.elementsFromPoint(xPosition + rect.left, yPosition + rect.top);
                    let dropElement = dropElements.find(foundElement => foundElement.classList.contains('react-pdf__Page'));
                    if(!dropElement){
                        xPosition += 0.1;
                        yPosition += 0.1;
                        dropElements = document.elementsFromPoint(xPosition + rect.left, yPosition + rect.top);
                        dropElement = dropElements.find(foundElement => foundElement.classList.contains('react-pdf__Page'));
                    }
                    if(dropElement){
                        const clickedPage = dropElement.getAttribute('data-page-number');
                        if(clickedPage){
                            const clickedPageNumber = parseInt(clickedPage);
                            const newPosition = getPosition({
                                left: updatedBox.left,
                                pageNumber: clickedPageNumber,
                                top: updatedBox.top
                            });
                            return ([
                                ...prevState.slice(0, draggedBoxIndex),
                                {...updatedBox, ...newPosition, page: clickedPageNumber},
                                ...prevState.slice(draggedBoxIndex + 1)
                            ]);
                        }
                    }
                }
                return prevState;
            });
        }
    };

    return (
        <Box>
            {
                loadingSignatures
                ?
                <Box my={3}>
                    <Box style={{textAlign: 'center'}} mb={2}>
                        <Typography variant="body1">{loadingESignatureStatus}</Typography>
                    </Box>
                    <LinearProgress variant="determinate" value={loadingESignatureProgress} />
                </Box>
                :
                <Box>
                    <Box mb={1}>
                        <Grid container justifyContent="center">
                            <Grid item>
                                <Typography variant="body1">Se desejar, clique no documento para inserir os campos de assinatura</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <Grid container justifyContent="center">
                            <Grid item>
                                
                                <DndContext onDragEnd={handleDragEnd} modifiers={[restrictToParentElement]}>
                                
                                    <PositionSignaturesCanvas
                                        pageRefs={pageRefs}
                                        pageRef={ref} base64String={base64String} onPageClick={handlePdfClick} onDocumentLoadSuccess={onDocumentLoadSuccess}
                                        numberOfPages={numPages}
                                        getPosition={getPosition}
                                        signers={signers}
                                        data={{
                                            boxes, set_boxes: setBoxes
                                        }}
                                    />

                                </DndContext>

                            </Grid>
                        </Grid>
                    </Box>
                    <Box mt={2}>
                        <Grid container spacing={1} alignItems="center" justifyContent="center">
                            <Grid>
                                <Button variant="text" startIcon={<ArrowBackIcon />} onClick={handleBackButtonClick}>Voltar</Button>
                            </Grid>
                            <Grid>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    endIcon={<FontAwesomeIcon icon={faFileSignature} />}
                                    onClick={handleSignDocumentButtonClick}
                                >Enviar</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            }
        </Box>
    );
};

export default PositionSignatures;