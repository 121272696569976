import { useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import Operator from '../pages/Operator';
import ReviewDocument from '../pages/Operator/Docs/ReviewDocument';
import PageNotFound from '../pages/PageNotFound';
import { useAppCtxAPI, useAppCtxActiveUser } from '../context/SystemContext';
import { useSocket, useSocketCtxConnectedAt } from '../context/SocketContext';
import { useClientCtxActiveClient } from '../context/ClientContext';

const ConnectedOperator = () => {
    const params = useParams();
    const workspaceId = params.workspaceId;
    const { handleNavigate } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const { createSocketConnection } = useSocket();
    const socket = createSocketConnection();
    const activeClient = useClientCtxActiveClient();
    const socketConnectedAt = useSocketCtxConnectedAt();

    // useEffect(() => {
    //     if(typeof Notification !== 'undefined'){
    //         const permission = Notification.permission;
    //         console.log('permission', permission)
    //         if(permission === 'granted') {
    //             setTimeout(() => {
    //                 console.log('document', document)
    //                 if(document.visibilityState === 'visible'){
    //                     return;
    //                 }
    //                 const title = 'JavaScript Jeep';
    //                 const icon = 'image-url'; // URL of an icon to be displayed in the notification
    //                 const body = 'Message to be displayed';
    //                 const notification = new Notification(title, { body, icon });
    //                 notification.onclick = () => {
    //                     notification.close();
    //                     window.parent.focus();
    //                 }
                    
    //             }, 3000);
    //         } else if(permission === 'default'){
    //             Notification.requestPermission(function (permission) {
    //                 console.log('permission', permission)
    //                 if (permission === 'granted') {
    //                     // showBrowserNotificationAction();
    //                 }
    //             });
    //         } else {
    //         //   alert("Use normal alert");
    //         }
    //     }
    // }, []);

    useEffect(() => {
        if(activeUser && workspaceId){
            if(activeUser.workspaceShortName !== workspaceId){
                handleNavigate(`/paineis`);
            }
        }
    }, [activeUser]);
    
    useEffect(() => {
        if(activeClient){
            socket.emit('OPERATOR CONNECTED', { appClientId: activeClient._id });
        }
    }, [activeClient, socketConnectedAt]);

    useEffect(() => {
        return () => {
            socket.emit('OPERATOR DISCONNECTED', {});
        };
    }, []);

    return (
        <Routes>
            <Route path="/documento/:documentId" element={<ReviewDocument />} />
            <Route path="/*" element={<Operator />} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
};

export default ConnectedOperator;