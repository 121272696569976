import { useEffect, useState } from 'react';
import { Snackbar } from '@mui/material';
import { Alert } from '@mui/material';

const FloatingNotification = ({notification}) => {
    const [showNotification, setShowNotification] = useState(false);
    const [severity, setSeverity] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        if(notification){
            setShowNotification(false);
            if(notification.message){
                setSeverity(notification.severity);
                setMessage(notification.message);
                setTimeout(() => {
                    setShowNotification(true);
                }, notification.timeout || 1000);
            }
        }
    }, [notification]);
    
    const handleClose = (event, reason) => {
        if(reason === 'clickaway'){
            return;
        }
      
        setShowNotification(false);
    };

    if(showNotification){
        return (
            <Snackbar
                anchorOrigin={
                    {
                        vertical: 'top',
                        horizontal: 'center',
                    }
                }
                open={showNotification}
                onClose={handleClose}
            >
                <Alert variant="filled" severity={severity || 'info'} onClose={handleClose}>
                    {message}
                </Alert>
            </Snackbar>
        );
    }

    return null;
};

export default FloatingNotification;