import { useEffect, useState } from 'react';
import { Box, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Switch from './Switch';
import { ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../utils/constants';
import { useAppCtxAPI } from '../context/SystemContext';

const ChangeUserBooleanField = ({currentUser, setCurrentUser, fieldKey}) => {
    const { toast, update } = useAppCtxAPI();
    const [fieldValue, setFieldValue] = useState(false);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if(currentUser){
            setFieldValue(fields[fieldKey].setOppositeValueOnMount ? !currentUser[fieldKey] : currentUser[fieldKey]);
        }
    }, [currentUser]);

    const handleChange = async (newValue) => {
        setSaving(true);
        setFieldValue(newValue);
        const updatedUser = {
            ...currentUser,
            [fieldKey]: fields[fieldKey].setOppositeValueOnMount ? !newValue : newValue
        };
        const postData = {
            [fieldKey]: fields[fieldKey].setOppositeValueOnMount ? !newValue : newValue
        };
        if(fields[fieldKey].shouldAlsoChange){
            if(fields[fieldKey].shouldAlsoChange.changeIf === undefined || newValue === fields[fieldKey].shouldAlsoChange.changeIf){
                postData[fields[fieldKey].shouldAlsoChange.fieldKey] = fields[fieldKey].shouldAlsoChange.fieldValue;
                updatedUser[fields[fieldKey].shouldAlsoChange.fieldKey] = fields[fieldKey].shouldAlsoChange.fieldValue;
            }
        }
        setCurrentUser(updatedUser);
        try {
            await update('Users', updatedUser._id, postData);
            toast(SUCCESS_MESSAGE_SAVED);
        } catch (error) {
            toast(ERROR_MESSAGE_UNKNOWN);
        }
        setSaving(false);
    };
    
    const defaultIconColor = 'action';
    const fields = {
        emailsDocumentReadyDisable: {
            setOppositeValueOnMount: true,
            icon: <NotificationsIcon color={defaultIconColor} />,
            label: 'Receber e-mails com documentos enviados',
            shouldAlsoChange: {
                changeIf: false,
                fieldKey: 'emailsDocumentReadyOnlyUserRequests',
                fieldValue: false
            }
        },
        emailsDocumentReadyOnlyUserRequests: {
            icon: <NotificationsIcon color={defaultIconColor} />,
            label: 'Receber e-mails só se eu tiver solicitado o documento',
            showIf: {
                fieldKey: 'emailsDocumentReadyDisable',
                fieldValue: false
            }
        },
        emailsDocumentSigned: {
            icon: <NotificationsIcon color={defaultIconColor} />,
            label: 'Receber e-mails com comprovante de assinatura de documentos'
        },
        emailsDocumentFullyExecutedDisable: {
            setOppositeValueOnMount: true,
            icon: <NotificationsIcon color={defaultIconColor} />,
            label: 'Receber e-mails avisando que todas as assinaturas de um documento foram concluídas'
        },
        documentsSignaturesStatusExpanded: {
            icon: <DescriptionIcon color={defaultIconColor} />,
            label: 'Expandir e manter visível a situação de assinatura eletrônica dos documentos'
        },
        disableClearanceEmails: {
            setOppositeValueOnMount: true,
            icon: <NotificationsIcon color={defaultIconColor} />,
            label: 'Receber e-mails com consultas e notificações de clearance',
            shouldAlsoChange: {
                changeIf: false,
                fieldKey: 'disableClearanceEmailsExceptIfCreatedByUser',
                fieldValue: false
            }
        },
        disableClearanceEmailsExceptIfCreatedByUser: {
            icon: <NotificationsIcon color={defaultIconColor} />,
            label: 'Receber e-mails só se eu tiver apresentado a consulta ou respondido a anotação de clearance',
            showIf: {
                fieldKey: 'disableClearanceEmails',
                fieldValue: false
            }
        }
    };

    if(currentUser && (!fields[fieldKey].showIf || currentUser[fields[fieldKey].showIf.fieldKey] === fields[fieldKey].showIf.fieldValue)){
        return (
            <Box>
                <Box py={0.5}>
                    <Grid
                        container spacing={1} alignItems="center"
                        sx={{
                            cursor: 'pointer',
                            transition: 'background-color .4s',
                            borderRadius: theme => theme.spacing(1),
                            '&:hover': {
                                backgroundColor: theme => theme.palette.action.hover
                            }
                        }}
                        onClick={() => handleChange(!fieldValue)}
                    >
                        <Grid item>
                            {
                                saving
                                ? <CircularProgress size={20} />
                                : fields[fieldKey].icon
                            }
                        </Grid>
                        <Grid item xs>
                            <Typography variant="body1" color="textPrimary">{fields[fieldKey].label}</Typography>
                        </Grid>
                        <Grid item>
                            <Switch checked={fieldValue} />
                        </Grid>
                    </Grid>
                </Box>
                <Divider variant="middle" />
            </Box>
        );
    }
    return null;
}

export default ChangeUserBooleanField;