import React from 'react';
import InputMask from 'react-input-mask';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import Select from '../../../../components/Select';

const ProjectWindowSignatureSection = ({projectState, setProjectState}) => {

    const handleSignerMobileChange = (index, newValue) => {
        let newValueNumber = newValue ? parseInt(newValue.replace(/\D/g, '')) : '';
        setProjectState(prevState => ({...prevState, signers: [...prevState.signers.slice(0, index), {...prevState.signers[index], mobile: newValueNumber}, ...prevState.signers.slice(index + 1)]}))
    };

    return (
        <Box mb={3}>
            {
                projectState.signers.length >= 1 &&
                <Box mb={2}>
                    <Typography variant="h6">Signatário(a)s</Typography>
                </Box>
            }
            <Box mb={1}>
                {
                    projectState.signers.map((signer, index) => (
                        <Box key={index}>
                            <Box my={1}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid xs={9} sm={10} md={11} container spacing={1} alignItems="flex-end">
                                        <Grid xs={12} sm={6} md={3} container spacing={1} alignItems="flex-end">
                                            <Grid sx={{ display: { xs: 'block', lg: 'none' } }}>
                                                <IconButton size="small"
                                                    onClick={() => setProjectState(prevState => ({...prevState, signers: [...prevState.signers.slice(0, index), ...prevState.signers.slice(index + 1)]}))}
                                                ><ClearIcon /></IconButton>
                                            </Grid>
                                            <Grid xs>
                                                <TextField
                                                    variant="standard"
                                                    label="Nome completo"
                                                    fullWidth
                                                    value={signer.name}
                                                    onChange={(e) => setProjectState(prevState => ({...prevState, signers: [...prevState.signers.slice(0, index), {...prevState.signers[index], name: e.target.value}, ...prevState.signers.slice(index + 1)]}))}
                                                    required
                                                    error={!signer.name} />
                                            </Grid>
                                        </Grid>
                                        <Grid xs={12} sm={6} md={2}>
                                            <Select
                                                label="Tipo"
                                                options={[{value: 'party', label: 'Parte'}, {value: 'witness', label: 'Testemunha'}]}
                                                value={signer.type} onChange={(e) => setProjectState(prevState => ({...prevState, signers: [...prevState.signers.slice(0, index), {...prevState.signers[index], type: e.target.value}, ...prevState.signers.slice(index + 1)]}))}
                                                required error={!signer.type}
                                            />
                                        </Grid>
                                        <Grid xs={12} sm={6} md={3}>
                                            <TextField
                                                variant="standard"
                                                label="E-mail"
                                                fullWidth
                                                value={signer.email}
                                                onChange={(e) => setProjectState(prevState => ({...prevState, signers: [...prevState.signers.slice(0, index), {...prevState.signers[index], email: e.target.value}, ...prevState.signers.slice(index + 1)]}))}
                                                required
                                                error={!signer.email} />
                                        </Grid>
                                        <Grid xs={12} sm={6} md={2}>
                                            <InputMask
                                                mask="999.999.999-99"
                                                value={signer.taxpayerNumber} onChange={(e) => setProjectState(prevState => ({...prevState, signers: [...prevState.signers.slice(0, index), {...prevState.signers[index], taxpayerNumber: e.target.value}, ...prevState.signers.slice(index + 1)]}))}
                                                maskChar=""
                                            >
                                                {() => (
                                                    <TextField variant="standard" fullWidth label="CPF" />
                                                )}
                                            </InputMask>
                                        </Grid>
                                        <Grid xs={12} sm={6} md={2}>
                                            <InputMask
                                                mask="(99) 99999-9999"
                                                value={signer.mobile} onChange={(e) => handleSignerMobileChange(index, e.target.value)}
                                                maskChar=""
                                            >
                                                {() => (
                                                    <TextField
                                                        variant="standard"
                                                        fullWidth
                                                        label="Celular"
                                                        required
                                                        error={!signer.mobile} />
                                                )}
                                            </InputMask>
                                        </Grid>
                                    </Grid>
                                    <Grid sx={{ display: { xs: 'none', lg: 'block' } }}>
                                        <IconButton size="small"
                                            onClick={() => setProjectState(prevState => ({...prevState, signers: [...prevState.signers.slice(0, index), ...prevState.signers.slice(index + 1)]}))}
                                        ><ClearIcon /></IconButton>
                                    </Grid>
                                </Grid>
                            </Box>
                            {/* <Divider /> */}
                        </Box>
                    ))
                }
            </Box>
            <Grid container justifyContent="flex-end">
                <Grid>
                    <Button
                        startIcon={<AddIcon />}
                        onClick={() => setProjectState(prevState => ({...prevState, signers: [...prevState.signers, { name: '', email: '', taxpayerNumber: '', mobile: '', type: 'select' }]}))}
                    >Cadastrar signatário(a)</Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ProjectWindowSignatureSection;