import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Switch from '../../../../components/Switch';
import { useAppCtxAPI, useAppCtxActiveUser } from '../../../../context/SystemContext';
import { useOperatorCtxRefs, useOperatorCtxAPI } from '../../context/OperatorContext';
import { useOperatorUsersCtxAPI, useOperatorCtxChangeUserView } from '../../../../context/OperatorUsersContext';

const SettingsSwitches = () => {
    const { changeDocumentKey, setLoading } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const { updateDocument } = useOperatorCtxAPI();
    const { checkIfShouldUpdateOperators, updatedUser } = useOperatorCtxRefs();
    const { setChangeUserViewFoundUser } = useOperatorUsersCtxAPI();
    const { changeUserViewFoundUser: selectedUser } = useOperatorCtxChangeUserView();

    const handleChange_emailsDocumentReadyDisable = (e) => {
        let updates = {
            emailsDocumentReadyDisable: e.target.checked
        };
        if(e.target.checked) updates.emailsDocumentReadyOnlyUserRequests = false;
        updatedUser.current = {...selectedUser, ...updates};
        checkIfShouldUpdateOperators();
        changeDocumentKey('Users', selectedUser, updates, updateDocument, setChangeUserViewFoundUser, setLoading, 'operator');
    };

    const handleChange_emailsDocumentReadyOnlyUserRequests = (e) => {
        let updates = {
            emailsDocumentReadyOnlyUserRequests: e.target.checked
        };
        if(e.target.checked) updates.emailsDocumentReadyDisable = false;
        updatedUser.current = {...selectedUser, ...updates};
        checkIfShouldUpdateOperators();
        changeDocumentKey('Users', selectedUser, updates, updateDocument, setChangeUserViewFoundUser, setLoading, 'operator');
    };

    const handleChangeOption = (e, optionId) => {
        const updates = {
            [optionId]: e.target.checked
        };
        updatedUser.current = {...selectedUser, ...updates};
        checkIfShouldUpdateOperators();
        changeDocumentKey('Users', selectedUser, updates, updateDocument, setChangeUserViewFoundUser, setLoading, 'operator');
    };

    const handleDisableClearanceEmailsChange = (e) => {
        let updates = {
            disableClearanceEmails: e.target.checked
        };
        if(e.target.checked) updates.disableClearanceEmailsExceptIfCreatedByUser = false;
        updatedUser.current = {...selectedUser, ...updates};
        checkIfShouldUpdateOperators();
        changeDocumentKey('Users', selectedUser, updates, updateDocument, setChangeUserViewFoundUser, setLoading, 'operator');
    };

    const handleDisableClearanceEmailsExceptIfCreatedByUserChange = (e) => {
        let updates = {
            disableClearanceEmailsExceptIfCreatedByUser: e.target.checked
        };
        if(e.target.checked) updates.disableClearanceEmails = false;
        updatedUser.current = {...selectedUser, ...updates};
        checkIfShouldUpdateOperators();
        changeDocumentKey('Users', selectedUser, updates, updateDocument, setChangeUserViewFoundUser, setLoading, 'operator');
    };

    const SettingsRow = ({id, description, disabled, onChange}) => (
        <Grid container spacing={1} alignItems="center"
            sx={{
                padding: '8px 4px',
                borderRadius: theme => theme.spacing(1),
                transition: 'background-color .2s',
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                }
            }}
        >
            <Grid item xs container spacing={1} alignItems="center">
                <Grid item xs>
                    <Typography variant="body2">{description}</Typography>
                </Grid>
                <Grid item>
                    <Switch
                        disabled={disabled}
                        checked={selectedUser[id]} onChange={onChange}
                    />
                </Grid>
            </Grid>
        </Grid>
    );

    const appAtSolidaUserId = '621257169023272acc8ba287';

    return (
        <Box>
            <SettingsRow
                id="emailsDocumentReadyDisable"
                description="Não receber e-mails com documentos?"
                disabled={activeUser?.type < 9 && selectedUser?._id === appAtSolidaUserId}
                onChange={handleChange_emailsDocumentReadyDisable}
            /> 
            <SettingsRow
                id="emailsDocumentReadyOnlyUserRequests"
                description="E-mails com documentos só quando for o(a) solicitante?"
                disabled={activeUser?.type < 9 && selectedUser?._id === appAtSolidaUserId}
                onChange={handleChange_emailsDocumentReadyOnlyUserRequests}
            />
            <SettingsRow
                id="emailsDocumentSigned"
                description="E-mails com comprovante de assinatura de documento?"
                disabled={activeUser?.type < 9 && selectedUser?._id === appAtSolidaUserId}
                onChange={(e) => handleChangeOption(e, 'emailsDocumentSigned')}
            />
            <SettingsRow
                id="emailsDocumentFullyExecutedDisable"
                description="Desativar e-mails com documentos assinados?"
                disabled={activeUser?.type < 9 && selectedUser?._id === appAtSolidaUserId}
                onChange={(e) => handleChangeOption(e, 'emailsDocumentFullyExecutedDisable')}
            />
            <SettingsRow
                id="documentsSignaturesStatusExpanded"
                description="Expandir e manter visível a situação de assinatura eletrônica dos documentos"
                onChange={(e) => handleChangeOption(e, 'documentsSignaturesStatusExpanded')}
            />
            <SettingsRow
                id="disableClearanceEmails"
                description="Não receber e-mails com consultas e anotação de clearance?"
                disabled={activeUser?.type < 9 && selectedUser?._id === appAtSolidaUserId}
                onChange={handleDisableClearanceEmailsChange}
            /> 
            <SettingsRow
                id="disableClearanceEmailsExceptIfCreatedByUser"
                description="Receber e-mails só quando tiver apresentado a consulta ou respondido a anotação de clearance?"
                disabled={activeUser?.type < 9 && selectedUser?._id === appAtSolidaUserId}
                onChange={handleDisableClearanceEmailsExceptIfCreatedByUserChange}
            />
        </Box>
    );
};

export default SettingsSwitches;