import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const displayOptions = [
    {value: 'singleLine', label: 'Gerar texto corrido com pré-produção, filmagem e pós-produção'},
    {value: 'lowerAlphaList', label: 'Gerar lista com pré-produção, filmagem e pós-produção'},
    {value: 'none', label: 'Não gerar lista combinando informações (gerar o conteúdo do editor em cada efeito)'},
];

const NewEffect = ({ handleChange, setDisabled }) => {
    const [value, setValue] = useState({ question: '', display: 'singleLine' });

    useEffect(() => {
        handleChange(value);
        if(setDisabled){
            let isDisabled = false;
            if(!value.question) isDisabled = true;
            setDisabled(isDisabled);
        }
    }, [value]);

    return (
        <Box>
            <Box mb={1}>
                <Typography variant="h6">Pergunta</Typography>
            </Box>
            <TextField
                variant="standard"
                fullWidth
                placeholder="Sua pergunta"
                value={value.question}
                onChange={(e) => setValue(prevState => ({...prevState, question: e.target.value}))}
            />
            <Divider />
            <RadioGroup
                value={value.display}
                onChange={(e) => setValue(prevState => ({...prevState, display: e.target.value}))}
            >
                <Grid container spacing={1} alignItems="center">
                    {
                        displayOptions.map((option, optionIndex) => (
                            <Grid key={optionIndex}>
                                <FormControlLabel
                                    value={option.value}
                                    control={<Radio />}
                                    label={option.label}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            </RadioGroup>
        </Box>
    );
};

export default NewEffect;