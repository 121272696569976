import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DescriptionIcon from '@mui/icons-material/Description';
import { formEffectTypes } from '../../../utils/constants';
import { useDocumentEditor } from '../../../pages/Operator/Docs/Templates/DocumentEditor/context/DocumentEditorContext';

const ListOption = ({label, onClick}) => {
    return (
        <Box>
            <Divider />
            <Box
                sx={{
                    '&:hover': {
                        backgroundColor: theme => theme.palette.action.hover
                    }
                }}
                mb={0.5} mt={0.5}
            >
                <Grid container alignItems="center" justifyContent="flex-end" spacing={1}>
                    <Grid xs>
                        <Typography variant="body2">{label}</Typography>
                    </Grid>
                    <Grid>
                        <IconButton size="small" onClick={onClick}><DescriptionIcon /></IconButton>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

const EditorEffect = (props) => {
    const { selectedEffect } = props;
    const { handle_effectRadioOutputButtonClick } = useDocumentEditor(); 

    const handleOutputClick = () => {
        handle_effectRadioOutputButtonClick(selectedEffect.id, 'output');
    };

    const handleElseOutputClick = () => {
        handle_effectRadioOutputButtonClick(selectedEffect.id, 'elseOutput');
    };

    return (
        <Box>
            <Box mb={2}>
                <Typography variant="h3">{selectedEffect.label || selectedEffect.question}</Typography>
            </Box>
            <Box mb={1}>
                <Typography variant="body1"><strong>Tipo:</strong> {formEffectTypes.find(formEffectType => formEffectType.value === selectedEffect.type).label}</Typography>
            </Box>
            <Box mb={1}>
                <Typography variant="body1"><strong>Pergunta:</strong> {selectedEffect.question}</Typography>
            </Box>
            <ListOption
                label="Se for verdadeiro..."
                onClick={handleOutputClick}
            />
            <ListOption
                label="Se for falso..."
                onClick={handleElseOutputClick}
            />
        </Box>
    )
};

export default EditorEffect;