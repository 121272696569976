import { Box, Grid } from '@mui/material';
import Switch from '../../../../components/Switch';
import { useAppCtxActiveUser } from '../../../../context/SystemContext';
import { useClientCtxActiveClient } from '../../../../context/ClientContext';
import { FOLDER_MODULES } from '../../../../utils/constants';

const SettingsSwitches = (props) => {
    const { folderState, setFolderState, selectedProject } = props;
    const activeUser = useAppCtxActiveUser();
    const activeClient = useClientCtxActiveClient();

    const SettingsRow = ({label, settingsKey}) => {

        const handleSwitchClick = (e) => {
            setFolderState(prevState => ({...prevState, [settingsKey]: e.target.checked}))
        };

        return (
            <Box>
                <Box>
                    <Switch checked={folderState[settingsKey]} onChange={handleSwitchClick}
                        label={label}
                        noWrap
                    />
                </Box>
            </Box>
        )
    };

    const ModulesRow = ({label, settingsKey}) => {
        
        const handleSwitchClick = () => {
            setFolderState(prevState => {
                let modules = prevState.modules || [];
                const foundModule = modules.find(module => module === settingsKey);
                if(foundModule){ //if includes, then remove
                    modules = modules.filter(module => module !== settingsKey);
                } else { // else, then add
                    modules.push(settingsKey);
                }
                return ({...prevState, modules});
            });
        }

        return (
            <Box>
                <Box>
                    <Switch
                        checked={folderState.modules.includes(settingsKey)} onChange={() => handleSwitchClick()}
                        label={label} noWrap
                    />
                </Box>
            </Box>
        )
    };

    const availableModules = FOLDER_MODULES.filter(module => {
        let showModule = true;
        if(selectedProject){
            if(module.typeFilter){
                if(module.typeFilter === 'projects'){
                    showModule = !!selectedProject.client;
                } else if(module.typeFilter === 'companies'){
                    showModule = !selectedProject.client;
                }
            }
        }
        return showModule && (!module.underConstruction || activeUser?.type >= 9);
    });

    return (
        <Grid item xs>
            <SettingsRow
                label="Ativar assinatura eletrônica"
                settingsKey="eSignature"
            />
            <SettingsRow
                label="Ativar grupos"
                settingsKey="useGroups"
            />
            <SettingsRow
                label={`Mostrar informações em destaque (favoritadas) em cada documento`}
                settingsKey="showDocumentsStarredInformation"
            />
            <SettingsRow
                label={`Mostrar campos adicionais ("Anotações") em cada documento na interface do usuário de pasta`}
                settingsKey="showFolderUserDocumentsCustomFields"
            />
            <SettingsRow
                label={`Permitir que usuários de pasta adicionem documentos sem formulário`}
                settingsKey="folderUsersCanAddDocuments"
            />
            <SettingsRow
                label="Enviar e-mails com notificações só para o(a) solicitante de cada documento"
                settingsKey="emailsDocumentReadyOnlyUserRequests"
            />

            {
                availableModules.length >= 1 &&
                availableModules
                .filter(module => {
                    if(activeClient){
                        const appModules = activeClient.modules || [];
                        return appModules.includes(module.id);
                    }
                    return false;
                })
                .map((module, moduleIndex) => (
                    <ModulesRow
                        key={moduleIndex}
                        label={module.name}
                        settingsKey={module.id}
                    />
                ))
            }
        </Grid>
    );
};

export default SettingsSwitches;