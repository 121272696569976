import { Link, Typography } from '@mui/material';
import { getDocumentLastVersion } from '../utils/common';
import { useClientCtxActiveClient } from '../context/ClientContext';

const PendingDocLink = ({selectedDocument}) => {
    const activeClient = useClientCtxActiveClient();

    const getUrl = () => {
        let link = '';
        const lastVersion = getDocumentLastVersion(selectedDocument);
        if(lastVersion){
            link = `/${activeClient.shortName}/juridico/documento/${lastVersion._id}`;
        }
        return link;
    };

    const documentVersions = selectedDocument.documents;

    let documentName = selectedDocument.name;
    if(documentName){
        const words = documentName.split(' ');
        let newName = '';
        words.forEach(word => {
            if(word.length > 29){
                return newName += word.substring(0, 29) + '. ';
            }
            newName += word + ' ';
        });
        if(newName) documentName = newName;
    }

    if(Array.isArray(documentVersions) && documentVersions.length >= 1){
        const link = getUrl();
        if(link){
            return <Link onClick={() => window.open(link, '_blank')} component="p" variant="body1" underline="hover" style={{cursor: 'pointer'}} noWrap>{documentName}</Link>;
        }
    }
    return (
        <Typography variant="body1" noWrap>{documentName}</Typography>
    );
};

export default PendingDocLink;