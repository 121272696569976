import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ButtonLink from '../../components/ButtonLink'; 
import ForgotPassword from './components/ForgotPassword';
import { useAppCtxAPI } from '../../context/SystemContext';
import { FIRM_NAME } from '../../utils/constants';

const classes = {
    root: {
        backgroundColor: theme => theme.palette.background.default,
        height: '100%',
        overflowX: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    box: {
        marginTop: ['16px', '64px'],
        marginBottom: ['8px', '32px'],
        borderRadius: '8px',
        padding: '8px',
        width: '264px',
    },
    poweredBy: {
        marginTop: '8px',
        marginBottom: {xs: '8px', md: 0},
        color: '#9a9ea6',
        textAlign: {xs: 'center', md: 'right'},
        position: {md: 'absolute'},
        right: {md: '8px'},
        bottom: {md: '8px'},
    },
    poweredByLink: {
        color: '#9a9ea6',
        textDecoration: 'none'
    },
    logo: {
        height: 160
    },
    loadingLogo: {
        '@keyframes loading': {
            '50%': {
                opacity: 0
            },
            '100%': {
                opacity: 1
            }
        },
        opacity: 1,
        animation: 'loading 2s infinite',
    },
};

const A = styled('a')(unstable_styleFunctionSx);
const Img = styled('img')(unstable_styleFunctionSx);

function Start(){
    const { handleNavigate, setActiveUser, setLoading, setLoadingSystem, signIn } = useAppCtxAPI();
    const [loadingPage, setLoadingPage] = useState(false);
    const [id, setId] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [passwordFieldType, set_passwordFieldType] = useState('password');
    const [forgotPasswordDialogOpen, set_forgotPasswordDialogOpen] = useState(false);
    const [forgotPasswordDialogErrorMessage, set_forgotPasswordDialogErrorMessage] = useState('');
    const systemOnline = true;

    useEffect(() => {
        setLoading(false);
        setTimeout(() => {
            setLoadingSystem(false);
        }, 1000);
    }, []);
    
    const handleViewPasswordClick = () => {
        set_passwordFieldType(passwordFieldType === 'password' ? 'text' : 'password');
    };
    function handleSubmit(e){
        if(e.key === 'Enter') handleLogin();
    }
    async function handleLogin(){
        const alwaysAllowed = ['stefanofalcao@gmail.com', 'stefanofalcao@dn.adv.br'];
        if(!systemOnline && !alwaysAllowed.includes(id)){
            setErrorMessage('O sistema está em manutenção. Tente novamente mais tarde.');
        } else {
            setLoadingPage(true);
            setTimeout(async () => {
                let activeUser = null;
                try {
                    let remember = false;
                    activeUser = await signIn(id, password, remember);
                } catch (err) {
                    setErrorMessage(err.response.data);
                    setLoadingPage(false);
                }
                if(activeUser){
                    setActiveUser(activeUser);
                    const singleWorkspaceFound = activeUser.selectedClient && activeUser.workspaceShortName;
                    if(singleWorkspaceFound){
                        setLoadingSystem(true);
                        const path = `/${activeUser.workspaceShortName}/${activeUser.type >= 2 ? 'juridico' : 'pastas'}`;
                        setTimeout(() => {
                            handleNavigate(path);
                        }, 1000);
                    } else {
                        handleNavigate('/paineis');
                    }
                }
            }, 200);
        }
    }
    const handleForgotPasswordClick = () => {
        set_forgotPasswordDialogErrorMessage('');
        set_forgotPasswordDialogOpen(true);
    };
    
    return (
        <Box sx={classes.root}>
            <Box sx={classes.box}>
                <Box style={{textAlign: 'center'}} mt={2} mb={1}>
                    <Img
                        src={'/SOLIDA-500.png'}
                        sx={[classes.logo, loadingPage && classes.loadingLogo]}
                    />
                </Box>
                <Collapse in={!loadingPage}>
                    <form name="login" style={{paddingTop: 16}}>
                        <Box mb={1}>
                            <TextField
                                fullWidth autoFocus
                                variant="outlined"
                                InputProps={{
                                    style: {
                                        backgroundColor: '#e8f0fe',
                                        borderRadius: 24,
                                        fontSize: 14
                                    },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AlternateEmailIcon />
                                        </InputAdornment>
                                        
                                    )
                                }}
                                placeholder="E-mail"
                                value={id}
                                onChange={e => setId(e.target.value)}
                                onKeyPress={handleSubmit}
                                disabled={loadingPage}
                            />
                        </Box>
                        <Box mb={2}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                InputProps={{
                                    style: {
                                        backgroundColor: '#e8f0fe',
                                        borderRadius: 24,
                                        fontSize: 14
                                    },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockIcon />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <IconButton size="small" onClick={handleViewPasswordClick} disabled={loadingPage}>
                                            {passwordFieldType === 'password' ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    )
                                }}
                                placeholder="Senha"
                                type={passwordFieldType}
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                onKeyPress={handleSubmit}
                                disabled={loadingPage}
                            />
                        </Box>

                        <Box style={{textAlign: 'center'}} mb={2}>
                            <Button
                                variant="contained" color="primary"
                                disabled={loadingPage}
                                onClick={handleLogin}
                            >Entrar</Button>
                            {
                                errorMessage &&
                                <FormHelperText error>
                                    {errorMessage}
                                </FormHelperText>
                            }
                        </Box>

                        <Box mb={2} style={{textAlign: 'center'}}>
                            <ButtonLink onClick={handleForgotPasswordClick}>
                                <Typography variant="body2">Esqueceu sua senha?</Typography>
                            </ButtonLink>
                        </Box>
                    </form>
                </Collapse>
            </Box>
            <ForgotPassword
                open={forgotPasswordDialogOpen} setOpen={set_forgotPasswordDialogOpen}
                errorMessage={forgotPasswordDialogErrorMessage} setErrorMessage={set_forgotPasswordDialogErrorMessage}
            />
            <Box sx={classes.poweredBy}>
                <Typography variant="body2">Desenvolvido por <A href="http://dn.adv.br/" target="_blank" sx={classes.poweredByLink}>{FIRM_NAME}</A>.</Typography>
            </Box>
        </Box>
    );
}

export default Start;