import { useEffect, useState } from 'react';
import { Box, Slide, Grid, Typography } from '@mui/material';
import ButtonLink from './ButtonLink';
import { useAppCtxVersion } from '../context/SystemContext';

const classes = {
    root: {
        position: 'absolute',
        top: '2px',
        right: '2px',
        zIndex: theme => theme.zIndex.snackbar + 1,
    },
    alert: {
        backgroundColor: '#f5f6fa', // theme.palette.background.paper,
        borderRadius: 8,
        boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.4)',
        padding: '8px'
    }
};

const NewVersionAvailable = () => {
    const { backendVersion, frontendVersion } = useAppCtxVersion();
    const [showBox, setShowBox] = useState(false);

    useEffect(() => {
        setShowBox(backendVersion && backendVersion > frontendVersion);
    }, [backendVersion]);

    const handleClick = () => {
        window.location.reload(true);
    };

    if(showBox){
        return (
            <Box sx={classes.root}>
                <Slide in direction="left">
                    <Box sx={classes.alert}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs>
                                <Typography variant="body2">Nova versão! <ButtonLink onClick={handleClick}>Clique para recarregar o aplicativo.</ButtonLink></Typography>
                            </Grid>
                            <Grid item>
                                <img src={'/SOLIDA-50.png'} alt="Solida" /> 
                            </Grid>
                        </Grid>
                    </Box>
                </Slide>
            </Box>
        );
    }
    return null;
};

export default NewVersionAvailable;