import { useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import FilterInput from '../../../components/FilterInput';
import NoResults from '../../../components/NoResults';
import TinyTable from '../../../components/TinyTable';
import Window from '../../../components/Window';
import { sortByKey } from '../../../utils/filters';

const tableHead = [
    {content: 'Nome'},
    {content: 'Número'},
    {content: 'Endereço'},
    {content: 'Representante legal'},
    {content: 'CPF'},
    {content: 'Outras informações'}
];

const SuppliersWindow = ({open, onClose, setSupplier, suppliersList}) => {
    const [filterText, setFilterText] = useState('');

    const handleBodyRowClick = (row) => {
        setSupplier(row.supplier);
    };
    
    return (
        <Window
            open={open} onClose={onClose}
            title="Fichas cadastrais"
            subTitle="Clique em uma linha para escolher a ficha"
            modalInnerBoxSx={{ width: ['100%', '90%', '75%'] }}
        >
            {
                suppliersList.length === 0
                ? <NoResults text="Não há fichas cadastradas nesta empresa." />
                :
                <Box>
                    <Box mb={2}>
                        <Container maxWidth="xs">
                            <FilterInput value={filterText} setValue={setFilterText} />
                        </Container>
                    </Box>
                    <TinyTable
                        head={tableHead}
                        body={
                            suppliersList
                            .filter((supplier) => {
                                if(filterText){
                                    const textFields = `${supplier.name}${supplier.address}${supplier.representative}${supplier.email}${supplier.mobile}`;
                                    return textFields.toUpperCase().indexOf(filterText.toUpperCase()) !== -1;
                                }
                                return true;
                            })
                            .sort(sortByKey('name'))
                            .map((supplier) => {
                                const columns = [
                                    {content: <Typography variant="body2">{supplier.name}</Typography>},
                                    {content: <Typography variant="body2">{supplier.type ? (supplier.type === 'PF' ? 'CPF' : 'CNPJ') : (supplier.representative ? 'CNPJ' : 'CPF')} {supplier.taxpayerNumber}</Typography>},
                                    {content: <Typography variant="body2">{supplier.address}</Typography>},
                                    {content: <Typography variant="body2">{supplier.representative}</Typography>},
                                    {content: <Typography variant="body2">{supplier.representativeTaxpayerNumber}</Typography>},
                                    {
                                        content:
                                            <Box>
                                                {
                                                    supplier.email &&
                                                    <Typography variant="body2">Email {supplier.email}</Typography>
                                                }
                                                {
                                                    supplier.mobile &&
                                                    <Typography variant="body2">Celular {supplier.mobile}</Typography>
                                                }
                                            </Box>
                                    },
                                ];
                                return ({
                                    columns,
                                    supplier
                                });
                            })
                        }
                        handleBodyRow={handleBodyRowClick}
                        updatedAt={new Date()}
                    />
                </Box>
            }
        </Window>
    );
};

export default SuppliersWindow;