import { useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import { Box, CircularProgress, Collapse, Grid, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import axios from 'axios';
import moment from 'moment';
import Chat from './Chat';
import ButtonLink from '../../../components/ButtonLink';
import DashboardBox from '../../../components/DashboardBox';
import DashboardCounter from '../../../components/DashboardCounter';
import NumberOfPendingDocuments from './components/NumberOfPendingDocuments';
import SectionTitle from '../../../components/SectionTitle';
import TinyTable from '../../../components/TinyTable';
import ViewBox from '../../../components/ViewBox';
import { getChartDataFoldersTime, getDocumentFolderName, getLawsuitNameById } from '../../../utils/common';
import { ERROR_MESSAGE_UNKNOWN, FIRST_CLIENT_ID, SERVER_PATH } from '../../../utils/constants';
import { useAppCtxAPI, useAppCtxActiveUser } from '../../../context/SystemContext';
import { useClientCtxActiveClient } from '../../../context/ClientContext';
import { useOperatorCtxAPI, useOperatorCtxCounters, useOperatorCtxLawsuits, useOperatorCtxProjects } from '../context/OperatorContext';
import { useTasksCtxAPI, useTasksCtxActiveUserTasks, useTasksCtxTasksPendingDocuments } from '../../../context/TasksContext';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import TaskBoard from '../Tasks/TaskBoard';
import TasksList from '../Tasks/TasksList';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

const boxBackgroundColor = '#f5f6fa';

const NoPendingTasks = ({label, icon}) => (
    <Grid container spacing={1} alignItems="center">
        <Grid item>
            {icon || <CheckCircleOutlineIcon />}
        </Grid>
        <Grid item>
            <Typography variant="body2">{label}</Typography>
        </Grid>
    </Grid>
);

const Home = () => {
    const { handleNavigate, toast } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const activeClient = useClientCtxActiveClient();
    const { setState: setOperatorCtxState } = useOperatorCtxAPI();
    const { lawsuitsCount } = useOperatorCtxCounters();
    const lawsuits = useOperatorCtxLawsuits();
    const projects = useOperatorCtxProjects();
    const { handleTaskDragEnd } = useTasksCtxAPI();
    const activeUserTasks = useTasksCtxActiveUserTasks()
    const tasksPendingDocuments = useTasksCtxTasksPendingDocuments();
    const [activeUserTimesheet, set_activeUserTimesheet] = useState([]);
    const [foldersTimeChartData, setFoldersTimeChartData] = useState([]);
    
    const [lawsuitsProgress, set_lawsuitsProgress] = useState(null);
    const [dashboardDocs, set_dashboardDocs] = useState(null);

    const [numberOfSentDocuments, set_numberOfSentDocuments] = useState(null);
    const [monthNumberOfDocumentsGenerated, set_monthNumberOfDocumentsGenerated] = useState(null);

    useEffect(() => {
        let isMounted = true;
        // const cancelTokenSource = axios.CancelToken.source();
        if(activeUser && activeClient){
            axios.get(SERVER_PATH + '/data/operator/overview', {
                // cancelToken: cancelTokenSource.token
            })
            .then(res => {
                if(isMounted){
                    if(activeClient?.modules?.includes('timesheet')) set_activeUserTimesheet(res.data.timesheet);

                    if(activeClient?.modules?.includes('documents')){
                        set_dashboardDocs(res.data.docs);
                    }
                    
                    if(activeClient?.modules?.includes('lawsuits')){
                        let dashboardLawsuits = res.data.lawsuits;
                        setOperatorCtxState('lawsuits', dashboardLawsuits);
                        let userLawsuitsProgress = res.data.lawsuitsProgress;
                        userLawsuitsProgress = userLawsuitsProgress.filter(lp => {
                            let parent = dashboardLawsuits.find(l => l._id === lp.parent);
                            if(parent){
                                return parent.lawyer.includes(activeUser._id);
                            }
                            return false;
                        });
                        set_lawsuitsProgress(userLawsuitsProgress);
                    }
                    
                    set_numberOfSentDocuments(res.data.numberOfSentDocuments || 0);
                }
            })
            .catch(err => {
                toast(ERROR_MESSAGE_UNKNOWN);
            });
        }
        return () => {
            // cancelTokenSource.cancel();
            isMounted = false;
        };
    }, [activeUser, activeClient]);

    useEffect(() => {
        if(activeUser && activeUser?.type === 2 || activeUser?.type === 3) handleNavigate(`/${activeClient.shortName}/juridico/financeiro/propostas`);
    }, [activeUser]);

    useEffect(() => {
        if(projects && projects.length >= 1 && activeUserTimesheet.length >= 1){
            let hoursCounter = getChartDataFoldersTime(activeUserTimesheet, setFoldersTimeChartData, projects);
            hoursCounter = Math.floor(hoursCounter / 60);
            setOperatorCtxState('hoursCount', hoursCounter)
        }
    }, [activeUserTimesheet, projects]);

    useEffect(() => {
        if(activeClient){
            let numberOfDocumentsGenerated = 0;
            const monthTotals = activeClient.monthTotals;
            if(monthTotals){
                const year = moment().year();
                if(monthTotals[year]){
                    const month = moment().month();
                    if(monthTotals[year][month]) numberOfDocumentsGenerated = monthTotals[year][month].numberOfDocumentsGenerated || 0;
                }
            }
            set_monthNumberOfDocumentsGenerated(numberOfDocumentsGenerated);
        }
    }, [activeClient]);

    return (
        <ViewBox>
            {
                activeUser?.type >= 3
                ?
                <Box>
                    <Box style={{
                        borderRadius: 8,
                        padding: '8px',
                        backgroundColor: boxBackgroundColor,
                        marginBottom: '8px',
                        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)'
                    }}>
                        <Grid container spacing={1} justifyContent="center">
                            <Grid item xs={12} sm={6} md={3} container justifyContent="center">
                                <NumberOfPendingDocuments />
                            </Grid>
                            {
                                activeClient?.modules?.includes('lawsuits') && 
                                <Grid item xs={12} sm={6} md={3} container justifyContent="center">
                                    <DashboardCounter
                                        icon={<FindInPageOutlinedIcon />}
                                        label="andamentos judiciais em aberto"
                                        loading={lawsuitsCount === null}
                                    >{lawsuitsCount}</DashboardCounter>
                                </Grid>
                            }
                            {
                                activeClient?._id === FIRST_CLIENT_ID &&
                                <Grid item xs={12} sm={6} md={3} container justifyContent="center">
                                    <DashboardCounter
                                        icon={<SendOutlinedIcon />}
                                        label="contratos enviados até hoje"
                                        loading={numberOfSentDocuments === null}
                                    >{numberOfSentDocuments}</DashboardCounter>
                                </Grid>
                            }
                            {
                                activeClient?._id !== FIRST_CLIENT_ID &&
                                <Grid item xs={12} sm={6} md={3} container justifyContent="center">
                                    <DashboardCounter
                                        icon={<SettingsOutlinedIcon />}
                                        label="documentos gerados no mês"
                                        loading={monthNumberOfDocumentsGenerated === null}
                                    >{monthNumberOfDocumentsGenerated}</DashboardCounter>
                                </Grid>
                            }
                        </Grid>
                    </Box>
                    <Collapse in={activeClient && activeClient._id !== FIRST_CLIENT_ID && !!activeClient.documentsGeneratedMonthLimit && monthNumberOfDocumentsGenerated && (monthNumberOfDocumentsGenerated >= (activeClient.documentsGeneratedMonthLimit * 0.8))}>
                        <Box mb={1}>
                            <Alert variant="filled" severity="error" onClose={() => set_monthNumberOfDocumentsGenerated(0)}>Você já consumiu {monthNumberOfDocumentsGenerated} do limite de {activeClient?.documentsGeneratedMonthLimit} contratos mensais.</Alert>
                        </Box>
                    </Collapse>

                    <Grid container spacing={1} justifyContent="center">
                        {
                            (activeClient?.modules?.includes('tasks') && activeUser?.type >= 4) &&
                            <Grid item xs={12} sm={4} md={3}>
                                <SectionTitle title="Tarefas" />
                                {
                                    !activeUserTasks &&
                                    <NoPendingTasks icon={<CircularProgress size={20} />} />
                                }
                                <Collapse in={!!activeUserTasks} mountOnEnter>
                                    <Box>
                                        <DragDropContext onDragEnd={handleTaskDragEnd}>
                                            <Grid container spacing={1} alignItems="flex-start" justifyContent="center" style={{height: '100%'}}>
                                                <Grid item xs container spacing={1} alignItems="flex-start" style={{height: '100%'}}>
                                                    <Grid item style={{height: '100%', width: `100%`}}>
                                                        <TaskBoard
                                                            user={{ img: activeUser.img, screenName: activeUser.screenName }}
                                                            userNumberOfPendingDocuments={(tasksPendingDocuments[activeUser._id] || 0).toString()}
                                                        >
                                                            <Droppable droppableId={'activeUserUnreadTasks'} isDropDisabled>
                                                                {(provided, snapshot) => (
                                                                    <TasksList
                                                                        boardId={'activeUserUnreadTasks'}
                                                                        innerRef={provided.innerRef}
                                                                        isActiveUserTask
                                                                        isDraggingOver={snapshot.isDraggingOver}
                                                                        tasks={activeUserTasks.activeUserUnreadTasks}
                                                                        
                                                                        {...provided.droppableProps}
                                                                    >
                                                                        {provided.placeholder}
                                                                    </TasksList>
                                                                )}
                                                            </Droppable>
                                                            <Droppable droppableId={'activeUserHighPriorityTasks'}>
                                                                {(provided, snapshot) => (
                                                                    <TasksList
                                                                        boardId={'activeUserHighPriorityTasks'}
                                                                        innerRef={provided.innerRef}
                                                                        isActiveUserTask
                                                                        isDraggingOver={snapshot.isDraggingOver}
                                                                        tasks={activeUserTasks.activeUserHighPriorityTasks}
                                                                        
                                                                        {...provided.droppableProps}
                                                                    >
                                                                        {provided.placeholder}
                                                                    </TasksList>
                                                                )}
                                                            </Droppable>
                                                            <Droppable droppableId={'activeUserMediumPriorityTasks'}>
                                                                {(provided, snapshot) => (
                                                                    <TasksList
                                                                        boardId={'activeUserMediumPriorityTasks'}
                                                                        innerRef={provided.innerRef}
                                                                        isActiveUserTask
                                                                        isDraggingOver={snapshot.isDraggingOver}
                                                                        tasks={activeUserTasks.activeUserMediumPriorityTasks}
                                                                        
                                                                        {...provided.droppableProps}
                                                                    >
                                                                        {provided.placeholder}
                                                                    </TasksList>
                                                                )}
                                                            </Droppable>
                                                            <Droppable droppableId={'activeUserLowPriorityTasks'}>
                                                                {(provided, snapshot) => (
                                                                    <TasksList
                                                                        boardId={'activeUserLowPriorityTasks'}
                                                                        innerRef={provided.innerRef}
                                                                        isActiveUserTask
                                                                        isDraggingOver={snapshot.isDraggingOver}
                                                                        tasks={activeUserTasks.activeUserLowPriorityTasks}
                                                                        
                                                                        {...provided.droppableProps}
                                                                    >
                                                                        {provided.placeholder}
                                                                    </TasksList>
                                                                )}
                                                            </Droppable>
                                                        </TaskBoard>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </DragDropContext>
                                    </Box>
                                </Collapse>
                            </Grid>
                        }
                        <Grid item xs={12} sm={activeClient?.modules?.includes('tasks') ? 8 : 12} md={activeClient?.modules?.includes('tasks') ? 9 : 12} container spacing={1}>
                            {
                                activeUser?.type >= 4 &&
                                <Grid item xs={12} sm={12} md={6}>
                                    {
                                        activeClient?.modules?.includes('timesheet') &&
                                        <Box>
                                            <SectionTitle title={`Timesheet em ${moment().format('MMMM')}`} />
                                            {
                                                !projects &&
                                                <NoPendingTasks icon={<CircularProgress size={20} />} />
                                            }
                                            <Collapse in={!!projects}>
                                                <Box>
                                                    {
                                                        foldersTimeChartData.length >= 1
                                                        ?
                                                        <DashboardBox>
                                                            <Box style={{margin: '0 auto'}}>
                                                                <Chart height={224} chartType="PieChart"
                                                                    data={foldersTimeChartData}
                                                                    options={{
                                                                        backgroundColor: boxBackgroundColor,
                                                                        sliceVisibilityThreshold: .03,
                                                                        pieResidueSliceLabel: 'Outros',
                                                                        pieSliceText: 'value',
                                                                        chartArea:  {
                                                                            top: 30,
                                                                            width: '100%',
                                                                            height: '100%',
                                                                        },
                                                                    }}
                                                                    rootProps={{ 'data-testid': '1' }}
                                                                />
                                                            </Box>
                                                        </DashboardBox>
                                                        : <NoPendingTasks label="Você não fez lançamentos no timesheet neste mês." icon={<AccessTimeIcon />} />
                                                    }
                                                </Box>
                                            </Collapse>
                                        </Box>
                                    }
                                    {
                                        activeClient?.modules?.includes('documents') &&
                                        <Box>
                                            <SectionTitle title="Documentos" />
                                            {
                                                (!dashboardDocs || !projects) &&
                                                <NoPendingTasks icon={<CircularProgress size={20} />} />
                                            }
                                            <Collapse in={!!dashboardDocs && !!projects}>
                                                <Box>
                                                    {
                                                        (!!projects && dashboardDocs?.length >= 1)
                                                        ?
                                                        <DashboardBox>
                                                            {
                                                                dashboardDocs.map((item) => (
                                                                    <Box key={item._id} mb={1}>
                                                                        <Box mb={1}>
                                                                            <Grid container spacing={1}>
                                                                                <Grid item container spacing={1}>
                                                                                    <Grid item xs={2}>
                                                                                        <Typography variant="body2">{moment(item.timestamp).format('L')}</Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={2}>
                                                                                        <Typography variant="body2">{getDocumentFolderName(item, projects)}</Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs>
                                                                                        <Typography variant="body2"><ButtonLink onClick={() => handleNavigate(`/${activeClient.shortName}/juridico/documentos/pendentes?u=${activeUser._id}`)}>{item.name}</ButtonLink></Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>
                                                                        <Divider />
                                                                    </Box>
                                                                ))
                                                            }
                                                        </DashboardBox>
                                                        : <NoPendingTasks label="Nenhum documento aguardando a sua revisão." />
                                                    }
                                                </Box>
                                            </Collapse>
                                        </Box>
                                    }
                                </Grid>
                            }
                            <Grid item xs={12} sm={12} md={6}>
                                {
                                    activeClient?.modules?.includes('lawsuits') &&
                                    <Box>
                                        <SectionTitle title="Processos" />
                                        {
                                            !lawsuitsProgress &&
                                            <NoPendingTasks icon={<CircularProgress size={20} />} />
                                        }
                                        <Collapse in={!!lawsuitsProgress}>
                                            <Box>
                                                {
                                                    lawsuitsProgress?.length >= 1
                                                    ?
                                                    <TinyTable
                                                        backgroundColor={boxBackgroundColor}
                                                        head={[
                                                            {content: 'Data'},
                                                            {content: 'Processo'},
                                                            {content: 'Andamento'},
                                                        ]}
                                                        body={
                                                            lawsuitsProgress.map((item) => {
                                                                let rowStyle;
                                                                
                                                                let thisMoment = moment();
                                                                let currentDayOfWeek = thisMoment.day();
                                                                let addHours = 0;
                                                                if(currentDayOfWeek === 5){
                                                                    addHours = thisMoment.hours();
                                                                } else if(currentDayOfWeek === 6){
                                                                    addHours = 24 + thisMoment.hours();
                                                                } else if(currentDayOfWeek === 1 || currentDayOfWeek === 2){
                                                                    addHours = 48;
                                                                }
                                                                let documentIsVeryLate = false, documentIsLate = false;
                                                                if(thisMoment.diff(moment(item.progressDate), 'hours') >= (48 + addHours)){
                                                                    documentIsVeryLate = !item.viewed;
                                                                } else if(thisMoment.diff(moment(item.progressDate), 'hours') >= (24 + addHours)){
                                                                    documentIsLate = !item.viewed;
                                                                }

                                                                if(documentIsVeryLate || documentIsLate){
                                                                    rowStyle = {
                                                                        backgroundColor: documentIsVeryLate ? '#ef9a9a' : (documentIsLate ? '#fff9c4' : '')
                                                                    };
                                                                }
                                                                return ({
                                                                    columns: [
                                                                        {content: <Typography variant="body2">{moment(item.progressDate).format('L')}</Typography>},
                                                                        {content: <Typography variant="body2">{getLawsuitNameById(item.parent, lawsuits, projects)}</Typography>},
                                                                        {content: <Typography variant="body2">{documentIsVeryLate || documentIsLate ? <span style={{color: 'red'}}>Favor acessar o módulo Processos e marcar a caixa "Visto" para este andamento! </span> : ''}{item.progress}</Typography>},
                                                                    ]
                                                                });
                                                            })
                                                        }
                                                        useTableLayoutWithSmallWindow
                                                    />
                                                    : <NoPendingTasks label="Nenhum andamento processual em aberto." />
                                                }
                                            </Box>
                                        </Collapse>
                                    </Box>
                                }

                                <SectionTitle title="Mensagens" />
                                <Chat />
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                :
                <Box>
                    <SectionTitle title="Olá!" />
                </Box>
            }
        </ViewBox>
    );
}

export default Home;