import React, { useEffect, useState } from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';

const boxBackgroundColor = '#f5f6fa';
const classes = {
    root: {
        borderRadius: 8,
        padding: '16px',
        backgroundColor: boxBackgroundColor,
        marginBottom: '16px',
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)'
    }
};

const FormSigners = ({signersRef}) => {
    const [signerName, setSignerName] = useState('');
    const [signerEmail, setSignerEmail] = useState('');
    const [otherEmails, setOtherEmails] = useState('');

    useEffect(() => {
        if(signersRef){
            signersRef.current = {
                signerName: '',
                signerEmail: '',
                otherEmails: ''
            };
        }
    }, [signersRef]);

    const handleSignerNameChange = (e) => {
        const newValue = e.target.value;
        setSignerName(newValue);
        signersRef.current = {...signersRef.current, signerName: newValue};
    };
    const handleSignerEmailChange = (e) => {
        const newValue = e.target.value;
        setSignerEmail(newValue);
        signersRef.current = {...signersRef.current, signerEmail: newValue};
    };
    const handleOtherEmailsChange = (e) => {
        const newValue = e.target.value;
        setOtherEmails(newValue);
        signersRef.current = {...signersRef.current, otherEmails: newValue};
    };

    return (
        <Box sx={classes.root}>
            <Typography variant="h4">Quando estiver pronto, quem é a outra parte que revisará o documento?</Typography>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={9}>
                    <TextField
                        variant="standard"
                        fullWidth
                        label="Nome"
                        value={signerName}
                        onChange={handleSignerNameChange} />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                        variant="standard"
                        fullWidth
                        label="E-mail"
                        value={signerEmail}
                        onChange={handleSignerEmailChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        variant="standard"
                        fullWidth
                        label="Enviar o documento com cópia para os e-mails abaixo"
                        value={otherEmails}
                        onChange={handleOtherEmailsChange} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default FormSigners;