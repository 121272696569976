import Typography from '@mui/material/Typography';
import Header from '../../../../components/Header';
import { useAppCtxSelectedFolder } from '../../../../context/SystemContext';
import { useFolderUserCtxAPI, useFolderUserCtxSidenavMobile } from '../../context/ClientContext';
import Account from '../../Account';

const classes = {
    title: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
};

const ClientHeader = () => {
    const selectedFolder = useAppCtxSelectedFolder();
    const { setSidenavMobileOpen } = useFolderUserCtxAPI();
    const sidenavMobileOpen = useFolderUserCtxSidenavMobile();

    return (
        <Header
            leftBox={
                <Typography variant="h2" sx={classes.title}>{selectedFolder ? selectedFolder.name : ''}</Typography>
            }
            onlineUsers={
                <></>
            }
            account={<Account />}
            data={{
                sidenavMobileOpen, set_sidenavMobileOpen: setSidenavMobileOpen
            }}
        />
    );
};

export default ClientHeader;