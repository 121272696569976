import { useNavigate } from 'react-router-dom';
import { createContext, useCallback, useContext, useMemo, useReducer, useRef, useEffect } from 'react';
import socketIOClient from 'socket.io-client';
import moment from 'moment';
import { useAppCtxAPI, useAppCtxUserLocation } from './SystemContext';

const SocketContext = createContext();
const SocketCtxAPI = createContext();
const SocketCtxOnlineUsers = createContext();
const SocketCtxConnectedAt = createContext();

const defaultState = {
    onlineUsersList: null,
    socketConnectedAt: null
};

const reducer = (state, action) => {
    const { payload, type } = action;

    switch (type) {
        case 'SET STATE':
            return { ...state, [payload.key]: payload.value };
        default: return state;
    }
    
};

const messageSocketEventName = 'MESSAGE';
const onlineUsersEventName = 'ONLINE USERS';

const SocketProvider = ({children}) => {
    const navigate = useNavigate();
    const { floatingAlert, logout } = useAppCtxAPI();
    const { userLocation } = useAppCtxUserLocation();
    const socketIOServer = window.location.hostname === 'localhost' ? 'http://localhost:5000' : `https://server.solidasistemas.com.br`;
    const socketIOOptions = { withCredentials: true };

    const [state, dispatch] = useReducer(reducer, {...defaultState});
    const api = useMemo(() => {

        const setShouldUpdateClient = (newValue) => {
            shouldUpdateClient.current = newValue;
        };

        const setShouldUpdatePendingDocuments = (newValue) => {
            shouldUpdatePendingDocuments.current = newValue;
        };

        const setShouldUpdateTasks = (newValue) => {
            shouldUpdateTasks.current = newValue;
        };

        const setShouldUpdateTemplates = (newValue) => {
            shouldUpdateTemplates.current = newValue;
        };
                
        const setState = (key, value) => {
            dispatch({ type: 'SET STATE', payload: { key, value } });
        };

        return {
            dispatch,
            setShouldUpdateClient,
            setShouldUpdatePendingDocuments,
            setShouldUpdateTasks,
            setShouldUpdateTemplates,
            setState,
        };
    }, []);
    
    const socketRef = useRef(null);
    const shouldUpdateClient = useRef(true);
    const shouldUpdatePendingDocuments = useRef(true);
    const shouldUpdateTasks = useRef(true);
    const shouldUpdateTemplates = useRef(true);
    
    const createSocketConnection = useCallback(() => {
        if(!socketRef.current){
            socketRef.current = socketIOClient.io(socketIOServer, socketIOOptions);
        }
        return socketRef.current;
    }, []);

    const socket = createSocketConnection();

    function onConnectEvent(){
        console.log(`\nSOCKET connected with id ${socket.id} at ${moment().format('L LTS')}`);
        shouldUpdateClient.current = true;
        shouldUpdatePendingDocuments.current = true;
        shouldUpdateTasks.current = true;
        shouldUpdateTemplates.current = true;
        dispatch({ type: 'SET STATE', payload: { key: 'socketConnectedAt', value: new Date() } });
    }

    function onOnlineUsersEvent(onlineUsersList){
        dispatch({ type: 'SET STATE', payload: { key: 'onlineUsersList', value: onlineUsersList } });
    }
    
    function onGotMessageEvent(message){
        floatingAlert(message);
    }

    function onForceDisconnectEvent(){
        logout(navigate);
    }

    function onDisconnectEvent(reason){
        if (reason === 'io server disconnect') {
            // the disconnection was initiated by the server, you need to reconnect manually
            socket.connect();
        }
        // else the socket will automatically try to reconnect
    }

    useEffect(() => {
        const forceDisconnectSocketEventName = 'FORCE DISCONNECT';

        socket.on('connect', onConnectEvent);
        socket.on(forceDisconnectSocketEventName, onForceDisconnectEvent);
        socket.on(messageSocketEventName, onGotMessageEvent);
        socket.on(onlineUsersEventName, onOnlineUsersEvent);
        socket.on('disconnect', onDisconnectEvent);
        return () => {
            socket.off('connect', onConnectEvent);
            socket.off(forceDisconnectSocketEventName, onForceDisconnectEvent);
            socket.off(messageSocketEventName, onGotMessageEvent);
            socket.off(onlineUsersEventName, onOnlineUsersEvent);
            socket.off('disconnect', onDisconnectEvent);
            socket.disconnect();
        };
    }, []);

    useEffect(() => {
        if(userLocation){
            socket.emit('USER LOCATION UPDATED', { location: userLocation, timestamp: new Date() });
        }
    }, [userLocation]);

    const socketContextValue = useMemo(() => {
        return {
            createSocketConnection,
            shouldUpdateClient: shouldUpdateClient.current,
            shouldUpdatePendingDocuments: shouldUpdatePendingDocuments.current,
            shouldUpdateTasks: shouldUpdateTasks.current,
            shouldUpdateTemplates: shouldUpdateTemplates.current
        }
    }, [shouldUpdateClient.current, shouldUpdatePendingDocuments.current, shouldUpdateTasks.current, shouldUpdateTemplates.current]);

    return (
        <SocketCtxAPI.Provider value={api}>
        <SocketContext.Provider value={socketContextValue}>
        <SocketCtxConnectedAt.Provider value={state.socketConnectedAt}>
        <SocketCtxOnlineUsers.Provider value={state.onlineUsersList}>
            {children}
        </SocketCtxOnlineUsers.Provider>
        </SocketCtxConnectedAt.Provider>
        </SocketContext.Provider>
        </SocketCtxAPI.Provider>
    );
};

const useSocket = () => useContext(SocketContext);
const useSocketCtxAPI = () => useContext(SocketCtxAPI);
const useSocketCtxConnectedAt = () => useContext(SocketCtxConnectedAt);
const useSocketCtxOnlineUsers = () => useContext(SocketCtxOnlineUsers);

export {
    SocketProvider,
    useSocket,
    useSocketCtxAPI,
    useSocketCtxConnectedAt,
    useSocketCtxOnlineUsers
};