import { useState } from 'react';
import FormDialog from '../../../../../../components/FormDialog';
import { useOperatorTemplatesCtxAPI } from '../../context/TemplatesContext';
import { useFormEditor } from '../FormEditorContext';

const UpdateDescriptionDialog = ({setSelectedFormEditorQuestion}) => {
    const { updateFormEditorQuestion } = useOperatorTemplatesCtxAPI();
    const { clickedQuestion, updateDescriptionDialogOpen, set_updateDescriptionDialogOpen } = useFormEditor();
    const [textFieldValue, set_textFieldValue] = useState('');

    const handle_updateDescriptionDialogOkClick = () => {
        set_updateDescriptionDialogOpen(false);
        const newValue = {...clickedQuestion, description: textFieldValue};
        setSelectedFormEditorQuestion(newValue);
        updateFormEditorQuestion(newValue);
    };
    
    return (
        <FormDialog
            id="update-description-dialog"
            open={updateDescriptionDialogOpen} onEnter={() => set_textFieldValue(clickedQuestion.description)} onClose={() => set_updateDescriptionDialogOpen(false)}
            title="Alterar descrição" text="Indique a descrição a seguir." textFieldLabel="Descrição"
            textFieldValue={textFieldValue} textFieldOnChange={(e) => set_textFieldValue(e.target.value)}
            okButtonOnClick={handle_updateDescriptionDialogOkClick}
        />
    );
}

export default UpdateDescriptionDialog;