import { createContext, useContext, useMemo, useReducer, useEffect } from 'react';
import { serverRequest } from '../utils/common';

const OperatorFoldersCtxAPI = createContext();
const OperatorFoldersCtxChangeFolderView = createContext();
const OperatorFoldersCtxFolderClearanceView = createContext();
const OperatorFoldersCtxFolderSignatureView = createContext();
const OperatorFoldersCtxFolderTemplatesView = createContext();
const OperatorFoldersCtxVisibleFolders = createContext();

const defaultState = {
    changeFolderViewOpen: false,
    changeFolderViewClickedFolder: null,
    changeFolderViewFoundFolder: null,
    changeFolderViewUpdatedAt: null,
    folderClearanceViewOpen: false,
    folderClearanceViewSelectedFolder: null,
    folderClearanceViewUpdatedAt: null,
    folderSignatureViewOpen: false,
    folderSignatureViewSelectedFolder: null,
    folderSignatureViewUpdatedAt: null,
    folderTemplatesViewOpen: false,
    folderTemplatesViewSelectedFolder: null,
    folderTemplatesViewUpdatedAt: null,
    folderUsers: [],
    isLoadingVisibleFolders: true,
    visibleFolders: [],
    visibleFoldersUpdatedAt: null
};

const reducer = (state, action) => {
    const { payload, type } = action;
    
    switch (type) {
        case 'ADD VISIBLE FOLDER':
            if(state.visibleFolders){
                return {
                    ...state,
                    visibleFolders: [...state.visibleFolders, payload],
                    visibleFoldersUpdatedAt: new Date()
                };
            }
            return state;
        case 'HIDE CHANGE FOLDER VIEW':
            return { ...state, changeFolderViewOpen: false, changeFolderViewClickedFolder: null };
        case 'HIDE FOLDER CLEARANCE VIEW':
            return { ...state, folderClearanceViewOpen: false, folderClearanceViewSelectedFolder: null };
        case 'HIDE FOLDER SIGNATURE VIEW':
            return { ...state, folderSignatureViewOpen: false, folderSignatureViewSelectedFolder: null };
        case 'HIDE FOLDER TEMPLATES VIEW':
            return { ...state, folderTemplatesViewOpen: false, folderTemplatesViewSelectedFolder: null };
        case 'SET CHANGE FOLDER VIEW CLICKED FOLDER':
            return { ...state, changeFolderViewClickedFolder: payload };
        case 'SET CHANGE FOLDER VIEW FOUND FOLDER':
            return { ...state, changeFolderViewFoundFolder: payload, changeFolderViewUpdatedAt: new Date() };
        case 'SET FOLDER CLEARANCE VIEW SELECTED FOLDER':
            return { ...state, folderClearanceViewSelectedFolder: payload, folderClearanceViewUpdatedAt: new Date() };
        case 'SET FOLDER SIGNATURE VIEW SELECTED FOLDER':
            return { ...state, folderSignatureViewSelectedFolder: payload, folderSignatureViewUpdatedAt: new Date() };
        case 'SET FOLDER TEMPLATES VIEW SELECTED FOLDER':
            return { ...state, folderTemplatesViewSelectedFolder: payload, folderTemplatesViewUpdatedAt: new Date() };
        case 'SET FOLDER USERS':
            return { ...state, folderUsers: payload };
        case 'SET STATE':
            return { ...state, [payload.key]: payload.value };
        case 'SET VISIBLE FOLDERS':
            return { ...state, visibleFolders: payload, isLoadingVisibleFolders: false, visibleFoldersUpdatedAt: new Date() };
        case 'SET VISIBLE FOLDERS LOADING':
            return { ...state, isLoadingVisibleFolders: payload };
        case 'SHOW ADD FOLDER VIEW':
            return { ...state, changeFolderViewOpen: true, changeFolderViewClickedFolder: null, changeFolderViewFoundFolder: null };
        case 'SHOW CHANGE FOLDER VIEW':
            return { ...state, changeFolderViewOpen: true, changeFolderViewClickedFolder: payload.clickedFolder, changeFolderViewFoundFolder: null };
        case 'SHOW FOLDER CLEARANCE VIEW':
            return { ...state, folderClearanceViewOpen: true, folderClearanceViewSelectedFolder: payload.selectedFolder };
        case 'SHOW FOLDER SIGNATURE VIEW':
            return { ...state, folderSignatureViewOpen: true, folderSignatureViewSelectedFolder: payload.selectedFolder };
        case 'SHOW FOLDER TEMPLATES VIEW':
            return { ...state, folderTemplatesViewOpen: true, folderTemplatesViewSelectedFolder: payload.selectedFolder };
        case 'UPDATE VISIBLE FOLDER':
            if(state.visibleFolders){
                const visibleFolderIndex = state.visibleFolders.findIndex(i => i._id === payload._id);
                if(visibleFolderIndex !== -1){
                    return {
                        ...state,
                        visibleFolders: [...state.visibleFolders.slice(0, visibleFolderIndex), {...payload, updatedAt: new Date()}, ...state.visibleFolders.slice(visibleFolderIndex + 1)],
                        visibleFoldersUpdatedAt: new Date()
                    };
                }
            }
            return state;
        default: return state;
    }
    
};

const OperatorFoldersProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, {...defaultState});

    const setVisibleFolders = (payload) => {
        dispatch({type: 'SET VISIBLE FOLDERS', payload});
    };

    const api = useMemo(() => {

        const addVisibleFolder = (payload) => {
            dispatch({type: 'ADD VISIBLE FOLDER', payload});
        };
        
        const hideChangeFolderView = (payload) => {
            dispatch({type: 'HIDE CHANGE FOLDER VIEW', payload});
        };

        const hideFolderClearanceView = (payload) => {
            dispatch({type: 'HIDE FOLDER CLEARANCE VIEW', payload});
        };

        const hideFolderSignatureView = (payload) => {
            dispatch({type: 'HIDE FOLDER SIGNATURE VIEW', payload});
        };

        const hideFolderTemplatesView = (payload) => {
            dispatch({type: 'HIDE FOLDER TEMPLATES VIEW', payload});
        };

        const setChangeFolderViewClickedFolder = (clickedFolder) => {
            dispatch({type: 'SET CHANGE FOLDER VIEW CLICKED FOLDER', payload: clickedFolder});
        };

        const setChangeFolderViewFoundFolder = (selectedFolder) => {
            dispatch({type: 'SET CHANGE FOLDER VIEW FOUND FOLDER', payload: selectedFolder});
        };

        const setFolderClearanceViewSelectedFolder = (selectedFolder) => {
            dispatch({type: 'SET FOLDER CLEARANCE VIEW SELECTED FOLDER', payload: selectedFolder});
        };

        const setFolderSignatureViewSelectedFolder = (selectedFolder) => {
            dispatch({type: 'SET FOLDER SIGNATURE VIEW SELECTED FOLDER', payload: selectedFolder});
        };

        const setFolderTemplatesViewSelectedFolder = (selectedFolder) => {
            dispatch({type: 'SET FOLDER TEMPLATES VIEW SELECTED FOLDER', payload: selectedFolder});
        };

        const setState = (key, value) => {
            dispatch({ type: 'SET STATE', payload: { key, value } });
        };

        const setLoadingVisibleFolders = (payload) => {
            dispatch({type: 'SET VISIBLE FOLDERS LOADING', payload});
        };
        
        const showAddFolderView = (payload) => {
            dispatch({type: 'SHOW ADD FOLDER VIEW', payload});
        };
        
        const showChangeFolderView = (clickedFolder) => {
            dispatch({type: 'SHOW CHANGE FOLDER VIEW', payload: { clickedFolder }});
        };

        const showFolderClearanceView = (selectedFolder) => {
            dispatch({type: 'SHOW FOLDER CLEARANCE VIEW', payload: { selectedFolder }});
        };

        const showFolderSignatureView = (selectedFolder) => {
            dispatch({type: 'SHOW FOLDER SIGNATURE VIEW', payload: { selectedFolder }});
        };

        const showFolderTemplatesView = (selectedFolder) => {
            dispatch({type: 'SHOW FOLDER TEMPLATES VIEW', payload: { selectedFolder }});
        };

        const updateVisibleFolder = (payload) => {
            dispatch({type: 'UPDATE VISIBLE FOLDER', payload});
        };

        return {
            dispatch,
            addVisibleFolder,
            hideChangeFolderView,
            hideFolderClearanceView,
            hideFolderSignatureView,
            hideFolderTemplatesView,
            setChangeFolderViewClickedFolder,
            setChangeFolderViewFoundFolder,
            setFolderClearanceViewSelectedFolder,
            setFolderSignatureViewSelectedFolder,
            setFolderTemplatesViewSelectedFolder,
            setState,
            setVisibleFolders,
            setLoadingVisibleFolders,
            showAddFolderView,
            showChangeFolderView,
            showFolderClearanceView,
            showFolderSignatureView,
            showFolderTemplatesView,
            updateVisibleFolder
        };
    }, []);

    const fetchFolder = async () => {
        try {
            const data = await serverRequest({path: `/data/projects/one`, method: 'post', data: {projectId: state.changeFolderViewClickedFolder._id, shouldGetFolderUsers: true}});
            const currentFoundProject = data.project;
            dispatch({ type: 'SET CHANGE FOLDER VIEW FOUND FOLDER', payload: currentFoundProject });
            dispatch({ type: 'SET FOLDER USERS', payload: data.folderUsers || [] });
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if(state.changeFolderViewClickedFolder){
            fetchFolder();
        } else {
            dispatch({ type: 'SET CHANGE FOLDER VIEW FOUND FOLDER', payload: null });
        }
    }, [state.changeFolderViewClickedFolder]);

    const operatorFoldersCtxChangeFolderViewValue = useMemo(() => ({
        changeFolderViewOpen: state.changeFolderViewOpen,
        changeFolderViewClickedFolder: state.changeFolderViewClickedFolder,
        changeFolderViewFoundFolder: state.changeFolderViewFoundFolder,
        changeFolderViewUpdatedAt: state.changeFolderViewUpdatedAt
    }), [state.changeFolderViewOpen, state.changeFolderViewUpdatedAt]);

    const operatorFoldersCtxFolderClearanceViewValue = useMemo(() => ({
        folderClearanceViewOpen: state.folderClearanceViewOpen,
        folderClearanceViewSelectedFolder: state.folderClearanceViewSelectedFolder,
        folderUsers: state.folderUsers
    }), [state.folderClearanceViewOpen, state.folderClearanceViewUpdatedAt]);

    const operatorFoldersCtxFolderSignatureViewValue = useMemo(() => ({
        folderSignatureViewOpen: state.folderSignatureViewOpen,
        folderSignatureViewSelectedFolder: state.folderSignatureViewSelectedFolder,
    }), [state.folderSignatureViewOpen, state.folderSignatureViewUpdatedAt]);

    const operatorFoldersCtxFolderTemplatesViewValue = useMemo(() => ({
        folderTemplatesViewOpen: state.folderTemplatesViewOpen,
        folderTemplatesViewSelectedFolder: state.folderTemplatesViewSelectedFolder,
    }), [state.folderTemplatesViewOpen, state.folderTemplatesViewUpdatedAt]);

    const operatorFoldersCtxVisibleFoldersValue = useMemo(() => ({
        isLoadingVisibleFolders: state.isLoadingVisibleFolders,
        visibleFolders: state.visibleFolders,
        // visibleFoldersUpdatedAt: state.visibleFoldersUpdatedAt
    }), [state.isLoadingVisibleFolders, state.visibleFolders]);

    return (
        <OperatorFoldersCtxAPI.Provider value={api}>
        <OperatorFoldersCtxChangeFolderView.Provider value={operatorFoldersCtxChangeFolderViewValue}>
        <OperatorFoldersCtxVisibleFolders.Provider value={operatorFoldersCtxVisibleFoldersValue}>
        <OperatorFoldersCtxFolderClearanceView.Provider value={operatorFoldersCtxFolderClearanceViewValue}>
        <OperatorFoldersCtxFolderSignatureView.Provider value={operatorFoldersCtxFolderSignatureViewValue}>
        <OperatorFoldersCtxFolderTemplatesView.Provider value={operatorFoldersCtxFolderTemplatesViewValue}>
            {children}
        </OperatorFoldersCtxFolderTemplatesView.Provider>
        </OperatorFoldersCtxFolderSignatureView.Provider>
        </OperatorFoldersCtxFolderClearanceView.Provider>
        </OperatorFoldersCtxVisibleFolders.Provider>
        </OperatorFoldersCtxChangeFolderView.Provider>
        </OperatorFoldersCtxAPI.Provider>
    );
};

const useOperatorFoldersCtxAPI = () => useContext(OperatorFoldersCtxAPI);
const useOperatorFoldersCtxChangeFolderView = () => useContext(OperatorFoldersCtxChangeFolderView);
const useOperatorFoldersCtxFolderClearanceView = () => useContext(OperatorFoldersCtxFolderClearanceView);
const useOperatorFoldersCtxFolderSignatureView = () => useContext(OperatorFoldersCtxFolderSignatureView);
const useOperatorFoldersCtxFolderTemplatesView = () => useContext(OperatorFoldersCtxFolderTemplatesView);
const useOperatorFoldersCtxVisibleFolders = () => useContext(OperatorFoldersCtxVisibleFolders);

export {
    OperatorFoldersProvider,
    useOperatorFoldersCtxAPI,
    useOperatorFoldersCtxChangeFolderView,
    useOperatorFoldersCtxFolderClearanceView,
    useOperatorFoldersCtxFolderSignatureView,
    useOperatorFoldersCtxFolderTemplatesView,
    useOperatorFoldersCtxVisibleFolders
};