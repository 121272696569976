import React, { useEffect, useState } from 'react';
import { Box, Checkbox, Divider, Grid, Typography } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import HtmlBox from '../HtmlBox';
import MultilineText from '../MultilineText';
import SideBarSection from '../SideBarSection';
import { copy } from '../../utils/common';

const classes = {
    clickableBox: {
        cursor: 'pointer',
        '& p': {
            '&:hover': {
                textDecoration: 'underline'
            },
            '&:active': {
                color: theme => theme.palette.primary.light
            }
        }
    }
};

const List = ({checklist, handleDoneCheckChange, review, showDone, talentFormState}) => {
    let questionNumber = 0;
    return talentFormState
    .filter(question => {
        if(checklist){
            return question.type !== 'sectionTitle' && !!question.done === showDone;
        }
        return true;
    })
    .map((question) => {
        
        const questionType = question.type;
        
        questionNumber++;
        let text = '';
        let clause = '';

        if(!question.information){
            text = <Typography variant="body2">-</Typography>;
        } else {
            if(questionType === 'text'){
                text = question.information?.input || '';
                if(text) text = <Typography variant="body2"><HtmlBox html={text.replace(/\n/g, '<br />')} /></Typography>;
                clause = question.information?.clause || '';
            } else if(questionType === 'radio'){
                text = (
                    <Grid container spacing={1} wrap="nowrap" alignItems="flex-start">
                        <Grid item>
                            <RadioButtonCheckedIcon style={{ fontSize: 12 }} />
                        </Grid>
                        <Grid item xs container spacing={1}>
                            <Grid item>
                                <Typography variant="body2">{question.information?.choice}</Typography>
                            </Grid>
                            {
                                question.information?.input &&
                                <Grid item xs={12}>
                                    <Typography variant="body2">{<HtmlBox html={question.information?.input.replace(/\n/g, '<br />')} />}</Typography>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                );
                clause = question.information?.clause || '';
            } else if(questionType === 'checkbox'){
                text = <Box>
                    {
                        question.information?.choices.map(choice => (
                            <Grid container spacing={1} wrap="nowrap" alignItems="flex-start">
                                <Grid item>
                                    <CheckBoxIcon style={{ fontSize: 12 }} />
                                </Grid>
                                <Grid item xs container spacing={1}>
                                    <Grid item>
                                        <Typography variant="body2">{choice.choice}</Typography>
                                    </Grid>
                                    {
                                        choice.input &&
                                        <Grid item xs={12}>
                                            <Typography variant="body2">{<HtmlBox html={choice.input.replace(/\n/g, '<br />')} />}</Typography>
                                        </Grid>
                                    }
                                    {
                                        (review && choice.clause) &&
                                        <Grid item xs={12}>
                                            <Box sx={classes.clickableBox} onClick={() => copy(choice.clause)}>
                                                <Typography variant="body2" color="primary">{choice.clause}</Typography>
                                            </Box>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        ))
                    }
                </Box>;
                clause = question.information?.clause || '';
            }
        }
        
        return (
            <Box key={question.id}>
                {
                    question.name &&
                    <Box mb={2}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs>
                                <Typography variant="h6">{questionNumber}. {question.name}</Typography>
                                {text}
                            </Grid>
                        </Grid>
                    </Box>
                }
                {
                    (review && (question.tips?.length >= 1 || clause)) &&
                    <Box mb={2}>
                        {
                            question.tips?.length >= 1 &&
                            <Box mb={1}>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <InfoOutlinedIcon color="primary" />
                                    </Grid>
                                    <Grid item xs>
                                        {
                                            question.tips.map((tip, tipIndex) => (
                                                <Box key={tipIndex} mb={1}>
                                                    <Typography variant="body2" color="primary"><HtmlBox html={tip} /></Typography>
                                                </Box>
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                        {
                            clause &&
                            <Box sx={classes.clickableBox} onClick={() => copy(clause)}>
                                <Typography variant="body2" color="primary"><MultilineText text={clause} /></Typography>
                            </Box>
                        }
                        
                    </Box>
                }
                {
                    checklist &&
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Checkbox
                                checked={!!question.done}
                                onChange={(e) => handleDoneCheckChange(question, e.target.checked)}
                            />
                        </Grid>
                    </Grid>
                }
                <Divider />
            </Box>
        );
    });
};

const ReadDocumentTalentForm = ({documentTalentForm, checklist, review, sectionTop}) => { 
    const [talentFormState, setTalentFormState] = useState([]);
    const [showDone, setShowDone] = useState(false);

    useEffect(() => {
        setTalentFormState(documentTalentForm);
    }, [documentTalentForm]);

    const handleDoneCheckChange = (updatedQuestion, newValue) => {
        setTalentFormState(prevState => {
            const index = prevState.findIndex(question => question.id === updatedQuestion.id);
            return [...prevState.slice(0, index), {...updatedQuestion, done: newValue}, ...prevState.slice(index + 1)];
        });
    };

    return (
        <Box>
            {
                documentTalentForm && Object.keys(documentTalentForm).length >= 1 &&
                <>
                    {
                        sectionTop &&
                        <SideBarSection title="TALENTO" showDone={showDone} setShowDone={setShowDone}><List checklist={checklist} handleDoneCheckChange={handleDoneCheckChange} review={review} showDone={showDone} talentFormState={talentFormState} /></SideBarSection>
                    }
                </>
            }
            {
                !sectionTop &&
                <List checklist={checklist} handleDoneCheckChange={handleDoneCheckChange} review={review} showDone={showDone} talentFormState={talentFormState} />
            }
        </Box>
    );
};

export default ReadDocumentTalentForm;