import { Grid, Button } from '@mui/material';

const TwoButtonsSwitch = ({boolean, setBoolean, firstButtonStartIcon, firstButtonText, secondButtonStartIcon, secondButtonText}) => {
    const handleClick = () => {
        setBoolean(!boolean);
    };
    return (
        <Grid item xs={12} container spacing={1} justifyContent="center">
            <Grid item>
                <Button
                    variant={boolean ? 'outlined' : 'text'}
                    color={boolean ? 'primary' : 'inherit'}
                    startIcon={firstButtonStartIcon}
                    onClick={handleClick}
                >{firstButtonText}</Button>
            </Grid>
            <Grid item>
                <Button
                    variant={boolean ? 'text' : 'outlined'}
                    color={boolean ? 'inherit' : 'primary'}
                    startIcon={secondButtonStartIcon}
                    onClick={handleClick}
                >{secondButtonText}</Button>
            </Grid>
        </Grid>
    );
};

export default TwoButtonsSwitch;