import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { SERVER_PATH } from '../utils/constants';
import { useAppCtxActiveUser } from '../context/SystemContext';
import { useSocket } from '../context/SocketContext';
import { useClientCtxActiveClient } from '../context/ClientContext';
import { useDocumentsAPI, useDocumentsCtxReviewerMenu } from '../context/DocumentsContext';

const ChangeReviewerMenu = ({operators, updateDocument, users}) => {
    const activeUser = useAppCtxActiveUser();
    const activeClient = useClientCtxActiveClient();
    const {createSocketConnection} = useSocket();
    const socket = createSocketConnection();
    const { setReviewerMenuOpen } = useDocumentsAPI();
    const { reviewerMenuAnchorEl, reviewerMenuOpen, reviewerMenuSelectedDocument: selectedDocument } = useDocumentsCtxReviewerMenu();

    const handleDocOperatorChangeClick = (operatorId, operatorName) => {
        handleClose();
        const documentCopy = {...selectedDocument};
        const operatorChangedAt = new Date();

        const foundUser = users.find(foundUser => foundUser._id === operatorId);

        updateDocument({...selectedDocument, sentBy: operatorId, sentByImage: foundUser?.img, sentByName: foundUser?.fullName, operatorChangedAt});
        let postData = {
            documentProject: selectedDocument.project || selectedDocument.client,
            documentId: selectedDocument._id,
            documentOperatorId: operatorId,
            operatorChangedAt
        }

        axios.post(SERVER_PATH + '/data/operator/docs/update/operator', postData)
        .then(res => {
            let newDoc = res.data; 
            socket.emit('DOCUMENT OPERATOR CHANGED', {
                clientId: activeClient._id, workspaceName: activeClient.name,
                actionUserId: activeUser._id, actionUserName: activeUser.fullName,
                documentOperatorId: operatorId, documentOperatorName: operatorName,
                documentName: newDoc.name,
                updatedDocument: newDoc
            });
        }).catch(() => {
            updateDocument(documentCopy);
        });
    };

    const handleClose = () => {
        setReviewerMenuOpen(false);
    };

    return (
        <Menu
            anchorEl={reviewerMenuAnchorEl}
            open={reviewerMenuOpen}
            onClose={handleClose}
        >
        {
            operators.map((operator, index) => (
                <MenuItem key={index} onClick={(e) => handleDocOperatorChangeClick(operator._id, operator.screenName)}>
                    <ListItemIcon>
                        <Avatar style={{height: 32, width: 32}} alt={operator.screenName} src={`/uploads/avatars/${operator?.img}`} />
                    </ListItemIcon>
                    <ListItemText primary={operator.screenName} />
                </MenuItem>
            ))
        }
        </Menu>
    );
}

export default ChangeReviewerMenu;