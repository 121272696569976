const radio = {
    createEffect: ({ effectSettings, newEffectId }) => {
        const newEffect = {
            id: newEffectId,
            type: effectSettings.type,
            question: effectSettings.question || effectSettings.label || '',
            label: effectSettings.label || effectSettings.question,
            options: [...effectSettings.options],
            textOption: {
                show: !!effectSettings.textOption?.show,
                output: !!effectSettings.textOption?.show ? (effectSettings.textOption?.output || '<span class="solida-effect textOption-output mceNonEditable">&nbsp;&bull; [ RESPOSTA ] &bull;&nbsp;</span>') : ''
            }
        };
        return { newEffect };
    },
    newEffectWindowHandleEffectTypeChange: ({}) => {
        const defaultEffectOption = {option: '', output: ''};
        const data = {};
        data.question = '';
        data.options = [defaultEffectOption, defaultEffectOption];
        data.textOption = {show: false, output: ''};
        return data;
    },
    setEffectOutput: ({ documentEditorValue, selectedEffect, selectedEffectOutput }) => {
        let option = {};
        if(selectedEffectOutput.optionIndex === 'textOption'){
            option = selectedEffect.textOption;
            option = {...option, output: documentEditorValue};
            selectedEffect.textOption = option;
        } else {
            option = selectedEffect.options[selectedEffectOutput.optionIndex];
            option = {...option, output: documentEditorValue};
            selectedEffect.options[selectedEffectOutput.optionIndex] = option;
        }
        return selectedEffect;
    },
    getSelectedEffectOutput: ({ selectedEffect, selectedEffectOutput }) => {
        let newValue = '';
        if(selectedEffectOutput.optionIndex === 'textOption'){
            newValue = selectedEffect.textOption?.output || '';
        } else {
            const selectedOption = selectedEffect.options[selectedEffectOutput.optionIndex];
            newValue = selectedOption?.output || '';
        }
        return newValue;
    },
    getOutputsContent: (data) => {
        let content = '';
        if(data){
            const { scannedEffect } = data;
            if(scannedEffect){
                scannedEffect.options.forEach((scannedEffectOption) => {
                    content += scannedEffectOption.output || '';
                });
                if(scannedEffect.textOption && scannedEffect.textOption.show){
                    content += scannedEffect.textOption.output || '';
                }
            }
        }
        return content;
    },
    getFormEditorQuestion: (data) => {
        if(data){
            const { effect, question } = data;
            const formEditorQuestion = {...question};
            const options = effect.options.map(option => ({id: option.id, choice: option.option, label: option.option}));
            if(effect.textOption?.show) options.push({id: 'other', choice: '~t;', label: 'Preencher resposta'});
            formEditorQuestion.options = options;
            return formEditorQuestion;
        }
        return false;
    },
    checkValidation: (data) => {
        let questionValid = false;
        if(data){
            questionValid = true;
            const { choice, input } = data;
            if(!choice || (['~t;', '&p'].includes(choice) && !input)) questionValid = false;
        }
        return questionValid;
    }
};

export {
    radio
}