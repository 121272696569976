import { memo, useEffect, useState } from 'react';
import { Box, Checkbox, Chip, Collapse, Grid, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LinkIcon from '@mui/icons-material/Link';
import ShadowedBox from '../../../components/ShadowedBox';
import { useOperatorCtxProjects } from '../context/OperatorContext';
import { useTasksCtxAPI, useTasksCtxCompleteTaskView, useTasksCtxDragDisabled, useTasksCtxFilters } from '../../../context/TasksContext';
import { getProjectNameById, minutesToStringHoursAndMinutes } from '../../../utils/common';
import { Draggable } from 'react-beautiful-dnd';
import moment from 'moment';

const defaultBackgroundColor = '#FFF5EE'; //seashell
const unreadColor = '#bbdefb'; // light blue
const highestPriorityColor = '#ef9a9a'; // red
const secondPriorityColor = '#fff9c4'; // yellow

const classes2 = {
    taskBox: {
        borderRadius: '6px',
        marginBottom: '4px',
        padding: '4px',
        position: 'relative',
        opacity: 0.9,
        transition: 'opacity .2s ease-out',
        '&:hover': {
            opacity: 1,    
        }
    },
    deletedTaskBox: {
        opacity: 0
    },
};

const AdditionalBoxComponent = ({box, render}) => {
    if(render) return render(box);
    return null;
};

const Task = memo(({
    boardId,
    boxFilterFn,
    index,
    isActiveUserTask,
    nameKey,
    onBoxClick,
    renderAdditionalBoxComponent,
    task,
}) => {
    const theme = useTheme();
    const projects = useOperatorCtxProjects();
    const { handleTaskChangeButtonPress, handleTaskDoneButtonPress, handleTaskReadButtonPress } = useTasksCtxAPI();
    const { completeTaskViewOpen, completeTaskViewSelectedTask: selectedTask } = useTasksCtxCompleteTaskView();
    const dragDisabled = useTasksCtxDragDisabled();
    const filters = useTasksCtxFilters();
    const defaultBorder = '1px solid #E8E8E8';
    const [description, setDescription] = useState('');
    const [collapseTask, setCollapseTask] = useState(false);
    const [taskChecked, setTaskChecked] = useState(false);
    const [taskDeleted, setTaskDeleted] = useState(false);

    useEffect(() => {
        if(task){
            const currentDescription = getProjectNameById(projects, task.project)
            setDescription(currentDescription);
        }
    }, [task]);

    const handleFilterBox = () => {
        if(boxFilterFn){
            return boxFilterFn(task, filters);
        }

        let currentCollapseTask = false;
        if(filters.project){
            currentCollapseTask = true;
            if(filters.project === 'all'){
                if(filters.client){
                    const companyId = filters.client._id;
                    let currentCompanyFolders = [companyId];
                    if(projects){
                        const foundCompanyFolders = projects.filter(folder => folder.client === companyId).map(folder => folder._id);
                        currentCompanyFolders = [...currentCompanyFolders, ...foundCompanyFolders];
                    }
                    if(currentCompanyFolders.includes(task.project)) currentCollapseTask = false;
                }
            } else if(filters.project === task.project) {
                currentCollapseTask = false;
            }
        }
        return currentCollapseTask;
    }

    useEffect(() => {
        const currentCollapseTask = handleFilterBox();
        setCollapseTask(currentCollapseTask);
    }, [filters]);

    useEffect(() => {
        if(!completeTaskViewOpen) setTaskChecked(false);
    }, [completeTaskViewOpen]);

    useEffect(() => {
        if(selectedTask){
            if(selectedTask._id === task._id){
                setTaskDeleted(selectedTask.deleted);
            }
        }
    }, [selectedTask])

    const openUrlInNewTab = (e, url) => {
        e.stopPropagation();
        window.open(url, '_blank').focus();
    };

    const getBorder = () => {
        let border = defaultBorder;
        if(task){
            if(task.deadline && moment().isAfter(moment(task.deadline).hours(23).minutes(59).seconds(59).milliseconds(999))) border = `2px solid ${theme.palette.secondary.main}`;
        }
        return border;
    };
    
    const getBackgroundColor = () => {
        let backgroundColor = defaultBackgroundColor;
        if(!task.viewed){
            backgroundColor = unreadColor;
        } else if(task.priority === 2){
            backgroundColor = highestPriorityColor;
        } else if(task.priority === 1 || task.priority === true){
            backgroundColor = secondPriorityColor;
        }
        if(task.backgroundColor) backgroundColor = task.backgroundColor;
        return backgroundColor;
    };

    const boxBackgroundColor = getBackgroundColor();
    let boxStyle = { border: getBorder(), backgroundColor: boxBackgroundColor, color: theme.palette.getContrastText(boxBackgroundColor) };
    const draggingStyle = {
        border: `1px solid grey`,
        opacity: 1,
    };
    let nameBoxStyle = {
        overflow: 'hidden',
        marginBottom: 4
    };
    nameBoxStyle.marginBottom = 1;
    const nameStyle = { lineHeight: 1 };

    const handleTaskPress = (e) => {
        if(onBoxClick){
            return onBoxClick(task);
        }
        if(isActiveUserTask){
            handleTaskChangeButtonPress(e, {...task, boardId}, index, boardId);
        }
    };

    const handleCheckboxPress = (e, task, boardId, index) => {
        setTaskChecked(true);
        handleTaskDoneButtonPress(e, task, boardId, index);
    };

    return (
        <Draggable draggableId={task.uuid || task._id} index={index} isDragDisabled={dragDisabled}
        >
            {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <Collapse in={!collapseTask}>
                        <div>
                            <ShadowedBox
                                mouseOverShadowEffect
                                additionalSxs={[classes2.taskBox, taskDeleted && classes2.deletedTaskBox]}
                                style={snapshot?.isDragging ? {...boxStyle, ...draggingStyle} : boxStyle} onClick={handleTaskPress}
                            >
                                <Box style={nameBoxStyle}>
                                    <Grid container alignItems="center">
                                        {
                                            (isActiveUserTask && task.viewed) &&
                                            <Grid item>
                                                <Checkbox color="primary" size="small"
                                                    style={{ padding: 0, paddingRight: 2 }}
                                                    icon={<RadioButtonUncheckedIcon fontSize="small" />}
                                                    checkedIcon={<CheckCircleOutlineIcon fontSize="small" />}
                                                    onClick={(e) => handleCheckboxPress(e, task, boardId, index)}
                                                    checked={taskChecked}
                                                />
                                            </Grid>
                                        }
                                        {
                                            task.hidden &&
                                            <Grid item style={{paddingRight: 2}}>
                                                <VisibilityOffIcon fontSize="small" />
                                            </Grid>
                                        }
                                        <Grid item xs container spacing={1} alignItems="center">
                                            <Grid item xs>
                                                <Typography variant="h6" style={nameStyle}>{task[nameKey || 'subject']}</Typography>
                                            </Grid>
                                            {
                                                (isActiveUserTask && task.taskUrl) &&
                                                <Grid item>
                                                    <IconButton
                                                        size="small" color="primary" title={task.taskUrl}
                                                        onClick={(e) => openUrlInNewTab(e, task.taskUrl)}
                                                        ><LinkIcon /></IconButton>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </Box>
                                {
                                    !!task.numberOfHoursRequired &&
                                    <Box mb={0.5}>
                                        <Typography variant="body2"
                                            style={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}
                                        >{minutesToStringHoursAndMinutes(task.numberOfHoursRequired)}</Typography>
                                    </Box>
                                }
                                {
                                    task.deadline &&
                                    <Box mb={0.5}>
                                        <Typography variant="body2"
                                            style={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}
                                        >{moment(task.deadline).format('L')}</Typography>
                                    </Box>
                                }
                                <AdditionalBoxComponent box={task} render={renderAdditionalBoxComponent} />
                                {
                                    !task.viewed
                                    ?
                                    <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
                                        {
                                            isActiveUserTask
                                            ?
                                            <Grid item style={{width: '100%'}}>
                                                <Chip
                                                    style={{width: '100%'}}
                                                    icon={<CheckIcon />}
                                                    label={<Typography variant="body2" style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>Ler</Typography>} variant="outlined" size="small" color="default"
                                                    onClick={(e) => handleTaskReadButtonPress(e, task, index)}
                                                />
                                            </Grid>
                                            :
                                            <Grid item>
                                                <Typography variant="body2"><em>Não lida</em></Typography>
                                            </Grid>
                                        }
                                    </Grid>
                                    :
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs>
                                            <Typography variant="body2"
                                                style={{
                                                    lineHeight: 1,
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis'
                                                }}
                                            >{description}</Typography>
                                        </Grid>
                                    </Grid>
                                }
                            </ShadowedBox>
                        </div>
                    </Collapse>
                </div>
            )}
        </Draggable>
    );
}, (prevProps, nextProps) => {
    return false; // props are not equal -> update the component
});

export default Task;