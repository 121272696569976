import { createContext, useContext, useRef, useState } from 'react';

const FormContext = createContext();

const FormProvider = ({children}) => {
    const [formValidation, setFormValidation] = useState({});
    const [foundCNPJ, setFoundCNPJ] = useState(null);
    const [foundCNPJs, setFoundCNPJs] = useState({});
    const [initialForm, set_initialForm] = useState({});
    const questionElements = useRef({});
    const [questionNameToCNPJ, setQuestionNameToCNPJ] = useState({});
    const [questionsStatus, setQuestionsStatus] = useState({});
    const [savedForm, set_savedForm] = useState(null);
    const [selectedFormId, set_selectedFormId] = useState('');
    const [selectedForm, set_selectedForm] = useState(null);
    const [visibleQuestions, set_visibleQuestions] = useState([]);
    const [userForm, set_userForm] = useState({});
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [formActiveClient, setFormActiveClient] = useState(null);
    const usedSupplierInformation = useRef(false);

    const resetForm = () => {
        questionElements.current = {};
        setFormValidation({});
        setFoundCNPJ(null);
        setFoundCNPJs({});
        setQuestionNameToCNPJ({});
        setQuestionsStatus({});
        setSelectedSupplier(null);
        usedSupplierInformation.current = false;
    };

    return (
        <FormContext.Provider
            value={{
                formActiveClient, setFormActiveClient,
                formValidation, setFormValidation,
                foundCNPJ, setFoundCNPJ,
                foundCNPJs, setFoundCNPJs,
                initialForm, set_initialForm,
                questionElements,
                questionNameToCNPJ, setQuestionNameToCNPJ,
                questionsStatus, setQuestionsStatus,
                resetForm,
                savedForm, set_savedForm,
                selectedFormId, set_selectedFormId,
                selectedForm, set_selectedForm,
                selectedSupplier, setSelectedSupplier,
                visibleQuestions, set_visibleQuestions,
                usedSupplierInformation,
                userForm, set_userForm,
            }}
        >
            {children}
        </FormContext.Provider>
    );
};

const useForm = () => {
    const context = useContext(FormContext);
    return context;
};

export {
    FormProvider,
    useForm
};