import { Grid, Typography } from '@mui/material';
import ViewBox from '../components/ViewBox';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const PageNotFound = () => {
    return (
        <ViewBox>
            <Grid container spacing={1} alignItems="center" justifyContent="center">
                <Grid item>
                    <ErrorOutlineIcon />
                </Grid>
                <Grid item>
                    <Typography variant="h2">O endereço {window.location.pathname} não foi encontrado.</Typography>
                    <Typography variant="h4">Verifique o endereço...</Typography>
                </Grid>
            </Grid>
        </ViewBox>
    );
};

export default PageNotFound;