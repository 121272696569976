import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Button, Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import Autocomplete from '../../../../components/Autocomplete';
import DataListItem from '../../../../components/DataListItem';
import NewClientWindow from './components/NewClientWindow';
import SectionTitle from '../../../../components/SectionTitle';
import ViewBox from '../../../../components/ViewBox';
import { CLIENT_MODULES, SERVER_PATH } from '../../../../utils/constants';
import { sortByKey } from '../../../../utils/filters';
import { useAppCtxAPI, useAppCtxActiveUser, useAppCtxAvailableClientsCompaniesFolders } from '../../../../context/SystemContext';
import { useSocket } from '../../../../context/SocketContext';
import { useClient, useClientCtxAPI, useClientCtxActiveClient } from '../../../../context/ClientContext';

function AppClients(){
    const { getAvailableAppClientsAction, toast } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const { availableAppClients } = useAppCtxAvailableClientsCompaniesFolders();
    const { createSocketConnection } = useSocket();
    const socket = createSocketConnection();
    const { setActiveClient } = useClientCtxAPI();
    const { setModules } = useClient();
    const activeClient = useClientCtxActiveClient();
    const [selectedClient, setSelectedClient] = useState(null);
    const [foundClient, setFoundClient] = useState(null);
    const [newClientWindowOpen, setNewClientWindowOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(false);
    }, []);

    useEffect(() => {
        if(activeUser?.type >= 9){
            getAvailableAppClientsAction();
        }
    }, [activeUser]);

    useEffect(() => {
        let isMounted = true;
        const cancelTokenSource = axios.CancelToken.source();
        if(selectedClient){
            setLoading(true);
            axios.get(SERVER_PATH + `/data/clients/one?selectedClientId=${selectedClient._id}`, {
                cancelToken: cancelTokenSource.token
            })
            .then(res => {
                if(isMounted){
                    let currentFoundClient = res.data;
                    setFoundClient(currentFoundClient);
                    setLoading(false);
                }
            })
            .catch(err => {
                if(isMounted){
                    setLoading(false);
                    toast(err.response.data);
                }
            });
        } else {
            setFoundClient(null);
        }

        return () => {
            cancelTokenSource.cancel();
            isMounted = false;
        };
    }, [selectedClient]);

    const addClient = () => {
        setSelectedClient(null);
        setFoundClient(null);
        setNewClientWindowOpen(true);
    };

    const changeClient = async (modules) => {
        const updates = {
            modules
        };
        const foundClientCopy = {...foundClient};
        setFoundClient(prevState => ({...prevState, ...updates}));

        setLoading(true);
        const postData = {
            updatedClientId: foundClient._id,
            updates
        };
        await axios.post(SERVER_PATH + '/data/clients/one/update', postData)
        .then(res => {
            const updatedClient = res.data;
            if(activeClient._id === foundClient._id){
                setModules(modules);
                socket.emit('CLIENT MODULES UPDATED', { updatedClient }); 
            }
        })
        .catch(err => {
            setFoundClient(foundClientCopy);
            if(activeClient._id === foundClient._id) setActiveClient(foundClientCopy);
        });
        setLoading(false);
    };

    const addModule = (modules = [], addedModule) => {
        modules.push(addedModule);
        changeClient(modules);
    };

    const removeModule = (modules = [], removedModule) => {
        const moduleIndex = modules.findIndex(module => module === removedModule);
        modules = [...modules.slice(0, moduleIndex), ...modules.slice(moduleIndex + 1)];
        changeClient(modules);
    };

    const ModuleButton = ({id, name, modules}) => (
        <Grid item>
            {
                modules.includes(id)
                ?
                <Button variant="outlined" color="primary" onClick={() => removeModule(modules, id)}>{name}</Button>
                :
                <Button onClick={() => addModule(modules, id)}>{name}</Button>
            }
        </Grid>
    );

    const ClientItem = ({client}) => (
        <DataListItem>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12}>
                    <Typography variant="h2">{client.name}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <SectionTitle title={`${client.screenName} - ${client.shortName}`} />
                </Grid>
                <Grid item xs={12}>
                    <SectionTitle title="Módulos" />
                    <Grid container spacing={1} alignItems="center">
                        {
                            CLIENT_MODULES.map(module => (
                                <ModuleButton key={module.id} id={module.id} name={module.name} modules={client.modules} />
                            ))
                        }
                    </Grid>
                </Grid>
            </Grid>
        </DataListItem>
    );

    return (
        <ViewBox>
            <Box mb={2}>
                <Grid container alignItems="flex-end" spacing={1}>
                    <Grid item xs={12} sm={2} container spacing={1} alignItems="center">
                        {
                            loading &&
                            <Grid item>
                                <CircularProgress size={20} />
                            </Grid>
                        }
                        <Grid item xs>
                            <Autocomplete
                                disabled={loading}
                                placeholder="Escolher"
                                options={availableAppClients?.sort(sortByKey('name'))}
                                value={selectedClient}
                                onChange={(e, newValue) => setSelectedClient(newValue)}
                                getOptionLabel={(option) => `${option.name}`}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs></Grid>
                    <Grid item xs={12} sm={4} container justifyContent="center">
                        <Button disabled={loading} variant="contained" color="primary" startIcon={<AddIcon />} onClick={addClient}>Novo cliente</Button>
                    </Grid>
                </Grid>
            </Box>
            {
                foundClient &&
                <ClientItem client={foundClient} />
            }
            <NewClientWindow open={newClientWindowOpen} setOpen={setNewClientWindowOpen} />
        </ViewBox>
    );
}

export default AppClients;