import axios from 'axios';
import AlertDialog from './AlertDialog';
import { ERROR_MESSAGE_CHANGES_UNDONE, SERVER_PATH } from '../utils/constants';
import { useAppCtxAPI, useAppCtxActiveUser, useAppCtxLoading } from '../context/SystemContext';
import { useSocket } from '../context/SocketContext';
import { useDocumentsAPI, useDocumentsCtxDeleteDocumentView } from '../context/DocumentsContext';

const DeleteDocDialog = ({updateDocument}) => {
    const { floatingAlert, setLoading } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const loading = useAppCtxLoading();
    const {createSocketConnection} = useSocket();
    const socket = createSocketConnection();
    const { hideDeleteDocumentView } = useDocumentsAPI();
    const { deleteDocumentViewOpen, deleteDocumentViewSelectedDocument: selectedDocument } = useDocumentsCtxDeleteDocumentView();

    const handle_okClick = async () => {
        setLoading(true);
        handleClose();
        updateDocument({...selectedDocument, hidden: true});
        const postData = {
            documentId: selectedDocument._id
        };
        await axios.post(SERVER_PATH + '/data/documents/one/delete', postData)
        .then(res => {
            updateDocument(res.data.updatedDocument); //? DELETE
            socket.emit('DOCUMENT DELETED', {activeUserId: activeUser._id, actionUserId: activeUser._id, actionUserName: activeUser.fullName, deletedDocument: res.data.updatedDocument});
        })
        .catch(err => {
            updateDocument({...selectedDocument, hidden: false});
            floatingAlert(ERROR_MESSAGE_CHANGES_UNDONE, 'error', 0);
        });
        setLoading(false);
    };

    const handleClose = () => {
        hideDeleteDocumentView();
    };
    
    return (
        <AlertDialog
            id="delete-doc-dialog"
            open={deleteDocumentViewOpen} onClose={handleClose}
            text={`Você quer cancelar o documento "${selectedDocument?.name}"?`}
            okButtonOnClick={handle_okClick}
            disabled={loading}
        />
    );
}

export default DeleteDocDialog;