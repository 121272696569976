import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Box, Collapse, Container, Fab, Fade, Grid, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DoneIcon from '@mui/icons-material/Done';
import GetAppIcon from '@mui/icons-material/GetApp';
import HelpIcon from '@mui/icons-material/Help';
import ReplyIcon from '@mui/icons-material/Reply';
import Alert from '@mui/material/Alert';
import AlertDialog from '../../components/AlertDialog';
import CenteredCircularProgress from '../../components/CenteredCircularProgress';
import { useAppCtxAPI } from '../../context/SystemContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload, faFileSignature } from '@fortawesome/free-solid-svg-icons';
import { catchRequestError } from '../../utils/common';
import { SERVER_PATH } from '../../utils/constants';

const headerHeight = 66;
const elevatedBoxHeight = headerHeight - 12;
const classes = {
    root: {
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        height: `${headerHeight}px`,
        backgroundColor: theme => theme.palette.background.paper
    },
    elevatedBox: {
        height: `${elevatedBoxHeight}px`,
        backgroundColor: '#f5f6fa',
        boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
        overflow: 'hidden',
    },
    elevatedBoxHeight: {
        height: `${elevatedBoxHeight}px`,
    },
    content: {
        height: `calc(100% - ${headerHeight}px)`,
        overflow: 'hidden',
        flex: 1,
        position: 'relative'
    },
    iframe: {
        width: '100%',
        height: '100%',
        border: 'none'
    },
    alert: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        msTransform: 'translate(-50%, -50%)',
        WebkitTransform: 'translate(-50%, -50%)',
        textAlign: 'center'
    },
    whiteFab: {
        backgroundColor: theme => theme.palette.background.paper
    }
};

const Div = styled('div')(unstable_styleFunctionSx);

const Iframe = styled('iframe')(unstable_styleFunctionSx);

const DocumentReview = () => {
    const params = useParams();
    const documentToken = params.documentId;
    const { setLoadingSystem } = useAppCtxAPI();
    const [loading, setLoading] = useState(true);
    const [selectedDocumentGoogleUrl, setSelectedDocumentGoogleUrl] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('success');
    const [alertMessage, setAlertMessage] = useState('');
    const [headerMessage, setHeaderMessage] = useState(false);
    const [finishedReviewDialogOpen, setFinishedReviewDialogOpen] = useState(false);
    const [documentCompanyName, setDocumentCompanyName] = useState('');
    const [selectedPath, setSelectedPath] = useState('');
    const [signDocumentDialogOpen, setSignDocumentDialogOpen] = useState(false);
    const [reviewDocumentDialogOpen, setReviewDocumentDialogOpen] = useState(false);
    const [approvedDocumentDialogOpen, setApprovedDocumentDialogOpen] = useState(false);
    const [selectedDocumentReviewStatus, setSelectedDocumentReviewStatus] = useState('');

    const reviewStatusActions = {
        'sent': (foundDocument) => {
            setSelectedDocumentGoogleUrl(foundDocument.url);
            setDocumentCompanyName(foundDocument.companyName);
        },
        'sentClear': (foundDocument) => {
            reviewStatusActions.sent(foundDocument);
        },
        'eSignature': (foundDocument) => {
            setAlertSeverity('info');
            setAlertMessage(`O documento "${foundDocument.name}" seguiu para assinatura eletrônica. Você está sendo redirecionado...`);
            setTimeout(() => {
                window.location.href = foundDocument.signUrl;
            }, 3000);
        },
        'review': (foundDocument) => {
            setSelectedPath('review');
            setSelectedDocumentGoogleUrl(foundDocument.url);
            setDocumentCompanyName(foundDocument.companyName);
        },
        'default': (foundDocument) => {
            setAlertSeverity('success');
            setAlertMessage(`Obrigado! O documento "${foundDocument.name}" foi revisado.`);
        },
    };

    useEffect(() => {
        setLoadingSystem(false);
    }, []);

    useEffect(() => {
        if(documentToken){
            axios.get(SERVER_PATH + `/data/documents/review/${documentToken}`)
            .then(res => {
                const foundDocument = res.data.document;
                let action = foundDocument.reviewStatus;
                if(!reviewStatusActions[action]) action = 'default';
                reviewStatusActions[action](foundDocument);
                setSelectedDocumentReviewStatus(foundDocument.reviewStatus);
                setLoading(false);
            })
            .catch(err => {
                console.log(err)
                err = catchRequestError(err);
                setAlertSeverity('error');
                if(err.status === 404){
                    setAlertMessage(`Documento não encontrado.`);
                } else {
                    setAlertMessage(err.message);
                }
                setLoading(false);
            });
        }
    }, []);

    const handleFinishedReviewButtonClick = () => {
        setFinishedReviewDialogOpen(true);
        setHeaderMessage('');
    };
    const handleApprovedDocumentButtonClick = () => {
        if(selectedDocumentReviewStatus === 'sentClear'){
            handleSignDocumentConfirm();
        } else {
            setApprovedDocumentDialogOpen(true);
            setHeaderMessage('');
        }
    };
    const handleFinishedReviewConfirm = (approved) => {
        setFinishedReviewDialogOpen(false);
        setApprovedDocumentDialogOpen(false);
        setLoading(true);
        setHeaderMessage('');
        setSelectedDocumentGoogleUrl('');
        axios.get(SERVER_PATH + `/data/documents/review/finished/${documentToken}?status=${approved ? 'approved' : 'returned'}`)
        .then(res => {
            setAlertSeverity('success');
            setAlertMessage(`Obrigado! O documento "${res.data.document.name}" foi revisado.`);
            setLoading(false);
        })
        .catch(err => {
            setHeaderMessage('Ocorreu um erro. Tente novamente.');
        });
    };
    const handleSignDocumentButtonClick = () => {
        setSignDocumentDialogOpen(true);
        setHeaderMessage('');
    };
    const handleSignDocumentConfirm = () => {
        setSignDocumentDialogOpen(false);
        setLoading(true);
        setHeaderMessage('');
        const currentSelectedDocumentGoogleUrl = selectedDocumentGoogleUrl;
        setSelectedDocumentGoogleUrl('');
        axios.get(SERVER_PATH + `/data/documents/review/sign/${documentToken}`)
        .then(res => {
            if(res.data.sign_url){
                return window.location.href = res.data.sign_url;
            }

            setLoading(false);
        })
        .catch(err => {
            setHeaderMessage('Ocorreu um erro. Tente novamente.');
            setSelectedDocumentGoogleUrl(currentSelectedDocumentGoogleUrl);
            setLoading(false);
        });
    };
    const handleReviewDocumentButtonClick = () => {
        setReviewDocumentDialogOpen(true);
        setHeaderMessage('');
    };
    const handleReviewDocumentConfirm = () => {
        setReviewDocumentDialogOpen(false);
        setLoading(true);
        setHeaderMessage('');
        axios.get(SERVER_PATH + `/data/documents/review/prepare/${documentToken}`)
        .then(res => {
            setSelectedPath('review');
            setLoading(false);
        })
        .catch(err => {
            setHeaderMessage('Ocorreu um erro. Tente novamente.');
        });
        
    };

    const StyledFab = ({icon, onClick, tooltip, white}) => (
        <Fab
            disabled={!selectedDocumentGoogleUrl}
            size="small"
            color={white ? 'default' : 'primary'}
            sx={white && classes.whiteFab}
            onMouseEnter={() => setHeaderMessage(tooltip || '')}
            onMouseLeave={() => setHeaderMessage('')}
            onClick={onClick}
        >{icon}</Fab>
    );

    return (
        <Div sx={classes.root}>
            <Box sx={classes.header}>
                <Box sx={classes.elevatedBox}>
                    <Container maxWidth="lg" sx={classes.elevatedBoxHeight}>
                        <Grid container spacing={2} alignItems="center" sx={classes.elevatedBoxHeight}>
                            <Grid item xs={1}>
                                <img src={'/SOLIDA-50.png'} alt="Solida" /> 
                            </Grid>
                            {
                                !selectedPath &&
                                <Grid item xs={2} container spacing={1} alignItems="center" justifyContent="center">
                                    <Grid item>
                                        <StyledFab icon={<FontAwesomeIcon icon={faFileSignature} />} tooltip="Assinar o documento" white
                                            onClick={handleSignDocumentButtonClick}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <StyledFab icon={<FontAwesomeIcon icon={faFileUpload} />} tooltip="Tenho considerações" white
                                            onClick={handleReviewDocumentButtonClick}
                                        />
                                    </Grid>
                                </Grid>
                            }
                            {
                                selectedPath === 'review' &&
                                <Grid item xs={2} container spacing={1} alignItems="center" justifyContent="center">
                                    <Grid item>
                                        <StyledFab icon={<ReplyIcon />} tooltip="Concluí a revisão e quero devolver o documento" white
                                            onClick={handleFinishedReviewButtonClick}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <StyledFab icon={<AttachFileIcon />} tooltip="Prefiro enviar um arquivo" white />
                                    </Grid>
                                </Grid>
                            }
                            {
                                selectedPath === 'review' &&
                                <Grid item xs={1}>
                                    <StyledFab icon={<DoneIcon />} tooltip="Estou de acordo com o documento"
                                        onClick={handleApprovedDocumentButtonClick}
                                    />
                                </Grid>
                            }
                            <Grid item xs={1}>
                                <StyledFab icon={<GetAppIcon />} tooltip="Download" white
                                    // onClick={handleFinishedReviewButtonClick}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton
                                    disabled={!selectedDocumentGoogleUrl}
                                    size="small"
                                    onMouseEnter={() => setHeaderMessage('Preciso de ajuda')}
                                    onMouseLeave={() => setHeaderMessage('')}
                                ><HelpIcon /></IconButton>
                            </Grid>
                            <Grid item xs>
                                <Fade in={headerMessage} timeout={500}>
                                    <Typography variant="body1" color="initial">{headerMessage}</Typography>
                                </Fade>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
            <Box sx={classes.content}>
                {
                    (!loading && selectedDocumentGoogleUrl) &&
                    <Iframe title="Document for review" sx={classes.iframe} src={selectedDocumentGoogleUrl} />
                }
                <Box sx={classes.alert}>
                    {
                        loading &&
                        <CenteredCircularProgress />
                    }
                    <Collapse in={alertMessage}>
                        <Alert variant="filled" severity={alertSeverity}><Typography variant="body1">{alertMessage}</Typography></Alert>
                    </Collapse>
                </Box>

                <AlertDialog
                    id="document-approved-dialog"
                    open={approvedDocumentDialogOpen} onClose={() => setApprovedDocumentDialogOpen(false)}
                    text={selectedDocumentReviewStatus === 'sentClear' ? `Você será redirecionado à plataforma de assinatura eletrônica.` : `Você está de acordo com as últimas alterações no documento? Isso o devolverá ${documentCompanyName ? `para ${documentCompanyName}` : 'ao remetente'}, para que o documento seja enviado para assinatura.`}
                    okButtonOnClick={() => handleFinishedReviewConfirm(true)}
                    cancelButtonText="Cancelar"
                />
                <AlertDialog
                    id="document-review-finished-dialog"
                    open={finishedReviewDialogOpen} onClose={() => setFinishedReviewDialogOpen(false)}
                    text={`Isso devolverá o documento revisado ${documentCompanyName ? `para ${documentCompanyName}` : 'ao remetente'}.`}
                    okButtonOnClick={() => handleFinishedReviewConfirm()}
                    cancelButtonText="Cancelar"
                />
                <AlertDialog
                    id="sign-document-dialog"
                    open={signDocumentDialogOpen} onClose={() => setSignDocumentDialogOpen(false)}
                    text={`Você será redirecionado à plataforma de assinatura eletrônica.`}
                    okButtonOnClick={handleSignDocumentConfirm}
                    okButtonText="Seguir"
                    cancelButtonText="Cancelar"
                />
                <AlertDialog
                    id="review-document-dialog"
                    open={reviewDocumentDialogOpen} onClose={() => setReviewDocumentDialogOpen(false)}
                    text={`Use o botão "Seguir" e faça seus comentários e sugestões diretamente no Google Doc.`}
                    okButtonOnClick={handleReviewDocumentConfirm}
                    okButtonText="Seguir"
                    cancelButtonText="Cancelar"
                />
            </Box>
        </Div>
    );
};

export default DocumentReview;