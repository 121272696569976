import { Routes } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useWindowSize from '../hooks/useWindowSize';
import Notifications from './Rooms/Notifications';
import { SIDENAV_WIDTH } from '../utils/constants';

const headerHeight = 50;

const classes = {
    root: {
        backgroundColor: theme => theme.palette.background.default,
        height: '100%',
        overflow: 'hidden'
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'relative',
        left: [0, SIDENAV_WIDTH],
        width: [`100%`, `calc(100% - ${SIDENAV_WIDTH}px)`]
    },
    content: {
        height: `calc(100% - ${headerHeight}px)`,
        flex: 1,
        overflowX: 'hidden',
    }
};

function Main({children, header, sidenav, mobileSidenav}){
    const { windowWidth } = useWindowSize();

    return (
        <Grid container sx={classes.root}>
            {sidenav}
            <Grid item sx={classes.main}>
                {header}
                <Box id="main" sx={classes.content}>
                    <Routes>
                        {children}
                    </Routes>
                </Box>
            </Grid>
            
            {(windowWidth && windowWidth < 960) ? mobileSidenav : null}

            <Notifications /> 

        </Grid>
    );
}

export default Main;