const actionWindowStyles = {
    width: ['100%', '75%', '50%']
};
const linkStyles = {
    cursor: 'pointer',
    color: theme => theme.palette.primary.main,
    '&:hover': {
        textDecoration: 'underline'
    }
};

export { actionWindowStyles, linkStyles }; 