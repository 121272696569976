import { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AlertDialog from '../components/AlertDialog';
import { useAppCtxAPI, useAppCtxActiveUser } from '../context/SystemContext';

const DeleteRecordMenuItem = ({collection, deleteDocument, document, confirmActionFunction}) => {
    const { deleteForever, setLoading, toast } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const [alertDialogOpen, setAlertDialogOpen] = useState(false);

    const handleClick = async () => {
        setAlertDialogOpen(true);
    };

    const handleAlertDialogConfirm = async () => {
        setAlertDialogOpen(false);
        if(confirmActionFunction) confirmActionFunction();
        setLoading(true);
        try {
            await deleteForever(collection, document._id);
            deleteDocument(document._id);
        } catch (error) {
            toast('Erro!');
        }
        setLoading(false);
    };

    return (
        <>
            {activeUser.type >= 6
            ?
            <MenuItem onClick={handleClick}
                sx={{
                    '&:hover': {
                        backgroundColor: theme => theme.palette.error.main,
                        color: theme => theme.palette.error.contrastText
                    }
                }}
            >
                <ListItemIcon>
                    <DeleteForeverIcon />
                </ListItemIcon>
                <ListItemText primary="Excluir" />
            </MenuItem>
            :
            null}
            <AlertDialog
                open={alertDialogOpen} onClose={() => setAlertDialogOpen(false)}
                title="Tem certeza?"
                text={`Essa ação é irreversível.`}
                okButtonOnClick={handleAlertDialogConfirm}
            />
        </>
    );
};

export default DeleteRecordMenuItem;