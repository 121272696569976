import { Checkbox, FormControlLabel, Typography } from "@mui/material";

function StyledCheckbox({checked, disabled, edge, onChange, name, color, id, label, labelStyle}){
    
    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={checked}
                    disabled={disabled}
                    edge={edge}
                    onChange={onChange}
                    name={name || 'checkbox'}
                    color={color || 'primary'}
                    id={id}
                />
            }
            label={<Typography color="textSecondary" style={labelStyle}>{label}</Typography>}
        />
    );
}

export default StyledCheckbox;