import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Autocomplete from '../../../../../components/Autocomplete';
import PopOver from '../../../../../components/PopOver';
import Select from '../../../../../components/Select';
import { sortByKey } from '../../../../../utils/filters';

const FiltersPopOver = ({data}) => {
    const {
        filtersPopOverOpen, set_filtersPopOverOpen, filtersPopOverAnchorEl,
        filters, setFilters,
        handleClientChange,
        handleProjectChange,
        loadingPage,
        projectOptions,
        projects,
    } = data;

    const clearFilters = () => {
        handleClose();
        setFilters(prevState => ({
            ...prevState,
            status: 'sent',
            orderBy: 'sentOn',
            signatureStatus: 'none'
        }));
        handleClientChange(null);
    };
    const handleClose = () => {
        set_filtersPopOverOpen(false);
    };
    
    const handleFiltersCompanyChange = (newValue) => {
        handleClientChange(newValue);
    };
    const handleFiltersFolderChange = (newValue) => {
        handleClose();
        handleProjectChange(newValue);
    };
    const handleFiltersStatusChange = (newValue) => {
        handleClose();
        setFilters(prevState => ({
            ...prevState,
            status: newValue
        }));
    };
    const handleFiltersOrderChange = (newValue) => {
        handleClose();
        setFilters(prevState => ({
            ...prevState,
            orderBy: newValue
        }));
    };
    const handleFiltersSignatureStatusChange = (newValue) => {
        handleClose();
        setFilters(prevState => ({
            ...prevState,
            signatureStatus: newValue
        }));
    };

    return (
        <PopOver open={filtersPopOverOpen} onClose={handleClose} anchorEl={filtersPopOverAnchorEl}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
        >
            <Box mb={2}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs>
                        <Typography variant="h5">Filtros</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={clearFilters}
                        >Limpar</Button>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Grid container spacing={1}>
                    {
                        projects &&
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                disabled={loadingPage}
                                label="Empresa"
                                options={[{_id: 'all', name: '(Todas)'}, ...projects.sort(sortByKey('name')).filter(project => !project.client)]}
                                getOptionLabel={(option) => option.name}
                                value={filters.company} onChange={(e, newValue) => handleFiltersCompanyChange(newValue)}
                            />
                        </Grid>
                    }
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            disabled={loadingPage}
                            label="Pasta" options={projectOptions} getOptionLabel={(option) => option.name}
                            value={filters.folder} onChange={(e, newValue) => handleFiltersFolderChange(newValue)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Select
                            disabled={loadingPage}
                            label="Situação"
                            options={[
                                {value: 'sent', label: 'Enviados'},
                                {value: 'scheduledSent', label: 'Enviados automaticamente'},
                                {value: 'deleted', label: 'Cancelados'},
                                {value: 'all', label: 'Todos'}
                            ]}
                            value={filters.status} onChange={(e) => handleFiltersStatusChange(e.target.value)}
                        />
                    </Grid>
                    {
                        filters.status === 'sent' &&
                        <Grid item xs={12} sm={6}>
                            <Select
                                disabled={loadingPage}
                                label="Ordem"
                                options={[
                                    {value: 'sentOn', label: 'Enviados recentemente'},
                                    {value: 'timestamp', label: 'Solicitados recentemente'}
                                ]}
                                value={filters.orderBy} onChange={(e) => handleFiltersOrderChange(e.target.value)}
                            />
                        </Grid>
                    }
                    <Grid item xs={12} sm={6}>
                        <Select
                            disabled={loadingPage}
                            label="Assinatura eletrônica"
                            options={[
                                {value: 'none', label: 'Nenhum'},
                                {value: 'eSignaturePending', label: 'Assinatura eletrônica não concluída'},

                                {value: 'eSignatureSigned', label: 'Documentos assinados eletronicamente'}
                            ]}
                            value={filters.signatureStatus || 'select'} onChange={(e) => handleFiltersSignatureStatusChange(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </Box>
        </PopOver>
    );
};

export default FiltersPopOver;