import { Route } from 'react-router-dom';
import Account from './Account';
import ActiveUserSettings from './ActiveUserSettings';
import Admin from './Admin';
import Clients from './Clients';
import DesktopSidenav from '../../components/DesktopSidenav';
import Header from './Header';
import Main from '../../components/Main';
import MobileSidenav from '../../components/MobileSidenav';
import PageNotFound from '../PageNotFound';
import Users from './Users';
import { useManagementCtxAPI, useManagementCtxSidenavMobileOpen } from '../../context/ManagementContext';
import useMenuItems from '../../components/ManagementProvider/hooks/useMenuItems';

function Management(){
    const { setSidenavMobileOpen } = useManagementCtxAPI();
    const sidenavMobileOpen = useManagementCtxSidenavMobileOpen();
    const menuItems = useMenuItems();

    const handleCloseMobileSidenav = () => {
        setSidenavMobileOpen(false);
    };

    return (
        <Main
            header={<Header />}
            sidenav={<DesktopSidenav menuItems={menuItems} account={<Account />} />}
            mobileSidenav={<MobileSidenav account={<Account size={7} />} handleCloseMobileSidenav={handleCloseMobileSidenav} menuItems={menuItems} sidenavMobileOpen={sidenavMobileOpen} set_sidenavMobileOpen={setSidenavMobileOpen} />}
        >
            <Route path="/clientes" element={<Clients />} />
            <Route path="/pessoas" element={<Users />} />
            <Route path="/app" element={<Admin />} />
            <Route path="/preferencias" element={<ActiveUserSettings />} />
            <Route path="*" element={<PageNotFound />} />
        </Main>
    );
}

export default Management;