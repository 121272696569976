import { useEffect, useState } from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import ListItemText from '@mui/material/ListItemText';
import ClearIcon from '@mui/icons-material/Clear';
import DescriptionIcon from '@mui/icons-material/Description';
import EventIcon from '@mui/icons-material/Event';
import FlagIcon from '@mui/icons-material/Flag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileWord, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { useAppCtxAPI, useAppCtxActiveUser, useAppCtxUserGroups } from '../../../../context/SystemContext';
import { useSocket } from '../../../../context/SocketContext';
import { useDocumentsAPI, useDocumentsCtxActionMenu } from '../../../../context/DocumentsContext';
import { flagDocument, getDocumentGoogleDocExportLink, getDocumentLastVersion } from '../../../../utils/common';

const ActionMenu = ({updateDocument}) => {
    const { setLoading, toast } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const { operatorStatus, userPosition } = useAppCtxUserGroups();
    const {createSocketConnection} = useSocket();
    const socket = createSocketConnection();
    const { setActionMenuOpen, showChangeDocumentNameView, showDeleteDocumentView, showAddDocumentAlertView } = useDocumentsAPI();
    const { actionMenuAnchorEl, actionMenuOpen, actionMenuSelectedDocument: selectedDocument } = useDocumentsCtxActionMenu();
    const [currentVersion, set_currentVersion] = useState({});
    const [allowDeleteDocument, setAllowDeleteDocument] = useState(false);

    useEffect(() => {
        if(selectedDocument){
            let version = getDocumentLastVersion(selectedDocument);
            set_currentVersion(version);
        }
    }, [selectedDocument]);

    useEffect(() => {
        let currentAllowDeleteDocument = false;
        if(selectedDocument){
            currentAllowDeleteDocument = operatorStatus || userPosition;
            if(currentAllowDeleteDocument) currentAllowDeleteDocument = !selectedDocument.documents.some(version => version.eSignatureStatus === 'signed');
        }
        setAllowDeleteDocument(currentAllowDeleteDocument);
    }, [selectedDocument]);

    const handle_editNameButtonClick = () => {
        showChangeDocumentNameView(selectedDocument);
    };
    const handle_deleteButtonClick = () => {
        showDeleteDocumentView(selectedDocument);
    };
    const handle_flagButtonClick = async (flag) => {
        flagDocument(flag, '', activeUser, selectedDocument, updateDocument, socket, setLoading, toast);
    };

    const handle_createNotificationClick = () => {
        showAddDocumentAlertView(selectedDocument);
    };
    const handle_downloadDocClick = () => {
        toast('Carregando... aguarde alguns segundos.');
    };

    const handleClose = () => {
        setActionMenuOpen(false);
    }
    
    return (
        <Menu
            anchorEl={actionMenuAnchorEl}
            open={actionMenuOpen}
            onClose={handleClose}
            onClick={handleClose}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <MenuItem onClick={handle_createNotificationClick}>
                <ListItemIcon>
                    <EventIcon />
                </ListItemIcon>
                <ListItemText primary="Criar alerta" />
            </MenuItem>
            {
                /*
                operatorStatus &&
                <MenuItem onClick={() => handle_editNameButtonClick()}>
                    <ListItemIcon>
                        <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText primary="Renomear" />
                </MenuItem>
                */
            }
            {
                /*
                (operatorStatus && selectedDocument?.flag !== 1) &&
                <MenuItem onClick={() => handle_flagButtonClick(1)}>
                    <ListItemIcon>
                        <FlagIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sinalizar" />
                </MenuItem>
                */
            }
            {
                /*
                (operatorStatus && selectedDocument?.flag && (selectedDocument.flag === 1 || selectedDocument.flag === 2)) &&
                <MenuItem onClick={() => handle_flagButtonClick(0)}>
                    <ListItemIcon>
                        <FlagIcon />
                    </ListItemIcon>
                    <ListItemText primary="Desmarcar" />
                </MenuItem>
                */
            }
            {
                (selectedDocument?.sent && !selectedDocument?.deleted && currentVersion.googleDoc && currentVersion.type !== 'download') &&
                <MenuItem onClick={handle_downloadDocClick} component={Link} href={getDocumentGoogleDocExportLink(currentVersion.googleDoc, 'pdf')}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faFilePdf} size="lg" />
                    </ListItemIcon>
                    <ListItemText primary="Baixar pdf" />
                </MenuItem>
            }
            {
                (selectedDocument?.sent && !selectedDocument?.deleted && currentVersion.googleDoc && currentVersion.type !== 'download') &&
                <MenuItem onClick={handle_downloadDocClick} component={Link} href={getDocumentGoogleDocExportLink(currentVersion.googleDoc, 'docx')}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faFileWord} size="lg" />
                    </ListItemIcon>
                    <ListItemText primary="Baixar docx" />
                </MenuItem>
            }
            {
                allowDeleteDocument &&
                <MenuItem onClick={() => handle_deleteButtonClick()}>
                    <ListItemIcon>
                        <ClearIcon />
                    </ListItemIcon>
                    <ListItemText primary="Cancelar" />
                </MenuItem>
            }
        </Menu>
    );
}

export default ActionMenu;