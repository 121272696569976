import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Box, Button, Grid } from '@mui/material';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import FoldersList from '../../../components/FoldersList';
import LoaderEllipsis from '../../../components/LoaderEllipsis';
import PromptDialog from '../../../components/PromptDialog';
import ViewBox from '../../../components/ViewBox';
import { useAppCtxAPI, useAppCtxActiveUser, useAppCtxSelectedFolder } from '../../../context/SystemContext';
import { useFolderUserCtxAPI, useFolderUserCtxCompanyFolders } from '../context/ClientContext';
import { ERROR_MESSAGE_UNKNOWN, SERVER_PATH, SUCCESS_MESSAGE_SAVED } from '../../../utils/constants';

const Folders = () => {
    const { setLoading } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const selectedFolder = useAppCtxSelectedFolder();
    const { addCompanyFolder, setCompanyFolders } = useFolderUserCtxAPI()
    const clientProjects = useFolderUserCtxCompanyFolders();
    const [isLoading, setLoadingPage] = useState(true);
    const [isNewFolderWindowOpen, setNewFolderWindowOpen] = useState(false);
    const newFolderNameRef = useRef('');
    const [floatingNotification, setFloatingNotification] = useState({});

    useEffect(() => {
        if(activeUser && selectedFolder){
            setLoadingPage(false);

            setLoading(false);
        }
    }, [activeUser, selectedFolder]);

    const handleNewFolderButtonClick = () => {
        setNewFolderWindowOpen(true);
    };

    const handleNewFolderConfirmButtonClick = async () => {
        setLoading(true);
        
        setNewFolderWindowOpen(false);

        const newFolder = {
            name: newFolderNameRef.current,
            client: selectedFolder._id,
        };

        let formData = new FormData();
        formData.append('name', newFolderNameRef.current);
        formData.append('client', selectedFolder._id);

        const clientFoldersCopy = [...clientProjects];
        addCompanyFolder(newFolder);

        await axios.post(SERVER_PATH + '/data/operator/folders/projects/save', formData, {headers: {'Content-Type': 'multipart/form-data'}})
        .then(res => {
            setFloatingNotification({message: SUCCESS_MESSAGE_SAVED});
        })
        .catch(err => {
            setFloatingNotification({message: err?.response?.data || ERROR_MESSAGE_UNKNOWN, severity: 'error'});
            setCompanyFolders(clientFoldersCopy);
        });

        setLoading(false);
    };

    return (
        <ViewBox message={floatingNotification}>
            <Box mb={3}>
                <Grid container spacing={1} justifyContent="flex-end">
                    <Grid item>
                        <Button
                            disabled={isLoading}
                            variant="contained" color="primary"
                            onClick={handleNewFolderButtonClick}
                            startIcon={<CreateNewFolderIcon />}
                        >Nova pasta</Button>
                    </Grid>
                </Grid>
            </Box>
            {
                isLoading
                ? <LoaderEllipsis primary />
                : <FoldersList foldersList={clientProjects} />
            }
            <PromptDialog
                open={isNewFolderWindowOpen} setOpen={setNewFolderWindowOpen}
                title="Nova pasta" text="Insira um nome a seguir para criar uma nova pasta" label="Nome"
                inputValueRef={newFolderNameRef}
                onClickConfirmButton={handleNewFolderConfirmButtonClick}
            />
        </ViewBox>
    );
};

export default Folders;