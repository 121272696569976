import { Box, Button, Grid, Popover, Typography } from '@mui/material';

const popOverWidth = 280;
const classes = {
    popOverFirstBox: {
        maxHeight: '280px',
        overflow: 'auto',
    },
    popOverFirstBoxWidth: {
        width: [`${popOverWidth}px`, `${popOverWidth * 1.25}px`, `${popOverWidth * 1.5}px`],
    },
    ellipsisTitle: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
};

const PopOver = ({
    children, anchorEl, anchorOrigin, anchorPosition, id, open, onClose, transformOrigin,
    className, style, firstBoxSx, firstBoxStyle,
    noPadding, noWidth,
    height, width, zIndex,
    ellipsisTitle, startIcon, title,
    handleSubmitButtonClick, submitButtonDisabled, submitButtonStartIcon, submitButtonText,
    hideCloseButton, closeButtonText
}) => {
    let popOverStyle = {};
    if(zIndex) popOverStyle.zIndex = zIndex;
    let popOverFirstBoxStyle = {};
    if(height) popOverFirstBoxStyle.height = height;
    if(width) popOverFirstBoxStyle.width = width;
    return (
        <Popover
            id={id}
            open={open}
            anchorReference={anchorPosition ? 'anchorPosition' : undefined}
            anchorPosition={anchorPosition}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={
                anchorOrigin || {
                    vertical: 'bottom',
                    horizontal: 'center',
                }
            }
            transformOrigin={
                transformOrigin || {
                    vertical: 'top',
                    horizontal: 'center',
                }
            }
            className={className}
            style={style || popOverStyle}
        >
            <Box sx={[classes.popOverFirstBox, !noWidth && classes.popOverFirstBoxWidth, firstBoxSx && firstBoxSx]} style={firstBoxStyle || popOverFirstBoxStyle}>
                <Box py={noPadding ? 0 : 2} px={noPadding ? 0 : 1} style={{height: '100%'}}>
                    {
                        ellipsisTitle &&
                        <Box mb={2}>
                            <Typography variant="h6" sx={classes.ellipsisTitle}>{ellipsisTitle}</Typography>
                        </Box>
                    }
                    {
                        (title || startIcon) &&
                        <Box mb={2}>
                            <Grid container spacing={1} justifyContent="center">
                                {
                                    startIcon &&
                                    <Grid item>
                                        {startIcon}
                                    </Grid>
                                }
                                {
                                    title &&
                                    <Grid item xs>
                                        <Typography variant="h5">{title}</Typography>
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                    }
                    {children}
                    <Grid container spacing={1} justifyContent="center">
                        {
                            handleSubmitButtonClick &&
                            <Grid item>
                                <Button
                                    variant="outlined" color="primary"
                                    disabled={submitButtonDisabled}
                                    startIcon={submitButtonStartIcon}
                                    onClick={handleSubmitButtonClick}
                                >{submitButtonText || 'Salvar'}</Button>
                            </Grid>
                        }
                        {
                            !hideCloseButton &&
                            <Grid item>
                                <Button onClick={onClose}>{closeButtonText || (handleSubmitButtonClick ? 'Cancelar' : 'Fechar')}</Button>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </Box>
      </Popover>
    );
};

export default PopOver;