import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FlagIcon from '@mui/icons-material/Flag';
import Input from '../Input';
import Window from '../Window';
import { useAppCtxAPI, useAppCtxActiveUser } from '../../context/SystemContext';
import { useSocket } from '../../context/SocketContext';
import { useDocumentsAPI, useDocumentsCtxFlagDocumentView } from '../../context/DocumentsContext';
import { flagDocument } from '../../utils/common';

const FlagDocumentWindow = ({activeUserIsOperator, updateDocument}) => {
    const { setLoading, toast } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const {createSocketConnection} = useSocket();
    const socket = createSocketConnection();
    const { hideFlagDocumentView } = useDocumentsAPI();
    const { flagDocumentViewOpen, flagDocumentViewSelectedDocument: selectedDocument } = useDocumentsCtxFlagDocumentView();
    const [flagComment, set_flagComment] = useState('');

    useEffect(() => {
        if(flagDocumentViewOpen){
            set_flagComment('');
        }
    }, [flagDocumentViewOpen]);

    const handleConfirm = async () => { 
        handleClose();
        let flag = activeUserIsOperator ? 1 : 2;
        flagDocument(flag, flagComment, activeUser, selectedDocument, updateDocument, socket, setLoading, toast);
    };

    const handleClose = () => {
        hideFlagDocumentView();
    };

    return (
        <Window
            open={flagDocumentViewOpen} onClose={handleClose}
            title="Sinalizar documento"
            submitButtonStartIcon={<FlagIcon />}
            submitButtonText="Sinalizar"
            handleSubmitButtonClick={handleConfirm}
        >
            <Box mb={3}>
                <Box mb={2}>
                    <Typography variant="body1">Você quer sinalizar o documento "<strong>{selectedDocument?.name}</strong>"? Isso notificará {activeUserIsOperator ? 'o(a) solicitante' : 'o Jurídico'}.</Typography>
                </Box>
                <Input
                    autoFocus
                    fullWidth multiline
                    label="Comentário (opcional)"
                    value={flagComment} onChange={(e) => set_flagComment(e.target.value)}
                />
            </Box>
        </Window>
    );
};

export default FlagDocumentWindow;