import EditorEffectIf from './EditorEffectIf';
import EditorEffectIfNot from './EditorEffectIfNot';
import EditorEffectIfTrue from './EditorEffectIfTrue';

const EditorEffect = (props) => {
    const { selectedEffect } = props;
    
    const acceptedComponents = {
        if: EditorEffectIf,
        ifNot: EditorEffectIfNot,
        ifTrue: EditorEffectIfTrue,
    }

    if(selectedEffect.special){
        const EditorEffectType = acceptedComponents[selectedEffect.special];
        if(EditorEffectType){
            return (
                <EditorEffectType {...props} />
            );
        }
    }
    return null;
};

export default EditorEffect;