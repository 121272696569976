import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';

const Transition = ({index, children}) => {
    const [transitionOpen, set_transitionOpen] = useState(false);
    const [transitionTimeout, set_transitionTimeout] = useState(300);

    useEffect(() => {
        setTimeout(() => {
            set_transitionOpen(true);
            set_transitionTimeout(0);
        }, 100);
    }, []);

    return (
        <Collapse key={index} timeout={transitionTimeout} in={transitionOpen}>
            <Box>
                {children}
            </Box>
        </Collapse>
    );
}

export default Transition;