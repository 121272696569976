import {
    useEffect,
    useRef,
    useState
} from 'react';
import FormQuestionSelect from '../../../../components/FormQuestionSelect';
import { useAppCtxSelectedFolder, useAppCtxUserGroups } from '../../../../context/SystemContext';
import { useForm } from '../../context/FormContext';

const SelectQuestion = ({question}) => {
    const selectedFolder = useAppCtxSelectedFolder();
    const { mainUserGroup } = useAppCtxUserGroups();
    const {
        initialForm,
        // userForm,
        set_userForm,
        // setFormValidation
    } = useForm();
    const [value, setValue] = useState(null);
    // const touchedField = useRef(false);
    const [userFormQuestion, setUserFormQuestion] = useState(null);

    useEffect(() => {
        if(userFormQuestion){
            set_userForm(prevState => ({
                ...prevState,
                [question.name]: userFormQuestion
            }));
        }
    }, [userFormQuestion]);
    
    useEffect(() => {
        setUserFormQuestion(prevState => ({
            ...prevState,
            id: question.id,
            type: 'select'
        }));
    }, []);

    useEffect(() => {
        if(question.name === 'Grupo' && (!initialForm || (initialForm && !initialForm['Grupo']) || selectedFolder.useGroups)){
            const newValue = initialForm[question.name] ? initialForm[question.name].choice : mainUserGroup || question.options[0].value;
            setValue({choice: newValue});
            handleChange(newValue);
        } else {
            setValue(initialForm[question.name]);
        }
    }, [initialForm]);

    // const checkValidation = () => {
    //     const questionValid = formEffects.select.checkValidation(userFormQuestion.current);
    //     setFormValidation(prevState => ({...prevState, [question.name]: !questionValid}));
    // };
    
    // useEffect(() => {
    //     if((userFormQuestion.current && userFormQuestion.current.choice) || touchedField.current){
    //         touchedField.current = true;
    //         checkValidation();
    //     }
    // }, [userFormQuestionUpdated]);

    const handleChange = (questionName, updates) => {
        setUserFormQuestion(prevState => ({
            ...prevState,
            ...updates
        }));
    };
    
    return (
        <FormQuestionSelect
            initialValue={value}
            question={question}
            onChange={handleChange}
        />
    );
};

export default SelectQuestion;