import SearchPage from '../../../components/clearance/SearchPage';

const Search = ({approvalAuthorities, authorityRiskLevel, numberOfApprovalsRequired, selectedFolder, selectedTemplate}) => {
    
    return (
        <SearchPage
            // activeUserIsTrueOperator
            // operators={operators}
            approvalAuthorities={approvalAuthorities}
            authorityRiskLevel={authorityRiskLevel}
            numberOfApprovalsRequired={numberOfApprovalsRequired}
            projects={[selectedFolder]}
            restrictedUserFolder={selectedFolder}
            selectedTemplate={selectedTemplate}
            // users={users}
        />
    );
};

export default Search;