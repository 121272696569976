import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const FormDialog = ({ defaultValue, disabledValues, multiline, okButtonText, open, onClose, onConfirm, title, text, textFieldLabel }) => {
    const [value, setValue] = useState('');
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        setValue(defaultValue || '');
    }, [defaultValue, open]);

    useEffect(() => {
        setDisabled(!value || (disabledValues && disabledValues.includes(value)));
    }, [value, disabledValues]);
    
    const handleChange = (e) => {
        setValue(e.target.value);
    };

    const handleConfirm = () => {
        onConfirm(value);
    };

    const handleKeyPress = (e) => {
        if(!disabled){
            if(!multiline && e.key === 'Enter') handleConfirm();
        }
    };
    
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{text}</DialogContentText>
                <TextField
                    variant="standard"
                    autoFocus
                    fullWidth
                    label={textFieldLabel}
                    margin="dense"
                    multiline={multiline}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                    value={value} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancelar</Button>
                <Button disabled={disabled} onClick={handleConfirm} variant="outlined" color="primary">{okButtonText || 'Ok'}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default FormDialog;