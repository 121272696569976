import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Box, Breadcrumbs, IconButton, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AlertDialog from '../../../../../components/AlertDialog';
import BigButton from '../../../../../components/BigButton';
import ButtonLink from '../../../../../components/ButtonLink';
import TinyMCE from '../components/TinyMCE';
import ViewBox from '../../../../../components/ViewBox';
import { onlyUnique } from '../../../../../utils/filters';
import { SERVER_PATH } from '../../../../../utils/constants';
import { useAppCtxAPI, useAppContext, useAppCtxListServicosDoAudiovisual } from '../../../../../context/SystemContext';
import { useClient, useClientCtxActiveClient } from '../../../../../context/ClientContext';
import { useOperatorTemplatesCtxAPI, useOperatorTemplatesCtxConstants, useOperatorTemplatesCtxTemplateDocumentEditorValue, useOperatorTemplatesCtxTemplateEditorSelectedTemplate, useOperatorTemplatesCtxTemplateFormEditorQuestions, useOperatorTemplatesCtxTemplateEffects } from '../context/TemplatesContext';
import { useDocumentEditor } from './context/DocumentEditorContext';
import { sortByKey } from '../../../../../utils/filters';
import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';

const breadCrumbsRowHeight = 38;
const breadCrumbsRowScrollbarHeight = 4;
const classes = {
    breadCrumbsRow: {
        backgroundColor: '#f5f6fa',
        height: `${breadCrumbsRowHeight}px`,
    },
    breadCrumbsSection: {
        marginRight: '8px',
        overflowX: 'auto',
        overflowY: 'hidden',
        '& *': {
            whiteSpace: 'nowrap',
            flexWrap: 'nowrap'
        },
        '&::-webkit-scrollbar': {
            height: `${breadCrumbsRowScrollbarHeight}px`},
    }
};

function DocumentEditor(){
    const { handleNavigate, setListServicosDoAudiovisual, setLoading } = useAppCtxAPI();
    const { formEffects } = useAppContext();
    const special_list_audiovisual_services = useAppCtxListServicosDoAudiovisual();
    const { getListById, getListItemKeyById } = useClient();
    const activeClient = useClientCtxActiveClient();
    const { handleChangeTemplateDocumentClick, setDocumentEditorValue, setFormEditorQuestions } = useOperatorTemplatesCtxAPI();
    const { specialEffects } = useOperatorTemplatesCtxConstants(); 
    const documentEditorValue = useOperatorTemplatesCtxTemplateDocumentEditorValue();
    const formEditorQuestions = useOperatorTemplatesCtxTemplateFormEditorQuestions();
    const templateEditorSelectedTemplate = useOperatorTemplatesCtxTemplateEditorSelectedTemplate();
    const templateEffects = useOperatorTemplatesCtxTemplateEffects();
    const {
        setExecCommand,
        handle_effectRadioOutputButtonClick,
        mainContent,
        mainContentOpen,
        onEffectDoubleClick,
        selectedEffectOutput, set_selectedEffectOutput,
        setEffectsTreeWindowOpen,
        tagPattern,
        tagIdPattern
    } = useDocumentEditor();
    const [backDialogOpen, set_backDialogOpen] = useState(false); // dialog opens when user clicks "back" button
    const [exitButtonClicked, set_exitButtonClicked] = useState('back');
    const [selectedEffect, setSelectedEffect] = useState(null);
    const [crumbs, set_crumbs] = useState([]);
    const crumbsBoxRef = useRef();

    useEffect(() => {
        if(crumbsBoxRef.current){
            bindEvents();
        }
    }, [crumbsBoxRef]);

    const bindEvents = () => {
        crumbsBoxRef.current.addEventListener('wheel', (evt) => {
            evt.preventDefault();
            crumbsBoxRef.current.scrollLeft += evt.deltaY;
        });
    };

    useEffect(() => {
        if(activeClient){
            let current_special_list_audiovisual_services = getListById('special_list_audiovisual_services');
            if(current_special_list_audiovisual_services){
                current_special_list_audiovisual_services = current_special_list_audiovisual_services.list.sort(sortByKey('key'));
                setListServicosDoAudiovisual(current_special_list_audiovisual_services);
            } else {
                setListServicosDoAudiovisual([]);
            }
        }
    }, [activeClient]);

    const getTemplateById = async (templateId) => {
        if(templateId){
            setLoading(true);
            await axios.post(SERVER_PATH + '/data/templates/one', { templateId })
            .then(res => {
                const foundTemplate = res.data;
                handleChangeTemplateDocumentClick(foundTemplate);
            })
            .catch(err => {
                setLoading(false);
            });
        }
    };

    useEffect(() => {
        let search = queryString.parse(window.location.search);
        const templateId = search.id;
        getTemplateById(templateId);
    }, []);

    useEffect(() => {
        if(selectedEffectOutput){
            const foundEffect = templateEffects.find(scannedEffect => scannedEffect.id === selectedEffectOutput.effect);
            if(foundEffect){
                setSelectedEffect(foundEffect);
                let crumbs = [];
                let foundEffectId = foundEffect.id;
                while (foundEffectId) {
                    let foundParent = false;
                    templateEffects.forEach(effect => {
                        if(effect.type === 'radio'){
                            let options = [...effect.options];
                            if(effect.textOption?.show) options = [...options, effect.textOption];
                            options.forEach((option, optionIndex) => {
                                if(option.output){
                                    const match = option.output.match(foundEffectId);
                                    if(match){
                                        foundEffectId = effect.id;
                                        foundParent = true;
                                        crumbs = [
                                            {
                                                id: foundEffectId,
                                                question: effect.question,
                                                option: option.option || option.show,
                                                optionIndex: option.show ? 'textOption' : optionIndex
                                            },
                                            ...crumbs
                                        ];
                                        return;
                                    }
                                }
                            });
                        } else if(effect.type === 'list' && effect.typeSpecifics === 'custom'){
                            let questionLabel = effect.question + ' - Lista (Personalizado)';
                            let options = [...effect.options];
                            options.forEach(option => {
                                if(option.output){
                                    const match = option.output.match(foundEffectId);
                                    if(match){
                                        foundEffectId = effect.id;
                                        foundParent = true;
                                        let optionLabel = option.option;
                                        optionLabel = getListItemKeyById(effect.typeId, option.option)
                                        crumbs = [
                                            {
                                                id: foundEffectId,
                                                question: questionLabel,
                                                option: optionLabel,
                                                optionIndex: option.option
                                            },
                                            ...crumbs
                                        ];
                                        return;
                                    }
                                }
                            });
                        } else if(effect.type === 'if'){
                            const options = [
                                {output: effect.output, optionIndex: 'output'},
                                {output: effect.elseOutput, optionIndex: 'elseOutput'}
                            ];
                            options.forEach(option => {
                                const foundEffectIdInOutput = option.output.match(foundEffectId);
                                if(foundEffectIdInOutput){
                                    foundEffectId = effect.id;
                                    foundParent = true;
                                    const question = effect.label.replace(/\[.*?\]\s/, '').replace(/(\[|\])/g, '');
                                    crumbs.unshift({id: foundEffectId, question, option: option.optionIndex === 'output' ? 'Então' : 'Caso contrário', optionIndex: option.optionIndex});
                                    return;
                                }
                            });
                        } else if(effect.type === 'ifNot'){
                            const options = [
                                {output: effect.output, optionIndex: 'output'},
                                {output: effect.elseOutput, optionIndex: 'elseOutput'}
                            ];
                            options.forEach(option => {
                                const foundEffectIdInOutput = option.output.match(foundEffectId);
                                if(foundEffectIdInOutput){
                                    foundEffectId = effect.id;
                                    foundParent = true;
                                    const question = effect.label.replace(/\[.*?\]\s/, '').replace(/(\[|\])/g, '');
                                    crumbs.unshift({id: foundEffectId, question, option: option.optionIndex === 'output' ? 'Então' : 'Caso contrário', optionIndex: option.optionIndex});
                                    return;
                                }
                            });
                        } else if(effect.type === 'ifTrue'){
                            const options = [
                                {output: effect.output, optionIndex: 'output'},
                                {output: effect.elseOutput, optionIndex: 'elseOutput'}
                            ];
                            options.forEach(option => {
                                const foundEffectIdInOutput = option.output.match(foundEffectId);
                                if(foundEffectIdInOutput){
                                    foundEffectId = effect.id;
                                    foundParent = true;
                                    const question = effect.label.replace(/\[.*?\]\s/, '').replace(/(\[|\])/g, '');
                                    crumbs.unshift({id: foundEffectId, question, option: option.optionIndex === 'output' ? 'Se verdadeiro' : 'Se falso', optionIndex: option.optionIndex});
                                    return;
                                }
                            });
                        } else if(effect.type === 'special' && (effect.special === 'if' || effect.special === 'ifNot' || effect.special === 'ifTrue')){
                            let options = [
                                {
                                    output: effect[effect.special].output, optionIndex: 'output',
                                    option: effect.special === 'if' ? 'Se' : (effect.special === 'ifNot' ? 'Se não' : 'Se verdadeiro')
                                },
                                {
                                    output: effect[effect.special].elseOutput, optionIndex: 'elseOutput',
                                    option: effect.special === 'if' ? 'Caso contrário' : (effect.special === 'ifNot' ? 'Caso contrário' : 'Se falso')
                                }
                            ];
                            options.forEach(option => {
                                const match = option.output.match(foundEffectId);
                                if(match){
                                    foundEffectId = effect.id;
                                    foundParent = true;
                                    let question = effect.label;
                                    question = question.replace(/\[.*?\]\s/, '');
                                    let optionLabel = option.option;
                                    if(effect.special === 'if' || effect.special === 'ifNot'){
                                        question = (effect.special === 'if' ? 'Se ' : 'Se não ') + question;
                                    }
                                    crumbs = [
                                        {
                                            id: foundEffectId,
                                            question: question,
                                            option: optionLabel,
                                            optionIndex: option.optionIndex
                                        }, ...crumbs
                                    ];
                                    return;
                                }
                            });
                        } else if(effect.type === 'actorSchedule'){
                            const options = [
                                {output: effect.preProductionSpecifyLimitNone, optionIndex: 'preProductionSpecifyLimitNone', label: 'Diárias de pré-produção: sem limite'},
                                {output: effect.preProductionSpecifyLimitSpecifyAdditionalServicesFeeNone, optionIndex: 'preProductionSpecifyLimitSpecifyAdditionalServicesFeeNone', label: 'Diárias de pré-produção: especificar limite, sem valor da diária extra'},
                                {output: effect.preProductionSpecifyLimitSpecifyAdditionalServicesFee, optionIndex: 'preProductionSpecifyLimitSpecifyAdditionalServicesFee', label: 'Diárias de pré-produção: especificar limite e valor da diária extra'},
                                {output: effect.principalPhotographySpecifyLimitNone, optionIndex: 'principalPhotographySpecifyLimitNone', label: 'Diárias de filmagem: sem limite'},
                                {output: effect.principalPhotographySpecifyLimitSpecifyAdditionalServicesFeeNone, optionIndex: 'principalPhotographySpecifyLimitSpecifyAdditionalServicesFeeNone', label: 'Diárias de filmagem: especificar limite, sem valor da diária extraOutro'},
                                {output: effect.principalPhotographySpecifyLimitSpecifyAdditionalServicesFee, optionIndex: 'principalPhotographySpecifyLimitSpecifyAdditionalServicesFee', label: 'Diárias de filmagem: especificar limite e valor da diária extra'},
                                {output: effect.postProductionSpecifyLimitNone, optionIndex: 'postProductionSpecifyLimitNone', label: 'Diárias de pós-produção: sem limite'},
                                {output: effect.postProductionSpecifyLimitSpecifyAdditionalServicesFeeNone, optionIndex: 'postProductionSpecifyLimitSpecifyAdditionalServicesFeeNone', label: 'Diárias de pós-produção: especificar limite, sem valor da diária extra'},
                                {output: effect.postProductionSpecifyLimitSpecifyAdditionalServicesFee, optionIndex: 'postProductionSpecifyLimitSpecifyAdditionalServicesFee', label: 'Diárias de pós-produção: especificar limite e valor da diária extra'},
                                {output: effect.tbd, optionIndex: 'tbd', label: 'A combinar'},
                                {output: effect.other, optionIndex: 'other', label: 'Outro'}
                            ];
                            options.forEach(option => {
                                const foundEffectIdInOutput = option.output.match(foundEffectId);
                                if(foundEffectIdInOutput){
                                    foundEffectId = effect.id;
                                    foundParent = true;
                                    const question = effect.question;
                                    crumbs.unshift({
                                        id: foundEffectId,
                                        question,
                                        option: option.label,
                                        optionIndex: option.optionIndex
                                    });
                                    return;
                                }
                            });
                        }
                    });
                    if(!foundParent) foundEffectId = null;
                }
                set_crumbs(crumbs);
            }
        }
    }, [selectedEffectOutput]);

    useEffect(() => {
        if(mainContentOpen){
            setSelectedEffect(null);
            set_selectedEffectOutput(null);
            set_crumbs([]);
        }
    }, [mainContentOpen]);

    useEffect(() => {
        if(crumbs.length >= 1){
            const crumbsBox = document.getElementById('crumbs-box');
            if(crumbsBox) crumbsBox.scrollLeft = crumbsBox.scrollWidth;
        }
    }, [crumbs]);

    const handle_documentEditorExitClick = (button) => {
        set_exitButtonClicked(button);
        set_backDialogOpen(true);
    };

    const getUniqueEffects = () => {
        let currentMainContent = mainContent;
        if(mainContentOpen) currentMainContent = documentEditorValue;
        const currentEffects = [];
        const getContent = (currentContent, prevContent) => {
            const contentEffects = currentContent.match(tagPattern);
            if(contentEffects){
                const uniqueEffects = contentEffects.filter(onlyUnique);
                uniqueEffects.forEach(effect => {
                    const effectRegExData = tagIdPattern.exec(effect);
                    if(effectRegExData){
                        const foundEffect = templateEffects.find(templateEffect => templateEffect.id === effectRegExData[1]);
                        if(foundEffect){
                            currentEffects.push(foundEffect);
                            if(formEffects[foundEffect.type].getOutputsContent){
                                const outputsContent = formEffects[foundEffect.type].getOutputsContent({ scannedEffect: foundEffect });
                                if(outputsContent) getContent(outputsContent, currentContent);
                            }
                        }
                    }
                });
            }
        };
        if(currentMainContent) getContent(currentMainContent);
        return currentEffects;
    };
    const getFormEditorQuestions = () => {
        if(templateEffects && templateEffects.length >= 1){
            const uniqueEffects = getUniqueEffects();
            let temp = [];
            const uniqueFormEffects = uniqueEffects.filter(effect => {
                if(effect){
                    const inArray = temp.includes(effect.question);
                    temp.push(effect.question);
                    return ((!['special', 'if', 'ifNot', 'ifTrue'].includes(effect.type) || effect.form)) && !inArray;
                }
                return false;
            });
            let newFormEditorQuestions = [];
            uniqueFormEffects.forEach(effect => {
                let question = {};
                question.id = effect.id;
                question.type = effect.type;
                question.name = effect.question;
                if(formEffects[question.type].getFormEditorQuestion){
                    question = formEffects[question.type].getFormEditorQuestion({ effect, question, activeClient, special_list_audiovisual_services });
                }
                question.logicalBranching = [{ id: uuidv4(), concurrentConditions: [ {id: uuidv4(), question: '~none;', choice: null, label: null} ] }];
                question.mandatory = true;
                question.typeId = effect.typeId;
                newFormEditorQuestions.push(question);
            });
            return newFormEditorQuestions;
        }
        return null;
    };
    const compareQuestions = (questions) => {
        //check passed questions against formEditorQuestions (latter is saved state)
        let addedQuestions = [];
        if(questions){
            questions.forEach(q => {
                let qIndex = formEditorQuestions.findIndex(formEditorQ => formEditorQ.type === q.type && formEditorQ.name === q.name);
                if(qIndex === -1) addedQuestions.push(q); //if q not found in formEditorQuestions...
            });
        }
        let removedQuestions = [];
        let newFormEditorQuestions = formEditorQuestions.filter(formEditorQ => {
            let tagIndex = -1;
            if(questions) tagIndex = questions.findIndex(q => q.type === formEditorQ.type && q.name === formEditorQ.name);
            if(formEditorQ.type === 'heading' || tagIndex > -1){
                return true;
            } else {
                removedQuestions.push(formEditorQ);
                return false;
            }
        });
        // check if removed questions are linked to questions with logical branching
        removedQuestions.forEach(removedQ => {
            if(removedQ.type === 'radio'){
                let questionIndex = -1;
                let logicalBranchingConcurrentConditionsGroupIndex = -1;
                let logicalBranchingConditionIndex = -1;
                questionIndex = newFormEditorQuestions.findIndex(question => {
                    logicalBranchingConcurrentConditionsGroupIndex = question.logicalBranching.findIndex(concurrentConditionGroup => {
                        logicalBranchingConditionIndex = concurrentConditionGroup.concurrentConditions.findIndex(logicalBranchingCondition => logicalBranchingCondition.question === removedQ.name);
                        return logicalBranchingConditionIndex !== -1;
                    });
                    return logicalBranchingConcurrentConditionsGroupIndex !== -1;
                });
                ;
                if(questionIndex === -1 && logicalBranchingConcurrentConditionsGroupIndex !== -1 && logicalBranchingConditionIndex !== -1){
                    newFormEditorQuestions[questionIndex].logicalBranching[logicalBranchingConcurrentConditionsGroupIndex].concurrentConditions[logicalBranchingConditionIndex].question = '~none;';
                };
            }
        });
        // add tags
        addedQuestions.reverse();
        addedQuestions.forEach(addedQ => newFormEditorQuestions.unshift(addedQ));
        setFormEditorQuestions(newFormEditorQuestions);
    };
    const handle_documentEditorSectionForwardClick = () => {
        handle_effectRadioOutputButtonClick('main');
        const newFormEditorQuestions = getFormEditorQuestions();
        if(formEditorQuestions === null) setFormEditorQuestions(newFormEditorQuestions);
        else compareQuestions(newFormEditorQuestions);
        handleNavigate(`/${activeClient.shortName}/juridico/documentos/matrizes/formulario`);
    };
    const handle_backDialogOkClick = () => {
        set_backDialogOpen(false);
        if(exitButtonClicked === 'back'){
            if(templateEditorSelectedTemplate){
                handleNavigate(`/${activeClient.shortName}/juridico/documentos/matrizes`);
            } else {
                handleNavigate(`/${activeClient.shortName}/juridico/documentos/matrizes/nova`);
            }
        } else {
            handleNavigate(`/${activeClient.shortName}/juridico/documentos/matrizes`);
        }
    };

    const EffectBreadcrumbs = () => {
        const getEffectLabel = () => {
            if(selectedEffect.type === 'if'){
                return selectedEffect.label.replace(/^\[Se\]\s/, '').replace(/(\[|\])/g, '');
            } else if(selectedEffect.type === 'ifNot'){
                return selectedEffect.label.replace(/^\[Se não\]\s/, '').replace(/(\[|\])/g, '');
            } else if(selectedEffect.type === 'ifTrue'){
                return selectedEffect.label.replace(/^\[Se verdadeiro\]\s/, '').replace(/(\[|\])/g, '');
            }
            return selectedEffect.label || selectedEffect.question;
        };
        const getHeading = () => {
            let heading = '';
            if(selectedEffectOutput && selectedEffect){
                const effectType = selectedEffect.type;
                if(effectType === 'radio'){
                    if(selectedEffectOutput.optionIndex === 'textOption'){
                        heading += `Preencher`;
                    } else {
                        heading += selectedEffect?.options ? selectedEffect?.options[selectedEffectOutput?.optionIndex]?.option : '(...)';
                    }
                } else if(effectType === 'list'){
                    heading += getListItemKeyById(selectedEffect.typeId, selectedEffectOutput.optionIndex);
                } else if(effectType === 'if'){
                    heading += selectedEffectOutput.optionIndex === 'output' ? 'Então' : 'Caso contrário';
                } else if(effectType === 'ifNot'){
                    heading += selectedEffectOutput.optionIndex === 'output' ? 'Então' : 'Caso contrário';
                } else if(effectType === 'ifTrue'){
                    heading += selectedEffectOutput.optionIndex === 'output' ? 'Se verdadeiro' : 'Se falso';
                } else if(effectType === 'actorSchedule'){
                    if(selectedEffectOutput.optionIndex === 'preProductionSpecifyLimitNone'){
                        heading += 'Diárias de pré-produção: sem limite';
                    } else if(selectedEffectOutput.optionIndex === 'preProductionSpecifyLimitSpecifyAdditionalServicesFeeNone'){
                        heading += 'Diárias de pré-produção: especificar limite, sem valor da diária extra';
                    } else if(selectedEffectOutput.optionIndex === 'preProductionSpecifyLimitSpecifyAdditionalServicesFee'){
                        heading += 'Diárias de pré-produção: especificar limite e valor da diária extra';
                    } else if(selectedEffectOutput.optionIndex === 'principalPhotographySpecifyLimitNone'){
                        heading += 'Diárias de filmagem: sem limite';
                    } else if(selectedEffectOutput.optionIndex === 'principalPhotographySpecifyLimitSpecifyAdditionalServicesFeeNone'){
                        heading += 'Diárias de filmagem: especificar limite, sem valor da diária extra';
                    } else if(selectedEffectOutput.optionIndex === 'principalPhotographySpecifyLimitSpecifyAdditionalServicesFee'){
                        heading += 'Diárias de filmagem: especificar limite e valor da diária extra';
                    } else if(selectedEffectOutput.optionIndex === 'postProductionSpecifyLimitNone'){
                        heading += 'Diárias de pós-produção: sem limite';
                    } else if(selectedEffectOutput.optionIndex === 'postProductionSpecifyLimitSpecifyAdditionalServicesFeeNone'){
                        heading += 'Diárias de pós-produção: especificar limite, sem valor da diária extra';
                    } else if(selectedEffectOutput.optionIndex === 'postProductionSpecifyLimitSpecifyAdditionalServicesFee'){
                        heading += 'Diárias de pós-produção: especificar limite e valor da diária extra';
                    } else if(selectedEffectOutput.optionIndex === 'tbd'){
                        heading += 'A combinar';
                    } else if(selectedEffectOutput.optionIndex === 'other'){
                        heading += 'Outro';
                    }
                }
            }
            return heading;
        };

        const Breadcrumb = ({crumbId, label, optionContent}) => (
            <Typography variant="body1" style={{fontSize: 13}}>
                {
                    crumbId &&
                    <span><ButtonLink onClick={(e) => onEffectDoubleClick(e, crumbId)}>{label}</ButtonLink>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                }
                {optionContent}
            </Typography>
        );
        
        return (
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                <Breadcrumb
                    optionContent={
                        mainContentOpen
                        ? <strong>Principal</strong>
                        : <ButtonLink onClick={() => handle_effectRadioOutputButtonClick('main')}>Principal</ButtonLink>
                    }
                />
                {
                    crumbs.map((crumb, index) => (
                        <Breadcrumb key={index}
                            crumbId={crumb.id}
                            label={crumb.question}
                            optionContent={<ButtonLink onClick={() => handle_effectRadioOutputButtonClick(crumb.id, crumb.optionIndex)}>"{crumb.option}"</ButtonLink>}
                        />
                    ))
                }
                {
                    selectedEffect &&
                    <Breadcrumb
                        crumbId={selectedEffect.id}
                        label={getEffectLabel()}
                        optionContent={<strong>"{getHeading()}"</strong>}
                    />
                }
            </Breadcrumbs>
        );
    };
    
    return (
        <ViewBox style={{height: '100%', padding: 0}}>
            <Box sx={classes.breadCrumbsRow}>
                <Grid container spacing={1} alignItems="center" wrap="nowrap">
                    <Grid item>
                        <IconButton size="small" onClick={() => setEffectsTreeWindowOpen(true)}><AccountTreeIcon /></IconButton>
                    </Grid>
                    <Grid item xs sx={classes.breadCrumbsSection} id="crumbs-box" ref={crumbsBoxRef}>
                        <EffectBreadcrumbs />
                    </Grid>
                </Grid>
            </Box>
            <Box style={{height: `calc(100% - ${breadCrumbsRowHeight - breadCrumbsRowScrollbarHeight}px)`, display: 'flex', flexDirection: 'row'}}>
                <Box style={{height: '100%', flex: 1}} id="tinyEditorBox">
                    <TinyMCE
                        noMenuBar
                        value={documentEditorValue} onEditorChange={(content) => setDocumentEditorValue(content)}
                        onEffectDoubleClick={(e) => onEffectDoubleClick(e)}

                        data={{ setExecCommand, specialEffects }}
                    />
                </Box>
                <Box style={{maxHeight: '100%', overflowY: 'auto', overflowX: 'hidden', display: 'flex', flexDirection: 'column'}}>
                    <Box style={{flex: 1}} mt={1}>
                        <Box>
                            <BigButton icon={<SettingsOutlinedIcon />} label="Matrizes" style={{marginRight: 0}} onClick={() => handle_documentEditorExitClick('templates')} />
                        </Box>
                    </Box>
                    <Box mb={1}>
                        <Box mb={2}>
                            <BigButton icon={<ArrowBackIcon />} label="Voltar" style={{marginRight: 0}} onClick={() => handle_documentEditorExitClick('back')} />
                        </Box>
                        <Box mb={2}>
                            <BigButton icon={<ArrowForwardIcon />} label="Formulário" style={{marginRight: 0}} onClick={handle_documentEditorSectionForwardClick} />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <AlertDialog
                id="document-editor-back-dialog"
                open={backDialogOpen} onClose={() => set_backDialogOpen(false)}
                text={`Você quer sair sem salvar o documento?`}
                okButtonOnClick={handle_backDialogOkClick}
            />
        </ViewBox>
    );
}

export default DocumentEditor;