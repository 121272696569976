import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';

const classes = {
    ellipsis: {
        display: 'inline-block',
        position: 'relative',
        width: '80px',
        height: '80px',
        '& div': {
            position: 'absolute',
            top: '33px',
            width: '13px',
            height: '13px',
            borderRadius: '50%',
            background: theme => theme.palette.grey[300],
            animationTimingFunction: 'cubic-bezier(0, 1, 1, 0)',
            '&:nth-of-type(1)': {
                '@keyframes lds-ellipsis1': {
                    '0%': {
                      transform: 'scale(0)'
                    },
                    '100%': {
                      transform: 'scale(1)'
                    }
                },
                left: '8px',
                animation: 'lds-ellipsis1 0.6s infinite'
            },
            '&:nth-of-type(2)': {
                '@keyframes lds-ellipsis2': {
                    '0%': {
                      transform: 'translate(0, 0)'
                    },
                    '100%': {
                      transform: 'translate(24px, 0)'
                    }
                },
                left: '8px',
                animation: 'lds-ellipsis2 0.6s infinite'
            },
            '&:nth-of-type(3)': {
                '@keyframes lds-ellipsis2': {
                    '0%': {
                      transform: 'translate(0, 0)'
                    },
                    '100%': {
                      transform: 'translate(24px, 0)'
                    }
                },
                left: '32px',
                animation: 'lds-ellipsis2 0.6s infinite'
            },
            '&:nth-of-type(4)': {
                '@keyframes lds-ellipsis3': {
                    '0%': {
                      transform: 'scale(1)'
                    },
                    '100%': {
                      transform: 'scale(0)'
                    }
                },
                left: '56px',
                animation: 'lds-ellipsis3 0.6s infinite'
            }
        },
    },
    primary: {
        '& div': {
            background: theme => theme.palette.primary.light,
        }
    }
};

const Div = styled('div')(unstable_styleFunctionSx);

const LoaderEllipsis = ({primary}) => {
    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid item>
                <Div sx={[classes.ellipsis, primary && classes.primary]}><div></div><div></div><div></div><div></div></Div>
            </Grid>
        </Grid>
    );
};

export default LoaderEllipsis;