import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import { Box, Button, CircularProgress, Collapse, FormControlLabel, Grid, List, ListItem, ListItemText, Radio, RadioGroup, TextField, Typography, Zoom } from '@mui/material';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import SaveIcon from '@mui/icons-material/Save';
import Autocomplete from '../../components/Autocomplete';
import ViewBox from '../../components/ViewBox';
import { BRAZILIAN_STATES, CNPJ_REGEXP, CPF_REGEXP, EMAIL_REGEXP, ERROR_MESSAGE_UNKNOWN, MOBILE_REGEXP, SERVER_PATH } from '../../utils/constants';
import { useAppCtxAPI } from '../../context/SystemContext';
import InputMask from 'react-input-mask';
import ReCAPTCHA from 'react-google-recaptcha';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Suppliers = () => {
    const { setLoadingSystem } = useAppCtxAPI();
    const params = useParams();
    const clientId = params.clientId;
    const [loading, setLoading] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const [client, setClient] = useState(null);
    const defaultForm = {
        name: { value: '', touched: false },
        taxpayerNumber: { value: '', touched: false },
        address: { value: '', touched: false },
        addressFirstLine: { value: '', touched: false },
        city: { value: '', touched: false },
        state: { value: '', touched: false },
        postCode: { value: '', touched: false },
        type: { value: 'PJ', touched: false },
        representative: { value: '', touched: false },
        representativeTaxpayerNumber: { value: '', touched: false },
        email: { value: '', touched: false },
        mobile: { value: '', touched: false }
    };
    const [form, setForm] = useState(defaultForm);
    const [showSuccessAlert, set_showSuccessAlert] = useState('');
    const [showErrorAlert, set_showErrorAlert] = useState('');
    const captchaRef = useRef(null);
    const [reCAPTCHAToken, setReCAPTCHAToken] = useState('');
    const [checkingCNPJ, setCheckingCNPJ] = useState(false);
    const [foundCNPJs, setFoundCNPJs] = useState({});
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

    useEffect(() => {
        setLoadingSystem(false);
    }, []);

    useEffect(() => {
        setLoading(true);
        if(clientId){
            axios.get(SERVER_PATH + `/data/suppliers?clientId=${clientId}`)
            .then(res => {
                setShowForm(true);
                setClient(res.data);
                set_showErrorAlert('');
                setLoading(false);
            })
            .catch(err => {
                set_showErrorAlert(err?.response?.data || ERROR_MESSAGE_UNKNOWN);
                setLoading(false);
            })
        } else {
            set_showErrorAlert('Cliente não encontrado.');
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        let isSubmitButtonDisabled = false;
        if(!form.name.value || !form.taxpayerNumber.value || !form.addressFirstLine.value || !form.city.value || !form.state.value || !form.postCode.value || !form.email.value){
            isSubmitButtonDisabled = true;
        }
        if(form.type.value === 'PJ' && (!form.representative.value || !form.representativeTaxpayerNumber.value)){
            isSubmitButtonDisabled = true;
        }
        
        if(form.taxpayerNumber.value){
            if(form.type.value === 'PF'){
                if(!CPF_REGEXP.test(form.taxpayerNumber.value)) isSubmitButtonDisabled = true;
            } else {
                if(!CNPJ_REGEXP.test(form.taxpayerNumber.value)) isSubmitButtonDisabled = true;
            }
        }
        if(form.representativeTaxpayerNumber.value && !CPF_REGEXP.test(form.representativeTaxpayerNumber.value)){
            isSubmitButtonDisabled = true;
        }
        if(form.email.value && !EMAIL_REGEXP.test(form.email.value)){
            isSubmitButtonDisabled = true;
        }
        if(form.mobile.value && !MOBILE_REGEXP.test(form.mobile.value)){
            isSubmitButtonDisabled = true;
        }
        setSubmitButtonDisabled(isSubmitButtonDisabled);
    }, [form]);

    const capitalizeFirstLetter = (phrase) => {
        const phraseWords = phrase.split(' ').map(phraseWord => {
            phraseWord = phraseWord.toLowerCase();
            if(/^(das?|de|dos?)$/.test(phraseWord)){
                return phraseWord;
            }
            return phraseWord.charAt(0).toUpperCase() + phraseWord.slice(1);
        });
        const newValue = phraseWords.join(' ');
        return newValue;
    }
    
    const getCNPJ = async (newValue) => {
        if(newValue){
            if(CNPJ_REGEXP.test(newValue)){
                if(!foundCNPJs[newValue]){
                    setCheckingCNPJ(true);
                    await axios.get(SERVER_PATH + `/apis/cnpj-ja/cnpj?cnpj=${newValue}`)
                    .then(res => {
                        const data = res.data;
                        if(data){
                            const result = {...data, cnpj: newValue, selectedLegalRepresentativeIndex: 0};
                            completeFieldsFromCNPJ(result);
                        } else {
                            // CNPJ not found, so reset CNPJ data
                            setFoundCNPJs(prevState => ({...prevState, [newValue]: null}));
                        }
                    })
                    .catch(err => {
                        // error retrieving CNPJ, so reset CNPJ data
                        
                        setFoundCNPJs(prevState => ({...prevState, [newValue]: null}));
                    });
                    setCheckingCNPJ(false);
                } else {
                    completeFieldsFromCNPJ(foundCNPJs[newValue]);
                }
            }
        } else {
            setFoundCNPJs(prevState => ({...prevState, [newValue]: null}));
        }
    };

    const completeFieldsFromCNPJ = (CNPJ) => {
        const addressStreet = capitalizeFirstLetter(CNPJ.address.street);
        const addressStreetNumber = capitalizeFirstLetter(CNPJ.address.number);
        const addressBuildingNumber = CNPJ.address.details ? capitalizeFirstLetter(CNPJ.address.details) : '';
        const addressNeighbourhood = capitalizeFirstLetter(CNPJ.address.district);
        const addressCity = capitalizeFirstLetter(CNPJ.address.city);
        const addressState = CNPJ.address.state;
        const addressPostCode = capitalizeFirstLetter(CNPJ.address.zip);
        const addressFirstLine = `${addressStreet}, ${addressStreetNumber}${addressBuildingNumber ? `, ${addressBuildingNumber}` : ''}, ${addressNeighbourhood}`;
        const fullAddress = `${addressFirstLine} - ${addressCity}/${addressState}, CEP ${addressPostCode}`;
        CNPJ.addressStreet = addressStreet;
        CNPJ.addressStreetNumber = addressStreetNumber;
        CNPJ.addressBuildingNumber = addressBuildingNumber;
        CNPJ.addressNeighbourhood = addressNeighbourhood;
        CNPJ.addressCity = addressCity;
        CNPJ.addressState = addressState;
        CNPJ.addressPostCode = addressPostCode;
        CNPJ.addressFirstLine = addressFirstLine;
        CNPJ.fullAddress = fullAddress;
        const currentPartners = CNPJ.company.members;
        const mappedPartners = currentPartners.map(partner => capitalizeFirstLetter(partner.person.name));
        CNPJ.partnerNames = mappedPartners;
        const mappedLegalRepresentatives = currentPartners.filter(partner => partner.role.id === 49).map(partner => capitalizeFirstLetter(partner.person.name));
        CNPJ.legalRepresentativeNames = mappedLegalRepresentatives;

        setForm(prevState => {
            if(!prevState.name.value) prevState.name.value = CNPJ.company.name;
            if(!prevState.addressFirstLine.value) prevState.addressFirstLine.value = CNPJ.fullAddress;
            if(!prevState.city.value) prevState.city.value = CNPJ.addressCity;
            if(!prevState.state.value) prevState.state.value = CNPJ.addressState;
            if(!prevState.postCode.value) prevState.postCode.value = CNPJ.addressPostCode;
            if(!prevState.representative.value){
                prevState.representative.value = CNPJ.legalRepresentativeNames[0];
                prevState.representative.legalRepresentativeFoundInCNPJ = true;
            }
            return prevState;
        });

        if(!foundCNPJs[CNPJ.cnpj]) setFoundCNPJs(prevState => ({...prevState, [CNPJ.cnpj]: CNPJ}));

        setCheckingCNPJ(false);
    };

    const handleSubmit = async () => {
        setLoading(true);
        const data = {
            clientId: clientId,
            form: {
                ...form,
                address: { value: `${form.addressFirstLine.value}` }
            },
            cnpj: {},
            reCAPTCHA: reCAPTCHAToken
        };
        const foundCNPJ = foundCNPJs[form.taxpayerNumber.value];
        if(foundCNPJ){
            data.cnpj.cnpj = foundCNPJ.cnpj;
            data.cnpj.companyName = foundCNPJ.nome;
            data.cnpj.addressStreet = foundCNPJ.addressStreet;
            data.cnpj.addressStreetNumber = foundCNPJ.addressStreetNumber;
            data.cnpj.addressBuildingNumber = foundCNPJ.addressBuildingNumber;
            data.cnpj.addressNeighbourhood = foundCNPJ.addressNeighbourhood;
            data.cnpj.addressCity = foundCNPJ.addressCity;
            data.cnpj.addressState = foundCNPJ.addressState;
            data.cnpj.addressPostCode = foundCNPJ.addressPostCode;
            data.cnpj.addressFirstLine = foundCNPJ.addressFirstLine;
            data.cnpj.fullAddress = foundCNPJ.fullAddress;
            data.cnpj.partnerNames = foundCNPJ.partnerNames;
            data.cnpj.legalRepresentativeNames = foundCNPJ.legalRepresentativeNames;
        }
        await axios.post(SERVER_PATH + '/data/suppliers/submit', data)
        .then(res => {
            clearForm();
            set_showSuccessAlert('A ficha foi cadastrada.');
        })
        .catch(err => {
            resetReCAPTCHA();
            set_showErrorAlert(err?.response?.data || ERROR_MESSAGE_UNKNOWN);
        });
        setLoading(false);
    };
    const clearForm = () => {
        setForm(defaultForm);
        setShowForm(false);
        resetReCAPTCHA();
    };
    const resetReCAPTCHA = () => {
        captchaRef.current.reset();
        setReCAPTCHAToken('');
    };

    const handleNewFormClick = () => {
        set_showSuccessAlert('');
        set_showErrorAlert('');
        setShowForm(true);
    };

    const handleSupplierTypeChange = (newValue) => {
        setForm(prevState => ({
            ...prevState,
            type: {
                ...prevState.type,
                value: newValue
            },
            taxpayerNumber: {
                ...prevState.taxpayerNumber
            },
            representative: {
                ...prevState.representative
            },
            representativeTaxpayerNumber: {
                ...prevState.representativeTaxpayerNumber
            }
        }));
    };

    const handleFormFieldChange = (fieldKey, updates) => {
        setForm(prevState => ({
            ...prevState,
            [fieldKey]: {
                ...prevState[fieldKey],
                ...updates
            }
        }))
    };

    const handleCorporationIdChange = (newValue) => {
        handleFormFieldChange('taxpayerNumber', {value: newValue});
        if(form.type.value === 'PJ'){
            getCNPJ(newValue);
        }
    }

    const handleRepresentativeChange = (newValue) => {
        let legalRepresentativeFoundInCNPJ = false;
        if(form.taxpayerNumber.value && foundCNPJs[form.taxpayerNumber.value] && foundCNPJs[form.taxpayerNumber.value].legalRepresentativeNames){
            legalRepresentativeFoundInCNPJ = foundCNPJs[form.taxpayerNumber.value].legalRepresentativeNames.includes(newValue);
        }
        handleFormFieldChange('representative', { value: newValue, legalRepresentativeFoundInCNPJ });
    };

    return (
        <ViewBox>
            <Container maxWidth="sm">
                <Box mb={1} style={{textAlign: 'center'}}>
                    <img src={'/SOLIDA-500.png'} style={{height: 70}} />
                </Box>
                <Collapse in={showSuccessAlert}>
                    <Box mb={1}>
                        <Alert variant="filled" severity="success" onClose={() => set_showSuccessAlert('')}>{showSuccessAlert}</Alert>
                    </Box>
                </Collapse>
                <Collapse in={showErrorAlert}>
                    <Box mb={1}>
                        <Alert variant="filled" severity="error" onClose={() => set_showErrorAlert('')}>{showErrorAlert}</Alert>
                    </Box>
                </Collapse>
                {
                    (!showForm && client) &&
                    <Box mb={1} style={{textAlign: 'center'}}>
                        <Button
                            disabled={loading}
                            variant="contained" color="primary"
                            onClick={handleNewFormClick}
                        >Cadastrar outra ficha</Button>
                    </Box>
                }
                <Box>
                    <Zoom in={!loading && showForm}>
                        <Paper>
                            <form>
                                <Box p={2}>
                                    {
                                        client &&
                                        <Box py={2} style={{textAlign: 'center'}}>
                                            {
                                                client.logo
                                                ?
                                                <img src={`/uploads/logos/${client.logo}`} alt={client.name} style={{maxHeight: 80}} />
                                                :
                                                <Typography variant="h4">{client.name}</Typography>
                                            }
                                        </Box>
                                    }
                                    <Box mb={2}>
                                        <Grid container spacing={1} alignItems="flex-end">
                                            <Grid item xs={12}>
                                                <RadioGroup
                                                    // aria-label={name} name={name}
                                                    value={form.type.value}
                                                    onChange={(e) => handleSupplierTypeChange(e.target.value)}
                                                >
                                                    {
                                                        [
                                                            {value: 'PF', label: 'Pessoa física'},
                                                            {value: 'PJ', label: 'Pessoa jurídica'}
                                                        ].map((option) => (
                                                            <FormControlLabel
                                                                key={option.value}
                                                                value={option.value}
                                                                control={<Radio />}
                                                                label={option.label}
                                                            />
                                                        ))
                                                    }
                                                </RadioGroup>
                                            </Grid>
                                            <Grid item xs={12} container spacing={1} alignItems="flex-end">
                                                <Grid item xs={9} sm={7} md={5} container spacing={1} alignItems="flex-end">
                                                    <Grid item xs>
                                                        <InputMask
                                                            mask={form.type.value === 'PJ' ? '99.999.999/9999-99' : '999.999.999-99'}
                                                            maskChar=""
                                                            disabled={checkingCNPJ}
                                                            value={form.taxpayerNumber.value}
                                                            onChange={(e) => handleCorporationIdChange(e.target.value)}
                                                            onBlur={() => handleFormFieldChange('taxpayerNumber', {touched: true})}
                                                        >
                                                            {() => (
                                                                <TextField
                                                                    fullWidth variant="standard"
                                                                    label={form.type.value === 'PJ' ? 'CNPJ' : 'CPF'}
                                                                    required error={
                                                                        form.taxpayerNumber.touched &&
                                                                        (
                                                                            !form.taxpayerNumber.value ||
                                                                            (
                                                                                (form.type.value === 'PF' && !CPF_REGEXP.test(form.taxpayerNumber.value)) ||
                                                                                (form.type.value === 'PJ' && !CNPJ_REGEXP.test(form.taxpayerNumber.value))
                                                                            )
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </InputMask>
                                                    </Grid>
                                                    {
                                                        checkingCNPJ
                                                        ?
                                                        <Grid item>
                                                            <CircularProgress size={20} />
                                                        </Grid>
                                                        :
                                                        <Grid item>
                                                            {
                                                                foundCNPJs[form.taxpayerNumber.value] &&
                                                                <CheckCircleIcon fontSize="small" htmlColor="green" />
                                                            }
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    variant="standard" fullWidth
                                                    required error={!form.name.value && form.name.touched}
                                                    label={form.type === 'PJ' ? 'Razão/denominação social' : 'Nome completo'}
                                                    value={form.name.value}
                                                    disabled={checkingCNPJ}
                                                    onChange={(e) => handleFormFieldChange('name', {value: e.target.value})}
                                                    onBlur={() => handleFormFieldChange('name', {touched: true})}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    variant="standard" fullWidth
                                                    required error={!form.addressFirstLine.value && form.addressFirstLine.touched}
                                                    label="Endereço (logradouro, número, complemento e bairro)"
                                                    value={form.addressFirstLine.value}
                                                    disabled={checkingCNPJ}
                                                    onChange={(e) => handleFormFieldChange('addressFirstLine', {value: e.target.value})}
                                                    onBlur={() => handleFormFieldChange('addressFirstLine', {touched: true})}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    variant="standard" fullWidth
                                                    required error={!form.city.value && form.city.touched}
                                                    label="Cidade"
                                                    value={form.city.value}
                                                    disabled={checkingCNPJ}
                                                    onChange={(e) => handleFormFieldChange('city', {value: e.target.value})}
                                                    onBlur={() => handleFormFieldChange('city', {touched: true})}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Autocomplete
                                                    required error={!form.state.value && form.state.touched}
                                                    label="UF"
                                                    value={form.state.value}
                                                    disabled={checkingCNPJ}
                                                    onChange={(e, newValue) => handleFormFieldChange('state', {value: newValue})}
                                                    onBlur={() => handleFormFieldChange('state', {touched: true})}
                                                    options={BRAZILIAN_STATES}
                                                    getOptionLabel={(option) => option}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    variant="standard" fullWidth
                                                    required error={!form.postCode.value && form.postCode.touched}
                                                    label="CEP"
                                                    value={form.postCode.value}
                                                    disabled={checkingCNPJ}
                                                    onChange={(e) => handleFormFieldChange('postCode', {value: e.target.value})}
                                                    onBlur={() => handleFormFieldChange('postCode', {touched: true})}
                                                />
                                            </Grid>
                                            {
                                                form.type.value === 'PJ' &&
                                                <Grid item xs={12}>
                                                    <Grid container spacing={1} alignItems="flex-end">
                                                        <Grid item xs={6} sm={7}>
                                                            <TextField
                                                                variant="standard" fullWidth
                                                                required error={!form.representative.value && form.representative.touched}
                                                                label="Nome completo do(a) representante legal"
                                                                disabled={checkingCNPJ}
                                                                value={form.representative.value}
                                                                onChange={(e) => handleRepresentativeChange(e.target.value)}
                                                                onBlur={() => handleFormFieldChange('representative', {touched: true})}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} sm={5}>
                                                            <InputMask
                                                                mask="999.999.999-99"
                                                                maskChar=""
                                                                value={form.representativeTaxpayerNumber.value}
                                                                onChange={(e) => handleFormFieldChange('representativeTaxpayerNumber', {value: e.target.value})}
                                                                onBlur={() => handleFormFieldChange('representativeTaxpayerNumber', {touched: true})}
                                                            >
                                                                {() => (
                                                                    <TextField
                                                                        fullWidth variant="standard"
                                                                        label="CPF do representante legal"
                                                                        required error={form.representativeTaxpayerNumber.touched && (!form.representativeTaxpayerNumber.value || !CPF_REGEXP.test(form.representativeTaxpayerNumber.value))}
                                                                    />
                                                                )}
                                                            </InputMask>
                                                        </Grid>
                                                    </Grid>
                                                    {
                                                        (form.taxpayerNumber.value && foundCNPJs[form.taxpayerNumber.value] && foundCNPJs[form.taxpayerNumber.value].legalRepresentativeNames && foundCNPJs[form.taxpayerNumber.value].legalRepresentativeNames.length >= 1) &&
                                                        <Collapse in>
                                                            <Box mt={1}>
                                                                <Grid container justifyContent="center">
                                                                    <Grid item>
                                                                        <Typography variant="h6">Sócios-administradores:</Typography>
                                                                        <List dense>
                                                                        {
                                                                            foundCNPJs[form.taxpayerNumber.value].legalRepresentativeNames.map(partnerName => (
                                                                                <ListItem key={partnerName} dense button
                                                                                    onClick={() => handleRepresentativeChange(partnerName)}
                                                                                >
                                                                                    <ListItemText disableTypography primary={
                                                                                        <Grid container spacing={1} alignItems="center" wrap="nowrap">
                                                                                            <Grid item xs><Typography variant="body2">{partnerName}</Typography></Grid>
                                                                                            {
                                                                                                form.representative.value === partnerName &&
                                                                                                <Grid item>
                                                                                                    <CheckCircleIcon fontSize="small" htmlColor="green" />
                                                                                                </Grid>
                                                                                            }
                                                                                        </Grid>
                                                                                    } />
                                                                                </ListItem>
                                                                            ))
                                                                        }
                                                                        </List>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </Collapse>
                                                    }
                                                </Grid>
                                            }
                                            <Grid item xs={6}>
                                                <TextField
                                                    fullWidth variant="standard"
                                                    required error={form.email.touched && (!form.email.value || !EMAIL_REGEXP.test(form.email.value))}
                                                    label="E-mail"
                                                    value={form.email.value}
                                                    onChange={(e) => handleFormFieldChange('email', {value: e.target.value})}
                                                    onBlur={() => handleFormFieldChange('email', {touched: true})}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <InputMask
                                                    mask="(99) 99999-9999"
                                                    maskChar=""
                                                    value={form.mobile.value}
                                                    onChange={(e) => handleFormFieldChange('mobile', {value: e.target.value})}
                                                    onBlur={() => handleFormFieldChange('mobile', {touched: true})}
                                                >
                                                    {() => (
                                                        <TextField
                                                            fullWidth variant="standard"
                                                            label="Celular"
                                                            required error={form.mobile.touched && (!form.mobile.value || !MOBILE_REGEXP.test(form.mobile.value))}
                                                        />
                                                    )}
                                                </InputMask>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box mb={2}>
                                        <ReCAPTCHA
                                            sitekey="6Le8UFUhAAAAAHrhXcPU17Dr7UdVQzFT5GUmt7aS"
                                            ref={captchaRef}
                                            onChange={token => setReCAPTCHAToken(token)}
                                        />
                                    </Box>
                                    <Box pb={2} style={{textAlign: 'center'}}>
                                        <Button
                                            disabled={!reCAPTCHAToken || submitButtonDisabled || loading}
                                            variant="contained" color="primary"
                                            startIcon={<SaveIcon />}
                                            onClick={handleSubmit}>Cadastrar</Button>
                                    </Box>
                                </Box>
                            </form>
                        </Paper>
                    </Zoom>        
                </Box>
            </Container>
        </ViewBox>
    );
}

export default Suppliers;