import ClearanceQuestionsListPage from '../../../components/clearance/ClearanceQuestionsListPage';

const Questions = ({approvalAuthorities, authorityRiskLevel, initialClearanceFolderInformation, initialSelectedTemplate, numberOfApprovalsRequired, selectedFolder, selectedFolderUsers, selectedFolderType}) => {

    return (
        <ClearanceQuestionsListPage
            // activeUserIsOperator
            // operators={operators}
            approvalAuthorities={approvalAuthorities}
            authorityRiskLevel={authorityRiskLevel}
            initialClearanceFolderInformation={initialClearanceFolderInformation}
            initialSelectedTemplate={initialSelectedTemplate}
            numberOfApprovalsRequired={numberOfApprovalsRequired}
            projects={[selectedFolder]}
            restrictedUserFolder={selectedFolder}
            selectedFolderType={selectedFolderType}
            selectedFolderUsers={selectedFolderUsers}
        />
    );
};

export default Questions;