import { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CloseIcon from '@mui/icons-material/Close';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import Autocomplete from '../Autocomplete';
import ClearanceTimeCodeScreenshot from '../ClearanceTimeCodeScreenshot/ClearanceTimeCodeScreenshot';
import CommentsList from '../CommentsList';
import NoResults from '../NoResults';
import Input from '../Input';
import Select from '../Select';
import axios from 'axios';
import { useAppCtxActiveUser } from '../../context/SystemContext';
import { updateStateChangeArrayItemWith_id } from '../../utils/common';
import { CLEARANCE_RISK_OPTIONS, SERVER_PATH } from '../../utils/constants';
import { sortByKey } from '../../utils/filters';

const classes = {
    closeButton: {
        position: 'absolute',
        right: '8px',
        top: '8px',
        color: theme => theme.palette.grey[500],
    }
};

const CommentsWindow = ({
    activeUserIsOperator,
    open, setOpen, authorityRiskLevel, numberOfApprovalsRequired, selectedClearanceItem, setSelectedClearanceItem, selectedReport, selectedTemplate, setClearanceItems, users
}) => {
    const activeUser = useAppCtxActiveUser();
    const [comments, setComments] = useState([]);
    const [newCommentValue, setNewCommentValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedRightType, setSelectedRightType] = useState(null);
    const [useTypeOptions, setUseTypeOptions] = useState([]);
    const [selectedUseType, setSelectedUseType] = useState('');
    const [selectedRiskLevel, setSelectedRiskLevel] = useState('');
    const [riskApproved, setRiskApproved] = useState('withholdOpinion');
    const riskLevelNumbers = {
        one: 1,
        two: 2,
        three: 3,
        four: 4
    };

    useEffect(() => {
        if(open){
            setNewCommentValue('');
        }
    }, [open]);

    useEffect(() => {
        if(selectedClearanceItem){
            setComments(selectedClearanceItem.comments || []);

            let currentRightType = selectedClearanceItem.rightType || null;
            let currentUseType = selectedClearanceItem.useType || '';
            let currentRiskLevel = selectedClearanceItem.riskLevel || '';
            let itemComments = selectedClearanceItem.comments || [];
            const commentsWithRiskLevel = itemComments.filter(comment => comment.riskLevel);
            if(commentsWithRiskLevel.length >= 1){
                const lastCommentWithRiskLevel = commentsWithRiskLevel[commentsWithRiskLevel.length - 1];
                currentRightType = lastCommentWithRiskLevel.rightType;
                currentUseType = lastCommentWithRiskLevel.useType;
                currentRiskLevel = lastCommentWithRiskLevel.riskLevel;
            }
            setSelectedRightType(currentRightType);
            setSelectedUseType(currentUseType);
            setSelectedRiskLevel(currentRiskLevel);

            let currentRiskApproved;
            if(activeUser){
                const approvals = selectedClearanceItem.approvals || [];
                const activeUserApprovals = approvals.filter(approval => approval.userId === activeUser._id);
                if(activeUserApprovals.length >= 1){
                    const activeUserLastApproval = activeUserApprovals[activeUserApprovals.length - 1];
                    currentRiskApproved = activeUserLastApproval.approval;
                }
            }
            setRiskApproved(currentRiskApproved || 'withholdOpinion');
        }
    }, [selectedClearanceItem]);

    useEffect(() => {
        if(open && activeUser){
            const comments = selectedClearanceItem.comments || [];
            const unreadComments = comments.filter(comment => !comment.readBy.includes(activeUser._id));
            if(unreadComments.length >= 1){
                markCommentsAsRead(unreadComments.map(comment => comment._id));
            }
        }
    }, [open, activeUser]);

    useEffect(() => {
        if(selectedClearanceItem && (selectedTemplate || selectedClearanceItem.template) && selectedRightType){
            const itemType = (selectedTemplate || selectedClearanceItem.template).rightTypes.find(rightType => rightType._id === selectedRightType);
            const riskLevels = itemType.riskLevels;
            let options = [];
            for(const riskLevelKey in riskLevels){
                const riskLevel = riskLevels[riskLevelKey];
                const riskOption = CLEARANCE_RISK_OPTIONS.find(riskOption => riskOption.value === riskLevelKey);
                options = [...options, ...riskLevel.map(item => ({value: item._id, label: `Risco ${riskOption.label} - ${item.name}` , riskLevel: riskLevelKey})).sort(sortByKey('label'))];
            }
            setUseTypeOptions(options);
        }
    }, [selectedClearanceItem, selectedTemplate, selectedRightType]);

    const markCommentsAsRead = (comments) => {
        axios.post(SERVER_PATH + '/data/clearance/comments/read', { reportItemId: selectedClearanceItem._id, comments })
        .then(res => {
            const updatedReportItemComments = res.data;
            const updatedReportItem = {...selectedClearanceItem, comments: updatedReportItemComments};
            updateStateChangeArrayItemWith_id(updatedReportItem, setClearanceItems);
            if(setSelectedClearanceItem) setSelectedClearanceItem(updatedReportItem);
        })
        .catch(err => {
            // err.response.data
        })
    }

    const handleApproveRiskButtonPress = (newValue) => {
        setRiskApproved(newValue);
    };

    const handleRightTypeChange = (newValue) => {
        setSelectedRightType(newValue);
        setSelectedUseType('');
        setSelectedRiskLevel('');
    };

    const handleUseTypeChange = (newValue) => {
        setSelectedUseType(newValue);
        const useType = useTypeOptions.find(option => option.value === newValue);
        setSelectedRiskLevel(useType.riskLevel);
    };

    const handleSubmit = async () => {
        setLoading(true);

        const postData = {
            documentId: selectedClearanceItem._id,
            comment: newCommentValue
        };

        if(activeUserIsOperator){
            postData.riskLevel = selectedRiskLevel;
            postData.riskLevelDescription = selectedRiskLevel ? CLEARANCE_RISK_OPTIONS.find(riskOption => riskOption.value === selectedRiskLevel)?.label : '';
        }
        
        if(selectedRiskLevel && numberOfApprovalsRequired && numberOfApprovalsRequired[selectedRiskLevel] > 0 && authorityRiskLevel > 0 && authorityRiskLevel >= riskLevelNumbers[selectedRiskLevel]){
            if(riskApproved !== 'withholdOpinion'){
                postData.riskApproved = riskApproved;
            }
        }

        await axios.post(SERVER_PATH + '/data/clearance/comment', postData)
        .then(res => {
            updateStateChangeArrayItemWith_id(res.data, setClearanceItems);
            if(setSelectedClearanceItem) setSelectedClearanceItem(res.data);
            setNewCommentValue('');
        })
        .catch(err => {
            // if(setAlert) setAlert({alert: ERROR_MESSAGE_CHANGES_UNDONE});
        });
        setLoading(false);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}
            // TransitionComponent={Transition}
            // keepMounted
            fullWidth
            maxWidth="md"
            // fullScreen
        >
            <DialogTitle id="simple-dialog-title">
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs>
                        <Typography variant="h6">{!!activeUser ? 'Comentar: ' : ''}{selectedClearanceItem?.description}</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton
                            sx={classes.closeButton}
                            aria-label="close"
                            onClick={handleClose}
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>

                <ClearanceTimeCodeScreenshot {...{ selectedClearanceItem, setSelectedClearanceItem, selectedReport, setClearanceItems, windowOpen: open }} />

                <Box mb={2}>
                    <CommentsList
                        comments={comments}
                        handleDeleteCommentButtonClick={null}
                        users={users}
                    />
                </Box>
                {
                    !!activeUser &&
                    <Box>
                        {
                            (selectedRiskLevel && numberOfApprovalsRequired && numberOfApprovalsRequired[selectedRiskLevel] > 0 && authorityRiskLevel > 0 && authorityRiskLevel >= riskLevelNumbers[selectedRiskLevel]) &&
                            <Box mb={1}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs={9} sm={8} md={7}>
                                        <Select
                                            label="Deseja aprovar o risco?"
                                            options={[
                                                {value: 'withholdOpinion', label: 'Não responder (aguardar informações)'},
                                                {value: 'yes', label: <Grid container spacing={1} alignItems="center"><Grid item><ThumbUpAltOutlinedIcon /></Grid><Grid item xs>Aprovar o risco</Grid></Grid>},
                                                {value: 'no', label: <Grid container spacing={1} alignItems="center"><Grid item><ThumbDownAltOutlinedIcon /></Grid><Grid item xs>Reprovar o risco</Grid></Grid>}
                                            ]}
                                            value={riskApproved} onChange={(e) => handleApproveRiskButtonPress(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <IconButton size="small" onClick={() => handleApproveRiskButtonPress('yes')}>
                                            <ThumbUpAltOutlinedIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton size="small" onClick={() => handleApproveRiskButtonPress('no')}>
                                            <ThumbDownAltOutlinedIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                        <Box mb={1}>
                            <Input
                                disabled={loading}
                                placeholder="Seu comentário"
                                autoFocus fullWidth multiline
                                minRows={5} maxRows={20}
                                value={newCommentValue} onChange={(e) => setNewCommentValue(e.target.value)}
                            />
                        </Box>
                        {
                            (activeUserIsOperator && selectedClearanceItem && !!selectedClearanceItem.comments && selectedClearanceItem.comments.length !== 0 && (selectedTemplate || selectedClearanceItem.template)) &&
                            <Box mt={1}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            disabled
                                            label="Direito envolvido"
                                            options={(selectedTemplate || selectedClearanceItem.template).rightTypes.sort(sortByKey('name')).map(rightType => rightType._id)}
                                            getOptionLabel={(option) => (selectedTemplate || selectedClearanceItem.template).rightTypes.find(rightType => rightType._id === option)?.name}
                                            value={selectedRightType} onChange={(e, newValue) => handleRightTypeChange(newValue)}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Select
                                            disabled
                                            optionsStyle={{fontSize: 14}} multiline
                                            label="Condição de uso"
                                            options={useTypeOptions}
                                            value={selectedUseType} onChange={(e) => handleUseTypeChange(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Select
                                            label="Nível de risco"
                                            options={CLEARANCE_RISK_OPTIONS.map(option => ({...option, label: <span><span style={{color: option.color}}>&#11044;</span>&nbsp;{option.label}</span>}))}
                                            value={selectedRiskLevel} onChange={(e) => setSelectedRiskLevel(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                    </Box>
                }
                {
                    (!activeUser && comments.length === 0) &&
                    <NoResults text="Nenhum comentário." />
                }
            </DialogContent>
            {
                !!activeUser &&
                <DialogActions>
                    <Button
                        onClick={handleSubmit}
                        variant="contained" color="primary"
                        disabled={loading || !newCommentValue}
                        startIcon={loading && <CircularProgress size={20} />}
                    >
                        Comentar
                    </Button>
                </DialogActions>
            }
        </Dialog>
    );
};

export default CommentsWindow;