import { useEffect, useRef, useState } from 'react';
import { useAppContext } from '../../../../context/SystemContext';
import FormQuestionCorporationId from '../../../../components/FormQuestionCorporationId';
import { useForm } from '../../context/FormContext';

const CorporationIdQuestion = ({question}) => {
    const { formEffects } = useAppContext();
    const {
        foundCNPJs, setFoundCNPJs, initialForm, set_initialForm,
        setQuestionNameToCNPJ,
        selectedSupplier, setQuestionsStatus,
        usedSupplierInformation, set_userForm, setFormValidation, visibleQuestions
    } = useForm();
    const [initialValue, setInitialValue] = useState('');
    const [foundCorporationId, setFoundCorporationId] = useState('');
    const [userFormQuestion, setUserFormQuestion] = useState(null);
    const touchedField = useRef(false);
    const shouldNotUpdate = useRef(false);

    useEffect(() => {
        if(userFormQuestion){
            set_userForm(prevState => ({
                ...prevState,
                [question.name]: userFormQuestion
            }));
        }
    }, [userFormQuestion]);
    
    useEffect(() => {
        setUserFormQuestion(prevState => ({
            ...prevState,
            id: question.id,
            type: 'corporationId'
        }));
    }, []);

    useEffect(() => {
        if(!shouldNotUpdate.current) setInitialValue(initialForm[question.name]);
        shouldNotUpdate.current = true;
    }, [initialForm[question.name]]);

    const checkValidation = () => {
        const questionValid = formEffects.corporationId.checkValidation(userFormQuestion);
        setFormValidation(prevState => ({...prevState, [question.name]: !questionValid}));
    };
    
    useEffect(() => {
        if((userFormQuestion && userFormQuestion.input) || touchedField.current){
            touchedField.current = true;
            checkValidation();
        }
    }, [userFormQuestion]);

    const handleChange = (questionName, updates) => {
        setUserFormQuestion(prevState => ({
            ...prevState,
            ...updates
        }));
    };

    const capitalizeFirstLetter = (phrase) => {
        const phraseWords = phrase.split(' ').map(phraseWord => {
            phraseWord = phraseWord.toLowerCase();
            if(/^(das?|de|dos?)$/.test(phraseWord)){
                return phraseWord;
            }
            return phraseWord.charAt(0).toUpperCase() + phraseWord.slice(1);
        });
        const newValue = phraseWords.join(' ');
        return newValue;
    }

    const completeFieldsFromCNPJ = (CNPJ) => {
        set_userForm(prevState => {
            const companyName = CNPJ.company.name;
            CNPJ.companyName = companyName;
            const checkCorporationIdIsMEI = !!CNPJ.company.simei.optant;
            const onePerson = checkCorporationIdIsMEI || CNPJ.company.nature.id === 2135; // "Empresário (Individual)"
            if(checkCorporationIdIsMEI){
                const onePersonRegEx = /^((\d{2}\.?\d{3}\.?\d{3} )?([A-zÀ-ú .]+)|([A-zÀ-ú .]+)( \d{11})?)$/.exec(companyName);
                if(onePersonRegEx){
                    CNPJ.company.members = [
                        {
                            person: {
                                name: onePersonRegEx[3] || onePersonRegEx[4],
                            },
                            role: {
                                id: 49
                            }
                        }
                    ];
                }
            }
            CNPJ.isMEI = checkCorporationIdIsMEI;
            const addressStreet = capitalizeFirstLetter(CNPJ.address.street);
            const addressStreetNumber = capitalizeFirstLetter(CNPJ.address.number);
            const addressBuildingNumber = CNPJ.address.details ? capitalizeFirstLetter(CNPJ.address.details) : '';
            const addressNeighbourhood = capitalizeFirstLetter(CNPJ.address.district);
            const addressCity = capitalizeFirstLetter(CNPJ.address.city);
            const addressState = CNPJ.address.state;
            const addressPostCode = capitalizeFirstLetter(CNPJ.address.zip);
            const fullAddress = `${addressStreet}, ${addressStreetNumber}${addressBuildingNumber ? `, ${addressBuildingNumber}` : ''}, ${addressNeighbourhood} - ${addressCity}/${addressState}, CEP ${addressPostCode}`
            CNPJ.addressStreet = addressStreet;
            CNPJ.addressStreetNumber = addressStreetNumber;
            CNPJ.addressBuildingNumber = addressBuildingNumber;
            CNPJ.addressNeighbourhood = addressNeighbourhood;
            CNPJ.addressCity = addressCity;
            CNPJ.addressState = addressState;
            CNPJ.addressPostCode = addressPostCode;
            CNPJ.fullAddress = fullAddress;
            const currentPartners = CNPJ.company.members;
            const mappedPartners = currentPartners.map(partner => capitalizeFirstLetter(partner.person.name));
            CNPJ.partnerNames = mappedPartners;
            const mappedLegalRepresentatives = currentPartners.filter(partner => partner.role.id === 49).map(partner => capitalizeFirstLetter(partner.person.name));
            CNPJ.legalRepresentativeNames = mappedLegalRepresentatives;
            
            const updates = {};
            if(visibleQuestions){
                const corporationNameQuestion = visibleQuestions.find(visibleQuestion => visibleQuestion.assignedField === 1 && visibleQuestion.corporationIdQuestion === question.name);
                if(corporationNameQuestion && (!prevState[corporationNameQuestion.name] || !prevState[corporationNameQuestion.name].input || (selectedSupplier && !usedSupplierInformation.current))){
                    if(!updates[corporationNameQuestion.name]){
                        updates[corporationNameQuestion.name] = { id: corporationNameQuestion.id };
                    }
                    updates[corporationNameQuestion.name].input = companyName;
                    updates[corporationNameQuestion.name].type = 'text';
                    updates[corporationNameQuestion.name].foundCorporationId = 'active';
                    updates[corporationNameQuestion.name].retrievedFromCorporationId = true;
                }
    
                const corporationAddressQuestion = visibleQuestions.find(visibleQuestion => visibleQuestion.assignedField === 3 && visibleQuestion.corporationIdQuestion === question.name);
                if(corporationAddressQuestion && (!prevState[corporationAddressQuestion.name] || !prevState[corporationAddressQuestion.name].input || (selectedSupplier && !usedSupplierInformation.current))){
                    if(!updates[corporationAddressQuestion.name]){
                        updates[corporationAddressQuestion.name] = { id: corporationAddressQuestion.id };
                    }
                    updates[corporationAddressQuestion.name].input = fullAddress;
                    updates[corporationAddressQuestion.name].type = 'text';
                    updates[corporationAddressQuestion.name].foundCorporationId = 'active';
                    updates[corporationAddressQuestion.name].retrievedFromCorporationId = true;
                }
    
                const corporationLegalRepresentativeQuestion = visibleQuestions.find(visibleQuestion => visibleQuestion.assignedField === 4 && visibleQuestion.corporationIdQuestion === question.name);
                if(corporationLegalRepresentativeQuestion && (!prevState[corporationLegalRepresentativeQuestion.name] || !prevState[corporationLegalRepresentativeQuestion.name].input || (selectedSupplier && !usedSupplierInformation.current))){
                    const foundSocio = mappedLegalRepresentatives[0];
                    if(foundSocio){
                        if(!updates[corporationLegalRepresentativeQuestion.name]){
                            updates[corporationLegalRepresentativeQuestion.name] = { id: corporationLegalRepresentativeQuestion.id };
                        }
                        updates[corporationLegalRepresentativeQuestion.name].input = foundSocio;
                        updates[corporationLegalRepresentativeQuestion.name].type = 'text';
                        updates[corporationLegalRepresentativeQuestion.name].foundCorporationId = 'active';
                        updates[corporationLegalRepresentativeQuestion.name].retrievedFromCorporationId = true;
                    }
                }

                if(selectedSupplier && !usedSupplierInformation.current) usedSupplierInformation.current = true;
            }
            
            if(!foundCNPJs[CNPJ.cnpj]) setFoundCNPJs(prevFoundCNPJs => ({...prevFoundCNPJs, [CNPJ.cnpj]: CNPJ}));
            setQuestionNameToCNPJ(questionNameToCNPJPrevState => ({...questionNameToCNPJPrevState, [question.name]: CNPJ}));

            shouldNotUpdate.current = true;
            set_initialForm(initialFormPrevState => ({...initialFormPrevState, ...updates}));
            
            setUserFormQuestion(userFormQuestionPrevState => ({
                ...userFormQuestionPrevState,
                foundCorporationId: 'active'
            }));

            return ({...prevState, ...updates});
        });
        setFoundCorporationId('active');
    };
    
    return (
        <FormQuestionCorporationId
            completeFieldsFromCNPJ={completeFieldsFromCNPJ}
            foundCNPJs={foundCNPJs}
            foundCorporationId={foundCorporationId} setFoundCorporationId={setFoundCorporationId}
            initialValue={initialValue}
            onChange={handleChange}
            question={question}
            setQuestionNameToCNPJ={setQuestionNameToCNPJ}
            setQuestionsStatus={setQuestionsStatus}
        />
    );
};

export default CorporationIdQuestion;