import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import Autocomplete from './Autocomplete';
import Select from './Select';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import update from 'immutability-helper';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

const ScheduleQuestion = (props) => {
    const { initialValue } = props;
    const questionOptions = [
        { choice: 'tbd', label: 'A combinar' },
        { choice: 'details', label: 'Detalhar cronograma' },
        { choice: 'other', label: 'Outro' }
    ];
    const defaultValue = { choice: 'tbd', rows: [], other: '' };
    const [value, setValue] = useState(defaultValue);
    const defaultRow = {
        description: '',
        type: 'tbd',
        oneDate: null,
        startDate: null,
        endDate: null,
        shouldSpecifyTime: 'none',
        time: 0
    };

    useEffect(() => {
        setValue(initialValue ? {...defaultValue, ...initialValue} : defaultValue);
    }, [initialValue]);

    useEffect(() => {
        if(props.onChange) props.onChange(props.question.name, value);
    }, [value]);

    const handleChangeChoice = (newValue) => {
        setValue(prevState => {
            let rows = [];
            if(newValue === 'details' && prevState.rows.length === 0) rows = [{...defaultRow, id: uuidv4()}];
            return ({...prevState, choice: newValue, rows});
        });
    };
    
    const handleChange = (rowIndex, key, newValue) => {
        if(key === 'oneDate' || key === 'startDate' || key === 'endDate') newValue = newValue.toDate();
        setValue(prevState => ({
            ...prevState,
            rows: [...prevState.rows.slice(0, rowIndex), {...prevState.rows[rowIndex], [key]: newValue}, ...prevState.rows.slice(rowIndex + 1)]
        }));
    };
    const handleDeleteRowButtonClick = (rowIndex) => {
        setValue(prevState => ({
            ...prevState,
            rows: [...prevState.rows.slice(0, rowIndex), ...prevState.rows.slice(rowIndex + 1)]
        }));
    };

    const handleAddRowButtonClick = () => {
        setValue(prevState => ({
            ...prevState,
            rows: [...prevState.rows, {...defaultRow, id: uuidv4()}]
        }));
    };

    const handleScheduleRowDragEnd = (data) => {
        setValue(prevState => ({
            ...prevState,
            rows: update(prevState.rows, {
                $splice: [
                    [data.source.index, 1],
                    [data.destination.index, 0, prevState.rows[data.source.index]],
                ],
            }),
        }));
    };
    
    return (
        <Box
            onBlur={props.onBlur}
            onFocus={props.onFocus}
        >
            <RadioGroup
                value={value.choice}
                onChange={(e) => handleChangeChoice(e.target.value)}
            >
                <Grid container spacing={1} alignItems="center">
                    {
                        questionOptions.map((option, optionIndex) => (
                            <Grid key={optionIndex}>
                                <FormControlLabel
                                    value={option.choice}
                                    control={<Radio />}
                                    label={option.label}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            </RadioGroup>
            {
                value.choice === 'details' &&
                <Box mt={1}>
                    <Box mb={1}>
                        <Divider />
                    </Box>
                    <DragDropContext onDragEnd={handleScheduleRowDragEnd}>
                        <Droppable droppableId={'form-schedule-question-droppable-rows-container'}>
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>

                                    {
                                        value.rows.map((row, rowIndex) => {
                                            return (
                                                <Draggable
                                                    key={row.id.toString()}
                                                    draggableId={row.id.toString()}
                                                    index={rowIndex}
                                                    isDragDisabled={false}
                                                >
                                                    {(provided) => (
                                                        <div ref={provided.innerRef} {...provided.draggableProps}>

                                                            <Box>
                                                                <Grid container spacing={1} alignItems="center"
                                                                    sx={{
                                                                        borderRadius: '8px',
                                                                        '&:hover': {
                                                                            backgroundColor: theme => theme.palette.action.hover
                                                                        }
                                                                    }}
                                                                >
                                                                    <Grid>
                                                                        <IconButton size="small" disableRipple {...provided.dragHandleProps}>
                                                                            <DragHandleIcon />
                                                                        </IconButton>
                                                                    </Grid>
                                                                    <Grid xs container spacing={1} alignItems="flex-start">
                                                                        <Grid xs={12} sm={3} md={4}>
                                                                            <Autocomplete
                                                                                freeSolo
                                                                                placeholder="Escolha ou digite a descrição"
                                                                                options={[
                                                                                    'Preparação',
                                                                                    'Pré-produção',
                                                                                    'Gravação',
                                                                                    'Filmagem',
                                                                                    'Desprodução',
                                                                                    'Pós-produção',
                                                                                    'Recesso'
                                                                                ]}
                                                                                getOptionLabel={(option) => option}
                                                                                value={row.description}
                                                                                onChange={(e, newValue) => handleChange(rowIndex, 'description', newValue)}
                                                                            />
                                                                        </Grid>
                                                                        <Grid xs container spacing={1} alignItems="center">
                                                                            <Grid xs={12} sm={3} md={4}>
                                                                                <Select
                                                                                    options={[
                                                                                        {value: 'tbd', label: 'Datas a combinar'},
                                                                                        {value: 'oneDate', label: 'Indicar data'},
                                                                                        {value: 'dateRange', label: 'Indicar período'}
                                                                                    ]}
                                                                                    value={row.type}
                                                                                    onChange={(e) => handleChange(rowIndex, 'type', e.target.value)}
                                                                                />
                                                                            </Grid>
                                                                            {
                                                                                row.type === 'oneDate' &&
                                                                                <Grid xs={12} sm={3} md={3}>
                                                                                    <DatePicker
                                                                                        format="DD/MM/YY" autoOk
                                                                                        value={moment(row.oneDate)}
                                                                                        onChange={(date) => handleChange(rowIndex, 'oneDate', date)}
                                                                                        invalidDateMessage="Formato de data inválido"
                                                                                        renderInput={(props) => <TextField placeholder="Data" {...props} /> }
                                                                                    />
                                                                                </Grid>
                                                                            }
                                                                            {
                                                                                row.type === 'dateRange' &&
                                                                                <Grid xs={12} sm={3} md={3}>
                                                                                    <DatePicker
                                                                                        format="DD/MM/YY" autoOk
                                                                                        value={moment(row.startDate)}
                                                                                        onChange={(date) => handleChange(rowIndex, 'startDate', date)}
                                                                                        invalidDateMessage="Formato de data inválido"
                                                                                        renderInput={(props) => <TextField placeholder="Início" {...props} /> }
                                                                                    />
                                                                                </Grid>
                                                                            }
                                                                            {
                                                                                row.type === 'dateRange' &&
                                                                                <Grid xs={12} sm={3} md={3}>
                                                                                    <DatePicker
                                                                                        format="DD/MM/YY" autoOk
                                                                                        value={moment(row.endDate)}
                                                                                        onChange={(date) => handleChange(rowIndex, 'endDate', date)}
                                                                                        invalidDateMessage="Formato de data inválido"
                                                                                        renderInput={(props) => <TextField placeholder="Fim" {...props} /> }
                                                                                    />
                                                                                </Grid>
                                                                            }
                                                                            {
                                                                                row.type !== 'oneDate' &&
                                                                                <Grid xs={12} sm={3} md={row.shouldSpecifyTime === 'none' ? 7 : 4}>
                                                                                    <Select
                                                                                        options={[
                                                                                            {value: 'none', label: 'Não especificar diárias/semanas/meses'},
                                                                                            {value: 'days', label: 'Especificar diárias'},
                                                                                            {value: 'weeks', label: 'Especificar semanas'},
                                                                                            {value: 'months', label: 'Especificar meses'}
                                                                                        ]}
                                                                                        value={row.shouldSpecifyTime}
                                                                                        onChange={(e) => handleChange(rowIndex, 'shouldSpecifyTime', e.target.value)}
                                                                                    />
                                                                                </Grid>
                                                                            }
                                                                            {
                                                                                (row.type !== 'oneDate' && row.shouldSpecifyTime !== 'none') &&
                                                                                <Grid xs={12} sm={3} md={1}>
                                                                                    <TextField
                                                                                        variant="standard"
                                                                                        fullWidth
                                                                                        placeholder="Número"
                                                                                        type="number"
                                                                                        value={row.time}
                                                                                        onChange={(e) => handleChange(rowIndex, 'time', e.target.value)} />
                                                                                </Grid>
                                                                            }
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid>
                                                                        <IconButton size="small" onClick={() => handleDeleteRowButtonClick(rowIndex)}><ClearIcon /></IconButton>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>

                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })
                                    }
                                    
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>

                    <Grid container justifyContent="flex-end">
                        <Grid>
                            <Button startIcon={<AddIcon />} onClick={handleAddRowButtonClick}>Adicionar período</Button>
                        </Grid>
                    </Grid>
                </Box>
            }
            {
                value.choice === 'other' &&
                <Box mt={1}>
                    <Box mb={1}>
                        <Divider />
                    </Box>
                    <TextField
                        variant="standard"
                        fullWidth
                        multiline
                        placeholder="Sua resposta"
                        value={value.other}
                        onChange={(e) => handleChange('other', e.target.value)} />
                </Box>
            }
        </Box>
    );
}

export default ScheduleQuestion;