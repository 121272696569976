import { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearanceTimeCodeScreenshot from '../ClearanceTimeCodeScreenshot/ClearanceTimeCodeScreenshot';
import ClearanceWindowRiskFields from './ClearanceWindowRiskFields';
import { useAppCtxAPI, useAppCtxActiveUser } from '../../context/SystemContext';
import { CLEARANCE_RISK_OPTIONS, ERROR_MESSAGE_CHANGES_UNDONE, SERVER_PATH } from '../../utils/constants';
import mongoose from 'mongoose';

const ClearanceWindow = ({
    open, setOpen, activeUserIsTrueOperator, clearanceItems, setClearanceItems, handleDeleteReportItemPress, orderClearanceReport,
    selectedProject, selectedProjectTemplate, selectedClearanceItem, setSelectedClearanceItem, selectedReport
}) => {
    const defaultClearanceItem = {
        description: '',
        rightType: null,
        useType: '',
        riskLevel: '',
        riskDescription: '',
        licensed: false,
        licenseLink: '',
        additionalInformation: '',
        scriptPage: 1,
        scriptScene: 1,
        cutTimeCode: '',
        legalRecommendation: '',
        requiresAdditionalInformation: false
    };
    const { floatingAlert } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const [clearanceItem, setClearanceItem] = useState(defaultClearanceItem);

    useEffect(() => {
        if(open){
            clearForm();
            if(selectedReport){
                if(selectedClearanceItem){
                    setClearanceItem({
                        ...defaultClearanceItem, ...selectedClearanceItem,
                        // itemNotInThisReport,
                        scriptPage: selectedClearanceItem?.scriptPage,
                        scriptScene: selectedClearanceItem?.scriptScene,
                        scriptPageOrScene: selectedClearanceItem?.scriptPageOrScene,
                        cutTimeCode: selectedClearanceItem?.cutTimeCode,
                        rightType: selectedClearanceItem?.rightType || null,
                        useType: selectedClearanceItem?.useType || '',
                        riskLevel: selectedClearanceItem?.riskLevel || '',
                        riskDescription: selectedClearanceItem?.riskDescription || '',
                        screenShot: selectedClearanceItem.screenShot || '',
                        licensed: !!selectedClearanceItem.licensed,
                        licenseLink: selectedClearanceItem.licenseLink || '',
                        legalRecommendation: selectedClearanceItem.legalRecommendation || '',
                        requiresAdditionalInformation: !!selectedClearanceItem.requiresAdditionalInformation,
                    });
                } else {
                    clearForm();
                    if(clearanceItems && clearanceItems.length >= 1){
                        const lastReportItem = clearanceItems[clearanceItems.length - 1];
                        if(lastReportItem.scriptScene) setClearanceItem(prevState => ({...prevState, scriptScene: lastReportItem.scriptScene}));
                    }
                }
            }
        }
    }, [open, selectedClearanceItem, selectedReport]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleFieldChange = (fieldId, newValue) => {
        setClearanceItem(prevState => ({...prevState, [fieldId]: newValue}));
    };

    const handleSubmit = async () => {
        const prevClearanceReportItems = [...clearanceItems];
        let nextClearanceReportItems = [...clearanceItems];
        
        const updates = {
            description: clearanceItem.description,
            scriptScene: clearanceItem.scriptScene,
            cutTimeCode: clearanceItem.cutTimeCode,
            rightType: clearanceItem.rightType,
            useType: clearanceItem.useType,
            riskLevel: clearanceItem.riskLevel,
            riskDescription: clearanceItem.riskDescription,
            clearanceTemplate: selectedProjectTemplate,
            report: selectedReport,
            screenShot: clearanceItem.screenShot,
            licensed: clearanceItem.licensed,
            licenseLink: clearanceItem.licenseLink,
            legalRecommendation: clearanceItem.legalRecommendation,
            requiresAdditionalInformation: clearanceItem.requiresAdditionalInformation,
            comments: clearanceItem.comments
        };

        updates.createdBy = activeUser._id;
        updates.createdByName = activeUser.fullName;
        updates.createdByImg = activeUser.img;
        if(selectedProjectTemplate){
            if(clearanceItem.rightType){
                const reportItemRightType = selectedProjectTemplate.rightTypes.find(rightType => rightType._id === clearanceItem.rightType);
                updates.rightTypeDescription = reportItemRightType.name;
                const riskLevels = reportItemRightType.riskLevels;
                for(const riskLevelKey in riskLevels){
                    const riskLevel = riskLevels[riskLevelKey];
                    const reportItemUseType = riskLevel.find(useType => useType._id === clearanceItem.useType);
                    if(reportItemUseType){
                        updates.useTypeDescription = reportItemUseType.name;
                        break;
                    }
                }
                const reportItemRiskLevel = CLEARANCE_RISK_OPTIONS.find(riskOption => riskOption.value === clearanceItem.riskLevel);
                updates.riskLevelDescription = reportItemRiskLevel.label;

                if(reportItemRiskLevel.label){
                    if(!selectedClearanceItem || !clearanceItem.comments || clearanceItem.comments.length === 0){
                        updates.comments = [
                            {
                                createdAt: new Date(),
                                createdBy: activeUser._id,
                                comment: clearanceItem.legalRecommendation || `Risco: ${reportItemRiskLevel.label}`,
                                readBy: [activeUser._id],
                                riskLevel: clearanceItem.riskLevel,
                                riskLevelDescription: reportItemRiskLevel.label,
                            }
                        ];        
                    }
                }
            }
        }

        if(!selectedClearanceItem){
            const ObjectId = mongoose.Types.ObjectId;
            const newReportItemId = new ObjectId().toString();
            updates._id = newReportItemId;
        }

        const postData = {
            folderId: selectedProject._id,
            reportId: selectedReport._id,
            clearanceItemId: selectedClearanceItem?._id,
            clearanceItem: updates,
        };
        if(selectedClearanceItem){
            const prevClearanceReportItemIndex = nextClearanceReportItems.findIndex(prevClearanceReportItem => prevClearanceReportItem._id === selectedClearanceItem._id);
            nextClearanceReportItems = [
                ...nextClearanceReportItems.slice(0, prevClearanceReportItemIndex),
                {
                    ...nextClearanceReportItems[prevClearanceReportItemIndex],
                    ...updates,
                    updatedAt: new Date()
                },
                ...nextClearanceReportItems.slice(prevClearanceReportItemIndex + 1)
            ];
        } else {
            nextClearanceReportItems.push(updates);
        }

        orderClearanceReport(nextClearanceReportItems);

        handleClose();
        await axios.post(SERVER_PATH + '/data/clearance/create', postData)
        .then(res => {})
        .catch(err => {
            setClearanceItems(prevClearanceReportItems);
            floatingAlert(ERROR_MESSAGE_CHANGES_UNDONE, 'error');
        });
    };

    const clearForm = () => {
        setClearanceItem(defaultClearanceItem);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>{selectedClearanceItem?.description || 'Cadastrar direito'}</DialogTitle>
            <DialogContent>
                
                <IconButton
                    style={{position: 'absolute', top: 2, right: 2}}
                    onClick={handleClose}
                    size="large"><CloseIcon /></IconButton>

                {
                    (clearanceItem?.lastReportId) &&
                    <Box mb={2}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <Typography variant="body1">Este direito foi lançado no relatório anterior.</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                }

                <ClearanceTimeCodeScreenshot {...{ allowAnnotations: true, selectedClearanceItem: clearanceItem, setSelectedClearanceItem, selectedReport, setClearanceItems, windowOpen: open }} />

                <Box mb={2}>
                    {
                        clearanceItem &&
                        <ClearanceWindowRiskFields
                            open={open}
                            activeUserIsTrueOperator={activeUserIsTrueOperator}
                            selectedProjectTemplate={selectedProjectTemplate}
                            selectedReport={selectedReport}
                            clearanceItem={clearanceItem}
                            handleFieldChange={handleFieldChange}
                        />
                    }
                </Box>
            </DialogContent>
            {
                activeUserIsTrueOperator &&
                <DialogActions>
                    {
                        (selectedClearanceItem && (!clearanceItem?.itemNotInThisReport || activeUser?.type >= 9)) &&
                        <Grid item>
                            <Button startIcon={<DeleteIcon />} onClick={handleDeleteReportItemPress}>Excluir</Button>
                        </Grid>
                    }
                    <Button
                        disabled={!selectedReport}
                        variant="contained" color="primary" onClick={handleSubmit}
                    >Salvar</Button>
                </DialogActions>
            }
        </Dialog>
    );
};

export default ClearanceWindow;