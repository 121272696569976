import { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { Badge, Box, IconButton } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useAppCtxActiveUser } from '../context/SystemContext';
import { useSocket } from '../context/SocketContext';
import { updateStateChangeArrayItemWith_id } from '../utils/common';
import { SERVER_PATH } from '../utils/constants';
import NotificationsPopOver from './NotificationsPopOver';

function Notifications(){
    const ref = useRef();
    const activeUser = useAppCtxActiveUser();
    const {createSocketConnection} = useSocket();
    const socket = createSocketConnection();
    const [notifications, setNotifications] = useState(null);
    const [notificationsCounter, setNotificationsCounter] = useState(0);
    const [isOpen, setOpen] = useState(false);
    const userNotificationsReady = useRef(false);

    function onNotificationEvent(data){
        setNotifications(prevState => [...prevState, data]);
        setNotificationsCounter((state) => state + 1);
    }

    useEffect(() => {
        if(activeUser && !userNotificationsReady.current){
            const userNotifications = activeUser.notifications || [];
            setNotifications(userNotifications);
            setNotificationsCounter(userNotifications.length);
            userNotificationsReady.current = true;
        }
    }, [activeUser]);

    useEffect(() => {
        if(socket){
            socket.on('NEW NOTIFICATION', onNotificationEvent);
        }
        return () => {
            if(socket){
                socket.off('NEW NOTIFICATION', onNotificationEvent);
            }
        };
    }, []);

    useEffect(() => {
        if(isOpen){
            setTimeout(() => {
                const notificationsCopy = [...notifications];
                const notificationsCounterCopy = notificationsCounter;
    
                const unreadNotificationIds = [];
                const unreadNotifications = notifications.filter(notification => !notification.read);
                unreadNotifications.forEach(notification => {
                    unreadNotificationIds.push(notification._id);
                    updateStateChangeArrayItemWith_id({...notification, read: true, readAt: new Date()}, setNotifications);
                });
                setNotificationsCounter(0);
                if(unreadNotificationIds.length >= 1){
                    axios.post(SERVER_PATH + '/data/notifications/read', {ids: unreadNotificationIds}) 
                    .then((res) => {
                        
                    })
                    .catch((err) => {
                        setNotifications(notificationsCopy);
                        setNotificationsCounter(notificationsCounterCopy);
                    });
                }
            }, 1500);
        }
    }, [isOpen]);

    function handleOpen(){
        setOpen(true);
    }

    if(!notifications) return null;

    return (
        <Box mx={2}>
            <IconButton ref={ref} onClick={handleOpen} size="large">
                <Badge badgeContent={notificationsCounter} color="secondary">
                    <NotificationsIcon /> 
                </Badge>
            </IconButton>
            <NotificationsPopOver isOpen={isOpen} setOpen={setOpen} notifications={notifications} popOverRef={ref} />
        </Box>
    );
}

export default Notifications;