import { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';
import Modal from '../../../../../../components/Modal';
import MultipleAutocomplete from '../../../../../../components/MultipleAutocomplete';
import { ERROR_MESSAGE_UNKNOWN, SERVER_PATH, SUCCESS_MESSAGE_SAVED } from '../../../../../../utils/constants';
import { useAppCtxAPI, useAppCtxTemplateLists } from '../../../../../../context/SystemContext';
import axios from 'axios';

const ListsWindow = ({open, onClose, selectedList, templates}) => {
    const { setTemplateLists, toast } = useAppCtxAPI();
    const templateLists = useAppCtxTemplateLists();
    const [saving, setSaving] = useState(false);
    const [listName, set_listName] = useState('');
    const [listItems, set_listItems] = useState([]);

    useEffect(() => {
        if(selectedList){
            set_listName(selectedList.name);
            set_listItems(selectedList.list);
        } else {
            set_listName('');
            set_listItems([]);
        }
    }, [open]);
    
    const handleSubmit = async () => {
        setSaving(true);
        let postData = {
            name: listName,
            list: listItems.map(i => i._id)
        };
        if(selectedList) postData.currentList = selectedList._id;
        await axios.post(SERVER_PATH + '/data/operator/docs/templates/lists/save', postData)
        .then(res => {
            let currentClient = res.data;
            if(selectedList){
                let currentClientLists = currentClient.templateLists;
                if(currentClientLists){
                    let updatedList = currentClientLists.find(list => list._id == selectedList._id);
                    if(updatedList){
                        const listIndex = templateLists.findIndex(list => list._id == updatedList._id);
                        if(listIndex !== -1) setTemplateLists([
                            ...templateLists.slice(0, listIndex),
                            updatedList,
                            ...templateLists.slice(listIndex + 1)
                        ]);
                    }
                }
            } else {
                let currentClientLists = currentClient.templateLists;
                if(currentClientLists){
                    let newList = currentClientLists[currentClientLists.length - 1];
                    if(newList) setTemplateLists([...templateLists, newList]);
                }
            }
            toast(SUCCESS_MESSAGE_SAVED);
        })
        .catch(err => {
            toast(ERROR_MESSAGE_UNKNOWN);
        });
        setSaving(false);
        onClose();
    }
    
    return (
        <Modal open={open} onClose={onClose}>
            <Box p={2} style={{minWidth: 280, maxWidth: '98%', maxHeight: '98%', overflow: 'auto'}}>
                <Box mb={2}>
                    <Box>
                        <Typography variant="h6">{selectedList ? 'Alterar lista' : 'Nova lista'}</Typography>
                    </Box>
                </Box>
                <Box mb={2}>
                    <TextField
                        variant="standard"
                        fullWidth
                        label="Nome"
                        value={listName}
                        onChange={(e) => set_listName(e.target.value)} />
                </Box>
                <Box mb={2}>
                    <MultipleAutocomplete
                        label="Lista"
                        options={templates} getOptionLabel={(option) => `${option.name}${option.description ? ` (${option.description})` : ''}`}
                        value={listItems} onChange={(e, newValue) => set_listItems(newValue)}
                    />
                </Box>
                <Box style={{textAlign: 'center'}}>
                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={saving ? <CircularProgress size={20} /> : <SendIcon />}
                        onClick={handleSubmit}
                        disabled={saving}
                    >Enviar</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ListsWindow;