import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DescriptionIcon from '@mui/icons-material/Description';
import { formEffectTypes } from '../../../utils/constants';
import { useClient } from '../../../context/ClientContext';
import { useDocumentEditor } from '../../../pages/Operator/Docs/Templates/DocumentEditor/context/DocumentEditorContext';
import { sortByKey } from '../../../utils/filters';

const ListOption = ({label, onClick}) => {
    return (
        <Box>
            <Divider />
            <Box
                sx={{
                    '&:hover': {
                        backgroundColor: theme => theme.palette.action.hover
                    }
                }}
                mb={0.5} mt={0.5}
            >
                <Grid container alignItems="center" justifyContent="flex-end" spacing={1}>
                    <Grid xs>
                        <Typography variant="body2">{label}</Typography>
                    </Grid>
                    <Grid>
                        <IconButton size="small" onClick={onClick}><DescriptionIcon /></IconButton>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

const EditorEffect = (props) => {
    const { selectedEffect } = props;
    const { getListById } = useClient();
    const { handle_effectRadioOutputButtonClick, listOutputOptions } = useDocumentEditor(); 
    const defaultListValue = { name: '', list: [] };
    const [list, setList] = useState(defaultListValue);

    useEffect(() => {
        let currentList = {...defaultListValue};
        if(selectedEffect && selectedEffect.type === 'list' && selectedEffect.typeSpecifics === 'custom'){
            const foundList = getListById(selectedEffect.typeId);
            if(foundList){
                currentList.name = foundList.name;
                currentList.list = foundList.list.map(listItem => {
                    const output = selectedEffect.options.find(option => option.option == listItem.id);
                    return ({...listItem, priority: !!output});
                }).sort(sortByKey('key'));
            }
        }
        setList(currentList);
    }, [selectedEffect]);

    const getListOutputName = (listOutputValue) => {
        let name = '';
        let currentListOutput = listOutputOptions.find(output => output.value === listOutputValue);
        if(currentListOutput) name = currentListOutput.label;
        return name;
    };

    return (
        <Box>
            <Box mb={2}>
                <Typography variant="h3">{selectedEffect.label || selectedEffect.question}</Typography>
            </Box>
            <Box mb={1}>
                <Typography variant="body1"><strong>Tipo:</strong> {formEffectTypes.find(formEffectType => formEffectType.value === selectedEffect.type).label}</Typography>
            </Box>
            <Box mb={1}>
                <Typography variant="body1"><strong>Pergunta:</strong> {selectedEffect.question}</Typography>
            </Box>
            <Box mb={1}>
                <Typography variant="body1"><strong>Lista:</strong> {list.name} ({getListOutputName(selectedEffect.typeSpecifics)})</Typography>
            </Box>
            {
                selectedEffect.typeSpecifics === 'custom' &&
                <>
                    {
                        list.list
                        .sort((a, b) => !a.priority && b.priority ? 1 : (a.priority && !b.priority ? -1 : 0))
                        .map(option => (
                            <ListOption
                                key={option.id}
                                label={<span>{option.priority ? <span><strong>{option.key}</strong></span> : <span>{option.key} (opção vazia)</span>}</span>}
                                onClick={() => handle_effectRadioOutputButtonClick(selectedEffect.id, option.id)}
                            />
                        ))
                    }
                </>
            }
        </Box>
    )
};

export default EditorEffect;