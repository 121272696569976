import { useEffect, useMemo, useState } from 'react';

import { Button, ListItemIcon, ListItemText, MenuItem } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ClearIcon from '@mui/icons-material/Clear';
import DescriptionIcon from '@mui/icons-material/Description';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import TitleIcon from '@mui/icons-material/Title';

import ChangeTemplateView from './components/TemplateWindow';
import Table2 from '../../../../components/Table2/Table';
import Table2CustomTopToolbar from '../../../../components/Table2/components/Table2CustomTopToolbar';
import ViewBox from '../../../../components/ViewBox';
import { useAppCtxAPI } from '../../../../context/SystemContext';
import { useSocket, useSocketCtxConnectedAt } from '../../../../context/SocketContext';
import { useClientCtxActiveClient } from '../../../../context/ClientContext';
import { useDocumentsAPI, useDocumentsCtxTemplates } from '../../../../context/DocumentsContext';
import { useOperatorTemplatesCtxAPI, useOperatorTemplatesCtxVisibleTemplates } from './context/TemplatesContext';
import { serverRequest } from '../../../../utils/common';
import { sortByKey } from '../../../../utils/filters';

const Main = () => {
    const { floatingAlert, setLoading, handleNavigate, toast, updateOneOperators } = useAppCtxAPI();
    const { createSocketConnection, shouldUpdateTemplates } = useSocket();
    const socket = createSocketConnection();
    const socketConnectedAt = useSocketCtxConnectedAt();
    const activeClient = useClientCtxActiveClient();
    const { addTemplate, loadTemplates, updateTemplate } = useDocumentsAPI();
    const templates = useDocumentsCtxTemplates();
    const { setLoadingVisibleTemplates, setTemplateTitlePatternSelectedTemplate, setVisibleTemplates, showChangeTemplateView, showAddTemplateView } = useOperatorTemplatesCtxAPI();
    const { isLoadingVisibleTemplates, visibleTemplates } = useOperatorTemplatesCtxVisibleTemplates();
    const [searchType, setSearchType] = useState('default');

    useEffect(() => {
        if(shouldUpdateTemplates){
            const shouldIncludeDisabled = searchType === 'disabled';
            loadTemplates(shouldIncludeDisabled);
        }
    }, [socketConnectedAt, searchType]);
    
    useEffect(() => {
        if(templates){
            let currentTemplates = [...templates];
            if(searchType === 'disabled'){
                currentTemplates = currentTemplates.filter(template => template.disabled);
            }
            currentTemplates = currentTemplates.sort(sortByKey('name'));
            setVisibleTemplates(currentTemplates);
        }
    }, [searchType, templates]);

    const handleListsClick = () => {
        handleNavigate(`/${activeClient.shortName}/juridico/documentos/matrizes/listas`);
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: 'name',
                filterVariant: 'text',
                header: 'Matriz',
            },
            {
                accessorKey: 'description',
                filterVariant: 'text',
                header: 'Descrição',
            },
            {
                id: 'updatedAt', 
                accessorFn: (row) => row.updatedAt ? new Date(row.updatedAt) : null, //convert to Date for sorting and filtering
                filterVariant: 'date',
                filterFn: 'greaterThan',
                sortingFn: 'datetime',
                Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(), //render Date as a string
                header: 'Última alteração',
                grow: false,
                size: 240,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },                            
            },
        ],
        [],
    );

    const renderTopToolbar = ({ table }) => {
        
        const handleAddTemplateButtonClick = () => {
            showAddTemplateView();
        };
    
        return (
            <Table2CustomTopToolbar
                buttons={[
                    <Button variant="contained" minWidth={157.48} onClick={handleAddTemplateButtonClick} startIcon={<AddIcon />}>Nova matriz</Button>,
                    <Button variant="outlined" minWidth={107.4} onClick={handleListsClick} endIcon={<ArrowForwardIcon />}>Listas</Button>
                ]}
                table={table}
            />
        );
    };

    const renderRowActionMenuItems = ({ closeMenu, row }) => {
        const selectedTemplate = row.original;

        const changeTemplateKey = async (template, key, newValue) => {
            setLoadingVisibleTemplates(true);
            let templateCopy = {...template};
            let updates = {[key]: newValue};
            let updatedTemplate = {...template, ...updates};
            updateTemplate(updatedTemplate);
            try {
                await updateOneOperators('Templates', template._id, updates);
                socket.emit('TEMPLATE UPDATED', { activeClientId: activeClient._id, updatedTemplate });
            } catch (error) {
                updateTemplate(templateCopy);;
            }
            setLoadingVisibleTemplates(false);
        };

        const handleChangeTemplateSettingsClick = () => {
            showChangeTemplateView(selectedTemplate);
            closeMenu();
        };

        const handleChangeTemplateClick = async () => {
            closeMenu();
            handleNavigate(`/${activeClient.shortName}/juridico/documentos/matrizes/documento?id=${selectedTemplate._id}`);
        };

        const handleChangeTemplateTitlePatternClick = () => {
            closeMenu();
            // setTemplateTitlePatternSelectedTemplate(selectedTemplate);
            handleNavigate(`/${activeClient.shortName}/juridico/documentos/matrizes/titulo?id=${selectedTemplate._id}`);
        };

        const handleCloneTemplateClick = async () => {
            closeMenu();
            setLoading(true);
            toast('Duplicando... aguarde alguns segundos...');
            try {
                //?todo serverrequest hook
                const newTemplate = await serverRequest({ path: '/data/operator/docs/templates/clone', method: 'post', data: {templateId: selectedTemplate._id} })
                addTemplate(newTemplate);
                socket.emit('TEMPLATE CREATED', { activeClientId: activeClient._id, createdTemplate: newTemplate });
                floatingAlert(`Uma cópia da matriz "${selectedTemplate.name}" com o nome "${newTemplate.name}" foi criada.`);
            } catch (error) {
                console.error(error);
            }
            setLoading(false);
        };

        const handleDisableTemplateClick = () => {
            changeTemplateKey(selectedTemplate, 'disabled', true);
            closeMenu();
        };

        return [
            <MenuItem
                key={0}
                onClick={handleChangeTemplateSettingsClick}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <EditIcon />
                </ListItemIcon>
                <ListItemText primary="Alterar configurações gerais" />
            </MenuItem>,
            <MenuItem
                key={1}
                onClick={handleChangeTemplateClick}
            >
                <ListItemIcon><DescriptionIcon /></ListItemIcon>
                <ListItemText primary="Alterar matriz" />
            </MenuItem>,
            <MenuItem
                key={2}
                onClick={handleChangeTemplateTitlePatternClick}
                sx={{ m: 0 }}
            >
                <ListItemIcon><TitleIcon /></ListItemIcon>
                <ListItemText primary="Alterar padrão de título" />
            </MenuItem>,
            <MenuItem
                key={3}
                onClick={handleCloneTemplateClick}
            >
                <ListItemIcon><FileCopyIcon /></ListItemIcon>
                <ListItemText primary="Duplicar matriz" secondary="Nova matriz com uma cópia do documento e formulário" />
            </MenuItem>,
            <MenuItem
                key={4}
                onClick={handleDisableTemplateClick}
            >
                <ListItemIcon><ClearIcon /></ListItemIcon>
                <ListItemText primary="Desativar" secondary="A matriz só poderá ser reativada pelo administrador do sistema" />
            </MenuItem>
        ]
    };

    return (
        <ViewBox additionalSx={[{
                // height: [`calc(100% - ${86}px)`, `calc(100% - ${86}px)`, `calc(100% - ${110.4}px)`]
                height: '100%'
            }]}>
            
            <Table2
                columns={columns}
                data={visibleTemplates}
                enablePagination={false}
                enableRowVirtualization
                initialStateDensity="comfortable"
                isLoading={isLoadingVisibleTemplates}
                renderRowActionMenuItems={renderRowActionMenuItems}
                renderTopToolbar={renderTopToolbar}
            />
            
            <ChangeTemplateView />

        </ViewBox>
    );
}

export default Main;