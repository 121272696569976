import moment from 'moment';
import { getUserNameById } from '../utils/common';
import { Box, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FlagIcon from '@mui/icons-material/Flag';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CustomSelect from '../pages/Client/Docs/components/CustomSelect';
import CustomText from '../pages/Client/Docs/components/CustomText';
import DataListItem from './DataListItem';
import DocumentFlag from './DocumentFlag';
import Tooltip from './Tooltip';
import VersionRowMainColumn from './VersionRowMainColumn';
import useWindowSize from '../hooks/useWindowSize';

const DocumentListItem = ({
    activeUserIsManager,
    activeUserIsOperator,
    boxStyle,
    setChangeStarredInfoList,
    setDocuments,
    comment,
    noHtmlComment,
    docsAlerts,
    documentFolder,
    eSignature,
    companyName,
    folderCustomDocumentFields,
    folderName,
    groupName,
    handleActionMenuOpen,
    handleFlagDocumentButtonClick,
    listDocument,
    projects,
    setSelectedDocument,
    showCompany,
    templates,
    updateDocument,
    useGroups,
    users
}) => {
    const { windowWidth } = useWindowSize();

    return (
        <DataListItem dense boxStyle={boxStyle} showMoreOptionsFab handleMoreOptionsShownOnMouseOverFab={(e) => handleActionMenuOpen(e, listDocument)}>
            <Box mb={0.5}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={3} sm={2} md={2}>
                        <Tooltip text={moment(listDocument.timestamp).format('D/M/YY H:mm')} enterDelay={400}>
                            <Typography variant="body2" noWrap>{moment(listDocument.timestamp).format('D/M/YY H:mm')}</Typography>
                        </Tooltip>
                    </Grid>
                    {
                        (windowWidth && windowWidth >= 960) &&
                        <Grid item xs={3} sm={3} md={2}>
                            <Tooltip text={listDocument.userName ? `${listDocument.userName}${!listDocument.user ? ` (${listDocument.userEmail})` : ''}` : getUserNameById(users, listDocument.user, true) || '(Não especificado)'} enterDelay={400}>
                                <Grid container alignItems="center" wrap="nowrap" style={{width: '100%'}}>
                                    <Grid item style={{width: 24}}>
                                        <PersonOutlineOutlinedIcon fontSize="small" style={{color: 'rgba(0, 0, 0, 0.54)'}} />
                                    </Grid>
                                    <Grid item style={{width: 'calc(100% - 24px)'}}>
                                        <Typography variant="body2" noWrap>{listDocument.userName ? `${listDocument.userName}${!listDocument.user ? ` (${listDocument.userEmail})` : ''}` : getUserNameById(users, listDocument.user, true) || '(Não especificado)'}</Typography>
                                    </Grid>
                                </Grid>
                            </Tooltip>
                        </Grid>
                    }
                    <Grid item xs={4} sm={5} md={4}>
                        <Tooltip text={`${showCompany ? companyName : ''}${folderName ? `${showCompany && companyName ? ' | ' : ''}${folderName}` : ''}${useGroups && groupName ? `${folderName ? ' | ' : ''}${groupName}` : ''}`} enterDelay={400}>
                            <Grid container alignItems="center" wrap="nowrap" style={{width: '100%'}}>
                                <Grid item style={{width: 24}}>
                                    <FolderOutlinedIcon fontSize="small" style={{color: 'rgba(0, 0, 0, 0.54)'}} />
                                </Grid>
                                <Grid item style={{width: 'calc(100% - 24px)'}}>
                                    <Grid item container alignItems="center" wrap="nowrap" style={{width: '100%'}}>
                                        {
                                            showCompany &&
                                            <>
                                                <Grid item style={{maxWidth: `calc(${useGroups ? '33% - 14px' : '50% - 14px'})`}}>
                                                    <Typography variant="body2" noWrap>{companyName}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2" noWrap style={{width: 14}}>&nbsp;|&nbsp;</Typography>
                                                </Grid>
                                            </>
                                        }
                                        <Grid item style={{maxWidth: `calc(${showCompany && useGroups ? '34%' : showCompany || useGroups ? '50%' : '100%'})`}}>
                                            <Typography variant="body2" noWrap>{folderName || '(Sem pasta)'}</Typography>
                                        </Grid>
                                        {
                                            useGroups &&
                                            <>
                                                <Grid item>
                                                    <Typography variant="body2" noWrap style={{width: 14}}>&nbsp;|&nbsp;</Typography>
                                                </Grid>
                                                <Grid item style={{maxWidth: `calc(${showCompany ? '33% - 14px' : '50% - 14px'})`}}>
                                                    <Typography variant="body2" noWrap>{groupName}</Typography>
                                                </Grid>
                                            </>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={5} sm={5} md={4}>
                        <Grid container spacing={1} alignItems="center" wrap="nowrap" style={{width: '100%'}}>
                            {
                                (listDocument.flag !== (activeUserIsOperator ? 1 : 2)) &&
                                <Grid item style={{width: 120}}>
                                    <Button variant="outlined" size="small"
                                        onClick={() => handleFlagDocumentButtonClick(listDocument)}
                                        startIcon={<FlagIcon />}
                                    >Sinalizar</Button>
                                </Grid>
                            }
                            {
                                !!listDocument.flag &&
                                <Grid item style={{width: listDocument.flag !== (activeUserIsOperator ? 1 : 2) ? `calc(100% - 120px)` : '100%'}}>
                                    <DocumentFlag 
                                        activeUserIsOperator={activeUserIsOperator}
                                        data={{
                                            flag: listDocument.flag,
                                            flaggedAt: listDocument.flaggedAt,
                                            flaggedBy: listDocument.flaggedBy,
                                            flaggedByUserName: listDocument.flaggedByName
                                        }}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Divider />
            <Box mt={0.5}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs>
                        <VersionRowMainColumn
                            activeUserIsManager={activeUserIsManager}
                            activeUserIsOperator={activeUserIsOperator}
                            comment={comment}
                            data={{
                                set_changeStarredInfoList: setChangeStarredInfoList,
                                setDocs: setDocuments,
                                docsAlerts,
                                eSignature,
                                folders: projects,
                            }}
                            documentFolder={documentFolder}
                            item={listDocument}
                            noHtmlComment={noHtmlComment}
                            project={documentFolder}
                            setSelectedDocument={setSelectedDocument}
                            templates={templates}
                            updateDocument={updateDocument}

                            users={users}
                        />
                    </Grid>
                    {
                        documentFolder?.showFolderUserDocumentsCustomFields &&
                        <Grid item xs={12} sm={12} md={2} container spacing={1} alignItems="center">
                            {
                                folderCustomDocumentFields.map((col, j) => (
                                    <Grid item xs={12} sm={6} md={12} key={j}>
                                        <Box>
                                            <Typography variant="h6">{col.name}</Typography>
                                        </Box>
                                        {
                                            col.type === 'text' &&
                                            <CustomText col={col} doc={listDocument} setDocuments={setDocuments} users={users} />
                                        }
                                        {
                                            col.type === 'select' &&
                                            <CustomSelect col={col} doc={listDocument} setDocuments={setDocuments} users={users} />
                                        }
                                    </Grid>
                                ))
                            }
                        </Grid>
                    }
                </Grid>
            </Box>
        </DataListItem>
    );
};

export default DocumentListItem;