//?todo delete

import moment from 'moment';
import { Avatar, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AttachmentIcon from '@mui/icons-material/Attachment';
import MovieIcon from '@mui/icons-material/Movie';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import NoResults from '../NoResults';
import RiskLevel from './RiskLevel';
import TinyTable from '../TinyTable';
import Tooltip from '../Tooltip';
import { getGroupNameById, getGroupsByFolderId, getUserImageById, getUserNameById } from '../../utils/common';
import { CLEARANCE_RISK_OPTIONS } from '../../utils/constants';

const classes = {
    smallAvatar: {
        width: '24px',
        height: '24px',
    }
};

const ClearanceQuestionsList = ({
    activeUserIsOperator, getQuestions, handleBodyRow, handleBodyRowContextMenu, handleOperatorMenuButtonPress, handleQuestionDoneButtonPress, list,
    numberOfApprovalsRequired, projects, selectedFolder, users,
    updatedAt
}) => {

    const handleColumnTitleClick = (columnTitleKey) => {
        getQuestions(1, columnTitleKey);
    };
    
    const tableHead = [
        { content: '#', justify: 'center' },
        { content: 'Em' },
        { content: 'Por' },
    ];
    if(activeUserIsOperator) tableHead.push({ content: 'Pasta' });
    if(selectedFolder?.useGroups) tableHead.push({ content: 'Grupo' });
    tableHead.push({ content: 'Assunto', onClick: () => handleColumnTitleClick('subject') });
    tableHead.push({ content: 'Última resposta' });
    tableHead.push({ content: 'Situação' });
    tableHead.push({ content: 'Direito', onClick: () => handleColumnTitleClick('rightTypeDescription') });
    tableHead.push({ content: 'Condição' });
    tableHead.push({ content: 'Risco', onClick: () => handleColumnTitleClick('riskLevelDescription') });
    if(activeUserIsOperator) tableHead.push({ content: 'Responsável', justify: 'center' });
    
    if(list){

        if(list.length === 0){
            return (
                <NoResults text="Nenhuma consulta." />
            );
        }

        return (
            <TinyTable
                head={tableHead}
                body={
                    list
                    .map(clearanceQuestion => {
                        let groupId = clearanceQuestion.groupId;
                        let folderGroups = [];
                        let groupName = '';
                        if(projects && groupId){
                            folderGroups = getGroupsByFolderId(clearanceQuestion.folderId, projects);
                            groupName = getGroupNameById(groupId, folderGroups);
                        }
                        const questionComments = clearanceQuestion.comments;
                        const lastComment = questionComments[questionComments.length - 1];
                        const lastDate = lastComment.createdAt;

                        let highlight = false;
                        let highlightColor = '';
                        if(activeUserIsOperator && !clearanceQuestion.done){
                            const deliveryIsVeryLate = 24;
                            const deliveryIsLate = 12;
                            const thisMoment = moment();
                            const currentDayOfWeek = thisMoment.day();
                            let addHours = 0;
                            if(currentDayOfWeek === 5){
                                addHours = thisMoment.hours();
                            } else if(currentDayOfWeek === 6){
                                addHours = 24 + thisMoment.hours();
                            } else if(currentDayOfWeek === 1 || currentDayOfWeek === 2){
                                addHours = 48;
                            }
                            if(thisMoment.diff(moment(lastDate), 'hours') >= (deliveryIsVeryLate + addHours)){
                                highlight = true;
                                highlightColor = '#ffcdd2';
                            } else if(thisMoment.diff(moment(lastDate), 'hours') >= (deliveryIsLate + addHours)){
                                highlight = true;
                                highlightColor = '#fff9c4';
                            }
                        }

                        const lastMessage = lastComment?.comment ? (`${lastComment.comment.substring(0, 200)}${lastComment.comment.length > 200 ? '...' : ''}`) : '';
                        const rightTypeDescription = lastComment?.rightTypeDescription || '';
                        const useTypeDescription = lastComment?.useTypeDescription ? lastComment.useTypeDescription.replace(/^Risco.*?\s-\s/, '') : '';
                        
                        let currentRiskLevel = '';
                        let riskOption = null;
                        const commentsWithRiskLevel = questionComments.filter(comment => comment.riskLevel);
                        if(commentsWithRiskLevel.length >= 1){
                            const lastCommentWithRiskLevel = commentsWithRiskLevel[commentsWithRiskLevel.length - 1];
                            currentRiskLevel = lastCommentWithRiskLevel.riskLevel;
                        }
                        if(currentRiskLevel){
                            riskOption = CLEARANCE_RISK_OPTIONS.find(riskOption => riskOption.value === currentRiskLevel);
                        }

                        const hasFiles = questionComments.find(comment => comment.files && comment.files.length >= 1);
                        
                        const columns = [
                            // Solicitação 
                            { content: <Typography variant="body1"><strong>{clearanceQuestion.referenceNumber}</strong></Typography>, justify: 'center' },
                            { content: <Typography variant="body2">{moment(clearanceQuestion.createdAt).format('D/M/YY H:mm:ss')}</Typography> },
                            { content: <Typography variant="body2">{clearanceQuestion.createdByFullName || getUserNameById(users, clearanceQuestion.createdBy, true)}</Typography> },
                        ];
                        // Pasta
                        if(activeUserIsOperator){
                            columns.push({ content: <Typography variant="body2">{clearanceQuestion.folderName}</Typography> });
                        }
                        // Grupo
                        if(selectedFolder?.useGroups){
                            columns.push({ content: <Typography variant="body2">{groupId ? groupName : ''}</Typography> });
                        }
                        // Assunto
                        columns.push({
                            content:
                                <Grid container spacing={1} alignItems="center" wrap="nowrap">
                                    {
                                        clearanceQuestion.isReportItemComment &&
                                        <Grid item>
                                            <MovieIcon />
                                        </Grid>
                                    }
                                    <Grid item xs>
                                        <Typography variant="body2">{clearanceQuestion.subject}</Typography>
                                    </Grid>
                                    {
                                        hasFiles &&
                                        <Grid item>
                                            <AttachmentIcon />
                                        </Grid>
                                    }
                                </Grid>
                        });
                        // Última resposta
                        columns.push({
                            content:
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs>
                                        <Typography variant="body2">&ldquo;<em>{lastMessage}</em>&rdquo; ({lastComment?.createdByFullName || getUserNameById(users, lastComment?.createdBy, true)})</Typography>
                                    </Grid>
                                </Grid>
                        });
                        // Situação
                        columns.push({
                            content:
                                <Grid container spacing={1} alignItems="center" wrap="nowrap">
                                    <Grid item xs>
                                        <Typography variant="body2">{!clearanceQuestion.done ? 'Aguardando retorno' : 'Consulta respondida'}</Typography>
                                    </Grid>
                                    {
                                        (activeUserIsOperator && !clearanceQuestion.done) &&
                                        <Grid item>
                                            {
                                                clearanceQuestion.savingDone
                                                ? <CircularProgress size={20} />
                                                : <IconButton size="small" onClick={(e) => handleQuestionDoneButtonPress(e, clearanceQuestion)}><AssignmentTurnedInIcon /></IconButton>
                                            }
                                        </Grid>
                                    }
                                </Grid>
                        });
                        // Assunto
                        columns.push({ content: <Typography variant="body2">{rightTypeDescription}</Typography> });
                        // Condição
                        columns.push({ content: <Typography variant="body2">{useTypeDescription}</Typography> });
                        // Risco
                        const riskLevelApprovalsRequired = currentRiskLevel && numberOfApprovalsRequired ? numberOfApprovalsRequired[currentRiskLevel] : 0;
                        const itemApprovals = clearanceQuestion.numberOfApprovals || 0;
                        columns.push({
                            content:
                                <Grid container spacing={1} alignItems="center" wrap="nowrap">
                                    <Grid item xs>
                                        <RiskLevel riskLevel={riskOption} />
                                    </Grid>
                                    {
                                        (currentRiskLevel && riskLevelApprovalsRequired > 0) &&
                                        <Grid item>
                                            {
                                                itemApprovals < 0
                                                ? <ThumbDownIcon color="secondary" />
                                                : itemApprovals === 0
                                                ? null
                                                : itemApprovals >= riskLevelApprovalsRequired
                                                ? <ThumbUpIcon color="primary" />
                                                : <ThumbUpAltOutlinedIcon color="disabled" />
                                            }
                                        </Grid>
                                    }
                                </Grid>
                            
                        });
                        // Responsável
                        if(activeUserIsOperator){
                            columns.push({
                                content: clearanceQuestion.operatorId
                                    ?
                                        (
                                            clearanceQuestion.isReportItemComment
                                            ?
                                            <Tooltip text={clearanceQuestion.operatorFullName || getUserNameById(users, clearanceQuestion.operatorId)}>
                                                <Avatar sx={classes.smallAvatar} alt={clearanceQuestion.operatorFullName || getUserNameById(users, clearanceQuestion.operatorId)} src={`/uploads/avatars/${clearanceQuestion.operatorImg || getUserImageById(users, clearanceQuestion.operatorId)}`} />
                                            </Tooltip>
                                            :
                                            <IconButton size="small" color="primary"
                                                onClick={(e) => handleOperatorMenuButtonPress(e, clearanceQuestion)}
                                            >
                                                <Tooltip text={clearanceQuestion.operatorFullName || getUserNameById(users, clearanceQuestion.operatorId)}>
                                                    <Avatar sx={classes.smallAvatar} alt={clearanceQuestion.operatorFullName || getUserNameById(users, clearanceQuestion.operatorId)} src={`/uploads/avatars/${clearanceQuestion.operatorImg || getUserImageById(users, clearanceQuestion.operatorId)}`} />
                                                </Tooltip>
                                            </IconButton>
                                        )
                                    :
                                        <IconButton size="small" color="primary"
                                            onClick={(e) => handleOperatorMenuButtonPress(e, clearanceQuestion)}
                                        >
                                            <Avatar sx={classes.smallAvatar} alt={'Escolher revisor'} />
                                        </IconButton>,
                                justify: 'center'
                            });
                        }
                        return ({
                            ...clearanceQuestion,
                            columns,
                            highlight,
                            highlightColor
                        })
                    })
                }
                handleBodyRow={handleBodyRow}
                handleBodyRowContextMenu={handleBodyRowContextMenu}
                handleMoreOptionsShownOnMouseOverFab={handleBodyRowContextMenu}
                updatedAt={updatedAt}
            />
        );

    }

    return null;
};

export default ClearanceQuestionsList;