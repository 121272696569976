import { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Typography } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import Autocomplete from '../Autocomplete';
import CenteredCircularProgress from '../CenteredCircularProgress';
import FilesField from '../FilesField';
import Input from '../Input';
import Select from '../Select';
import UserComment from '../UserComment';
import { useAppCtxAPI, useAppCtxActiveUser } from '../../context/SystemContext';
import { updateStateChangeArrayItemWith_id } from '../../utils/common';
import { CLEARANCE_RISK_OPTIONS, SERVER_PATH } from '../../utils/constants';
import { sortByKey } from '../../utils/filters';
import axios from 'axios';

const classes = {
    closeButton: {
        position: 'absolute',
        right: '8px',
        top: '8px',
        color: theme => theme.palette.grey[500],
    },
};

const ReplyQuestionWindow = ({open, onClose, activeUserIsOperator, authorityRiskLevel, handleCommentInputKeyPress, numberOfApprovalsRequired, selectedQuestion, selectedTemplate, setQuestions, setVisibleImageUrl, actionCallback}) => {
    const { toast } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const filesFieldId = 'filesField';
    const [loading, setLoading] = useState(false);
    const [replyComment, setReplyComment] = useState('');
    const [filesList, set_filesList] = useState([]);
    const [selectedRightType, setSelectedRightType] = useState(null);
    const [useTypeOptions, setUseTypeOptions] = useState([]);
    const [selectedUseType, setSelectedUseType] = useState('');
    const [selectedRiskLevel, setSelectedRiskLevel] = useState('');
    const [questionName, setQuestionName] = useState('');
    const [riskApproved, setRiskApproved] = useState('no');
    const riskLevelNumbers = {
        one: 1,
        two: 2,
        three: 3,
        four: 4
    };

    useEffect(() => {
        clearForm();
        if(open && selectedQuestion){
            let questionComments = selectedQuestion.comments;
            let currentRightType = null;
            let currentUseType = '';
            let currentRiskLevel = '';
            const commentsWithRiskLevel = questionComments.filter(comment => comment.riskLevel);
            if(commentsWithRiskLevel.length >= 1){
                const lastCommentWithRiskLevel = commentsWithRiskLevel[commentsWithRiskLevel.length - 1];
                currentRightType = lastCommentWithRiskLevel.rightType;
                currentUseType = lastCommentWithRiskLevel.useType;
                currentRiskLevel = lastCommentWithRiskLevel.riskLevel;
            }
            setSelectedRightType(currentRightType);
            setSelectedUseType(currentUseType);
            setSelectedRiskLevel(currentRiskLevel);

            let currentQuestionName = '';
            if(selectedQuestion.episode){
                currentQuestionName += `Ep. ${selectedQuestion.episode}`;
            }
            if(selectedQuestion.scriptTreatment){
                currentQuestionName += `${currentQuestionName ? ' - ' : ''}Tratamento ${selectedQuestion.scriptTreatment}`;
            }
            if(selectedQuestion.scene){
                currentQuestionName += `${currentQuestionName ? ' - ' : ''}Cena ${selectedQuestion.scene}`;
            }
            if(selectedQuestion.subject){
                currentQuestionName += `${currentQuestionName ? ' - ' : ''}${selectedQuestion.subject}`;
            }
            setQuestionName(currentQuestionName);

            let currentRiskApproved;
            if(activeUser){
                const approvals = selectedQuestion.approvals;
                const activeUserApprovals = approvals.filter(approval => approval.userId === activeUser._id);
                if(activeUserApprovals.length >= 1){
                    const activeUserLastApproval = activeUserApprovals[activeUserApprovals.length - 1];
                    currentRiskApproved = activeUserLastApproval.approval;
                }
            }
            setRiskApproved(currentRiskApproved || 'withholdOpinion');
        }
    }, [activeUser, open, selectedQuestion]);

    useEffect(() => {
        if(selectedQuestion && (selectedTemplate || selectedQuestion.template) && selectedRightType){
            const itemType = (selectedTemplate || selectedQuestion.template).rightTypes.find(rightType => rightType._id === selectedRightType);
            const riskLevels = itemType.riskLevels;
            let options = [];
            for(const riskLevelKey in riskLevels){
                const riskLevel = riskLevels[riskLevelKey];
                const riskOption = CLEARANCE_RISK_OPTIONS.find(riskOption => riskOption.value === riskLevelKey);
                options = [...options, ...riskLevel.map(item => ({value: item._id, label: `Risco ${riskOption.label} - ${item.name}` , riskLevel: riskLevelKey})).sort(sortByKey('label'))];
            }
            setUseTypeOptions(options);
        }
    }, [selectedQuestion, selectedTemplate, selectedRightType]);

    useEffect(() => {
        if(open && activeUser && selectedQuestion && selectedQuestion.isReportItemComment){
            const comments = selectedQuestion.comments;
            const unreadComments = comments.filter(comment => !comment.readBy.includes(activeUser._id) && !!comment._id);
            if(unreadComments.length >= 1){
                markCommentsAsRead(unreadComments.map(comment => comment._id));
            }
        }
    }, [open]);

    const markCommentsAsRead = (comments) => {
        axios.post(SERVER_PATH + '/data/clearance/comments/read', { reportItemId: selectedQuestion.reportItemId, comments })
        .then(res => {
            comments.forEach(() => {
                updateStateChangeArrayItemWith_id({
                    ...selectedQuestion,
                    readBy: [...selectedQuestion.readBy, activeUser._id]
                }, setQuestions);
            });
        })
        .catch(err => {});
    }

    const handleSubmit = async () => {
        setLoading(true);

        let formData = new FormData();
        formData.append('folderId', selectedQuestion.folderId);
        formData.append('comment', replyComment);
        formData.append('rightType', selectedRightType || '');
        formData.append('rightTypeDescription', selectedRightType ? (selectedTemplate || selectedQuestion.template).rightTypes.find(rightType => rightType._id === selectedRightType)?.name : '');
        formData.append('useType', selectedUseType);
        formData.append('useTypeDescription', selectedUseType ? useTypeOptions.find(option => option.value === selectedUseType)?.label : '');
        formData.append('riskLevel', selectedRiskLevel);
        formData.append('riskLevelDescription', selectedRiskLevel ? CLEARANCE_RISK_OPTIONS.find(riskOption => riskOption.value === selectedRiskLevel)?.label : '');
        if(selectedQuestion.firstReplyDeliveredByOperator && selectedRiskLevel && numberOfApprovalsRequired && numberOfApprovalsRequired[selectedRiskLevel] > 0 && authorityRiskLevel > 0 && authorityRiskLevel >= riskLevelNumbers[selectedRiskLevel]){
            if(riskApproved !== 'withholdOpinion'){
                formData.append('riskApproved', riskApproved);
            }
        }

        let questionId = selectedQuestion._id;

        if(selectedQuestion.isReportItemComment){
            questionId = selectedQuestion.reportItemId;
            formData.append('isReportItemComment', true);
        }
        
        const filesInput = document.getElementById('filesField');
        if(filesList.length >= 1){
            let files = filesInput.files;
            if(files.length > 0){
                for (let i = 0; i < files.length; i++) {
                    formData.append('file' + i, files[i]);
                }
            }
        }
        await axios.post(SERVER_PATH + `/data/clearance/question/${questionId}/reply`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
        .then(res => {
            clearForm();
            if(filesList.length >= 1){
                if(filesInput) filesInput.value = '';
                set_filesList([]);
            }
            onClose();
            toast('Sua resposta foi enviada.');
            if(setQuestions){
                const updatedClearanceQuestion = res.data;
                updateStateChangeArrayItemWith_id({ ...updatedClearanceQuestion, updatedAt: new Date() }, setQuestions);
            }
            if(actionCallback) actionCallback();
        })
        .catch(err => {
            toast(err.response.data);
            if(filesList.length >= 1){
                if(filesInput) filesInput.value = '';
                set_filesList([]);
            }
        });
        setLoading(false);
    }

    // const handleCancel = async () => {
    //     const confirm = window.confirm('Tem certeza que quer deletar esse registro?');
    //     if(confirm){
    //         const clearanceQuestionsCopy = [...clearanceQuestions];
    //         try {
    //             updateStateDeleteArrayItemBy_id(selectedQuestion._id, setClearanceQuestions);
    //             const updates = {
    //                 deleted: true,
    //                 deletedAt: new Date(),
    //                 deletedBy: activeUser._id
    //             };
    //             await updateOneTrueOperators('ClearanceQuestions', selectedQuestion._id, updates);
    //         } catch (error) {
    //             setClearanceQuestions(clearanceQuestionsCopy);
    //             setNotification({ severity: 'error', message: ERROR_MESSAGE_CHANGES_UNDONE });
    //         }
    //     }
    // };

    const handleApproveRiskButtonPress = (newValue) => {
        setRiskApproved(newValue);
    };

    const clearForm = () => {
        setQuestionName('');
        set_filesList([]);
        setReplyComment('');
        setSelectedRightType(null);
        setUseTypeOptions([]);
        setSelectedUseType('');
        setSelectedRiskLevel('');
    };

    const handleRightTypeChange = (newValue) => {
        setSelectedRightType(newValue);
        setSelectedUseType('');
        setSelectedRiskLevel('');
    };

    const handleUseTypeChange = (newValue) => {
        setSelectedUseType(newValue);
        const useType = useTypeOptions.find(option => option.value === newValue);
        setSelectedRiskLevel(useType.riskLevel);
    };

    const triggerFileInputClick = () => {
        const fileInput = document.getElementById(filesFieldId);
        if(fileInput) fileInput.click();
    };

    return (
        <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}
            // TransitionComponent={Transition}
            // keepMounted
            fullWidth
            maxWidth="md"
            // fullScreen
        >
            <DialogTitle id="simple-dialog-title">
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs>
                        <Typography variant="h6">{!!activeUser ? 'Responder: ' : ''}{questionName}</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton
                            sx={classes.closeButton}
                            aria-label="close"
                            onClick={onClose}
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                {
                    (!selectedQuestion || (!selectedTemplate && !selectedQuestion.template))
                    ? <CenteredCircularProgress />
                    :
                    <Box mb={2}>
                        {
                            selectedQuestion.comments.map((comment, commentIndex) => {
                                const commentFiles = comment.files;
                                const hasFiles = commentFiles && commentFiles.length >= 1;
                                return (
                                    <Box key={commentIndex} mb={1}>
                                        <UserComment
                                            comment={comment}
                                            commentFiles={commentFiles}
                                            hasFiles={hasFiles}
                                            setVisibleImageUrl={setVisibleImageUrl}
                                            userImage={comment.createdByImg}
                                            userName={comment.createdByFullName}
                                        />
                                        <Divider />
                                    </Box>
                                )
                            })
                        }
                        {
                            !!activeUser &&
                            <Box>
                                {
                                    (selectedQuestion.firstReplyDeliveredByOperator && selectedRiskLevel && numberOfApprovalsRequired && numberOfApprovalsRequired[selectedRiskLevel] > 0 && authorityRiskLevel > 0 && authorityRiskLevel >= riskLevelNumbers[selectedRiskLevel]) &&
                                    <Box mb={1}>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item xs={9} sm={8} md={7}>
                                                <Select
                                                    label="Deseja aprovar o risco?"
                                                    options={[
                                                        {value: 'withholdOpinion', label: 'Não responder (aguardar informações)'},
                                                        {value: 'yes', label: <Grid container spacing={1} alignItems="center"><Grid item><ThumbUpAltOutlinedIcon /></Grid><Grid item xs>Aprovar o risco</Grid></Grid>},
                                                        {value: 'no', label: <Grid container spacing={1} alignItems="center"><Grid item><ThumbDownAltOutlinedIcon /></Grid><Grid item xs>Reprovar o risco</Grid></Grid>}
                                                    ]}
                                                    value={riskApproved} onChange={(e) => handleApproveRiskButtonPress(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <IconButton size="small" onClick={() => handleApproveRiskButtonPress('yes')}>
                                                    <ThumbUpAltOutlinedIcon />
                                                </IconButton>
                                            </Grid>
                                            <Grid item>
                                                <IconButton size="small" onClick={() => handleApproveRiskButtonPress('no')}>
                                                    <ThumbDownAltOutlinedIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                }
                                <Box mb={1}>
                                    <Input
                                        placeholder="Sua resposta"
                                        autoFocus fullWidth multiline minRows={5} maxRows={20}
                                        value={replyComment} onChange={(e) => setReplyComment(e.target.value)}
                                        onKeyPress={handleCommentInputKeyPress}
                                    />
                                </Box>
                                <FilesField
                                    attachFileButtonComponent="none"
                                    multiple
                                    accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*"
                                    filesList={filesList} setFilesList={set_filesList}
                                />
                                {
                                    (activeUserIsOperator && (selectedTemplate || selectedQuestion.template)) &&
                                    <Box mt={1}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Autocomplete
                                                    label="Direito envolvido"
                                                    options={(selectedTemplate || selectedQuestion.template).rightTypes.sort(sortByKey('name')).map(rightType => rightType._id)}
                                                    getOptionLabel={(option) => (selectedTemplate || selectedQuestion.template).rightTypes.find(rightType => rightType._id === option)?.name}
                                                    value={selectedRightType} onChange={(e, newValue) => handleRightTypeChange(newValue)}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Select
                                                    optionsStyle={{fontSize: 14}} multiline
                                                    label="Condição de uso"
                                                    options={useTypeOptions}
                                                    value={selectedUseType} onChange={(e) => handleUseTypeChange(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Select
                                                    label="Nível de risco"
                                                    options={CLEARANCE_RISK_OPTIONS.map(option => ({...option, label: <span><span style={{color: option.color}}>&#11044;</span>&nbsp;{option.label}</span>}))}
                                                    value={selectedRiskLevel} onChange={(e) => setSelectedRiskLevel(e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                }
                            </Box>
                        }
                    </Box>
                }
            </DialogContent>
            {
                !!activeUser &&
                <DialogActions>
                    <IconButton disabled={loading} size="small" onClick={triggerFileInputClick}>
                        <AttachFileIcon />
                    </IconButton>
                    <Button
                        onClick={handleSubmit}
                        variant="contained" color="primary"
                        disabled={loading || !replyComment || (activeUserIsOperator && (!selectedRightType || !selectedUseType || !selectedRiskLevel))}
                        startIcon={loading && <CircularProgress size={20} />}
                    >
                        Responder
                    </Button>
                </DialogActions>
            }
        </Dialog>
    );
};

export default ReplyQuestionWindow;