import React, { useEffect, useState } from 'react';
import { Box, Slide } from '@mui/material';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Dialog from './Dialog/Dialog';
import LoaderEllipsis from './LoaderEllipsis';
import Tooltip from './Tooltip';
import { getUserNameById } from '../utils/common';
import { SERVER_PATH } from '../utils/constants';
import { useAppCtxAPI, useAppCtxActiveUser, useAppCtxChangeFolderGroupsView } from '../context/SystemContext';
import axios from 'axios';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const GroupsWindow = (props) => {
    const { isOperator, users } = props;
    const { setLoading, hideChangeFolderGroupsView, setChangeFolderGroupsViewSelectedFolder, toast, updateOneManagers } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const { changeFolderGroupsViewOpen, changeFolderGroupsViewSelectedFolder: selectedFolder, changeFolderGroupsViewSelectedFolderId: folderId,  } = useAppCtxChangeFolderGroupsView();
    const managerGroups = [1, '1'];
    const adminGroups = [2, '2'];
    const managementGroups = [...managerGroups, ...adminGroups];
    const [availableUsers, set_availableUsers] = useState([]);
    const [groups, set_groups] = useState([]);
    const [selectedGroup, set_selectedGroup] = useState(null);
    const [menuOpen, set_menuOpen] = useState(false);
    const [menuAnchorEl, set_menuAnchorEl] = useState(null);
    const [loadingPage, set_loadingPage] = useState(false);
    const [folderName, set_folderName] = useState('');
    const [saving, setSaving] = useState(false);

    const systemIDs = ['62177a2ded92587af6430239', '61070b746c18c758e409a4be'];
    // solicitante@solidasistemas.com.br, stefanofalcao@dn.adv.br

    useEffect(() => {
        if(changeFolderGroupsViewOpen){
            set_folderName('');
            if(folderId){
                setLoading(true);
                set_loadingPage(true);
                
                axios.get(SERVER_PATH + `/data/folders/name-groups-users?folderId=${folderId}`)
                .then(res => {
                    set_folderName(res.data.name);

                    let currentAvailableUsers = res.data.users;
                    currentAvailableUsers = currentAvailableUsers.map(user => user._id);
                    set_availableUsers(currentAvailableUsers);

                    const removeGroups = ['~all;', '&all', '~none;', '&none'];
                    let currentGroups = res.data.groups;
                    currentGroups = currentGroups.filter(group => !removeGroups.includes(group.id));
                    currentGroups = currentGroups.map(group => {
                        group.users = group.users.filter(user => currentAvailableUsers.includes(user));
                        return group;
                    });
                    if(!currentGroups.find(group => group.id == 1)) currentGroups.push({id: 1, name: 'Gerentes', users: []});
                    // if(!currentGroups.find(group => group.id == 2)) currentGroups.push({id: 2, name: 'Administradores', users: []});
                    set_groups(currentGroups);

                    setLoading(false);
                    set_loadingPage(false);
                })
                .catch(err => {
                    setLoading(false);
                    set_loadingPage(false);
                    toast(err.response.data);
                });
            }
        }
    }, [changeFolderGroupsViewOpen, folderId]);

    const saveGroups = () => {
        setLoading(true);
        setSaving(true);
        updateOneManagers('Projects', folderId, {groups}, folderId)
        .then(res => {
            setSaving(false);
            setLoading(false);

            if(selectedFolder){
                if(setChangeFolderGroupsViewSelectedFolder) setChangeFolderGroupsViewSelectedFolder({...selectedFolder, groups});
            }
        })
        .catch(err => {
            setSaving(false);
            setLoading(false);
            toast(err.response.data);
        });
    };

    useEffect(() => {
        if(changeFolderGroupsViewOpen && folderId && !loadingPage && groups.length >= 1){
            saveGroups();
        }
    }, [groups]);

    const handleMenuOpenClick = (e, group) => {
        set_selectedGroup(group);
        set_menuAnchorEl(e.target);
        set_menuOpen(true);
    };

    const handleAddGroupUser = (user) => {
        set_menuOpen(false);
        let confirm = true;
        let currentGroups = [...groups];
        
        if(managementGroups.includes(selectedGroup.id)){ // changing user to management
            currentGroups = currentGroups.map(group => {
                group.users = group.users.filter(groupUser => groupUser !== user);
                return group;
            });
        } else {
            let managementGroup = currentGroups.find(group => managementGroups.includes(group.id) && group.users.includes(user));
            if(managementGroup){
                confirm = window.confirm(`${getUserNameById(users, user, true)} está no grupo ${managementGroup.name}. Isso poderá alterar os privilégios dessa pessoa. Tem certeza que quer continuar?`);
                if(confirm){
                    let userIndex = managementGroup.users.findIndex(groupUser => groupUser === user);
                    managementGroup.users.splice(userIndex, 1);
                    let managementGroupIndex = currentGroups.findIndex(group => group.id === managementGroup.id);
                    currentGroups = [...currentGroups.slice(0, managementGroupIndex), managementGroup, ...currentGroups.slice(managementGroupIndex + 1)]
                }
            }
        }
        if(confirm){
            let groupIndex = currentGroups.findIndex(group => group.id === selectedGroup.id);
            let group = currentGroups[groupIndex];
            group.users.push(user);
            set_groups(prevState => [...prevState.slice(0, groupIndex), group, ...prevState.slice(groupIndex + 1)]);
        }
    };
    const handleDeleteUserClick = (groupId, user) => {
        let groupIndex = groups.findIndex(group => group.id === groupId);
        let group = groups[groupIndex];
        let userIndex = group.users.findIndex(groupUser => groupUser == user);
        group.users.splice(userIndex, 1);
        set_groups(prevState => [...prevState.slice(0, groupIndex), group, ...prevState.slice(groupIndex + 1)]);
    };
    const handleAddGroupClick = () => {
        let groupName = window.prompt('Qual é o nome do grupo?');
        if(groupName){
            let newGroup = {
                id: new Date().getTime(),
                name: groupName,
                users: []
            };
            set_groups(prevState => [...prevState, newGroup]);
        }
    };
    const handleChangeGroupNameClick = (selectedGroup) => {
        let groupName = window.prompt('Qual é o nome do grupo?', selectedGroup.name);
        if(groupName){
            selectedGroup.name = groupName;
            let groupIndex = groups.findIndex(group => group.id === selectedGroup.id);
            set_groups(prevState => [...prevState.slice(0, groupIndex), selectedGroup, ...prevState.slice(groupIndex + 1)]);
        }
    };
    const handleDeleteGroupClick = (groupIndex, groupName) => {
        if(window.confirm(`Tem certeza que quer excluir o grupo ${groupName}?`)){
            set_groups(prevState => [...prevState.slice(0, groupIndex), ...prevState.slice(groupIndex + 1)]);
        }
    };

    const handleClose = () => {
        hideChangeFolderGroupsView();
    };
    
    return (
        <>
            <Dialog
                closeButtonLabel="Fechar"
                onClose={handleClose}
                open={changeFolderGroupsViewOpen}
                saving={saving}
                title={`${folderName ? `${folderName} | ` : ''}Grupos`}
                TransitionComponent={Transition}
            >
                <Box mb={2}>
                    {
                        loadingPage
                        ? <LoaderEllipsis primary />
                        :
                        <Box>
                            <Box mb={2}>
                                <Typography variant="h6">Nenhum grupo</Typography>
                                {
                                    availableUsers
                                    .filter(user => {
                                        let userInGroup = false;
                                        groups.forEach(group => {
                                            if(group.users.includes(user)){
                                                userInGroup = true;
                                                return;
                                            }
                                        });
                                        return !userInGroup;
                                    })
                                    .map((user) => (
                                        <Chip
                                            key={user}
                                            style={{margin: 2}}
                                            label={getUserNameById(users, user, true) + (systemIDs.includes(user) ? ` (SOLIDA)` : '')}
                                        />
                                    ))
                                }
                            </Box>
                            {
                                groups
                                .sort((a, b) => {
                                    if(!managementGroups.includes(a.id) && managementGroups.includes(b.id)) return 1;
                                    if(managementGroups.includes(a.id) && !managementGroups.includes(b.id)) return -1;
                                    return a.name > b.name ? 1 : (a.name < b.name ? -1 : 0);
                                })
                                .map((group, index) => (
                                    <Box key={group.id} mb={2}>
                                        <Box mb={1}>
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item>
                                                    <Typography variant="h6">{group.name}</Typography>
                                                </Grid>
                                                {
                                                    !managementGroups.includes(group.id) &&
                                                    <Grid item>
                                                        <Tooltip text="Alterar nome">
                                                            <IconButton size="small" onClick={() => handleChangeGroupNameClick(group)}><EditIcon /></IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                }
                                                {
                                                    activeUser?.type >= 9 &&
                                                    <Grid item>
                                                        <Tooltip text="Excluir grupo">
                                                            <IconButton size="small" onClick={() => handleDeleteGroupClick(index, group.name)}><DeleteIcon /></IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Box>
                                        {
                                            group.users.map(user => (
                                                <Chip
                                                    key={user}
                                                    color={systemIDs.includes(user) ? 'secondary' : 'primary'}
                                                    style={{margin: 2}}
                                                    label={getUserNameById(users, user, true) + (systemIDs.includes(user) ? ` (SOLIDA)` : '')}
                                                    onDelete={isOperator || !adminGroups.includes(group.id) ? () => handleDeleteUserClick(group.id, user) : undefined}
                                                />
                                            ))
                                        }
                                        {
                                            (isOperator || !adminGroups.includes(group.id)) &&
                                            <Tooltip text={`Alocar alguém no grupo ${group.name}`}>
                                                <IconButton size="small" onClick={(e) => handleMenuOpenClick(e, group)}><AddIcon /></IconButton>
                                            </Tooltip>
                                        }
                                    </Box>
                                ))
                            }
                            <Button startIcon={<AddIcon />} onClick={handleAddGroupClick}>Novo grupo</Button>
                        </Box>
                    }
                </Box>

            </Dialog>
            <Menu
                anchorEl={menuAnchorEl}
                open={menuOpen}
                onClose={() => set_menuOpen(false)}
            >
                {
                    availableUsers
                    .filter(user => !selectedGroup?.users.includes(user))
                    .sort((a, b) => {
                        let aFullName = getUserNameById(users, a, true);
                        let bFullName = getUserNameById(users, b, true);
                        return aFullName > bFullName ? 1 : (aFullName < bFullName ? -1 : 0);
                    })
                    .map((user, index) => (
                        <MenuItem key={index}
                            onClick={() => handleAddGroupUser(user)}
                        >
                            <Typography>{getUserNameById(users, user, true)}</Typography>
                        </MenuItem>
                    ))
                }
            </Menu>
        </>
    );
};

export default GroupsWindow;