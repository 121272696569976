import { useEffect, useState } from 'react';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';
import HtmlBox from './HtmlBox';
import Window from './Window';
import moment from 'moment';
import { useDocumentsAPI, useDocumentsCtxCommentView } from '../context/DocumentsContext';

const Blockquote = styled('blockquote')(unstable_styleFunctionSx);

const CommentWindow = ({users}) => {
    const { hideCommentView } = useDocumentsAPI();
    const { commentViewComment, commentViewOpen } = useDocumentsCtxCommentView();
    const [commentUser, setCommentUser] = useState(null);

    useEffect(() => {
        if(commentViewComment && users){
            const user = users.find(user => user._id === commentViewComment.createdBy);
            setCommentUser(user);
        }
    }, [commentViewComment, users]);

    const handleClose = () => {
        hideCommentView();
    };

    return (
        <Window open={commentViewOpen} onClose={handleClose}>
            {
                commentViewComment &&
                <Box>
                    {
                        commentUser &&
                        <Grid container spacing={1} alignItems="center" wrap="nowrap">
                            <Grid item>
                                <Avatar
                                    sx={{
                                        height: '32px',
                                        width: '32px'
                                    }}
                                    alt={commentUser.fullName}
                                    src={`/uploads/avatars/${commentUser.img}`}
                                />
                            </Grid>
                            <Grid item xs>
                                <Typography variant="body2">{commentUser.fullName}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">{moment(commentViewComment.createdAt).format('L LTS')}</Typography>
                            </Grid>
                        </Grid>
                    }
                    <Blockquote
                        sx={{
                            '&:before': {
                                color: '#ccc',
                                content: 'open-quote',
                                fontSize: '4em',
                                lineHeight: '0.1em',
                                marginRight: '0.25em',
                                verticalAlign: '-0.4em',
                            },
                        }}
                    >
                        <HtmlBox html={commentViewComment.comment.replace(/\n/g, '<br/>')} />
                    </Blockquote>
                </Box>
            }
        </Window>
    );
};

export default CommentWindow;