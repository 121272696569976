import React from 'react';
import { Collapse, Divider, List } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SidenavMobile from './SidenavMobile';
import SidenavMobileMenuButton from './SidenavMobileMenuButton';
import SidenavMobileTop from './SidenavMobileTop';
import Circle from './Circle';

const ListDivider = () => (
    <Divider component="li" />
);

const MobileSidenav = ({account, handleCloseMobileSidenav, menuItems, sidenavMobileOpen, set_sidenavMobileOpen}) => {
    return (
        <SidenavMobile
            data={{ sidenavMobileOpen, set_sidenavMobileOpen }}
        >
            <SidenavMobileTop closeSidenavMobile={handleCloseMobileSidenav} account={account} />
        
            <List>
                {
                    menuItems
                    .filter(menuItem => menuItem.visible)
                    .map((menuItem, menuItemIndex) => {
                        let subItems;
                        if(menuItem.highlight){
                            const foundSubItems = menuItem.highlight.filter(highlight => highlight.visible && highlight.mobileMenuItem);
                            if(foundSubItems.length >= 1) subItems = foundSubItems;
                        }
                        let endIcon;
                        let handleClick = menuItem.onButtonClick;
                        if(subItems){
                            endIcon = menuItem.mobileMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />;
                            handleClick = () => menuItem.setMobileMenuOpen(!menuItem.mobileMenuOpen);
                        }
                        return (
                            <React.Fragment key={menuItemIndex}>
                                <ListDivider />
                                <SidenavMobileMenuButton
                                    path={menuItem.path}
                                    label={menuItem.label}
                                    icon={menuItem.mobileIcon}
                                    endIcon={endIcon}
                                    onClick={handleClick}
                                    didNavigateAction={handleCloseMobileSidenav}
                                >
                                    {
                                        menuItem.badgeValue &&
                                        <Circle size={10} />
                                    }
                                </SidenavMobileMenuButton>
                                {
                                    subItems &&
                                    <Collapse in={menuItem.mobileMenuOpen}>
                                        <List component="div">
                                            <ListDivider />
                                            {
                                                subItems.map((subItem, subItemIndex) => {
                                                    return (
                                                        <React.Fragment key={`${menuItemIndex}${subItemIndex}`}>
                                                            <ListDivider />
                                                            <SidenavMobileMenuButton
                                                                path={subItem.path}
                                                                label={subItem.label}
                                                                badge={subItem.badgeValue}
                                                                didNavigateAction={handleCloseMobileSidenav}
                                                            />
                                                        </React.Fragment>
                                                    );
                                                })
                                            }
                                        </List>
                                    </Collapse>
                                }
                            </React.Fragment>
                        );
                    })
                }
            </List>
        </SidenavMobile>
    );
};

export default MobileSidenav;