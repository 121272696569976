import { createContext, useContext, useEffect, useMemo, useReducer } from 'react';
import axios from 'axios';
import { serverRequest } from '../../../../../utils/common';
import { SERVER_PATH } from '../../../../../utils/constants';
import { useAppCtxAPI, useAppCtxListServicosDoAudiovisual } from '../../../../../context/SystemContext';
import { useClient, useClientCtxActiveClient } from '../../../../../context/ClientContext';
import update from 'immutability-helper';
import { v4 as uuidv4 } from 'uuid';

const OperatorTemplatesCtxAPI = createContext();
const OperatorTemplatesCtxChangeTemplateView = createContext();
const OperatorTemplatesCtxConstants = createContext();
const OperatorTemplatesCtxTemplateName = createContext();
const OperatorTemplatesCtxTemplateDocumentEditorValue = createContext();
const OperatorTemplatesCtxTemplateFormEditorQuestions = createContext();
const OperatorTemplatesCtxTemplateFormEditorLogicalBranchingOptions = createContext();
const OperatorTemplatesCtxTemplateEditorSelectedTemplate = createContext();
const OperatorTemplatesCtxTemplateEffects = createContext();
const OperatorTemplatesCtxTemplateTitlePattern = createContext();
const OperatorTemplatesCtxVisibleTemplates = createContext();

const defaultState = {
    changeTemplateViewOpen: false,
    changeTemplateViewClickedTemplate: null,
    changeTemplateViewFoundTemplate: null,
    changeTemplateViewUpdatedAt: null,
    documentEditorValue: null,
    formEditorLogicalBranchingOptions: {},
    formEditorQuestions: [],
    templateName: '',
    templateEditorSelectedTemplate: null,
    templateEffects: [],
    isLoadingVisibleTemplates: true,
    visibleTemplates: [],
    visibleTemplatesUpdatedAt: null
};

const reducer = (state, action) => {
    const { payload, type } = action;
    
    switch (type) {
        case 'ADD VISIBLE TEMPLATE':
            if(state.visibleTemplates){
                return {
                    ...state,
                    visibleTemplates: [...state.visibleTemplates, payload],
                    visibleTemplatesUpdatedAt: new Date()
                };
            }
            return state;
        case 'ADD FORM EDITOR QUESTION':
            if(state.formEditorQuestions){
                if(payload.positionIndex !== undefined && payload.positionIndex !== -1){
                    return {
                        ...state,
                        formEditorQuestions: [
                            ...state.formEditorQuestions.slice(0, payload.positionIndex),
                            payload.item,
                            ...state.formEditorQuestions.slice(payload.positionIndex)
                        ]
                    };
                }
                return {
                    ...state,
                    formEditorQuestions: [...state.formEditorQuestions, payload.item]
                };
            }
            return state;
        case 'ADD TEMPLATE EFFECT':
            if(state.templateEffects){
                return {
                    ...state,
                    templateEffects: [...state.templateEffects, payload]
                };
            }
            return state;
        case 'DELETE FORM EDITOR QUESTION':
            if(state.formEditorQuestions){
                const formEditorQuestionIndex = state.formEditorQuestions.findIndex(formEditorQuestion => formEditorQuestion.id === payload);
                if(formEditorQuestionIndex !== -1){
                    return {
                        ...state,
                        formEditorQuestions: [
                            ...state.formEditorQuestions.slice(0, formEditorQuestionIndex),
                            ...state.formEditorQuestions.slice(formEditorQuestionIndex + 1)
                        ],
                        formEditorQuestionsUpdatedAt: new Date()
                    };
                }
            }
            return state;
        case 'DELETE TEMPLATE EFFECT':
            if(state.templateEffects){
                const templateEffectIndex = state.templateEffects.findIndex(templateEffect => templateEffect._id === payload);
                if(templateEffectIndex !== -1){
                    return {
                        ...state,
                        templateEffects: [
                            ...state.templateEffects.slice(0, templateEffectIndex),
                            ...state.templateEffects.slice(templateEffectIndex + 1)
                        ],
                        templateEffectsUpdatedAt: new Date()
                    };
                }
            }
            return state;
        case 'HIDE CHANGE TEMPLATE VIEW': 
            return { ...state, changeTemplateViewOpen: false, changeTemplateViewClickedTemplate: null };
        case 'POSITION FORM EDITOR QUESTION': 
            if(state.formEditorQuestions){
                return {
                    ...state,
                    formEditorQuestions: update(state.formEditorQuestions, {
                        $splice: [
                            [payload.sourceIndex, 1],
                            [payload.destinationIndex, 0, state.formEditorQuestions[payload.sourceIndex]],
                        ],
                    })
                }
            }
            return state;
        case 'SET CHANGE TEMPLATE VIEW CLICKED TEMPLATE':
            return { ...state, changeTemplateViewClickedTemplate: payload };
        case 'SET CHANGE TEMPLATE VIEW FOUND TEMPLATE':
            return { ...state, changeTemplateViewFoundTemplate: payload, changeTemplateViewUpdatedAt: new Date() };
        case 'SET DOCUMENT EDITOR VALUE':
            return { ...state, documentEditorValue: payload };
        case 'SET FORM EDITOR LOGICAL BRANCHING OPTIONS':
            return { ...state, formEditorLogicalBranchingOptions: payload };
        case 'SET FORM EDITOR QUESTIONS':
            return { ...state, formEditorQuestions: payload };
        case 'SET STATE':
            return { ...state, [payload.key]: payload.value };
        case 'SET TEMPLATE NAME':
            return { ...state, templateName: payload };
        case 'SET TEMPLATE EDITOR SELECTED TEMPLATE':
            return { ...state, templateEditorSelectedTemplate: payload };
        case 'SET TEMPLATE EFFECTS':
            return { ...state, templateEffects: payload };
        case 'SET TEMPLATE TITLE PATTERN SELECTED TEMPLATE':
            return { ...state, templateTitlePatternSelectedTemplate: payload };
        case 'SET VISIBLE TEMPLATES':
            return { ...state, isLoadingVisibleTemplates: false, visibleTemplates: payload, visibleTemplatesUpdatedAt: new Date() };
        case 'SET VISIBLE TEMPLATES LOADING':
            return { ...state, isLoadingVisibleTemplates: payload };
        case 'SHOW ADD TEMPLATE VIEW':
            return {
                ...state,
                changeTemplateViewFoundTemplate: null,
                documentEditorValue: '',
                templateEditorSelectedTemplate: null,
                templateEffects: [],
                templateName: ''
            };
        case 'SHOW CHANGE TEMPLATE VIEW':
            return { ...state, changeTemplateViewOpen: true, changeTemplateViewClickedTemplate: payload.clickedTemplate, changeTemplateViewFoundTemplate: null };
        case 'UPDATE FORM EDITOR QUESTION':
            if(state.formEditorQuestions){
                const formEditorQuestionIndex = state.formEditorQuestions.findIndex(i => i.id === payload.id);
                if(formEditorQuestionIndex !== -1){
                    return {
                        ...state,
                        formEditorQuestions: [
                            ...state.formEditorQuestions.slice(0, formEditorQuestionIndex),
                            {
                                ...payload,
                                updatedAt: new Date()
                            },
                            ...state.formEditorQuestions.slice(formEditorQuestionIndex + 1)
                        ],
                        formEditorQuestionsUpdatedAt: new Date()
                    };
                }
            }
            return state;
        case 'UPDATE TEMPLATE EFFECT':
            if(state.templateEffects){
                const templateEffectIndex = state.templateEffects.findIndex(i => i.id === payload.id);
                if(templateEffectIndex !== -1){
                    return {
                        ...state,
                        templateEffects: [
                            ...state.templateEffects.slice(0, templateEffectIndex),
                            {
                                ...payload,
                                updatedAt: new Date()
                            },
                            ...state.templateEffects.slice(templateEffectIndex + 1)
                        ],
                        templateEffectsUpdatedAt: new Date()
                    };
                }
            }
            return state;
        case 'UPDATE VISIBLE TEMPLATE':
            if(state.visibleTemplates){
                const visibleTemplateIndex = state.visibleTemplates.findIndex(i => i._id === payload._id);
                if(visibleTemplateIndex !== -1){
                    return {
                        ...state,
                        visibleTemplates: [...state.visibleTemplates.slice(0, visibleTemplateIndex), {...payload, updatedAt: new Date()}, ...state.visibleTemplates.slice(visibleTemplateIndex + 1)],
                        visibleTemplatesUpdatedAt: new Date()
                    };
                }
            }
            return state;
        default: return state;
    }
    
};

const TemplatesProvider = ({children}) => {
    const { handleNavigate, setLoading } = useAppCtxAPI();
    const special_list_audiovisual_services = useAppCtxListServicosDoAudiovisual();
    const { getListById, userTemplateFields } = useClient();
    const activeClient = useClientCtxActiveClient();

    const [state, dispatch] = useReducer(reducer, {...defaultState});

    const handleChangeTemplateDocumentClick = async (template) => {
        setLoading(true);
        setTemplateName('');
        if(template.useEffectsVersion === 2){
            setDocumentEditorValue(template.mainSheet);
            setTemplateEffects(template.effects);
        } else {
            await axios.post(SERVER_PATH + `/data/operator/docs/templates/file`, template)
            .then(res => {
                if(template.useEffects){
                    setDocumentEditorValue(res.data.main);
                    setTemplateEffects(res.data.effects);
                } else {
                    setDocumentEditorValue(res.data);
                }
            });
        }
        setTemplateEditorSelectedTemplate(template);
        setTemplateName(template.name);
        loadFormEditorQuestions(template);
        setLoading(false);
    };

    const setDocumentEditorValue = (newValue) => {
        dispatch({type: 'SET DOCUMENT EDITOR VALUE', payload: newValue});
    };

    const setFormEditorLogicalBranchingOptions = (newValue) => {
        dispatch({type: 'SET FORM EDITOR LOGICAL BRANCHING OPTIONS', payload: newValue});
    };

    const setFormEditorQuestions = (newValue) => {
        dispatch({type: 'SET FORM EDITOR QUESTIONS', payload: newValue});
    };

    const setTemplateName = (newValue) => {
        dispatch({type: 'SET TEMPLATE NAME', payload: newValue});
    };

    const setTemplateEditorSelectedTemplate = (newValue) => {
        dispatch({type: 'SET TEMPLATE EDITOR SELECTED TEMPLATE', payload: newValue});
    };

    const setTemplateEffects = (newValue) => {
        dispatch({type: 'SET TEMPLATE EFFECTS', payload: newValue});
    };

    const api = useMemo(() => {

        const addFormEditorQuestion = (item, positionIndex) => {
            dispatch({type: 'ADD FORM EDITOR QUESTION', payload: { item, positionIndex }});
        };

        const addTemplateEffect = (newValue) => {
            dispatch({type: 'ADD TEMPLATE EFFECT', payload: newValue});
        };
        
        const addVisibleTemplate = (payload) => {
            dispatch({type: 'ADD VISIBLE TEMPLATE', payload});
        };

        const deleteFormEditorQuestion = (newValue) => {
            dispatch({type: 'DELETE FORM EDITOR QUESTION', payload: newValue});
        };

        const deleteTemplateEffect = (newValue) => {
            dispatch({type: 'DELETE TEMPLATE EFFECT', payload: newValue});
        };

        const hideChangeTemplateView = (payload) => {
            dispatch({type: 'HIDE CHANGE TEMPLATE VIEW', payload});
        };

        const setChangeTemplateViewClickedTemplate = (clickedTemplate) => {
            dispatch({type: 'SET CHANGE TEMPLATE VIEW CLICKED TEMPLATE', payload: clickedTemplate});
        };

        const setChangeTemplateViewFoundTemplate = (selectedTemplate) => {
            dispatch({type: 'SET CHANGE TEMPLATE VIEW FOUND TEMPLATE', payload: selectedTemplate});
        };

        const setLoadingVisibleTemplates = (payload) => {
            dispatch({type: 'SET VISIBLE TEMPLATES LOADING', payload});
        };

        const setState = (key, value) => {
            dispatch({ type: 'SET STATE', payload: { key, value } });
        };

        const setTemplateTitlePatternSelectedTemplate = (selectedTemplate) => {
            dispatch({type: 'SET TEMPLATE TITLE PATTERN SELECTED TEMPLATE', payload: selectedTemplate});
        };

        const setVisibleTemplates = (payload) => {
            dispatch({type: 'SET VISIBLE TEMPLATES', payload});
        };
        
        const showAddTemplateView = (payload) => {
            dispatch({type: 'SHOW ADD TEMPLATE VIEW', payload});
            handleNavigate(`/${activeClient.shortName}/juridico/documentos/matrizes/nova`);
        };
        
        const showChangeTemplateView = (clickedTemplate) => {
            dispatch({type: 'SHOW CHANGE TEMPLATE VIEW', payload: { clickedTemplate }});
        };

        const positionFormEditorQuestion = (sourceIndex, destinationIndex) => {
            dispatch({type: 'POSITION FORM EDITOR QUESTION', payload: { sourceIndex, destinationIndex }});
        };

        const updateFormEditorQuestion = (newValue) => {
            dispatch({type: 'UPDATE FORM EDITOR QUESTION', payload: newValue});
        };

        const updateTemplateEffect = (newValue) => {
            dispatch({type: 'UPDATE TEMPLATE EFFECT', payload: newValue});
        };

        const updateVisibleTemplate = (payload) => {
            dispatch({type: 'UPDATE VISIBLE TEMPLATE', payload});
        };

        return {
            dispatch,
            addFormEditorQuestion,
            addTemplateEffect,
            addVisibleTemplate,
            deleteFormEditorQuestion,
            deleteTemplateEffect,
            handleChangeTemplateDocumentClick,
            hideChangeTemplateView,
            setChangeTemplateViewClickedTemplate,
            setChangeTemplateViewFoundTemplate,
            setDocumentEditorValue,
            setFormEditorLogicalBranchingOptions,
            setFormEditorQuestions,
            setTemplateName,
            setTemplateEditorSelectedTemplate,
            setTemplateEffects,
            setState,
            setTemplateTitlePatternSelectedTemplate,
            setVisibleTemplates,
            setLoadingVisibleTemplates,
            showAddTemplateView,
            showChangeTemplateView,
            positionFormEditorQuestion,
            updateFormEditorQuestion,
            updateTemplateEffect,
            updateVisibleTemplate
        };
    }, []);

    let userCreatedFieldsOptions = [], userCreatedFieldsTextOptions = [], userCreatedFieldsBooleanOptions = [];
    if(userTemplateFields){
        userCreatedFieldsOptions = userTemplateFields.map(field => ({value: field.id, label: field.name, type: field.type, template: field.template}));
        userCreatedFieldsTextOptions = userCreatedFieldsOptions.filter(field => {
            if(state.templateEditorSelectedTemplate){
                if(Array.isArray(field.template)){
                    return field.type !== 'boolean' && (field.template.length === 0 || field.template.includes(state.templateEditorSelectedTemplate._id));
                }
                return field.type !== 'boolean' && (!field.template || field.template === state.templateEditorSelectedTemplate._id);
            }
            if(Array.isArray(field.template)){
                return field.type !== 'boolean' && field.template.length === 0;        
            }
            return field.type !== 'boolean' && !field.template;
        });
        userCreatedFieldsBooleanOptions = userCreatedFieldsOptions.filter(field => {
            if(state.templateEditorSelectedTemplate){
                if(Array.isArray(field.template)){
                    return field.type === 'boolean' && (field.template.length === 0 || field.template.includes(state.templateEditorSelectedTemplate._id));
                }
                return field.type === 'boolean' && (!field.template || field.template === state.templateEditorSelectedTemplate._id);
            }
            if(Array.isArray(field.template)){
                return field.type === 'boolean' && field.template.length === 0;        
            }
            return field.type === 'boolean' && !field.template;
        });
    }

    const specialEffects = [
        {category: 'Cliente'},
        {value: 'clientDataFlowing', label: '[Cliente: Dados completos em texto corrido]', sample: ''},
        {value: 'clientDataFlowingExceptName', label: '[Cliente: Dados completos em texto corrido, exceto o nome]', sample: ''},
        {value: 'clientName', label: '[Cliente: Nome]', filters: ['documentEditorSlashOptions']},
        {value: 'clientId', label: '[Cliente: CPF/CNPJ]', filters: ['documentEditorSlashOptions']},
        {value: 'clientAncine', label: '[Cliente: Registro na Ancine]'},
        {value: 'clientAddress', label: '[Cliente: Endereço]'},
        {value: 'clientCity', label: '[Cliente: Cidade]'},
        {value: 'clientState', label: '[Cliente: Estado]'},
        {value: 'clientCityState', label: '[Cliente: Cidade e estado]'},
        {value: 'clientPostCode', label: '[Cliente: CEP]'},
        {value: 'clientFullAddressFlowing', label: '[Cliente: Endereço completo em texto corrido]', filters: ['documentEditorSlashOptions']},
        {value: 'clientLegalRepresentative', label: '[Cliente: Representante legal]'},
        {value: 'clientLegalRepresentativeId', label: '[Cliente: CPF do(a) representante legal]'},
        {value: 'clientLegalRepresentativePosition', label: '[Cliente: Cargo do(a) representante legal]'},
        {value: 'clientEmail', label: '[Cliente: E-mail]'},
        {value: 'clientJurisdiction', label: '[Cliente: Foro]'},
        {category: 'Projeto'},
        {value: 'projectDataBox', label: 'PROJETO: "[Nome]" - [Tipo]/[Categoria] ([Fase])'},
        {value: 'projectName', label: '[Projeto: Nome]', filters: ['documentEditorSlashOptions']},
        {value: 'projectType', label: '[Projeto: Tipo]'},
        {value: 'projectGenre', label: '[Projeto: Categoria]'},
        {value: 'projectPhase', label: '[Projeto: Fase]'},
        {value: 'projectDuration', label: '[Projeto: Duração]'},
        {value: 'projectDirector', label: '[Projeto: Diretor(a)]'},
        {value: 'projectFinancier', label: '[Projeto: Financiador]'},
        {value: 'projectWitness1FullName', label: '[Projeto: Nome completo da testemunha 1]'},
        {value: 'projectWitness1TaxpayerNumber', label: '[Projeto: CPF da testemunha 1]'},
        {value: 'projectWitness1Email', label: '[Projeto: E-mail da testemunha 1]'},
        {value: 'projectWitness2FullName', label: '[Projeto: Nome completo da testemunha 2]'},
        {value: 'projectWitness2TaxpayerNumber', label: '[Projeto: CPF da testemunha 2]'},
        {value: 'projectWitness2Email', label: '[Projeto: E-mail da testemunha 2]'},
        {category: 'Mais campos'},
        ...userCreatedFieldsTextOptions,
        {category: 'Outros'},
        {value: 'date', label: '[Data]', filters: ['documentEditorSlashOptions']},
        {value: 'documentNumber', label: '[Número do documento]', filters: ['documentEditorSlashOptions']},
    ];

    const loadFormEditorQuestions = (template) => {
        setFormEditorLogicalBranchingOptions({});
        let knownQuestions = template.form;
        const mappedQuestions = knownQuestions.map((question, index) => {
            if(!Array.isArray(question.logicalBranching)){
                const concurrentConditionGroups = [];
                if(question.logicalBranching && question.logicalBranching.question !== '~none;'){
                    const concurrentConditionGroup = {
                        id: uuidv4(),
                        concurrentConditions: [question.logicalBranching]
                    };
                    concurrentConditionGroups.push(concurrentConditionGroup);
                }
                question.logicalBranching = concurrentConditionGroups;
            } else {
                const concurrentConditionGroups = [];
                if(!question.logicalBranching.some(logicalBranchingCondition => logicalBranchingCondition.concurrentConditions)){
                    const concurrentConditionGroup = {
                        id: uuidv4(),
                        concurrentConditions: question.logicalBranching
                    };
                    concurrentConditionGroups.push(concurrentConditionGroup);
                    question.logicalBranching = concurrentConditionGroups;
                }
            }
            question.logicalBranching.forEach(concurrentConditionGroup => {
                concurrentConditionGroup.concurrentConditions.map(logicalBranchingCondition => {
                    if(logicalBranchingCondition.question && !['~none;', '&none'].includes(logicalBranchingCondition.question)){
                        if(!logicalBranchingCondition.id) logicalBranchingCondition.id = uuidv4();
                        let logicalBranchingQuestionOptions = [];
                        if(logicalBranchingCondition.type === 'userCreatedBooleanField'){
                            logicalBranchingQuestionOptions = [{choice: 'true', label: 'Se verdadeiro'}, {choice: 'false', label: 'Se falso'}];
                        } else {
                            const logicalBranchingQuestion = knownQuestions.find(j => j.name === logicalBranchingCondition.question);
                            if(logicalBranchingQuestion){
                                const lists = ['customList', 'list_name_servicosDoAudiovisual', 'list_description_servicosDoAudiovisual'];
                                if(logicalBranchingQuestion.type === 'list'){
                                    let listId = logicalBranchingQuestion.typeId;
                                    let currentList = getListById(listId);
                                    if(currentList){
                                        currentList = currentList.list;
                                        logicalBranchingQuestionOptions = currentList.map(i => ({choice: i.key, label: i.key}));
                                    }
                                } else if(logicalBranchingQuestion.type === 'special'){ // replace theses fields in templates, then delete
                                    if((lists.includes(logicalBranchingQuestion.special) || logicalBranchingQuestion.name === 'Serviço')){
                                        logicalBranchingQuestionOptions = special_list_audiovisual_services.map(i => ({choice: i.key, label: i.key}));
                                    }
                                } else {
                                    logicalBranchingQuestionOptions = logicalBranchingQuestion.options;
                                }
                            }
                        }

                        const formEditorLogicalBranchingOptionsNextState = {...state.formEditorLogicalBranchingOptions};
                        if(!formEditorLogicalBranchingOptionsNextState[question.name]) formEditorLogicalBranchingOptionsNextState[question.name] = {};
                        setFormEditorLogicalBranchingOptions({
                            ...formEditorLogicalBranchingOptionsNextState,
                            [question.name]: {
                                ...formEditorLogicalBranchingOptionsNextState[question.name],
                                [logicalBranchingCondition.id]: logicalBranchingQuestionOptions
                            }
                        });
                    }
                });
            });
            if(question.options){
                question.options = question.options.map(option => ({...option, id: uuidv4()}));
            }
            return {
                ...question,
                id: index + 1,
                assignedField: question.assignedField !== 0 ? question.assignedField : null,
                logicalBranching: question.logicalBranching.map(concurrentConditionGroup => ({
                    ...concurrentConditionGroup,
                    concurrentConditions: concurrentConditionGroup.concurrentConditions.map(logicalBranchingCondition => ({
                        ...logicalBranchingCondition,
                        question: logicalBranchingCondition.question === '&none' ? '~none;' : logicalBranchingCondition.question
                    }))
                }))
            }
        });
        setFormEditorQuestions(mappedQuestions);
    };

    const fetchTemplate = async () => {
        try {
            const currentFoundTemplate = await serverRequest({path: `/data/templates/one`, method: 'post', data: {templateId: state.changeTemplateViewClickedTemplate._id}});
            dispatch({ type: 'SET CHANGE TEMPLATE VIEW FOUND TEMPLATE', payload: currentFoundTemplate });
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if(state.changeTemplateViewClickedTemplate){
            fetchTemplate();
        } else {
            dispatch({ type: 'SET CHANGE TEMPLATE VIEW FOUND TEMPLATE', payload: null });
        }
    }, [state.changeTemplateViewClickedTemplate]);

    const operatorTemplatesCtxChangeTemplateViewValue = useMemo(() => ({
        changeTemplateViewOpen: state.changeTemplateViewOpen,
        changeTemplateViewClickedTemplate: state.changeTemplateViewClickedTemplate,
        changeTemplateViewFoundTemplate: state.changeTemplateViewFoundTemplate,
        changeTemplateViewUpdatedAt: state.changeTemplateViewUpdatedAt
    }), [state.changeTemplateViewOpen, state.changeTemplateViewUpdatedAt]);

    const operatorTemplatesCtxVisibleTemplatesValue = useMemo(() => ({
        isLoadingVisibleTemplates: state.isLoadingVisibleTemplates,
        visibleTemplates: state.visibleTemplates,
        visibleTemplatesUpdatedAt: state.visibleTemplatesUpdatedAt
    }), [state.isLoadingVisibleTemplates, state.visibleTemplatesUpdatedAt]);
    
    return (
        <OperatorTemplatesCtxAPI.Provider value={api}>
        <OperatorTemplatesCtxConstants.Provider value={{
            specialEffects,
            userCreatedFieldsBooleanOptions,
        }}>

        <OperatorTemplatesCtxChangeTemplateView.Provider value={operatorTemplatesCtxChangeTemplateViewValue}>
        <OperatorTemplatesCtxVisibleTemplates.Provider value={operatorTemplatesCtxVisibleTemplatesValue}>
        <OperatorTemplatesCtxTemplateName.Provider value={state.templateName}>
        <OperatorTemplatesCtxTemplateDocumentEditorValue.Provider value={state.documentEditorValue}>
        <OperatorTemplatesCtxTemplateFormEditorQuestions.Provider value={state.formEditorQuestions}>
        <OperatorTemplatesCtxTemplateEffects.Provider value={state.templateEffects}>
        <OperatorTemplatesCtxTemplateFormEditorLogicalBranchingOptions.Provider value={state.formEditorLogicalBranchingOptions}>
        <OperatorTemplatesCtxTemplateEditorSelectedTemplate.Provider value={state.templateEditorSelectedTemplate}>
        <OperatorTemplatesCtxTemplateTitlePattern.Provider value={state.templateTitlePatternSelectedTemplate}>
            {children}
        </OperatorTemplatesCtxTemplateTitlePattern.Provider>
        </OperatorTemplatesCtxTemplateEditorSelectedTemplate.Provider>
        </OperatorTemplatesCtxTemplateFormEditorLogicalBranchingOptions.Provider>
        </OperatorTemplatesCtxTemplateEffects.Provider>
        </OperatorTemplatesCtxTemplateFormEditorQuestions.Provider>
        </OperatorTemplatesCtxTemplateDocumentEditorValue.Provider>
        </OperatorTemplatesCtxTemplateName.Provider>
        </OperatorTemplatesCtxVisibleTemplates.Provider>
        </OperatorTemplatesCtxChangeTemplateView.Provider>
        </OperatorTemplatesCtxConstants.Provider>
        </OperatorTemplatesCtxAPI.Provider>
    );
};

const useOperatorTemplatesCtxAPI = () => useContext(OperatorTemplatesCtxAPI);
const useOperatorTemplatesCtxConstants = () => useContext(OperatorTemplatesCtxConstants);

const useOperatorTemplatesCtxChangeTemplateView = () => useContext(OperatorTemplatesCtxChangeTemplateView);
const useOperatorTemplatesCtxTemplateName = () => useContext(OperatorTemplatesCtxTemplateName);
const useOperatorTemplatesCtxTemplateDocumentEditorValue = () => useContext(OperatorTemplatesCtxTemplateDocumentEditorValue);
const useOperatorTemplatesCtxTemplateFormEditorLogicalBranchingOptions = () => useContext(OperatorTemplatesCtxTemplateFormEditorLogicalBranchingOptions);
const useOperatorTemplatesCtxTemplateFormEditorQuestions = () => useContext(OperatorTemplatesCtxTemplateFormEditorQuestions);
const useOperatorTemplatesCtxTemplateEditorSelectedTemplate = () => useContext(OperatorTemplatesCtxTemplateEditorSelectedTemplate);
const useOperatorTemplatesCtxTemplateEffects = () => useContext(OperatorTemplatesCtxTemplateEffects);
const useOperatorTemplatesCtxTemplateTitlePattern = () => useContext(OperatorTemplatesCtxTemplateTitlePattern);
const useOperatorTemplatesCtxVisibleTemplates = () => useContext(OperatorTemplatesCtxVisibleTemplates);

export {
    TemplatesProvider,
    useOperatorTemplatesCtxAPI,
    useOperatorTemplatesCtxConstants,

    useOperatorTemplatesCtxChangeTemplateView,
    useOperatorTemplatesCtxTemplateName,
    useOperatorTemplatesCtxTemplateDocumentEditorValue,
    useOperatorTemplatesCtxTemplateFormEditorLogicalBranchingOptions,
    useOperatorTemplatesCtxTemplateFormEditorQuestions,
    useOperatorTemplatesCtxTemplateEditorSelectedTemplate,
    useOperatorTemplatesCtxTemplateEffects,
    useOperatorTemplatesCtxTemplateTitlePattern,
    useOperatorTemplatesCtxVisibleTemplates
};