import { styled } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';

const Div = styled('div')(unstable_styleFunctionSx);

const HtmlBox = ({html}) => {
    // html = html.replace(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/);
    return (
        <Div sx={{ '& p': { margin: 0 } }} dangerouslySetInnerHTML={{ __html: html }} />
    );
};

export default HtmlBox;