const plugin = ({editor, setExecCommand}) => {
    editor.editorManager.PluginManager.add('solidacontextmenu', function(editor){
        // const textArea = editor.getElement();
        const textArea = document.getElementById('tinyEditorBox');
        editor.ui.registry.addMenuItem('contextMenuCopyEffect', {
            icon: 'copy',
            text: 'Copiar efeito',
            onAction: function() {
                const effectId = textArea.getAttribute('data-clickedEffectId');
                textArea.setAttribute('data-copiedEffectId', effectId);
                setExecCommand({command: 'copyEffect', data: {id: effectId}});
            }
        });
        editor.ui.registry.addContextMenu('contextMenuCopyEffect', {
            update: function (element) {
                if(element.classList.contains('solida-effect')){
                    const effectId = element.getAttribute('data-id');
                    textArea.setAttribute('data-clickedEffectId', effectId);
                    return 'contextMenuCopyEffect';
                }
                return '';
            }
        });
        editor.ui.registry.addMenuItem('contextMenuPasteEffect', {
            icon: 'paste',
            text: 'Colar efeito',
            onAction: function() {
                setExecCommand({command: 'insertCopiedEffect'});
            }
        });
        editor.ui.registry.addContextMenu('contextMenuPasteEffect', {
            update: function (element) {
                return textArea.getAttribute('data-copiedEffectId') ? 'contextMenuPasteEffect' : '';
            }
        });
        editor.ui.registry.addMenuItem('contextMenuNewEffect', {
            icon: 'plus',
            text: 'Criar efeito',
            onAction: function() {
                setExecCommand({command: 'createEffect'});
            }
        });
        editor.ui.registry.addMenuItem('contextMenuDeleteEffect', {
            icon: 'remove',
            text: 'Deletar todas as ocorrências desse efeito',
            onAction: function() {
                const effectId = textArea.getAttribute('data-clickedEffectId');
                setExecCommand({command: 'deleteEffect', data: {id: effectId}});
            }
        });
        editor.ui.registry.addContextMenu('contextMenuDeleteEffect', {
            update: function (element) {
                if(element.classList.contains('solida-effect')){
                    const effectId = element.getAttribute('data-id');
                    textArea.setAttribute('data-clickedEffectId', effectId);
                    return 'contextMenuDeleteEffect';
                }
                return '';
            }
        });
        // editor.ui.registry.addMenuItem('contextMenuInvisibleTable', {
        //     icon: 'table',
        //     text: 'Tabela invisível',
        //     onAction: function() {
        //         const tableCell = '<td style="width: 50%"></td>';
        //         const invisibleTable = `<table cellpadding="8" border="0" style="width: 100%; border-style: none"><tbody><tr>${tableCell}${tableCell}</tr></tbody></table>`;
        //         editor.insertContent(invisibleTable);
        //     }
        // });
        editor.ui.registry.addMenuItem('contextMenuPageBreak', {
            icon: 'page-break',
            text: 'Quebra de página',
            onAction: function() {
                editor.insertContent('<hr class="pb">');
            }
        });
        
        editor.ui.registry.addContextMenu('tableprops', {
            update: function (element) {
                return element.tagName === 'TD' ? 'tableprops' : '';
            }
        });
        editor.ui.registry.addContextMenu('tablerowprops', {
            update: function (element) {
                return element.tagName === 'TD' ? 'tablerowprops' : '';
            }
        });
        editor.ui.registry.addContextMenu('tablecellprops', {
            update: function (element) {
                return element.tagName === 'TD' ? 'tablecellprops' : '';
            }
        });

        /* Return the metadata for the plugin */
        return {
            getMetadata: function () {
                return  {
                    name: 'SOLIDA plugin',
                    url: 'http://exampleplugindocsurl.com'
                };
            }
        };
    });
};

export default plugin;