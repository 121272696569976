import { Avatar } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

function DashboardCounter({children, icon, label, loading}){
    
    const Item = ({avatar, number, description}) => (
        <Grid container spacing={1} alignItems="center">
            <Grid item>
                {avatar}
            </Grid>
            <Grid item xs>
                <Box mb={0.5}>
                    <Grid container alignItems="center">
                        <Grid item xs={3} sm={6} md={4}>
                            <Typography variant="h2" color="textSecondary">{number}</Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Typography variant="body2" style={{textTransform: 'uppercase'}} color="textSecondary">{description}</Typography>
            </Grid>
        </Grid>
    );

    return (
        <Grid container alignItems="center">
            <Grid item xs>
            {
                loading
                ?
                <Item
                    avatar={<Skeleton variant="circular" animation="wave" width={36} height={36} />}
                    number={<Skeleton />}
                    description={<Skeleton />}
                />
                :
                <Item
                    avatar={<Avatar sx={{
                        color: (theme) => theme.palette.primary.main,
                        backgroundColor: (theme) => theme.palette.background.paper,
                    }}>{icon}</Avatar>}
                    number={children}
                    description={label}
                />
            }
            </Grid>
        </Grid>
    );
}

export default DashboardCounter;