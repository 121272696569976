import { useState } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import ViewBox from '../../components/ViewBox';

const Users = () => {
    const [loading, setLoading] = useState(false);

    return (
        <ViewBox>
            <Box mb={2}>
                <Container maxWidth="xs">
                    <Grid container alignItems="flex-end" spacing={1}>
                        {
                            loading &&
                            <Grid item>
                                <CircularProgress size={20} />
                            </Grid>
                        }
                        <Grid item xs>
                            
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </ViewBox>
    );
}

export default Users;