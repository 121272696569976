import { memo } from 'react';
import { Avatar, Box, Grid, Typography } from '@mui/material';

const UserBox = memo(({user}) => {
    return (
        <Box p={0.5}>
            <Grid item xs container spacing={1} alignItems="center">
                <Grid item>
                    <Avatar style={{height: 32, width: 32}} alt={user.screenName} src={`/uploads/avatars/${user.img}`} />
                </Grid>
                <Grid item xs zeroMinWidth>
                    <Typography variant="h6" noWrap sx={{ display: { xs: 'none', md: 'block' } }}>{user.screenName}</Typography>
                </Grid>
            </Grid>
        </Box>
    );
}, (prevProps, nextProps) => {
    const { user: prevUser } = prevProps;
    const { user: nextUser } = nextProps;
    if(prevUser && nextUser){
        if(prevUser.screenName === nextUser.screenName && prevUser.img === nextUser.img){
            return true;
        }
    }
    return false;
});

export default UserBox;