import IconButton from '@mui/material/IconButton';
import UpdateIcon from '@mui/icons-material/Update';
import Tooltip from './Tooltip';
import { ERROR_MESSAGE_UNKNOWN, SERVER_PATH } from '../utils/constants';
import { useAppCtxAPI, useAppCtxActiveUser, useAppCtxLoading } from '../context/SystemContext';
import axios from 'axios';

const UpdateSignatureStatusButton = ({document, version, updateDocument}) => {
    const { setLoading, toast } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const loading = useAppCtxLoading();

    const handleUpdateSignatureStatusClick = () => {
        setLoading(true);
        let { eSignaturePlatform, eSignaturePlatformId } = version;
        if(!eSignaturePlatform) eSignaturePlatform = 'zapSign';
        if(activeUser.type >= 9 && !eSignaturePlatformId){
            eSignaturePlatformId = prompt('Insira o token do documento (os dados de assinatura serão salvos na ÚLTIMA versão)', '');
        }
        if(eSignaturePlatformId){
            let postData = {
                documentId: document._id,
                eSignaturePlatform,
                eSignaturePlatformId
            };  
            axios.post(`${SERVER_PATH}/data/documents/sign/update`, postData)
            .then(res => {
                let documentNewValue = res.data;
                updateDocument(documentNewValue);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                toast(ERROR_MESSAGE_UNKNOWN);
            });
        } else {
            setLoading(false);
            toast('Erro: token não encontrado. Entre em contato com o administrador do sistema.');
        }
    };

    return (
        <Tooltip text="Atualizar situação das assinaturas">
            <span>
                <IconButton
                    disabled={loading}
                    size="small"
                    onClick={handleUpdateSignatureStatusClick}
                >
                    <UpdateIcon />
                </IconButton>
            </span>
        </Tooltip>
    );
};

export default UpdateSignatureStatusButton;