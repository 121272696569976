import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { getDocumentVersionLink } from '../utils/common';
import { REMOTE_SERVER_PATH } from '../utils/constants';

const ClientDocumentLink = ({document, versionName}) => {
    if(document){
        const documents = document.documents;
        if(documents && documents !== '~empty;' && documents !== '&empty' && documents.length !== 0){
            let version = documents;
            if(Array.isArray(documents) && documents.length >= 1){
                version = documents[documents.length - 1];
            }
            let link = getDocumentVersionLink(version, REMOTE_SERVER_PATH);
            if(link){
                const documentFullName = `${document.name}${versionName ? ` | ${versionName}` : ''}`;
                return <Link variant="body1" href={link} target="_blank" underline="hover">{documentFullName}</Link>;
            }
            return <Typography variant="body1" noWrap>{document.name}</Typography>;
        }
    }
    return null;
};

export default ClientDocumentLink;