import { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useAppCtxAPI, useAppCtxLoadingApp } from '../context/SystemContext';
import LoadingBackdrop from './LoadingBackdrop';
import LoaderEllipsis from './LoaderEllipsis';

const LoadingSystemScreen = () => {
    const { setLoadingSystem } = useAppCtxAPI();
    const loadingSystem = useAppCtxLoadingApp()
    const [showProgress, setShowProgress] = useState(false);

    useEffect(() => {
        setShowProgress(false);
        setTimeout(() => {
            setShowProgress(true);
        }, 3000);
    }, [loadingSystem]);

    const handleClick = () => {
        setShowProgress(true);
    };

    return (
        <LoadingBackdrop open={loadingSystem} onClick={handleClick} onClose={() => setLoadingSystem(false)} backgroundColor="white"
            content={
                <Grid container direction="column" alignItems="center" justifyContent="center">
                    <Grid>
                        <Box mb={2}>
                            <img src={'/SOLIDA-500.png'} style={{height: 80, maxWidth: '100%'}} alt="SOLIDA" />
                        </Box>
                    </Grid>
                    <Grid style={{height: 30}} container alignItems="center" justifyContent="center">
                        <Grid>
                            {
                                showProgress &&
                                <CircularProgress size={20} />
                            }
                        </Grid>
                    </Grid>
                </Grid>
            }
        />
    );
};

export default LoadingSystemScreen;