import Tooltip from '@mui/material/Tooltip';

const StyledTooptip = ({ariaLabel, enterDelay, text, placement, children}) => {
    if(text){
        return (
            <Tooltip title={text} placement={placement} enterDelay={enterDelay || 0} aria-label={ariaLabel}>
                {children}
            </Tooltip>
        );
    }
    return children;
}

export default StyledTooptip;