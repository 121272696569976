import React from 'react';
import moment from 'moment';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import theme from './theme';
import { AppDataProvider } from './context/SystemContext';
import { OnlineUsersProvider } from './context/OnlineUsersContext';
import { SocketProvider } from './context/SocketContext';
import Auth from './components/Auth';
import Connected from './pages/Connected';
import DocumentReview from './pages/DocumentReview';
import RecoverPassword from './pages/RecoverPassword';
import SentAgreement from './pages/SentAgreement';
import Start from './pages/Start';
import Suppliers from './pages/Suppliers';
import './index.css';
import '../node_modules/moment/locale/pt-br';
moment.locale('pt-br');

function App() {
    return (
        <CssBaseline>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="pt-br">
                        <BrowserRouter>
                            <AppDataProvider>
                                <Auth>
                                    <Routes>
                                        <Route path="/" element={<Start />} />
                                        <Route path="/documento/revisar/:documentId" element={<DocumentReview />} />
                                        <Route path="/proposta/dnrf/:proposalVersionId" element={<SentAgreement />} />
                                        <Route path="/ficha/:clientId" element={<Suppliers />} />
                                        <Route path="/senha" element={<RecoverPassword />} />
                                        <Route path="/*" element={<OnlineUsersProvider><SocketProvider><Connected /></SocketProvider></OnlineUsersProvider>} />
                                    </Routes>
                                </Auth>
                            </AppDataProvider>
                        </BrowserRouter>
                    </LocalizationProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </CssBaseline>
    );
}

export default App;