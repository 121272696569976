import { useEffect, useState } from 'react';
import { TextField } from '@mui/material';

const NumberQuestion = ({initialValue, onChange, question}) => {
    const defaultValue = { value: 0 };
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        setValue(initialValue || defaultValue);
    }, [initialValue]);

    useEffect(() => {
        if(onChange) onChange(question.name, value);
    }, [value]);

    const handleChange = (newValue) => {
        setValue(prevState => ({...prevState, value: newValue}));
    };
    
    return (
        <TextField
            variant="standard"
            fullWidth
            placeholder="Sua resposta"
            type="number"
            value={value.value}
            onChange={(e) => handleChange(e.target.value)} />
    );
};

export default NumberQuestion;