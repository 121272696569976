import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AttachmentIcon from '@mui/icons-material/Attachment';
import Tooltip from './Tooltip';

const FilesField = ({attachFileButtonComponent, accept, buttonColor, buttonVariant, filesFieldId, filesList, setFilesList, horizontalAlign}) => {
    
    const triggerFileInputClick = () => {
        const fileInput = document.getElementById(filesFieldId || 'filesField');
        if(fileInput) fileInput.click();
    };
    
    const handleFileInputChange = (e) => {
        const el = e.target;
        let files = el.files;
        if(files && files.length >= 1){
            let list = [];
            for (let file of files) {
                let fileName = file.name || 'NOT SUPPORTED';
                list.push(fileName);
            }
            setFilesList(list);
        } else {
            setFilesList([]);
        }
    };

    const AttachFileButton = () => {
        if(!attachFileButtonComponent || attachFileButtonComponent === 'Fab'){
            return (
                <Tooltip text="Anexar arquivo">
                    <Fab size="small" variant={buttonVariant} color={buttonColor || 'primary'} onClick={triggerFileInputClick}><AttachFileIcon /></Fab>
                </Tooltip>
            );
        } else if(attachFileButtonComponent === 'Button'){
            return (
                <Button variant={buttonVariant || 'outlined'} color={buttonColor || 'primary'} onClick={triggerFileInputClick} startIcon={<AttachFileIcon />}>Anexar arquivos</Button>
            );
        } else if(attachFileButtonComponent === 'IconButton'){
            return (
                <IconButton size="small" variant={buttonVariant} color={buttonColor || 'primary'} onClick={triggerFileInputClick}><AttachFileIcon /></IconButton>
            );
        }
        return null;
    };

    return (
        <Box>
            <Grid container justifyContent={horizontalAlign || "center"}>
                <Grid item>
                    <AttachFileButton />
                </Grid>
            </Grid>
            <input
                style={{display: 'none'}}
                id={filesFieldId || 'filesField'}
                type="file"
                multiple="multiple"
                accept={accept}
                onChange={handleFileInputChange}
            />
            {
                filesList.length >= 1 &&
                <Box my={2}>
                    {/* <ShadowedBox mouseOverShadowEffect> */}
                        <Grid container spacing={1} alignItems="center">
                            {/* <Grid item>
                                <Box>
                                    <AttachmentIcon />
                                </Box>
                                <Box style={{textAlign: 'center'}}>
                                    <Typography variant="body2">({filesList.length})</Typography>
                                </Box>
                            </Grid> */}
                            <Grid item xs>
                                {
                                    filesList.map((fileListItem, index) => (
                                        <Box key={index}>
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item>
                                                    <Chip label={fileListItem} variant="outlined" icon={<AttachmentIcon />} />
                                                </Grid>
                                                {/* <Grid item>
                                                    X
                                                </Grid> */}
                                            </Grid>
                                            
                                        </Box>
                                    ))
                                }
                            </Grid>
                        </Grid>
                    {/* </ShadowedBox> */}
                </Box>
            }
        </Box>
    );
};

export default FilesField;