const MultilineText = ({text, breaker}) => {
    if(text){
        const elements = text.split(breaker || '\n');
        return elements.map((element, index) => (
            <span key={index}>
                {element}
                {
                    (index !== (elements.length - 1)) &&
                    <br />
                }
            </span>
        ));
    }
    return '';
};

export default MultilineText;