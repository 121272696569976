import { useParams } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Account from '../../components/Account';
import { useAppCtxAPI } from '../../context/SystemContext';

const FolderUserAccount = ({size}) => {
    const { workspaceId } = useParams();
    const { handleNavigate } = useAppCtxAPI();
    
    return (
        <Account size={size}> 
            <MenuItem onClick={() => handleNavigate(`/${workspaceId}/pastas/preferencias`)}>Preferências</MenuItem>
        </Account>
    );
};

export default FolderUserAccount;