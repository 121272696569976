import { useParams } from 'react-router-dom';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DescriptionIcon from '@mui/icons-material/Description';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import EditIcon from '@mui/icons-material/Edit';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MovieCreationOutlinedIcon from '@mui/icons-material/MovieCreationOutlined';
import MovieIcon from '@mui/icons-material/Movie';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import PeopleIcon from '@mui/icons-material/People';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import SaveIcon from '@mui/icons-material/Save';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileSignature } from '@fortawesome/free-solid-svg-icons';
import { useAppCtxAPI, useAppCtxActiveUser, useAppCtxSelectedFolder, useAppCtxSelectedLanguage, useAppCtxUseCompanyRootFolder, useAppCtxUserGroups } from '../../../context/SystemContext';
import { useFolderUserCtxAPI, useFolderUserCtxFolderModules, useFolderUserCtxSidenavMobile } from '../../../pages/Client/context/ClientContext';
import { menu } from '../../../utils/languages';

const useMenuItems = () => {
    const { workspaceId } = useParams();
    const { showChangeFolderGroupsView } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const selectedFolder = useAppCtxSelectedFolder();
    const selectedLanguage = useAppCtxSelectedLanguage();
    const useCompanyRootFolder = useAppCtxUseCompanyRootFolder();
    const { operatorStatus, userPosition } = useAppCtxUserGroups();
    const { setSidenavMobileOpen } = useFolderUserCtxAPI();
    
    const folderModules = useFolderUserCtxFolderModules();
    const sidenavMobileOpen = useFolderUserCtxSidenavMobile();

    const handleCloseMobileSidenav = () => {
        setSidenavMobileOpen(false);
    };

    const handleChangeGroupViewOpenButtonClick = () => {
        showChangeFolderGroupsView(selectedFolder);
    };

    const getShowModules = {
        createFolder: () => {
            return useCompanyRootFolder && folderModules.includes('createFolder');
        },
        eSignature: () => {
            return selectedFolder?.eSignature || useCompanyRootFolder;
        },
        questions: () => {
            return !useCompanyRootFolder && folderModules.includes('questions');
        }
    };

    const projectPath = selectedFolder ? `${selectedFolder.shortName || selectedFolder._id}` : '';

    const iconColor = !sidenavMobileOpen ? '#b2bfdc' : undefined;

    const menuItems = [
        {
            label: menu.chooseFolder[selectedLanguage],
            tooltip: menu.chooseFolder[selectedLanguage],
            path: `/${workspaceId}/pastas`,
            icon: <FolderIcon htmlColor={iconColor} />,
            mobileIcon: <FolderOpenIcon />,
            didNavigateAction: handleCloseMobileSidenav,
            visible: true,
        },
        {
            label: menu.contracts[selectedLanguage],
            tooltip: menu.seeAllContracts[selectedLanguage],
            path: `/${workspaceId}/pastas/${projectPath}/contratos`,
            icon: <DescriptionIcon htmlColor={iconColor} />,
            mobileIcon: <DescriptionOutlinedIcon />,
            didNavigateAction: handleCloseMobileSidenav,
            visible: !!selectedFolder,
        },
        {
            label: menu.completeForm[selectedLanguage],
            tooltip: menu.requestContract[selectedLanguage],
            path: `/${workspaceId}/pastas/${projectPath}/formularios`,
            icon: <EditIcon htmlColor={iconColor} />,
            mobileIcon: <EditOutlinedIcon />,
            didNavigateAction: handleCloseMobileSidenav,
            visible: !!selectedFolder && !useCompanyRootFolder,
        },
        {
            label: menu.savedForms[selectedLanguage],
            tooltip: menu.continueForm[selectedLanguage],
            path: `/${workspaceId}/pastas/${projectPath}/formularios/salvos`,
            icon: <SaveIcon htmlColor={iconColor} />,
            mobileIcon: <SaveOutlinedIcon />,
            didNavigateAction: handleCloseMobileSidenav,
            visible: !!selectedFolder && !useCompanyRootFolder,
        },
        {
            label: menu.supplierRecords[selectedLanguage],
            tooltip: menu.seeSupplierRecords[selectedLanguage],
            path: `/${workspaceId}/pastas/${projectPath}/fichas`,
            icon: <FileCopyIcon htmlColor={iconColor} />,
            mobileIcon: <FileCopyOutlinedIcon />,
            didNavigateAction: handleCloseMobileSidenav,
            visible: !!selectedFolder && !useCompanyRootFolder,
        },
        {
            label: menu.requestReview[selectedLanguage],
            tooltip: menu.requestDocumentReview[selectedLanguage],
            path: `/${workspaceId}/pastas/${projectPath}/revisao`,
            icon: <AssignmentIcon htmlColor={iconColor} />,
            mobileIcon: <AssignmentOutlinedIcon />,
            didNavigateAction: handleCloseMobileSidenav,
            visible: !!selectedFolder && !useCompanyRootFolder,
        },
        {
            label: menu.signatures[selectedLanguage],
            path: `/${workspaceId}/pastas/${projectPath}/assinar`,
            icon: <FontAwesomeIcon icon={faFileSignature} color={iconColor} />,
            mobileIcon: <AssignmentIndOutlinedIcon />,
            didNavigateAction: handleCloseMobileSidenav,
            visible: !!selectedFolder && getShowModules['eSignature'](),
        },
        {
            label: menu.alerts[selectedLanguage],
            tooltip: menu.seeAllAlerts[selectedLanguage],
            path: `/${workspaceId}/pastas/${projectPath}/contratos/alertas`,
            icon: <NotificationsIcon htmlColor={iconColor} />,
            mobileIcon: <NotificationsOutlinedIcon />,
            didNavigateAction: handleCloseMobileSidenav,
            visible: !!selectedFolder,
        },
        {
            label: menu.questions[selectedLanguage],
            path: `/${workspaceId}/pastas/${projectPath}/demandas`,
            icon: <AssignmentTurnedInIcon htmlColor={iconColor} />,
            mobileIcon: <AssignmentTurnedInOutlinedIcon />,
            didNavigateAction: handleCloseMobileSidenav,
            visible: !!selectedFolder && !useCompanyRootFolder && getShowModules['questions'](),
        },
        {
            label: menu.clearance[selectedLanguage],
            path: `/${workspaceId}/pastas/${projectPath}/clearance`,
            highlight: [
                { path: `/${workspaceId}/pastas/${projectPath}/clearance` },
                { path: `/${workspaceId}/pastas/${projectPath}/clearance/consultas` },
                { path: `/${workspaceId}/pastas/${projectPath}/clearance/roteiro` },
                { path: `/${workspaceId}/pastas/${projectPath}/clearance/corte` },
                { path: `/${workspaceId}/pastas/${projectPath}/clearance/pesquisa` },
            ],
            routes: [
                {
                    path: `/${workspaceId}/pastas/${projectPath}/clearance`, regExp: new RegExp(`${workspaceId}\/pastas\/${projectPath}\/clearance\/?$`),
                    icon: <HelpOutlineIcon />, label: `Orientações gerais`
                },
                {
                    path: `/${workspaceId}/pastas/${projectPath}/clearance/roteiro`, regExp: new RegExp(`${workspaceId}\/pastas\/${projectPath}\/clearance\/roteiro\/?$`),
                    icon: <DescriptionOutlinedIcon />, label: `Roteiro`
                },
                {
                    path: `/${workspaceId}/pastas/${projectPath}/clearance/consultas`, regExp: new RegExp(`${workspaceId}\/pastas\/${projectPath}\/clearance\/consultas\/?$`),
                    icon: <QuestionAnswerOutlinedIcon />, label: `Consultas`
                },
                {
                    path: `/${workspaceId}/pastas/${projectPath}/clearance/corte`, regExp: new RegExp(`${workspaceId}\/pastas\/${projectPath}\/clearance\/corte\/?$`),
                    icon: <MovieCreationOutlinedIcon />, label: `Corte`
                },
                {
                    path: `/${workspaceId}/pastas/${projectPath}/clearance/pesquisa`, regExp: new RegExp(`${workspaceId}\/pastas\/${projectPath}\/clearance\/pesquisa\/?$`),
                    icon: <FindInPageOutlinedIcon />, label: `Pesquisa`
                }
            ],
            icon: <MovieIcon htmlColor={iconColor} />,
            mobileIcon: <MovieCreationOutlinedIcon />,
            visible: !!selectedFolder && folderModules.includes('clearance') && (userPosition || operatorStatus)
        },
        {
            label: menu.groups[selectedLanguage],
            tooltip: 'Gerenciar grupos de permissões',
            icon: <GroupWorkIcon htmlColor={iconColor} />,
            mobileIcon: <GroupWorkOutlinedIcon />,
            onButtonClick: handleChangeGroupViewOpenButtonClick,
            didNavigateAction: handleCloseMobileSidenav,
            visible: !!selectedFolder && (userPosition || operatorStatus) && selectedFolder.useGroups,
        },
        {
            label: menu.reports[selectedLanguage],
            tooltip: menu.generateContractReports[selectedLanguage],
            path: `/${workspaceId}/pastas/${projectPath}/relatorios`,
            icon: <AssessmentIcon htmlColor={iconColor} />,
            mobileIcon: <AssessmentOutlinedIcon />,
            didNavigateAction: handleCloseMobileSidenav,
            visible: !!selectedFolder && (userPosition || operatorStatus),
        },
        {
            label: menu.deletedDocuments[selectedLanguage],
            path: `/${workspaceId}/pastas/${projectPath}/contratos/lixeira`,
            icon: <DeleteIcon htmlColor={iconColor} />,
            mobileIcon: <DeleteOutlineIcon />,
            didNavigateAction: handleCloseMobileSidenav,
            visible: !!selectedFolder && (userPosition || operatorStatus),
        },
        {
            label: menu.createFolder[selectedLanguage],
            tooltip: menu.createFolder[selectedLanguage],
            path: `/${workspaceId}/pastas/${projectPath}/pastas`,
            icon: <CreateNewFolderIcon htmlColor={iconColor} />,
            mobileIcon: <CreateNewFolderOutlinedIcon />,
            didNavigateAction: handleCloseMobileSidenav,
            visible: !!selectedFolder && getShowModules['createFolder'](),
        },
        {
            label: menu.companies[selectedLanguage],
            path: `/${workspaceId}/pastas/empresas`,
            icon: <BusinessIcon htmlColor={iconColor} />,
            mobileIcon: <BusinessOutlinedIcon />,
            didNavigateAction: handleCloseMobileSidenav,
            visible: activeUser?.allowManageCompanies,
        },
        {
            label: menu.people[selectedLanguage],
            path: `/${workspaceId}/pastas/${projectPath}/pessoas`,
            icon: <PeopleIcon htmlColor={iconColor} />,
            mobileIcon: <PeopleOutlinedIcon />,
            didNavigateAction: handleCloseMobileSidenav,
            visible: activeUser?.allowManageUsers,
        },
    ];

    return menuItems;
};

export default useMenuItems;