const radioGrid = {
    createEffect: ({ effectSettings, newEffectId }) => {
        const newEffect = {
            id: newEffectId,
            type: effectSettings.type,
            rows: effectSettings.rows,
            columns: effectSettings.columns,
            column: effectSettings.column,
            display: effectSettings.display,
            question: effectSettings.question || effectSettings.label || '',
            label: effectSettings.label || effectSettings.question
        };
        return { newEffect };
    },
    newEffectWindowHandleEffectTypeChange: () => {
        const data = {};
        data.question = '';
        return data;
    },
    getFormEditorQuestion: (data) => {
        if(data){
            const { effect, question } = data;
            const formEditorQuestion = {...question};
            formEditorQuestion.rows = effect.rows;
            formEditorQuestion.columns = effect.columns;
            return formEditorQuestion;
        }
        return false;
    },
    checkValidation: (data) => {
        let questionValid = false;
        if(data){
            questionValid = true;
            const { choices } = data;
            if(!choices || choices.length === 0){
                questionValid = false;
            } else {
                choices.forEach(choice => {
                    if(!choice.choice) questionValid = false;
                });
            }
        }
        return questionValid;
    }
};

export {
    radioGrid
}