import { ifCondition } from './documents-templates-if';

const ifNotCondition = {
    createEffect: ({ effectSettings, newEffectId, specialEffects }) => {
        const typeSpecificsLabel = ifCondition.getTypeSpecificsLabel({ effectSettings, specialEffects });
        const inputLabel = ifCondition.getInputLabel({effectSettings}, ' e ');
        const newEffect = {
            id: newEffectId,
            type: effectSettings.type,
            typeId: effectSettings.typeId,
            typeSpecifics: effectSettings.typeSpecifics,
            input: effectSettings.input,
            output: effectSettings.output || '',
            elseOutput: effectSettings.elseOutput || '',
            question: '[Se não]',
            label: `[Se ${typeSpecificsLabel} for diferente de ${inputLabel}]`,
            form: false
        };
        return { newEffect };
    },
    newEffectWindowHandleEffectTypeChange: () => {
        const data = {
            typeId: '',
            typeSpecifics: '',
            input: [''],
            output: '',
            elseOutput: ''
        };
        return data;
    },
    setEffectOutput: ({ documentEditorValue, selectedEffect, selectedEffectOutput }) => {
        selectedEffect[selectedEffectOutput.optionIndex] = documentEditorValue;
        return selectedEffect;
    },
    getSelectedEffectOutput: ({ selectedEffect, selectedEffectOutput }) => {
        let newValue = selectedEffect[selectedEffectOutput.optionIndex] || '';
        return newValue;
    },
    getOutputsContent: (data) => {
        let content = '';
        if(data){
            const { scannedEffect } = data;
            if(scannedEffect){
                content += scannedEffect.output || '';
                content += scannedEffect.elseOutput || '';
            }
        }
        return content;
    },
    getFormEditorQuestion: (data) => {
        if(data){
            const { effect, question } = data;
            const formEditorQuestion = {...question};
            formEditorQuestion.typeId = effect.typeId;
            formEditorQuestion.typeSpecifics = effect.typeSpecifics;
            return formEditorQuestion;
        }
        return false;
    }
};

export {
    ifNotCondition
}