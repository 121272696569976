import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import ChangePassword from '../components/ChangePassword';
import DashboardBox from './DashboardBox';
import Tooltip from './Tooltip';
import ViewBox from './ViewBox';
import { ERROR_MESSAGE_UNKNOWN, SERVER_PATH, SUCCESS_MESSAGE_SAVED } from '../utils/constants';
import { useAppCtxAPI, useAppCtxActiveUser } from '../context/SystemContext';
import axios from 'axios';

const classes = {
    image: {
        height: '100%',
        maxHeight: '220px',
        borderRadius: '50%',
        cursor: 'pointer'
    },
};

const Img = styled('img')(unstable_styleFunctionSx);

function Profile({children}){
    const { setLoading, toast, updateActiveUser } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const [changePasswordDialogOpen, set_changePasswordDialogOpen] = useState(false);
    
    const triggerFileButton = () => {
        document.getElementById('avatarFile').click();
    };
    const handle_selectedFile = (e) => {
        const filesInput = document.getElementById('avatarFile');
        let files = filesInput.files;
        if(files.length > 0){
            setLoading(true);
            toast('Verificando arquivos... isso pode levar alguns segundos...');
            let formData = new FormData();
            formData.append('file', files[0]);
            axios.post(SERVER_PATH + '/data/profile/avatar/update', formData, {headers: {'Content-Type': 'multipart/form-data'}})
            .then(res => {
                updateActiveUser({ img: res.data.img });
                setLoading(false);
                toast(SUCCESS_MESSAGE_SAVED);
            })
            .catch(err => {
                setLoading(false);
                toast(ERROR_MESSAGE_UNKNOWN);
            });
        }
    };

    return (
        <ViewBox>
            {
                activeUser &&
                <Container maxWidth="md">
                    <Box mb={2}>
                        <Typography variant="h2">Preferências</Typography>
                    </Box>
                    <Box mb={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <DashboardBox p={3} style={{height: 'auto'}}>
                                    <Box mb={2}>
                                        <Box mb={2}>
                                            <Typography variant="h5" color="textSecondary">Dados gerais</Typography>
                                        </Box>
                                        <Grid item container direction="column" alignItems="center" justifyContent="center" style={{paddingTop: 8}}>
                                            {
                                                activeUser.img
                                                ?
                                                    <>
                                                        <Tooltip text="Alterar imagem"><Img src={`/uploads/avatars/${activeUser.img}`} alt={activeUser.screenName} sx={classes.image} onClick={triggerFileButton} /></Tooltip>
                                                    </>
                                                :
                                                    <Box style={{cursor: 'pointer', textAlign: 'center'}} onClick={triggerFileButton}>
                                                        <Box mb={1}>
                                                            <Tooltip text="Escolher imagem"><ClearIcon fontSize="large" /></Tooltip>
                                                        </Box>
                                                        <Box mb={1}>
                                                            <em>Nenhuma imagem de perfil carregada. Clique aqui para escolher uma imagem.</em>
                                                        </Box>
                                                    </Box>
                                            }
                                            <input style={{display: 'none'}} id="avatarFile" type="file" accept="image/*" onChange={handle_selectedFile} />
                                        </Grid>
                                        <List dense={true}>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <PersonIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Nome completo" secondary={activeUser.fullName} />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <EmojiPeopleIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Nome de exibição" secondary={activeUser.screenName} />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <EmailIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="E-mail" secondary={<Link href={`mailto:${activeUser.email}`} underline="hover">{activeUser.email}</Link>} />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <LockIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Senha" />
                                                <ListItemSecondaryAction>
                                                    <Tooltip text="Alterar a sua senha">
                                                        <IconButton
                                                            edge="end"
                                                            aria-label="change password"
                                                            onClick={() => set_changePasswordDialogOpen(true)}
                                                            size="large">
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        </List>
                                    </Box>
                                    <Divider />
                                    {children}
                                </DashboardBox>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            }
            <ChangePassword open={changePasswordDialogOpen} setOpen={set_changePasswordDialogOpen} />
        </ViewBox>
    );
}

export default Profile;