import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { BIG_BUTTON_BOTTOM_PADDING, BIG_BUTTON_MD_SIZE } from '../utils/constants';

const activeButtonClass = {
    backgroundColor: theme => theme.palette.background.dark,
    color: '#f5f5f5'
}
const classes = {
    activeButton: activeButtonClass,
    button: {
        backgroundColor: theme => theme.palette.background.darker,
        color: theme => theme.palette.text.primary,
        marginRight: theme => theme.spacing(1),
        borderRadius: theme => [theme.spacing(1), theme.spacing(1), theme.spacing(2)],
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
        cursor: 'pointer',
        position: 'relative',
        textAlign: 'center',
        textDecoration: 'none',
        textTransform: 'uppercase',
        transition: 'background-color .2s ease-out',
        WebkitTransition: 'background-color .2s ease-out',
        MozTransition: 'background-color .2s ease-out',
        msTransition: 'background-color .2s ease-out',
        '&:hover': activeButtonClass,
    },
    content: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }
};

const BigButton = ({active, icon, label, onClick, style}) => {
    const rootClasses = [
        classes.button,
        {
            height: `${BIG_BUTTON_MD_SIZE}px`,
            minWidth: `${BIG_BUTTON_MD_SIZE + BIG_BUTTON_BOTTOM_PADDING}px`
        }
    ];
    if(active) rootClasses.push(classes.activeButton);

    return (
        <Box sx={rootClasses} style={style} onClick={onClick}>
            <Box sx={classes.content}>
                {icon}
                <Typography variant="body2">{label}</Typography>
            </Box>
        </Box>
    )
}

export default BigButton;