import { useState } from 'react';
import FormDialog from '../../../../../../components/FormDialog';
import { useOperatorTemplatesCtxAPI, useOperatorTemplatesCtxTemplateFormEditorQuestions } from '../../context/TemplatesContext';
import { useFormEditor } from '../FormEditorContext';
import { v4 as uuidv4 } from 'uuid';

const NewTitleDialog = ({setFormEditorQuestionWindowOpen}) => {
    const { addFormEditorQuestion } = useOperatorTemplatesCtxAPI();
    const formEditorQuestions = useOperatorTemplatesCtxTemplateFormEditorQuestions();
    const { clickedQuestion, newTitleDialogOpen, set_newTitleDialogOpen } = useFormEditor();
    const [textFieldValue, set_textFieldValue] = useState('');

    const handleNewTitleDialogOkClick = () => {
        setFormEditorQuestionWindowOpen(false);

        const positionIndex = formEditorQuestions.indexOf(clickedQuestion);
        addFormEditorQuestion({ id: uuidv4(), type: 'heading', name: textFieldValue, logicalBranching: [] }, positionIndex);

        set_newTitleDialogOpen(false);
    };
    
    return (
        <FormDialog
            id="new-title-dialog"
            open={newTitleDialogOpen} onEnter={() => set_textFieldValue('')} onClose={() => set_newTitleDialogOpen(false)}
            title="Novo título" text="Criar novo título?" textFieldLabel="Título"
            textFieldValue={textFieldValue} textFieldOnChange={(e) => set_textFieldValue(e.target.value)}
            okButtonOnClick={handleNewTitleDialogOkClick}
            okButtonDisabled={textFieldValue === ''}
        />
    );
}

export default NewTitleDialog;