import { Route } from 'react-router-dom';
import Main from '../../components/Main';
import Header from './components/Header';
import Home from './Home';
import Profile from './Profile';
import ConnectedFolderUser from '../ConnectedFolderUser';
import PageNotFound from '../PageNotFound';
import { useFolderUserCtxAPI, useFolderUserCtxSidenavMobile } from './context/ClientContext';
import DesktopSidenav from '../../components/DesktopSidenav';
import MobileSidenav from '../../components/MobileSidenav';
import Account from './Account';
import useMenuItems from '../../components/FolderUserContext/hooks/useMenuItems';

function Client(){
    const { setSidenavMobileOpen } = useFolderUserCtxAPI();
    const sidenavMobileOpen = useFolderUserCtxSidenavMobile();
    const menuItems = useMenuItems();

    const handleCloseMobileSidenav = () => {
        setSidenavMobileOpen(false);
    };

    return (
        <Main
            header={<Header />}
            sidenav={<DesktopSidenav menuItems={menuItems} account={<Account />} />}
            mobileSidenav={<MobileSidenav account={<Account size={7} />} handleCloseMobileSidenav={handleCloseMobileSidenav} menuItems={menuItems} sidenavMobileOpen={sidenavMobileOpen} set_sidenavMobileOpen={setSidenavMobileOpen} />} 
        >
            <Route path="/" element={<Home />} />
            <Route path="/preferencias" element={<Profile />} />
            <Route path="/:folderId/*" element={<ConnectedFolderUser />} />
            <Route path="*" element={<PageNotFound />} />
        </Main>
    );
}

export default Client;