import { CNPJ_REGEXP } from '../../../utils/constants';

const corporationId = {
    createEffect: ({ effectSettings, newEffectId, templateEffects }) => {
        const newEffect = {
            id: newEffectId,
            type: effectSettings.type,
            question: effectSettings.question,
            label: effectSettings.label || effectSettings.question
        };
        const foundEffect = templateEffects.find(templateEffect => templateEffect.type === effectSettings.type && templateEffect.question === effectSettings.question);
        return { foundEffect, newEffect };
    },
    newEffectWindowHandleEffectTypeChange: () => {
        const data = {
            question: '',
        };
        return data;
    },
    checkValidation: (data) => {
        let questionValid = false;
        if(data){
            questionValid = true;
            const { input } = data;
            if(!input || !CNPJ_REGEXP.test(input)) questionValid = false;
        }
        return questionValid;
    }
};

export {
    corporationId
}