const showBrowserNotification = () => {

    const showBrowserNotificationAction = () => {
        if(document.visibilityState === 'visible'){
            return;
        }
        const title = 'JavaScript Jeep';
        const icon = 'image-url'; // URL of an icon to be displayed in the notification
        const body = 'Message to be displayed';
        const notification = new Notification(title, { body, icon });
        notification.onclick = () => {
            notification.close();
            window.parent.focus();
        }
    };

    const requestPermissionAndShowBrowserNotificationAction = () => {
        Notification.requestPermission(function (permission) {
            if (permission === 'granted') {
                showBrowserNotificationAction();
            }
         });      
    };

    if(typeof Notification !== 'undefined'){
        const permission = Notification.permission;
        if(permission === 'granted') {
            showBrowserNotificationAction();
        } else if(permission === 'default'){
            requestPermissionAndShowBrowserNotificationAction();
        } else {
        //   alert("Use normal alert");
        }
    }
};

function onDocumentDeletedEvent(data, deleteDocument, setMessage){
    const { actionUserName, deletedDocument } = data;

    deleteDocument({ data: deletedDocument._id, message: { setMessage, ifFound: `O documento "${deletedDocument.name}" foi excluído por ${actionUserName}.` } });

    // setVisibleDocuments(prevState => {
    //     if(prevState){
    //         const foundDocumentIndex = prevState.findIndex(foundDocument => foundDocument._id === deletedDocument._id);
    //         if(foundDocumentIndex !== -1){
    //             setMessage(`O documento "${deletedDocument.name}" foi excluído por ${actionUserName}.`);
    //             return [...prevState.slice(0, foundDocumentIndex), ...prevState.slice(foundDocumentIndex + 1)];
    //         }
    //     }
    //     return prevState;
    // });
}

function onDocumentFlaggedEvent(data, updateDocument, setMessage){
    const { actionUserName, updatedDocument } = data;

    updateDocument({ data: updatedDocument, message: { setMessage, ifFound: `O documento "${updatedDocument.name}" foi sinalizado por ${actionUserName}.` } });

    // setVisibleDocuments(prevState => {
    //     if(prevState){
    //         const foundDocumentIndex = prevState.findIndex(foundDocument => foundDocument._id === updatedDocument._id);
    //         if(foundDocumentIndex !== -1){
    //             setMessage(`O documento "${updatedDocument.name}" foi sinalizado por ${actionUserName}.`);
    //             return [...prevState.slice(0, foundDocumentIndex), {...updatedDocument, updatedAt: new Date()}, ...prevState.slice(foundDocumentIndex + 1)];
    //         }
    //     }
    //     return prevState;
    // });
}

function onDocumentFlaggedInPendingDocumentsPageEvent(data, updateOrAddDocument, setMessage){
    const { actionUserName, updatedDocument } = data;

    updateOrAddDocument(updatedDocument);

    // setVisibleDocuments(prevState => {
    //     if(prevState){
    //         const foundDocumentIndex = prevState.findIndex(foundDocument => foundDocument._id === updatedDocument._id);
    //         if(foundDocumentIndex !== -1){
    //             return [...prevState.slice(0, foundDocumentIndex), {...updatedDocument, updatedAt: new Date()}, ...prevState.slice(foundDocumentIndex + 1)];
    //         } else {
    //             //if not found, then add to array
    //             return [...prevState, updatedDocument];
    //         }
    //     }
    //     return prevState;
    // });
    setMessage(`O documento "${updatedDocument.name}" foi sinalizado por ${actionUserName}.`);
}

function onDocumentFolderChangedEvent(data, updateDocument, setMessage){
    const { actionUserId, actionUserName, activeUserId, updatedDocument } = data;
    
    updateDocument({ data: updatedDocument, message: { setMessage, ifFound: actionUserId !== activeUserId ? `${actionUserName} alterou a pasta do documento "${updatedDocument.name}".` : '' } });
    
    // setVisibleDocuments(prevState => {
    //     if(prevState){
    //         const foundDocumentIndex = prevState.findIndex(foundDocument => foundDocument._id === updatedDocument._id);
    //         if(foundDocumentIndex !== -1){
    //             if(actionUserId !== activeUserId) setMessage(`${actionUserName} alterou a pasta do documento "${updatedDocument.name}".`);
    //             return [...prevState.slice(0, foundDocumentIndex), {...updatedDocument, updatedAt: new Date()}, ...prevState.slice(foundDocumentIndex + 1)];
    //         }
    //     }
    //     return prevState;
    // });
}

function onDocumentGeneratedEvent(data, updateOrAddDocument){
    const { newDocument } = data;

    updateOrAddDocument(newDocument);

    // setVisibleDocuments(prevState => {
    //     if(prevState){
    //         const foundDocumentIndex = prevState.findIndex(foundDocument => foundDocument._id === newDocument._id);
    //         if(foundDocumentIndex !== -1){
    //             return [...prevState.slice(0, foundDocumentIndex), {...newDocument, updatedAt: new Date()}, ...prevState.slice(foundDocumentIndex + 1)];
    //         }
    //         return [...prevState, newDocument];
    //     }
    //     return prevState;
    // });
}

function onDocumentGroupChangedEvent(data, updateDocument, setMessage){
    const { actionUserId, actionUserName, activeUserId, updatedDocument } = data;
    
    updateDocument({ data: updatedDocument, message: { setMessage, ifFound: actionUserId !== activeUserId ? `${actionUserName} alterou o grupo do documento "${updatedDocument.name}".` : '' } });
    
    // setVisibleDocuments(prevState => {
    //     if(prevState){
    //         const foundDocumentIndex = prevState.findIndex(foundDocument => foundDocument._id === updatedDocument._id);
    //         if(foundDocumentIndex !== -1){
    //             if(actionUserId !== activeUserId) setMessage(`${actionUserName} alterou o grupo do documento "${updatedDocument.name}".`);
    //             return [...prevState.slice(0, foundDocumentIndex), {...updatedDocument, updatedAt: new Date()}, ...prevState.slice(foundDocumentIndex + 1)];
    //         }
    //     }
    //     return prevState;
    // });
}

function onDocumentNameChangedEvent(data, updateDocument, setMessage){
    const { previousName, updatedDocument } = data;

    updateDocument({ data: updatedDocument, message: { setMessage, ifFound: `O nome do documento "${previousName}" foi alterado para "${updatedDocument.name}".` } });

    // setVisibleDocuments(prevState => {
    //     if(prevState){
    //         const foundDocumentIndex = prevState.findIndex(foundDocument => foundDocument._id === updatedDocument._id);
    //         if(foundDocumentIndex !== -1){
    //             setMessage(`O nome do documento "${previousName}" foi alterado para "${updatedDocument.name}".`);
    //             return [...prevState.slice(0, foundDocumentIndex), {...updatedDocument, updatedAt: new Date()}, ...prevState.slice(foundDocumentIndex + 1)]
    //         }
    //     }
    //     return prevState;
    // });
}

function onDocumentOperatorChangedEvent(data, updateDocument, setMessage){
    const { actionUserId, actionUserName, activeUserId, documentOperatorName, updatedDocument } = data;

    updateDocument({ data: updatedDocument, message: { setMessage, ifFound: actionUserId !== activeUserId ? `${actionUserName} atribuiu a revisão do documento "${updatedDocument.name}" a ${documentOperatorName}.` : '' } });

    // setVisibleDocuments(prevState => {
    //     if(prevState){
    //         const foundDocumentIndex = prevState.findIndex(foundDocument => foundDocument._id === updatedDocument._id);
    //         if(foundDocumentIndex !== -1){
    //             if(actionUserId !== activeUserId) setMessage(`${actionUserName} atribuiu a revisão do documento "${updatedDocument.name}" a ${documentOperatorName}.`);
    //             return [...prevState.slice(0, foundDocumentIndex), {...updatedDocument, updatedAt: new Date()}, ...prevState.slice(foundDocumentIndex + 1)];
    //         }
    //     }
    //     return prevState;
    // });
}

function onDocumentScheduledSendCancelledEvent(data, updateDocument, setMessage){
    const { updatedDocument } = data;

    updateDocument({ data: updatedDocument, message: { setMessage, ifFound: `O envio programado do documento "${updatedDocument.name}" foi cancelado.` } });

    // setVisibleDocuments(prevState => {
    //     if(prevState){
    //         const foundDocumentIndex = prevState.findIndex(foundDocument => foundDocument._id === updatedDocument._id);
    //         if(foundDocumentIndex !== -1){
    //             setMessage(`O envio programado do documento "${updatedDocument.name}" foi cancelado.`);
    //             return [...prevState.slice(0, foundDocumentIndex), {...updatedDocument, updatedAt: new Date()}, ...prevState.slice(foundDocumentIndex + 1)]
    //         }
    //     }
    //     return prevState;
    // });
}

function onDocumentSentEvent(data, updateDocument, setMessage){
    const { actionUserName, sentDocument } = data;

    updateDocument({ data: sentDocument, message: { setMessage, ifFound: `O documento "${sentDocument.name}" foi revisado${actionUserName ? ` por ${actionUserName}` : ''}.` } });

    // setVisibleDocuments(prevState => {
    //     if(prevState){
    //         const foundDocumentIndex = prevState.findIndex(foundDocument => foundDocument._id === sentDocument._id);
    //         if(foundDocumentIndex !== -1){
    //             setMessage(`O documento "${sentDocument.name}" foi revisado${actionUserName ? ` por ${actionUserName}` : ''}.`);
    //             return [...prevState.slice(0, foundDocumentIndex), {...sentDocument, updatedAt: new Date()}, ...prevState.slice(foundDocumentIndex + 1)];
    //         }
    //     }
    //     return prevState;
    // });
}

function onDocumentSentInPendingDocumentsPageEvent(data, deleteDocument, setMessage){
    const { sentDocument } = data;

    deleteDocument({ data: sentDocument._id, message: { setMessage, ifFound: `O documento "${sentDocument.name}" foi enviado.` } });

    // setVisibleDocuments(prevState => {
    //     if(prevState){
    //         const foundDocumentIndex = prevState.findIndex(foundDocument => foundDocument._id === sentDocument._id);
    //         if(foundDocumentIndex !== -1){
    //             setMessage(`O documento "${sentDocument.name}" foi enviado.`);
    //             return [...prevState.slice(0, foundDocumentIndex), ...prevState.slice(foundDocumentIndex + 1)];
    //         }
    //     }
    //     return prevState;
    // });
}

function onDocumentSentStatusChangedToFalseEvent(data, updateOrAddDocument, setMessage){
    const { newDocument } = data;

    updateOrAddDocument(newDocument);

    // setVisibleDocuments(prevState => {
    //     if(prevState){
    //         const foundDocumentIndex = prevState.findIndex(foundDocument => foundDocument._id === newDocument._id);
    //         if(foundDocumentIndex !== -1){
    //             return [...prevState.slice(0, foundDocumentIndex), {...newDocument, updatedAt: new Date()}, ...prevState.slice(foundDocumentIndex + 1)];
    //         }
    //         return [...prevState, newDocument];
    //     }
    //     return prevState;
    // });
    setMessage(`O documento "${newDocument.name}" foi reaberto e disponibilizado nesta página.`);
}

function onDocumentSignedEvent(data, updateDocument, setMessage, shouldAlwaysSendMessage){
    const { updatedDocument } = data;

    updateDocument({ data: updatedDocument, message: { setMessage, ifFound: !shouldAlwaysSendMessage ? `O documento "${updatedDocument.name}" foi assinado${data.signerName ? ` por ${data.signerName}` : ''}` : '' } });
    if(shouldAlwaysSendMessage) setMessage(`O documento "${updatedDocument.name}" foi assinado${data.signerName ? ` por ${data.signerName}` : ''}`);

    // setVisibleDocuments(prevState => {
    //     if(prevState){
    //         const foundDocumentIndex = prevState.findIndex(foundDocument => foundDocument._id === updatedDocument._id);
    //         if(foundDocumentIndex !== -1){
    //             if(!shouldAlwaysSendMessage) setMessage(`O documento "${updatedDocument.name}" foi assinado${data.signerName ? ` por ${data.signerName}` : ''}`);
    //             return [...prevState.slice(0, foundDocumentIndex), {...updatedDocument, updatedAt: new Date()}, ...prevState.slice(foundDocumentIndex + 1)];
    //         }
    //     }
    //     return prevState;
    // });
    // if(shouldAlwaysSendMessage) setMessage(`O documento "${updatedDocument.name}" foi assinado${data.signerName ? ` por ${data.signerName}` : ''}`);
}

export {
    onDocumentDeletedEvent, onDocumentFlaggedEvent, onDocumentFlaggedInPendingDocumentsPageEvent, onDocumentFolderChangedEvent, onDocumentGeneratedEvent,
    onDocumentGroupChangedEvent, onDocumentNameChangedEvent, onDocumentOperatorChangedEvent, onDocumentScheduledSendCancelledEvent,
    onDocumentSentEvent, onDocumentSentInPendingDocumentsPageEvent, onDocumentSentStatusChangedToFalseEvent, onDocumentSignedEvent
}