import MenuBtn from './MenuBtn';
import Sidenav from './Sidenav';

function DesktopSidenav({account, menuItems}){
    return (
        <Sidenav account={account}>
            {
                menuItems
                .filter(menuItem => menuItem.visible)
                .map((menuItem, menuItemIndex) => {
                    let highlights;
                    if(menuItem.highlight) highlights = menuItem.highlight.map(highlight => highlight.path);
                    return (
                        <MenuBtn
                            key={menuItemIndex}
                            path={menuItem.path}
                            label={menuItem.label}
                            icon={menuItem.icon}
                            highlight={highlights}
                            onClick={menuItem.onButtonClick}
                            onClickAdditionalAction={menuItem.onClickAdditionalAction}
                            routes={menuItem.routes}
                            badge={menuItem.badgeId}
                        >{menuItem.badgeValue}</MenuBtn>
                    );
                })
            }
        </Sidenav>
    );
}

export default DesktopSidenav;