import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Slide, TextField, Typography } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '../Autocomplete';
import FavoriteProjects from '../FavoriteProjects';
import FilesField from '../FilesField';
import Input from '../Input';
import Select from '../Select';
import { getProjectNameById } from '../../utils/common';
import { SERVER_PATH } from '../../utils/constants';
import { useAppCtxAPI, useAppCtxActiveUser } from '../../context/SystemContext';
import { useSocket } from '../../context/SocketContext';
import axios from 'axios';
import { sortByKey } from '../../utils/filters';

const AddClearanceQuestionWindow = ({
    open, onClose, activeUserIsOperator, projects, selectedFolder, selectedFolderType, selectedTemplate, setQuestions, users, actionCallback,
}) => {
    const { toast } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const {createSocketConnection} = useSocket();
    const socket = createSocketConnection();
    const filesFieldId = 'filesField';
    const [loadingPage, setLoadingPage] = useState(null);
    const [selectedClient, set_selectedClient] = useState(null);
    const [projectOptions, set_projectOptions] = useState([]);
    const [selectedProject, set_selectedProject] = useState('');
    const [groupOptions, set_groupOptions] = useState([]);
    const [selectedGroup, set_selectedGroup] = useState('');
    const [questionSubject, set_questionSubject] = useState('');
    const [episode, setEpisode] = useState('');
    const [scriptTreatment, setScriptTreatment] = useState('');
    const [scene, setScene] = useState('');
    const [questionContent, set_questionContent] = useState('');
    const [filesList, set_filesList] = useState([]);
    const [dialogTitle, setDialogTitle] = useState('');
    const [userName, setUserName] = useState('');
    const [rightTypesOptions, setRightTypesOptions] = useState([]);
    const [selectedRightType, setSelectedRightType] = useState(null);

    useEffect(() => {
        if(open){
            if(selectedFolder){
                if(selectedFolder.client){
                    handle_selectedClientChange(selectedFolder.client);
                    handle_selectedProjectChange(selectedFolder._id);
                } else {
                    handle_selectedClientChange(selectedFolder);
                    handle_selectedProjectChange('none');
                }
            } else {
                clearForm();
            }
        }
    }, [open]);

    useEffect(() => {
        if(selectedTemplate){
            const currentRightTypesOptions = selectedTemplate.rightTypes.sort(sortByKey('name')).map(rightType => rightType._id);
            currentRightTypesOptions.push('other');
            setRightTypesOptions(currentRightTypesOptions);
        }
    }, [selectedTemplate]);

    const getRightTypeOptionLabel = (rightTypeId) => {
        const currentRightTypesOption = selectedTemplate.rightTypes.find(rightType => rightType._id === rightTypeId);
        if(currentRightTypesOption) return currentRightTypesOption.name;
        return 'Outro/Não sei';
    };

    const handle_selectedClientChange = (newValue) => {
        set_selectedClient(newValue);
        if(newValue){
            let clientProjects = projects.filter(project => project.client === newValue);
            let newProjectOptions = clientProjects.map(project => ({value: project._id, label: project.name}));
            set_projectOptions(newProjectOptions);
        } else {
            set_projectOptions([]);
        }
        set_selectedProject('');
        set_groupOptions([]);
        set_selectedGroup('');
    };
    const getFolder = (projects, projectId) => {
        let folder = projects.find(p => p._id === (projectId !== 'none' ? projectId : selectedClient));
        return folder;
    };
    const handle_selectedProjectChange = (newValue) => {
        set_selectedProject(newValue);
        let groups = [];
        let folder = getFolder(projects, newValue);
        if(folder && folder.useGroups){
            if(activeUser){
                const folderGroups = folder.groups;
                const noGroupIds = ['&all', '&none', '~any;'];
                // const group_any = { value:'~any;', label:'Sem grupo (disponível para todos)' };
                const activeUserInAdminGroup = folderGroups.find(folderGroup => folderGroup.id === '2' && folderGroup.users.includes(activeUser._id));
                if(activeUserInAdminGroup){
                    folderGroups.forEach(group => {
                        if(!noGroupIds.includes(group.id)) groups.push({value: group.id, label: group.name});
                    });
                } else {
                    const activeUserInManagementGroup = folderGroups.find(folderGroup => folderGroup.id === '1' && folderGroup.users.includes(activeUser._id));
                    if(activeUserInManagementGroup){
                        folderGroups.forEach(group => {
                            if(!noGroupIds.includes(group.id) && group.id !== '2') groups.push({value: group.id, label: group.name});
                        });
                    } else {
                        folderGroups.forEach(group => {
                            if(!noGroupIds.includes(group.id) && group.users.includes(activeUser._id)) groups.push({value: group.id, label: group.name});
                        });
                    }
                }
                // groups.unshift(group_any);
            }
            set_groupOptions(groups);
        } else {
            set_groupOptions([]);
        }
        if(groups.length >= 1){
            const foundManagementGroup = groups.find(option => option.value == '1' || option.value == '2');
            if(foundManagementGroup){
                set_selectedGroup('1');
            } else {
                set_selectedGroup(groups[0].value);
            }
        } else {
            set_selectedGroup('');
        }
    };
    const handleSubmit = async () => {
        setLoadingPage(true);

        let formData = new FormData();
        formData.append('folderId', selectedProject !== 'none' ? selectedProject : selectedClient);
        if(selectedGroup){
            formData.append('groupId', selectedGroup !== '~any;' ? selectedGroup : '');
        } else {
            formData.append('groupId', '');
        }
        formData.append('subject', questionSubject);
        if(selectedFolderType === 'series') formData.append('episode', episode || 1);
        formData.append('scriptTreatment', scriptTreatment);
        formData.append('scene', scene);
        formData.append('rightType', selectedRightType || '');
        formData.append('content', questionContent);
        
        const filesInput = document.getElementById(filesFieldId);
        if(filesInput && filesList.length >= 1){
            let files = filesInput.files;
            if(files.length > 0){
                for (let i = 0; i < files.length; i++) {
                    formData.append('file' + i, files[i]);
                }
            }
        }

        await axios.post(SERVER_PATH + '/data/clearance/question/new', formData, {headers: {'Content-Type': 'multipart/form-data'}})
        .then(res => {
            clearForm();
            if(filesList.length >= 1){
                if(filesInput) filesInput.value = '';
                set_filesList([]);
            }
            onClose();
            toast('A dúvida foi cadastrada.');
            const newQuestion = res.data;
            if(setQuestions){
                if(newQuestion){
                    setQuestions(prevState => {
                        if(Array.isArray(prevState)) return [...prevState, newQuestion];
                        return [newQuestion];
                    });
                }
            }
            if(actionCallback) actionCallback(newQuestion);
        })
        .catch(err => {
            toast(err.response.data);
            if(filesList.length >= 1){
                if(filesInput) filesInput.value = '';
                set_filesList([]);
            }
        });
        setLoadingPage(false);
    }
    const clearForm = () => {
        set_questionSubject('');
        setEpisode('');
        setScriptTreatment('');
        setScene('');
        setSelectedRightType(null);
        set_questionContent('');
        
        set_groupOptions([]);
        set_selectedGroup(null);
        set_projectOptions([]);
        set_selectedProject(null);
        set_selectedClient(null);
    };
    const handleFavoriteClick = (folderType, folderId) => {
        if(folderType === 'projects'){
            let projectData = projects.find(p => p._id == folderId);
            if(projectData){
                handle_selectedClientChange(projectData.client);
                handle_selectedProjectChange(folderId);
            }
        } else {
            handle_selectedClientChange(folderId);
            handle_selectedProjectChange('none');
        }
    };

    const triggerFileInputClick = () => {
        const fileInput = document.getElementById(filesFieldId);
        if(fileInput) fileInput.click();
    };

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    return (
        <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}
            // TransitionComponent={Transition}
            // keepMounted
            fullWidth
            maxWidth="md"
            // fullScreen
        >
            <DialogTitle id="simple-dialog-title">
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs>
                        <Typography variant="h6">{dialogTitle || 'Enviar consulta'}</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton
                            sx={{
                                position: 'absolute',
                                right: '8px',
                                top: '8px',
                                color: theme => theme.palette.grey[500],
                            }}
                            aria-label="close"
                            onClick={onClose}
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                {
                    (!selectedFolder && projects && projects[0]) &&
                    <Box mb={2}>
                        <FavoriteProjects handleFavoriteClick={handleFavoriteClick} />
                    </Box>
                }
                {
                    (!selectedFolder && projects && projects[0]) &&
                    <Box>
                        <Box mb={2}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={12} md={6}>
                                    <Autocomplete
                                        label="Cliente"
                                        value={selectedClient}
                                        onChange={(e, value) => handle_selectedClientChange(value)} options={projects ? projects.filter(p => !p.client).map(p => p._id) : []}
                                        getOptionLabel={(option) => getProjectNameById(projects, option)}
                                    />
                                </Grid>
                                {
                                    selectedClient &&
                                    <Grid item xs>
                                        <Select
                                            label="Projeto"
                                            options={[{value: 'none', label: 'Nenhum'}, ...projectOptions]}
                                            value={selectedProject} onChange={(e) => handle_selectedProjectChange(e.target.value)}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                    </Box>
                }
                {
                    (groupOptions.length >= 1) &&
                    <Box mb={2}>
                        <Select label="Grupo" value={selectedGroup} onChange={(e) => set_selectedGroup(e.target.value)} options={groupOptions} />
                    </Box>
                }
                {
                    !activeUser &&
                    <Box mb={2}>
                        <Input
                            placeholder="Seu nome"
                            fullWidth
                            value={userName} onChange={(e) => setUserName(e.target.value)}
                        />
                    </Box>
                }
                <Box mb={2}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12} sm={5}>
                            <TextField
                                variant="standard"
                                autoFocus
                                fullWidth
                                label="Assunto"
                                placeholder="Descrição do assunto"
                                value={questionSubject}
                                onChange={(e) => set_questionSubject(e.target.value)}
                                onBlur={() => setDialogTitle(questionSubject)} />
                        </Grid>
                        <Grid item xs container spacing={1} alignItems="center">
                            {
                                selectedFolderType === 'series' &&
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        variant="standard"
                                        fullWidth
                                        type="number"
                                        InputProps={{ inputProps: { min: 0 } }}
                                        label="Episódio"
                                        placeholder="Número"
                                        value={episode}
                                        onChange={(e) => setEpisode(e.target.value)} />
                                </Grid>
                            }
                            <Grid item xs container spacing={1} alignItems="center">
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="standard"
                                        fullWidth
                                        type="number"
                                        InputProps={{ inputProps: { min: 0 } }}
                                        label="Tratamento"
                                        placeholder="Número"
                                        value={scriptTreatment}
                                        onChange={(e) => setScriptTreatment(e.target.value)} />
                                </Grid>
                                <Grid item xs>
                                    <TextField
                                        variant="standard"
                                        fullWidth
                                        type="number"
                                        InputProps={{ inputProps: { min: 0 } }}
                                        label="Cena"
                                        placeholder="Número"
                                        value={scene}
                                        onChange={(e) => setScene(e.target.value)} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                {
                    (activeUserIsOperator && selectedTemplate) &&
                    <Box mb={2}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={12} sm={9}>
                                <Autocomplete
                                    label="Direito envolvido"
                                    options={rightTypesOptions}
                                    getOptionLabel={(option) => getRightTypeOptionLabel(option)}
                                    value={selectedRightType} onChange={(e, newValue) => setSelectedRightType(newValue)}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                }
                <Box pb={2}>
                    <Box mb={1}>
                        <Input
                            placeholder="Descrição detalhada da sua dúvida (apresente informações sobre o material, o destaque e o contexto)"
                            fullWidth multiline minRows={5} maxRows={20}
                            value={questionContent} onChange={(e) => set_questionContent(e.target.value)}
                        />
                    </Box>
                    <FilesField
                        multiple
                        filesFieldId={filesFieldId}
                        attachFileButtonComponent="none"
                        accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*"
                        filesList={filesList} setFilesList={set_filesList}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <IconButton size="small" onClick={triggerFileInputClick}>
                    <AttachFileIcon />
                </IconButton>
                <Button
                    onClick={handleSubmit}
                    variant="contained" color="primary"
                    disabled={
                        loadingPage ||
                        !selectedProject
                        || !questionSubject || !questionContent
                        || (selectedProject && getFolder(projects, selectedProject).useGroups && !selectedGroup)
                    }
                    startIcon={loadingPage && <CircularProgress size={20} />}
                >
                    Enviar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddClearanceQuestionWindow;