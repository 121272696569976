import { useEffect, useRef, useState } from 'react';
import { useAppContext } from '../../../../context/SystemContext';
import { useForm } from '../../context/FormContext';
import FormQuestionCurrency from '../../../../components/FormQuestionCurrency';

const CurrencyQuestion = ({question}) => {
    const { formEffects } = useAppContext();
    const { initialForm, set_userForm, setFormValidation } = useForm();
    const [value, setValue] = useState(null);
    const touchedField = useRef(false);
    const [userFormQuestion, setUserFormQuestion] = useState(null);

    useEffect(() => {
        if(userFormQuestion){
            set_userForm(prevState => ({
                ...prevState,
                [question.name]: userFormQuestion
            }));
        }
    }, [userFormQuestion]);
    
    useEffect(() => {
        setUserFormQuestion(prevState => ({
            ...prevState,
            id: question.id,
            type: 'currency',
            typeSpecifics: question.typeSpecifics
        }));
    }, []);

    useEffect(() => {
        setValue(initialForm[question.name]);
    }, [initialForm]);

    const checkValidation = () => {
        const questionValid = formEffects.currency.checkValidation(userFormQuestion);
        setFormValidation(prevState => ({...prevState, [question.name]: !questionValid}));
    };
    
    useEffect(() => {
        if((userFormQuestion && userFormQuestion.input) || touchedField.current){
            touchedField.current = true;
            checkValidation();
        }
    }, [userFormQuestion]);

    const handleChange = (questionName, updates) => {
        const input = !!updates.value ? formEffects.currency.getTypeSpecificInput(updates.value, question.typeSpecifics) : '';
        setUserFormQuestion(prevState => ({
            ...prevState,
            value: updates.value,
            input,
        }));
    };
    
    return (
        <FormQuestionCurrency
            onChange={handleChange}
            initialValue={value}
            question={question}
        />
    );
}

export default CurrencyQuestion;