import { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Box, CircularProgress, Grid } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import AlertDialog from '../AlertDialog';
import ChangeOperatorMenu from '../ChangeOperatorMenu';
import ClearanceItemsList from '../clearance/ClearanceItemsList';
// import ClearanceQuestionsList from '../clearance/ClearanceQuestionsList';
import CommentsWindow from '../clearance/CommentsWindow';
import ImageWindow from '../ImageWindow';
import Input from '../Input';
import LoaderEllipsis from '../LoaderEllipsis';
import NoResults from '../NoResults';
import ReplyQuestionWindow from './ReplyQuestionWindow';
import RiskLevel from './RiskLevel';
import SectionTitle from '../SectionTitle';
import ViewBox from '../ViewBox';
import { useAppCtxAPI, useAppCtxActiveUser } from '../../context/SystemContext';
import { useSocket } from '../../context/SocketContext';
import { useClearanceCtxActiveClient } from '../../context/ClearanceContext';
import { updateStateChangeArrayItemWith_id, updateStateDeleteArrayItemBy_id } from '../../utils/common';
import { CLEARANCE_RISK_OPTIONS, ERROR_MESSAGE_CHANGES_UNDONE, SERVER_PATH, SUCCESS_MESSAGE_SAVED } from '../../utils/constants';

const SearchPage = ({
    activeUserIsTrueOperator, authorityRiskLevel, numberOfApprovalsRequired, operators, projects, restrictedUserFolder, selectedFolderType, selectedTemplate, setPendingClearanceCount, users, reportType
}) => {
    const { showOperatorsMenu, updateOneTrueOperators } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const clearanceCtxActiveClient = useClearanceCtxActiveClient();
    const {createSocketConnection} = useSocket();
    const socket = createSocketConnection();
    const [loading, setLoading] = useState(false);
    const [notification, setNotification] = useState({});
    const [searchInput, setSearchInput] = useState('');
    const [doneSearch, setDoneSearch] = useState('');
    const defaultFilters = {
        createdBy: 'all',
        folder: 'all',
        folderGroup: 'all',
        rightType: 'all',
        riskLevel: 'all',
        status: 'all',
        search: ''
    };
    const [filters, setFilters] = useState(defaultFilters);
    const [foundQuestions, setFoundQuestions] = useState(null);
    const [foundReportItems, setFoundReportItems] = useState(null);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [replyQuestionWindowOpen, setReplyQuestionWindowOpen] = useState(false);
    const [visibleImageUrl, setVisibleImageUrl] = useState('');
    const [selectedReportItem, setSelectedReportItem] = useState(null);
    const [commentsWindowOpen, setCommentsWindowOpen] = useState(false);
    const [selectedFolder, setSelectedFolder] = useState(false);
    const [clearanceQuestionsUpdatedAt, setClearanceQuestionsUpdatedAt] = useState(null);
    const [clearanceReportItemsUpdatedAt, setClearanceReportItemsUpdatedAt] = useState(null);
    const [isSetQuestionDoneDialogOpen, setSetQuestionDoneDialogOpen] = useState(false);

    useEffect(() => {
        if(restrictedUserFolder){
            setSelectedFolder(restrictedUserFolder);
        }
    }, [restrictedUserFolder]);

    const handleSearchKeyPress = (e) => {
        if(e.key === 'Enter') handleSearch();
    };
    
    const handleSearch = async () => {
        setLoading(true);
        setDoneSearch(searchInput);
        await axios.post(SERVER_PATH + '/data/clearance/search', {
            folderId: restrictedUserFolder._id,
            search: searchInput
        })
        .then(res => {
            setFoundQuestions(res.data.questions);
            setClearanceQuestionsUpdatedAt(new Date());
            setFoundReportItems(res.data.reportItems);
            setClearanceReportItemsUpdatedAt(new Date());
        })
        .catch(err => {});
        setLoading(false);
    };

    const handleClearanceQuestionBodyRow = useCallback((clearanceQuestion) => {
        if(clearanceQuestion){ 
            handleReplyQuestionClick(clearanceQuestion);
        }
    }, []);
    const handleBodyRowContextMenu = useCallback((e, clearanceQuestion) => {
        // if(activeUserIsTrueOperator){
        //     e.preventDefault();
        //     e.stopPropagation();
        //     setSelectedQuestion(clearanceQuestion);
        //     setActionMenuAnchorPosition({
        //         mouseX: e.clientX - 2,
        //         mouseY: e.clientY - 4,
        //     });
        //     setActionMenuOpen(true);
        // }
    }, []);

    const handleOperatorMenuButtonPress = (e, question) => {
        e.preventDefault();
        e.stopPropagation();
        showOperatorsMenu(e.target, question);
    };

    const handleCommentsButtonClick = useCallback((e, reportItem) => {
        e.stopPropagation();
        setSelectedReportItem(reportItem);
        setCommentsWindowOpen(true);
    }, []);
    const handleClearanceItemBoxClick = useCallback((reportItem) => {
        setSelectedReportItem(reportItem);
        setCommentsWindowOpen(true);
    }, []);

    const handleReplyQuestionClick = useCallback((question) => {
        setSelectedQuestion(question);
        setReplyQuestionWindowOpen(true);
    }, []);

    const handleCancel = async () => {
        const confirm = window.confirm('Tem certeza que quer deletar esse registro?');
        if(confirm){
            const clearanceQuestionsCopy = [...foundQuestions];
            try {
                updateStateDeleteArrayItemBy_id(selectedQuestion._id, setFoundQuestions);
                setClearanceQuestionsUpdatedAt(new Date());
                const updates = {
                    deleted: true,
                    deletedAt: new Date(),
                    deletedBy: activeUser._id
                };
                await updateOneTrueOperators('ClearanceQuestions', selectedQuestion._id, updates);
            } catch (error) {
                setFoundQuestions(clearanceQuestionsCopy);
                setClearanceQuestionsUpdatedAt(new Date());
                setNotification({ severity: 'error', message: ERROR_MESSAGE_CHANGES_UNDONE });
            }
        }
    };

    const handleQuestionDoneButtonPress = (e, question) => {
        e.stopPropagation();
        setSelectedQuestion(question);
        setSetQuestionDoneDialogOpen(true);
    };

    const markAsDone = async () => {
        setSetQuestionDoneDialogOpen(false);
        const selectedQuestionCopy = {...selectedQuestion};
        updateStateChangeArrayItemWith_id({ ...selectedQuestion, savingDone: true }, setFoundQuestions);
        await axios.get(SERVER_PATH + `/data/clearance/question/${selectedQuestion.isReportItemComment ? selectedQuestion.reportItemId : selectedQuestion._id}/done${selectedQuestion.isReportItemComment ? '?isReportItemComment=true' : ''}`)
        .then(res => {
            setNotification({message: SUCCESS_MESSAGE_SAVED});
            let updates = {
                done: true,
                savingDone: false,
                updatedAt: new Date()
            }
            if(!selectedQuestion.isReportItemComment){
                updates.operatorId = activeUser._id;
                updates.firstReplyDeliveredByOperator = true;
                updates.operatorFullName = activeUser.fullName;
                updates.operatorImg = activeUser.img;
            }
            updateStateChangeArrayItemWith_id({
                ...selectedQuestion,
                ...updates
            }, setFoundQuestions);
            if(filters.status === 'pending' || selectedQuestion.isReportItemComment){
                updateStateDeleteArrayItemBy_id(selectedQuestion._id, setFoundQuestions);
                setClearanceQuestionsUpdatedAt(new Date());
                if(selectedQuestion.isReportItemComment){
                    const comments = selectedQuestion.comments;
                    const unreadComments = comments.filter(comment => !comment.readBy.includes(activeUser._id) && !!comment._id);
                    if(unreadComments.length >= 1){
                        markCommentsAsRead(unreadComments.map(comment => comment._id));
                    }
                }
            }

            if(res.data.pendingClearanceCount && setPendingClearanceCount){
                setPendingClearanceCount(res.data.pendingClearanceCount);
                if(socket) socket.emit('NUMBER OF CLEARANCE QUESTIONS UPDATED', {count: res.data.pendingClearanceCount, clientId: clearanceCtxActiveClient._id});
            }
        })
        .catch(err => {
            updateStateChangeArrayItemWith_id(selectedQuestionCopy, setFoundQuestions);
            setNotification({message: err.response.data, severity: 'error'});
        });
    };

    const markCommentsAsRead = (comments) => {
        axios.post(SERVER_PATH + '/data/clearance/comments/read', { reportItemId: selectedQuestion.reportItemId, comments })
        .then(res => {})
        .catch(err => {});
    }

    const handleCommentInputKeyPress = useCallback((e) => {
        // if(e.key === '@'){
        //     setFolderUsersMenuAnchorEl(e.target);
        //     setFolderUsersMenuOpen(true);
        // }
    }, []);

    return (
        <ViewBox message={notification}>
            <Box mb={2}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12} sm={6} md={3}>
                        <Input
                            fullWidth
                            disabled={loading}
                            placeholder="Busca"
                            value={searchInput} onChange={(e) => setSearchInput(e.target.value)}
                            onKeyPress={handleSearchKeyPress}
                            endIcon={loading ? <CircularProgress size={20} /> : <SearchIcon />}
                            endIconOnClick={handleSearch}
                        />
                    </Grid>
                </Grid>
            </Box>
            {
                loading
                ? <LoaderEllipsis primary />
                :
                <>
                    {
                        doneSearch &&
                        <Box>
                            <SectionTitle title="Consultas" />
                            {/* <ClearanceQuestionsList
                                activeUserIsOperator={activeUserIsTrueOperator}
                                handleBodyRow={handleClearanceQuestionBodyRow}
                                handleBodyRowContextMenu={handleBodyRowContextMenu}
                                handleOperatorMenuButtonPress={handleOperatorMenuButtonPress}
                                handleQuestionDoneButtonPress={handleQuestionDoneButtonPress}
                                list={foundQuestions}
                                numberOfApprovalsRequired={numberOfApprovalsRequired}            
                                projects={projects}
                                selectedFolder={selectedFolder}
                                users={users}
                                updatedAt={clearanceQuestionsUpdatedAt}
                            /> */}
                            <SectionTitle title="Relatórios" />
                            {
                                foundReportItems &&
                                <Box>
                                    {
                                        foundReportItems.length === 0
                                        ? <NoResults text="Nenhum resultado em relatórios." />
                                        :
                                        <Box>
                                            <ClearanceItemsList
                                                activeUser={activeUser}
                                                activeUserIsTrueOperator={activeUserIsTrueOperator}
                                                clearanceItems={foundReportItems}
                                                handleCommentsButtonClick={handleCommentsButtonClick}
                                                handleClearanceItemBoxClick={handleClearanceItemBoxClick}
                                                hideCommentsIconButton
                                                numberOfApprovalsRequired={numberOfApprovalsRequired}
                                                selectedFolderTemplate={selectedTemplate}
                                                selectedFolderType={selectedFolderType}
                                                updatedAt={clearanceReportItemsUpdatedAt}
                                                users={users}
                                            />
                                        </Box>
                                    }
                                </Box>
                            }
                        </Box>   
                    }
                </>
            }
            <ReplyQuestionWindow
                open={replyQuestionWindowOpen} onClose={() => setReplyQuestionWindowOpen(false)}
                authorityRiskLevel={authorityRiskLevel}
                handleCommentInputKeyPress={handleCommentInputKeyPress}
                numberOfApprovalsRequired={numberOfApprovalsRequired}
                selectedFolderType={selectedFolderType}
                selectedQuestion={selectedQuestion}
                selectedTemplate={selectedTemplate}
                setVisibleImageUrl={setVisibleImageUrl}
                setQuestions={setFoundQuestions}
                activeUserIsOperator={activeUserIsTrueOperator} 
            />
            <CommentsWindow
                open={commentsWindowOpen} setOpen={setCommentsWindowOpen}
                authorityRiskLevel={authorityRiskLevel}
                numberOfApprovalsRequired={numberOfApprovalsRequired}
                selectedClearanceItem={selectedReportItem} setSelectedClearanceItem={setSelectedReportItem}
                setClearanceItems={setFoundReportItems}
                users={users}
            />
            <ImageWindow imageUrl={visibleImageUrl} setImageUrl={setVisibleImageUrl} />
            {
                operators &&
                <ChangeOperatorMenu
                    collection="ClearanceQuestions" operatorIdKey="operatorId"
                    document={selectedQuestion} setDocuments={setFoundQuestions}
                />
            }
            <AlertDialog
                open={isSetQuestionDoneDialogOpen} onClose={() => setSetQuestionDoneDialogOpen(false)}
                title="Tem certeza?" text={`Você quer marcar ${selectedQuestion?.isReportItemComment ? 'o comentário' : 'a consulta'} "${selectedQuestion?.subject || ''}" como respondid${selectedQuestion?.isReportItemComment ? 'o' : 'a'} sem enviar uma resposta?`}
                okButtonOnClick={markAsDone}
            />
        </ViewBox>
    );
};

export default SearchPage;