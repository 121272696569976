import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import CenteredCircularProgress from '../../../../../components/CenteredCircularProgress';
import DataListItem from '../../../../../components/DataListItem';
import ListsWindow from './components/ListsWindow';
import ViewBox from '../../../../../components/ViewBox';
import { useAppCtxTemplateLists } from '../../../../../context/SystemContext';
import { useSocket, useSocketCtxConnectedAt } from '../../../../../context/SocketContext';
import { useDocumentsAPI, useDocumentsCtxLoadingTemplates, useDocumentsCtxTemplates } from '../../../../../context/DocumentsContext';
import { sortByKey } from '../../../../../utils/filters';

const Lists = () => {
    const templateLists = useAppCtxTemplateLists();
    const { shouldUpdateTemplates } = useSocket();
    const socketConnectedAt = useSocketCtxConnectedAt();
    const { dispatch: dispatchDocuments } = useDocumentsAPI();
    const loadingTemplates = useDocumentsCtxLoadingTemplates();
    const templates = useDocumentsCtxTemplates();
    const [loadingPage, setLoadingPage] = useState(true);
    const [visibleTemplates, setVisibleTemplates] = useState([]);
    const [lists, set_lists] = useState([]);
    const [selectedList, set_selectedList] = useState(null);
    const [listsWindowOpen, set_listsWindowOpen] = useState(false);

    useEffect(() => {
        setLoadingPage(loadingTemplates);
    }, [loadingTemplates]);

    useEffect(() => {
        if(shouldUpdateTemplates){
            dispatchDocuments({type: 'LOAD TEMPLATES'});
        }
    }, [socketConnectedAt]);
    
    useEffect(() => {
        if(templates){
            const currentTemplates = templates.sort(sortByKey('name'));
            setVisibleTemplates(currentTemplates);
        }
    }, [templates]);
    
    useEffect(() => {
        let currentLists = [];
        if(templateLists?.length !== 0 && visibleTemplates?.length !== 0){
            templateLists.map(list => {
                const listTemplates = [];
                list.list.forEach(templateId => {
                    const foundTemplate = visibleTemplates.find(template => template._id === templateId);
                    const templateName = foundTemplate?.name || '';
                    const templateDescription = foundTemplate?.description || '';
                    const currentTemplate = {
                        _id: templateId,
                        name: templateName,
                        description: templateDescription,
                        label: `${templateName}${templateDescription ? ` (${templateDescription})` : ''}`
                    }
                    listTemplates.push(currentTemplate);
                });
                
                const currentList = {...list, list: listTemplates};
                currentLists.push(currentList);
            })
        }
        set_lists(currentLists);
    }, [templateLists, visibleTemplates]);

    const handle_newListClick = () => {
        set_selectedList(null);
        set_listsWindowOpen(true);
    };
    const handle_editListClick = (clickedList) => {
        set_selectedList(clickedList);
        set_listsWindowOpen(true);
    };

    return (
        <ViewBox>
            {
                loadingPage
                ? <CenteredCircularProgress />
                :
                <Box>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={handle_newListClick}>Nova lista</Button>
                        </Grid>
                    </Grid>
                    {
                        !!lists &&
                        <List id="lists-list">
                            {
                                lists.map((list) => (
                                    <DataListItem key={list._id}>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item xs={2} sm={1}>
                                                <Fab size="small" color="primary" onClick={() => handle_editListClick(list)}><EditIcon /></Fab>
                                            </Grid>
                                            <Grid item xs={10} sm={2}>
                                                <Typography variant="h6">{list.name}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm>
                                                <List>
                                                    {
                                                        list.list
                                                        .sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0)
                                                        .map(listTemplate => (
                                                            <Typography key={listTemplate._id} variant="body2">{listTemplate.label}</Typography>
                                                        ))
                                                    }
                                                </List>
                                            </Grid>
                                        </Grid>
                                    </DataListItem>
                                ))
                            }
                        </List>
                    }
                </Box>
            }
            <ListsWindow
                open={listsWindowOpen} onClose={() => set_listsWindowOpen(false)} selectedList={selectedList}
                templates={visibleTemplates}
            />
        </ViewBox>
    );
};

export default Lists;