import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Select from '../../../components/Select';

const NewEffect = ({ handleChange, ifTrueOptions, setDisabled }) => {
    const [value, setValue] = useState({ typeId: '', output: '', elseOutput: '', form: false });

    useEffect(() => {
        handleChange(value);
        if(setDisabled){
            let isDisabled = false;
            if(!value.typeId) isDisabled = true;
            setDisabled(isDisabled);
        }
    }, [value]);

    if(ifTrueOptions){
        return (
            <Box>
                <Box mb={1}>
                    <Typography variant="h6">Se o efeito abaixo for verdadeiro...</Typography>
                </Box>
                <Select
                    options={ifTrueOptions}
                    value={value.typeId || 'select'}
                    onChange={(e) => setValue(prevState => ({...prevState, typeId: e.target.value}))}
                />
            </Box>
        );
    }
    return null;
};

export default NewEffect;