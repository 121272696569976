import { useEffect, useRef } from 'react';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import { useAppCtxLoading } from '../context/SystemContext';
import { useOperatorCtxFavoriteProjects } from '../pages/Operator/context/OperatorContext';

const classes = {
    favoriteChips: {
        margin: '0 1px 1px 1px'
    },
};

const FavoriteProjects = ({handleFavoriteClick, maxHeight}) => {
    const loading = useAppCtxLoading();
    const favoriteFolders = useOperatorCtxFavoriteProjects();
    const stickyContainerRef = useRef();

    useEffect(() => {
        if(stickyContainerRef.current){
            bindEvents();
        }
    }, [stickyContainerRef]);

    const bindEvents = () => {
        stickyContainerRef.current.addEventListener('wheel', (evt) => {
            evt.preventDefault();
            stickyContainerRef.current.scrollLeft += evt.deltaY;
        });        
    };

    let chipsBoxStyle = {};
    if(maxHeight){
        chipsBoxStyle.maxHeight = maxHeight;
        chipsBoxStyle.overflowY = 'auto';
    }

    return (
        <Grid container spacing={1} alignItems="flex-end">
            <Grid item xs style={chipsBoxStyle}>
                {
                    favoriteFolders.map((folder, index) => (
                        <Chip
                            key={index}
                            sx={classes.favoriteChips}
                            disabled={loading}
                            color="primary"
                            size="small"
                            onClick={() => handleFavoriteClick(folder.type, folder.id)}
                            label={folder ? folder.name : null} />
                    ))
                }
            </Grid>
        </Grid>
    );
};

export default FavoriteProjects;