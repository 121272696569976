import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useAppCtxActiveUser } from '../context/SystemContext';

const ActionMenu = ({open, setOpen, anchorEl, anchorPosition, actionMenuItems = []}) => {
    const activeUser = useAppCtxActiveUser();

    const handleClose = () => {
        setOpen(false);
    };
    
    return (
        <Menu
            // keepMounted
            open={open}
            // open={anchorPosition.mouseY !== null && anchorPosition.mouseX !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            // anchorEl={anchorEl}
            anchorPosition={
                anchorPosition.mouseY !== null && anchorPosition.mouseX !== null ? { top: anchorPosition.mouseY, left: anchorPosition.mouseX } : undefined
            }
            onClick={handleClose}
        >
            {
                actionMenuItems
                .filter(item => {
                    if(item.minimumUserType){
                        return activeUser?.type >= item.minimumUserType;
                    }
                    return true;
                })
                .map((item, index) => (
                    <MenuItem key={index} onClick={item.onClick}>
                        {
                            item.icon &&
                            <ListItemIcon>{item.icon}</ListItemIcon>
                        }
                        <ListItemText primary={item.primaryText} secondary={item.secondaryText} />
                    </MenuItem>
                ))
            }
        </Menu>
    );
}

export default ActionMenu;