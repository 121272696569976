const ifCondition = {
    typeIdOptions: [
        {value: 'special', label: 'Importar dados'},
        {value: 'question', label: 'Pergunta'}
    ],
    getTypeSpecificsLabel: ({effectSettings, specialEffects}) => {
        let typeSpecificsLabel = '';
        if(effectSettings.typeId === 'special'){
            const specialEffect = specialEffects.find(specialEffect => specialEffect.value === effectSettings.typeSpecifics);
            if(specialEffect) typeSpecificsLabel = `${specialEffect.label}`;
        } else {
            typeSpecificsLabel = `"${effectSettings.typeSpecifics}"`;
        }
        return typeSpecificsLabel;
    },
    getInputLabel: ({effectSettings}, separator) => {
        let inputLabel = '';
        if(Array.isArray(effectSettings.input)){
            inputLabel = effectSettings.input.map(input => `"${input}"`).join(separator);
        } else {
            inputLabel = `"${effectSettings.input}"`;
        }
        return inputLabel;
    },
    createEffect: ({effectSettings, newEffectId, specialEffects}) => {
        const typeSpecificsLabel = ifCondition.getTypeSpecificsLabel({ effectSettings, specialEffects });
        const inputLabel = ifCondition.getInputLabel({effectSettings}, ' ou ');
        const newEffect = {
            id: newEffectId,
            type: effectSettings.type,
            typeId: effectSettings.typeId,
            typeSpecifics: effectSettings.typeSpecifics,
            input: effectSettings.input,
            output: effectSettings.output || '',
            elseOutput: effectSettings.elseOutput || '',
            question: '[Se]',
            label: `[Se ${typeSpecificsLabel} for igual a ${inputLabel}]`,
            form: false
        }
        return { newEffect };
    },
    newEffectWindowHandleEffectTypeChange: () => {
        const data = {
            typeId: '',
            typeSpecifics: '',
            input: [''],
            output: '',
            elseOutput: ''
        };
        return data;
    },
    setEffectOutput: ({ documentEditorValue, selectedEffect, selectedEffectOutput }) => {
        selectedEffect[selectedEffectOutput.optionIndex] = documentEditorValue;
        return selectedEffect;
    },
    getSelectedEffectOutput: ({ selectedEffect, selectedEffectOutput }) => {
        let newValue = selectedEffect[selectedEffectOutput.optionIndex] || '';
        return newValue;
    },
    getOutputsContent: (data) => {
        let content = '';
        if(data){
            const { scannedEffect } = data;
            if(scannedEffect){
                content += scannedEffect.output || '';
                content += scannedEffect.elseOutput || '';
            }
        }
        return content;
    },
    getFormEditorQuestion: (data) => {
        if(data){
            const { effect, question } = data;
            const formEditorQuestion = {...question};
            formEditorQuestion.typeId = effect.typeId;
            formEditorQuestion.typeSpecifics = effect.typeSpecifics;
            return formEditorQuestion;
        }
        return false;
    }
};

export {
    ifCondition
}