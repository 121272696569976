import { useCallback, useEffect, useState } from 'react';
import { Grid, List, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import TinyTableBodyRow from './TinyTableBodyRow';
import TinyTableListItem from './TinyTableListItem';
import useWindowSize from '../hooks/useWindowSize';

const classes = {
    tableContainer: {
        borderRadius: '4px'
    },
    farLeftTableHeadCell: {
        borderTopLeftRadius: '4px'
    },
    farRightTableHeadCell: {
        borderTopRightRadius: '4px'
    },
};

const TableHeadCell = (props) => <TableCell sx={
    [
        {
            background: 'linear-gradient(#9e9e9e, #bdbdbd)',
            position: 'sticky',
            top: 0,
            zIndex: 2,
            padding: '8px'
        },
        props.additionalSx
    ]
} {...props} />;

const TableBodyCell = (props) => <TableCell sx={{
    fontSize: 14,
    padding: '8px'
}} {...props} />;

const TinyTable = ({
    head, headTop, backgroundColor, body, handleBodyRow, handleBodyRowContextMenu, handleMoreOptionsShownOnMouseOverFab, useTableLayoutWithSmallWindow,
    updatedAt
}) => {
    const { windowWidth } = useWindowSize();
    const [smallWindow, setSmallWindow] = useState(false);

    useEffect(() => {
        if(!useTableLayoutWithSmallWindow){
            if(windowWidth){
                if(windowWidth < 960){
                    setSmallWindow(true);
                }
            }
        }
    }, [windowWidth]);

    const onClick = useCallback((e, row) => {
        if(handleBodyRow) handleBodyRow(row);
    }, []);

    const onContextMenu = useCallback((e, row) => {
        if(handleBodyRowContextMenu) handleBodyRowContextMenu(e, row);
    }, []);

    const onClickFab = (e, row) => {
        if(handleMoreOptionsShownOnMouseOverFab) handleMoreOptionsShownOnMouseOverFab(e, row);
    };

    const TinyTableHead = () => (
        <TableHead>
            <TableRow>
                {
                    head.map((cell, index) => (
                        <TableHeadCell
                            key={index}
                            additionalSx={
                                index === 0 ? classes.farLeftTableHeadCell : (head.length === index + 1 ? classes.farRightTableHeadCell : null)
                            }
                            style={headTop ? { top: headTop } : undefined}
                        >
                            <Grid container justifyContent={cell.justify || 'flex-start'} style={cell.style} onClick={cell.onClick}>
                                <Grid item>{cell.content}</Grid>
                            </Grid>
                        </TableHeadCell>
                    ))
                }
            </TableRow>
        </TableHead>
    );

    return (
        !smallWindow
        ?
        <Paper sx={classes.tableContainer} style={backgroundColor ? {backgroundColor} : undefined}>
            <Table>
                <TinyTableHead />
                <TableBody>
                    {
                        body
                        .map((row, index) => (
                            <TinyTableBodyRow
                                key={index} onClick={onClick} onContextMenu={onContextMenu} row={row}
                                TableBodyCell={TableBodyCell}
                                updatedAt={updatedAt}
                            />
                        ))
                    }
                </TableBody>
            </Table>
        </Paper>
        :
        <List dense>
            {
                body
                .map((row, index) => {
                    return (
                        <TinyTableListItem key={index} handleMoreOptionsShownOnMouseOverFab={handleMoreOptionsShownOnMouseOverFab} head={head} onClick={onClick} onClickFab={onClickFab} row={row} />
                    );
                })
            }
        </List>
    );
};

export default TinyTable;