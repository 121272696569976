import { memo } from 'react';
import moment from 'moment';
import { Avatar, Box, Chip, CircularProgress, Divider, Fab, Grid, IconButton, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CancelIcon from '@mui/icons-material/Cancel';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SendIcon from '@mui/icons-material/Send';
import DataListItem from '../../../../../components/DataListItem';
import DocumentFilesList from '../../../../../components/DocumentFilesList';
import PendingDocLink from '../../../../../components/PendingDocLink';
import PendingDocumentComment from './PendingDocumentListItem/components/PendingDocumentComment';
import PendingDocumentInnerBox from './PendingDocumentListItem/components/PendingDocumentInnerBox';
import PendingDocumentListItemTop from './PendingDocumentListItem/components/PendingDocumentListItemTop';
import SignatureStatus from '../../../../../components/eSignature/SignatureStatus';
import Tooltip from '../../../../../components/Tooltip';
import UserSelectDocumentLabel from './PendingDocumentListItem/components/UserSelectDocumentLabel';
import useWindowSize from '../../../../../hooks/useWindowSize';

const classes = {
    documentBox: {
        '&:hover .spinningIcon': {
            animation: theme => `spin 1000ms ${theme.transitions.easing.easeOut}`
        },
        '@keyframes spin': {
            '0%': {
                transform: 'rotate(0deg)'
            },
            '100%': {
                transform: 'rotate(360deg)'
            }
        },
    },
    clickable: {
        cursor: 'pointer',
    },
    selectedBoxUser: {
        position: 'absolute',
        top: 2,
        left: 2
    }
};

const CheckCorporationIdCorporationIdCodes = ({pendingDocument}) => (
    <Box>
        <Box>
            <strong>CNAEs do CNPJ: </strong>
        </Box>
        {
            pendingDocument.checkCorporationIdCorporationIdCodes?.map((checkCorporationIdCorporationIdCode, listItemIndex) => (
                <Box key={checkCorporationIdCorporationIdCode.code}>{checkCorporationIdCorporationIdCode.code} ({checkCorporationIdCorporationIdCode.description})</Box>
            ))
        }
    </Box>
);

const CheckCorporationIdActivityCodes = ({pendingDocument}) => (
    <Box>
        <Box>
            <strong>CNAEs da atividade: </strong>
        </Box>
        {
            pendingDocument.checkCorporationIdActivityCodes?.map((checkCorporationIdActivityCode, listItemIndex) => (
                <Box key={checkCorporationIdActivityCode.code}>{checkCorporationIdActivityCode.code} ({checkCorporationIdActivityCode.description})</Box>
            ))
        }
    </Box>
);

const ScheduledRelease = ({handleCancelScheduledSendButtonPress, pendingDocument}) => {
    const showCorporationIdBox = pendingDocument.checkCorporationId && (pendingDocument.checkCorporationIdActivityCodes?.length >= 1 || pendingDocument.checkCorporationIdLegalRepresentatives?.length >= 1 || pendingDocument.checkCorporationIdPartners.length >= 1 || pendingDocument.checkCorporationIdIsMEI !== undefined);
    let foundErrors = false;
    let noErrorsFound = pendingDocument.checkCorporationIdCorporationIdContainsActivityCode && pendingDocument.checkCorporationIdCorporationIdContainsLegalRepresentative && pendingDocument.checkCorporationIdCorporationIdContainsConsentingSignatory && (!pendingDocument.checkCorporationIdIsMEI || pendingDocument.checkCorporationIdMEIAllowedForDocumentActivity);
    if(showCorporationIdBox) foundErrors = !noErrorsFound;
    if(pendingDocument.releaseAt || showCorporationIdBox){
        return (
            <Grid item xs={12}>
                <Box
                    style={{
                        backgroundColor: 'rgba(255, 255, 255, 0.25)',
                        border: '1px solid rgba(0, 0, 0, 0.08)',
                        borderRadius: 8,
                        boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.08)',
                        padding: '4px 8px',
                    }}
                >
                    {
                        showCorporationIdBox &&
                        <Box>
                            {
                                pendingDocument.checkCorporationIdActivityCodes?.length >= 1 &&
                                <Box>
                                    <Box my={0.5}>
                                        <Grid container spacing={1} alignItems="center" wrap="nowrap">
                                            <Grid item>
                                                {
                                                    pendingDocument.checkCorporationIdCorporationIdContainsActivityCode
                                                    ?
                                                    <CheckCircleIcon htmlColor="green" fontSize={'small'} />
                                                    :
                                                    <CancelIcon htmlColor="red" fontSize={'small'} />
                                                }
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="body2" style={{color: pendingDocument.checkCorporationIdCorporationIdContainsActivityCode ? 'green' : 'red'}}>Os <Tooltip text={<CheckCorporationIdActivityCodes pendingDocument={pendingDocument} />}><span style={{textDecoration: 'underline'}}>CNAEs vinculados à atividade{pendingDocument.checkCorporationIdDocumentActivity ? ` "${pendingDocument.checkCorporationIdDocumentActivity}"` : ''}</span></Tooltip> {pendingDocument.checkCorporationIdCorporationIdContainsActivityCode ? '' : 'NÃO '}foram encontrados no <Tooltip text={<CheckCorporationIdCorporationIdCodes pendingDocument={pendingDocument} />}><span style={{textDecoration: 'underline'}}>CNPJ <Link
                                                    href={`https://servicos.receita.fazenda.gov.br/servicos/cnpjreva/cnpjreva_solicitacao.asp?cnpj=${pendingDocument.checkCorporationIdDocumentCorporationId}`}
                                                    target="_blank"
                                                    underline="hover">{pendingDocument.checkCorporationIdDocumentCorporationId ? pendingDocument.checkCorporationIdDocumentCorporationId.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5') : '?'}</Link></span></Tooltip>.</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Divider />
                                </Box>
                            }
                            {
                                pendingDocument.checkCorporationIdIsMEI &&
                                <Box>
                                    <Box my={0.5}>
                                        <Grid container spacing={1} alignItems="center" wrap="nowrap">
                                            <Grid item>
                                                {
                                                    pendingDocument.checkCorporationIdMEIAllowedForDocumentActivity
                                                    ?
                                                    <CheckCircleIcon htmlColor="green" fontSize={'small'} />
                                                    :
                                                    <CancelIcon htmlColor="red" fontSize={'small'} />
                                                }
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="body2" style={{color: pendingDocument.checkCorporationIdMEIAllowedForDocumentActivity ? 'green' : 'red'}}>A atividade{pendingDocument.checkCorporationIdDocumentActivity ? ` "${pendingDocument.checkCorporationIdDocumentActivity}"` : ''} {pendingDocument.checkCorporationIdMEIAllowedForDocumentActivity ? '' : 'NÃO '} é permitida para Microempreendedor Individual (MEI).</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Divider />
                                </Box>
                            }
                            <Box>
                                <Box my={0.5}>
                                    <Grid container spacing={1} alignItems="center" wrap="nowrap">
                                        <Grid item>
                                            {
                                                pendingDocument.checkCorporationIdCorporationIdContainsLegalRepresentative
                                                ?
                                                <CheckCircleIcon htmlColor="green" fontSize={'small'} />
                                                :
                                                <CancelIcon htmlColor="red" fontSize={'small'} />
                                            }
                                        </Grid>
                                        <Grid item xs>
                                            <Typography variant="body2" style={{color: pendingDocument.checkCorporationIdCorporationIdContainsLegalRepresentative ? 'green' : 'red'}}>O(A) representante legal{pendingDocument.checkCorporationIdDocumentLegalRepresentative ? ` ${pendingDocument.checkCorporationIdDocumentLegalRepresentative}` : ''} {pendingDocument.checkCorporationIdCorporationIdContainsLegalRepresentative ? '' : 'NÃO '}foi encontrado(a) no {pendingDocument.checkCorporationIdIsMEI ? 'CNPJ' : <Tooltip text={pendingDocument.checkCorporationIdLegalRepresentatives.map((partnerName, listItemIndex) => (<Box key={partnerName}>{partnerName}</Box>))}><span style={{textDecoration: 'underline'}}>quadro de sócios-administradores da empresa</span></Tooltip>}.</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                            {
                                pendingDocument.checkCorporationIdCorporationIdContainsConsentingSignatory !== undefined &&
                                <Box>
                                    <Divider />
                                    <Box my={0.5}>
                                        <Grid container spacing={1} alignItems="center" wrap="nowrap">
                                            <Grid item>
                                                {
                                                    pendingDocument.checkCorporationIdCorporationIdContainsConsentingSignatory
                                                    ?
                                                    <CheckCircleIcon htmlColor="green" fontSize={'small'} />
                                                    :
                                                    <CancelIcon htmlColor="red" fontSize={'small'} />
                                                }
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="body2" style={{color: pendingDocument.checkCorporationIdCorporationIdContainsConsentingSignatory ? 'green' : 'red'}}>O(A) interveniente/anuente{pendingDocument.checkCorporationIdDocumentConsentingSignatory ? ` ${pendingDocument.checkCorporationIdDocumentConsentingSignatory}` : ''} {pendingDocument.checkCorporationIdCorporationIdContainsConsentingSignatory ? '' : 'NÃO '}foi encontrado(a) no {pendingDocument.checkCorporationIdIsMEI ? 'CNPJ' : <Tooltip text={pendingDocument.checkCorporationIdPartners.map((partnerName, listItemIndex) => (<Box key={partnerName}>{partnerName}</Box>))}><span style={{textDecoration: 'underline'}}>quadro de sócios da empresa</span></Tooltip>}.</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            }
                        </Box>
                    }
                    <Divider />
                    {
                        pendingDocument.shouldNotSkipOperatorReason &&
                        <Box mt={1}>
                            <Grid container spacing={1} alignItems="center" justifyContent="center" wrap="nowrap">
                                <Grid item>
                                    <Typography variant="body2" style={{color: 'red'}}>{pendingDocument.shouldNotSkipOperatorReason}</Typography>
                                </Grid>
                                <Grid item>
                                    <CancelIcon htmlColor="red" fontSize={'small'} />
                                </Grid>
                            </Grid>
                        </Box>
                    }
                    {
                        pendingDocument.releaseAt &&
                        <Box mt={1}>
                            {
                                foundErrors &&
                                <Grid container spacing={1} alignItems="center" justifyContent="center" wrap="nowrap">
                                    <Grid item>
                                        <Typography variant="body2" style={{color: 'green'}}>O(A) solicitante manifestou ciência das questões indicadas acima.</Typography>
                                    </Grid>
                                    <Grid item>
                                        <CheckCircleIcon htmlColor="green" fontSize={'small'} />
                                    </Grid>
                                </Grid>
                            }
                            <Grid container spacing={1} alignItems="center" justifyContent="center" wrap="nowrap">
                                <Grid item>
                                    <Chip
                                        color="primary"
                                        avatar={<CancelScheduleSendIcon fontSize="small" />} label={`Programado para ${moment(pendingDocument.releaseAt).format('L LT')}`}
                                        onDelete={() => handleCancelScheduledSendButtonPress(pendingDocument)}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    }
                </Box>
            </Grid>
        );
    }
    return null;
};

const PendingDocumentListItem = memo((props) => {
    const {
        getCurrentDocumentLastDate, getDocumentLastCommentIfHuman,
        handle_actionMenuOpen, handle_clickedProject,
        handleCancelScheduledSendButtonPress,
        handleDocumentClick,
        handleDocumentHistoryButtonPress, handleMenuOpen, handle_updateDocSentButtonClick,
        pendingDocument, saving, setSelectedDocument,
        updatePendingDocument
    } = props;
    const theme = useTheme();
    const { windowWidth } = useWindowSize();

    let documentBoxStyle = {
        padding: 0
    };

    let currentDocumentLastDate = getCurrentDocumentLastDate(pendingDocument);
    
    let thisMoment = moment();
    let currentDayOfWeek = thisMoment.day();
    let addHours = 0;
    if(currentDayOfWeek === 5){
        addHours = thisMoment.hours();
    } else if(currentDayOfWeek === 6){
        addHours = 24 + thisMoment.hours();
    } else if(currentDayOfWeek === 1 || currentDayOfWeek === 2){
        addHours = 48;
    }
    let documentIsVeryLate = false, documentIsLate = false;
    if(thisMoment.diff(moment(currentDocumentLastDate), 'hours') >= (48 + addHours)){
        documentIsVeryLate = true;
    } else if(thisMoment.diff(moment(currentDocumentLastDate), 'hours') >= (24 + addHours)){
        documentIsLate = true;
    }
    if(pendingDocument.flag === 2) documentBoxStyle.border = `2px solid ${theme.palette.secondary.main}`;
    if(pendingDocument.flag !== 1 && (documentIsVeryLate || documentIsLate)){
        documentBoxStyle.backgroundColor = documentIsVeryLate ? '#ef9a9a' : (documentIsLate ? '#fff9c4' : '') // ffcdd2 fffde7;
    }

    let lastVersion = null;
    const documentVersions = pendingDocument.documents;
    if(Array.isArray(documentVersions)){
        lastVersion = documentVersions[documentVersions.length - 1];
    }

    return (
        <DataListItem
            dataId={pendingDocument._id} sx={classes.documentBox} boxStyle={documentBoxStyle}
            showMoreOptionsFab
            handleMoreOptionsShownOnMouseOverFab={(e) => handle_actionMenuOpen(e, pendingDocument)}
            moreOptionsFabStyle={{top: 0, right: 0, transform: 'translate(50%, -50%)'}}
        >
            <PendingDocumentInnerBox handleDocumentClick={handleDocumentClick} pendingDocument={pendingDocument}>
                <UserSelectDocumentLabel pendingDocument={pendingDocument} />
                <Grid container spacing={1} alignItems="center" style={{width: '100%'}}>
                    <Grid item style={{width: '100%'}}>
                        <Box>
                            <PendingDocumentListItemTop handleDocumentProjectNameClick={handle_clickedProject} pendingDocument={pendingDocument} />
                        </Box>
                        <Grid container spacing={1} alignItems="center" style={{width: '100%'}}>
                            <Grid item style={{width: 38}}>
                                {
                                    pendingDocument.sentBy
                                    ?
                                    <IconButton disabled={pendingDocument.loading} size="small" color="primary" onClick={(e) => handleMenuOpen(e, pendingDocument)}>
                                        <Tooltip text={pendingDocument.sentByName}>
                                            <Avatar style={{height: 30, width: 30}} alt={pendingDocument.sentByName} src={`/uploads/avatars/${pendingDocument.sentByImage}`} />
                                        </Tooltip>
                                    </IconButton>
                                    : <Tooltip text="Clique para alterar o revisor deste documento"><span><IconButton disabled={pendingDocument.loading} size="small" color="primary" onClick={(e) => handleMenuOpen(e, pendingDocument)}><Avatar style={{height: 30, width: 30}} alt={'Escolher responsável'} /></IconButton></span></Tooltip>
                                }
                            </Grid>
                            <Grid item style={{width: 30}}>
                                <Tooltip text="Histórico">
                                    <span>
                                        <IconButton disabled={pendingDocument.loading} size="small" onClick={(e) => handleDocumentHistoryButtonPress(e, pendingDocument)}><AccessTimeIcon /></IconButton>
                                    </span>
                                </Tooltip>
                            </Grid>
                            {
                                pendingDocument.loading &&
                                <Grid item style={{width: 24}}>
                                    <CircularProgress size={20} />
                                </Grid>
                            }
                            <Grid item style={{width: `calc(100% - 38px - 30px${pendingDocument.loading ? ' - 24px' : ''}${windowWidth && windowWidth >= 600 && lastVersion?.eSignature ? ' - 280px' : ''} - 48px)`}}>
                                {
                                    pendingDocument.loading
                                    ? <Typography variant="body1">{pendingDocument.name}</Typography>
                                    : <PendingDocLink selectedDocument={pendingDocument} />
                                }
                            </Grid>
                            {
                                (windowWidth && windowWidth >= 600 && lastVersion?.eSignature) &&
                                <Grid item style={{width: 280}}>
                                    <SignatureStatus
                                        activeUserIsManager
                                        activeUserIsOperator
                                        data={{
                                            folderId: pendingDocument.project,
                                            folderName: pendingDocument.folderName,
                                            subDocument: lastVersion,
                                            subDocumentParentDocument: pendingDocument,
                                            setSelectedSubDocumentParentDocument: setSelectedDocument,
                                            userIsManager: true,

                                            eSignature: true, // since "hideSignButton" actived, option has no effect
                                            // handleSignDocumentWindowOpenClick,
                                        }}
                                        documentType="document"
                                        hideSignButton
                                        updateDocument={updatePendingDocument}
                                    />
                                </Grid>
                            }
                            <Grid item style={{width: 48}}>
                                <Tooltip text="Enviar" placement="left">
                                    <span>
                                        <Fab disabled={saving || pendingDocument.loading || pendingDocument.sending} size="small" color="primary" onClick={() => handle_updateDocSentButtonClick(pendingDocument)}>{pendingDocument.sending ? <CircularProgress size={20} /> : <SendIcon />}</Fab>
                                    </span>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <PendingDocumentComment getDocumentLastCommentIfHuman={getDocumentLastCommentIfHuman} pendingDocument={pendingDocument} />
                        <DocumentFilesList doc={pendingDocument} />
                        {
                            (windowWidth && windowWidth < 600) &&
                            <Box mt={1}>
                                <Grid container justifyContent="center">
                                    <Grid item>
                                        <SignatureStatus
                                            activeUserIsManager
                                            activeUserIsOperator
                                            data={{
                                                folderId: pendingDocument.project,
                                                folderName: pendingDocument.folderName,
                                                subDocument: lastVersion,
                                                subDocumentParentDocument: pendingDocument,
                                                setSelectedSubDocumentParentDocument: setSelectedDocument,
                                                userIsManager: true,

                                                eSignature: true, // since "hideSignButton" actived, option has no effect
                                                // handleSignDocumentWindowOpenClick,
                                            }}
                                            documentType="document"
                                            hideSignButton
                                            updateDocument={updatePendingDocument}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                        <ScheduledRelease handleCancelScheduledSendButtonPress={handleCancelScheduledSendButtonPress} pendingDocument={pendingDocument} />
                    </Grid>
                </Grid>
            </PendingDocumentInnerBox>
        </DataListItem>
    );
}, (prevProps, nextProps) => {
    if(nextProps.pendingDocument.updatedAt !== prevProps.pendingDocument.updatedAt){
        return false;
    }
    return true;
});

export default PendingDocumentListItem;