import React, { lazy, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Collapse, IconButton, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { styled } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import RemoveIcon from '@mui/icons-material/Remove';
import SearchIcon from '@mui/icons-material/Search';
import Alert from '@mui/material/Alert';

// REACT-PDF IMPORTS
import { Page } from 'react-pdf';

import CenteredCircularProgress from '../../components/CenteredCircularProgress';
import LazyLoadedComponent from '../../components/LazyLoadedComponent';
import SentProposalAgreementInformation from './components/SentProposalAgreementInformation';
import { useAppCtxAPI } from '../../context/SystemContext';
import { serverRequest } from '../../utils/common';

const LazyLoadedPdfDocument = lazy(() => import('../../components/PdfDocument'));

const headerHeight = 66;
const elevatedBoxHeight = headerHeight - 12;
const classes = {
    root: {
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        height: `${headerHeight}px`,
        backgroundColor: '#f0f0f0'
    },
    elevatedBox: {
        height: `${elevatedBoxHeight}px`,
        backgroundColor: '#f5f6fa',
        boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
        overflow: 'hidden',
        padding: '0 24px'
    },
    elevatedBoxHeight: {
        height: `${elevatedBoxHeight}px`,
    },
    content: {
        backgroundColor: '#f0f0f0',
        height: `calc(100% - ${headerHeight}px - ${elevatedBoxHeight}px)`,
        overflowX: 'hidden',
        overflowY: 'auto',
        position: 'relative',
        padding: `${headerHeight}px 0`
    },
    footer: {
        height: `${elevatedBoxHeight}px`,
        backgroundColor: '#f5f6fa',
        boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
        overflow: 'hidden',
        padding: '0 24px',
        position: 'relative'
    },
    footerButton: {
        position: 'absolute',
        top: '50%',
        right: '24px',
        transform: 'translate(0, -50%)',
    },
    alert: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        msTransform: 'translate(-50%, -50%)',
        WebkitTransform: 'translate(-50%, -50%)',
        textAlign: 'center'
    },
    zoomControls: {
        position: 'fixed',
        top: headerHeight + 12,
        left: '50%',
        transform: 'translate(-50%)',
        zIndex: 1,
    },
    zoomControlsBackgroundLayer: {
        position: 'absolute',
        backgroundColor: 'grey',
        borderRadius: '8px',
        height: '100%',
        opacity: 0.5,
        width: '100%',
    }
};

const Div = styled('div')(unstable_styleFunctionSx);

const ZoomControls = ({setPageScale}) => {

    const handleMinusClick = () => {
        setPageScale(prevState => {
            let newPageScale = prevState;
            if(prevState === 0.9){
                newPageScale = 0.7;
            } else if(prevState === 0.7){
                newPageScale = 0.5;
            }
            return newPageScale;
        });
    };

    const handlePlusClick = () => {
        setPageScale(prevState => {
            let newPageScale = prevState;
            if(prevState === 0.5){
                newPageScale = 0.7;
            } else if(prevState === 0.7){
                newPageScale = 0.9;
            }
            return newPageScale;
        });
    };

    return (
        <Box sx={classes.zoomControls}>
            <Box sx={classes.zoomControlsBackgroundLayer} />
            <Grid container spacing={1} alignItems="center">
                <Grid>
                    <IconButton onClick={handleMinusClick}><RemoveIcon /></IconButton>
                </Grid>
                <Grid>
                    <SearchIcon />
                </Grid>
                <Grid>
                    <IconButton onClick={handlePlusClick}><AddIcon /></IconButton>
                </Grid>
            </Grid>
        </Box>
    );
};

const PdfDocument = ({base64String, pageScale, pageWidth}) => {
    const [numberOfPages, setNumberOfPages] = useState(1);

    const handleDocumentLoadSuccess = (data) => {
        if(!!data.numPages) setNumberOfPages(data.numPages);
    };

    return (
        <LazyLoadedComponent
            component={
                <LazyLoadedPdfDocument
                    base64String={base64String}
                    loading={<CenteredCircularProgress />}
                    onLoadSuccess={handleDocumentLoadSuccess}
                >
                    {Array.from(
                        new Array(numberOfPages),
                        (documentPage, index) => (
                        <div
                            key={`page_${index + 1}`}
                            style={{ boxShadow: '4px 4px 5px 0 rgba(0, 0, 0, .25)', marginBottom: '10px' }}
                        >
                            <Page
                                pageNumber={index + 1}
                                renderAnnotationLayer={false}
                                renderTextLayer={false}
                                scale={pageScale}
                                width={pageWidth}
                            />
                        </div>
                        ),
                    )}
                </LazyLoadedPdfDocument>
            }
        />
    );
};

const PageFooter = ({base64String, proposalVersionId, setSentProposalAgreementInformationViewOpen}) => {

    const handleAcceptProposalClick = async () => {
        setSentProposalAgreementInformationViewOpen(true);
    };

    return (
        <Box sx={classes.footer}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs></Grid>
                <Grid item>
                    <Button disabled={!base64String} variant="contained" sx={classes.footerButton} onClick={handleAcceptProposalClick}>Estou de acordo</Button>
                </Grid>
            </Grid>
        </Box>
    );
};

const SentAgreement = () => {
    const { setLoadingSystem, toast } = useAppCtxAPI();
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [alertSeverity, setAlertSeverity] = useState('success');
    const [alertMessage, setAlertMessage] = useState('');
    const [includesContract, setIncludesContract] = useState(false);
    const [shouldStartAcceptedProposalESignatureVersionId, setShouldStartAcceptedProposalESignatureVersionId] = useState('');
    const [base64String, setBase64String] = useState(null);
    const [pageScale, setPageScale] = useState(0.7);
    const [pageWidth, setPageWidth] = useState(null);
    const [sentProposalAgreementInformationViewOpen, setSentProposalAgreementInformationViewOpen] = useState(false);
    const pageWidthRef = useRef(null);
    
    useEffect(() => {
        if(pageWidthRef.current){
            const currentPageWith = pageWidthRef.current.getBoundingClientRect().width;
            setPageWidth(currentPageWith);
        }
        setLoadingSystem(false);
    }, []);
    
    useEffect(() => {
        setLoadingSystem(false);
    }, []);

    const fetchData = async () => {
        const proposalVersionId = params.proposalVersionId;
        if(proposalVersionId){
            try {
                const data = await serverRequest({path: `/propostas/${proposalVersionId}/pdf`});
                if(data.shouldStartAcceptedProposalESignatureVersionId){
                    setShouldStartAcceptedProposalESignatureVersionId(data.shouldStartAcceptedProposalESignatureVersionId);
                    return setSentProposalAgreementInformationViewOpen(true);
                }
                setIncludesContract(!!data.includesContract);
                setBase64String(data.base64String);
            } catch (error) {
                setAlertSeverity('error');
                setAlertMessage(error);
            }
        }
        setLoading(false);
    };
    
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <Div sx={classes.root}>
                <Box sx={classes.header}>
                    <Box sx={classes.elevatedBox}>
                        <Grid container spacing={1} alignItems="center" sx={classes.elevatedBoxHeight}>
                            <Grid item xs>
                                <img src={'/SOLIDA-50.png'} alt="Solida" /> 
                            </Grid>
                            {/* <Grid>
                                <Tooltip title="Baixar PDF">
                                    <IconButton><DownloadIcon /></IconButton>
                                </Tooltip>
                            </Grid> */}
                        </Grid>
                    </Box>
                </Box>
                <Box sx={classes.content}>
                    
                    <ZoomControls setPageScale={setPageScale} />

                    <div ref={pageWidthRef}>
                        <Grid container spacing={1} alignItems="center" justifyContent="center">
                            <Grid item>
                                {
                                    base64String &&
                                    <PdfDocument base64String={base64String} pageScale={pageScale} pageWidth={pageWidth} />
                                }
                            </Grid>
                        </Grid>
                    </div>

                    <Box sx={classes.alert}>
                        {
                            loading &&
                            <CenteredCircularProgress />
                        }
                        <Collapse in={!!alertMessage}>
                            <Alert variant="filled" severity={alertSeverity}><Typography variant="body1">{alertMessage}</Typography></Alert>
                        </Collapse>
                    </Box>
                    
                </Box>
                <PageFooter base64String={base64String} proposalVersionId={params.proposalVersionId} setSentProposalAgreementInformationViewOpen={setSentProposalAgreementInformationViewOpen} />

            </Div>
            <SentProposalAgreementInformation proposalVersionId={params.proposalVersionId} sentProposalAgreementInformationViewOpen={sentProposalAgreementInformationViewOpen} setSentProposalAgreementInformationViewOpen={setSentProposalAgreementInformationViewOpen} includesContract={includesContract} shouldStartAcceptedProposalESignatureVersionId={shouldStartAcceptedProposalESignatureVersionId} />
            
        </>
    );
};

export default SentAgreement;