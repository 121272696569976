import { useEffect, useState } from 'react';
import { Box, Checkbox, Collapse, Grid, TextField, Typography } from '@mui/material';
import Autocomplete from './Autocomplete';
import { mapFormList } from '../utils/common';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const ListQuestion = ({
    checkCorporationIdActivityCodes,
    checkCorporationIdCorporationIdContainsActivityCode,
    checkCorporationIdMEIAllowedForDocumentActivity,
    formActiveClient,
    foundActivityCodeInCNPJ,
    foundCNPJ, setFoundCNPJ,
    handleUserAwareOfErrorsChange,
    initialValue,
    onChange,
    question,
    questionNameToCNPJ,
    showCorporationIdActivityError,
    showMEIActivityError,
    userAwareOfErrors,
}) => {
    const [selectedList, setSelectedList] = useState([]);
    const defaultValue = { choice: '', input: '', listItemDescription: '', selectedListItem: null };
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        if(formActiveClient){ 
            let currentList = [];
            const listId = question.typeId;
            const currentLists = formActiveClient.lists;
            if(currentLists) currentList = currentLists.find(list => list.shortName === listId || list._id == listId);
            if(currentList){
                currentList = mapFormList(currentList.list);
            } else {
                currentList = [];
            }
            setSelectedList(currentList);
        }
    }, [formActiveClient]);

    useEffect(() => {
        setValue(prevState => {
            if(initialValue && selectedList){
                const selectedListItem = selectedList.find(listItem => listItem.key === initialValue.input);
                return {...prevState, ...initialValue, selectedListItem};
            }
            return prevState;
        });
    }, [initialValue, selectedList]);

    useEffect(() => {
        if(onChange){
            const selectedListItem = selectedList.find(listItem => listItem.key.toUpperCase() === value.input.toUpperCase());
            onChange(question.name, {...value, selectedListItem});
        }
    }, [value]);

    useEffect(() => {
        if(setFoundCNPJ && questionNameToCNPJ && question.corporationIdQuestion){
            setFoundCNPJ(questionNameToCNPJ[question.corporationIdQuestion] || null);
        }
    }, [questionNameToCNPJ]);
    
    const handleChoiceChange = (newValue) => {
        let choice = '', listItemDescription = '', selectedListItem = null;
        if(newValue){
            selectedListItem = newValue;
            choice = newValue.key;
            listItemDescription = newValue.value.replace(/<br \/>/g, '\n').trim();
        }
        setValue(prevState => ({...prevState, choice, listItemDescription, selectedListItem}));
    };
    const handleInputChange = (newValue) => {
        let selectedListItem = null;
        if(newValue) selectedListItem = selectedList.find(listItem => listItem.key.toUpperCase() === newValue.toUpperCase());
        let choice = '', listItemDescription = '';
        if(selectedListItem){
            choice = selectedListItem.key;
            listItemDescription = selectedListItem.value.replace(/<br \/>/g, '\n').trim();
        }
        setValue(prevState => ({...prevState, input: newValue, choice, listItemDescription}));
    };
    const handleListItemDescriptionChange = (newValue) => {
        let selectedListItem = null;
        if(value.choice) selectedListItem = selectedList.find(listItem => listItem.key.toUpperCase() === value.choice.toUpperCase());
        let listItemDescriptionChanged;
        if(selectedListItem) listItemDescriptionChanged = newValue !== selectedListItem.value.replace(/<br \/>/g, '\n').trim();
        setValue(prevState => ({...prevState, listItemDescription: newValue, listItemDescriptionChanged}));
    };

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs>
                    <Autocomplete
                        placeholder="Sua resposta"
                        options={selectedList} getOptionLabel={(option) => option.key}
                        freeSolo
                        value={value.selectedListItem}
                        onChange={(e, newValue) => handleChoiceChange(newValue)}
                        inputValue={value.input}
                        onInputChange={(e, newValue) => handleInputChange(newValue)}
                    />

                    <Collapse in={question.showListDescription && !!value.selectedListItem && !!value.selectedListItem.value.trim()}>
                        <Box mt={2}>
                            <Box mb={1}>
                                <Typography variant="h6">Detalhamento:</Typography>
                            </Box>
                            <TextField
                                variant="standard"
                                fullWidth
                                multiline
                                value={value.listItemDescription}
                                onChange={(e) => handleListItemDescriptionChange(e.target.value)} />
                            <Box>
                                <Grid container justifyContent="flex-end">
                                    <Grid item>
                                        <Typography variant="body2">Se você alterar a opção na pergunta "{question.name}", o detalhamento também será alterado.</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Collapse>
                </Grid>
                {
                    foundActivityCodeInCNPJ &&
                    <Grid item xs={12} sm={6} md={4} container spacing={1} alignItems="center" wrap="nowrap">
                        <Grid item xs>
                            <Typography variant="body2">O código CNAE correspondente ao serviço foi encontrato no CNPJ da empresa.</Typography>
                        </Grid>
                        <Grid item>
                            <CheckCircleIcon fontSize="small" htmlColor="green" />
                        </Grid>
                    </Grid>
                }
            </Grid>
            {
                (handleUserAwareOfErrorsChange && foundCNPJ && value.input && checkCorporationIdActivityCodes && (showCorporationIdActivityError || showMEIActivityError)) &&
                <Box>
                    {
                        (showCorporationIdActivityError && !checkCorporationIdCorporationIdContainsActivityCode) &&
                        <Box mt={1}>
                            <Grid container spacing={1} alignItems="center" justifyContent="center" wrap="nowrap">
                                <Grid item>
                                    <CancelIcon htmlColor="red" fontSize="small" />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1" style={{color: 'red'}}>Os principais códigos da CNAE associados a essa atividade não foram encontrados no CNPJ da empresa. Códigos principais:</Typography>
                                    {
                                        checkCorporationIdActivityCodes.map(activity => (
                                            <Typography key={activity.code} variant="body1" style={{color: 'red'}}>&bull; {activity.code} ({activity.description})</Typography>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                    }
                    {
                        (showMEIActivityError && foundCNPJ.isMEI && !checkCorporationIdMEIAllowedForDocumentActivity) &&
                        <Box mt={1}>
                            <Grid container spacing={1} alignItems="center" justifyContent="center" wrap="nowrap">
                                <Grid item>
                                    <CancelIcon htmlColor="red" fontSize="small" />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1" style={{color: 'red'}}>O CNPJ pertence a um Microempreendedor Individual (MEI), mas essa atividade não está listada entre aquelas geralmente permitidas para MEI.</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    }
                    <Grid container spacing={1} alignItems="center" justifyContent="center" wrap="nowrap">
                        <Grid item>
                            <Checkbox size="small" color="primary"
                                checked={userAwareOfErrors}
                                onChange={(e) => handleUserAwareOfErrorsChange(e.target.checked)}
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">Estou ciente, mas quero enviar o formulário mesmo assim.</Typography>
                        </Grid>
                    </Grid>
                </Box>
            }
        </Box>
    );
}

export default ListQuestion;