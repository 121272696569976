import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import axios from 'axios';
import { getFolderTemplates } from '../../../utils/common';
import { ERROR_MESSAGE_UNKNOWN, SERVER_PATH } from '../../../utils/constants';
import { useAppCtxAPI, useAppCtxActiveUser, useAppCtxSelectedFolder, useAppCtxTemplateLists } from '../../../context/SystemContext';
import { useClientCtxActiveClient } from '../../../context/ClientContext';
import GroupsWindow from '../../../components/GroupsWindow';

const FolderUserCtxAPI = createContext();
const FolderUserCtxCompanyFolders = createContext();
const FolderUserCtxFolderGroups = createContext();
const FolderUserCtxFolderModules = createContext();
const FolderUserCtxFolderTemplates = createContext();
const FolderUserCtxFolderUsers = createContext();
const FolderUserCtxLoadFolder = createContext();
const FolderUserCtxProjectClient = createContext();
const FolderUserCtxProjects = createContext();
const FolderUserCtxSidenavMobile = createContext();
const FolderUserCtxTemplates = createContext();
const FolderUserCtxUseAddDocument = createContext();
const FolderUserCtxUsers = createContext();

const defaultState = {
    clientProjects: [],
    docNotifications: [],
    folderGroups: [], // [{id: '', users: []}]
    folderModules: [],
    folderOperators: [],
    folderSettings: {},
    folderTemplates: [],
    folderUsers: [],
    projectClient: null,
    projects: [],
    selectedFolderCustomDocCols: [],
    sidenavMobileOpen: false,
    templates: [],
    useAddDocument: false,
    users: [],
    usersUpdatedAt: null
};

const reducer = (state, action) => {
    const { payload, type } = action;

    switch (type) {
        case 'ADD COMPANY FOLDER':
            return { ...state, clientProjects: [...state.clientProjects, payload] };
        case 'SET STATE':
            return { ...state, [payload.key]: payload.value };
        case 'SET STATE + UPDATED AT':
            return { ...state, [payload.key]: payload.value, [payload.updatedAt]: new Date() };
        case 'UPDATE STATE':
            return { ...state, ...payload };
        default: return state;
    }
    
};

const FolderUserProvider = ({children}) => {
    const { getUserGroups, setActiveUserGroups, setLoadingSystem, setOperatorStatus, setSelectedFolder, setTemplateLists, setUseCompanyRootFolder, setUserPosition, resetUser, toast } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const selectedFolder = useAppCtxSelectedFolder();
    const templateLists = useAppCtxTemplateLists();
    const activeClient = useClientCtxActiveClient();

    const [state, dispatch] = useReducer(reducer, {...defaultState});
    const api = useMemo(() => {

        const addCompanyFolder = (payload) => {
            dispatch({ type: 'ADD COMPANY FOLDER', payload });
        };

        const setCompanyFolders = (newValue) => {
            dispatch({ type: 'SET STATE', payload: { key: 'clientProjects', value: newValue } });
        };
        
        const setProjects = (newValue) => {
            dispatch({ type: 'SET STATE', payload: { key: 'projects', value: newValue } });
        };

        const setSidenavMobileOpen = (newValue) => {
            dispatch({ type: 'SET STATE', payload: { key: 'sidenavMobileOpen', value: newValue } });
        };

        const setState = (key, value) => {
            dispatch({ type: 'SET STATE', payload: { key, value } });
        };

        const setUsers = (newValue) => {
            dispatch({ type: 'SET STATE + UPDATED AT', payload: { key: 'users', value: newValue, updatedAt: 'usersUpdatedAt' } });
        };

        return {
            dispatch,
            addCompanyFolder,
            setCompanyFolders,
            setProjects,
            setSidenavMobileOpen,
            setState,
            setUsers
        };
    }, []);

    useEffect(() => {
        if(activeUser && activeClient){
            setTimeout(() => {
                setLoadingSystem(false);    
            }, 2000);
        }
    }, [activeUser, activeClient]);
    
    useEffect(() => {
        let lists = [];
        if(activeClient){
            lists = activeClient.templateLists || [];
        }
        setTemplateLists(lists);
    }, [activeClient]);

    useEffect(() => {
        if(state.templates.length >= 1 && selectedFolder){
            let folderTemplates = getFolderTemplates(selectedFolder, templateLists);
            dispatch({ type: 'SET STATE', payload: { key: 'folderTemplates', value: folderTemplates } });
        }
    }, [state.templates, selectedFolder]);
    
    const loadFolder = useCallback(async (id, callback) => {
        resetUser();
        setSelectedFolder(null);
        let q = SERVER_PATH + `/data/client/folder?folderId=${id}`;
        axios.get(q)
        .then(res => { 
            let folder = res.data.folder;
            folder.type = folder.client ? 'projects' : 'clients';
            setSelectedFolder(folder);
            const stateUpdates = {
                users: res.data.users,
                usersUpdatedAt: new Date()
            };
            setUseCompanyRootFolder(!folder.client);
            stateUpdates.selectedFolderCustomDocCols = getFolderCustomDocCols(folder);
            let loadedFolderUsers = res.data.team;
            stateUpdates.folderUsers = loadedFolderUsers;
            let operators = res.data.projectOperators?.map(o => state.users.find(u => u._id === o))
            stateUpdates.folderOperators = operators;
            let userOperatorAllowed = !!res.data.userPermissions?.operator;
            setOperatorStatus(userOperatorAllowed);
            const currentFolderModules = folder.modules || [];
            stateUpdates.folderModules = currentFolderModules;
            stateUpdates.useAddDocument = !!folder.folderUsersCanAddDocuments;
            getFolderGroups(folder, loadedFolderUsers);
            let loadedTemplates = res.data.templates;
            stateUpdates.templates = loadedTemplates;
            stateUpdates.settings = getFolderSettings(folder.settings);
            if(res.data.clientProjects) stateUpdates.clientProjects = res.data.clientProjects;
            stateUpdates.projectClient = res.data.projectClient || null;

            dispatch({ type: 'UPDATE STATE', payload: stateUpdates });

            if(callback) callback();
        })
        .catch(err => {
            console.log(err);
            let error = err?.response;
            if(error?.status === 403){
                toast(error.data);
            } else {
                toast(ERROR_MESSAGE_UNKNOWN);
            }
        })
    }, [activeUser, state.usersUpdatedAt]);

    const getFolderSettings = (settings) => {
        try {
            settings = JSON.parse(settings);
        } catch (error) {
            settings = {};
        }
        if(!settings) settings = {};
        return settings;
    };
    const getFolderCustomDocCols = (folder) => {
        let folderCustomDocCols = folder.documentsCustomColumns;
        if(!folderCustomDocCols) folderCustomDocCols = [];
        return folderCustomDocCols;
    };
    const getFolderGroups = (folder, loadedFolderUsers) => {
        if(folder){
            if(folder.useGroups){
                let groups = folder.groups || [];
                // if(!groups.find(group => group.id === '2' || group.id === 2)){
                //     groups.push({id: 2, name: 'Administradores', users: []});
                // }
                if(!groups.find(group => group.id === '1' || group.id === 1)){
                    groups.push({id: 1, name: 'Gerentes', users: []});
                }
                if(!groups.find(group => group.id === '~all;' || group.id === '&all')){
                    groups.push({id: '&all', name: 'Todos', users: []});
                }
                if(!groups.find(group => group.id === '~none;' || group.id === '&none')){
                    groups.push({id: '&none', name: 'Nenhum', users: []});
                }
                assignStrayUsersToNone(loadedFolderUsers, groups);
            } else {
                setActiveUserGroups([]);
                setUserPosition(2);
            }
        }
    };
    const assignStrayUsersToNone = (loadedFolderUsers, groups) => {
        loadedFolderUsers.forEach(u => {
            let userGroup = null;
            groups.forEach(g => {
                if(g.users.includes(u)) userGroup = g;
            });
            let group_all_index = groups.findIndex(g => g.id === '~all;' || g.id === '&all');
            let group_none_index = groups.findIndex(g => g.id === '~none;' || g.id === '&none');
            if(!userGroup && groups.length > 4){
                groups[group_none_index].users.push(u);
            } else if(!userGroup && groups.length <= 4){
                groups[group_all_index].users.push(u);
            }
        });
        dispatch({ type: 'SET STATE', payload: { key: 'folderGroups', value: groups } });
        getUserGroups(activeUser, groups);
    };
    
    return (
        <FolderUserCtxAPI.Provider value={api}>
        <FolderUserCtxProjects.Provider value={state.projects}>
        <FolderUserCtxUsers.Provider value={state.users}>
        <FolderUserCtxTemplates.Provider value={state.templates}>
        <FolderUserCtxSidenavMobile.Provider value={state.sidenavMobileOpen}>
        <FolderUserCtxFolderModules.Provider value={state.folderModules}>
        <FolderUserCtxUseAddDocument.Provider value={state.useAddDocument}>
        <FolderUserCtxFolderGroups.Provider value={state.folderGroups}>
        <FolderUserCtxProjectClient.Provider value={state.projectClient}>
        <FolderUserCtxCompanyFolders.Provider value={state.clientProjects}>
        <FolderUserCtxFolderTemplates.Provider value={state.folderTemplates}>
        <FolderUserCtxFolderUsers.Provider value={state.folderUsers}>
        <FolderUserCtxLoadFolder.Provider value={loadFolder}>

            {children}

            <GroupsWindow
                isOperator={false}
                users={state.users}
            />

        </FolderUserCtxLoadFolder.Provider>
        </FolderUserCtxFolderUsers.Provider>
        </FolderUserCtxFolderTemplates.Provider>
        </FolderUserCtxCompanyFolders.Provider>
        </FolderUserCtxProjectClient.Provider>
        </FolderUserCtxFolderGroups.Provider>
        </FolderUserCtxUseAddDocument.Provider>
        </FolderUserCtxFolderModules.Provider>
        </FolderUserCtxSidenavMobile.Provider>
        </FolderUserCtxTemplates.Provider>
        </FolderUserCtxUsers.Provider>
        </FolderUserCtxProjects.Provider>
        </FolderUserCtxAPI.Provider>
    );
};

const useFolderUserCtxAPI = () => useContext(FolderUserCtxAPI);
const useFolderUserCtxCompanyFolders = () => useContext(FolderUserCtxCompanyFolders);
const useFolderUserCtxFolderGroups = () => useContext(FolderUserCtxFolderGroups);
const useFolderUserCtxFolderModules = () => useContext(FolderUserCtxFolderModules);
const useFolderUserCtxFolderTemplates = () => useContext(FolderUserCtxFolderTemplates);
const useFolderUserCtxFolderUsers = () => useContext(FolderUserCtxFolderUsers);
const useFolderUserCtxLoadFolder = () => useContext(FolderUserCtxLoadFolder);
const useFolderUserCtxProjectClient = () => useContext(FolderUserCtxProjectClient);
const useFolderUserCtxProjects = () => useContext(FolderUserCtxProjects);
const useFolderUserCtxSidenavMobile = () => useContext(FolderUserCtxSidenavMobile);
const useFolderUserCtxTemplates = () => useContext(FolderUserCtxTemplates);
const useFolderUserCtxUseAddDocument = () => useContext(FolderUserCtxUseAddDocument);
const useFolderUserCtxUsers = () => useContext(FolderUserCtxUsers);

export {
    FolderUserProvider,
    useFolderUserCtxAPI,
    useFolderUserCtxCompanyFolders,
    useFolderUserCtxFolderGroups,
    useFolderUserCtxFolderModules,
    useFolderUserCtxFolderTemplates,
    useFolderUserCtxFolderUsers,
    useFolderUserCtxLoadFolder,
    useFolderUserCtxProjectClient,
    useFolderUserCtxProjects,
    useFolderUserCtxSidenavMobile,
    useFolderUserCtxTemplates,
    useFolderUserCtxUseAddDocument,
    useFolderUserCtxUsers
};