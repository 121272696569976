import MenuItem from '@mui/material/MenuItem';
import Account from '../../components/Account';
import { useAppCtxAPI } from '../../context/SystemContext';

const ManagementAccount = ({size}) => {
    const { handleNavigate } = useAppCtxAPI();
    return (
        <Account size={size}> 
            <MenuItem onClick={() => handleNavigate(`/admin/preferencias`)}>Preferências</MenuItem>
        </Account>
    );
};

export default ManagementAccount;