import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DashboardCounter from '../../../../../components/DashboardCounter';
import { useDocumentsCtxPendingDocumentsCount } from '../../../../../context/DocumentsContext';

const NumberOfPendingDocuments = () => {
    const pendingDocumentsCount = useDocumentsCtxPendingDocumentsCount();
    return (
        <DashboardCounter
            icon={<DescriptionOutlinedIcon />}
            label="contratos em aberto"
            loading={pendingDocumentsCount === null}
        >{pendingDocumentsCount}</DashboardCounter>
    )
}

export default NumberOfPendingDocuments;