import React, { useEffect, useState } from 'react';
import { Box, Container, Slide } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Dialog from './Dialog/Dialog';
import LoaderEllipsis from './LoaderEllipsis';
import ProjectWindowSignatureSection from '../pages/Operator/Folders/components/ProjectWindowSignatureSection';
import Select from './Select';
import { ERROR_MESSAGE_UNKNOWN, SERVER_PATH } from '../utils/constants';
import { useAppCtxAPI, useAppCtxActiveUser } from '../context/SystemContext';
import { useOperatorCompaniesCtxAPI } from '../context/OperatorCompaniesContext';
import { useOperatorFoldersCtxAPI, useOperatorFoldersCtxFolderSignatureView } from '../context/OperatorFoldersContext';
import axios from 'axios';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ChangeProjectSignersWindow = ({setFloatingNotification}) => {
    const { toast } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const { setChangeCompanyViewFoundCompany } = useOperatorCompaniesCtxAPI();
    const { hideFolderSignatureView, setChangeFolderViewFoundFolder } = useOperatorFoldersCtxAPI();
    const { folderSignatureViewOpen, folderSignatureViewSelectedFolder: selectedFolder } = useOperatorFoldersCtxFolderSignatureView();
    const [loading, setLoading] = useState(false);
    const defaultFolderState = {
        eSignaturePlatform: 'zapSign',
        signers: [],
    };
    const eSignaturePlatformOptions = [{value: 'docuSign', label: 'DocuSign'}, {value: 'zapSign', label: 'ZapSign'}];
    const [folderState, setFolderState] = useState(defaultFolderState);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if(folderSignatureViewOpen && selectedFolder){
            const signers = getFolderSigners(selectedFolder);
            setFolderState(prevState => ({
                ...prevState,
                eSignaturePlatform: selectedFolder.eSignaturePlatform || 'zapSign',
                signers
            }));

            setLoading(false);
        }
    }, [folderSignatureViewOpen, selectedFolder]);

    const getFolderSigners = (folder) => {
        let signers = [];
        if(folder.signers && folder.signers.length >= 1){
            signers = folder.signers;
        } else if(folder.info){
            const info = folder.info;
            if(info.signerName){
                signers.push({
                    type: 'party',
                    name: info.signerName,
                    email: info.signerEmail || '',
                    mobile: info.signerMobile || '',
                    taxpayerNumber: info.signerTaxpayerNumber || ''
                });
            }
            if(info.witness1Name){
                signers.push({
                    type: 'witness',
                    name: info.witness1Name,
                    email: info.witness1Email || '',
                    mobile: info.witness1Mobile || '',
                    taxpayerNumber: info.witness1TaxpayerNumber || ''
                });
            }
            if(info.witness2Name){
                signers.push({
                    type: 'witness',
                    name: info.witness2Name,
                    email: info.witness2Email || '',
                    mobile: info.witness2Mobile || '',
                    taxpayerNumber: info.witness2TaxpayerNumber || ''
                });
            }
        }
        return signers;
    };

    const handleSubmit = async () => {
        setSaving(true);
        
        let formData = new FormData();
        if(selectedFolder) formData.append('updatedProjectId', selectedFolder._id);
        formData.append('eSignaturePlatform', folderState.eSignaturePlatform);
        formData.append('signers', JSON.stringify(folderState.signers));
        
        await axios.post(SERVER_PATH + '/data/operator/folders/projects/save', formData, {headers: {'Content-Type': 'multipart/form-data'}})
        .then(res => {
            toast('As informações foram salvas.');
            handleClose();
            setFolderState(defaultFolderState);
            // setFolderSignatureViewSelectedFolder(res.data);
            setChangeCompanyViewFoundCompany(res.data);
            setChangeFolderViewFoundFolder(res.data);
        })
        .catch(err => {
            setFloatingNotification({message: err?.response?.data || ERROR_MESSAGE_UNKNOWN, severity: 'error'});
        });
        setSaving(false);
    };

    const handleClose = () => {
        hideFolderSignatureView();
    };

    return (
        <Dialog
            onClose={handleClose}
            onSaveClick={handleSubmit}
            open={folderSignatureViewOpen}
            saving={saving}
            title={`${selectedFolder?.name} | Assinatura eletrônica`}
            TransitionComponent={Transition}
        >

            <Container maxWidth="md">
                {
                    folderSignatureViewOpen &&
                    <>
                        {
                            !selectedFolder || loading
                            ? <LoaderEllipsis primary />
                            :
                            <Box>
                                <Box mb={3}>
                                    <Grid container>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <Select
                                                disabled={activeUser.type < 9}
                                                label="Plataforma"
                                                options={eSignaturePlatformOptions}
                                                value={folderState.eSignaturePlatform} onChange={(e) => setFolderState(prevState => ({...prevState, eSignaturePlatform: e.target.value}))}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <ProjectWindowSignatureSection projectState={folderState} setProjectState={setFolderState} />
                            </Box>
                        }
                    </>
                }
            </Container>

        </Dialog>
    );
};

export default ChangeProjectSignersWindow;