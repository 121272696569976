import { formatCurrency } from '../../../utils/common';
import numero from 'numero-por-extenso';

const currency = {
    typeSpecificsOptions: [
        {value: 'numberWordsCurrency', label: 'Em numeral e por extenso'},
        {value: 'numberCurrency', label: 'Em numeral'},
    ],
    createEffect: ({ effectSettings, newEffectId, templateEffects }) => {
        const newEffect = {
            id: newEffectId,
            type: effectSettings.type,
            typeSpecifics: effectSettings.typeSpecifics,
            question: effectSettings.question || effectSettings.label || '',
            label: effectSettings.label || effectSettings.question
        };
        const foundEffect = templateEffects.find(templateEffect => templateEffect.type === effectSettings.type && templateEffect.question === effectSettings.question && templateEffect.typeSpecifics === effectSettings.typeSpecifics);
        return { foundEffect, newEffect };
    },
    newEffectWindowHandleEffectTypeChange: () => {
        const data = {
            question: '',
            typeSpecifics: 'numberWordsCurrency'
        };
        return data;
    },
    getFormEditorQuestion: (data) => {
        if(data){
            const { effect, question } = data;
            const formEditorQuestion = {...question};
            formEditorQuestion.typeSpecifics = effect.typeSpecifics;
            return formEditorQuestion;
        }
        return false;
    },
    getTypeSpecificInput: (number, typeSpecifics) => {
        let input = '';
        if(typeSpecifics === 'numberWordsCurrency'){
            input = `${formatCurrency(number)} (${numero.porExtenso(number, numero.estilo.monetario)})`;
        } else if(typeSpecifics === 'numberCurrency'){
            input = formatCurrency(number);
        } else {
            input = number.toString().replace(/(\d+?)(\d{2})$/, '$1,$2');
        }
        return input;
    },
    checkValidation: (data) => {
        let questionValid = false;
        if(data){
            questionValid = true;
            const { input } = data;
            if(!input) questionValid = false;
        }
        return questionValid;
    }
};

export {
    currency
}