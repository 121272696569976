import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ViewBox from '../../../../../components/ViewBox';
import { useAppCtxAPI, useAppCtxLoading } from '../../../../../context/SystemContext';
import { useClientCtxActiveClient } from '../../../../../context/ClientContext';
import { useOperatorTemplatesCtxAPI, useOperatorTemplatesCtxTemplateName } from '../context/TemplatesContext';

function AddTemplate(){
    const { handleNavigate } = useAppCtxAPI();
    const loading = useAppCtxLoading();
    const activeClient = useClientCtxActiveClient();
    const { setTemplateName } = useOperatorTemplatesCtxAPI();
    const templateName = useOperatorTemplatesCtxTemplateName();
    const [newTemplateName, setNewTemplateName] = useState('');

    useEffect(() => {
        setNewTemplateName(templateName || '');
    }, [templateName]);

    const handle_addTemplateSectionBackClick = () => {
        handleNavigate(`/${activeClient.shortName}/juridico/documentos/matrizes`);
    };
    const handle_addTemplateSectionForwardClick = () => {
        setTemplateName(newTemplateName);
        handleNavigate(`/${activeClient.shortName}/juridico/documentos/matrizes/documento`);
    };

    return (
        <ViewBox>
            <Container maxWidth="xs">
                <Box mb={2}>
                    <TextField
                        variant="standard"
                        required
                        fullWidth
                        label="Nome da matriz"
                        value={newTemplateName}
                        onChange={(e) => setNewTemplateName(e.target.value)} />
                </Box>
                <Box mb={2}>
                    <Grid container spacing={1} alignItems="flex-start" justifyContent="center">
                        <Grid item xs={6} container justifyContent="flex-end">
                            <Button
                                variant="text"
                                startIcon={<ArrowBackIcon />}
                                onClick={handle_addTemplateSectionBackClick}>Voltar</Button>
                        </Grid>
                        <Grid item xs={6} container justifyContent="flex-start">
                            <Button variant="contained" color="primary" endIcon={<ArrowForwardIcon />}
                                onClick={handle_addTemplateSectionForwardClick}
                                disabled={loading || !newTemplateName}
                            >Avançar</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </ViewBox>
    );
}

export default AddTemplate;