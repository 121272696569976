import { memo } from 'react';
import { Box, Typography } from '@mui/material';
import PendingDocumentsTask from './PendingDocumentsTask';
import TaskBox from './TaskBox';
import UserBox from './UserBox';

const classes = {
    root: {
        height: '100%',
        transition: 'background-color 0.2s ease',
        borderRadius: '8px',
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    tasksBox: {
        padding: '4px',
        flexGrow: 1,
        overflowX: 'hidden',
        overflowY: 'auto',
        
        '&::-webkit-scrollbar': {
            width: 3
        },
    }
};

const TaskBoard = memo(({
    children,

    boardId,
    boxFilterFn,
    innerRef,
    isActiveUserTask,
    isDraggingOver,
    onBoxClick,
    nameKey,
    renderAdditionalBoxComponent,
    tasks,
    title,
    user,
    userNumberOfPendingDocuments
}) => {
    let backgroundColor = 'white';
    if (isDraggingOver) {
        backgroundColor = '#e8eaf6';
    }

    return (
        <div ref={innerRef} style={{height: '100%'}}>
            <Box
                sx={classes.root}
                style={{backgroundColor}}
            >
                <Box>
                    {
                        user &&
                        <UserBox user={user} />
                    }
                    {
                        title &&
                        <Box my={1} p={0.5}>
                            <Typography variant="h6" noWrap>{title}</Typography>
                        </Box>
                    }
                </Box>
                <Box sx={classes.tasksBox}>
                    {
                        (userNumberOfPendingDocuments) &&
                        <PendingDocumentsTask userNumberOfPendingDocuments={userNumberOfPendingDocuments} />
                    }
                    {
                        tasks &&
                        <Box>
                            {
                                tasks
                                .map((task, taskIndex) => (
                                    <TaskBox
                                        key={task.uuid || task._id}
                                        boardId={boardId}
                                        boxFilterFn={boxFilterFn}
                                        index={taskIndex}
                                        isActiveUserTask={isActiveUserTask}
                                        onBoxClick={onBoxClick}
                                        nameKey={nameKey}
                                        renderAdditionalBoxComponent={renderAdditionalBoxComponent}
                                        task={task}
                                    />
                                ))
                            }
                        </Box>
                    }
                    {children}
                </Box>
            </Box>
        </div>
    );
}, (prevProps, nextProps) => {
    return false; // props are not equal -> update the component
});

export default TaskBoard;