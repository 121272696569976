import React, { useCallback, useMemo } from 'react';
import {useDropzone} from 'react-dropzone';
import { colors } from '@mui/material';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  cursor: 'pointer',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3',
};

const acceptStyleColor = '#00e676';
const acceptStyle = {
  borderColor: acceptStyleColor,
  backgroundColor: 'rgba(255, 255, 0, 0.1)',
};

const rejectStyleColor = '#ff1744';
const rejectStyle = {
  borderColor: rejectStyleColor
};

function StyledDropzone(props) {
    
    const onDrop = useCallback(acceptedFiles => {
        if(props.acceptedFilesRef){
            props.acceptedFilesRef.current = acceptedFiles;
        }
        if(props.onChange) props.onChange(acceptedFiles);
    }, []);

    const onFileDialogOpen = useCallback(() => {
        if(props.acceptedFilesRef){
            props.acceptedFilesRef.current = [];
        }
        if(props.onChange) props.onChange([]);
    }, []);

    const dropzone = useDropzone({
        accept: props.accept,
        multiple: !!props.multiple,
        onDrop,
        onFileDialogOpen
    });
    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = dropzone;

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    return (
        <div className="container">
            <div {...getRootProps({style})}>
                <input id={props.inputId} {...getInputProps()} />
                {
                    isDragAccept
                    ?
                    <p style={{color: acceptStyleColor, fontSize: 13}}>Soltar!</p>
                    :
                    isDragReject
                    ?
                    <p style={{color: rejectStyleColor, fontSize: 13}}>Não é possível enviar arquivos com esse formato...</p>
                    :
                    acceptedFiles.length > 0
                    ?
                    <p style={{color: colors.grey[900], fontSize: 13}}>{acceptedFiles.map(acceptedFile => <span key={acceptedFile.path}>{acceptedFile.path}; </span>)}</p>
                    :
                    <p style={{fontSize: 13}}>Solte um arquivo aqui ou clique para selecionar um arquivo. Formatos aceitos: {props.acceptedExtensions ? props.acceptedExtensions.map(acceptedExtension => <span key={acceptedExtension}>{acceptedExtension}</span>) : 'PDF, DOC, DOCX'}</p>
                }
            </div>
        </div>
    );
}

export default StyledDropzone;