import { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Button, CircularProgress, Fade } from '@mui/material';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import FavoriteProjects from '../../../../components/FavoriteProjects';
import Form from '../../../Form';
import Autocomplete from '../../../../components/Autocomplete';
import Select from '../../../../components/Select';
import ViewBox from '../../../../components/ViewBox';
import { getFolderTemplates } from '../../../../utils/common';
import { SERVER_PATH } from '../../../../utils/constants';
import { sortByKey } from '../../../../utils/filters';
import { useAppCtxAPI, useAppCtxActiveUser, useAppCtxSelectedFolder, useAppCtxTemplateLists } from '../../../../context/SystemContext';
import { useSocket, useSocketCtxConnectedAt } from '../../../../context/SocketContext';
import { useClientCtxActiveClient } from '../../../../context/ClientContext';
import { useDocumentsAPI, useDocumentsCtxLoadingTemplates, useDocumentsCtxSelectedDocumentForm, useDocumentsCtxTemplates } from '../../../../context/DocumentsContext';
import { useOperatorCtxProjects, useOperatorCtxUsers } from '../../context/OperatorContext';
import { useForm } from '../../../Form/context/FormContext';
import SavedFormsWindow from '../../../../components/SavedFormsWindow';

function Main(){
    const { getUserGroups, setSelectedFolder } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const selectedFolder = useAppCtxSelectedFolder();
    const templateLists = useAppCtxTemplateLists();
    const { shouldUpdateTemplates } = useSocket();
    const socketConnectedAt = useSocketCtxConnectedAt();
    const activeClient = useClientCtxActiveClient();
    const { dispatch: dispatchDocuments } = useDocumentsAPI();
    const loadingTemplates = useDocumentsCtxLoadingTemplates();
    const selectedDocumentForm = useDocumentsCtxSelectedDocumentForm();
    const templates = useDocumentsCtxTemplates();
    const projects = useOperatorCtxProjects();
    const { users } = useOperatorCtxUsers();
    const { resetForm, setFormActiveClient, set_initialForm, set_savedForm, set_userForm } = useForm();
    const [visibleTemplates, setVisibleTemplates] = useState([]);
    const [selectedClient, set_selectedClient] = useState(null);
    const [projectOptions, set_projectOptions] = useState([]);
    const [selectedProject, set_selectedProject] = useState('');
    const [formOptions, set_formOptions] = useState([]);
    const [selectedTemplateId, setSelectedTemplateId] = useState('');
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [savedFormsWindowOpen, setSavedFormsWindowOpen] = useState(false);
    const [loadingPage, setLoadingPage] = useState(false);
    const [loadingProject, setLoadingProject] = useState(false);

    useEffect(() => {
        setFormActiveClient(activeClient);
    }, []);

    useEffect(() => {
        setLoadingPage(loadingTemplates);
    }, [loadingTemplates]);

    useEffect(() => {
        if(shouldUpdateTemplates){
            dispatchDocuments({type: 'LOAD TEMPLATES'});
        }
    }, [socketConnectedAt]);
    
    useEffect(() => {
        if(templates){
            const currentTemplates = templates.sort(sortByKey('name'));
            setVisibleTemplates(currentTemplates);
        }
    }, [templates]);
    
    useEffect(() => {
        if(selectedDocumentForm && visibleTemplates.length >= 1){
            handle_selectedClientChange(projects.find(i => i._id === selectedDocumentForm.client));
            handle_selectedProjectChange(selectedDocumentForm.project || '~none;');
            setSelectedTemplateId(selectedDocumentForm.template);
            getSelectedTemplateAction(selectedDocumentForm.template);
            const userForm = JSON.parse(selectedDocumentForm.form);
            set_userForm(userForm);
            set_initialForm(userForm);
            dispatchDocuments({type: 'SET FORM DOCUMENT', payload: null});
        }
    }, [selectedDocumentForm, visibleTemplates]);

    const clearForm = () => {
        set_savedForm(null);
        setSelectedTemplateId('');
        setSelectedTemplate(null);
        set_userForm({});
        set_initialForm({});
    };
    const handle_selectedClientChange = (newValue) => {
        clearForm();

        set_selectedClient(newValue);
        if(newValue){
            let clientProjects = projects.filter(project => project.client === newValue._id);
            let newProjectOptions = clientProjects.map(project => ({value: project._id, label: project.name}));
            set_projectOptions(newProjectOptions);
        } else {
            set_projectOptions([]);
        }
        set_selectedProject('');
    };
    const getProject = async (projectId) => {
        setLoadingProject(true);
        projectId = projectId !== '~none;' ? projectId : selectedClient._id;
        let project = null;
        await axios.post(SERVER_PATH + '/data/projects/one', {projectId})
        .then(res => {
            project = res.data.project;
        })
        .catch(err => {
            // throw err;
        });
        setLoadingProject(false);
        return project;
    };
    const handle_selectedProjectChange = async (newValue) => {
        clearForm();

        set_selectedProject(newValue);
        
        try {
            const folder = await getProject(newValue);
            setSelectedFolder(folder);
            let folderTemplates = getFolderTemplates(folder, templateLists);
            folderTemplates = folderTemplates.map(folderTemplateId => {
                let folderTemplate = visibleTemplates.find(t => t._id === folderTemplateId);
                if(!folderTemplate) return null;
                folderTemplate = {value: folderTemplate._id, label: folderTemplate.name};
                return folderTemplate;
            }).filter(template => !!template);;
            set_formOptions(folderTemplates);
            getFolderGroups(folder);
        } catch (error) {
            console.log(error);
        }
    };
    const getFolderGroups = (folder) => {
        let groups = [];
        if(folder.useGroups){
            groups = folder.groups;
        }
        getUserGroups(activeUser, groups, true);
    };

    const getSelectedTemplateAction = async (templateId) => {
        setLoadingPage(true);
        await axios.post(SERVER_PATH + '/data/templates/one', {
            templateId,
            fields: null
        })
        .then(res => {
            setSelectedTemplate(res.data);    
        })
        .catch(err => {});
        setLoadingPage(false);
    };
    
    const handle_selectedTemplateChange = async (newValue) => {
        clearForm();

        resetForm();

        setSelectedTemplate(null);
        const initialForm = {};
        const folderInitialFormResponses = selectedFolder.folderInitialFormResponses || [];
        const templateIndex = folderInitialFormResponses.findIndex(item => item.templateId === newValue);
        if(templateIndex !== -1){
            folderInitialFormResponses[templateIndex].questions.forEach(question => {
                initialForm[question.name] = question.response;
            });
        }
        set_initialForm(initialForm);
        set_userForm(initialForm);
        
        setSelectedTemplateId(newValue);
        getSelectedTemplateAction(newValue);
    }
    const onFormSubmit = (data) => {
        setSelectedTemplate(null);
        setSelectedTemplateId('');
    };
    const handleFormSave = () => {
        setSelectedTemplate(null);
        setSelectedTemplateId('');
    };
    const setFavoriteFolder = (type, folderId) => {
        let favoriteClient, favoriteProject;
        if(type === 'clients'){
            favoriteClient = projects.find(i => i._id === folderId);
            favoriteProject = '~none;';
        } else {
            favoriteProject = projects.find(i => i._id === folderId);
            favoriteClient = projects.find(i => i._id === favoriteProject.client);
            favoriteProject = folderId;
        }
        handle_selectedClientChange(favoriteClient);
        handle_selectedProjectChange(favoriteProject);
    };

    return (
        <ViewBox>
            <Box mb={2}>
                <FavoriteProjects handleFavoriteClick={setFavoriteFolder} />
                <Grid container spacing={1} alignItems="center">
                    {
                        projects &&
                        <Grid item xs={12} sm={4} md={3}>
                            <Autocomplete
                                label="Empresa" options={projects.sort(sortByKey('name')).filter(p => !p.client)} getOptionLabel={(option) => option.name}
                                value={selectedClient} onChange={(e, value) => handle_selectedClientChange(value)}
                            />
                        </Grid>
                    }
                    <Grid item xs={12} sm={4} md={3}>
                        <Slide in={!!selectedClient} direction="up">
                            <Box>
                                <Select
                                    label="Pasta"
                                    options={[{value: '~none;', label: 'Nenhum'}, ...projectOptions]}
                                    value={selectedProject} onChange={(e) => handle_selectedProjectChange(e.target.value)}
                                />
                            </Box>
                        </Slide>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <Slide in={!!selectedProject} direction="up">
                            <Box>
                                <Grid container spacing={1} alignItems="flex-end">
                                    {
                                        loadingProject &&
                                        <Grid item>
                                            <CircularProgress size={20} />
                                        </Grid>
                                    }
                                    <Grid item xs>
                                        <Select
                                            disabled={loadingProject}
                                            label="Formulário" options={formOptions.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))}
                                            value={selectedTemplateId} onChange={(e) => handle_selectedTemplateChange(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Slide>
                    </Grid>
                    <Grid item xs>
                        <Fade in={!!selectedProject}>
                            <Box style={{textAlign: 'center'}}>
                                <Button
                                    onClick={() => setSavedFormsWindowOpen(true)}
                                >Formulários salvos</Button>
                            </Box>
                        </Fade>
                    </Grid>
                </Grid>
            </Box>
            {
                loadingPage &&
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                </Grid>
            }
            {
                selectedTemplate &&
                <Form
                    form={selectedTemplate} onFormSubmit={onFormSubmit}
                    data={{
                        onFormSave: handleFormSave,
                        operatorStatus: true
                    }}
                />
            }
            <SavedFormsWindow 
                data={{
                    folderId: selectedProject,
                    savedFormsWindowOpen,
                    setSavedFormsWindowOpen,
                    templates: visibleTemplates,
                    users,

                    set_selectedForm: setSelectedTemplate,
                    set_selectedFormId: setSelectedTemplateId
                }}
            />
        </ViewBox>
    );
}

export default Main;