import { useEffect, useRef, useState } from 'react';
import { useAppContext } from '../../../../context/SystemContext';
import FormQuestionNumber from '../../../../components/FormQuestionNumber';
import { useForm } from '../../context/FormContext';

const NumberQuestion = ({question}) => {
    const { formEffects } = useAppContext();
    const { initialForm, set_userForm, setFormValidation } = useForm();
    const { name } = question;
    const [initialValue, setInitialValue] = useState(null);
    const touchedField = useRef(false);
    const [userFormQuestion, setUserFormQuestion] = useState(null);
    
    useEffect(() => {
        if(userFormQuestion){
            set_userForm(prevState => ({
                ...prevState,
                [question.name]: userFormQuestion
            }));
        }
    }, [userFormQuestion]);
    
    useEffect(() => {
        setUserFormQuestion(prevState => ({
            ...prevState,
            id: question.id,
            type: 'number',
            typeSpecifics: question.typeSpecifics
        }));
    }, []);

    useEffect(() => {
        setInitialValue(initialForm[name]);
    }, [initialForm]);

    const checkValidation = () => {
        const questionValid = formEffects.number.checkValidation(userFormQuestion);
        setFormValidation(prevState => ({...prevState, [question.name]: !questionValid}));
    };
    
    useEffect(() => {
        if((userFormQuestion && userFormQuestion.input) || touchedField.current){
            touchedField.current = true;
            checkValidation();
        }
    }, [userFormQuestion]);

    const handleChange = (questionName, updates) => {
        const input = formEffects.number.getTypeSpecificInput(updates.value, question.typeSpecifics);
        setUserFormQuestion(prevState => ({
            ...prevState,
            value: updates.value,
            input,
        }));
    };
    
    return (
        <FormQuestionNumber
            initialValue={initialValue}
            onChange={handleChange}
            question={question}
        />
    );
};

export default NumberQuestion;