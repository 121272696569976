import { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import MultilineText from '../../components/MultilineText';

const classes = {
    row: {
        borderRadius: '8px',
        cursor: 'pointer',
        transition: 'background-color,box-shadow .2s ease-out',
        '&:hover': {
            backgroundColor: theme => theme.palette.action.hover,
            boxShadow: '0 0 8px rgba(0, 0, 0, 0.08)',
        }
    },
};

const CommentLine = ({ comment, noHtmlComment }) => {
    const [longCommentVisible, setLongCommentVisible] = useState(false);
    if(comment){
        return (
            <Box mt={1} style={{width: '100%'}}>
                <Grid container spacing={1} alignItems="center" wrap="nowrap" sx={classes.row} onClick={() => setLongCommentVisible(prevState => !prevState)} style={{width: '100%'}}>
                    <Grid item style={{width: 28}}>
                        <AnnouncementOutlinedIcon fontSize="small" style={{color: 'rgba(0, 0, 0, 0.54)'}} />
                    </Grid>
                    <Grid item style={{width: `calc(100% - 28px)`}}>
                        <Typography variant="body2" noWrap={!longCommentVisible}>{comment.createdByName}:{!longCommentVisible ? <span> &ldquo;<em>{noHtmlComment}</em>&rdquo;</span> : ''}</Typography>
                        {
                            longCommentVisible &&
                            <Typography variant="body1">&ldquo;<em><MultilineText text={noHtmlComment} /></em>&rdquo;</Typography>
                        }
                    </Grid>
                </Grid>
            </Box>
        );
    }
    return null;
};

export default CommentLine;