import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { formEffectTypes } from '../../../utils/constants';

const displayOptions = [
    {value: 'singleLine', label: 'Mostrar em texto corrido'},
    {value: 'lowerAlphaList', label: 'Mostrar em lista'},
];

const EditorEffect = (props) => {
    const { selectedEffect } = props;
    const displayId = selectedEffect.display;
    const displayOption = displayOptions.find(displayOption => displayOption.value === displayId);
    
    return (
        <Box>
            <Box mb={2}>
                <Typography variant="h3">{selectedEffect.label || selectedEffect.question}</Typography>
            </Box>
            <Box mb={1}>
                <Typography variant="body1"><strong>Tipo:</strong> {formEffectTypes.find(formEffectType => formEffectType.value === selectedEffect.type).label}</Typography>
            </Box>
            <Box mb={1}>
                <Typography variant="body1"><strong>Pergunta:</strong> {selectedEffect.question}</Typography>
            </Box>
            {
                displayOption &&
                <Box mb={1}>
                    <Typography variant="body1">- {displayOption.label}</Typography>
                </Box>
            }
        </Box>
    )
};

export default EditorEffect;