import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import CenteredCircularProgress from '../../../../../components/CenteredCircularProgress';
import MultipleAutocomplete from '../../../../../components/MultipleAutocomplete';
import Select from '../../../../../components/Select';
import Window from '../../../../../components/Window';
import { useAppCtxAPI, useAppCtxActiveUser, useAppCtxLoading } from '../../../../../context/SystemContext';
import { useSocket } from '../../../../../context/SocketContext';
import { useClient, useClientCtxActiveClient } from '../../../../../context/ClientContext';
import { serverRequest } from '../../../../../utils/common';

const AddUserFieldWindow = ({open, setOpen, selectedField, data}) => {
    const { templatesOptions } = data;
    const { setLoading, toast } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const loading = useAppCtxLoading();
    const { setUserTemplateFields } = useClient();
    const activeClient = useClientCtxActiveClient();
    const { createSocketConnection } = useSocket();
    const socket = createSocketConnection();
    const nameRef = useRef();
    const defaultField = useMemo(() => ({
        name: '',
        template: [],
        type: 'select',
        options: []
    }), []);
    const [field, set_field] = useState(defaultField);
    const [fieldName, set_fieldName] = useState('');
    const [fieldType, set_fieldType] = useState('string');
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

    const clearForm = useCallback(() => {
        set_field(defaultField);
        set_fieldName('');
        set_fieldType('string');
    }, [defaultField]);

    useEffect(() => {
        if(open){
            if(selectedField){
                let fieldTemplate = [];
                if(!Array.isArray(selectedField.template)){
                    const currentFieldTemplate = templatesOptions.find(template => template.value === fieldTemplate);
                    if(currentFieldTemplate) fieldTemplate = [currentFieldTemplate];
                } else {
                    const currentFieldTemplate = templatesOptions.filter(template => selectedField.template.includes(template.value));
                    if(currentFieldTemplate && currentFieldTemplate.length >= 1){
                        fieldTemplate = currentFieldTemplate;
                    }
                }
                set_field({
                    ...selectedField,
                    template: fieldTemplate,
                });
                set_fieldName(selectedField.name || '');
                set_fieldType(selectedField.type || '');
            } else {
                clearForm();
            }
            setTimeout(() => {
                nameRef.current?.focus();    
            }, 500);
        }
    }, [open, selectedField, templatesOptions, clearForm]);

    useEffect(() => {
        let currentSubmitButtonDisabled = false;
        if(!fieldName || !fieldType || !field.template || field.template.length === 0){
            currentSubmitButtonDisabled = true;
        }
        if(fieldType === 'select'){
            if(!field.options || field.options.length === 0) currentSubmitButtonDisabled = true;
        }
        if(field.options){
            const options = [];
            field.options.forEach(option => {
                if(!option){
                    currentSubmitButtonDisabled = true;
                } else {
                    if(options.includes(option)) currentSubmitButtonDisabled = true;
                    options.push(option);
                }
            });
        }
        setSubmitButtonDisabled(currentSubmitButtonDisabled);
    }, [fieldName, field, fieldType]);

    const handleFieldTypeChange = (newValue) => {
        set_fieldType(newValue);
        if(newValue === 'select'){
            if(!field.options || field.options.length === 0) set_field(prevState => ({...prevState, options: ['']}));
        }
    };
    const handleFieldTemplateChange = (newValue) => {
        set_field(prevState => ({...prevState, template: newValue}))
    };

    const handleSubmit = async () => {
        setLoading(true);
        let postData = {
            fieldName,
            template: field.template.map(template => template.value),
            fieldType,
            options: field.options
        };
        if(selectedField) postData.updateFieldId = selectedField.id;
        try {
            const data = await serverRequest({path: '/data/clients/one/user-created-fields/update', method: 'post', data: postData})
            if(data.userCreatedFields){
                const sortedUserCreatedFields = data.userCreatedFields.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                setUserTemplateFields(sortedUserCreatedFields);
                socket.emit('CLIENT USER TEMPLATE FIELDS UPDATED', { updatedClient: activeClient, userTemplateFields: sortedUserCreatedFields });
            }
            clearForm();
            setOpen(false);
            toast(`Você salvou a informação especial "${(postData.fieldName)}"`);
        } catch (error) {
            toast(error);
        }
        setLoading(false);
    };

    return (
        <Window
            open={open} onClose={() => setOpen(false)}
            title={selectedField ? `Editando informação especial "${selectedField.name}"` : 'Nova informação especial'}
            handleSubmitButtonClick={handleSubmit}
            submitButtonDisabled={loading || submitButtonDisabled}
        >
            {
                loading
                ? <CenteredCircularProgress />
                :
                <>
                    <Box mb={1}>
                        <Grid container spacing={1} alignItems="flex-end">
                            <Grid item xs={12} sm={6} md={9}>
                                <TextField
                                    variant="standard"
                                    disabled={selectedField && activeUser?.type < 9}
                                    fullWidth
                                    inputProps={{ref: nameRef}}
                                    label="Nome"
                                    value={fieldName}
                                    onChange={(e) => set_fieldName(e.target.value)} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <Select
                                    label="Tipo"
                                    options={[
                                        {value: 'string', label: 'Texto'},
                                        {value: 'boolean', label: 'Verdadeiro ou falso'},
                                        {value: 'select', label: 'Múltipla escolha'},
                                    ]}
                                    value={fieldType} onChange={(e) => handleFieldTypeChange(e.target.value)}
                                />
                            </Grid>
                            {
                                (fieldType === 'string' || fieldType === 'select') &&
                                <Grid item xs={12}>
                                    {
                                        field.options.map((option, optionIndex) => (
                                            <Box key={optionIndex}>
                                                <Grid container spacing={1} alignItems="center">
                                                    <Grid item xs>
                                                        <TextField
                                                            variant="standard"
                                                            placeholder={`Opção ${optionIndex + 1}`}
                                                            fullWidth
                                                            value={option}
                                                            onChange={(e) => set_field(prevState => ({...prevState, options: [...prevState.options.slice(0, optionIndex), e.target.value, ...prevState.options.slice(optionIndex + 1)]}))} />
                                                    </Grid>
                                                    <Grid item>
                                                        <IconButton
                                                            onClick={(e) => set_field(prevState => ({...prevState, options: [...prevState.options.slice(0, optionIndex), ...prevState.options.slice(optionIndex + 1)]}))}
                                                            size="large"><ClearIcon /></IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        ))
                                    }
                                    <Box style={{textAlign: 'center'}}>
                                        <Button startIcon={<AddIcon />} onClick={() => set_field(prevState => ({...prevState, options: [...prevState.options, '']}))}>Opção</Button>
                                    </Box>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                {
                                    <MultipleAutocomplete
                                        label="Matriz"
                                        options={templatesOptions} getOptionLabel={(option) => option.label}
                                        value={field.template} onChange={(e, newValue) => handleFieldTemplateChange(newValue)}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </>
            }
        </Window>
    );
};

export default AddUserFieldWindow;