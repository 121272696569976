import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { minutesToStringHoursAndMinutes } from '../utils/common';

const AddOrSubtractDial = ({subtractDisabled, subtractOnClick, addDisabled, addOnClick, number, shouldShowNumber}) => {
    return (
        <Grid container spacing={1} alignItems="center" wrap="nowrap">
            <Grid item>
                <IconButton disabled={subtractDisabled} onClick={subtractOnClick} size="large"><RemoveCircleOutlineIcon /></IconButton>
            </Grid>
            <Grid item
                style={{minWidth: 90, textAlign: 'center'}}
            >
                <Chip variant="outlined" label={shouldShowNumber ? number : minutesToStringHoursAndMinutes(number)} />
            </Grid>
            <Grid item>
                <IconButton disabled={addDisabled} onClick={addOnClick} size="large"><AddCircleOutlineIcon /></IconButton>
            </Grid>
        </Grid>
    );
};

export default AddOrSubtractDial;