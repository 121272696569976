import React, { useEffect, useRef, useState } from 'react';
import { Box, Chip, Collapse, Fab, IconButton, Zoom } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import ActiveProjectsStatusBox from './components/ActiveProjectsStatusBox/ActiveProjectsStatusBox';
import ActiveProjectsWorkTypeBoxes from './components/ActiveProjectsWorkTypeBoxes/ActiveProjectsWorkTypeBoxes';
import NewActiveProject from './components/NewActiveProject';
import SearchEngineTextField from '../../../../components/SearchEngineTextField';
import TaskBoard from '../../Tasks/TaskBoard';
import ViewBox from '../../../../components/ViewBox';
import { useAppCtxAPI, useAppCtxActiveUser } from '../../../../context/SystemContext';
import { useSocket } from '../../../../context/SocketContext';
import { useClientCtxActiveClient, useClientCtxActiveProjects } from '../../../../context/ClientContext';
import { useTasksCtxAPI, useTasksCtxActiveProjectBoards, useTasksCtxFilters } from '../../../../context/TasksContext';
import { useOperatorCtxOperators } from '../../context/OperatorContext';
import { serverRequest } from '../../../../utils/common';
import { sortByKey } from '../../../../utils/filters';
import mongoose from 'mongoose';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

const classes = {
    fab: {
        position: 'absolute',
        bottom: '16px',
        right: '16px',
    },
};

const activeProjectStatusOptions = [
    {value: 'accepted', label: 'Contratado', icon: <CheckCircleOutlineOutlinedIcon />},
    {value: 'pending', label: 'Pendente', icon: <PendingOutlinedIcon />}
];

const activeProjectTypes = [
    {value: 'atendimento', label: 'Atendimento', color: '#f6b26b', minWidth: '96.95px'},
    {value: 'contratos', label: 'Contratos', color: '#6d9eeb', minWidth: '77.91px'},
    {value: 'clearance', label: 'Clearance', color: '#93c47d', minWidth: '78.03px'},
    {value: 'alvaras', label: 'Alvarás', color: '#ffd966', minWidth: '62.89px'},
    {value: 'defesa', label: 'Defesa', color: '#e06666', minWidth: '59.88px'},
    {value: 'marca', label: 'Registro de marca', color: '#8e7cc3', minWidth: '126.55px'},
    {value: 'coproducaoInternacional', label: 'Coprodução internacional', color: '#38761d', minWidth: '173.11px'},
    {value: 'other', label: 'Outro', color: '#b7b7b7', minWidth: '54.28px'},
];

const renderAdditionalBoxComponent = (activeProject) => {
    if(activeProject.operatorId === 'newActiveProjects'){
        const activeProjectStatus = activeProjectStatusOptions.find(activeProjectStatusOption => activeProjectStatusOption.value === activeProject.status);
        if(activeProjectStatus){
            return (
                <Box mb={0.5} mt={0.5}>
                    <ActiveProjectsStatusBox
                        activeProjectStatus={activeProjectStatus}
                        style={{border: '2px solid #313330', opacity: 1, padding: '1px'}}
                        textStyle={{opacity: 1}}
                    />
                </Box>
            );
        }
    }
    return null;
};

const TasksAccounts = () => {
    const { toast } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const {createSocketConnection} = useSocket();
    const socket = createSocketConnection();
    const activeClient = useClientCtxActiveClient();
    const activeClientActiveProjects = useClientCtxActiveProjects();
    const { setActiveProjectBoards: setBoards, setFilters } = useTasksCtxAPI();
    const boards = useTasksCtxActiveProjectBoards();
    const filters = useTasksCtxFilters();
    const operators = useOperatorCtxOperators();
    const [loading, setLoading] = useState(true);
    const defaultFiltersValue = { workType: '' };

    const [newActiveProjectViewOpen, setNewActiveProjectViewOpen] = useState(false);
    const [activeProjectViewSelectedBox, setActiveProjectViewSelectedBox] = useState(null);

    const shouldEmitBoardsUpdatedSocket = useRef(false);

    useEffect(() => {
        if(activeClientActiveProjects && operators){
            let currentActiveClientActiveProjects = (activeClientActiveProjects || []).sort(sortByKey('orderNumber'));
            const currentActiveProjects = [{_id: 'newActiveProjects', name: 'NOVOS PROJETOS', activeProjects: [] }];
            operators.forEach(operator => {
                currentActiveProjects.push({
                    _id: operator._id,
                    img: operator.img,
                    name: operator.screenName,
                    activeProjects: []
                });
            });
            currentActiveClientActiveProjects.forEach(activeProject => {
                const boardIndex = currentActiveProjects.findIndex(board => board._id === activeProject.operatorId);
                if(boardIndex !== -1){
                    currentActiveProjects[boardIndex].activeProjects.push(activeProject);
                }
            });
            setBoards(currentActiveProjects);
        }
    }, [activeClientActiveProjects, operators]);

    useEffect(() => {
        if(shouldEmitBoardsUpdatedSocket.current){
            shouldEmitBoardsUpdatedSocket.current = false;
            socket.emit('ACTIVE PROJECTS UPDATED', { actionUserId: activeUser._id, actionUserName: activeUser.fullName, updatedActiveProjects: boards, workspaceId: activeClient._id });
        }
    }, [boards])

    const handleActiveProjectDragEnd = (data) => {
        const { destination, source, draggableId } = data;

        if(!destination) return;

        if(destination.droppableId === source.droppableId && destination.index === source.index) return;
        
        setBoards(prevState => {
            const bulkActions = [];
            const nextState = [...prevState];
            
            // SOURCE BOARD
            const sourceBoardIndex = nextState.findIndex(board => board._id === source.droppableId);
            const deletedTask = nextState[sourceBoardIndex].activeProjects[source.index];
            nextState[sourceBoardIndex].activeProjects = [
                ...nextState[sourceBoardIndex].activeProjects.slice(0, source.index),
                ...nextState[sourceBoardIndex].activeProjects.slice(source.index + 1)
            ].map((nextStateActiveProject, nextStateActiveProjectIndex) => {
                const newOrderNumber = nextStateActiveProjectIndex;
                const prevStateSameActiveProject = prevState[sourceBoardIndex].activeProjects.find(prevStateActiveProject => prevStateActiveProject._id === nextStateActiveProject._id);
                if(prevStateSameActiveProject){
                    if(prevStateSameActiveProject.orderNumber !== nextStateActiveProjectIndex){
                        nextStateActiveProject.orderNumber = newOrderNumber;
                        bulkActions.push({activeProjectId: nextStateActiveProject._id, action: 'update', updates: { orderNumber: newOrderNumber }});
                    }
                }
                return nextStateActiveProject;
            });

            // DESTINATION BOARD
            const destinationBoardIndex = nextState.findIndex(board => board._id === destination.droppableId);
            const updates = {operatorId: destination.droppableId, orderNumber: destination.index};
            const updatedActiveProject = { ...deletedTask, ...updates };
            nextState[destinationBoardIndex].activeProjects = [
                ...nextState[destinationBoardIndex].activeProjects.slice(0, destination.index),
                updatedActiveProject,
                ...nextState[destinationBoardIndex].activeProjects.slice(destination.index)
            ].map((nextStateActiveProject, nextStateActiveProjectIndex) => {
                const newOrderNumber = nextStateActiveProjectIndex;
                const prevStateSameActiveProject = prevState[destinationBoardIndex].activeProjects.find(prevStateActiveProject => prevStateActiveProject._id === nextStateActiveProject._id);
                if(prevStateSameActiveProject){
                    if(prevStateSameActiveProject.orderNumber !== nextStateActiveProjectIndex){
                        nextStateActiveProject.orderNumber = newOrderNumber;
                        bulkActions.push({activeProjectId: nextStateActiveProject._id, action: 'update', updates: { orderNumber: newOrderNumber }});
                    }
                }
                return nextStateActiveProject;
            });

            bulkActions.push({activeProjectId: draggableId, action: 'update', updates});

            bulkActionsAction(bulkActions, prevState);

            shouldEmitBoardsUpdatedSocket.current = true;

            return nextState;
        });
    };

    const bulkActionsAction = async (bulkActions, prevState) => {
        try {
            const data = await serverRequest({ path: `/data/workspaces/${activeClient._id}/active-projects/bulk-actions`, data: { actions: bulkActions } });
            // setActiveProjects(data.activeProjects);
        } catch (error) {
            shouldEmitBoardsUpdatedSocket.current = true;
            setBoards(prevState);
            toast('Algo deu errado. As alterações foram desfeitas.');
        }
    };

    const handleAddActiveProjectConfirm = (activeProject) => {
        const activeProjectName = activeProject.name;
        const activeProjectNotes = activeProject.notes;
        const activeProjectWorkTypeId = activeProject.workType;
        const activeProjectStatus = activeProject.status;
        
        if(activeProject._id){
            return setBoards(prevState => {
                const bulkActions = [ {activeProjectId: activeProject._id, action: 'update', updates: { name: activeProjectName, notes: activeProjectNotes, status: activeProjectStatus, workType: activeProjectWorkTypeId }} ];
                bulkActionsAction(bulkActions, prevState);
                const activeProjectBoardIndex = boards.findIndex(board => board._id === activeProject.operatorId);
                const activeProjectIndex = boards[activeProjectBoardIndex].activeProjects.findIndex(boardActiveProject => boardActiveProject._id === activeProject._id);
                shouldEmitBoardsUpdatedSocket.current = true;
                return [
                    ...prevState.slice(0, activeProjectBoardIndex),
                    {
                        ...prevState[activeProjectBoardIndex],
                        activeProjects: [
                            ...prevState[activeProjectBoardIndex].activeProjects.slice(0, activeProjectIndex),
                            activeProject,
                            ...prevState[activeProjectBoardIndex].activeProjects.slice(activeProjectIndex + 1),
                        ]
                    },
                    ...prevState.slice(activeProjectBoardIndex + 1)
                ];
            });
        }

        const newActiveProjectsBoardIndex = 0;
        const ObjectId = mongoose.Types.ObjectId;
        const newActiveProjectId = new ObjectId().toString();
        const foundActiveProjectType = activeProjectTypes.find(activeProjectType => activeProjectType.value === activeProjectWorkTypeId);
        const newActiveProject = {
            _id: newActiveProjectId,
            name: activeProjectName, notes: activeProjectNotes, status: activeProjectStatus, workType: activeProjectWorkTypeId,
            operatorId: 'newActiveProjects',
            orderNumber: boards[newActiveProjectsBoardIndex].activeProjects.length,
            backgroundColor: foundActiveProjectType.color,
            viewed: true, viewedAt: new Date
        };

        setBoards(prevState => {
            const bulkActions = [
                { activeProjectId: newActiveProjectId, action: 'create', newActiveProject }
            ];
            bulkActionsAction(bulkActions, prevState);
            shouldEmitBoardsUpdatedSocket.current = true;
            return [
                ...prevState.slice(0, newActiveProjectsBoardIndex),
                {
                    ...prevState[newActiveProjectsBoardIndex],
                    activeProjects: [...prevState[newActiveProjectsBoardIndex].activeProjects, newActiveProject]
                },
                ...prevState.slice(newActiveProjectsBoardIndex + 1)
            ];
        });
    };

    const handleAddActiveProjectClick = () => {
        setNewActiveProjectViewOpen(true);
    };

    const handleDeleteActiveProjectConfirm = (activeProject) => {
        setBoards(prevState => {
            const activeProjectBoardIndex = boards.findIndex(board => board._id === activeProject.operatorId);
            if(activeProjectBoardIndex !== -1){
                const activeProjectIndex = boards[activeProjectBoardIndex].activeProjects.findIndex(boardActiveProject => boardActiveProject._id === activeProject._id);
                if(activeProjectIndex !== -1){
                    const bulkActions = [
                        {activeProjectId: activeProject._id, action: 'delete'}
                    ];

                    const nextState = [
                        ...prevState.slice(0, activeProjectBoardIndex),
                        {
                            ...prevState[activeProjectBoardIndex],
                            activeProjects: [
                                ...prevState[activeProjectBoardIndex].activeProjects.slice(0, activeProjectIndex),
                                ...prevState[activeProjectBoardIndex].activeProjects.slice(activeProjectIndex + 1),
                            ].map((nextStateActiveProject, nextStateActiveProjectIndex) => {
                                const newOrderNumber = nextStateActiveProjectIndex;
                                const prevStateSameActiveProject = prevState[activeProjectBoardIndex].activeProjects.find(prevStateActiveProject => prevStateActiveProject._id === nextStateActiveProject._id);
                                if(prevStateSameActiveProject){
                                    if(prevStateSameActiveProject.orderNumber !== nextStateActiveProjectIndex){
                                        nextStateActiveProject.orderNumber = newOrderNumber;
                                        bulkActions.push({activeProjectId: nextStateActiveProject._id, action: 'update', updates: { orderNumber: newOrderNumber }});
                                    }
                                }
                                return nextStateActiveProject;
                            })
                        },
                        ...prevState.slice(activeProjectBoardIndex + 1)
                    ];

                    bulkActionsAction(bulkActions, prevState);

                    shouldEmitBoardsUpdatedSocket.current = true;

                    return nextState;
                }
            }
            return prevState;
        });
    };

    const boxFilterFn = (activeProject, filters) => {
        let currentCollapseTask = false;
        if(filters.text){
            currentCollapseTask = true;
            if(activeProject.name.toUpperCase().indexOf(filters.text.toUpperCase()) !== -1){
                currentCollapseTask = false;
            }
        }
        if(filters.workType){
            currentCollapseTask = true;
            if(filters.workType === activeProject.workType) {
                currentCollapseTask = false;
            }
        }
        return currentCollapseTask;
    };

    const handleBoxClick = (activeProject) => {
        setActiveProjectViewSelectedBox(activeProject);
        setNewActiveProjectViewOpen(true);
    };

    const handleSearch = (newValue) => {
        setFilters(prevState => ({
            ...prevState,
            text: newValue
        }));
    };

    const handleClearSearchClick = () => {
        handleSearch('');
    };

    const topHeight = 56;

    return (
        <ViewBox style={{height: '100%', paddingTop: 8, paddingBottom: 8}}>
            <Box
                sx={{
                    height: `${topHeight}px`,
                }}
            >
                <Grid container spacing={1} alignItems="center" justifyContent={{ xs: 'flex-start', md: 'center' }} wrap="nowrap" sx={{overflowX: 'auto',}}>
                    <Grid sx={{ maxWidth: '211.16px' }}>
                        <SearchEngineTextField onSearch={handleSearch} placeholder="Pesquisar" />
                    </Grid>
                    {
                        filters.text &&
                        <Grid sx={{ maxWidth: '100px' }}>
                            <Chip color="primary" label={filters.text} onDelete={handleClearSearchClick} />
                        </Grid>
                    }
                    <ActiveProjectsWorkTypeBoxes
                        activeProjectTypes={activeProjectTypes}
                        onClick={(activeProjectType) => setFilters(prevState => ({...prevState, workType: activeProjectType.value}))}
                        style={(activeProjectType) => !filters.workType || filters.workType === activeProjectType.value || filters.workType === 'all' ? ({border: '2px solid #313330', opacity: 1}) : undefined} // ({ border: '2px solid transparent', opacity: 0.6 })}
                        textStyle={(activeProjectType) => !filters.workType || filters.workType === activeProjectType.value || filters.workType === 'all' ? ({opacity: 1}) : undefined} // ({ opacity: 0.6 })}
                    />
                    <Grid>
                        <Zoom in={!!filters.workType}>
                            <IconButton size="small" onClick={() => setFilters(prevState => ({...prevState, workType: ''}))}><ClearIcon /></IconButton>
                        </Zoom>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{
                height: `calc(100% - ${topHeight}px)`
            }}>
                <DragDropContext onDragEnd={handleActiveProjectDragEnd}>
                    <Grid container spacing={1} alignItems="flex-start" justifyContent="center" style={{height: '100%'}}>
                        <Grid item xs container spacing={1} alignItems="flex-start" wrap="nowrap" style={{height: '100%', overflowX: 'auto'}}>
                            {
                                boards.map((activeProject) => (
                                    <Grid item key={activeProject._id} style={{height: '100%', width: `${(100 / (boards.length))}%`, minWidth: '100px'}}>
                                        <Droppable droppableId={activeProject._id}>
                                            {(provided, snapshot) => {
                                                let boardTitle, boardUser;
                                                if(activeProject._id === 'newActiveProjects'){
                                                    boardTitle = 'NOVOS PROJETOS';
                                                } else {
                                                    boardUser = { img: activeProject.img, screenName: activeProject.name };
                                                }
                                                return (
                                                    <TaskBoard
                                                        boardId={activeProject._id}
                                                        boxFilterFn={boxFilterFn}
                                                        innerRef={provided.innerRef}
                                                        isDraggingOver={snapshot.isDraggingOver}
                                                        onBoxClick={handleBoxClick}
                                                        nameKey="name"
                                                        renderAdditionalBoxComponent={renderAdditionalBoxComponent}
                                                        tasks={activeProject.activeProjects}
                                                        title={boardTitle}
                                                        user={boardUser}

                                                        {...provided.droppableProps}
                                                    >
                                                        {provided.placeholder}
                                                    </TaskBoard>
                                                )
                                            }}
                                        </Droppable>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                </DragDropContext>
            </Box>

            <NewActiveProject
                open={newActiveProjectViewOpen} setOpen={setNewActiveProjectViewOpen}
                activeProjectViewSelectedBox={activeProjectViewSelectedBox} setActiveProjectViewSelectedBox={setActiveProjectViewSelectedBox}
                activeProjectStatusOptions={activeProjectStatusOptions}
                activeProjectTypes={activeProjectTypes}
                handleAddActiveProjectConfirm={handleAddActiveProjectConfirm}
                handleDeleteActiveProjectConfirm={handleDeleteActiveProjectConfirm}
            />

            <Zoom in={true}>
                <Fab
                    variant="circular" color="primary"
                    sx={classes.fab}
                    onClick={handleAddActiveProjectClick}
                >
                    <AddIcon />
                </Fab>
            </Zoom>

        </ViewBox>
    );
};

export default TasksAccounts;