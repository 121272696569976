import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import EventIcon from '@mui/icons-material/Event';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileWord, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import CommentLine from './Documents/CommentLine';
import ClientDocumentLink from './ClientDocumentLink';
import DocumentFilesList from './DocumentFilesList';
import PendingDocLink from './PendingDocLink';
import SignatureStatus from './eSignature/SignatureStatus';
import Tooltip from './Tooltip';
import { useAppCtxAPI, useAppCtxActiveUser } from '../context/SystemContext';
import { useDocumentsAPI } from '../context/DocumentsContext';
import { checkDocumentVersion, getSigners, storeStringifiedDocument } from '../utils/eSignature';
import { getDocumentVersionLink, getUserImageById, getUserNameById, updateStateChangeArrayItemWith_id } from '../utils/common';
import { REMOTE_SERVER_PATH, SERVER_PATH } from '../utils/constants';
import useWindowSize from '../hooks/useWindowSize';

const MainTextButton = ({document, handleDocumentHistoryPress, version}) => (
    <Typography
        display="inline"
        variant="body1"
        sx={{
            color: (theme) => theme.palette.primary.main,
            '&:hover': {
                cursor: 'pointer',
                textDecoration: 'underline',
            }
        }}
        onClick={(e) => handleDocumentHistoryPress(e, document)}
    >{document.name}{version && version.name ? ` | ${version.name}` : ''}</Typography>
);

const StarredItem = ({name, info, timestamp, user, users}) => (
    <ListItem dense style={{display: 'flex', paddingTop: 1, paddingBottom: 1}}>
        <Box style={{flex: 1, lineHeight: 1}}>
            <Tooltip
                text={timestamp || user ? `${timestamp ? `Em ${moment(timestamp).format('D/M/YY')}` : ''}${user ? ` por ${getUserNameById(users, user)}` : ''}` : name}
            >
                <Typography variant="body2" component="span" style={{lineHeight: 1}}><span style={{fontWeight: 500}}>{name}</span> &#8680; {info?.replace(/\n/g, ' | ')}</Typography>
            </Tooltip>
        </Box>
    </ListItem>
);

const VersionIcon = ({theme, version}) => {
    let fileExtension = '';
    if(version.fileFormat){
        if(version.serverFile){
            fileExtension = version.link.split('.').pop();
        } else {
            if(version.fileFormat === 'pdf'){
                fileExtension = 'pdf';
            } else {
                fileExtension = version.googleDoc ? 'googleDoc' : version.link.split('.').pop();
                if(fileExtension !== 'pdf' && fileExtension !== 'docx') fileExtension = 'googleDoc';
            }
        }
    } else {
        if(version.link){
            fileExtension = version.link.split('.').pop();
        } else if(version.googleDoc){
            fileExtension = 'googleDoc';
        }
    }

    if(fileExtension === 'pdf'){
        return <FontAwesomeIcon icon={faFilePdf} size="1x" color={theme.palette.secondary.main} />;
    } else if(fileExtension === 'docx'){
        return <FontAwesomeIcon icon={faFileWord} size="1x" color={theme.palette.primary.main} />;
    } else if(fileExtension === 'googleDoc'){
        return <FontAwesomeIcon icon={faGoogle} size="1x" color={theme.palette.primary.main} />;
    }

    return null;
};

const VersionRowMainColumn = (props) => {
    const {
        activeUserIsManager,
        activeUserIsOperator,
        data,
        documentFolder,
        comment,
        item,
        noHtmlComment,
        project,
        setSelectedDocument,
        templates,
        updateDocument,
        userIsManager,
        users,
    } = props;
    const theme = useTheme();
    const { toast } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const { dispatch: dispatchDocuments, hideSignDocumentView, showAddDocumentStarredInformationView, showChangeDocumentStarredInformationView, showDocumentAlertsView, showSignDocumentView, showDocumentHistoryView } = useDocumentsAPI();
    const {
        folders,
        set_defaultSigners,
        setDocs,
        set_changeStarredInfoList,
        docsAlerts,
        eSignature
    } = data;
    const [currentVersion, set_currentVersion] = useState(null);
    const [showDocumentsStarredInformation, setShowDocumentsStarredInformation] = useState(false);
    const [documentWithAlerts, setDocumentWithAlerts] = useState(false);
    const { windowWidth } = useWindowSize();
    const shouldOpenSignDocumentWindow = useRef(false);

    useEffect(() => {
        if(item){
            const versions = item.documents;
            let version = {};
            if(Array.isArray(versions) && versions.length >= 1){
                version = versions[versions.length - 1];
            }
            set_currentVersion(version);
        }
    }, [item]);

    useEffect(() => {
        setDocumentWithAlerts(item && docsAlerts && docsAlerts.some(alert => alert.documentId === item._id && !alert.sent));
    }, [item, docsAlerts]);

    useEffect(() => {
        if(item && item.project && folders){
            const documentFolder = folders.find(folder => folder._id === item.project);
            if(documentFolder){
                setShowDocumentsStarredInformation(!!documentFolder.showDocumentsStarredInformation);
            }
        }
    }, [item, folders]);

    const handle_documentHistoryPress = (event, clickedDocument) => {
        showDocumentHistoryView({
            left: event.clientX - 2,
            top: event.clientY - 4,
        }, clickedDocument, activeUserIsOperator, activeUserIsManager, clickedDocument.project);
    };

    const handle_documentAlertsPress = (event, clickedDocument) => {
        showDocumentAlertsView({
            left: event.clientX - 2,
            top: event.clientY - 4,
        }, clickedDocument);
    };
    
    const Starred = ({record}) => {
        const handle_newStarredInfoClick = (event, list = []) => {
            set_changeStarredInfoList(list);
            showAddDocumentStarredInformationView({
                left: event.clientX - 2,
                top: event.clientY - 4,
            }, record);
        };

        const StarredList = ({list}) => {
            const handle_editStarredListClick = (event) => {
                set_changeStarredInfoList(list);
                showChangeDocumentStarredInformationView({
                    left: event.clientX - 2,
                    top: event.clientY - 4,
                }, record);
            };
            return (
                <Box style={{display: 'flex', alignItems: 'center', border: '1px solid #e8e8e8', borderRadius: '8px'}} py={0.3}>
                    <Box style={{flex: 1}}>
                        <List disablePadding>
                            {
                                list.map((item, index) => (
                                    <StarredItem key={index} name={item.key} info={item.value} timestamp={item.timestamp} user={item.user} users={users} />
                                ))        
                            }
                        </List>
                    </Box>
                    <Box>
                        <IconButton
                            size="small"
                            onClick={(e) => handle_editStarredListClick(e)}><EditIcon /></IconButton>
                        <IconButton
                            size="small"
                            onClick={(e) => handle_newStarredInfoClick(e, list)}><AddIcon /></IconButton>
                    </Box>
                </Box>
            )
        };

        const documentInfo = record.info;
        if(Array.isArray(documentInfo) && documentInfo.length >= 1){
            return <StarredList list={documentInfo} />;
        }

        return (
            <Box style={{textAlign: 'center'}}>
                <Box style={{display: 'inline-block', backgroundColor: '#e8e8e8', borderRadius: '50%'}}>
                    <Tooltip text={"Cadastrar informações em destaque"}>
                        <IconButton
                            style={{height: 8, width: 8}}
                            onClick={(e) => handle_newStarredInfoClick(e)}
                            size="large"><AddIcon /></IconButton>
                    </Tooltip>
                </Box>
            </Box>
        );
    };
    const DocumentNotificationIcon = ({item}) => (
        <Grid item style={{width: 30}}>
            <Tooltip text="Lembretes">
                <IconButton size="small" onClick={(e) => handle_documentAlertsPress(e, item)}><EventIcon /></IconButton>
            </Tooltip>
        </Grid>
    );
    const isValidHttpUrl = (string) => {
        let url;
        
        try {
          url = new URL(string);
        } catch (_) {
          return false;  
        }
      
        return url.protocol === "http:" || url.protocol === "https:";
    };
    
    const MainTextUrl = ({document, version}) => (
        <ClientDocumentLink document={document} versionName={version?.name} />
    );
    const textTypes = {
        download: (document, version) => (
            <MainTextUrl document={document} version={version} />
        ),
        googleDoc: (document, version) => (
            <MainTextUrl document={document} version={version} />
        ),
        link: (document, version) => {
            const documentVersionLink = getDocumentVersionLink(version, REMOTE_SERVER_PATH);
            if(isValidHttpUrl(documentVersionLink)){
                return (
                    <MainTextUrl document={document} version={version} />
                );
            }
            return (<MainTextButton document={document} version={version} handleDocumentHistoryPress={handle_documentHistoryPress} />);
        },
        text: (document, version) => (
            <MainTextButton document={document} version={version} handleDocumentHistoryPress={handle_documentHistoryPress} />
        )
    };

    const handleSignDocumentWindowOpenClick = async () => {
        let newSignatureEnvelope = checkDocumentVersion({
            dispatchDocuments,
            eSignatureCallback: () => {
                hideSignDocumentView();
            },
            // eSignaturePlatform: selectedDocumentVersion.eSignaturePlatform,
            selectedDocument: item,
            // selectedVersionId,
            setDocuments: updateDocument,
            setErrorDescription: toast
        });
        if(newSignatureEnvelope){
            newSignatureEnvelope = storeStringifiedDocument({ dispatchDocuments, eSignatureEnvelope: newSignatureEnvelope, selectedDocument: item, type: 'document' });
            updateStateChangeArrayItemWith_id({...item, loadingSignIcon: true, updatedAt: new Date()}, setDocs);
            let foundFolders = folders;
            if(activeUserIsOperator){
                await axios.post(SERVER_PATH + '/data/projects/one', {
                    projectId: item.project,
                    fields: ['client', 'info', 'signers', 'useClientESignatureSigners']
                })
                .then(res => {
                    const foundFolder = res.data.project;
                    foundFolders = [foundFolder];
                })
                .catch(err => {});
            }
            let foundTemplates = templates;
            await axios.post(SERVER_PATH + '/data/templates/one', {
                templateId: item.template,
                fields: ['form']
            })
            .then(res => {
                const foundTemplate = res.data;
                if(foundTemplate) foundTemplates = [foundTemplate];
            })
            .catch(err => {});
            updateStateChangeArrayItemWith_id({...item, loadingSignIcon: false, updatedAt: new Date()}, setDocs);
            
            let signDocumentViewDefaultSigners = [];
            
            if(foundTemplates) signDocumentViewDefaultSigners = getSigners(foundFolders, foundTemplates, item, set_defaultSigners);

            shouldOpenSignDocumentWindow.current = true;
            dispatchDocuments({ type: 'SET SIGNATURE ENVELOPE', payload: newSignatureEnvelope });
            //?TODO dispatchDocuments
            //?TODO only one call
            
            const documentName = item.name;
            // projectFolder: projects.find(project => project._id == selectedDocument?.project)?.shortName || undefined,
            const signDocumentViewFolderFolder = documentFolder ? (documentFolder.shortName || documentFolder._id) : undefined;
            // projectName: projects.find(project => project._id == (selectedDocument?.project || selectedDocument?.client))?.name,
            const signDocumentViewFolderName = documentFolder?.name;
            const signDocumentViewSignaturePlatform = documentFolder?.eSignaturePlatform || 'zapSign';

            showSignDocumentView({
                action: '',
                document: item,
                documentName,
                documentLocation: signDocumentViewFolderFolder,
                folderName: signDocumentViewFolderName,
                initialSigners: signDocumentViewDefaultSigners,
                platform: signDocumentViewSignaturePlatform,
                envelope: newSignatureEnvelope
            });
        }
    };

    return (
        <Box>
            {
                !item.sent || item.deleted
                ?
                <Box>
                    <Grid container alignItems="center" wrap="nowrap">
                        <Grid item style={{width: 30}}>
                            <Tooltip text="Histórico">
                                <IconButton size="small" onClick={(e) => handle_documentHistoryPress(e, item)}><AccessTimeIcon /></IconButton>
                            </Tooltip>
                        </Grid>
                        {
                            documentWithAlerts &&
                            <DocumentNotificationIcon item={item} />
                        }
                        <Grid item>
                            {
                                activeUser?.type >= 4
                                ? <PendingDocLink selectedDocument={item} />
                                : <Typography variant="body2">{item.name}</Typography>
                            }
                        </Grid>
                        <Grid item style={{width: 75, textAlign: 'center'}}>
                            <Typography variant="body2"><em>({!item.sent ? 'Em análise' : 'Cancelado'})</em></Typography>
                        </Grid>
                    </Grid>
                    <DocumentFilesList doc={item} />
                </Box>
                :
                <Box>
                    <Grid container spacing={1} alignItems="center" style={{width: '100%'}}>
                        {
                            activeUserIsOperator &&
                            <Grid item style={{width: 38}}>
                                <Tooltip text={`${getUserNameById(users, item.sentBy)}`}>
                                    <Avatar style={{height: 30, width: 30}} alt={getUserNameById(users, item.sentBy)} src={`/uploads/avatars/${getUserImageById(users, item.sentBy)}`} />
                                </Tooltip>
                            </Grid>
                        }
                        <Grid item style={{width: 30}}>
                            <Tooltip text="Histórico">
                                <IconButton size="small" onClick={(e) => handle_documentHistoryPress(e, item)}><AccessTimeIcon /></IconButton>
                            </Tooltip>
                        </Grid>
                        {
                            documentWithAlerts &&
                            <DocumentNotificationIcon item={item} />
                        }
                        {
                            currentVersion &&
                            <Grid item style={{width: 18}}>
                                <VersionIcon version={currentVersion} theme={theme} />
                            </Grid>
                        }
                        {
                            currentVersion &&
                            <Grid item style={{width: `calc(100% - 30px${documentWithAlerts ? ' - 30px' : ''} - 18px${eSignature && templates && windowWidth && windowWidth >= 600 ? ' - 280px' : ''}${activeUserIsOperator ? ' - 38px' : ''})`}}>
                                {textTypes[currentVersion.type || 'googleDoc'](item, currentVersion)}
                            </Grid>
                        }
                        {
                            (eSignature && templates && windowWidth && windowWidth >= 600) &&
                            <Grid item style={{width: 280}}>
                                <SignatureStatus
                                    activeUserIsManager={activeUserIsManager}
                                    activeUserIsOperator={activeUserIsOperator}
                                    data={{
                                        eSignature,
                                        handleSignDocumentWindowOpenClick,
                                        project,
                                        subDocument: currentVersion,
                                        subDocumentParentDocument: item,
                                        setSelectedSubDocumentParentDocument: setSelectedDocument,
                                        setDocuments: setDocs,
                                        userIsManager
                                    }}
                                    documentType="document"
                                    loadingSignature={item.loadingSignIcon}
                                    updateDocument={updateDocument}
                                />
                            </Grid>
                        }
                    </Grid>
                    <CommentLine comment={comment} noHtmlComment={noHtmlComment} users={users} />
                    {
                        (templates && windowWidth && windowWidth < 600) &&
                        <Box mt={1}>
                            <Grid container justifyContent="center">
                                <Grid item>
                                    <SignatureStatus
                                        activeUserIsManager={activeUserIsManager}
                                        activeUserIsOperator={activeUserIsOperator}
                                        data={{
                                            eSignature,
                                            handleSignDocumentWindowOpenClick,
                                            project,
                                            subDocument: currentVersion,
                                            subDocumentParentDocument: item,
                                            setSelectedSubDocumentParentDocument: setSelectedDocument,
                                            setDocuments: setDocs,
                                            userIsManager
                                        }}
                                        documentType="document"
                                        loadingSignature={item.loadingSignIcon}
                                        updateDocument={updateDocument}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    }
                    {
                        showDocumentsStarredInformation &&
                        <Starred record={item} />
                    }
                </Box>
            }
        </Box>
    );
};

export default VersionRowMainColumn;