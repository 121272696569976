import { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Tooltip from '../../../../../../../../components/Tooltip';
import { useDocumentsCtxSelectedDocuments } from '../../../../../../../../context/DocumentsContext';

const UserSelectDocumentLabel = ({pendingDocument}) => {
    const selectedDocuments = useDocumentsCtxSelectedDocuments();
    const [selectedBy, setSelectedBy] = useState(null);

    useEffect(() => {
        if(selectedDocuments[pendingDocument._id]){
            setSelectedBy({
                color: selectedDocuments[pendingDocument._id].color,
                userImg: selectedDocuments[pendingDocument._id].userImg,
                userName: selectedDocuments[pendingDocument._id].userName,
            });
        } else {
            setSelectedBy(null);
        }
    }, [selectedDocuments]);

    if(selectedBy){
        return (
            <Tooltip text={selectedBy.userName || ''}>
                <Avatar
                    style={{position: 'absolute', top: 0, left: 0, transform: 'translate(-50%, -50%)', border: `2px solid ${selectedBy.color}`, height: 24, width: 24}}
                    alt={selectedBy.userName || ''}
                    src={selectedBy.userImg ? `/uploads/avatars/${selectedBy.userImg}` : undefined}
                />
            </Tooltip>
        );
    }
    return null;
};

export default UserSelectDocumentLabel;