import React, { useEffect, useState } from 'react';
import { Grid, TextField } from '@mui/material';
import Window from '../../../components/Window';
import { serverRequest, updateStateAddArrayItem } from '../../../utils/common';
import { ERROR_MESSAGE_MANDATORY_FIELD_EMPTY } from '../../../utils/constants';
import { useAppCtxAPI } from '../../../context/SystemContext';

const NewClientWindow = ({open, setOpen}) => {
    const { setAvailableClients, toast } = useAppCtxAPI();
    const [loading, setLoading] = useState(false);

    const defaultNewClient = {
        name: '',
        screenName: '',
        shortName: ''
    };
    const [newClient, setNewClient] = useState(defaultNewClient);

    useEffect(() => {
        if(open){
            clearForm();
        }
    }, [open]);

    const handleSubmit = async () => {
        if(!newClient.name || !newClient.screenName || !newClient.shortName){
            toast(ERROR_MESSAGE_MANDATORY_FIELD_EMPTY);
        } else {
            setLoading(true);
            handleClose();
            try {
                const data = await serverRequest({path: '/data/system/clients/create', method: 'post', data: { newClient }});
                const createdClient = data.createdClient;
                updateStateAddArrayItem(createdClient, setAvailableClients);
            } catch (error) {
                toast(error);
                setOpen(true);
            }
            setLoading(false);
        }
    }
    
    const clearForm = () => {
        setNewClient(defaultNewClient);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Window
            open={open} onClose={handleClose}
            title="Novo cliente"
            handleSubmitButtonClick={handleSubmit}
            submitButtonDisabled={loading}
        >
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                    <TextField
                        variant="standard"
                        label="Nome do cliente"
                        fullWidth
                        required
                        value={newClient.name}
                        onChange={(e) => setNewClient(prevState => ({...prevState, name: e.target.value}))} />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        variant="standard"
                        label="Nome de exibição"
                        fullWidth
                        required
                        value={newClient.screenName}
                        onChange={(e) => setNewClient(prevState => ({...prevState, screenName: e.target.value}))} />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        variant="standard"
                        label="URL amigável"
                        fullWidth
                        required
                        value={newClient.shortName}
                        onChange={(e) => setNewClient(prevState => ({...prevState, shortName: e.target.value}))} />
                </Grid>
            </Grid>
        </Window>
    );
}

export default NewClientWindow;