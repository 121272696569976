import { createContext, useContext, useMemo, useReducer } from 'react';
import { useAppCtxActiveUser } from '../context/SystemContext';
import { CLEARANCE_RISK_OPTIONS } from '../utils/constants';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

const ClearanceCtxAPI = createContext();

const ClearanceCtxActiveClient = createContext();
const ClearanceCtxClearanceQuestionsListFilters = createContext();
const ClearanceCtxClearanceQuestionsListFiltersMenu = createContext();
const ClearanceCtxClearanceQuestionsListFiltersMenuOptions = createContext();

const reducer = (state, action) => {
    const { payload, type } = action;

    switch (type) {
        case 'SET STATE':
            let newValue = payload.value;
            if(typeof newValue === 'function'){
                newValue = newValue(state[payload.key]);
            }
            return { ...state, [payload.key]: newValue };
        case 'SHOW CLEARANCE QUESTIONS LIST FILTERS MENU':
            if(payload.element){
                return {
                    ...state,
                    clearanceQuestionsListFiltersMenuAnchorEl: payload.element,
                    clearanceQuestionsListFiltersMenuOpen: true,
                    clearanceQuestionsListFiltersMenuOptionId: payload.optionId
                };
            }
            return {
                ...state,
                clearanceQuestionsListFiltersMenuOpen: false,
            };
        default: return state;
    }
};

const ClearanceProvider = ({children}) => {
    const activeUser = useAppCtxActiveUser();

    const getActiveUserIsOperator = () => {
        let activeUserIsOperator = false;
        if(activeUser){
            if(activeUser.type > 1) activeUserIsOperator = true;
        }
        return activeUserIsOperator;
    }

    const defaultClearanceQuestionsListFilters = {
        createdBy: 'all',
        folder: 'all',
        folderGroup: 'all',
        rightType: 'all',
        riskLevel: 'all',
        status: getActiveUserIsOperator() ? 'pending' : 'all',
        search: ''
    };
    
    const defaultState = {
        clearanceCtxActiveClient: null,
        clearanceQuestionsListFilters: defaultClearanceQuestionsListFilters,
        clearanceQuestionsListFiltersMenuAnchorEl: null,
        clearanceQuestionsListFiltersMenuOpen: false,
        clearanceQuestionsListFiltersMenuOptionId: '',
        clearanceQuestionsListFiltersMenuOptions: {
            createdBy: [{value: 'all', label: 'Todas'}],
            folder: [{value: 'all', label: 'Todos'}],
            folderGroup: [{value: 'all', label: 'Todos'}],
            rightType: [{value: 'all', label: 'Todos'}],
            riskLevel: [
                {value: 'all', label: 'Todos'},
                ...CLEARANCE_RISK_OPTIONS.map(option => ({...option, description: option.label, label: <span><span style={{color: option.color}}>&#11044;</span>&nbsp;{option.label}</span>}))
            ],
            status: [
                {value: 'pending', label: 'Em aberto'},
                {value: 'done', label: 'Respondidas'},
                {value: 'all', label: 'Todas'}
            ]
        },
    };

    const [state, dispatch] = useReducer(reducer, {...defaultState});

    const getClearanceQuestionsListFiltersMenuOptions = ({
        numberOfApprovalsRequired,
        selectedFolder,
        selectedFolderUsers,
        selectedTemplate,
        currentClearanceFolderOptions
    }) => {
        if(numberOfApprovalsRequired){
            let approvalsRequired = false;
            for(const key in numberOfApprovalsRequired){
                if(numberOfApprovalsRequired[key] > 0) approvalsRequired = true;
            }
            if(approvalsRequired){
                setState('clearanceQuestionsListFiltersMenuOptions', (prevState) => ({
                    ...prevState,
                    status: [
                        {value: 'pending', label: 'Em aberto'},
                        {value: 'done', label: 'Respondidas'},
                        {value: 'approved', description: 'Riscos aprovados', label: <Grid container spacing={1} alignItems="center"><Grid item xs><Typography variant="body1">Riscos aprovados</Typography></Grid><Grid item><ThumbUpIcon /></Grid></Grid>},
                        {value: 'rejected', description: 'Riscos reprovados', label: <Grid container spacing={1} alignItems="center"><Grid item xs><Typography variant="body1">Riscos reprovados</Typography></Grid><Grid item><ThumbDownIcon /></Grid></Grid>},
                        {value: 'all', label: 'Todas'}
                    ]
                }));
            }
        }

        if(selectedFolder){
            if(selectedFolder.useGroups){
                let groupOptions = [];
                if(activeUser){
                    const folderGroups = selectedFolder.groups || [];
                    const noGroupIds = ['&all', '&none', '~any;'];
                    // const group_any = { value:'~any;', label:'Sem grupo (disponível para todos)' };
                    const activeUserInAdminGroup = folderGroups.find(folderGroup => folderGroup.id === '2' && folderGroup.users.includes(activeUser._id));
                    if(activeUserInAdminGroup){
                        folderGroups.forEach(group => {
                            if(!noGroupIds.includes(group.id)) groupOptions.push({value: group.id, label: group.name});
                        });
                    } else {
                        const activeUserInManagementGroup = folderGroups.find(folderGroup => folderGroup.id === '1' && folderGroup.users.includes(activeUser._id));
                        if(activeUserInManagementGroup){
                            folderGroups.forEach(group => {
                                if(!noGroupIds.includes(group.id) && group.id !== '2') groupOptions.push({value: group.id, label: group.name});
                            });
                        } else {
                            folderGroups.forEach(group => {
                                if(!noGroupIds.includes(group.id) && group.users.includes(activeUser._id)) groupOptions.push({value: group.id, label: group.name});
                            });
                        }
                    }
                    // groups.unshift(group_any);
                }
                if(groupOptions.length >= 1){
                    setState('clearanceQuestionsListFiltersMenuOptions', (prevState) => ({
                        ...prevState,
                        folderGroup: [
                            {value: 'all', label: 'Todos'},
                            ...groupOptions
                        ]
                    }));
                }
            }
        }

        if(selectedFolderUsers){
            setState('clearanceQuestionsListFiltersMenuOptions', (prevState) => ({
                ...prevState,
                createdBy: [
                    {value: 'all', label: 'Todas'},
                    ...selectedFolderUsers.map(user => ({value: user._id, label: user.fullName})).sort((a, b) => a.label > b.label ? 1 : (a.label < b.label ? -1 : 0))
                ]
            }));
        }

        if(selectedTemplate){
            setState('clearanceQuestionsListFiltersMenuOptions', (prevState) => ({
                ...prevState,
                rightType: [{value: 'all', label: 'Todos'}, ...selectedTemplate.rightTypes.map(rightType => ({value: rightType._id, label: rightType.name})).sort((a, b) => a.label > b.label ? 1 : (a.label < b.label ? -1 : 0))]
            }));
        }

        if(currentClearanceFolderOptions){
            setState('clearanceQuestionsListFiltersMenuOptions', (prevState) => ({
                ...prevState,
                folder: [
                    {value: 'all', label: 'Todas'},
                    ...currentClearanceFolderOptions.map(option => ({value: option._id, label: option.name}))
                ]
            }));
        }
    };

    const resetClearanceQuestionsListFilters = () => {
        setState('clearanceQuestionsListFilters', defaultClearanceQuestionsListFilters);
    };

    const setState = (key, newValue) => {
        dispatch({type: 'SET STATE', payload: { key, value: newValue }});
    };

    const sortClearanceReportItems = (a, b) => {};

    const api = useMemo(() => {

        const setClearanceQuestionsListFilters = (newValue) => {
            setState('clearanceQuestionsListFilters', newValue);
        }

        const showClearanceQuestionsListFiltersMenu = (element, optionId) => {
            dispatch({type: 'SHOW CLEARANCE QUESTIONS LIST FILTERS MENU', payload: { element, optionId }});
        };

        return {
            dispatch,

            getClearanceQuestionsListFiltersMenuOptions,
            resetClearanceQuestionsListFilters,
            setClearanceQuestionsListFilters,
            setState,
            showClearanceQuestionsListFiltersMenu,
        };
    }, []);

    const clearanceCtxClearanceQuestionsListFiltersMenuValue = useMemo(() => ({
        clearanceQuestionsListFiltersMenuAnchorEl: state.clearanceQuestionsListFiltersMenuAnchorEl,
        clearanceQuestionsListFiltersMenuOpen: state.clearanceQuestionsListFiltersMenuOpen,
        clearanceQuestionsListFiltersMenuOptionId: state.clearanceQuestionsListFiltersMenuOptionId
    }), [state.clearanceQuestionsListFiltersMenuOpen]);

    return (
        <ClearanceCtxAPI.Provider value={api}>

        <ClearanceCtxActiveClient.Provider value={state.clearanceCtxActiveClient}>
        <ClearanceCtxClearanceQuestionsListFilters.Provider value={state.clearanceQuestionsListFilters}>
        <ClearanceCtxClearanceQuestionsListFiltersMenu.Provider value={clearanceCtxClearanceQuestionsListFiltersMenuValue}>
        <ClearanceCtxClearanceQuestionsListFiltersMenuOptions.Provider value={state.clearanceQuestionsListFiltersMenuOptions}>
            {children}
        </ClearanceCtxClearanceQuestionsListFiltersMenuOptions.Provider>
        </ClearanceCtxClearanceQuestionsListFiltersMenu.Provider>
        </ClearanceCtxClearanceQuestionsListFilters.Provider>
        </ClearanceCtxActiveClient.Provider>

        </ClearanceCtxAPI.Provider>
    );
};

const useClearanceCtxAPI = () => useContext(ClearanceCtxAPI);

const useClearanceCtxActiveClient = () => useContext(ClearanceCtxActiveClient);
const useClearanceCtxClearanceQuestionsListFilters = () => useContext(ClearanceCtxClearanceQuestionsListFilters);
const useClearanceCtxClearanceQuestionsListFiltersMenu = () => useContext(ClearanceCtxClearanceQuestionsListFiltersMenu);
const useClearanceCtxClearanceQuestionsListFiltersMenuOptions = () => useContext(ClearanceCtxClearanceQuestionsListFiltersMenuOptions);

export {
    ClearanceProvider,
    
    useClearanceCtxAPI,

    useClearanceCtxActiveClient,
    useClearanceCtxClearanceQuestionsListFilters,
    useClearanceCtxClearanceQuestionsListFiltersMenu,
    useClearanceCtxClearanceQuestionsListFiltersMenuOptions,
};