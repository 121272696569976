import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Divider, Grid, List, ListItem, Typography } from '@mui/material';
import PopOver from './PopOver';
import { useDocumentsAPI, useDocumentsCtxDocumentAlertsView } from '../context/DocumentsContext';

const DocumentAlertsPopOver = ({availableDocumentAlerts}) => {
    const { hideDocumentAlertsView } = useDocumentsAPI();
    const { documentAlertsViewAnchorPosition, documentAlertsViewOpen, documentAlertsViewSelectedDocument: selectedDocument } = useDocumentsCtxDocumentAlertsView();
    const [documentAlerts, setDocumentAlerts] = useState([]);

    const setDocumentAlertsAction = () => {
        if(documentAlertsViewOpen && selectedDocument){
            const currentDocumentAlerts = availableDocumentAlerts.filter(availableDocumentAlert => availableDocumentAlert.documentId === selectedDocument._id);
            return setDocumentAlerts(currentDocumentAlerts);
        }
        setDocumentAlerts([]);
    };
    
    useEffect(() => {
        setDocumentAlertsAction();
    }, [documentAlertsViewOpen, selectedDocument]);

    const handleClose = () => {
        hideDocumentAlertsView();
    };
    
    return (
        <PopOver
            open={documentAlertsViewOpen} onClose={handleClose} anchorPosition={documentAlertsViewAnchorPosition}
            ellipsisTitle={`${selectedDocument ? `${selectedDocument.name} | ` : ''}Alertas`}
        >
            <List dense>
                {
                    documentAlerts.filter(documentAlert => !documentAlert.sent).map((alert, index) => (
                        <React.Fragment key={index}>
                            <ListItem>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item>
                                        <Typography variant="h6">{moment(alert.alert).format('L')}</Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="body1">{alert.description}</Typography>
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <Divider component="li" />
                        </React.Fragment>
                    ))
                }
            </List>
        </PopOver>
    );
};

export default DocumentAlertsPopOver;