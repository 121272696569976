import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import NewEffectsTypes from '../../../../../../documents/templates/new-effect-components/NewEffectsTypes';
import Select from '../../../../../../components/Select';
import Window from '../../../../../../components/Window';
import { formEffectTypes } from '../../../../../../utils/constants';
import { sortByKey } from '../../../../../../utils/filters';
import { useAppContext } from '../../../../../../context/SystemContext';
import { useClientCtxActiveClient } from '../../../../../../context/ClientContext';
import { useOperatorTemplatesCtxConstants, useOperatorTemplatesCtxTemplateEffects } from '../../context/TemplatesContext';
import { useDocumentEditor } from '../context/DocumentEditorContext';

const NewEffect = ({open, setOpen}) => {
    const { formEffects } = useAppContext();
    const activeClient = useClientCtxActiveClient();
    const { specialEffects, userCreatedFieldsBooleanOptions } = useOperatorTemplatesCtxConstants();
    const templateEffects = useOperatorTemplatesCtxTemplateEffects();
    const { createEffect } = useDocumentEditor();
    const [listOptions, set_listOptions] = useState([]);
    const defaultNewEffect = {
        id: '',
        type: 'text',
        question: '',
        label: ''
    };
    const [newEffect, setNewEffect] = useState({...defaultNewEffect});
    const [isDisabled, setDisabled] = useState(false);

    useEffect(() => {
        if(open){
            setNewEffect(defaultNewEffect);
        }
    }, [open]);

    useEffect(() => {
        if(activeClient){
            let currentLists = activeClient.lists;
            currentLists = currentLists.map(list => ({value: list.shortName, label: list.name})).sort(sortByKey('label'));
            set_listOptions(currentLists);
        }
    }, [activeClient]);

    const handleEffectTypeChange = (newValue) => {
        const data = formEffects[newValue].newEffectWindowHandleEffectTypeChange({listOptions});
        setNewEffect({type: newValue, ...data});
    };
    
    const handleQuestionKeyPress = (e) => {
        const pressedKey = e.key;
        if(pressedKey === 'Enter'){
            if(['text', 'date'].includes(newEffect.type) && newEffect.question){
                createEffect(newEffect, handleClose);
            }
        }
    }

    const handleChange = (newValue) => {
        setNewEffect(prevState => ({...prevState, ...newValue}));
    };

    const handleClose = () => {
        setOpen(false);
    };
 
    return (
        <Window
            open={open} onClose={handleClose}
            submitButtonDisabled={isDisabled}
            submitButtonText="Criar efeito"
            handleSubmitButtonClick={() => createEffect(newEffect, handleClose)}
        >
            <Box mb={2}>
                <Box mb={1}>
                    <Typography variant="h6">Tipo</Typography>
                </Box>
                <Select shrink autoFocus options={formEffectTypes} value={newEffect.type || 'select'} onChange={(e) => handleEffectTypeChange(e.target.value)} />
            </Box>
            <NewEffectsTypes
                type={newEffect.type}
                handleChange={handleChange}
                handleQuestionKeyPress={handleQuestionKeyPress}
                setDisabled={setDisabled}
                ifTrueOptions={userCreatedFieldsBooleanOptions} listOptions={listOptions} specialEffects={specialEffects} templateEffects={templateEffects}
            />
        </Window>
    );
};

export default NewEffect;