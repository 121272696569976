import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const StyledAutocomplete = ({
    disabled, error, freeSolo, getOptionLabel, id, inputValue, label,
    onBlur, onChange, onInputChange, onKeyPress, options, placeholder, ref,
    required, value
}) => {
    return (
        <Autocomplete
            disabled={disabled}
            ref={ref}
            inputValue={inputValue}
            onInputChange={onInputChange}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            id={id}
            freeSolo={freeSolo}
            fullWidth
            options={options}
            onKeyPress={onKeyPress}
            getOptionLabel={getOptionLabel}
            renderInput={(params) => (
                <TextField {...params} label={label} placeholder={placeholder} required={required} error={error} variant="standard" margin="none" />
            )}
            renderOption={(props, option, { inputValue }) => {
                const optionLabel = getOptionLabel(option);
                const matches = match(optionLabel, inputValue, { insideWords: true });
                const parts = parse(optionLabel, matches);
                return (
                    <li {...props}>
                        <span>
                            {parts.map((part, index) => (
                                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                    {part.text}
                                </span>
                            ))}
                        </span>
                    </li>
                );
            }}
        />
    );
}

export default StyledAutocomplete;