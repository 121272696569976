import { Box, Grid, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const NoResults = ({text}) => (
    <Box mb={2}>
        <Grid container spacing={1} alignItems="center">
            <Grid item>
                <ClearIcon />
            </Grid>
            <Grid item>
                <Typography variant="body1">{text}</Typography>
            </Grid>
        </Grid>
    </Box>
);

export default NoResults;