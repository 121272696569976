import { createContext, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';
import MessagesWindow from '../../../components/Messages/MessagesWindow';
import { serverRequest } from '../../../utils/common';
import { APP_USER_ID, SERVER_PATH } from '../../../utils/constants';
import { useAppCtxAPI, useAppCtxActiveUser } from '../../../context/SystemContext';
import { useSocket, useSocketCtxConnectedAt } from '../../../context/SocketContext';
import { useClientCtxActiveClient } from '../../../context/ClientContext';
import { useDocumentsAPI, useDocumentsCtxTemplates } from '../../../context/DocumentsContext';
import { sortByKey } from '../../../utils/filters';
import axios from 'axios';
import moment from 'moment';
import queryString from 'query-string';

const OperatorCtxAPI = createContext();
const OperatorCtxChat = createContext();
const OperatorCtxChatMessages = createContext();
const OperatorCtxCompanies = createContext();
const OperatorCtxCounters = createContext();
const OperatorCtxFavoriteProjects = createContext();
const OperatorCtxFolders = createContext();
const OperatorCtxLawsuits = createContext();
const OperatorCtxINPIItems = createContext();
const OperatorCtxINPIProgressItems = createContext();
const OperatorCtxMenuItems = createContext();
const OperatorCtxMobileSidenav = createContext();
const OperatorCtxOperators = createContext();
const OperatorCtxProjects = createContext();
const OperatorCtxRefs = createContext();
const OperatorCtxSidenavMobile = createContext();
const OperatorCtxTimesheet = createContext();
const OperatorCtxUsers = createContext();

const newChatMessageEventName = 'NEW CHAT MESSAGE';

const defaultState = {
    companies: null,
    favoriteFolders: [],
    folders: null,
    hideTimesheetTop: false,
    hoursCount: null,
    inpiPendingProgress: [],
    inpiRecords: [],
    lawsuits: null,
    lawsuitsCount: null,
    messages: [],
    messagesWindowOpen: false,
    mobileSidenavDocumentsMenuOpen: false,
    mobileSidenavFoldersMenuOpen: false,
    mobileSidenavProcessosMenuOpen: false,
    mobileSidenavTasksMenuOpen: false,
    numberOfUnreadMessages: 0,
    operators: [],
    pendingClearanceCount: null,
    projects: null,
    questionsCount: null,
    sidenavMobileOpen: false,
    tasksCount: null,
    timesheet: [],
    timesheetPanelOpen: false,
    timesheetSelectedDate: moment(),
    timesheetSelectedUser: '',
    users: null,
};

const reducer = (state, action) => {
    const { payload, type } = action;

    switch (type) {
        case 'ADD CHAT MESSAGE':
            return { ...state, messages: [...state.messages, payload] };
        case 'ADD INPI ITEM':
            return { ...state, inpiRecords: [...state.inpiRecords, payload] };
        case 'ADD LAWSUIT':
            return { ...state, lawsuits: [...state.lawsuits, payload] };
        case 'ADD PROJECT':
            return { ...state, projects: [...state.projects, payload] };
        case 'ADD TIMESHEET ITEM':
            return { ...state, timesheet: [...state.timesheet, payload] };
        case 'ADD USER':
            return { ...state, users: [...state.users, payload] };
        case 'DELETE CHAT MESSAGES':
            const deletedChatMessageIndex = state.messages.findIndex(item => item._id === payload);
            if(deletedChatMessageIndex !== -1){
                return { ...state, messages: [...state.messages.slice(0, deletedChatMessageIndex), ...state.messages.slice(deletedChatMessageIndex + 1)] };
            }
            return state;
        case 'DELETE INPI ITEM':
            const deletedINPIItemIndex = state.inpiRecords.findIndex(item => item._id === payload);
            if(deletedINPIItemIndex !== -1){
                return { ...state, inpiRecords: [...state.inpiRecords.slice(0, deletedINPIItemIndex), ...state.inpiRecords.slice(deletedINPIItemIndex + 1)] };
            }
            return state;
        case 'DELETE INPI PROGRESS ITEM':
            const deletedINPIProgressItemIndex = state.inpiPendingProgress.findIndex(item => item._id === payload);
            if(deletedINPIProgressItemIndex !== -1){
                return { ...state, inpiPendingProgress: [...state.inpiPendingProgress.slice(0, deletedINPIProgressItemIndex), ...state.inpiPendingProgress.slice(deletedINPIProgressItemIndex + 1)] };
            }
            return state;
        case 'DELETE LAWSUIT':
            const deletedLawsuitIndex = state.lawsuits.findIndex(item => item._id === payload);
            if(deletedLawsuitIndex !== -1){
                return { ...state, lawsuits: [...state.lawsuits.slice(0, deletedLawsuitIndex), ...state.lawsuits.slice(deletedLawsuitIndex + 1)] };
            }
            return state;
        case 'DELETE TIMESHEET ITEM':
            const deletedTimesheetItemIndex = state.timesheet.findIndex(item => item._id === payload);
            if(deletedTimesheetItemIndex !== -1){
                return { ...state, timesheet: [...state.timesheet.slice(0, deletedTimesheetItemIndex), ...state.timesheet.slice(deletedTimesheetItemIndex + 1)] };
            }
            return state;
        case 'INCREMENT UNREAD MESSAGES':
            return { ...state, numberOfUnreadMessages: state.numberOfUnreadMessages + 1 };
        case 'SET STATE':
            return { ...state, [payload.key]: payload.value };
        case 'UPDATE CHAT MESSAGE':
            const chatMessageIndex = state.messages.findIndex(item => item._id === (payload.itemId || payload.newValue._id));
            if(chatMessageIndex !== -1){
                return { ...state, messages: [...state.messages.slice(0, chatMessageIndex), {...state.messages[chatMessageIndex], ...payload.newValue, updatedAt: new Date()}, ...state.messages.slice(chatMessageIndex + 1)] };
            }
            return state;
        case 'UPDATE INPI PROGRESS ITEM':
            const INPIItemProgressIndex = state.inpiPendingProgress.findIndex(item => item._id === (payload.itemId || payload.newValue._id));
            if(INPIItemProgressIndex !== -1){
                return { ...state, inpiPendingProgress: [...state.inpiPendingProgress.slice(0, INPIItemProgressIndex), {...state.inpiPendingProgress[INPIItemProgressIndex], ...payload.newValue, updatedAt: new Date()}, ...state.inpiPendingProgress.slice(INPIItemProgressIndex + 1)] };
            }
            return state;
        case 'UPDATE INPI ITEM':
            const INPIItemIndex = state.inpiRecords.findIndex(item => item._id === (payload.itemId || payload.newValue._id));
            if(INPIItemIndex !== -1){
                return { ...state, inpiRecords: [...state.inpiRecords.slice(0, INPIItemIndex), {...state.inpiRecords[INPIItemIndex], ...payload.newValue, updatedAt: new Date()}, ...state.inpiRecords.slice(INPIItemIndex + 1)] };
            }
            return state;
        case 'UPDATE LAWSUIT':
            const lawsuitIndex = state.lawsuits.findIndex(item => item._id === (payload.itemId || payload.newValue._id));
            if(lawsuitIndex !== -1){
                return { ...state, lawsuits: [...state.lawsuits.slice(0, lawsuitIndex), {...state.lawsuits[lawsuitIndex], ...payload.newValue, updatedAt: new Date()}, ...state.lawsuits.slice(lawsuitIndex + 1)] };
            }
            return state;
        case 'UPDATE PROJECT':
            const projectIndex = state.projects.findIndex(item => item._id === payload._id);
            if(projectIndex !== -1){
                return { ...state, projects: [...state.projects.slice(0, projectIndex), {...state.projects[projectIndex], ...payload, updatedAt: new Date()}, ...state.projects.slice(projectIndex + 1)] };
            }
            return state;
        case 'UPDATE TIMESHEET ITEM':
            const timesheetItemIndex = state.timesheet.findIndex(item => item._id === payload._id);
            if(timesheetItemIndex !== -1){
                return { ...state, timesheet: [...state.timesheet.slice(0, timesheetItemIndex), {...state.timesheet[timesheetItemIndex], ...payload, updatedAt: new Date()}, ...state.timesheet.slice(timesheetItemIndex + 1)] };
            }
            return state;
        case 'UPDATE USER':
            const userIndex = state.users.findIndex(item => item._id === (payload.itemId || payload.newValue._id));
            if(userIndex !== -1){
                return { ...state, users: [...state.users.slice(0, userIndex), {...state.users[userIndex], ...payload.newValue, updatedAt: new Date()}, ...state.users.slice(userIndex + 1)] };
            }
            return state;
        default: return state;
    }
    
};

const classes = {
    content: {
        height: '100%',
        width: '100%',
    },
};

const Div = styled('div')(unstable_styleFunctionSx);

const OperatorProvider = ({children}) => {
    const location = useLocation();
    const { setLoadingSystem, setTemplateLists, setUserLocation } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const activeClient = useClientCtxActiveClient();
    const { dispatch: dispatchDocuments } = useDocumentsAPI();
    const templates = useDocumentsCtxTemplates();
    const { createSocketConnection } = useSocket();
    const socket = createSocketConnection();
    const socketConnectedAt = useSocketCtxConnectedAt();

    const [state, dispatch] = useReducer(reducer, {...defaultState});
    const api = useMemo(() => {

        const addChatMessage = (payload) => {
            dispatch({ type: 'ADD CHAT MESSAGE', payload });
        };
        
        const addINPIItem = (payload) => {
            dispatch({ type: 'ADD INPI ITEM', payload });
        };

        const addLawsuit = (payload) => {
            dispatch({ type: 'ADD LAWSUIT', payload });
        };
        
        const addProject = (payload) => {
            dispatch({ type: 'ADD PROJECT', payload });
        };

        const addTimesheetItem = (payload) => {
            dispatch({ type: 'ADD TIMESHEET ITEM', payload });
        };

        const addUser = (payload) => {
            dispatch({ type: 'ADD USER', payload });
        };

        const closeTimesheetPanel = () => {
            dispatch({ type: 'SET STATE', payload: { key: 'timesheetPanelOpen', value: false } });
            setTimeout(() => dispatch({ type: 'SET STATE', payload: { key: 'hideTimesheetTop', value: false } }), 400);
        };

        const deleteChatMessage = (payload) => {
            dispatch({ type: 'DELETE CHAT MESSAGE', payload });
        };

        const deleteINPIItem = (payload) => {
            dispatch({ type: 'DELETE INPI ITEM', payload });
        };

        const deleteINPIProgressItem = (payload) => {
            dispatch({ type: 'DELETE INPI PROGRESS ITEM', payload });
        };

        const deleteLawsuit = (payload) => {
            dispatch({ type: 'DELETE LAWSUIT', payload });
        };

        const deleteTimesheetItem = (payload) => {
            dispatch({ type: 'DELETE TIMESHEET ITEM', payload });
        };

        const setState = (key, value) => {
            dispatch({ type: 'SET STATE', payload: { key, value } });
        };

        const updateChatMessage = (newValue, itemId) => {
            dispatch({ type: 'UPDATE CHAT MESSAGE', payload: { newValue, itemId } });
        };

        const updateINPIItem = (newValue, itemId) => {
            dispatch({ type: 'UPDATE INPI ITEM', payload: { newValue, itemId } });
        };

        const updateINPIProgressItem = (newValue, itemId) => {
            dispatch({ type: 'UPDATE INPI PROGRESS ITEM', payload: { newValue, itemId } });
        };

        const updateLawsuit = (newValue, itemId) => {
            dispatch({ type: 'UPDATE LAWSUIT', payload: { newValue, itemId } });
        };

        const updateProject = (payload) => {
            dispatch({ type: 'UPDATE PROJECT', payload });
        };

        const updateTimesheetItem = (payload) => {
            dispatch({ type: 'UPDATE TIMESHEET ITEM', payload });
        };

        const updateUser = (newValue, itemId) => {
            dispatch({ type: 'UPDATE USER', payload: { newValue, itemId } });
        };

        return {
            dispatch,
            addChatMessage,
            addINPIItem,
            addLawsuit,
            addProject,
            addTimesheetItem,
            addUser,
            closeTimesheetPanel,
            deleteChatMessage,
            deleteINPIItem,
            deleteINPIProgressItem,
            deleteLawsuit,
            deleteTimesheetItem,
            setState,
            updateChatMessage,
            updateINPIItem,
            updateINPIProgressItem,
            updateLawsuit,
            updateProject,
            updateTimesheetItem,
            updateUser
        };
    }, []);

    const updatedProject = useRef(null);
    const shouldEmitUpdatedProjects = useRef(false);
    const shouldUpdateProjects = useRef(false);
    const updatedUser = useRef(null);
    const shouldEmitUpdatedUsers = useRef(false);
    const shouldUpdateUsers = useRef(false);
    const shouldUpdateOperators = useRef(true);

    const getBreadcrumbs = (pathname) => {

        const getTemplateBreadCrumbs = (breadcrumb) => {
            const breadcrumbs = ['Contratos', 'Matrizes'];
            if(templates){
                const search = queryString.parse(window.location.search);
                const { id: templateId } = search;
                if(templateId){
                    const foundTemplate = templates.find(template => template._id === templateId);
                    if(foundTemplate) breadcrumbs.push(foundTemplate.name);
                }
            }
            breadcrumbs.push(breadcrumb);
            return breadcrumbs;
        }

        const pathToBreadcrumbs = {
            'juridico/admin': () => ['Sistema'],
            'juridico/atendimento': () => ['Painel de projetos'],
            'juridico/clearance/consultas': () => ['Clearance', 'Consultas'],
            'juridico/clearance/matrizes': () => ['Clearance', 'Matrizes'],
            'juridico/clearance/relatorios': () => ['Clearance', 'Relatórios'],
            'juridico/demandas/geral': () => ['Tarefas', 'Minhas tarefas'],
            'juridico/documentos/alertas': () => ['Contratos', 'Alertas'],
            'juridico/documentos/formulario': () => ['Contratos', 'Formulário'],
            'juridico/documentos/listas': () => ['Contratos', 'Listas para matrizes'],
            'juridico/documentos/matrizes': () => ['Contratos', 'Matrizes'],
            'juridico/documentos/pendentes': () => ['Contratos', 'Contratos em aberto'],
            'juridico/documentos/pesquisar': () => ['Contratos', 'Todos'],
            'juridico/documentos/matrizes/documento': () => getTemplateBreadCrumbs('Documento'),
            'juridico/documentos/matrizes/formulario': () => getTemplateBreadCrumbs('Formulário'),
            'juridico/documentos/matrizes/listas': () => ['Contratos', 'Matrizes', 'Conjuntos'],
            'juridico/documentos/matrizes/nova': () => ['Contratos', 'Matrizes', 'Nova matriz'],
            'juridico/documentos/matrizes/titulo': () => getTemplateBreadCrumbs('Padrão de título'),
            'juridico/financeiro/areas': () => ['Financeiro', 'Áreas'],
            'juridico/financeiro/composicao': () => ['Financeiro', 'Composição financeira'],
            'juridico/financeiro/despesas': () => ['Financeiro', 'Despesas'],
            'juridico/financeiro/estados': () => ['Financeiro', 'Estados'],
            'juridico/financeiro/lucros': () => ['Financeiro', 'Distribuição de lucros'],
            'juridico/financeiro/nova-proposta': () => ['Financeiro', 'Nova proposta'],
            'juridico/financeiro/planejamento-mensal': () => ['Financeiro', 'Planejamento mensal'],
            'juridico/financeiro/propostas': () => ['Financeiro', 'Propostas'],
            'juridico/financeiro/ranking': () => ['Financeiro', 'Ranking'],
            'juridico/financeiro/recebimentos': () => ['Financeiro', 'Recebimentos'],
            'juridico/preferencias': () => ['Preferências'],
            'juridico/processos/administrativos/acompanhamento': () => ['Processos administrativos', 'Acompanhamento'],
            'juridico/processos/administrativos/andamentos': () => ['Processos administrativos', 'Andamentos'],
            'juridico/processos/administrativos/consultar': () => ['Processos administrativos', 'Consultar processo'],
            'juridico/processos/administrativos/todos': () => ['Processos administrativos', 'Lista de processos'],
            'juridico/processos/inpi/acompanhamento': () => ['INPI', 'Acompanhamento'],
            'juridico/processos/inpi/andamentos': () => ['INPI', 'Andamentos'],
            'juridico/processos/inpi/todos': () => ['INPI', 'Lista de processos'],
            'juridico/processos/judicial/acompanhamento': () => ['Ações judiciais', 'Acompanhamento'],
            'juridico/processos/judicial/andamentos': () => ['Ações judiciais', 'Andamentos'],
            'juridico/processos/judicial/consultar': () => ['Ações judiciais', 'Consultar processo'],
            'juridico/processos/judicial/todos': () => ['Ações judiciais', 'Lista de processos'],
            'juridico/pessoas': () => ['Pessoas'],
            'juridico/projetos/clientes': () => ['Projetos', 'Áreas de trabalho'],
            'juridico/projetos/empresas': () => ['Projetos', 'Empresas'],
            'juridico/projetos/informacoes-especiais': () => ['Projetos', 'Informações especiais'],
            'juridico/projetos/pastas': () => ['Projetos', 'Pastas'],
            'juridico/relatorios/advogados': () => ['Relatórios', 'Advogado'],
            'juridico/relatorios/clientes': () => ['Relatórios', 'Pastas'],
            'juridico/relatorios/empresa': () => ['Relatórios', 'Empresa'],
            'juridico/relatorios/equipe': () => ['Relatórios', 'Equipe'],
            'juridico/salas': () => ['Salas'],
            'juridico/tarefas/equipe': () => ['Tarefas', 'Equipe'],
            'juridico/timesheet': () => ['Timesheet'],
        };
        const path = pathname.replace(/^\/\w+?\//, '');
        let breadcrumbs;
        if(pathToBreadcrumbs[path]) breadcrumbs = pathToBreadcrumbs[path]();
        return breadcrumbs || [];
    }
    
    useEffect(() => {
        const pathname = location.pathname;
        const breadcrumbs = getBreadcrumbs(pathname);
        setUserLocation({ breadcrumbs, path: pathname });
    }, [location, templates]);

    const checkIfUserIsOperator = (user) => {
        const userClients = user.clients;
        const permissions = userClients.find(client => client.id === activeClient._id);
        if(permissions){
            if(permissions.type >= 4 && !user.disabled){
                return true;
            }
        }
        return false;
    };

    const checkIfShouldUpdateOperators = () => {
        // update operators if no updated user or if user user is operator
        if(!updatedUser.current){
            shouldUpdateOperators.current = true;
        } else {
            shouldUpdateOperators.current = checkIfUserIsOperator(updatedUser.current);
        }
    };

    useEffect(() => {
        if(activeUser && activeClient && state.users && state.projects && state.lawsuitsCount !== null){
            setTimeout(() => {
                setLoadingSystem(false);    
            }, 2000);
        }
    }, [activeUser, activeClient, state.users, state.projects, state.lawsuitsCount]);

    useEffect(() => {
        const keyPress = (e) => {
            if(e.ctrlKey && e.key === '2'){
                e.preventDefault();
                dispatch({ type: 'SET STATE', payload: { key: 'messagesWindowOpen', value: true } });
            }
        };
        document.addEventListener('keydown', keyPress);
        return () => {
            document.removeEventListener('keydown', keyPress);
        };
    }, []);

    useEffect(() => {
        let isMounted = true;
        if(activeClient){
            axios.get(SERVER_PATH + '/data/operator/start')
            .then(res => {
                if(isMounted){
                    dispatch({ type: 'SET STATE', payload: { key: 'projects', value: res.data.projects } });
                    shouldUpdateProjects.current = true;
                    dispatch({ type: 'SET STATE', payload: { key: 'users', value: res.data.users } });
                    shouldUpdateUsers.current = true;
                    dispatchDocuments({type: 'SET PENDING DOCUMENTS COUNT', payload: res.data.pendingDocumentsCount || 0});
                    if(res.data.pendingClearanceCount){
                        dispatch({ type: 'SET STATE', payload: { key: 'pendingClearanceCount', value: res.data.pendingClearanceCount } });
                        if(socket) socket.emit('NUMBER OF CLEARANCE QUESTIONS UPDATED', {count: res.data.pendingClearanceCount, clientId: activeClient._id});
                    }
                    dispatch({ type: 'SET STATE', payload: { key: 'questionsCount', value: res.data.pendingQuestionsCount } });
                    dispatch({ type: 'SET STATE', payload: { key: 'lawsuitsCount', value: res.data.lawsuitsCount } });
                }
            })
            .catch(err => {
                console.log(err);
            });
        }
        return () => { isMounted = false };
    }, [activeClient]);

    useEffect(() => {
        let isMounted = true;
        axios.get(SERVER_PATH + '/data/operator/chat')
        .then(res => {
            if(isMounted){
                let currentMessags = res.data.messages;
                currentMessags.reverse();
                dispatch({ type: 'SET STATE', payload: { key: 'messages', value: currentMessags } });
            }
        })
        .catch(err => {
            console.log(err);
        });
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        const numberOfClearanceQuestionsUpdatedSocketEventName = 'NUMBER OF CLEARANCE QUESTIONS UPDATED';
        const numberOfPendingDocumentsUpdatedSocketEventName = 'NUMBER OF PENDING DOCUMENTS UPDATED';

        function onGotChatMessageEvent(message){
            const newMessage = message.body;
            dispatch({ type: 'ADD CHAT MESSAGE', payload: newMessage });
            dispatch({ type: 'INCREMENT UNREAD MESSAGES' });
        }

        function onNumberOfClearanceQuestionsUpdated(data){
            if(data && data.count) dispatch({ type: 'SET STATE', payload: { key: 'pendingClearanceCount', value: data.count } });
        }

        function onNumberOfPendingDocumentsUpdated(data){
            if(data && data.count) dispatchDocuments({type: 'SET PENDING DOCUMENTS COUNT', payload: data.count});
        }

        socket.on(newChatMessageEventName, onGotChatMessageEvent);
        socket.on(numberOfPendingDocumentsUpdatedSocketEventName, onNumberOfPendingDocumentsUpdated);
        socket.on(numberOfClearanceQuestionsUpdatedSocketEventName, onNumberOfClearanceQuestionsUpdated);
        return () => {
            socket.off(newChatMessageEventName, onGotChatMessageEvent);
            socket.off(numberOfPendingDocumentsUpdatedSocketEventName, onNumberOfPendingDocumentsUpdated);
            socket.off(numberOfClearanceQuestionsUpdatedSocketEventName, onNumberOfClearanceQuestionsUpdated);
        };
    }, []);

    useEffect(() => {
        let lists = [];
        if(activeClient){
            lists = activeClient.templateLists || [];
        }
        setTemplateLists(lists);
    }, [activeClient]);

    useEffect(() => {
        if(state.projects){
            const currentCompanies = [];
            const currentFolders = [];
            state.projects.forEach(project => {
                if(project.client){
                    currentFolders.push(project);
                } else {
                    currentCompanies.push(project);
                }
            });
            dispatch({ type: 'SET STATE', payload: { key: 'companies', value: currentCompanies.sort(sortByKey('name')) } });
            dispatch({ type: 'SET STATE', payload: { key: 'folders', value: currentFolders.sort(sortByKey('name')) } });
        }
    }, [state.projects]);

    useEffect(() => {
        if(state.users){
            if(shouldEmitUpdatedUsers.current){
                socket.emit('USERS UPDATED', { activeClientId: activeClient._id, users: state.users, updatedUser: updatedUser.current });
                updatedUser.current = null;
            }
            shouldEmitUpdatedUsers.current = true;
        }
    }, [state.users]);

    useEffect(() => {
        if(state.projects){
            if(shouldEmitUpdatedProjects.current){
                socket.emit('PROJECTS UPDATED', { activeClientId: activeClient._id, projects: state.projects, updatedProject: updatedProject.current });
                updatedProject.current = null;
            }
            shouldEmitUpdatedProjects.current = true;
        }
    }, [state.projects]);

    useEffect(() => {
        const projectsUpdatedSocketEventName = 'PROJECTS UPDATED';
        const usersUpdatedSocketEventName = 'USERS UPDATED';

        const projectsUpdatedSocketEvent = (data) => {
            shouldEmitUpdatedProjects.current = false;
            updatedProject.current = data.updatedProject;
            dispatch({ type: 'SET STATE', payload: { key: 'projects', value: data.projects } });
        };

        const usersUpdatedSocketEvent = (data) => {
            shouldEmitUpdatedUsers.current = false;
            updatedUser.current = data.updatedUser;
            checkIfShouldUpdateOperators();
            dispatch({ type: 'SET STATE', payload: { key: 'users', value: data.users } });
        };

        socket.on(projectsUpdatedSocketEventName, projectsUpdatedSocketEvent);
        socket.on(usersUpdatedSocketEventName, usersUpdatedSocketEvent);
        return () => {
            socket.off(projectsUpdatedSocketEventName, projectsUpdatedSocketEvent);
            socket.off(usersUpdatedSocketEventName, usersUpdatedSocketEvent);
        };
    }, []);

    useEffect(() => {
        if(state.users && shouldUpdateOperators.current){
            const currentOperators = [];
            state.users.forEach(user => {
                if(user._id === APP_USER_ID) return false;
                const userClients = user.clients;
                const permissions = userClients.find(client => client.id === activeClient._id);
                if(permissions){
                    if(permissions.type >= 4 && !user.disabled){
                        user.type = permissions.type;
                        currentOperators.push(user);
                    }
                }
            });
            const sortedOperators = currentOperators.sort((a, b) => a.fullName > b.fullName ? 1 : (a.fullName < b.fullName ? -1 : 0));
            dispatch({ type: 'SET STATE', payload: { key: 'operators', value: sortedOperators } });
            shouldUpdateOperators.current = false;
        } 
    }, [state.users]);

    useEffect(() => {
        if(activeUser && state.projects){
            let favoriteFolders = [];
            if(activeUser.favoriteProjects){
                for(let projectId of activeUser.favoriteProjects){
                    let folder = state.projects.find(p => p._id === projectId);
                    if(folder) favoriteFolders.push({type: folder.client ? 'projects' : 'clients', id: projectId, name: folder.name});
                }
            }
            favoriteFolders = favoriteFolders.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
            dispatch({ type: 'SET STATE', payload: { key: 'favoriteFolders', value: favoriteFolders } });
        }
    }, [activeUser, state.projects]);

    const fetchUsers = async () => {
        console.log(`Recarregando USUÁRIOS em ${moment().format('L LTS')}`);
        try {
            const data = await serverRequest({path: '/data/operator/users'});
            shouldEmitUpdatedUsers.current = false;
            dispatch({ type: 'SET STATE', payload: { key: 'users', value: data.users } });
        } catch (error) {
        }
    };

    const fetchProjects = async () => {
        console.log(`Recarregando PROJETOS em ${moment().format('L LTS')}`);
        try {
            const data = await serverRequest({path: '/data/projects/list', method: 'post', data: {
                fields: ['accountingClientName', 'client', 'disabled', 'eSignaturePlatform', 'groups', 'info.type', 'info.city', 'info.state', 'modules', 'name', 'projectOperator', 'showDocumentsStarredInformation', 'showFolderUserDocumentsCustomFields', 'useGroups']
            }});
            shouldEmitUpdatedProjects.current = false;
            dispatch({ type: 'SET STATE', payload: { key: 'projects', value: data.projects } });
        } catch (error) {
        }
    };
    
    useEffect(() => {
        if(shouldUpdateUsers.current) fetchUsers();
        if(shouldUpdateProjects.current) fetchProjects();
    }, [socketConnectedAt]);

    return (
        <OperatorCtxAPI.Provider value={api}>
        <OperatorCtxRefs.Provider
            value={{
                checkIfShouldUpdateOperators,
                shouldEmitUpdatedProjects,
                shouldEmitUpdatedUsers,
                shouldUpdateOperators,
                shouldUpdateProjects,
                shouldUpdateUsers,
                updatedProject,
                updatedUser,
            }}
        >
        <OperatorCtxMenuItems.Provider
            value={{}}
            // value={menuItems}
        >
        <OperatorCtxSidenavMobile.Provider
            value={{ sidenavMobileOpen: state.sidenavMobileOpen }}
        >
        <OperatorCtxUsers.Provider
            value={{ users: state.users }}
        >
        <OperatorCtxChat.Provider
            value={{
                messagesWindowOpen: state.messagesWindowOpen,
                newChatMessageEventName,
                numberOfUnreadMessages: state.numberOfUnreadMessages,
            }}
        >
        <OperatorCtxProjects.Provider value={state.projects}>
        <OperatorCtxCompanies.Provider value={state.companies}>
        <OperatorCtxFolders.Provider value={state.folders}>
        <OperatorCtxOperators.Provider value={state.operators}>
        <OperatorCtxTimesheet.Provider
            value={{
                hideTimesheetTop: state.hideTimesheetTop,
                timesheet: state.timesheet,
                timesheetPanelOpen: state.timesheetPanelOpen,
                timesheetSelectedDate: state.timesheetSelectedDate,
                timesheetSelectedUser: state.timesheetSelectedUser,
            }}
        >
        <OperatorCtxLawsuits.Provider value={state.lawsuits}>
        <OperatorCtxINPIItems.Provider value={state.inpiRecords}>
        <OperatorCtxINPIProgressItems.Provider value={state.inpiPendingProgress}>
        <OperatorCtxMobileSidenav.Provider
            value={{
                mobileSidenavDocumentsMenuOpen: state.mobileSidenavDocumentsMenuOpen,
                mobileSidenavFoldersMenuOpen: state.mobileSidenavFoldersMenuOpen,
                mobileSidenavProcessosMenuOpen: state.mobileSidenavProcessosMenuOpen,
                mobileSidenavTasksMenuOpen: state.mobileSidenavTasksMenuOpen
            }}
        >
        <OperatorCtxCounters.Provider
            value={{
                hoursCount: state.hoursCount,
                lawsuitsCount: state.lawsuitsCount,
                pendingClearanceCount: state.pendingClearanceCount,
                questionsCount: state.questionsCount,
                tasksCount: state.tasksCount,
            }}
        >
        <OperatorCtxChatMessages.Provider value={state.messages}>
        <OperatorCtxFavoriteProjects.Provider value={state.favoriteFolders}>

            <Div
                // style={{height: '100%'}}
                // sx={!timesheetPanelOpen ? classes.content : classes.contentShift}
                sx={classes.content}
            >
                {children}
            </Div>

            <MessagesWindow />

        </OperatorCtxFavoriteProjects.Provider>
        </OperatorCtxChatMessages.Provider>
        </OperatorCtxCounters.Provider>
        </OperatorCtxMobileSidenav.Provider>
        </OperatorCtxINPIProgressItems.Provider>
        </OperatorCtxINPIItems.Provider>
        </OperatorCtxLawsuits.Provider>
        </OperatorCtxTimesheet.Provider>
        </OperatorCtxOperators.Provider>
        </OperatorCtxFolders.Provider>
        </OperatorCtxCompanies.Provider>
        </OperatorCtxProjects.Provider>
        </OperatorCtxChat.Provider>
        </OperatorCtxUsers.Provider>
        </OperatorCtxSidenavMobile.Provider>
        </OperatorCtxMenuItems.Provider>
        </OperatorCtxRefs.Provider>
        </OperatorCtxAPI.Provider>
    );
};

const useOperatorCtxAPI = () => useContext(OperatorCtxAPI);
const useOperatorCtxRefs = () => useContext(OperatorCtxRefs);
const useOperatorCtxChat = () => useContext(OperatorCtxChat);
const useOperatorCtxChatMessages = () => useContext(OperatorCtxChatMessages);
const useOperatorCtxCompanies = () => useContext(OperatorCtxCompanies);
const useOperatorCtxCounters = () => useContext(OperatorCtxCounters);
const useOperatorCtxFavoriteProjects = () => useContext(OperatorCtxFavoriteProjects);
const useOperatorCtxFolders = () => useContext(OperatorCtxFolders);
const useOperatorCtxLawsuits = () => useContext(OperatorCtxLawsuits);
const useOperatorCtxINPIItems = () => useContext(OperatorCtxINPIItems);
const useOperatorCtxINPIProgressItems = () => useContext(OperatorCtxINPIProgressItems);
const useOperatorCtxMenuItems = () => useContext(OperatorCtxMenuItems);
const useOperatorCtxMobileSidenav = () => useContext(OperatorCtxMobileSidenav);
const useOperatorCtxOperators = () => useContext(OperatorCtxOperators);
const useOperatorCtxProjects = () => useContext(OperatorCtxProjects);
const useOperatorCtxSidenavMobile = () => useContext(OperatorCtxSidenavMobile);
const useOperatorCtxTimesheet = () => useContext(OperatorCtxTimesheet);
const useOperatorCtxUsers = () => useContext(OperatorCtxUsers);

export {
    OperatorProvider,
    useOperatorCtxAPI,
    useOperatorCtxRefs,
    useOperatorCtxChat,
    useOperatorCtxChatMessages,
    useOperatorCtxCompanies,
    useOperatorCtxCounters,
    useOperatorCtxFavoriteProjects,
    useOperatorCtxFolders,
    useOperatorCtxLawsuits,
    useOperatorCtxINPIItems,
    useOperatorCtxINPIProgressItems,
    useOperatorCtxMenuItems,
    useOperatorCtxMobileSidenav,
    useOperatorCtxOperators,
    useOperatorCtxProjects,
    useOperatorCtxSidenavMobile,
    useOperatorCtxTimesheet,
    useOperatorCtxUsers
};