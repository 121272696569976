import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';

function StyledPagination({count, disabled, page, onChange, my, perPage = 50}){
    const [numberOfPages, set_numberOfPages] = useState(0);

    useEffect(() => {
        let pages = 1;
        if(count){
            pages = Math.ceil(count / perPage);
        }
        set_numberOfPages(pages);
    }, [count]);

    return (
        <Box>
            {
                numberOfPages > 1 &&
                <Box my={my || 2}>
                    <Grid container justifyContent="center">
                        <Pagination disabled={disabled} color="primary" count={numberOfPages} page={page} onChange={onChange} />
                    </Grid>
                </Box>
            }
        </Box>
    );
}

export default StyledPagination;