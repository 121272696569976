import { useEffect, useState } from 'react';
import CurrencyInput from '../pages/Operator/Accounting/components/CurrencyInput';

const CurrencyQuestion = ({initialValue, onChange, question}) => {
    const defaultValue = { value: 0 };
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        setValue(initialValue || defaultValue);
    }, [initialValue]);

    useEffect(() => {
        if(onChange) onChange(question.name, value);
    }, [value]);
    
    const handleChange = (newValue) => {
        setValue(prevState => ({...prevState, value: newValue}));
    };
    
    return (
        <CurrencyInput
            placeholder="Sua resposta"
            value={value.value}
            onChange={(e, newValue) => handleChange(newValue)}
        />
    );
}

export default CurrencyQuestion;