import { useState } from 'react';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import MoreOptionsShownOnMouseOverFab from './MoreOptionsShownOnMouseOverFab';

const DataListItem = ({
    children,
    boxStyle = {},
    dataId,
    dense, 
    handleMoreOptionsShownOnMouseOverFab,
    moreOptionsFabStyle,
    onClick,
    showMoreOptionsFab,
    style,
    sx,
}) => {
    const [mouseIsOver, setMouseIsOver] = useState(false);

    const handleMouseOver = () => {
        setMouseIsOver(true);
    };
    
    const handleMouseOut = () => {
        setMouseIsOver(false);
    };

    boxStyle = {
        padding: dense ? 8 : 16,
        cursor: onClick ? 'pointer' : 'default',
        ...boxStyle
    }
    return (
        <ListItem
            disableGutters
            sx={sx} style={style}
            data-id={dataId}
        >
            <Box
                sx={{
                    backgroundColor: theme => theme.palette.background.paper,
                    borderRadius: '8px',
                    boxShadow: '0 0 8px rgba(0, 0, 0, 0.08)',
                    position: 'relative',
                    width: '100%',
                    '&:hover': {
                        boxShadow: '0 2px 16px rgba(0, 0, 0, 0.24)'
                    },
                }}
                style={boxStyle} onClick={onClick}
                onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}
            >
                {
                    showMoreOptionsFab &&
                    <MoreOptionsShownOnMouseOverFab mouseIsOver={mouseIsOver} moreOptionsFabStyle={moreOptionsFabStyle} onClick={handleMoreOptionsShownOnMouseOverFab} />
                }
                {children}
            </Box>
        </ListItem>
    );
};

export default DataListItem;