import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import CenteredCircularProgress from './CenteredCircularProgress';
import Modal from './Modal';
import { actionWindowStyles } from '../styles/index';

const Window = ({children, id, open, onClose, actionLoading, title, subTitle, subTitleIcon, closeButtonText, handleSubmitButtonClick, hideBottomButtons, modalInnerBoxSx, submitButtonDisabled, submitButtonLoading, submitButtonStartIcon, submitButtonText, windowRef}) => {
    return (
        <Modal 
            id={id}
            open={open}
            onClose={onClose}
            innerBoxSx={modalInnerBoxSx || actionWindowStyles}
            modalRef={windowRef}
        >
            <Box mb={2}>
                {
                    title &&
                    <Box mb={3}>
                        <Typography variant="h2">{title}</Typography>
                    </Box>
                }
                <Box mb={2}>
                    {
                        subTitle &&
                        <Box mb={2}>
                            <Grid container spacing={1} alignItems="center">
                                {
                                    subTitleIcon &&
                                    <Grid item>
                                        {subTitleIcon}
                                    </Grid>
                                }
                                <Grid item xs>
                                    <Typography variant="h2">{subTitle}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    }
                    {children}
                </Box>
            </Box>
            {
                !hideBottomButtons &&
                <Grid container spacing={1} alignItems="center" justifyContent="center">
                    {
                        handleSubmitButtonClick &&
                        <Grid item>
                            {
                                actionLoading
                                ? <CenteredCircularProgress />
                                :
                                <Button
                                    variant="contained" color="primary"
                                    disabled={submitButtonDisabled || submitButtonLoading}
                                    startIcon={submitButtonLoading ? <CircularProgress size={20} /> : submitButtonStartIcon}
                                    onClick={handleSubmitButtonClick}
                                >{submitButtonText || 'Salvar'}</Button>
                            }
                        </Grid>
                    }
                    <Grid item>
                        <Button
                            onClick={onClose}
                        >{closeButtonText || (handleSubmitButtonClick ? 'Cancelar' : 'Fechar')}</Button>
                    </Grid>
                </Grid>
            }
        </Modal>
    );
};

export default Window;