import { useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DescriptionIcon from '@mui/icons-material/Description';
import CenteredCircularProgress from '../../../../components/CenteredCircularProgress';
import Form from '../../../Form';
import Select from '../../../../components/Select';
import ViewBox from '../../../../components/ViewBox';
import { SERVER_PATH } from '../../../../utils/constants';
import { useAppCtxAPI, useAppCtxLoading, useAppCtxReturnedFormFile, useAppCtxSelectedFolder, useAppCtxUserGroups } from '../../../../context/SystemContext';
import { useClientCtxActiveClient } from '../../../../context/ClientContext';
import { useFolderUserCtxFolderTemplates, useFolderUserCtxTemplates } from '../../context/ClientContext';
import { useForm } from '../../../Form/context/FormContext';

function RequestDoc(){
    const { setLoading, setReturnedFormFile, updateSelectedFolder } = useAppCtxAPI();
    const loading = useAppCtxLoading();
    const returnedFormFile = useAppCtxReturnedFormFile();
    const selectedFolder = useAppCtxSelectedFolder();
    const { operatorStatus } = useAppCtxUserGroups();
    const activeClient = useClientCtxActiveClient();
    const folderTemplates = useFolderUserCtxFolderTemplates();
    const templates = useFolderUserCtxTemplates();
    const { resetForm, set_savedForm, selectedForm, set_selectedForm, selectedFormId, set_selectedFormId, setFormActiveClient, set_visibleQuestions, set_initialForm, set_userForm } = useForm();
    const [formOptions, set_formOptions] = useState([]);

    useEffect(() => {
        setFormActiveClient(activeClient);
        setReturnedFormFile(null);
        setLoading(false);
    }, []);

    useEffect(() => {
        if(templates.length >= 1){
            let formOptionsNewValue = folderTemplates.map(templateId => {
                let template = templates.find(t => t._id === templateId);
                if(!template) return null;
                let formOption = {value: template?._id, label: template?.name};
                return formOption;
            }).filter(template => !!template);
            set_formOptions(formOptionsNewValue);
        }
    }, [templates, folderTemplates]);

    const handle_selectedFormIdChange = async (newValue) => {
        set_selectedFormId(newValue);
        setLoading(true);
        resetForm();
        set_selectedForm(null);

        set_savedForm(null);
        setReturnedFormFile(null);
        set_visibleQuestions([]);

        const initialForm = {};
        const folderInitialFormResponses = selectedFolder.folderInitialFormResponses || [];
        const templateIndex = folderInitialFormResponses.findIndex(item => item.templateId === newValue);
        if(templateIndex !== -1){
            folderInitialFormResponses[templateIndex].questions.forEach(question => {
                initialForm[question.name] = question.response;
            });
        }
        set_initialForm(initialForm);
        set_userForm(initialForm);

        const postData = {
            templateId: newValue,
            fields: ['name', 'form', 'useEffects']
        }
        await axios.post(SERVER_PATH + '/data/templates/one', postData)
        .then(res => {
            set_selectedForm(res.data);
        })
        .catch(err => {

        });
        setLoading(false);
    };

    const onFormSubmit = (data) => {
        const { savedForm } = data;
        if(savedForm){
            let currentSavedForms = selectedFolder.forms;
            let index = currentSavedForms.findIndex(form => form._id === savedForm._id);
            if(index >= 0) currentSavedForms = [...currentSavedForms.slice(0, index), ...currentSavedForms.slice(index + 1)];
            updateSelectedFolder({ forms: currentSavedForms });
        }
    };

    return (
        <ViewBox>
            {
                returnedFormFile &&
                <Box style={{textAlign: 'center'}}>
                    Clique no ícone para acessar o documento:<br />
                    <IconButton
                        href={returnedFormFile.serverFile ? `${SERVER_PATH}/${returnedFormFile.link}` : returnedFormFile.link}
                        target="_blank"
                        size="large"><DescriptionIcon /></IconButton>
                </Box>
            }
            {
                !loading &&
                <Box mb={2}>
                    {
                        formOptions.length === 0
                        ?
                        <Box style={{textAlign: 'center'}}>
                            <Typography variant="body1">Não há formulários cadastrados nesta pasta.</Typography>
                        </Box>
                        :
                        <Box>
                            <Container maxWidth="xs">
                                <Select
                                    label="Formulário"
                                    options={formOptions.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))}
                                    value={selectedFormId} onChange={(e) => handle_selectedFormIdChange(e.target.value)}
                                />
                            </Container>
                        </Box>
                    }
                </Box>
            }
            {
                loading &&
                <CenteredCircularProgress />
            }
            {
                selectedForm &&
                <Fade in timeout={1500}>
                    <Box>
                        <Collapse in={!loading}>
                            <Box>
                                <Form form={selectedForm} templates={templates} data={{operatorStatus}} onFormSubmit={onFormSubmit} />
                            </Box>
                        </Collapse>
                    </Box>
                </Fade>
            }
        </ViewBox>
    );
}

export default RequestDoc;