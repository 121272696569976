import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import queryString from 'query-string';
import { ERROR_MESSAGE_UNKNOWN, SERVER_PATH, SUCCESS_MESSAGE_SAVED } from '../../utils/constants';
import { useAppCtxAPI } from '../../context/SystemContext';
import axios from 'axios';
import generator from 'generate-password';

const classes = {
    root: {
        backgroundColor: theme => theme.palette.background.default,
        height: '100%',
        padding: '24px'
    },
    box: {
        backgroundColor: '#f5f5f5',
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
        padding: '24px 16px',
        borderRadius: theme => theme.spacing(1)
    }
};

const RecoverPassword = () => {
    const { floatingAlert, handleNavigate, setLoadingSystem, toast } = useAppCtxAPI();
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const [loadingPage, set_loadingPage] = useState(true);
    const [errorMessage, set_errorMessage] = useState('');
    const [foundUser, set_foundUser] = useState({});

    const [password1, set_password1] = useState('');
    const [password2, set_password2] = useState('');
    const [passwordFieldType, set_passwordFieldType] = useState('password');

    useEffect(() => {
        setLoadingSystem(false);
    }, []);

    useEffect(() => {
        let search = queryString.parse(window.location.search);
        let token = search.token;
        if(token){
            axios.get(SERVER_PATH + `/data/retrieve-password/check-token?token=${token}`)
            .then(res => {
                set_foundUser(res.data);
                set_loadingPage(false);
            })
            .catch(err => {
                set_errorMessage(err.response.data);
                set_loadingPage(false);
            });
        } else {
            handleNavigate('/');
        }
    }, []);

    const handle_okClick = () => {
        let password1 = ref1.current.value;
        let regExpTest = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&\-_.])[A-Za-z\d@$!%*?&\-_.]{8,}$/.test(password1)
        if(!regExpTest){
            toast('Sua senha deve respeitar os requisitos de segurança.');
        } else {
            let password2 = ref2.current.value;
            if(!password1 || password1 !== password2){
                toast('Você deve indicar a mesma senha nos dois campos.');
            } else {
                set_loadingPage(true);
                let search = queryString.parse(window.location.search);
                let token = search.token;
                axios.post(SERVER_PATH + '/data/retrieve-password/update', {pw: password1, id: foundUser._id, token})
                .then(res => {
                    toast(SUCCESS_MESSAGE_SAVED);
                    handleNavigate('/');
                })
                .catch(err => {
                    set_loadingPage(false);
                    floatingAlert(err.response.data || ERROR_MESSAGE_UNKNOWN, 'error', 0);
                });
            }
        }
    };

    const handleGenerateStrongPasswordPress = () => {
        const password = generator.generate({length: 16, numbers: true, symbols: '-_@*', strict: true});
        set_password1(password);
        set_password2(password);
        set_passwordFieldType('text');
    };

    return (
        <Box sx={classes.root}>
            <Container maxWidth="xs">
                <Box sx={classes.box}>
                    <Box style={{textAlign: 'center'}} mb={2}>
                        <img src={'/SOLIDA-500.png'} style={{height: 80}} />
                    </Box>
                    <Box style={{textAlign: 'center'}} mb={3}>
                        <Typography variant="h2">Esqueceu sua senha?</Typography>
                    </Box>
                    {
                        loadingPage
                        ?
                        <Box style={{textAlign: 'center'}} mb={2}>
                            <CircularProgress />
                            <Typography variant="body1">Verificando token...</Typography>
                        </Box>
                        :
                        <Box style={{textAlign: 'center'}} mb={2}>
                            {
                                errorMessage
                                ?
                                <Typography variant="body1">{errorMessage}</Typography>
                                :
                                <Box>
                                    <Box mb={2}>
                                        <Typography variant="body1">Olá, {foundUser.fullName}. O seu e-mail é <strong>{foundUser.email}</strong></Typography>
                                    </Box>
                                    <Box mb={1}>
                                        <TextField
                                            variant="standard"
                                            inputProps={{ref: ref1}}
                                            margin="dense"
                                            label="Nova senha"
                                            type={passwordFieldType}
                                            onError={() => console.log('error')}
                                            value={password1}
                                            onChange={(e) => set_password1(e.target.value)}
                                            onKeyPress={(e) => {if(e.key === 'Enter') handle_okClick()}}
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton size="small" onClick={() => set_passwordFieldType(prevState => prevState === 'password' ? 'text' : 'password')}>
                                                        {passwordFieldType === 'password' ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                    </IconButton>
                                                )
                                            }}
                                            fullWidth />
                                    </Box>
                                    <Box mb={2}>
                                        <TextField
                                            variant="standard"
                                            inputProps={{ref: ref2}}
                                            margin="dense"
                                            label="Repetir a nova senha"
                                            type={passwordFieldType}
                                            value={password2}
                                            onChange={(e) => set_password2(e.target.value)}
                                            onKeyPress={(e) => {if(e.key === 'Enter') handle_okClick()}}
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton size="small" onClick={() => set_passwordFieldType(prevState => prevState === 'password' ? 'text' : 'password')}>
                                                        {passwordFieldType === 'password' ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                    </IconButton>
                                                )
                                            }}
                                            fullWidth />
                                    </Box>
                                    <Box style={{textAlign: 'left'}} mb={1}>
                                        {/(?=.*[a-z])/.test(password1)
                                            ?
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item>
                                                    <CheckCircleOutlineIcon style={{color: 'darkgreen'}} />
                                                </Grid>
                                                <Grid item xs>
                                                    <Typography variant="body2" style={{color: 'darkgreen'}}>A senha tem pelo menos 1 letra minúscula</Typography>
                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item>
                                                    <HighlightOffIcon color="error" />
                                                </Grid>
                                                <Grid item xs>
                                                    <Typography variant="body2" color="error">A senha deve ter pelo menos 1 letra minúscula</Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                        {/(?=.*[A-Z])/.test(password1)
                                            ?
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item>
                                                    <CheckCircleOutlineIcon style={{color: 'darkgreen'}} />
                                                </Grid>
                                                <Grid item xs>
                                                    <Typography variant="body2" style={{color: 'darkgreen'}}>A senha tem pelo menos 1 letra maiúscula</Typography>
                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item>
                                                    <HighlightOffIcon color="error" />
                                                </Grid>
                                                <Grid item xs>
                                                    <Typography variant="body2" color="error">A senha deve ter pelo menos 1 letra maiúscula</Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                        {/(?=.*\d)/.test(password1)
                                            ?
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item>
                                                    <CheckCircleOutlineIcon style={{color: 'darkgreen'}} />
                                                </Grid>
                                                <Grid item xs>
                                                    <Typography variant="body2" style={{color: 'darkgreen'}}>A senha tem pelo menos 1 número</Typography>
                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item>
                                                    <HighlightOffIcon color="error" />
                                                </Grid>
                                                <Grid item xs>
                                                    <Typography variant="body2" color="error">A senha deve ter pelo menos 1 número</Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                        {/(?=.*[@$!%*?&\-_.])/.test(password1)
                                            ?
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item>
                                                    <CheckCircleOutlineIcon style={{color: 'darkgreen'}} />
                                                </Grid>
                                                <Grid item xs>
                                                    <Typography variant="body2" style={{color: 'darkgreen'}}>A senha tem pelo menos 1 caractere especial</Typography>
                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item>
                                                    <HighlightOffIcon color="error" />
                                                </Grid>
                                                <Grid item xs>
                                                    <Typography variant="body2" color="error">A senha deve ter pelo menos 1 caractere especial</Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                        {/.{8,}/.test(password1)
                                            ?
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item>
                                                    <CheckCircleOutlineIcon style={{color: 'darkgreen'}} />
                                                </Grid>
                                                <Grid item xs>
                                                    <Typography variant="body2" style={{color: 'darkgreen'}}>A senha tem 8 caracteres ou mais</Typography>
                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item>
                                                    <HighlightOffIcon color="error" />
                                                </Grid>
                                                <Grid item xs>
                                                    <Typography variant="body2" color="error">A senha deve ter no mínimo 8 caracteres</Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Box>
                                    <Box mt={2}>
                                        <Button onClick={handleGenerateStrongPasswordPress} variant="outlined">Sugerir senha forte</Button>
                                    </Box>
                                    <Box mt={2}>
                                        <Button onClick={handle_okClick} color="primary" variant="contained">Enviar</Button>
                                    </Box>
                                </Box>
                            }
                        </Box>
                    }
                </Box>
            </Container>
        </Box>
    );
};

export default RecoverPassword;