import { createContext, useContext, useMemo, useReducer } from 'react';

const OnlineUsersCtxAPI = createContext();
const OnlineUsersCtx = createContext();

const defaultState = {
    onlineUsers: [],
    onlineUsersUpdatedAt: null,
    onlineOperators: [],
    onlineOperatorsUpdatedAt: null,
};

const reducer = (state, action) => {
    const { payload, type } = action;

    switch (type) {
        case 'SET ONLINE OPERATORS':
            return { ...state, onlineOperators: payload, onlineOperatorsUpdatedAt: new Date() };
        case 'SET ONLINE USERS':
            return { ...state, onlineUsers: payload, onlineUsersUpdatedAt: new Date() };
        default: return state;
    }
};

const OnlineUsersProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, {...defaultState});

    const api = useMemo(() => {

        const setOnlineOperators = (payload) => {
            dispatch({ type: 'SET ONLINE OPERATORS', payload });
        };
        
        const setOnlineUsers = (payload) => {
            dispatch({ type: 'SET ONLINE USERS', payload });
        };

        return {
            dispatch,
            setOnlineOperators,
            setOnlineUsers,
        };

    }, []);

    const onlineUsersCtxValue = useMemo(() => ({
        onlineOperators: state.onlineOperators,
        onlineUsers: state.onlineUsers
    }), [state.onlineOperatorsUpdatedAt, state.onlineUsersUpdatedAt]);

    return (
        <OnlineUsersCtxAPI.Provider value={api}>
            <OnlineUsersCtx.Provider value={onlineUsersCtxValue}>
                {children}
            </OnlineUsersCtx.Provider>
        </OnlineUsersCtxAPI.Provider>
    );
};

const useOnlineUsersCtxAPI = () => useContext(OnlineUsersCtxAPI);
const useOnlineUsersCtx = () => useContext(OnlineUsersCtx);

export {
    OnlineUsersProvider,
    useOnlineUsersCtxAPI,
    useOnlineUsersCtx,
};