import numero from 'numero-por-extenso';

const number = {
    typeSpecificsOptions: [
        {value: 'numberAndInWords', label: 'Em numeral e por extenso'},
        {value: 'number', label: 'Em numeral'},
        {value: 'inWords', label: 'Por extenso'},
    ],
    createEffect: ({ effectSettings, newEffectId, templateEffects }) => {
        const newEffect = {
            id: newEffectId,
            type: effectSettings.type,
            typeSpecifics: effectSettings.typeSpecifics,
            question: effectSettings.question,
            label: effectSettings.label || effectSettings.question
        };
        const foundEffect = templateEffects.find(templateEffect => templateEffect.type === effectSettings.type && templateEffect.question === effectSettings.question);
        return { foundEffect, newEffect };
    },
    newEffectWindowHandleEffectTypeChange: () => {
        const data = {
            question: '',
            typeSpecifics: 'numberAndInWords'
        };
        return data;
    },
    getFormEditorQuestion: (data) => {
        if(data){
            const { effect, question } = data;
            const formEditorQuestion = {...question};
            formEditorQuestion.typeSpecifics = effect.typeSpecifics;
            return formEditorQuestion;
        }
        return false;
    },
    getTypeSpecificInput: (number, typeSpecifics) => {
        let input = '';
        if(typeSpecifics === 'numberAndInWords'){
            input = `${number} (${numero.porExtenso(number)})`;
        } else if(typeSpecifics === 'inWords'){
            input = numero.porExtenso(number);
        } else {
            input = number.toString();
        }
        return input;
    },
    checkValidation: (data) => {
        let questionValid = false;
        if(data){
            questionValid = true;
            const { input } = data;
            if(!input) questionValid = false;
        }
        return questionValid;
    }
};

export {
    number
}