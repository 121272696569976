import { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AttachmentIcon from '@mui/icons-material/Attachment';
import DescriptionIcon from '@mui/icons-material/Description';
import SendIcon from '@mui/icons-material/Send';
import WarningIcon from '@mui/icons-material/Warning';
import AddOrSubtractDial from './AddOrSubtractDial';
import Window from './Window';
import { useAppCtxAPI, useAppCtxActiveUser, useAppCtxLoading } from '../context/SystemContext';
import { useSocket } from '../context/SocketContext';
import { useClientCtxActiveClient } from '../context/ClientContext';
import { useDocumentsAPI, useDocumentsCtxSubmitDocumentView } from '../context/DocumentsContext';
import { ERROR_MESSAGE_CHANGES_UNDONE, SERVER_PATH, SPECIAL_STRINGS_REVIEW } from '../utils/constants';

const SubmitDocDialog = ({setSaving, actionConfirmCallback, actionDoneCallback, updateDocument}) => {
    const { floatingAlert, setLoading, toast } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const loading = useAppCtxLoading();
    const activeClient = useClientCtxActiveClient();
    const {createSocketConnection} = useSocket();
    const socket = createSocketConnection();
    const { hideSubmitDocumentView } = useDocumentsAPI();
    const { submitDocumentViewOpen, submitDocumentViewSelectedDocument: selectedDocument } = useDocumentsCtxSubmitDocumentView();
    const [loadingPage, setLoadingPage] = useState(true);
    const [documentName, set_documentName] = useState('');
    const [minimumNumberOfMinutesRequiredForDocumentDelivery, set_minimumNumberOfMinutesRequiredForDocumentDelivery] = useState(15);
    const [numberOfMinutesTakenForDocumentDelivery, set_numberOfMinutesTakenForDocumentDelivery] = useState(15);
    const [isStaticDocument, set_isStaticDocument] = useState(false);
    const [uploadTypeUrl, set_uploadTypeUrl] = useState(false);
    const [reviewLink, set_reviewLink] = useState('');
    const [customText, set_customText] = useState('');
    const [filesList, set_filesList] = useState([]);
    const [selectedDocumentTemplate, setSelectedDocumentTemplate] = useState();
    const [selectedDocumentTemplateIdIsReview, setSelectedDocumentTemplateIdIsReview] = useState(false);

    useEffect(() => {
        if(submitDocumentViewOpen){
            set_customText('');
            if(selectedDocument && selectedDocumentTemplate !== undefined){
                set_documentName(selectedDocument.name);

                let currentIsStaticDocument = false;
                
                const currentSelectedDocumentTemplateIdIsReview = SPECIAL_STRINGS_REVIEW.includes(selectedDocument?.template);
                setSelectedDocumentTemplateIdIsReview(currentSelectedDocumentTemplateIdIsReview);
                
                if(!currentSelectedDocumentTemplateIdIsReview){
                    let documentDocuments = selectedDocument.documents;
                    if(!Array.isArray(documentDocuments)) documentDocuments = [documentDocuments];
                    let currentDocument = documentDocuments[documentDocuments.length - 1];
                    if(currentDocument.fileFormat !== 'googleDoc'){
                        let currentDocumentLink = currentDocument.link;
                        if(currentDocumentLink){
                            let fileExtension = currentDocumentLink.split('.').pop();
                            let extensions = ['pdf', 'docx', 'doc'];
                            if(extensions.includes(fileExtension)) currentIsStaticDocument = true;
                        }
                    }
                } else {
                    currentIsStaticDocument = true; 
                }
                set_isStaticDocument(currentIsStaticDocument);
                
                if(selectedDocumentTemplate){
                    set_minimumNumberOfMinutesRequiredForDocumentDelivery(selectedDocumentTemplate.averageNumberOfMinutesRequiredForDocumentDelivery || 15);
                    return set_numberOfMinutesTakenForDocumentDelivery(selectedDocumentTemplate.averageNumberOfMinutesRequiredForDocumentDelivery || 15);
                }
                set_minimumNumberOfMinutesRequiredForDocumentDelivery(15);
                set_numberOfMinutesTakenForDocumentDelivery(15);
            }
        }
    }, [submitDocumentViewOpen, selectedDocument, selectedDocumentTemplate]);

    useEffect(() => {
        let isMounted = true;
        const cancelTokenSource = axios.CancelToken.source();
        if(selectedDocument){
            if(selectedDocument.template){
                const fetchData = async () => {
                    setLoadingPage(true);
                    const postData = {
                        templateId: selectedDocument.template,
                        fields: ['averageNumberOfMinutesRequiredForDocumentDelivery']
                    };
                    await axios.post(SERVER_PATH + '/data/templates/one', postData, {
                        cancelToken: cancelTokenSource.token
                    })
                    .then(res => {
                        setSelectedDocumentTemplate(res.data);
                    })
                    .catch(err => {
                        setSelectedDocumentTemplate(null);
                    });
                    if(isMounted) setLoadingPage(false);
                };
    
                fetchData();
                
            } else {
                setSelectedDocumentTemplate(null);
            }
        }
        return () => {
            cancelTokenSource.cancel();
            isMounted = false;
        };
    }, [selectedDocument]);

    const handle_okClick = async () => {
        
        if(actionConfirmCallback) actionConfirmCallback();
        
        if(setSaving) setSaving(true);
        else setLoading(true);
        handleClose();

        if(updateDocument) updateDocument({...selectedDocument, sending: true, hidden: true});

        const filesInput = document.getElementById('reviewFile');
        
        let postData = new FormData();
        postData.append('documentId', selectedDocument._id);
        postData.append('documentProject', selectedDocument.project || selectedDocument.client);
        if(selectedDocument.flag) postData.append('documentFlag', selectedDocument.flag);
        postData.append('documentName', documentName);
        if(activeClient?.modules?.includes('timesheet') && !SPECIAL_STRINGS_REVIEW.includes(selectedDocument.template)){
            postData.append('timesheet', true);
            postData.append('numberOfMinutesTakenForDocumentDelivery', numberOfMinutesTakenForDocumentDelivery);
        }

        if(reviewLink){
            postData.append('link', reviewLink);
        } else {
            if(filesInput){
                let files = filesInput.files;
                if(files.length > 0){
                    toast('Verificando arquivos... isso pode levar alguns segundos...');
                    postData.append('file', files[0]);
                }
            }
        }
        postData.append('message', customText);
 
        await axios.post(SERVER_PATH + '/data/operator/docs/send', postData, {headers: {'Content-Type': 'multipart/form-data'}})
        .then(res => {
            if(updateDocument) updateDocument(res.data.doc);
            if(filesInput) filesInput.value = '';
            socket.emit('DOCUMENT SENT', {actionUserId: activeUser._id, actionUserName: activeUser.fullName, sentDocument: res.data.doc});
            
            if(actionDoneCallback) actionDoneCallback(res.data.doc);
        })
        .catch(err => {
            if(updateDocument){
                updateDocument({...selectedDocument, sending: false, hidden: false});
                floatingAlert(ERROR_MESSAGE_CHANGES_UNDONE, 'error', 0);
            }
        });
        
        if(setSaving) setSaving(false);
        else setLoading(false);
    };

    const triggerFileInputClick = () => {
        const fileInput = document.getElementById('reviewFile');
        if(fileInput) fileInput.click();
    }
    const handleFileInputChange = (e) => {
        const el = e.target;
        let files = el.files;
        if(files && files.length >= 1){
            let list = [];
            for (let file of files) {
                let fileName = file.name ? file.name : 'NOT SUPPORTED';
                list.push(fileName);
            }
            set_filesList(list);
        } else {
            set_filesList([]);
        }
    };

    const handleClose = () => {
        hideSubmitDocumentView();
    };
    
    return (
        <Window
            open={submitDocumentViewOpen} onClose={handleClose}
            title="Enviar documento"
            subTitle={selectedDocument?.name}
            subTitleIcon={<DescriptionIcon />}
            actionLoading={loadingPage}
            handleSubmitButtonClick={handle_okClick}
            submitButtonText="Enviar"
            submitButtonStartIcon={<SendIcon />}
            submitButtonDisabled={!documentName || loading}
        >
            <Box mb={2}>
                <Typography variant="h6">Você quer disponibilizar o documento para o(a) solicitante?</Typography>
            </Box>
            {
                isStaticDocument &&
                <>
                    {
                        uploadTypeUrl
                        ?
                        <Box mb={2}>
                            <TextField
                                variant="standard"
                                fullWidth
                                label="Link do documento revisado"
                                value={reviewLink}
                                onChange={(e) => set_reviewLink(e.target.value)} />
                        </Box>
                        :
                        <Box style={{textAlign: 'center'}} mb={2}>
                            <Button variant="text" onClick={triggerFileInputClick}>Escolher arquivo</Button>
                            <input
                                style={{display: 'none'}}
                                id="reviewFile" type="file"
                                accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                onChange={handleFileInputChange}
                            />
                        </Box>
                    }
                    {
                        (!uploadTypeUrl && filesList.length >= 1) &&
                        <Box mb={2}>
                            <Box mt={1} style={{display: 'flex', alignItems: 'center', backgroundColor: '#e8e8e8', borderRadius: '8px'}}>
                                <Box pl={1}>
                                    <AttachmentIcon />
                                </Box>
                                <div style={{flex: 1}}>
                                    <ul>
                                        {
                                            filesList.map((fileListItem, index) => (
                                                <li key={index}>{fileListItem}</li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </Box>
                        </Box>
                    }
                    <Box mb={2} style={{textAlign: 'center'}}>
                        <Typography variant="body2" sx={{
                            color: theme => theme.palette.primary.main,
                            cursor: 'pointer',
                            '&:hover': {
                                textDecoration: 'underline'
                            }}}
                        onClick={(e) => set_uploadTypeUrl(!uploadTypeUrl)}>Quero enviar um {uploadTypeUrl ? 'arquivo' : 'link'}</Typography>
                    </Box>
                </>
            }
            <Box mb={2}>
                <TextField
                    variant="standard"
                    fullWidth
                    multiline
                    label="Comentários, se houver"
                    value={customText}
                    onChange={(e) => set_customText(e.target.value)} />
            </Box>
            <Box mb={2}>
                <TextField
                    variant="standard"
                    fullWidth
                    label="Renomear"
                    value={documentName}
                    onChange={(e) => set_documentName(e.target.value)} />
            </Box>
            {
                (activeClient?.modules?.includes('timesheet')) &&
                <>
                {
                    selectedDocumentTemplateIdIsReview
                    ?
                    <Box mb={3}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <WarningIcon />
                            </Grid>
                            <Grid item xs>
                                <Typography variant="body1">Entregar uma revisão não lança automaticamente as respectivas horas no relatório do cliente.</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    :
                    <Box mb={3}>
                        <Typography variant="h6">Você levou mais que {minimumNumberOfMinutesRequiredForDocumentDelivery} minutos para revisar o contrato?</Typography>
                        <AddOrSubtractDial
                            subtractDisabled={!numberOfMinutesTakenForDocumentDelivery || numberOfMinutesTakenForDocumentDelivery === 15 || numberOfMinutesTakenForDocumentDelivery <= minimumNumberOfMinutesRequiredForDocumentDelivery}
                            subtractOnClick={() => set_numberOfMinutesTakenForDocumentDelivery(prevState => prevState - 15)}
                            number={numberOfMinutesTakenForDocumentDelivery || 15}
                            addOnClick={() => set_numberOfMinutesTakenForDocumentDelivery(prevState => prevState + 15)}
                        />
                    </Box>
                }
                </>
            }
        </Window>
    );
}

export default SubmitDocDialog;