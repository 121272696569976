import { Box, Grid, Typography } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';

const PendingDocumentsTask = ({userNumberOfPendingDocuments}) => {
    if(userNumberOfPendingDocuments === '0') return null;
    return (
        <Box mb={1}>
            <Grid container alignItems="center" wrap="nowrap">
                <Grid item>
                    <DescriptionIcon />
                </Grid>
                <Grid item xs>
                    <Typography variant="body2"><Typography variant="body2" component="span" sx={{display: {xs: 'none', lg: 'inline'}}}>Contratos: </Typography>{userNumberOfPendingDocuments}</Typography>
                </Grid>
            </Grid>
        </Box>
    );
}

export default PendingDocumentsTask;