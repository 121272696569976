import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';
import Window from './Window';

const classes = {
    actionWindow: {
        width: ['95%', '85%', '75%']
    },
    imageBox: {
        textAlign: 'center'
    },
    image: {
        maxWidth: '100%'
    }
};

const Img = styled('img')(unstable_styleFunctionSx);

const ImageWindow = ({imageUrl, setImageUrl}) => {
    
    const handleClose = () => {
        setImageUrl('');
    };

    return (
        <Window open={!!imageUrl} onClose={handleClose} modalInnerBoxSx={classes.actionWindow}>
            <Box sx={classes.imageBox}>
                <Img sx={classes.image} src={imageUrl} />
            </Box>
        </Window>
    );
};

export default ImageWindow;