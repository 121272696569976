import { useEffect, useState } from 'react';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '../../../../../components/Dialog/Dialog';
import Input from '../../../../../components/Input';
import MultipleAutocomplete from '../../../../../components/MultipleAutocomplete';
import Switch from '../../../../../components/Switch';
import { useAppCtxAPI, useAppCtxActiveUser } from '../../../../../context/SystemContext';
import { useSocket } from '../../../../../context/SocketContext';
import { useClient } from '../../../../../context/ClientContext';
import { CNAEs } from '../../../../../utils/cnaes';
import { serverRequest, updateStateChangeArrayItemWith_id } from '../../../../../utils/common';
import { ERROR_MESSAGE_CHANGES_UNDONE } from '../../../../../utils/constants';

const ItemWindow = ({open, setOpen, data, selectedListName}) => {
    const { selectedItem, selectedListId, selectedList, set_selectedList } = data;
    const { toast } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const { createSocketConnection } = useSocket();
    const socket = createSocketConnection();
    const { getListById, setLists } = useClient();
    const defaultListItem = {
        key: {
            value: '',
            dirty: false
        },
        aliases: [],
        value: {
            value: '',
            dirty: false
        },
        cnaes: [],
        meiAllowed: false
    };
    const [listItem, set_listItem] = useState(defaultListItem);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if(open){
            let currentKey = '', currentAliases = [], currentValue = '', currentCnaes = [], currentMeiAllowed = false;
            if(selectedItem){
                currentKey = selectedItem.key;
                currentAliases = selectedItem.aliases || [];
                currentValue = selectedItem.value.replace(/<br \/>/g, '\n');
                // currentCnaes = selectedItem.cnaes ? selectedItem.cnaes.map(cnaeCode => {
                //     const foundCnae = CNAEs.find(foundCnae => foundCnae.cnae === cnaeCode);
                //     return foundCnae;
                // }) : [];
                currentCnaes = selectedItem.cnaes || [];
                currentMeiAllowed = selectedItem.meiAllowed || false;
            }
            set_listItem({
                key: {value: currentKey, dirty: false},
                aliases: currentAliases.map(alias => ({value: alias, dirty: false})),
                value: {value: currentValue, dirty: false},
                cnaes: currentCnaes,
                meiAllowed: currentMeiAllowed
            })
        }
    }, [open, selectedItem]);

    const handleSave = async () => {
        setSaving(true);
        const prevSelectedList = [...selectedList];
        const currentList = getListById(selectedListId);
        const listId = currentList.shortName || currentList._id;
        const itemId = selectedItem?.id;
        const itemUpdates = {
            key: listItem.key.value,
            aliases: listItem.aliases.map(alias => alias.value),
            value: listItem.value.value.replace(/\n/g, '<br />'),
            // cnaes: listItem.cnaes.map(cnaeObject => cnaeObject.cnae),
            cnaes: listItem.cnaes,
            meiAllowed: listItem.meiAllowed
        };
        updateStateChangeArrayItemWith_id({...selectedItem, ...itemUpdates}, set_selectedList, 'id');
        try {
            const data = await serverRequest({path: '/data/lists/save', method: 'post', data: {
                listId,
                itemId,
                item: itemUpdates
            }});
            setLists(data.lists);
            socket.emit('CLIENT LISTS UPDATED', { updatedClient: data });
            handleClose();
        } catch (error) {
            set_selectedList(prevSelectedList);
            toast(ERROR_MESSAGE_CHANGES_UNDONE);
        }
        setSaving(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            onClose={handleClose}
            onSaveClick={handleSave}
            open={open}
            saveButtonDisabled={!listItem.key || listItem.aliases?.includes('') || !listItem.value || (selectedListId === 'special_list_audiovisual_services' && activeUser.type < 9)}
            saving={saving}
            title={selectedItem ? `${selectedListName ? `${selectedListName} | ` : ''}${selectedItem.key}`  : 'Novo item'}
        >
            
            <Box mb={2}>
                <Box mb={1}>
                    <Typography variant="h6">Nome</Typography>
                </Box>
                <Input
                    disabled={selectedItem}
                    fullWidth required
                    value={listItem.key.value} onChange={(e) => set_listItem(prevState => ({...prevState, key: {...prevState['key'], value: e.target.value}}))}
                    error={listItem.key.dirty && !listItem.key.value}
                    helperText={listItem.key.dirty && !listItem.key.value ? 'Esta pergunta é obrigatória' : ''}
                    onBlur={() => set_listItem(prevState => ({...prevState, key: {...prevState['key'], dirty: true}}))}
                />
            </Box>
            <Box mb={2}>
                {
                    listItem.aliases?.map((alias, index) => (
                        <Box key={index}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <Input
                                        required
                                        label={`Outro nome ${index + 1}`}
                                        value={alias.value} onChange={(e) => set_listItem(prevState => ({...prevState, aliases: [...prevState.aliases.slice(0, index), {...prevState['aliases'][index], value: e.target.value}, ...prevState.aliases.slice(index + 1)]}))}
                                        error={alias.dirty && !alias.value}
                                        helperText={alias.dirty && !alias.value ? 'Esta pergunta é obrigatória' : ''}
                                        onBlur={() => set_listItem(prevState => ({...prevState, aliases: [...prevState.aliases.slice(0, index), {...prevState['aliases'][index], dirty: true}, ...prevState.aliases.slice(index + 1)]}))}
                                    />
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        size="small"
                                        onClick={() => set_listItem(prevState => ({...prevState, aliases: [...prevState.aliases.slice(0, index), ...prevState.aliases.slice(index + 1)]}))}
                                    ><DeleteIcon /></IconButton>
                                </Grid>
                            </Grid>
                        </Box>
                    ))
                }
                <Button
                    onClick={() => set_listItem(prevState => ({...prevState, aliases: [...prevState.aliases, {value: '', dirty: false}]}))}
                >Acrescentar outro nome</Button>
            </Box>
            <Box mb={2}>
                <Box mb={1}>
                    <Typography variant="h6">Descrição</Typography>
                </Box>
                <Input
                    fullWidth required multiline
                    value={listItem.value.value} onChange={(e) => set_listItem(prevState => ({...prevState, value: {...prevState['value'], value: e.target.value}}))}
                />
            </Box>
            <Box mb={2}>
                <Box mb={1}>
                    <Typography variant="h6">CNAEs</Typography>
                </Box>
                <MultipleAutocomplete
                    options={CNAEs} getOptionLabel={(option) => `${option.code} - ${option.description}`}
                    value={listItem.cnaes} onChange={(e, newValue) => set_listItem(prevState => ({...prevState, cnaes: newValue}))}
                />
            </Box>
            <Box mb={2}>
                <Box mb={1}>
                    <Typography variant="h6">MEI</Typography>
                </Box>
                <Switch label="Essa atividade pode ser exercida por MEI?" labelPosition="left"
                    checked={listItem.meiAllowed} onChange={(e) => set_listItem(prevState => ({...prevState, meiAllowed: e.target.checked}))}
                />
            </Box>
                
        </Dialog>
    );
};

export default ItemWindow;