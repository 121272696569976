import { styled } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';

const classes = {
    static: {
        boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.08)',
        border: '1px solid rgba(0, 0, 0, 0.08)',
        borderRadius: '8px',
        padding: '8px',
        position: 'relative',
    },
    withAnimation: {
        boxShadow: '0 0 8px rgba(0, 0, 0, 0.08)',
        border: 0,
        borderRadius: '8px',
        padding: '8px',
        position: 'relative',
        '&:hover': {
            boxShadow: '0 2px 16px rgba(0, 0, 0, 0.24)'
        }
    }
};

const Div = styled('div')(unstable_styleFunctionSx);

const ShadowedBox = ({children, additionalSxs = [], mouseOverShadowEffect, maxWidth, onClick, style}) => {
    let boxStyle = {maxWidth};
    if(style) boxStyle = {...boxStyle, ...style};
    
    return (
        <Div
            sx={[mouseOverShadowEffect ? classes.withAnimation : classes.static, ...additionalSxs]} style={boxStyle} mb={1}
            onClick={onClick}
        >
            {children}
        </Div>
    );
};

export default ShadowedBox;