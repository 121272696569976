import Header from '../../components/Header';
import { useManagementCtxAPI, useManagementCtxSidenavMobileOpen } from '../../context/ManagementContext';
import Account from './Account';

const ManagementHeader = () => {
    const { setSidenavMobileOpen } = useManagementCtxAPI();
    const sidenavMobileOpen = useManagementCtxSidenavMobileOpen();
    return (
        <Header
            account={<Account />}
            data={{
                sidenavMobileOpen, set_sidenavMobileOpen: setSidenavMobileOpen
            }}
        />
    );
};

export default ManagementHeader;