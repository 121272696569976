import React, { useEffect, useState } from 'react';
import axios from 'axios';
import queryString from 'query-string';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import AlertDialog from '../../../../../components/AlertDialog';
import TitlePatternElementWindow from './TitlePatternElementWindow';
import ViewBox from '../../../../../components/ViewBox';
import { useAppCtxAPI, useAppCtxLoading } from '../../../../../context/SystemContext';
import { useSocket } from '../../../../../context/SocketContext';
import { useClient, useClientCtxActiveClient } from '../../../../../context/ClientContext';
import { useDocumentsAPI } from '../../../../../context/DocumentsContext';
import { useOperatorTemplatesCtxAPI, useOperatorTemplatesCtxTemplateTitlePattern } from '../context/TemplatesContext';
import { SERVER_PATH } from '../../../../../utils/constants';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import update from 'immutability-helper';

const classes = {
    list: {
        // flex: 'none',
        borderRadius: '3px',
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'auto',
        overflowY: 'hidden',
        '&::-webkit-scrollbar': {
            height: 6
        },
    },
    elementBox: {
        backgroundColor: '#FFF5EE',
        border: '1px solid #E8E8E8',
        borderRadius: '3px',
        marginBottom: '4px',
        padding: '4px',
    }
};

const Div = styled('div')(unstable_styleFunctionSx);

const TitlePattern = () => {
    const { handleNavigate, setLoading, toast, updateOneOperators } = useAppCtxAPI();
    const loading = useAppCtxLoading();
    const { createSocketConnection } = useSocket();
    const socket = createSocketConnection();
    const { userTemplateFields } = useClient();
    const activeClient = useClientCtxActiveClient();
    const { updateTemplate } = useDocumentsAPI();
    const { setTemplateTitlePatternSelectedTemplate } = useOperatorTemplatesCtxAPI();
    const selectedTemplate = useOperatorTemplatesCtxTemplateTitlePattern();
    const [titlePattern, set_titlePattern] = useState([]);
    const [templateQuestions, set_templateQuestions] = useState([]);
    const [backDialogOpen, set_backDialogOpen] = useState(false);
    const [selectedTitlePatternElement, setSelectedTitlePatternElement] = useState(null);
    const [titlePatternElementWindowOpen, setTitlePatternElementWindowOpen] = useState(false);
    const [templateUserFields, setTemplateUserFields] = useState([]);
    const dateTitlePatternElementValueOptions = [
        {value: 'Y', label: 'Ano (AAAA)'}, // YYYY
        {value: 'YY', label: 'Ano (AA)'},
        {value: 'DD-MM-YYYY', label: 'Data (DD-MM-AAAA)'},
        {value: 'DD-MM-YY', label: 'Data (DD-MM-AA)'},
        {value: 'YYYY-MM-DD', label: 'Data (AAAA-MM-DD)'},
        {value: 'YYYYMMDD', label: 'Data (AAAAMMDD)'},
    ];

    const getTemplateById = async (templateId) => {
        if(templateId){
            setLoading(true);
            await axios.post(SERVER_PATH + '/data/templates/one', { templateId })
            .then(res => {
                const foundTemplate = res.data;
                setTemplateTitlePatternSelectedTemplate(foundTemplate);
            })
            .catch(err => {
                console.log(err);
            });
            setLoading(false);
        }
    };

    useEffect(() => {
        let search = queryString.parse(window.location.search);
        const templateId = search.id;
        getTemplateById(templateId);
    }, []);

    useEffect(() => {
        if(selectedTemplate){
            //?todo load template keys
            let knownQuestions = selectedTemplate.form;
            let mapQuestions = knownQuestions.filter(question => !['h', 'heading'].includes(question.type)).map(i => ({value: i.name, label: i.name}));
            set_templateQuestions(mapQuestions);
    
            const currentPattern = selectedTemplate.title;
            const mappedPattern = currentPattern.map((titlePatternElement, titlePatternElementIndex) => ({...titlePatternElement, id: (titlePatternElementIndex + 1).toString()}));
            set_titlePattern(mappedPattern);
        }
    }, [selectedTemplate]);

    useEffect(() => {
        if(selectedTemplate){
            const currentTemplateUserFields = userTemplateFields
            .filter(field => field.template.includes(selectedTemplate._id) && ['string', 'select'].includes(field.type))
            .map(field => ({ value: field.id, label: field.name }));
            setTemplateUserFields(currentTemplateUserFields);
        }
    }, [userTemplateFields]);

    const handle_backDialogOkClick = () => {
        set_backDialogOpen(false);
        handleNavigate(`/${activeClient.shortName}/juridico/documentos/matrizes`);
    };
    const handle_titlePatternSaveClick = async () => {
        setLoading(true);
        let recordCopy = {...selectedTemplate};
        let newRecord = {...selectedTemplate};
        let newValue = titlePattern;
        newRecord.title = newValue;
        updateTemplate(newRecord);
        try {
            await updateOneOperators('Templates', selectedTemplate._id, {title: newValue})
            socket.emit('TEMPLATE UPDATED', { activeClientId: activeClient._id, updatedTemplate: {...selectedTemplate, title: newValue} });
            setLoading(false);
            handleNavigate(`/${activeClient.shortName}/juridico/documentos/matrizes`);
        } catch (error) {
            updateTemplate(recordCopy);
            setLoading(false);
            toast('Erro! As alterações foram desfeitas.');
        }
    };

    const handleTitleElementDragEnd = (data) => {
        set_titlePattern(prevState => {
            return update(prevState, {
                $splice: [
                    [data.source.index, 1],
                    [data.destination.index, 0, prevState[data.source.index]],
                ],
            })
        });
    };

    const handleTitlePatternElementWindowOpen = (titlePatternElement) => {
        setSelectedTitlePatternElement(titlePatternElement);
        setTitlePatternElementWindowOpen(true);
    };

    const handleAddTitlePatternElement = () => {
        setSelectedTitlePatternElement(null);
        setTitlePatternElementWindowOpen(true);
    };

    return (
        <ViewBox>
            <Box>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs>
                        <DragDropContext onDragEnd={handleTitleElementDragEnd}>
                            <Droppable droppableId={'title-pattern-droppable-container'} direction="horizontal">
                                {(provided, snapshot) => (
                                    <Div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        sx={classes.list}
                                        style={snapshot.isDraggingOver ? { backgroundColor: '#C0CCD1' } : {backgroundColor: 'white'}}
                                    >
                                        {
                                            titlePattern.map((titlePatternElement, titlePatternElementIndex) => {
                                                let userTemplateFieldName = '';
                                                if(titlePatternElement.type === 'userTemplateField' && titlePatternElement.value){
                                                    const foundUserTemplateField = templateUserFields.find(field => field.value === titlePatternElement.value);
                                                    if(foundUserTemplateField) userTemplateFieldName = foundUserTemplateField.label;
                                                }
                                                
                                                return (
                                                    <div key={titlePatternElement.id}>
                                                        <Draggable
                                                            draggableId={titlePatternElement.id}
                                                            index={titlePatternElementIndex}
                                                            isDragDisabled={false}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                    <Box sx={classes.elementBox}
                                                                        style={
                                                                            snapshot?.isDragging
                                                                            ? { backgroundColor: '#434343', borderColor: '#434343', color: '#f5f5f5' }
                                                                            : (titlePatternElement.type === 'text' ? null : { backgroundColor: '#bfedd2', borderRadius: 8 })
                                                                        }
                                                                        onClick={() => handleTitlePatternElementWindowOpen(titlePatternElement)}
                                                                    >
                                                                        {
                                                                            titlePatternElement.type === 'text'
                                                                            ?
                                                                            <Typography variant="body1" noWrap>{titlePatternElement.value}</Typography>
                                                                            :
                                                                            titlePatternElement.type === 'date'
                                                                            ?
                                                                            <Typography variant="body1" noWrap>&nbsp;&bull;&nbsp;<em>{dateTitlePatternElementValueOptions.find(option => option.value === titlePatternElement.value).label}</em>&nbsp;&bull;&nbsp;</Typography>
                                                                            :
                                                                            titlePatternElement.type === 'count'
                                                                            ?
                                                                            <Typography variant="body1" noWrap>&nbsp;&bull;&nbsp;<em>Quantidade</em>&nbsp;&bull;&nbsp;</Typography>
                                                                            :
                                                                            titlePatternElement.type === 'question'
                                                                            ?
                                                                            <Typography variant="body1" noWrap>&nbsp;&bull;&nbsp;<em>{Array.isArray(titlePatternElement.value) ? '(descontinuado)' : titlePatternElement.value.questionName}</em>&nbsp;&bull;&nbsp;</Typography>
                                                                            :
                                                                            titlePatternElement.type === 'ifQuestionResponseIs'
                                                                            ?
                                                                            <Typography variant="body1" noWrap>&nbsp;&bull;&nbsp;<em>Lógica "Se"</em>&nbsp;&bull;&nbsp;</Typography>
                                                                            :
                                                                            titlePatternElement.type === 'ifQuestionResponseIsNot'
                                                                            ?
                                                                            <Typography variant="body1" noWrap>&nbsp;&bull;&nbsp;<em>Lógica "Se não"</em>&nbsp;&bull;&nbsp;</Typography>
                                                                            :
                                                                            titlePatternElement.type === 'userTemplateField'
                                                                            ?
                                                                            <Typography variant="body1" noWrap>&nbsp;&bull;&nbsp;<em>Informação especial "{userTemplateFieldName}"</em>&nbsp;&bull;&nbsp;</Typography>
                                                                            :
                                                                            titlePatternElement.type === 'shortId'
                                                                            ? <Typography variant="body1" noWrap>&nbsp;&bull;&nbsp;<em>ID do documento</em>&nbsp;&bull;&nbsp;</Typography>
                                                                            :
                                                                            null
                                                                        }
                                                                    </Box>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    </div>
                                                )
                                            })
                                        }
                                        {provided.placeholder}
                                    </Div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Grid>
                </Grid>
            </Box>
            <Box mb={2}>
                <Grid container>
                    <Grid item>
                        <Button startIcon={<AddIcon />} onClick={handleAddTitlePatternElement}>Elemento</Button>
                    </Grid>
                    <Grid item xs></Grid>
                    <Grid item>
                        <Button disabled={loading || titlePattern.length === 0} variant="contained" color="primary" startIcon={<SaveAltIcon />} onClick={handle_titlePatternSaveClick}>Salvar</Button>
                    </Grid>
                </Grid>
            </Box>
            <AlertDialog
                id="title-pattern-section-back-dialog"
                open={backDialogOpen} onClose={() => set_backDialogOpen(false)}
                text={`Você quer sair sem salvar?`}
                okButtonOnClick={handle_backDialogOkClick}
            />
            <TitlePatternElementWindow
                open={titlePatternElementWindowOpen} setOpen={setTitlePatternElementWindowOpen} selectedTitlePatternElement={selectedTitlePatternElement}
                dateTitlePatternElementValueOptions={dateTitlePatternElementValueOptions}
                setTitlePattern={set_titlePattern}
                templateQuestions={templateQuestions}
                templateUserFields={templateUserFields}
            />
        </ViewBox>
    );
}

export default TitlePattern;