import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CNPJ_REGEXP, SERVER_PATH } from '../utils/constants';
import InputMask from 'react-input-mask';
import axios from 'axios';

const FoundCorporationIdError = ({message}) => (
    <Box>
        <Grid container spacing={1} alignItems="center" justifyContent="center" wrap="nowrap">
            <Grid item>
                <CancelIcon htmlColor="red" fontSize="small" />
            </Grid>
            <Grid item>
                <Typography variant="body1" style={{color:  'red'}}>{message}</Typography>
            </Grid>
        </Grid>
    </Box>
)

const CorporationIdQuestion = ({
    completeFieldsFromCNPJ, foundCNPJs, foundCorporationId, setFoundCorporationId, initialValue, onChange, question, setQuestionNameToCNPJ, setQuestionsStatus
}) => {
    const defaultValue = { input: '' };
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        setValue(initialValue || defaultValue);
    }, [initialValue]);

    useEffect(() => {
        if(onChange) onChange(question.name, value);
        if(foundCNPJs && setQuestionsStatus && completeFieldsFromCNPJ && setQuestionNameToCNPJ){
            const questionValid = value.input && CNPJ_REGEXP.test(value.input);
            getCNPJ(value.input, questionValid);
        }
    }, [value]);

    const getCNPJ = async (questionValue, questionValid) => {
        if(questionValid && questionValue){
            if(!foundCNPJs[questionValue]){
                setQuestionsStatus(prevState => ({...prevState, [question.name]: 'retrievingData'}));
                await axios.get(SERVER_PATH + `/apis/cnpj-ja/cnpj?cnpj=${questionValue}&simples=true`)
                .then(res => {
                    setQuestionsStatus(prevState => ({...prevState, [question.name]: ''}));
                    const data = res.data;
                    if(data){
                        if(data.status.id === 2){ // Ativa
                            const result = {...data, cnpj: questionValue, selectedLegalRepresentativeIndex: 0};
                            completeFieldsFromCNPJ(result);
                        } else if([3, 4, 8].includes(data.status.id)) { //Suspensa, Inapta, Baixada
                            setQuestionNameToCNPJ(prevState => ({...prevState, [question.name]: null}));
                            setFoundCorporationId('inactive');
                        }
                    } else {
                        // CNPJ not found, so reset CNPJ data
                        setQuestionNameToCNPJ(prevState => ({...prevState, [question.name]: null}));
                        setFoundCorporationId('false');
                    }
                })
                .catch(err => {
                    console.log(err);
                    setQuestionsStatus(prevState => ({...prevState, [question.name]: ''}));
                    // error retrieving CNPJ, so reset CNPJ data
                    //?TODO testar não encontrado vs. erro
                    setQuestionNameToCNPJ(prevState => ({...prevState, [question.name]: null}));
                    setFoundCorporationId('error');
                });
            } else {
                completeFieldsFromCNPJ(foundCNPJs[questionValue]);
            }
        } else {
            // question empty or not valid, so reset CNPJ data
            setQuestionNameToCNPJ(prevState => ({...prevState, [question.name]: null}));
            setFoundCorporationId('');
        }
    };

    const handleChange = (newValue) => {
        setValue(prevState => ({...prevState, input: newValue}));
    };
    
    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs>
                    <InputMask
                        mask="99.999.999/9999-99"
                        value={value.input}
                        onChange={(e) => handleChange(e.target.value)}
                        maskChar=""
                    >
                        {() => (
                            <TextField variant="standard" fullWidth placeholder="Sua resposta" />
                        )}
                    </InputMask>
                </Grid>
                {
                    foundCorporationId !== '' &&
                    <Grid item xs={12} sm={6} md={4}>
                        {
                            foundCorporationId === 'active'
                            ?
                            <Box>
                                <Grid container spacing={1} alignItems="center" justifyContent="center" wrap="nowrap">
                                    <Grid item>
                                        <CheckCircleIcon htmlColor="green" fontSize="small" />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1" style={{color:  'green'}}>O CNPJ foi encontrado na Receita Federal</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            :
                            foundCorporationId === 'inactive'
                            ? <FoundCorporationIdError message="Esse CNPJ está inativo" />
                            :
                            foundCorporationId === 'false'
                            ? <FoundCorporationIdError message="Esse CNPJ não foi encontrado na Receita Federal" />
                            :
                            <FoundCorporationIdError message="Não foi possível realizar a consulta na Receita Federal." />
                        }
                    </Grid>
                }
            </Grid>
        </Box>
    );
};

export default CorporationIdQuestion;