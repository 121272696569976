import React, { useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../utils/constants';
import { useAppCtxAPI, useAppCtxLoading } from '../context/SystemContext';
import axios from 'axios';

const ChangePassword = ({open, setOpen}) => {
    const { setActiveUser, setLoading, toast } = useAppCtxAPI();
    const loading = useAppCtxLoading();
    const id = 'change-password-dialog';
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const [passwordFieldType, set_passwordFieldType] = useState('password');
    const [password1, set_password1] = useState('');
    const [password2, set_password2] = useState('');

    const handle_okClick = () => {
        let password1 = ref1.current.value;
        let regExpTest = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&\-_.])[A-Za-z\d@$!%*?&\-_.]{8,}$/.test(password1)
        if(!regExpTest){
            toast('Sua senha deve respeitar os requisitos de segurança.');
        } else {
            let password2 = ref2.current.value;
            if(!password1 || password1 !== password2){
                toast('Você deve indicar a mesma senha nos dois campos.');
            } else {
                setLoading(true);
                setOpen(false);
                axios.post('/data/profile/password/update', {pw: password1})
                .then(res => {
                    setActiveUser(res.data);
                    setLoading(false);
                    toast(SUCCESS_MESSAGE_SAVED);
                })
                .catch(err => {
                    setLoading(false);
                    toast(ERROR_MESSAGE_UNKNOWN);
                });
            }
        }
    };

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby={id}
            TransitionProps={{
                onEnter: () => { setLoading(false); }
            }}>
            <DialogTitle
                id={id}
            >Alterar senha</DialogTitle>
            <DialogContent>
                <DialogContentText>Crie a sua nova senha nos campos abaixo.</DialogContentText>
                <Box mb={1}>
                    <TextField
                        variant="standard"
                        inputProps={{ref: ref1}}
                        margin="dense"
                        label="Nova senha"
                        type={passwordFieldType}
                        onError={() => console.log('error')}
                        value={password1}
                        onChange={(e) => set_password1(e.target.value)}
                        onKeyPress={(e) => {if(e.key === 'Enter') handle_okClick()}}
                        InputProps={{
                            endAdornment: (
                                <IconButton size="small" onClick={() => set_passwordFieldType(prevState => prevState === 'password' ? 'text' : 'password')}>
                                    {passwordFieldType === 'password' ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                            )
                        }}
                        fullWidth />
                </Box>
                <Box mb={1}>
                    <TextField
                        variant="standard"
                        inputProps={{ref: ref2}}
                        margin="dense"
                        label="Repetir a nova senha"
                        type={passwordFieldType}
                        value={password2}
                        onChange={(e) => set_password2(e.target.value)}
                        onKeyPress={(e) => {if(e.key === 'Enter') handle_okClick()}}
                        InputProps={{
                            endAdornment: (
                                <IconButton size="small" onClick={() => set_passwordFieldType(prevState => prevState === 'password' ? 'text' : 'password')}>
                                    {passwordFieldType === 'password' ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                            )
                        }}
                        fullWidth />
                </Box>
                <Box mb={1}>
                    {/(?=.*[a-z])/.test(password1)
                        ?
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <CheckCircleOutlineIcon style={{color: 'darkgreen'}} />
                            </Grid>
                            <Grid item xs>
                                <Typography variant="body2" style={{color: 'darkgreen'}}>A senha tem pelo menos 1 letra minúscula</Typography>
                            </Grid>
                        </Grid>
                        :
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <HighlightOffIcon color="error" />
                            </Grid>
                            <Grid item xs>
                                <Typography variant="body2" color="error">A senha deve ter pelo menos 1 letra minúscula</Typography>
                            </Grid>
                        </Grid>
                    }
                    {/(?=.*[A-Z])/.test(password1)
                        ?
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <CheckCircleOutlineIcon style={{color: 'darkgreen'}} />
                            </Grid>
                            <Grid item xs>
                                <Typography variant="body2" style={{color: 'darkgreen'}}>A senha tem pelo menos 1 letra maiúscula</Typography>
                            </Grid>
                        </Grid>
                        :
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <HighlightOffIcon color="error" />
                            </Grid>
                            <Grid item xs>
                                <Typography variant="body2" color="error">A senha deve ter pelo menos 1 letra maiúscula</Typography>
                            </Grid>
                        </Grid>
                    }
                    {/(?=.*\d)/.test(password1)
                        ?
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <CheckCircleOutlineIcon style={{color: 'darkgreen'}} />
                            </Grid>
                            <Grid item xs>
                                <Typography variant="body2" style={{color: 'darkgreen'}}>A senha tem pelo menos 1 número</Typography>
                            </Grid>
                        </Grid>
                        :
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <HighlightOffIcon color="error" />
                            </Grid>
                            <Grid item xs>
                                <Typography variant="body2" color="error">A senha deve ter pelo menos 1 número</Typography>
                            </Grid>
                        </Grid>
                    }
                    {/(?=.*[@$!%*?&\-_.])/.test(password1)
                        ?
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <CheckCircleOutlineIcon style={{color: 'darkgreen'}} />
                            </Grid>
                            <Grid item xs>
                                <Typography variant="body2" style={{color: 'darkgreen'}}>A senha tem pelo menos 1 caractere especial</Typography>
                            </Grid>
                        </Grid>
                        :
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <HighlightOffIcon color="error" />
                            </Grid>
                            <Grid item xs>
                                <Typography variant="body2" color="error">A senha deve ter pelo menos 1 caractere especial</Typography>
                            </Grid>
                        </Grid>
                    }
                    {/.{8,}/.test(password1)
                        ?
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <CheckCircleOutlineIcon style={{color: 'darkgreen'}} />
                            </Grid>
                            <Grid item xs>
                                <Typography variant="body2" style={{color: 'darkgreen'}}>A senha tem 8 caracteres ou mais</Typography>
                            </Grid>
                        </Grid>
                        :
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <HighlightOffIcon color="error" />
                            </Grid>
                            <Grid item xs>
                                <Typography variant="body2" color="error">A senha deve ter no mínimo 8 caracteres</Typography>
                            </Grid>
                        </Grid>
                    }
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancelar</Button>
                <Button disabled={loading} onClick={handle_okClick} variant="outlined" color="primary">Ok</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ChangePassword;