import { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import { Box, Button, Collapse, Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import moment from 'moment';
import ActionMenu from '../components/ActionMenu';
import AddDocumentWindow from '../../../../components/AddDocumentWindow';
import CancelScheduledSendDialog from './components/CancelScheduledSendDialog';
import ChangeDocumentFolderMenu from '../../../../components/ChangeDocumentFolderMenu';
import ChangeDocumentGroupWindow from '../../../../components/ChangeDocumentGroupWindow';
import ChangeReviewerMenu from '../../../../components/ChangeReviewerMenu';
import CommentWindow from '../../../../components/CommentWindow';
import DeleteDocDialog from '../../../../components/DeleteDocDialog';
import DocFormView from '../../../../components/DocFormView';
import FlagDocumentWindow from '../../../../components/Documents/FlagDocumentWindow';
import HistoryPopOver from '../../../../components/HistoryPopOver';
import LoaderEllipsis from '../../../../components/LoaderEllipsis';
import OperatorDocumentsSinglePageFilters from '../components/OperatorDocumentsSinglePageFilters';
import PendingDocumentsList from './components/PendingDocumentsList';
import SubmitDocDialog from '../../../../components/SubmitDocDialog';
import UpdateNameDialog from '../../../../components/UpdateNameDialog';
import ViewBox from '../../../../components/ViewBox';
import { FIRST_CLIENT_ID } from '../../../../utils/constants';
import { useSocket, useSocketCtxConnectedAt } from '../../../../context/SocketContext';
import { useClientCtxActiveClient } from '../../../../context/ClientContext';
import { useDocumentsAPI, useDocumentsCtxLoadingPendingDocuments, useDocumentsCtxPendingDocuments, useDocumentsCtxPendingDocumentsCount } from '../../../../context/DocumentsContext';
import { useOperatorCtxOperators, useOperatorCtxProjects, useOperatorCtxUsers } from '../../context/OperatorContext';
import { getProjectNameById } from '../../../../utils/common';
import { sortByKey } from '../../../../utils/filters';

const Docs = () => {
    const { shouldUpdatePendingDocuments } = useSocket();
    const socketConnectedAt = useSocketCtxConnectedAt();
    const activeClient = useClientCtxActiveClient();
    const { dispatch: dispatchDocuments, setState: setDocumentsCtxState, showAddDocumentView, showCommentView } = useDocumentsAPI(); 
    const loadingPendingDocuments = useDocumentsCtxLoadingPendingDocuments();
    const pendingDocuments = useDocumentsCtxPendingDocuments();
    const pendingDocumentsCount = useDocumentsCtxPendingDocumentsCount();
    const operators = useOperatorCtxOperators();
    const projects = useOperatorCtxProjects();
    const { users } = useOperatorCtxUsers();
    const [saving, setSaving] = useState(false);
    const [clientOptions, set_clientOptions] = useState([]);
    const [selectedClient, set_selectedClient] = useState('all');
    const [projectOptions, set_projectOptions] = useState([]);
    const [selectedProject, set_selectedProject] = useState('all');
    const [documentProjects, set_documentProjects] = useState([]);
    const [monthNumberOfDocumentsGenerated, set_monthNumberOfDocumentsGenerated] = useState(0);
    const defaultFilters = { text: '' };
    const [filters, setFilters] = useState(defaultFilters);
    const [numberOfVisibleDocuments, setNumberOfVisibleDocuments] = useState(0);

    const setSelectedReviewer = (newValue) => {
        setDocumentsCtxState('selectedPendingDocumentsReviewer', newValue);
    };

    useEffect(() => {
        if(projects && shouldUpdatePendingDocuments){
            dispatchDocuments({type: 'LOAD PENDING DOCUMENTS'});
        }
    }, [projects, socketConnectedAt]);

    useEffect(() => {
        let numberOfDocumentsGenerated = 0;
        if(activeClient){
            const monthTotals = activeClient.monthTotals;
            if(monthTotals){
                const year = moment().year();
                if(monthTotals[year]){
                    const month = moment().month();
                    if(monthTotals[year][month]) numberOfDocumentsGenerated = monthTotals[year][month].numberOfDocumentsGenerated || 0;
                }
            }
        }
        set_monthNumberOfDocumentsGenerated(numberOfDocumentsGenerated);
    }, [activeClient]);

    useEffect(() => {
        if(pendingDocuments){
            let currentClientOptions = [];
            let currentDocumentProjects = [];
            pendingDocuments.forEach(document => {
                if(!currentClientOptions.includes(document.client)) currentClientOptions.push(document.client);
                if(document.project && !currentDocumentProjects.includes(document.project)) currentDocumentProjects.push(document.project);
            });
            currentClientOptions = currentClientOptions.map(projectId => ({value: projectId, label: getProjectNameById(projects, projectId)})).sort(sortByKey('label'));
            set_clientOptions(currentClientOptions);
            set_documentProjects(currentDocumentProjects);
        }
    }, [pendingDocuments]);

    useEffect(() => {
        if(documentProjects.length >= 1){
            getProjectOptions();
        }
    }, [documentProjects]);

    const getProjectOptions = (clientId) => {
        let currentProjectOptions = documentProjects;
        if(clientId && clientId !== 'all'){
            currentProjectOptions = currentProjectOptions.filter(projectId => {
                let project = projects.find(project => project._id === projectId);
                if(project) return project.client === clientId;
                return false;
            });
        }
        currentProjectOptions = currentProjectOptions.map(projectId => ({value: projectId, label: getProjectNameById(projects, projectId)})).sort(sortByKey('label'));;
        set_projectOptions(currentProjectOptions);
    };

    const handle_addDocumentClick = () => {
        showAddDocumentView(projects);
    };
    
    const filter = () => {
        
    };
    
    const handleClientChange = (newValue) => {
        set_selectedClient(newValue);
        getProjectOptions(newValue);
        set_selectedProject('all');
    };

    const handleReadComment = (comment) => {
        showCommentView(comment);
    };

    const handleSentDocument = () => {
        // filter();
    };

    const clearFilters = () => {
        set_selectedClient('all');
        set_selectedProject('all');
        setSelectedReviewer('~all;');
        setFilters(defaultFilters);
    };

    const addPendingDocument = (data) => {
        dispatchDocuments({type: 'ADD PENDING DOCUMENT', payload: data});
    };

    const deletePendingDocument = (data) => {
        dispatchDocuments({type: 'DELETE PENDING DOCUMENT', payload: data});
    };

    const updatePendingDocument = (data) => {
        dispatchDocuments({type: 'UPDATE PENDING DOCUMENT', payload: data});
    };

    return (
        <ViewBox>
            <Box mb={2}>
                <Grid container alignItems="center" justifyContent="center" spacing={1}>
                    <OperatorDocumentsSinglePageFilters
                        filter={filter}
                        filters={filters} setFilters={setFilters}
                        clearFilters={clearFilters}
                        clientOptions={clientOptions}
                        handleClientChange={handleClientChange}
                        loading={loadingPendingDocuments}
                        numberOfDocuments={numberOfVisibleDocuments}
                        projectOptions={projectOptions}
                        selectedClient={selectedClient}
                        selectedProject={selectedProject} set_selectedProject={set_selectedProject}
                    />
                    <Grid item xs container alignItems="flex-end" justifyContent="flex-end">
                        <Grid item>
                            <Button disabled={loadingPendingDocuments} variant="contained" color="primary" startIcon={<AddIcon />} onClick={handle_addDocumentClick}>Adicionar documento</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Collapse in={activeClient && activeClient._id !== FIRST_CLIENT_ID && !!activeClient.documentsGeneratedMonthLimit && monthNumberOfDocumentsGenerated && (monthNumberOfDocumentsGenerated >= (activeClient.documentsGeneratedMonthLimit * 0.8))}>
                <Box mb={1}>
                    <Alert variant="filled" severity="error" onClose={() => set_monthNumberOfDocumentsGenerated(0)}>Você já consumiu {monthNumberOfDocumentsGenerated} do limite de {activeClient?.documentsGeneratedMonthLimit} contratos mensais.</Alert>
                </Box>
            </Collapse>
            {
                pendingDocumentsCount === 0 &&
                <Box mb={2}>
                    <Grid container spacing={1} alignItems="center" justifyContent="center">
                        <Grid item>
                            <StarBorderIcon />
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">Parabéns! Não há contratos em aberto.</Typography>
                        </Grid>
                    </Grid>
                </Box>
            }
            {
                loadingPendingDocuments &&
                <LoaderEllipsis primary />
            }

            <PendingDocumentsList
                filters={filters} setFilters={setFilters}
                saving={saving}
                selectedClient={selectedClient}
                selectedProject={selectedProject}
                setNumberOfVisibleDocuments={setNumberOfVisibleDocuments}
            />

            <ChangeReviewerMenu
                operators={operators}
                updateDocument={updatePendingDocument}
                users={users}
            />
            <ActionMenu
                deleteDocument={deletePendingDocument}
                updateDocument={updatePendingDocument}
            />
            <SubmitDocDialog
                actionDoneCallback={handleSentDocument} 
                setSaving={setSaving}
                updateDocument={updatePendingDocument}
            />
            <HistoryPopOver
                updateDocument={updatePendingDocument}
                users={users}
                handleReadComment={handleReadComment}
            />
            <DocFormView />
            <DeleteDocDialog updateDocument={updatePendingDocument} />
            <UpdateNameDialog updateDocument={updatePendingDocument} />
            <ChangeDocumentFolderMenu
                projects={projects}
                updateDocument={updatePendingDocument}
            />
            <ChangeDocumentGroupWindow
                projects={projects}
                updateDocument={updatePendingDocument}
            />
            <AddDocumentWindow
                activeUserIsOperator
                addDocument={addPendingDocument}
            />
            <CommentWindow users={users} />

            <FlagDocumentWindow
                activeUserIsOperator
                updateDocument={updatePendingDocument}
            />
            <CancelScheduledSendDialog />
        </ViewBox>
    );
}

export default Docs;