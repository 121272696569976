import axios from 'axios';

class AuthService {
    login = (serverPath, id, password, remember) => {
        return new Promise((resolve, reject) => {
            axios.post(serverPath + '/auth', {id, password, remember},
            {
                withCredentials: true
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err);
            });
        });
    };
    isAuthenticated = (serverPath) => {
        return new Promise((resolve, reject) => {
            axios.get(serverPath + `/auth/session`, {
                withCredentials: true
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err);
            })
        });
    };
}

const authService = new AuthService();

export default authService;