import { colors } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { typography } from './typography';

const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: colors.indigo[900],
            dark: '#30426a',
            light: colors.indigo[500]
        },
        secondary: {
            main: '#9b2520',
            dark: colors.red[900],
            light: colors.red[900]
        },
        background: {
            default: '#fbfbfc',
            darker: '#C0CCD1',
            dark: '#434343',
            paper: colors.common.white,
            transparency: 'rgba(200, 200, 200, 0.2)'
        },
        text: {
            primary: colors.grey[900],
            secondary: '#434343'
        },
        special: {
            highlight: colors.teal[100]
        },
        details: '#30426a'
    },
    typography
});

export default theme;