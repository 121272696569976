import { useEffect, useMemo, useState } from 'react';
import { Box, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ItemWindow from './components/ItemWindow';
import ListWindow from './components/ListWindow';
import MenuChip from '../../../../components/MenuChip';
import MultilineText from '../../../../components/MultilineText';
import Select from '../../../../components/Select';
import Table2 from '../../../../components/Table2/Table';
import Table2CustomTopToolbar from '../../../../components/Table2/components/Table2CustomTopToolbar';
import ViewBox from '../../../../components/ViewBox';
import { useAppCtxAPI, useAppCtxActiveUser } from '../../../../context/SystemContext';
import { useSocket } from '../../../../context/SocketContext';
import { useClient } from '../../../../context/ClientContext';
import { serverRequest } from '../../../../utils/common';

function Lists(){ 
    const { setLoading, toast } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const { createSocketConnection } = useSocket();
    const socket = createSocketConnection();
    const { lists, setLists } = useClient();
    const [listOptions, setListOptions] = useState([]);
    const [selectedListId, set_selectedListId] = useState('');
    const [selectedList, set_selectedList] = useState(null);
    const [selectedListName, setSelectedListName] = useState('');
    const [itemWindowOpen, set_itemWindowOpen] = useState(false);
    const [selectedItem, set_selectedItem] = useState(null);
    const [listWindowOpen, set_listWindowOpen] = useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        const currentLists = lists.map(list => ({value: list.shortName, label: list.name}));
        setListOptions(currentLists);
        if(currentLists[0]) set_selectedListId(currentLists[0].value);
    }, [lists]);

    useEffect(() => {
        if(selectedListId){ 
            const foundList = lists.find(list => list.shortName === selectedListId);
            if(foundList) set_selectedList(foundList.list);
            setSelectedListName(foundList?.name || '');
        } else {
            set_selectedList(null);
        }
    }, [lists, selectedListId]);

    const handleListWindowOpenClick = (listId) => {
        set_selectedListId(listId);
        set_listWindowOpen(true);
    };
    const handleItemWindowOpenClick = (item) => {
        set_selectedItem(item);
        set_itemWindowOpen(true);
    };
    
    const handleDeleteListItemButtonPress = async (itemId) => {
        if(itemId){
            if(window.confirm('Tem certeza?')){
                setLoading(true);
                try {
                    const data = await serverRequest({path: `/data/lists/one/${selectedListId}/one/${itemId}`, method: 'delete'})
                    setLists(data.lists);
                    socket.emit('CLIENT LISTS UPDATED', { updatedClient: data });
                } catch (error) {
                    toast(error);
                }
                setLoading(false);
            }
        }
    };

    const handleMenuOpen = (e) => {
        setMenuAnchorEl(e.currentTarget);
        setMenuOpen(true);
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: 'key',
                filterVariant: 'text',
                header: 'Nome',
            },
            {
                id: 'aliases',
                accessorFn: (row) => {
                    const aliases = row.aliases || [];
                    return aliases.join('');
                },
                Cell: ({ row }) => {
                    const aliases = row.original.aliases || [];
                    return (
                        <Box>
                            {aliases.map((alias, aliasIndex) => (<Typography variant="body2" key={aliasIndex}>{alias}</Typography>))}
                        </Box>
                    );
                },
                filterVariant: 'text',
                header: 'Variações',
            },
            {
                accessorKey: 'value',
                Cell: ({ cell }) => (
                    <Typography variant="body2"><MultilineText text={cell.getValue()} breaker="<br />" /></Typography>
                ),
                filterVariant: 'text',
                header: 'Descrição',
            },
        ],
        [],
    );

    const handleListChange = (newValue) => {
        set_selectedListId(newValue);
        setMenuOpen(false);
    };

    const getSelectedListLabelById = (listId) => {
        const foundList = listOptions.find(listOption => listOption.value === listId);
        return foundList?.label || '';
    };
    const renderTopToolbar = ({ table }) => {
        
        const handleAddListItemClick = () => {
            handleItemWindowOpenClick(null);
            // showAddTemplateView();
        };

        const handleAddListClick = () => {
            handleListWindowOpenClick(null);
        };
    
        return (
            <Table2CustomTopToolbar
                buttons={[
                    <MenuChip
                        minWidth={194.28}
                        label="Lista"
                        onPress={(e) => handleMenuOpen(e)}
                        value={selectedListId} valueLabel={getSelectedListLabelById(selectedListId)}
                    />,
                    <Button variant="contained" minWidth={142.65} onClick={handleAddListClick} startIcon={<AddIcon />}>Nova Lista</Button>,
                    selectedListId && <Button disabled={selectedListId === 'special_list_audiovisual_services' && activeUser.type < 9} variant="contained" minWidth={141.28} onClick={handleAddListItemClick} startIcon={<PlaylistAddIcon />}>Novo item</Button>,
                ]}
                table={table}
            />
        );
    };

    const renderRowActionMenuItems = ({ closeMenu, row }) => {
        const selectedListItem = row.original;

        const handleChangeListItemClick = () => {
            handleItemWindowOpenClick(selectedListItem);
            closeMenu();
        };

        const handleDeleteListItemClick = () => {
            handleDeleteListItemButtonPress(selectedListItem.id);
            closeMenu();
        };

        return [
            <MenuItem
                key={0}
                onClick={handleChangeListItemClick}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <EditIcon />
                </ListItemIcon>
                <ListItemText primary="Alterar configurações gerais" />
            </MenuItem>,
            activeUser.type >= 9 &&
            <MenuItem
                key={1}
                onClick={handleDeleteListItemClick}
            >
                <ListItemIcon><DeleteForeverIcon /></ListItemIcon>
                <ListItemText primary="Excluir" />
            </MenuItem>
        ]
    };

    return (
        <ViewBox additionalSx={[{ height: '100%' }]}>
            
            <Table2
                columns={columns}
                data={selectedList || []}
                enablePagination={false}
                enableRowVirtualization
                initialStateDensity="comfortable"
                isLoading={!lists}
                renderRowActionMenuItems={renderRowActionMenuItems}
                renderTopToolbar={renderTopToolbar}
            />

            <ItemWindow
                open={itemWindowOpen} setOpen={set_itemWindowOpen}
                selectedListName={selectedListName}
                data={{
                    selectedItem, set_selectedItem, selectedListId, selectedList, set_selectedList
                }}
            />

            <ListWindow
                open={listWindowOpen} setOpen={set_listWindowOpen}
                data={{
                    selectedItem, set_selectedItem, selectedListId, selectedList, set_selectedList
                }}
            />

            <Menu
                id="simple-menu"
                anchorEl={menuAnchorEl}
                keepMounted
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
            >
                {
                    listOptions.map((option, optionIndex) => (
                        <MenuItem
                            key={optionIndex}
                            onClick={() => handleListChange(option.value)}
                            selected={option.value === selectedListId}
                        >{option.label}</MenuItem>
                    ))
                }
            </Menu>

        </ViewBox>
    );
}

export default Lists;