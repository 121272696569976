const special = {
    createEffect: ({ effectSettings, specialEffects }) => {
        const foundSpecialEffect = specialEffects.find(specialEffect => specialEffect.value === effectSettings.special);
        const newEffect = {
            id: effectSettings.special,
            type: 'special',
            question: effectSettings.question || effectSettings.label || '',
            label: foundSpecialEffect.label
        };
        return { newEffect };
    },
    newEffectWindowHandleEffectTypeChange: () => {
        const data = {};
        return data;
    },
    getOutputsContent: (data) => {
        let content = '';
        if(data){
            const { scannedEffect } = data;
            if(scannedEffect.special === 'customList'){
                scannedEffect.options.forEach((scannedEffectOption) => {
                    content += scannedEffectOption.output || '';
                });
            }
        }
        return content;
    },
    getFormEditorQuestion: (data) => {
        if(data){
            const { effect, question, special_list_audiovisual_services } = data;
            const formEditorQuestion = {...question};
            formEditorQuestion.special = effect.special;

            if(formEditorQuestion.special === 'customList'){
                formEditorQuestion.options = special_list_audiovisual_services.map(i => ({choice: i.key, label: i.key}));
            }

            return formEditorQuestion;
        }
        return false;
    },
    checkValidation: (data) => {
        let questionValid = false;
        if(data){
            questionValid = true;
            const { input, userNotAwareOfCorporationIdActivityError, userNotAwareOfMEIActivityError } = data;
            if(!input || userNotAwareOfCorporationIdActivityError || userNotAwareOfMEIActivityError) questionValid = false;
        }
        return questionValid;
    }
};

export {
    special
}