import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

const classes = {
    appBar: {
        backgroundColor: theme => theme.palette.background.dark,
        color: '#e8e8e8'
    },
    drawer: {
        padding: theme => theme.spacing(2),
        '& > *': {
            marginBottom: theme => theme.spacing(1)
        },
    },
    drawerSide: {
        maxWidth: ['80vw', '50vw', '34vw']
    },
    title: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
};

function StyledDrawer({anchor, children, sx, maxWidth, minWidth, onClose, open, style = {}, title, variant}){
    style = {
        ...style,
        minWidth: minWidth || 278,
    };
    if(maxWidth) style.maxWidth = maxWidth;

    const contentClasses = [classes.drawer];
    if(!anchor) contentClasses.push(classes.drawerSide);

    return (
        <Drawer anchor={anchor || 'right'} open={open} onClose={onClose} variant={variant} sx={sx}>
            {
                title &&
                <AppBar sx={classes.appBar} position="static" style={{padding: '8px 0', ...style}}>
                    <Toolbar><Typography variant="h2" sx={classes.title}>{title}</Typography></Toolbar>
                </AppBar>
            }
            <Box sx={contentClasses} style={style}>
                {children}
            </Box>
        </Drawer>
    );
}

export default StyledDrawer;