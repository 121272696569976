import Header from '../../../../components/Header';
import OnlineUsers from '../../../../components/OnlineUsers';
import { FIRST_CLIENT_ID } from '../../../../utils/constants';
import { useAppCtxUserLocation } from '../../../../context/SystemContext';
import { useClientCtxActiveClient } from '../../../../context/ClientContext';
import { useOperatorCtxAPI, useOperatorCtxSidenavMobile, useOperatorCtxUsers } from '../../context/OperatorContext';
import { Avatar, Box, Breadcrumbs, Fade, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Account from '../../Account';
import BackgroundLetterAvatar from '../../../../components/BackgroundLetterAvatar/BackgroundLetterAvatar';
import { styled } from "@mui/material/styles";

const StyledBreadcrumbs = styled(Breadcrumbs)`
    .MuiBreadcrumbs-ol {
        flex-wrap: nowrap;
    }
`;

const OperatorHeader = () => { 
    const { userLocationBreadcrumbs } = useAppCtxUserLocation();
    const activeClient = useClientCtxActiveClient();
    const { setState: setOperatorCtxState } = useOperatorCtxAPI();
    const { users } = useOperatorCtxUsers();
    const { sidenavMobileOpen } = useOperatorCtxSidenavMobile();

    const set_sidenavMobileOpen = (newValue) => {
        setOperatorCtxState('sidenavMobileOpen', newValue);
    };

    return (
        <Header
            leftBox={
                <Grid container spacing={1} wrap="nowrap">
                    <Grid display="flex" alignItems="center">
                        {
                            activeClient &&
                            <>
                                {
                                    activeClient.logo
                                    ?
                                    <>
                                        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                            {/* <Avatar
                                                src={activeClient?._id === FIRST_CLIENT_ID ? '/uploads/logos/logo-DN-small.png' : activeClient.logo} alt={activeClient.name}
                                                sx={{
                                                    backgroundColor: theme => theme.palette.background.paper,
                                                    boxShadow: '0 0 8px rgba(0, 0, 0, 0.08)',
                                                    width: 50, height: 50,
                                                    '& img': { objectFit: 'scale-down' }
                                                }}
                                            /> */}
                                            <Fade in={!!activeClient?.logo} timeout={1000}>
                                                <img src={activeClient?.logo} style={{maxHeight: 34}} />
                                            </Fade>
                                        </Box>
                                        <Box
                                            style={{
                                                width: 66,
                                                overflow: 'hidden'
                                            }}
                                            sx={{ display: { xs: 'block', sm: 'none' } }}
                                        >
                                            <Fade in={!!activeClient?.logo} timeout={1000}>
                                                <img src={activeClient?.logo} style={{maxHeight: 34, maxWidth: activeClient?._id === FIRST_CLIENT_ID ? undefined : 66}} />
                                            </Fade>
                                        </Box>
                                    </>
                                    :
                                    <BackgroundLetterAvatar name={activeClient.screenName || activeClient.name || ''} />
                                }
                            </>
                        }
                    </Grid>
                    <Grid xs
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                        }}
                        display="flex" alignItems="center"
                    >
                        <Box
                            sx={{
                                '&::-webkit-scrollbar': {
                                    height: '3px'
                                },
                                overflowX: 'auto'
                            }}
                        >

                            <StyledBreadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                                <Typography></Typography>
                                {
                                    userLocationBreadcrumbs.map((breadcrumb, breadcrumbIndex) => {
                                        let breadcrumbLabel = breadcrumb;
                                        if(typeof breadcrumb !== 'string') breadcrumbLabel = breadcrumb.label;
                                        return (
                                            <Typography key={breadcrumbIndex} variant="h1" noWrap>{breadcrumbLabel}</Typography>
                                        )
                                    })
                                }
                            </StyledBreadcrumbs>

                        </Box>
                    </Grid>
                </Grid>
            }
            onlineUsers={
                <OnlineUsers users={users} />
            }
            account={<Account />}
            data={{
                sidenavMobileOpen, set_sidenavMobileOpen
            }}
        />
    );
};

export default OperatorHeader;