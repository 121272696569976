import { Box, Typography } from '@mui/material';

const classes = {
    question: {
        padding: '24px',
        borderRadius: '8px',
        marginBottom: '16px',
        border: '1px solid #dadce0',
        opacity: 1,
        height: 'auto',
        overflow: 'hidden',
        backgroundColor: theme => theme.palette.background.paper,
        WebkitTransition: 'max-height .5s ease-out, opacity .5s ease-out, padding-top .5s ease-out, margin-top .5s ease-out, padding-bottom .5s ease-out, margin-bottom .5s ease-out',
        MozTransition: 'max-height .5s ease-out, opacity .5s ease-out, padding-top .5s ease-out, margin-top .5s ease-out, padding-bottom .5s ease-out, margin-bottom .5s ease-out',
        msTransition: 'max-height .5s ease-out, opacity .5s ease-out, padding-top .5s ease-out, margin-top .5s ease-out, padding-bottom .5s ease-out, margin-bottom .5s ease-out',
        transition: 'max-height .5s ease-out, opacity .5s ease-out, padding-top .5s ease-out, margin-top .5s ease-out, padding-bottom .5s ease-out, margin-bottom .5s ease-out',
        '&.hidden': {
            maxHeight: 0,
            opacity: 0,
            padding: 0,
            border: 0,
            margin: 0
        }
    },
    notValid: {
        borderColor: '#b71c1c'
    }
};

function QuestionBox({assignedField, children, description, mandatory, name, onBlur, questionElements, questionValid, style, type}){
    return (
        <div
            ref={
                (el) => {
                    if(questionElements && name){
                        questionElements.current[name] = el;
                    }
                }
            }
        >
            <Box
                sx={[classes.question, !questionValid && classes.notValid]}
                style={style}
                data-question-type={type}
                data-question-name={name}
                data-question-mandatory={mandatory}
                data-question-assigned-field={assignedField}
                onBlur={onBlur}
            >
                {
                    name && <Typography variant="h4" color="textPrimary">{name}{ mandatory && <Typography component="span" variant="inherit" color="secondary"> *</Typography> }</Typography>
                }
                {
                    description && <Typography variant="caption">{description}</Typography>
                }
                {children}
            </Box>
        </div>
    )
}

export default QuestionBox;