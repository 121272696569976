import React from 'react';
import axios from 'axios';
import AlertDialog from '../AlertDialog';
import { updateStateAddArrayItem, updateStateDeleteArrayItemBy_id } from '../../utils/common';
import { SERVER_PATH } from '../../utils/constants';
import { useSocket } from '../../context/SocketContext';

const SetDocumentPendingDialog = ({open, setOpen, selectedDocument, setDocuments, loading, setLoading, handleSetMessage}) => {
    const {createSocketConnection} = useSocket();
    const socket = createSocketConnection();
    
    const handleConfirmButtonClick = async () => {
        setOpen(false);

        if(setLoading) setLoading(true);

        const selectedDocumentCopy = {...selectedDocument};
        
        const documentId = selectedDocument._id;

        updateStateDeleteArrayItemBy_id(documentId, setDocuments);
        
        await axios.post(SERVER_PATH + `/data/documents/${documentId}/set/pending`)
        .then(res => {
            const { updatedDocument } = res.data;
            socket.emit('DOCUMENT SENT STATUS CHANGED TO FALSE', { updatedDocument });
            if(handleSetMessage) handleSetMessage(`O documento está disponível na página de documentos em aberto.`);
        })
        .catch(err => {
            updateStateAddArrayItem(selectedDocumentCopy, setDocuments);
        });

        if(setLoading) setLoading(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <AlertDialog
            open={open} onClose={handleClose}
            text={`Você quer reabrir o documento "${selectedDocument?.name}"? O documento será disponibilizado na página de documentos em aberto.`}
            okButtonOnClick={handleConfirmButtonClick}
            disabled={loading}
        />
    );
};

export default SetDocumentPendingDialog;