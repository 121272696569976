const menu = {
    chooseFolder: {
        en: 'Choose folder',
        pt: 'Escolher pasta'
    },
    contracts: {
        en: 'Contracts',
        pt: 'Contratos'
    },
    seeAllContracts: {
        en: 'See all contracts',
        pt: 'Ver todos os contratos'
    },
    completeForm: {
        en: 'Complete form',
        pt: 'Preencher formulário'
    },
    requestContract: {
        en: 'Request contract',
        pt: 'Solicitar a elaboração de um contrato'
    },
    savedForms: {
        en: 'Saved forms',
        pt: 'Formulários salvos'
    },
    continueForm: {
        en: 'Continue form',
        pt: 'Continuar o preenchimento de um formulário'
    },
    supplierRecords: {
        en: 'Supplier records',
        pt: 'Fichas cadastrais'
    },
    seeSupplierRecords: {
        en: 'See supplier records',
        pt: 'Consultar fichas cadastrais'
    },
    requestReview: {
        en: 'Request review',
        pt: 'Solicitar revisão'
    },
    requestDocumentReview: {
        en: 'Request document review',
        pt: 'Solicitar a revisão de um documento'
    },
    alerts: {
        en: 'Alerts',
        pt: 'Alertas'
    },
    seeAllAlerts: {
        en: 'See all alerts',
        pt: 'Ver todos os alertas'
    },
    signatures: {
        en: 'Sign',
        pt: 'Assinar'
    },
    questions: {
        en: 'Questions',
        pt: 'Demandas'
    },
    clearance: {
        en: 'Clearance',
        pt: 'Clearance'
    },
    groups: {
        en: 'Groups',
        pt: 'Grupos'
    },
    managePermissionGroups: {
        en: 'Manage permission groups',
        pt: 'Gerenciar grupos de permissões'
    },
    reports: {
        en: 'Reports',
        pt: 'Relatórios'
    },
    deletedDocuments: {
        en: 'Recycle bin',
        pt: 'Lixeira'
    },
    generateContractReports: {
        en: 'Generate contract reports',
        pt: 'Gerar relatórios de contratos'
    },
    talents: {
        en: 'Talents',
        pt: 'Talento'
    },
    changeDefaultTalentConditions: {
        en: 'Change default talent conditions',
        pt: 'Alterar condições padrão do talento'
    },
    createFolder: {
        en: 'Create folder',
        pt: 'Criar pasta'
    },
    companies: {
        en: 'Companies',
        pt: 'Empresas'
    },
    people: {
        en: 'People',
        pt: 'Pessoas'
    }
};

export {
    menu
};