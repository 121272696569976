import { useEffect, useRef, useState } from 'react';
import FormQuestionRadioGrid from '../form-question-skeleton-components/FormQuestionRadioGrid';
import { radioGrid } from '../settings/documents-templates-radio-grid';
import { useForm } from '../../../pages/Form/context/FormContext';

const RadioGridQuestion = ({question}) => {
    const { initialForm, set_userForm, setFormValidation } = useForm();
    const [value, setValue] = useState(null);
    const touchedField = useRef(false);
    const userFormQuestion = useRef(null);
    const [userFormQuestionUpdated, setUserFormQuestionUpdated] = useState(null);

    useEffect(() => {
        set_userForm(prevState => {
            const nextQuestionState = {
                ...prevState[question.name],
                id: question.id,
                type: 'radioGrid'
            };
            userFormQuestion.current = nextQuestionState;
            return ({
                ...prevState,
                [question.name]: nextQuestionState
            });
        });
        setUserFormQuestionUpdated(new Date());
    }, []);
    
    useEffect(() => {
        if(initialForm[question.name]){
            setValue(initialForm[question.name]);
        }
    }, [initialForm]);

    const checkValidation = () => {
        const questionValid = radioGrid.checkValidation(userFormQuestion.current);
        setFormValidation(prevState => ({...prevState, [question.name]: !questionValid}));
    };
    
    useEffect(() => {
        if((userFormQuestion.current && userFormQuestion.current.choice) || touchedField.current){
            touchedField.current = true;
            checkValidation();
        }
    }, [userFormQuestionUpdated]);

    const handleGroupFocus = () => {
        touchedField.current = true;
    };

    const handleGroupBlur = () => {
        checkValidation();
    };

    const handleChange = (questionName, updates) => {
        set_userForm(prevState => {
            const nextQuestionState = {
                ...prevState[questionName],
                ...updates
            };
            userFormQuestion.current = nextQuestionState;
            return ({
                ...prevState,
                [questionName]: nextQuestionState
            });
        });
        setUserFormQuestionUpdated(new Date());
    };
    
    return (
        <FormQuestionRadioGrid
            onChange={handleChange}
            onBlur={handleGroupBlur}
            onFocus={handleGroupFocus}
            question={question}
            initialValue={value}
        />
    );
};

export default RadioGridQuestion;