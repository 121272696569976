import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import Checkbox from '../../../components/Checkbox';
import Tooltip from '../../../components/Tooltip';
import { v4 as uuidv4 } from 'uuid';

const NewEffect = ({ handleChange, setDisabled }) => {
    const defaultOptionValue = { id: '', option: '', output: '' };
    const [value, setValue] = useState({ question: '', options: [], textOption: { show: false, output: '' } });

    useEffect(() => {
        setValue(prevState => ({...prevState, options: [...prevState.options, {...defaultOptionValue, id: uuidv4()}, {...defaultOptionValue, id: uuidv4()}]}));
    }, []);

    useEffect(() => {
        handleChange(value);
        if(setDisabled){
            let isDisabled = false;
            if(!value.question){
                isDisabled = true;
            } else {
                if(value.options.length === 0){
                    isDisabled = true;
                } else {
                    value.options.forEach(option => {
                        if(!option.option) isDisabled = true;
                    });
                }
            }
            setDisabled(isDisabled);
        }
    }, [value]);

    const handleOptionChange = (optionIndex, newValue) => {
        setValue(prevState => ({...prevState, options: [...prevState.options.slice(0, optionIndex), {...prevState.options[optionIndex], option: newValue}, ...prevState.options.slice(optionIndex + 1)]}));
    };

    const handleOptionDelete = (optionIndex) => {
        setValue(prevState => ({...prevState, options: [...prevState.options.slice(0, optionIndex), ...prevState.options.slice(optionIndex + 1)]}));
    };

    const handleAddOption = () => {
        setValue(prevState => ({...prevState, options: [...prevState.options, {...defaultOptionValue, id: uuidv4()}]}));
    };

    const handleTextOptionCheckedChange = (newValue) => {
        setValue(prevState => ({...prevState, textOption: {...prevState.textOption, show: newValue}}));
    };

    return (
        <Box>
            <Box mb={2}>
                <Box mb={1}>
                    <Typography variant="h6">Pergunta</Typography>
                </Box>
                <TextField
                    variant="standard"
                    fullWidth
                    placeholder="Sua pergunta"
                    value={value.question}
                    onChange={(e) => setValue(prevState => ({...prevState, question: e.target.value}))} />
            </Box>
            <Box>
                {
                    value.options.map((option, optionIndex) => (
                        <Box key={optionIndex} mb={1}>
                            <Grid container alignItems="flex-end" spacing={1}>
                                <Grid item xs>
                                    <TextField
                                        variant="standard"
                                        fullWidth
                                        label={`Opção ${optionIndex + 1}`}
                                        value={option.option}
                                        onChange={(e) => handleOptionChange(optionIndex, e.target.value)} />
                                </Grid>
                                <Grid item>
                                    <Tooltip text={`Excluir opção ${optionIndex + 1}`}>
                                        <IconButton size="small" onClick={() => handleOptionDelete(optionIndex)}
                                        ><ClearIcon /></IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Box>
                    ))
                }
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <Button startIcon={<AddIcon />} onClick={handleAddOption}>Adicionar opção</Button>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={1}>
                    <Grid item xs>
                        <Checkbox label={`Opção "Preencher"`} checked={value.textOption.show} onChange={(e) => handleTextOptionCheckedChange(e.target.checked)} />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default NewEffect;