import React from 'react';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useAppCtxActiveUser } from '../context/SystemContext';

const classes = {
    root: {
        padding: '8px',
        backgroundColor: '#f5f6fa',
        marginBottom: '8px',
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)'
    },
    whiteIcon: {
        color: theme => theme.palette.primary.main,
        backgroundColor: theme => theme.palette.background.paper,
    }
};

const SidenavMobileTop = ({closeSidenavMobile, account}) => {
    const activeUser = useAppCtxActiveUser();

    return (
        <Box sx={classes.root}>
            <Grid container justifyContent="center">
                <Grid item xs={4}>
                    <Avatar sx={classes.whiteIcon} onClick={closeSidenavMobile}>
                        <KeyboardBackspaceIcon />
                    </Avatar>
                </Grid>
                <Grid item xs={4} container justifyContent="center">
                    <Grid item>
                        <Box mt={2}>
                            {account}
                        </Box>
                    </Grid>
                </Grid>
                <Grid item xs={4}></Grid>
            </Grid>
            <Box style={{textAlign: 'center'}} mb={1}>
                <Typography variant="body1">{activeUser?.fullName}</Typography>
                <Typography variant="body2">{activeUser?.email}</Typography>
            </Box>
        </Box>
    );
};

export default SidenavMobileTop;