import { useEffect, useRef, useState } from 'react';
import FormQuestionList from '../../../../components/FormQuestionList';
import { useAppContext } from '../../../../context/SystemContext';
import { useForm } from '../../context/FormContext';

const ListQuestion = ({question}) => {
    const { formEffects } = useAppContext();
    const { formActiveClient, questionNameToCNPJ, initialForm, setFoundCNPJs, setFormValidation, userForm, set_userForm } = useForm();
    const [initialValue, setInitialValue] = useState(null);
    const [foundCNPJActivities, setFoundCNPJActivities] = useState(null);
    const [checkCorporationIdActivityCodes, setCheckCorporationIdActivityCodes] = useState([]);
    const [foundActivityCodeInCNPJ, setFoundActivityCodeInCNPJ] = useState(false);
    const [showCorporationIdActivityError, setShowCorporationIdActivityError] = useState(false);
    const [userAwareOfErrors, setUserAwareOfErrors] = useState(false);
    const [showMEIActivityError, setShowMEIActivityError] = useState(false);
    const [foundCNPJ, setFoundCNPJ] = useState(null);
    const touchedField = useRef(false);
    const [userFormQuestion, setUserFormQuestion] = useState(null);

    useEffect(() => {
        if(userFormQuestion){
            set_userForm(prevState => ({
                ...prevState,
                [question.name]: userFormQuestion
            }));
        }
    }, [userFormQuestion])
    
    useEffect(() => {
        setUserFormQuestion(prevState => ({
            ...prevState,
            id: question.id,
            type: 'list',
        }));
    }, []);

    useEffect(() => {
        setInitialValue(initialForm[question.name]);
    }, [initialForm]);

    const checkValidation = () => {
        const questionValid = formEffects.list.checkValidation(userFormQuestion);
        setFormValidation(prevState => ({...prevState, [question.name]: !questionValid}));
    };
    
    useEffect(() => {
        if((userFormQuestion && userFormQuestion.input) || touchedField.current){
            touchedField.current = true;
            checkValidation();
        }
    }, [userFormQuestion]);

    useEffect(() => {
        const userNotAwareOfCorporationIdActivityError = showCorporationIdActivityError && !userAwareOfErrors;
        const userNotAwareOfMEIActivityError = showMEIActivityError && !userAwareOfErrors;
        setUserFormQuestion(prevState => ({
            ...prevState,
            userNotAwareOfCorporationIdActivityError,
            userNotAwareOfMEIActivityError
        }));
    }, [showCorporationIdActivityError, showMEIActivityError, userAwareOfErrors]);

    useEffect(() => {
        if(question.corporationIdQuestion){
            setFoundCNPJ(questionNameToCNPJ[question.corporationIdQuestion] || null);
        }
    }, [questionNameToCNPJ]);

    const checkCNPJActivities = (currentCNPJActivities, currentSelectedListItem) => {
        let checkCorporationIdActivityCodes = null;
        let checkCorporationIdCorporationIdContainsActivityCode = false;
        let checkCorporationIdIsMEI = false;
        let checkCorporationIdMEIAllowedForDocumentActivity;
        if(currentCNPJActivities && currentSelectedListItem){
            checkCorporationIdActivityCodes = currentSelectedListItem.cnaes.map(activity => ({...activity, code: parseInt(activity.code.replace(/\D+/g, ''))}));
            for(const activity of currentCNPJActivities){
                const foundCodeInCnaes = checkCorporationIdActivityCodes.find(cnae => cnae.code === activity.code);
                if(foundCodeInCnaes){
                    checkCorporationIdCorporationIdContainsActivityCode = true;
                }
            }
            const isMEI = foundCNPJ?.isMEI;
            checkCorporationIdIsMEI = isMEI;
            if(isMEI){
                checkCorporationIdMEIAllowedForDocumentActivity = !!currentSelectedListItem.meiAllowed;
            }
        }
        setCheckCorporationIdActivityCodes(checkCorporationIdActivityCodes);
        setShowCorporationIdActivityError(checkCorporationIdActivityCodes && !checkCorporationIdCorporationIdContainsActivityCode);
        setShowMEIActivityError(checkCorporationIdIsMEI && !checkCorporationIdMEIAllowedForDocumentActivity);
        setFoundActivityCodeInCNPJ(checkCorporationIdCorporationIdContainsActivityCode);
        
        if(foundCNPJ && question.corporationIdQuestion){
            const userFormCNPJQuestion = userForm[question.corporationIdQuestion];
            if(userFormCNPJQuestion){
                const currentCNPJ = userFormCNPJQuestion.input;
                setFoundCNPJs(prevState => {
                    if(!prevState.checkCorporationIdDetails) prevState.checkCorporationIdDetails = [];
                    const prevCheckCorporationIdDetails = prevState.checkCorporationIdDetails;
                    const questionId = question.id;
                    const questionName = question.name;

                    const listItemName = currentSelectedListItem?.key;
                    const CNPJCodes = currentCNPJActivities;
                    const listItemCodes = checkCorporationIdActivityCodes;
                    const foundListItemCodeInCNPJ = checkCorporationIdCorporationIdContainsActivityCode;
                    const foundCheckIfCNPJContainsListItemCodeQuestionIndex = prevCheckCorporationIdDetails.findIndex(prevItem => prevItem.questionName === question.name && prevItem.action === 'CHECK IF CNPJ CONTAINS LIST ITEM CODE');
                    if(foundCheckIfCNPJContainsListItemCodeQuestionIndex !== -1){
                        prevState.checkCorporationIdDetails[foundCheckIfCNPJContainsListItemCodeQuestionIndex].listItemName = listItemName
                        prevState.checkCorporationIdDetails[foundCheckIfCNPJContainsListItemCodeQuestionIndex].listItemCodes = listItemCodes;
                        prevState.checkCorporationIdDetails[foundCheckIfCNPJContainsListItemCodeQuestionIndex].CNPJCodes = CNPJCodes;
                        prevState.checkCorporationIdDetails[foundCheckIfCNPJContainsListItemCodeQuestionIndex].foundListItemCodeInCNPJ = foundListItemCodeInCNPJ;
                    } else {
                        prevState.checkCorporationIdDetails.push({
                            questionId,
                            questionName,
                            corporationIdQuestion: question.corporationIdQuestion,
                            action: 'CHECK IF CNPJ CONTAINS LIST ITEM CODE',
                            CNPJ: currentCNPJ,
                            listItemName,
                            listItemCodes,
                            CNPJCodes,
                            foundListItemCodeInCNPJ,
                        });
                    }
    
                    const isMEI = checkCorporationIdIsMEI;
                    if(isMEI){
                        const MEIAllowsListItemCode = checkCorporationIdMEIAllowedForDocumentActivity;
                        const foundCheckIfMEIAllowsListItemCodeQuestionIndex = prevCheckCorporationIdDetails.findIndex(prevItem => prevItem.questionName === question.name && prevItem.action === 'CHECK IF MEI ALLOWS LIST ITEM CODE');
                        if(foundCheckIfMEIAllowsListItemCodeQuestionIndex !== -1){
                            prevState.checkCorporationIdDetails[foundCheckIfMEIAllowsListItemCodeQuestionIndex].listItemName = listItemName
                            prevState.checkCorporationIdDetails[foundCheckIfMEIAllowsListItemCodeQuestionIndex].listItemCodes = listItemCodes;
                            prevState.checkCorporationIdDetails[foundCheckIfMEIAllowsListItemCodeQuestionIndex].isMEI = isMEI;
                            prevState.checkCorporationIdDetails[foundCheckIfMEIAllowsListItemCodeQuestionIndex].MEIAllowsListItemCode = MEIAllowsListItemCode;
                        } else {
                            prevState.checkCorporationIdDetails.push({
                                questionId,
                                questionName,
                                corporationIdQuestion: question.corporationIdQuestion,
                                action: 'CHECK IF MEI ALLOWS LIST ITEM CODE',
                                listItemName,
                                listItemCodes,
                                isMEI,
                                MEIAllowsListItemCode
                            });
                        }
                    }
                    
                    return prevState;
                });
            }
        }

        return { checkCorporationIdActivityCodes, checkCorporationIdCorporationIdContainsActivityCode, checkCorporationIdIsMEI, checkCorporationIdMEIAllowedForDocumentActivity };
    };
    
    useEffect(() => {
        let checkCorporationIdCorporationIdCodes = null;
        if(foundCNPJ){
            if(foundCNPJ.mainActivity){
                checkCorporationIdCorporationIdCodes = [
                    {
                        ...foundCNPJ.mainActivity,
                        code: foundCNPJ.mainActivity.id, description: foundCNPJ.mainActivity.text,
                        isMainActivity: true
                    }
                ];
                foundCNPJ.sideActivities.forEach(activity => {
                    checkCorporationIdCorporationIdCodes.push({
                        ...activity,
                        code: activity.id, description: activity.text
                    });
                });
            }
        }
        setFoundCNPJActivities(checkCorporationIdCorporationIdCodes);
        const { checkCorporationIdActivityCodes, checkCorporationIdCorporationIdContainsActivityCode, checkCorporationIdIsMEI, checkCorporationIdMEIAllowedForDocumentActivity } = checkCNPJActivities(checkCorporationIdCorporationIdCodes, userFormQuestion?.selectedListItem);
        setUserFormQuestion(prevState => ({
            ...prevState,
            checkCorporationIdCorporationIdCodes,
            checkCorporationIdActivityCodes,
            checkCorporationIdCorporationIdContainsActivityCode,
            checkCorporationIdIsMEI,
            checkCorporationIdMEIAllowedForDocumentActivity
        }));
    }, [question, foundCNPJ]);
    
    const handleChange = (questionName, updates) => {
        const newValue = {
            input: updates.input || updates.choice || '',
            choice: updates.choice || updates.input || '', //needed for logical branching
            listItemIsSelected: !!updates.choice,
            listItemDescription: updates.listItemDescription || '',
            listItemDescriptionChanged: !!updates.listItemDescriptionChanged
        };

        const { checkCorporationIdActivityCodes, checkCorporationIdCorporationIdContainsActivityCode, checkCorporationIdIsMEI, checkCorporationIdMEIAllowedForDocumentActivity } = checkCNPJActivities(foundCNPJActivities, updates.selectedListItem);

        setUserFormQuestion(prevState => ({
            ...prevState,
            ...newValue,
            checkCorporationIdActivityCodes,
            checkCorporationIdCorporationIdContainsActivityCode,
            checkCorporationIdIsMEI,
            checkCorporationIdMEIAllowedForDocumentActivity
        }));
    };

    const handleUserAwareOfErrorsChange = (newValue) => {
        setUserAwareOfErrors(newValue);
    };
    
    return (
        <FormQuestionList
            checkCorporationIdActivityCodes={checkCorporationIdActivityCodes}
            checkCorporationIdCorporationIdContainsActivityCode={userFormQuestion?.checkCorporationIdCorporationIdContainsActivityCode}
            checkCorporationIdMEIAllowedForDocumentActivity={userFormQuestion?.checkCorporationIdMEIAllowedForDocumentActivity}
            formActiveClient={formActiveClient}
            foundActivityCodeInCNPJ={foundActivityCodeInCNPJ}
            foundCNPJ={foundCNPJ} setFoundCNPJ={setFoundCNPJ}
            handleUserAwareOfErrorsChange={handleUserAwareOfErrorsChange}
            initialValue={initialValue}
            onChange={handleChange}
            question={question}
            questionNameToCNPJ={questionNameToCNPJ}
            showCorporationIdActivityError={showCorporationIdActivityError}
            showMEIActivityError={showMEIActivityError}
            userAwareOfErrors={userAwareOfErrors}
        />
    );
}

export default ListQuestion;