import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CenteredCircularProgress from '../../components/CenteredCircularProgress';
import Form from '../Form';
import ViewBox from '../../components/ViewBox';
import { serverRequest } from '../../utils/common';
import { useAppCtxAPI, useAppCtxSelectedFolder, useAppCtxUserGroups } from '../../context/SystemContext';
import { useForm } from '../Form/context/FormContext';

const LinkForm = () => {
    const params = useParams();
    const { floatingAlert, setLoadingSystem, setSelectedFolder } = useAppCtxAPI();
    const selectedFolder = useAppCtxSelectedFolder();
    const { operatorStatus } = useAppCtxUserGroups();
    const { resetForm, set_savedForm, selectedForm, set_selectedForm, setFormActiveClient, set_visibleQuestions, set_initialForm, set_userForm } = useForm();
    const [loadingPage, setLoadingPage] = useState(true);
    const [formSent, setFormSent] = useState(false);
    const [foundForm, setFoundForm] = useState(null);
    const folderFormId = params.folderFormId;

    useEffect(() => {
        if(folderFormId){
            clearForm();
            loadForm();
        }
    }, [folderFormId]);

    const clearForm = () => {
        resetForm();
        set_selectedForm(null);
        set_savedForm(null);
        set_visibleQuestions([]);
        set_initialForm({});
        set_userForm({});
    };

    const handleNewFormButtonClick = () => {
        clearForm();
        setSelectedFolder(foundForm.folder);
        set_selectedForm(foundForm.template);
        setFormSent(false);
    };

    const loadForm = async () => {
        try {
            const data = await serverRequest({path: `/data/form/link/${folderFormId}`});
            setFoundForm(data);
            setSelectedFolder(data.folder);
            setFormActiveClient(data.client);
            openForm(data.folder, data.template);
        } catch (error) {
            floatingAlert(error);
        }
        setLoadingPage(false);
        setLoadingSystem(false);
    };

    const openForm = (selectedFolder, selectedTemplate) => {
        const initialForm = {};
        const folderInitialFormResponses = selectedFolder.folderInitialFormResponses || [];
        const templateIndex = folderInitialFormResponses.findIndex(item => item.templateId === selectedTemplate._id);
        if(templateIndex !== -1){
            folderInitialFormResponses[templateIndex].questions.forEach(question => {
                initialForm[question.name] = question.response;
            });
        }
        set_initialForm(initialForm);
        set_userForm(initialForm);
        set_selectedForm(selectedTemplate);
    };

    const onFormSubmit = () => {
        setFormSent(true);
    };

    if(!folderFormId){
        <ViewBox>
            <Grid container justifyContent="center">
                <Grid item>
                    <Typography variant="body1">Ocorreu um erro.</Typography>
                </Grid>
            </Grid>
        </ViewBox>
    };

    if(formSent){
        return (
            <ViewBox>
                <Box mb={2}>
                    <Grid container justifyContent="center">
                        <Grid item>
                            <Typography variant="body1">O formulário foi enviado!</Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box mb={2}>
                    <Grid container justifyContent="center">
                        <Grid item>
                            <Button
                                variant="contained" color="primary"
                                onClick={handleNewFormButtonClick}
                            >Preencher outro formulário</Button>
                        </Grid>
                    </Grid>
                </Box>
            </ViewBox>
        );
    }

    return ( 
        <ViewBox>
            {
                loadingPage &&
                <CenteredCircularProgress />
            }
            {
                (selectedFolder && selectedForm) &&
                <Fade in timeout={1500}>
                    <Box>
                        <Collapse in={!loadingPage}>
                            <Box>
                                <Form
                                    data={{operatorStatus}} 
                                    form={selectedForm}
                                    hideSaveFormButton
                                    hideSuppliersButton
                                    linkForm
                                    onFormSubmit={onFormSubmit}
                                    replaceTitle={`${selectedFolder.name} - ${selectedForm.name}`}
                                />
                            </Box>
                        </Collapse>
                    </Box>
                </Fade>
            } 
        </ViewBox>
    );
}

export default LinkForm;