import { useState, useRef } from 'react';
import { Button, Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import ChipWithMenu from '../../../../components/ChipWithMenu';
import FilterListIcon from '@mui/icons-material/FilterList';
import FiltersPopOver from '../Pending/components/FiltersPopOver';
import FilterInput from '../../../../components/FilterInput';
import PendingDocumentReviewerSelector from './PendingDocumentReviewerSelector';
import Tooltip from '../../../../components/Tooltip';
import { useDocumentsAPI, useDocumentsCtxPendingDocumentsReviewer } from '../../../../context/DocumentsContext';
import useWindowSize from '../../../../hooks/useWindowSize';

const OperatorDocumentsSinglePageFilters = ({
    clearFilters,
    clientOptions,
    filters, setFilters,
    handleClientChange,
    loading,
    numberOfDocuments,
    projectOptions,
    selectedClient,
    selectedProject, set_selectedProject,
}) => {
    const { setState: setDocumentsCtxState } = useDocumentsAPI();
    const selectedReviewer = useDocumentsCtxPendingDocumentsReviewer()
    const filtersButtonRef = useRef();
    const [filtersPopOverAnchorEl, set_filtersPopOverAnchorEl] = useState(null);
    const [filtersPopOverOpen, set_filtersPopOverOpen] = useState(false);
    const { windowWidth } = useWindowSize();

    const set_selectedReviewer = (newValue) => {
        setDocumentsCtxState('selectedPendingDocumentsReviewer', newValue);
    };

    const handleFiltersButtonClick = () => {
        set_filtersPopOverAnchorEl(filtersButtonRef.current);
        set_filtersPopOverOpen(true);
    };

    return (
        <>
            {
                (windowWidth && windowWidth < 960)
                ?
                <Grid item>
                    <Button
                        disabled={loading}
                        ref={filtersButtonRef}
                        variant="outlined" startIcon={<FilterListIcon />}
                        onClick={handleFiltersButtonClick}
                    >Filtros</Button>
                </Grid>
                :
                <>
                    <Grid item>
                        <FilterInput value={filters.text} setValue={newValue => setFilters(prevState => ({...prevState, text: newValue}))} />
                    </Grid>
                    <Grid item>
                        <ChipWithMenu
                            label="Empresa"
                            onChange={handleClientChange}
                            options={[{value: 'all', label: 'Todas'}, ...clientOptions]}
                            value={selectedClient}
                        />
                    </Grid>
                    <Grid item>
                        <ChipWithMenu
                            label="Pasta"
                            onChange={set_selectedProject}
                            options={[{value: 'all', label: 'Todas'}, ...projectOptions]}
                            value={selectedProject}
                        />
                    </Grid>
                    <Grid item>
                        <PendingDocumentReviewerSelector />
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">({numberOfDocuments || 0} documentos)</Typography>
                    </Grid>
                    {
                        (filters.text || selectedClient !== 'all' || selectedProject !== 'all' || selectedReviewer !== '~all;') &&
                        <Grid item>
                            <Tooltip text="Limpar filtros">
                                <IconButton size="small" onClick={clearFilters}><ClearIcon /></IconButton>
                            </Tooltip>
                        </Grid>
                    }
                </>
            }

            <FiltersPopOver
                data={{
                    filtersPopOverOpen, set_filtersPopOverOpen, filtersPopOverAnchorEl,

                    clientOptions,
                    handleClientChange,
                    loadingPage: loading,
                    projectOptions,
                    selectedClient,
                    selectedProject,
                    selectedReviewer,
                    set_selectedProject,
                    set_selectedReviewer
                }}
            />
        </>
    );
};

export default OperatorDocumentsSinglePageFilters;