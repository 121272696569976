import { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Box, Button, Chip, CircularProgress, Collapse, Fab, Fade, IconButton, Link, Menu, MenuItem, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Autocomplete from '../Autocomplete';
import AlertDialog from '../AlertDialog';
import ClearanceItemsList from './ClearanceItemsList';
import ClearanceWindow from './ClearanceWindow';
import CommentsWindow from './CommentsWindow';
import Input from '../Input';
import LoaderEllipsis from '../LoaderEllipsis';
import MenuChip from '../MenuChip';
import NewReportWindow from './NewReportWindow';
import NoResults from '../NoResults';
import Select from '../Select';
import SectionTitle from '../SectionTitle';
import ViewBox from '../ViewBox';
import { useAppCtxAPI, useAppCtxActiveUser } from '../../context/SystemContext';
import { updateStateDeleteArrayItemBy_id, serverRequest } from '../../utils/common';
import { CLEARANCE_RISK_OPTIONS, SERVER_PATH } from '../../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import queryString from 'query-string'; 

const classes = {
    newClearanceItemButton: {
        position: 'fixed',
        bottom: '8px',
        right: '8px',
        zIndex: theme => theme.zIndex.modal + 1
    },
    searchValueBox: {
        maxWidth: '200px',
        overflowX: 'auto',
        textAlign: ['center', 'left'],
    },
};

const ClearanceItemsListPage = ({
    activeUserIsOperator, activeUserIsTrueOperator, authorityRiskLevel, hideReports, initialSelectedTemplate, initialClearanceFolderInformation,
    numberOfApprovalsRequired, projects, restrictedUserFolder, users, reportType
}) => {
    const { deleteForever, updateOneTrueOperators } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const printRef = useRef();
    const [loading, setLoading] = useState(true);
    const [projectOptions, setProjectOptions] = useState([]);
    const [trueOperatorSelectedFolder, setTrueOperatorSelectedFolder] = useState(null);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [selectedFolderType, setSelectedFolderType] = useState('');
    const [selectedFolderTemplateId, setSelectedFolderTemplateId] = useState(initialSelectedTemplate ? initialSelectedTemplate._id : '');
    const [selectedFolderTemplate, setSelectedFolderTemplate] = useState(initialSelectedTemplate || null);
    const [clearanceFolderInformation, setClearanceFolderInformation] = useState(initialClearanceFolderInformation || null);
    const [clearanceWindowOpen, setClearanceWindowOpen] = useState(false);
    const [clearanceItems, setClearanceItems] = useState(null);
    const [selectedClearanceItem, setSelectedClearanceItem] = useState(null);
    const [notification, setNotification] = useState({});
    const [commentsWindowOpen, setCommentsWindowOpen] = useState(false);
    const [templates, setTemplates] = useState(null);
    const [searchInput, setSearchInput] = useState('');
    const [availableClearanceReports, setAvailableClearanceReports] = useState([]);
    const [clearanceReportOptions, setClearanceReportOptions] = useState([]);
    const [selectedReportId, setSelectedReportId] = useState('none');
    const [selectedReport, setSelectedReport] = useState(null);
    const [newReportWindowOpen, setNewReportWindowOpen] = useState(false);
    const [sendingReport, setSendingReport] = useState(false);
    const defaultFilters = {
        rightType: 'all',
        riskLevel: 'all',
        status: 'all',
        search: ''
    };
    const [filters, setFilters] = useState(defaultFilters);
    const [filtersOptions, setFiltersOptions] = useState({
        rightType: [{value: 'all', label: 'Todos'}],
        riskLevel: [
            {value: 'all', label: 'Todos'},
            ...CLEARANCE_RISK_OPTIONS.map(option => ({...option, description: option.label, label: <span><span style={{color: option.color}}>&#11044;</span>&nbsp;{option.label}</span>}))
        ],
        status: null
    });
    const [filterActive, setFilterActive] = useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [filterOpen, setFilterOpen] = useState('');
    const [menuOpen, setMenuOpen] = useState(false);
    const [downloadingExcel, setDownloadingExcel] = useState(false);
    const [downloadingPdf, setDownloadingPdf] = useState(false);
    const [sendReportAlertDialogOpen, setSendReportAlertDialogOpen] = useState(false);
    const [clearanceReportItemsUpdatedAt, setClearanceReportItemsUpdatedAt] = useState(null);
    const [cancelReportItemDialogOpen, setCancelReportItemDialogOpen] = useState(false);
    const [deleteReportItemDialogOpen, setDeleteReportItemDialogOpen] = useState(false);
    const usedSearchReportId = useRef(false);
    const usedSearchReportItemId = useRef(false);

    useEffect(() => {
        if(initialSelectedTemplate && initialClearanceFolderInformation){
            setClearanceFolderInformation(initialClearanceFolderInformation);
            setSelectedFolderTemplateId(initialSelectedTemplate._id || '');
            setSelectedFolderTemplate(initialSelectedTemplate);
        }
    }, [initialSelectedTemplate, initialClearanceFolderInformation]);
    
    useEffect(() => {
        if(activeUserIsOperator && projects){
            const currentProjectOptions = projects.filter(project => project.modules?.includes('clearance'));
            setProjectOptions(currentProjectOptions);
            setLoading(false);
        }
    }, [activeUserIsOperator, projects]);

    useEffect(() => {
        if(activeUserIsOperator){
            setSelectedFolder(trueOperatorSelectedFolder);
        }
    }, [trueOperatorSelectedFolder]);

    useEffect(() => {
        if(!activeUserIsOperator){ // if client, set initialSelectedFolder
            setSelectedFolder(restrictedUserFolder);
        }
    }, [restrictedUserFolder]);

    useEffect(() => {
        if(!initialSelectedTemplate && !initialClearanceFolderInformation){
            setClearanceItems(null);
            changeAvailableClearanceReports([]);
            setClearanceFolderInformation(null);
            setSelectedFolderTemplateId('');
            setSelectedFolderType('');
            if(selectedFolder){
                setLoading(true);
                getClearanceFolderInformation();
            }
        }
    }, [selectedFolder, reportType]);
    
    useEffect(() => {
        if(selectedFolder && clearanceFolderInformation){
            setSelectedFolderType(clearanceFolderInformation.clearanceType);
            setSelectedFolderTemplateId(clearanceFolderInformation.clearanceTemplate);
            changeAvailableClearanceReports(clearanceFolderInformation.clearanceReports);
            if(clearanceFolderInformation.clearanceReports.length === 0){
                setClearanceItems([]);
                setLoading(false);
            }
        }
    }, [selectedFolder, clearanceFolderInformation]);

    useEffect(() => {
        if(numberOfApprovalsRequired){
            let approvalsRequired = false;
            for(const key in numberOfApprovalsRequired){
                if(numberOfApprovalsRequired[key] > 0) approvalsRequired = true;
            }
            if(approvalsRequired){
                setFiltersOptions(prevState => ({
                    ...prevState,
                    status: [
                        {value: 'approved', description: 'Riscos aprovados', label: <Grid container spacing={1} alignItems="center"><Grid xs><Typography variant="body1">Riscos aprovados</Typography></Grid><Grid><ThumbUpIcon /></Grid></Grid>},
                        {value: 'rejected', description: 'Riscos reprovados', label: <Grid container spacing={1} alignItems="center"><Grid xs><Typography variant="body1">Riscos reprovados</Typography></Grid><Grid><ThumbDownIcon /></Grid></Grid>},
                        {value: 'all', label: 'Todas'}
                    ]
                }));
            }
        }
    }, [numberOfApprovalsRequired]);
    
    useEffect(() => {
        if(selectedFolderTemplate){
            setFiltersOptions(prevState => ({
                ...prevState,
                rightType: [{value: 'all', label: 'Todos'}, ...selectedFolderTemplate.rightTypes.map(rightType => ({value: rightType._id, label: rightType.name})).sort((a, b) => a.label > b.label ? 1 : (a.label < b.label ? -1 : 0))]
            }));
        }
    }, [selectedFolderTemplate]);

    useEffect(() => {
        let currentFilterActive = false;
        for(const key in filters){
            if(!['', 'all'].includes(filters[key])) currentFilterActive = true;
        }
        setFilterActive(currentFilterActive);
    }, [filters]);

    const changeAvailableClearanceReports = (newValue, shouldNotSetSelectedReportId) => {
        setAvailableClearanceReports(newValue);
        if(newValue){
            const currentClearanceReportOptions = newValue.map(report => {
                const reportEpisode = report.episode;
                const reportType = report.type;
                return ({value: report._id, label: `${reportEpisode ? `Episódio ${reportEpisode}, ` : ''}${reportType === 'script' ? `Tratamento ${report.scriptTreatment}` : `Corte ${report.cutVersion}`}`});
            });
            setClearanceReportOptions(currentClearanceReportOptions);
            if(currentClearanceReportOptions.length >= 1){
                if(!shouldNotSetSelectedReportId) setSelectedReportId(currentClearanceReportOptions[currentClearanceReportOptions.length - 1].value);
            } else {
                setSelectedReportId('');
                // setLoading(false);
            }
        }
    };

    useEffect(() => {
        getClearance(selectedReportId);
        let currentSelectedReport = null;
        if(selectedReportId){
            currentSelectedReport = availableClearanceReports.find(report => report._id === selectedReportId);
        }
        setSelectedReport(currentSelectedReport);
    }, [selectedReportId]);

    useEffect(() => {
        if(selectedFolder){
            getClearance(selectedReportId);
        }
    }, [filters]);

    useEffect(() => {
        if(!initialSelectedTemplate && !initialClearanceFolderInformation) getTemplates();
    }, []);

    useEffect(() => {
        if(!initialSelectedTemplate && !initialClearanceFolderInformation && templates && selectedFolderTemplateId){
            const currentSelectedFolderTemplate = templates.find(template => template._id === selectedFolderTemplateId);
            setSelectedFolderTemplate(currentSelectedFolderTemplate);
        }
    }, [templates, selectedFolderTemplateId]);

    useEffect(() => {
        if(!usedSearchReportId.current && clearanceReportOptions?.length >= 1){
            const search = queryString.parse(window.location.search);
            const { relatorio: reportId } = search;
            if(reportId){
                usedSearchReportId.current = true;
                setSelectedReportId(reportId);
            }
        }
    }, [clearanceReportOptions]);

    useEffect(() => {
        if(!usedSearchReportItemId.current && selectedReport && clearanceItems?.length >= 1){
            const search = queryString.parse(window.location.search);
            const { anotacao: reportItemId } = search;
            if(reportItemId){
                usedSearchReportItemId.current = true;
                const foundClearanceItem = clearanceItems.find(clearanceItem => clearanceItem._id === reportItemId);
                if(foundClearanceItem){
                    handleCommentsButtonClick(null, foundClearanceItem);
                }
            }
        }
    }, [clearanceItems]);

    const handleChangeSelectedFolderTemplateId = (newValue) => {
        setSelectedFolderTemplateId(newValue);
        changeFolder({ clearanceTemplate: newValue });
    };

    const handleChangeSelectedFolderType = (newValue) => {
        setSelectedFolderType(newValue);
        changeFolder({ clearanceType: newValue });
    }

    const changeFolder = (updates) => {
        updateOneTrueOperators('Projects', selectedFolder._id, updates);
    };

    const getTemplates = async () => {
        if(activeUserIsOperator){
            setLoading(true);
            await axios.get(SERVER_PATH + '/data/clearance/templates')
            .then(res => {
                setTemplates(res.data);
            })
            .catch(err => {
    
            });
            setLoading(false);
        }
    };

    const getClearanceFolderInformation = async () => {
        await axios.get(SERVER_PATH + `/data/clearance/folder/${selectedFolder._id}?reportType=${reportType || ''}`)
        .then(res => {
            setClearanceFolderInformation(res.data);
        })
        .catch(err => {});
        if(clearanceItems) setLoading(false);
    };
    
    const getClearance = async (reportId) => {
        if(reportId && reportId !== 'none' && !hideReports){
            setLoading(true);
            let queries = [];
            if(filters.rightType !== 'all') queries.push(`rightType=${filters.rightType}`);
            if(filters.riskLevel !== 'all') queries.push(`riskLevel=${filters.riskLevel}`);
            if(filters.status !== 'all') queries.push(`status=${filters.status}`);
            if(filters.search !== '') queries.push(`text=${filters.search}`);
            const query = queries.length >= 1 ? `?${queries.join('&')}` : '';
            await axios.get(SERVER_PATH + `/data/clearance/find/${selectedFolder._id}/${reportId}${query}`)
            .then(res => {
                if(!initialSelectedTemplate && !initialClearanceFolderInformation && res.data.template){ // if user NOT operator
                    setTemplates([res.data.template]);
                }
                setClearanceItems(res.data.clearance);
                setClearanceReportItemsUpdatedAt(new Date());
            })
            .catch(err => {});
            setLoading(false);
        }
    };

    const handleNewRightButtonClick = () => {
        setSelectedClearanceItem(null);
        setClearanceWindowOpen(true);
    };

    const handleClearanceItemBoxClick = useCallback((clearanceItem) => {
        if(clearanceItem) setSelectedClearanceItem(clearanceItem);
        if(activeUserIsOperator){
            setClearanceWindowOpen(true);
        } else {
            setCommentsWindowOpen(true);
        }
    }, []);

    const handleCommentsButtonClick = useCallback((e, clearanceItem) => {
        if(e) e.stopPropagation();
        setSelectedClearanceItem(clearanceItem);
        setCommentsWindowOpen(true);
    }, []);

    const handleNewReportButton = () => {
        setNewReportWindowOpen(true);
    };

    const handleSendReportButton = async () => {
        setSendReportAlertDialogOpen(true);
    };
    const handleSendReportConfirm = async () => {
        setSendReportAlertDialogOpen(false);
        setLoading(true);
        setSendingReport(true);
        await axios.post(SERVER_PATH + `/data/clearance/report/${selectedReportId}/send`, {})
        .then(res => {
            const clearanceReports = res.data;
            changeAvailableClearanceReports(clearanceReports, true);
            setSelectedReport(prevState => ({...prevState, sent: true}));
        })
        .catch(err => {

        });
        setSendingReport(false);
        setLoading(false);
    };

    const handleExcelButtonPress = async () => {
        setDownloadingExcel(true);
        setNotification({ message: 'Gerando o relatório... isso pode levar alguns minutos...' });
        try {
            await serverRequest({ path: `/data/clearance/${selectedFolder._id}/reports/${selectedReportId}/excel`, method: 'download' });
            setNotification({ message: '' });
        } catch (error) {
            setNotification({ severity: 'error', message: error });
        }
        setDownloadingExcel(false);
    };

    const handlePdfButtonPress = async () => {
        setDownloadingPdf(true);
        setNotification({ message: 'Gerando o relatório... isso pode levar alguns minutos...' });
        try {
            await serverRequest({ path: `/data/folders/${selectedFolder._id}/clearance/reports/${selectedReportId}/pdf`, method: 'download' });
            setNotification({ message: '' });
        } catch (error) {
            setNotification({ severity: 'error', message: error });
        }
        setDownloadingPdf(false);
    };

    const handleMenuOpen = (e, selectedFilter) => {
        setMenuAnchorEl(e.currentTarget);
        setFilterOpen(selectedFilter);
        setMenuOpen(true);
    };

    const handleDeleteReportItemPress = () => {
        if(activeUser.type >= 9){
            return setDeleteReportItemDialogOpen(true);
        }
        setCancelReportItemDialogOpen(true);
    }
    const handleDeleteReportItemDialogCancelButtonPress = () => {
        setDeleteReportItemDialogOpen(false);
        setCancelReportItemDialogOpen(true);
    }
    const handleCancelReportItemConfirm = async () => {
        setCancelReportItemDialogOpen(false);
        setClearanceWindowOpen(false);

        const clearanceItemsCopy = [...clearanceItems];
        updateStateDeleteArrayItemBy_id(selectedClearanceItem._id, setClearanceItems);
        setClearanceReportItemsUpdatedAt(new Date());
        
        const postData = {
            reportId: selectedReport._id
        };
        await axios.post(SERVER_PATH + `/data/clearance/${selectedClearanceItem._id}/delete`, postData)
        .then(res => {
        })
        .catch(err => {
            console.log(err);
            setClearanceItems(clearanceItemsCopy);
            setClearanceReportItemsUpdatedAt(new Date());
            // setShowNotification(true);
        });
        // setLoading(false);
    };

    const orderClearanceReport = (nextClearanceReportItems) => {
        const bulkUpdates = [];
        let newReferenceNumber = 1;
        nextClearanceReportItems.sort((a, b) => {
            if(selectedReport.type === 'script'){
                if(a.scriptScene !== null && b.scriptScene !== null && a.scriptScene !== undefined && b.scriptScene !== undefined){
                    return a.scriptScene - b.scriptScene;
                } else {
                    if(a.scriptScene === null || a.scriptScene === undefined) {
                        return 1;
                    } else if (b.scriptScene === null || b.scriptScene === undefined) {
                        return -1;
                    }
                }
            } else if(selectedReport.type === 'cut'){
                if(a.cutTimeCode !== null && a.cutTimeCode !== undefined && a.cutTimeCode !== '' && b.cutTimeCode !== null && b.cutTimeCode !== undefined && b.cutTimeCode !== ''){
                    return a.cutTimeCode.localeCompare(b.cutTimeCode);
                } else {
                    if(a.cutTimeCode === null || a.cutTimeCode === undefined || a.cutTimeCode === ''){
                        return 1;
                    } else if (b.cutTimeCode === null || b.cutTimeCode === undefined || b.cutTimeCode === ''){
                        return -1;
                    }
                }
            }
            return a.createdAt > b.createdAt ? 1 : (a.createdAt < b.createdAt ? -1 : 0);
        }).forEach((clearanceReportItem, clearanceReportItemIndex) => {
            if(clearanceReportItem.referenceNumber !== newReferenceNumber){
                nextClearanceReportItems = [
                    ...nextClearanceReportItems.slice(0, clearanceReportItemIndex),
                    {
                        ...nextClearanceReportItems[clearanceReportItemIndex],
                        referenceNumber: newReferenceNumber,
                        updatedAt: new Date()
                    },
                    ...nextClearanceReportItems.slice(clearanceReportItemIndex + 1)
                ];
                bulkUpdates.push({
                    updateOne: {
                        filter: {
                            _id: clearanceReportItem._id
                        },
                        update: {
                            referenceNumber: newReferenceNumber
                        }
                    }
                });
            }
            newReferenceNumber++;
        });
        setClearanceItems(nextClearanceReportItems);
    };

    const handleDeleteReportItemConfirm = async () => {
        setDeleteReportItemDialogOpen(false);
        setClearanceWindowOpen(false);

        const clearanceItemsCopy = [...clearanceItems];
        let nextClearanceReportItems = [...clearanceItems];
        try {
            const prevClearanceReportItemIndex = nextClearanceReportItems.findIndex(prevClearanceReportItem => prevClearanceReportItem._id === selectedClearanceItem._id);
            nextClearanceReportItems = [
                ...nextClearanceReportItems.slice(0, prevClearanceReportItemIndex),
                ...nextClearanceReportItems.slice(prevClearanceReportItemIndex + 1)
            ];
            orderClearanceReport(nextClearanceReportItems);
            deleteForever('Clearance', selectedClearanceItem._id, selectedClearanceItem.folderId);
        } catch (error) {
            setClearanceItems(clearanceItemsCopy);
        }
    }

    const getFilterLabelByValue = (value, filter) => {
        const currentFilterOptions = filtersOptions[filter];
        const currentFilterOption = currentFilterOptions.find(option => option.value === value);
        return currentFilterOption.description || currentFilterOption.label;
    };

    const filterSearch = (newValue) => {
        setFilters(prevState => ({...prevState, search: newValue}));
        setSearchInput('');
    };

    const filterOnKeyDown = (e) => {
        const pressedKey = e.key;
        if(pressedKey === 'Enter'){
            filterSearch(e.target.value);
        }
    };
    
    return (
        <ViewBox message={notification}>
            {
                hideReports
                ?
                <Box>
                    <NoResults text="Nenhum relatório disponível." />
                </Box>
                :
                <Box>
                    <Box mb={2}>
                        {
                            activeUserIsOperator &&
                            <Box mb={1}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid xs={12} sm={4} md={2}>
                                        <Autocomplete
                                            disabled={!projects}
                                            label="Projeto"
                                            options={projectOptions}
                                            getOptionLabel={(option) => option.name}
                                            value={trueOperatorSelectedFolder} onChange={(e, newValue) => setTrueOperatorSelectedFolder(newValue)}
                                        />
                                    </Grid>
                                    <Grid xs>
                                        <Fade in={clearanceFolderInformation}>
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid xs={12} sm={4} md={2}>
                                                    <Select
                                                        disabled={(loading || selectedFolderType)}
                                                        label="Tipo"
                                                        options={clearanceFolderInformation?.workTypeOptions}
                                                        value={selectedFolderType} onChange={(e) => handleChangeSelectedFolderType(e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={4} md={2}>
                                                    {
                                                        templates &&
                                                        <Select
                                                            disabled={(loading || selectedFolderTemplateId)}
                                                            label="Matriz"
                                                            options={templates.map(template => ({value: template._id, label: template.name}))}
                                                            value={selectedFolderTemplateId} onChange={(e) => handleChangeSelectedFolderTemplateId(e.target.value)}
                                                        />
                                                    }
                                                </Grid>
                                                <Grid xs></Grid>
                                                {
                                                    (selectedFolderType && selectedFolderTemplate) &&
                                                    <Grid>
                                                        <Button variant="contained" color="primary" onClick={handleNewReportButton}>Novo relatório</Button>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Fade>
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                        <Grid container spacing={1} alignItems="center">
                            <Fade in={clearanceFolderInformation}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    {
                                        clearanceReportOptions.length >= 1 &&
                                        <Grid xs={12} sm={4} md={2}>
                                            <Select
                                                label="Relatório"
                                                options={clearanceReportOptions}
                                                value={selectedReportId} onChange={(e) => setSelectedReportId(e.target.value)}
                                            />
                                        </Grid>
                                    }
                                    <Grid xs={12} sm={6} md={3}>
                                        <Input
                                            disabled={loading}
                                            fullWidth
                                            placeholder="Busca"
                                            value={searchInput} onChange={(e) => setSearchInput(e.target.value)}
                                            endIcon={<SearchIcon />}
                                            endIconTitle="Busca"
                                            endIconOnClick={() => filterSearch(searchInput)}
                                            onKeyDown={filterOnKeyDown}
                                        />
                                    </Grid>
                                    <Grid sx={classes.searchValueBox}>
                                        <Collapse in={filters.search}>
                                            <Chip
                                                label={filters.search}
                                                color="primary"
                                                onDelete={() => setFilters(prevState => ({...prevState, search: ''}))}
                                            />
                                        </Collapse>
                                    </Grid>

                                    {
                                        filtersOptions.status &&
                                        <Grid>
                                            <MenuChip
                                                label="Situação"
                                                onPress={(e) => handleMenuOpen(e, 'status')}
                                                value={filters.status} valueLabel={getFilterLabelByValue(filters.status, 'status')}
                                            />
                                        </Grid>
                                    }
                                    {
                                        selectedFolderTemplate &&
                                        <Grid>
                                            <MenuChip
                                                label="Direito"
                                                onPress={(e) => handleMenuOpen(e, 'rightType')}
                                                value={filters.rightType} valueLabel={getFilterLabelByValue(filters.rightType, 'rightType')}
                                            />
                                        </Grid>
                                    }

                                    <Grid>
                                        <MenuChip
                                            label="Risco"
                                            onPress={(e) => handleMenuOpen(e, 'riskLevel')}
                                            value={filters.riskLevel} valueLabel={getFilterLabelByValue(filters.riskLevel, 'riskLevel')}
                                        />
                                    </Grid>
                                    {
                                        filterActive &&
                                        <Grid>
                                            <IconButton size="small" onClick={() => setFilters(defaultFilters)}><ClearIcon /></IconButton>
                                        </Grid>
                                    }
                                    <Grid xs></Grid>
                                    <Grid>
                                        <Button
                                            disabled={!clearanceItems || clearanceItems.length === 0 || downloadingExcel}
                                            variant="outlined" size="small"
                                            startIcon={downloadingExcel ? <CircularProgress size={12} /> : <FontAwesomeIcon icon={faFileExcel} />}
                                            onClick={handleExcelButtonPress}
                                        >XLSX</Button>
                                    </Grid>
                                    <Grid>
                                        <Button
                                            disabled={!clearanceItems || clearanceItems.length === 0 || downloadingPdf}
                                            variant="outlined" size="small"
                                            startIcon={downloadingPdf ? <CircularProgress size={12} /> : <FontAwesomeIcon icon={faFilePdf} />}
                                            onClick={handlePdfButtonPress}
                                        >PDF</Button>
                                    </Grid>
                                </Grid>
                            </Fade>
                        </Grid>
                    </Box>
                    {
                        selectedReport &&
                        <Box mb={3}>
                            <SectionTitle title={`${selectedReport.episode ? `Episódio ${selectedReport.episode}, ` : ''}${selectedReport.type === 'script' ? `Tratamento ${selectedReport.scriptTreatment}` : `Corte ${selectedReport.cutVersion}`}`} />
                            {
                                selectedReport.videoPlatformUrl &&
                                <Link variant="body1" href={selectedReport.videoPlatformUrl} target="_blank">{selectedReport.videoPlatformUrl}</Link>
                            }
                            {
                                (selectedReport && !selectedReport.sent) &&
                                <Box>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid>
                                            <Typography variant="body1">Este relatório ainda não foi disponibilizado para o cliente.</Typography>
                                        </Grid>
                                        <Grid xs>
                                            <Button
                                                variant="outlined" color="primary"
                                                endIcon={sendingReport ? <CircularProgress size={20} /> : <SendIcon />}
                                                onClick={handleSendReportButton}
                                            >Enviar</Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            }
                        </Box>
                    }
                    {
                        (loading || (!activeUserIsOperator && !clearanceItems))
                        ? <LoaderEllipsis primary />
                        :
                        <div ref={printRef} style={{ backgroundColor: 'white' }}>
                        <ClearanceItemsList
                            {
                                ...{
                                    activeUser,
                                    activeUserIsTrueOperator,
                                    clearanceItems, setClearanceItems,
                                    handleClearanceItemBoxClick,
                                    handleCommentsButtonClick,
                                    numberOfApprovalsRequired,
                                    selectedFolderTemplate,
                                    selectedReport,
                                    updatedAt: clearanceReportItemsUpdatedAt,
                                    users
                                }
                            }
                        />
                        </div>
                    }

                    {
                        activeUserIsOperator &&
                        <Fab
                            color="primary"
                            sx={classes.newClearanceItemButton}
                            disabled={!selectedFolder || !selectedFolderTemplate || !selectedReport}
                            onClick={handleNewRightButtonClick}
                        ><AddIcon /></Fab>
                    }
                </Box>
            }

            <ClearanceWindow
                open={clearanceWindowOpen} setOpen={setClearanceWindowOpen}
                activeUserIsTrueOperator={activeUserIsTrueOperator}
                clearanceItems={clearanceItems}
                orderClearanceReport={orderClearanceReport}
                selectedProject={selectedFolder}
                selectedProjectTemplate={selectedFolderTemplate}
                setClearanceItems={setClearanceItems}
                selectedClearanceItem={selectedClearanceItem} setSelectedClearanceItem={setSelectedClearanceItem}
                selectedReport={selectedReport}
                handleDeleteReportItemPress={handleDeleteReportItemPress}
            />
            <CommentsWindow
                activeUserIsOperator={activeUserIsOperator}
                open={commentsWindowOpen} setOpen={setCommentsWindowOpen}
                authorityRiskLevel={authorityRiskLevel}
                numberOfApprovalsRequired={numberOfApprovalsRequired}
                selectedProject={selectedFolder}
                selectedClearanceItem={selectedClearanceItem} setSelectedClearanceItem={setSelectedClearanceItem}
                selectedReport={selectedReport}
                selectedTemplate={selectedFolderTemplate}
                setClearanceItems={setClearanceItems}
                users={users}
            />
            <NewReportWindow
                open={newReportWindowOpen} setOpen={setNewReportWindowOpen}
                availableClearanceReports={availableClearanceReports}
                selectedFolder={selectedFolder}
                selectedFolderType={selectedFolderType}
                changeAvailableClearanceReports={changeAvailableClearanceReports}
                setSelectedReportId={setSelectedReportId}
            />

            <Menu
                id="simple-menu"
                anchorEl={menuAnchorEl}
                keepMounted
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
            >
                {
                    filterOpen &&
                    filtersOptions[filterOpen].map((option, optionIndex) => (
                        <MenuItem
                            key={optionIndex}
                            onClick={() => {
                                setFilters(prevState => ({...prevState, [filterOpen]: option.value}));
                                setMenuOpen(false);
                            }}
                            selected={option.value === filters[filterOpen]}
                        >{option.label}</MenuItem>
                    ))
                }
            </Menu>

            <AlertDialog
                open={sendReportAlertDialogOpen} onClose={() => setSendReportAlertDialogOpen(false)}
                title="Tem certeza?"
                text={`Quer disponibilizar este relatório para o cliente?`}
                okButtonOnClick={handleSendReportConfirm}
            />
            <AlertDialog
                open={cancelReportItemDialogOpen} onClose={() => setCancelReportItemDialogOpen(false)}
                title="Tem certeza?"
                text={`Quer excluir esta anotação do relatório?`}
                okButtonOnClick={handleCancelReportItemConfirm}
            />
            <AlertDialog
                open={deleteReportItemDialogOpen} onClose={() => setDeleteReportItemDialogOpen(false)}
                title="Tem certeza?"
                text={`Quer excluir definitivamente esta anotação do SOLIDA? Essa ação é irreversível.`}
                okButtonOnClick={handleDeleteReportItemConfirm}
                cancelButtonText="Não"
                cancelButtonOnClick={handleDeleteReportItemDialogCancelButtonPress}
            />
        </ViewBox>
    );
};

export default ClearanceItemsListPage;