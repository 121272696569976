import NewEffectActorSchedule from './NewEffectActorSchedule';
import NewEffectCheckbox from './NewEffectCheckbox';
import NewEffectCorporationId from './NewEffectCorporationId';
import NewEffectCurrency from './NewEffectCurrency';
import NewEffectDate from './NewEffectDate';
import NewEffectFileUpload from './NewEffectFileUpload';
import NewEffectIf from './NewEffectIf';
import NewEffectIfNot from './NewEffectIfNot';
import NewEffectIfTrue from './NewEffectIfTrue';
import NewEffectIndividualId from './NewEffectIndividualId';
import NewEffectList from './NewEffectList';
import NewEffectMovablePropertyLeaseDetails from './NewEffectMovablePropertyLeaseDetails';
import NewEffectNumber from './NewEffectNumber';
import NewEffectPaymentSchedule from './NewEffectPaymentSchedule';
import NewEffectRadio from './NewEffectRadio';
import NewEffectRadioGrid from './NewEffectRadioGrid';
import NewEffectSchedule from './NewEffectSchedule';
import NewEffectSelect from './NewEffectSelect';
import NewEffectSpecial from './NewEffectSpecial';
import NewEffectText from './NewEffectText';

const NewEffectTypes = (props) => {
    const acceptedComponents = {
        actorSchedule: NewEffectActorSchedule,
        checkbox: NewEffectCheckbox,
        corporationId: NewEffectCorporationId,
        currency: NewEffectCurrency,
        date: NewEffectDate,
        file: NewEffectFileUpload,
        if: NewEffectIf,
        ifNot: NewEffectIfNot,
        ifTrue: NewEffectIfTrue,
        individualId: NewEffectIndividualId,
        list: NewEffectList,
        movablePropertyLeaseDetails: NewEffectMovablePropertyLeaseDetails,
        number: NewEffectNumber,
        paymentSchedule: NewEffectPaymentSchedule,
        radio: NewEffectRadio,
        radioGrid: NewEffectRadioGrid,
        schedule: NewEffectSchedule,
        select: NewEffectSelect,
        special: NewEffectSpecial,
        text: NewEffectText,
    }
    
    if(props.type){
        const NewEffectType = acceptedComponents[props.type];
        if(NewEffectType){
            return (
                <NewEffectType {...props} />
            );
        }
    }
    return null;
};

export default NewEffectTypes;