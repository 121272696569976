import { styled } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';
import { useAppCtxLoading } from '../../context/SystemContext';

const classes = {
    logo: {
        opacity: 1,
        '&.loading': {
            '@keyframes loading': {
                '50%': {
                    opacity: 0
                },
                '100%': {
                    opacity: 1
                }
            },
            animation: 'loading 2s infinite',
        },
        zIndex: 103
    },
    loadingLogo: {
        '@keyframes loading': {
            '50%': {
                opacity: 0
            },
            '100%': {
                opacity: 1
            }
        },
        animation: 'loading 2s infinite',
    },
};

const Img = styled('img')(unstable_styleFunctionSx);

const Logo = () => {
    const loading = useAppCtxLoading();

    return (
        <Img id="logo" src={'/SOLIDA-50.png'} alt="Solida" sx={[classes.logo, loading && classes.loadingLogo]} /> 
    );
};

export default Logo;