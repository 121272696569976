import EditorEffectActorSchedule from './EditorEffectActorSchedule';
import EditorEffectCheckbox from './EditorEffectCheckbox';
import EditorEffectCorporationId from './EditorEffectCorporationId';
import EditorEffectCurrency from './EditorEffectCurrency';
import EditorEffectDate from './EditorEffectDate';
import EditorEffectFile from './EditorEffectFile';
import EditorEffectIf from './EditorEffectIf';
import EditorEffectIfNot from './EditorEffectIfNot';
import EditorEffectIfTrue from './EditorEffectIfTrue';
import EditorEffectIndividualId from './EditorEffectIndividualId';
import EditorEffectList from './EditorEffectList';
import EditorEffectMovablePropertyLeaseDetails from './EditorEffectMovablePropertyLeaseDetails';
import EditorEffectNumber from './EditorEffectNumber';
import EditorEffectPaymentSchedule from './EditorEffectPaymentSchedule';
import EditorEffectRadio from './EditorEffectRadio';
import EditorEffectRadioGrid from './EditorEffectRadioGrid';
import EditorEffectSchedule from './EditorEffectSchedule';
import EditorEffectSelect from './EditorEffectSelect';
import EditorEffectSpecial from './EditorEffectSpecial';
import EditorEffectText from './EditorEffectText';

const EditorEffectTypes = (props) => {
    const acceptedComponents = {
        actorSchedule: EditorEffectActorSchedule,
        checkbox: EditorEffectCheckbox,
        corporationId: EditorEffectCorporationId,
        currency: EditorEffectCurrency,
        date: EditorEffectDate,
        file: EditorEffectFile,
        if: EditorEffectIf,
        ifNot: EditorEffectIfNot,
        ifTrue: EditorEffectIfTrue,
        individualId: EditorEffectIndividualId,
        list: EditorEffectList,
        movablePropertyLeaseDetails: EditorEffectMovablePropertyLeaseDetails,
        number: EditorEffectNumber,
        paymentSchedule: EditorEffectPaymentSchedule,
        radio: EditorEffectRadio,
        radioGrid: EditorEffectRadioGrid,
        schedule: EditorEffectSchedule,
        select: EditorEffectSelect,
        special: EditorEffectSpecial,
        text: EditorEffectText,
    }
    
    if(props.type){
        const EditorEffectType = acceptedComponents[props.type];
        if(EditorEffectType){
            return (
                <EditorEffectType {...props} />
            );
        }
    }
    return null;
};

export default EditorEffectTypes;