import Box from '@mui/material/Box';

function DashboardBox({children, id, p, style}){
    return (
        <Box
            id={id}
            sx={{
                height: theme => theme.spacing(30),
                overflowY: 'auto',
                backgroundColor: '#f5f6fa', // theme.palette.background.paper,
                borderRadius: '8px',
                boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.08)'
            }}
            p={p || 1}
            style={style}
        >
            {children}
        </Box>
    );
}

export default DashboardBox;