import { useEffect, useState } from 'react';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import DocumentFlag from '../../../../../../../../components/DocumentFlag';
import Tooltip from '../../../../../../../../components/Tooltip';
import { useOperatorCtxProjects } from '../../../../../../context/OperatorContext';
import useWindowSize from '../../../../../../../../hooks/useWindowSize';

const classes = {
    link: {
        color: theme => theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
};

const Span = styled('span')(unstable_styleFunctionSx);

const PendingDocumentListItemTop = ({handleDocumentProjectNameClick, pendingDocument}) => {
    const projects = useOperatorCtxProjects();
    const [useGroups, setUseGroups] = useState(false);
    const { windowWidth } = useWindowSize();

    useEffect(() => {
        let currentUseGroups = false;
        if(pendingDocument){
            const folderId = pendingDocument.project;
            if(folderId){
                const foundFolder = projects.find(scannedFolder => scannedFolder._id === folderId);
                if(foundFolder){
                    currentUseGroups = !!foundFolder.useGroups;
                }
            }
        }
        setUseGroups(currentUseGroups);
    }, [pendingDocument, projects]);

    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={!!pendingDocument.flag ? 3 : 4} sm={2} md={!!pendingDocument.flag ? 1 : 2}>
                <Typography variant="body2" noWrap>{moment(pendingDocument.timestamp).format('D/M/YY H:mm')}</Typography>
            </Grid>
            {
                (windowWidth && windowWidth >= 960) &&
                <Grid item xs={3} sm={3} md={!!pendingDocument.flag ? 2 : 3}>
                    <Grid container alignItems="center" wrap="nowrap" style={{width: '100%'}}>
                        <Grid item style={{width: 24}}>
                            <PersonOutlineOutlinedIcon fontSize="small" style={{color: 'rgba(0, 0, 0, 0.54)'}} />
                        </Grid>
                        <Grid item style={{width: 'calc(100% - 24px)'}}>
                            <Typography variant="body2" noWrap>
                                {
                                    pendingDocument.userName
                                    ? `${pendingDocument.userName}${!pendingDocument.user ? ` (${pendingDocument.userEmail})` : ''}`
                                    : pendingDocument.createdByName
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            }
            <Grid item xs={!!pendingDocument.flag ? 6 : 8} sm={!!pendingDocument.flag ? 6 : 10} md={!!pendingDocument.flag ? 6 : 7}>
                <Grid container alignItems="center" wrap="nowrap" style={{width: '100%'}}>
                    <Grid item style={{width: 24}}>
                        <FolderOutlinedIcon fontSize="small" style={{color: 'rgba(0, 0, 0, 0.54)'}} />
                    </Grid>
                    <Grid item style={{width: 'calc(100% - 24px)'}}>
                        <Grid item container alignItems="center" wrap="nowrap" style={{width: '100%'}}>
                            <Grid item style={{maxWidth: `calc(${useGroups ? '33% - 14px' : '50% - 14px'})`}}>
                                <Tooltip text={`Pesquisar outros documentos de ${pendingDocument.companyName}`} enterDelay={300}>
                                    <Typography variant="body2" noWrap><Span sx={classes.link} onClick={() => handleDocumentProjectNameClick(pendingDocument.client, '')}>{pendingDocument.companyName}</Span></Typography>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2" noWrap style={{width: 14}}>&nbsp;|&nbsp;</Typography>
                            </Grid>
                            <Grid item style={{maxWidth: `calc(${useGroups ? '34%' : '50%'})`}}>
                                <Tooltip text={`Pesquisar outros documentos da pasta ${pendingDocument.folderName}`} enterDelay={300}>
                                    <Typography variant="body2" noWrap><Span sx={classes.link} onClick={() => handleDocumentProjectNameClick(pendingDocument.client, pendingDocument.project)}>{pendingDocument.folderName}</Span></Typography>
                                </Tooltip>
                            </Grid>
                            {
                                useGroups &&
                                <>
                                    <Grid item>
                                        <Typography variant="body2" noWrap style={{width: 14}}>&nbsp;|&nbsp;</Typography>
                                    </Grid>
                                    <Grid item style={{maxWidth: `calc(33% - 14px)`}}>
                                        <Typography variant="body2" noWrap>{pendingDocument.groupName}</Typography>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {
                !!pendingDocument.flag &&
                <Grid item xs={3} sm={4} md={3}>
                    <DocumentFlag
                        activeUserIsOperator
                        iconClass="spinningIcon"
                        data={{
                            flag: pendingDocument.flag,
                            flaggedAt: pendingDocument.flaggedAt,
                            flaggedBy: pendingDocument.flaggedBy,
                            flaggedByUserName: pendingDocument.flaggedByName
                        }}
                    />
                </Grid>
            }
        </Grid>
    );
};

export default PendingDocumentListItemTop;