import React from 'react';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const FormDialog = ({
    errorMessage, fullWidth, id, maxWidth, multiline, open, onEnter, onClose,
    title, text, textFieldLabel, textFieldId, textFieldType, textFieldValue, textFieldOnChange,
    cancelButtonText, cancelButtonOnClick, okButtonText, okButtonOnClick, okButtonDisabled
}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby={id}
            maxWidth={maxWidth}
            fullWidth={fullWidth}
            TransitionProps={{
                onEnter
            }}>
            <DialogTitle id={id}>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{text}</DialogContentText>
                <TextField
                    variant="standard"
                    autoFocus
                    margin="dense"
                    multiline={multiline}
                    id={textFieldId}
                    label={textFieldLabel}
                    type={textFieldType || 'text'}
                    value={textFieldValue}
                    onChange={textFieldOnChange}
                    onKeyPress={(e) => {if(!multiline && e.key === 'Enter') okButtonOnClick()}}
                    fullWidth />
                {
                    errorMessage &&
                    <FormHelperText error>
                        {errorMessage}
                    </FormHelperText>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={cancelButtonOnClick || onClose}>{cancelButtonText || 'Cancelar'}</Button>
                <Button disabled={okButtonDisabled} onClick={okButtonOnClick} variant="outlined" color="primary">{okButtonText || 'Ok'}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default FormDialog;