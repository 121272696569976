import React from 'react';
import Button from '@mui/material/Button';
import Drawer from './Drawer';
import ReadDocumentForm from './Documents/ReadDocumentForm';
import { useDocumentsAPI, useDocumentsCtxDocumentFormView } from '../context/DocumentsContext';

const DocFormView = () => {
    const { hideDocumentFormView } = useDocumentsAPI();
    const { documentFormViewOpen, documentFormViewSelectedDocument: selectedDocument } = useDocumentsCtxDocumentFormView();

    const handleClose = () => {
        hideDocumentFormView();
    };

    return (
        <Drawer maxWidth={599} title={selectedDocument?.name} open={documentFormViewOpen} onClose={handleClose}>
            <ReadDocumentForm documentForm={selectedDocument?.form} talentForm={selectedDocument?.talentForm} />
            <Button onClick={handleClose}>Fechar</Button>
        </Drawer>
    );
};

export default DocFormView;