import { useRef } from 'react';
import Badge from '@mui/material/Badge';
import Fab from '@mui/material/Fab';
import ChatIcon from '@mui/icons-material/Chat';
import Chat from '../../pages/Operator/Home/Chat';
import PopOver from '../PopOver';
import { useOperatorCtxAPI, useOperatorCtxChat } from '../../pages/Operator/context/OperatorContext';

const classes = {
    container: {
        width: [`${280}px`, `${600}px`],
    }
};

const MessagesWindow = () => {
    const { setState: setOperatorCtxState } = useOperatorCtxAPI();
    const { messagesWindowOpen, numberOfUnreadMessages } = useOperatorCtxChat();
    const messageFabRef = useRef();

    const setMessagesWindowOpen = (newValue) => {
        setOperatorCtxState('messagesWindowOpen', newValue);
    };
    
    const handleClose = () => {
        setMessagesWindowOpen(false);
    };

    return (
        <>
            <PopOver
                open={messagesWindowOpen}
                anchorEl={messageFabRef.current}
                onClose={handleClose}
                anchorOrigin={{vertical: 'center', horizontal: 'center'}}
                transformOrigin={{vertical: 'bottom', horizontal: 'right'}}
                firstBoxSx={classes.container}
                hideCloseButton
            >
                <Chat />
            </PopOver>

            <Fab
                ref={messageFabRef}
                color="primary"
                style={{
                    visibility: !!numberOfUnreadMessages || messagesWindowOpen ? 'visible' : 'hidden',
                    position: 'fixed',
                    bottom: 4,
                    right: 4,
                    zIndex: 9999
                }}
                onClick={() => setMessagesWindowOpen(!messagesWindowOpen)}
            >
                <Badge badgeContent={numberOfUnreadMessages} color="secondary">
                    <ChatIcon />
                </Badge>
            </Fab>

        </>
    );
};

export default MessagesWindow;