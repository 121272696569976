import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';

const InputField = ({formRef, questionKey, checkSubmitButtonDisabled}) => {
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if(formRef.current[questionKey]){
            setInputValue(formRef.current[questionKey].input || '');
        }
    }, []);

    const handleChange = (newValue) => {
        setInputValue(newValue);

        formRef.current[questionKey].input = newValue;
    };

    return (
        <TextField
            variant="standard"
            fullWidth
            placeholder="Sua resposta"
            value={inputValue}
            onChange={(e) => handleChange(e.target.value)}
            onBlur={checkSubmitButtonDisabled} />
    );
};

export default InputField;