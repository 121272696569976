import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { updateStateChangeArrayItemWith_id, serverRequest } from '../../utils/common';
import { SERVER_PATH } from '../../utils/constants';
import * as markerjs2 from 'markerjs2';

const Img = styled('img')(unstable_styleFunctionSx);

const ClearanceTimeCodeScreenshot = (props) => {
    const { allowAnnotations, selectedClearanceItem, setSelectedClearanceItem, selectedReport, setClearanceItems, windowOpen } = props;
    const [usingImageEditor, setUsingImageEditor] = useState(false);
    const [mouseIsOver, setMouseIsOver] = useState(false);
    const [imgSrc, setImgSrc] = useState('');
    const [errorText, setErrorText] = useState('');
    const imgParentRef = useRef(null);
    const imgRef = useRef(null);
    const markerAreaState = useRef(null);

    useEffect(() => {
        if(selectedClearanceItem){
            markerAreaState.current = selectedClearanceItem.screenShotMarkers || null;
            if(selectedClearanceItem.screenShot){
                const currentImgSrc = `${SERVER_PATH}/${selectedClearanceItem.screenShotWithMarkers || selectedClearanceItem.screenShot}`
                return setImgSrc(currentImgSrc);
            }
        }
        setImgSrc('');
    }, [selectedClearanceItem, windowOpen]);

    const handleMouseOver = () => {
        setMouseIsOver(true);
    };

    const handleMouseOut = () => {
        setMouseIsOver(false);
    };

    const saveMarkers = async (screenShotMarkers, screenShotWithMarkers) => {
        try {
            const folderId = selectedClearanceItem.folderId;
            const reportId = selectedClearanceItem.reportId;
            const bookmarkId = selectedClearanceItem._id;
            const updatedBookmark = await serverRequest({
                path: `/data/folders/${folderId}/clearance/reports/${reportId}/bookmarks/${bookmarkId}`,
                method: 'put',
                data: {
                    screenShotMarkers,
                    screenShotWithMarkersBase64String: screenShotWithMarkers
                }
            });
            updateStateChangeArrayItemWith_id(updatedBookmark, setClearanceItems);
            setSelectedClearanceItem(updatedBookmark);
        } catch (error) {
            setErrorText(error);
        }
    };

    const showMarkerArea = (e) => {
        e.stopPropagation();
        setUsingImageEditor(true);
        if (imgRef.current !== null) {
            setErrorText('');
            const markerArea = new markerjs2.MarkerArea(imgRef.current);
            markerArea.targetRoot = imgParentRef.current;
            markerArea.renderImageType = 'image/webp';
            markerArea.renderAtNaturalSize = true;
            markerArea.renderImageQuality = 1;
            markerArea.addEventListener('render', event => {
                const screenShotMarkers = event.state;
                markerAreaState.current = screenShotMarkers;
                const screenShotWithMarkers = event.dataUrl;
                setImgSrc(screenShotWithMarkers);
                saveMarkers(screenShotMarkers, screenShotWithMarkers);
            });
            markerArea.addEventListener('beforeclose', event => {
                if(!window.confirm('Descartar alterações?')){
                    event.preventDefault();
                }
            });
            markerArea.show();
            if (markerAreaState.current) {
                setImgSrc(`${SERVER_PATH}/${selectedClearanceItem.screenShot}`);
                markerArea.restoreState(markerAreaState.current);
            }
          }
    };

    const handlePlayButtonClick = () => {
        if(usingImageEditor) return;

        let playButtonUrl = '';
        if(selectedReport.videoPlatform === 'youTube'){
            playButtonUrl = `https://www.youtube.com/watch?v=${selectedReport.videoPlatformId}&t=${selectedClearanceItem.cutSeconds}s`;
        } else if(selectedReport.videoPlatform === 'vimeo'){
            playButtonUrl = `https://vimeo.com/${selectedReport.videoPlatformId}#t=${selectedClearanceItem.cutSeconds}s`;
        } else {
            playButtonUrl = selectedReport.videoPlatformUrl;
        }
        if(playButtonUrl){
            window.open(playButtonUrl, '_blank');
        }
    }

    if(selectedReport && selectedReport.videoPlatform && selectedReport.videoPlatformId && selectedClearanceItem && selectedClearanceItem.cutSeconds){

        if(selectedClearanceItem.screenShot){
            return (
                <Box mb={2}>
                    <Grid container justifyContent="center">
                        <Grid
                            sx={{
                                position: 'relative',
                                cursor: 'pointer',
                                opacity: 1,
                                transition: 'opacity .2s linear',
                                '&:hover': {
                                    opacity: usingImageEditor ? 1 : .85,
                                },
                            }}
                            onClick={handlePlayButtonClick}
                            onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}
                        >
                            <div ref={imgParentRef}>
                                {
                                    imgSrc &&
                                    <Img
                                        ref={imgRef}
                                        src={imgSrc}
                                        alt={selectedClearanceItem.description}
                                        style={{ maxWidth: '100%'}}
                                    />
                                }
                            </div>
                            {
                                (!usingImageEditor && selectedReport.videoPlatform && selectedReport.videoPlatformId && selectedClearanceItem.cutSeconds) &&
                                <PlayCircleIcon
                                    sx={{
                                        opacity: 0,
                                        transition: 'opacity .2s linear',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)'
                                    }}
                                    style={{ opacity: mouseIsOver ? 1 : 0 }}
                                    fontSize="large" htmlColor="#f5f5f5" />
                            }
                            {
                                allowAnnotations &&
                                <IconButton
                                    sx={{
                                        backgroundColor: 'background.paper',
                                        cursor: 'pointer',
                                        position: 'absolute',
                                        bottom: '8px',
                                        right: '8px',
                                        opacity: 0.6,
                                        transform: 'translate(8px)',
                                        transition: 'opacity .2s linear',
                                        '&:hover': {
                                            opacity: 0.9,
                                            backgroundColor: 'background.paper',
                                        }
                                    }}
                                    onClick={showMarkerArea}
                                    size="large" color="primary"
                                    // fontSize="large" htmlColor="#f5f5f5"
                                >
                                    <EditIcon />
                                </IconButton>
                            }
                        </Grid>
                    </Grid>
                    {
                        errorText &&
                        <FormHelperText error>{errorText}</FormHelperText>
                    }
                </Box>
            );
        }

        return (
            <Box mb={2}>
                <Grid container justifyContent="center">
                    <Grid>
                        <Button startIcon={<PlayCircleIcon />} onClick={handlePlayButtonClick}>{selectedClearanceItem.cutTimeCode}</Button>
                    </Grid>
                </Grid>
            </Box>
        );

    }

    return null;
};

export default ClearanceTimeCodeScreenshot;