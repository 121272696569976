import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ClearIcon from '@mui/icons-material/Clear';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileSignature } from '@fortawesome/free-solid-svg-icons';
import CenteredCircularProgress from '../CenteredCircularProgress';
import Files from './Files';
import Signers from './Signers';
import PositionSignaturesWindow from './PositionSignaturesWindow';
import { serverRequest } from '../../utils/common';
import { ERROR_MESSAGE_UNKNOWN, SERVER_PATH } from '../../utils/constants';
import { useAppCtxAPI, useAppCtxActiveUser } from '../../context/SystemContext';
import { useDocumentsAPI, useDocumentsCtxLoadingSignatures, useDocumentsCtxSignatureEnvelope, useDocumentsCtxSignDocumentView } from '../../context/DocumentsContext';
import useAppVersion from '../../hooks/useAppVersion';

const SignDocumentWindow = () => {
    const { floatingAlert, toast } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const { hideSignDocumentView, setLoadingSignatures } = useDocumentsAPI();
    const loadingSignatures = useDocumentsCtxLoadingSignatures();
    const eSignatureEnvelope = useDocumentsCtxSignatureEnvelope();
    const {
        signDocumentViewCompanyFolder,
        signDocumentViewDefaultSigners,
        signDocumentViewDocument,
        signDocumentViewDocumentName,
        signDocumentViewFolderFolder,
        signDocumentViewFolderId,
        signDocumentViewFolderName,
        signDocumentViewOpen,
        signDocumentViewSignaturePlatform,
        // eSignatureEnvelope
    } = useDocumentsCtxSignDocumentView();
    const [originalBase64String, setOriginalBase64String] = useState(null);
    const [base64String, set_base64String] = useState(null);
    const [files, setFiles] = useState([]);
    const [availableFiles, setAvailableFiles] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [signers, set_signers] = useState(null);
    const defaultSigner = {
        fullName: '',
        documentation: '',
        email: '',
        mobile: '',
        type: 'party',
        brazilian: true,
        locked: false
    };
    const [boxes, set_boxes] = useState([]);
    const [positionSignaturesWindowOpen, set_positionSignaturesWindowOpen] = useState(false);
    const [orderSignatures, set_orderSignatures] = useState(false);
    const [loadingDocument, setLoadingDocument] = useState(false);
    const updateRequired = useAppVersion();
    const [documentDimensions, setDocumentDimensions] = useState(null);
    const [loadingESignatureStatus, set_loadingESignatureStatus] = useState('');
    const [loadingESignatureProgress, set_loadingESignatureProgress] = useState(0);

    const getSigners = () => {
        let currentSigners = [];
        let initialSigners = signDocumentViewDefaultSigners;
        if(eSignatureEnvelope?.signers) initialSigners = eSignatureEnvelope.signers;
        if(!initialSigners){
            currentSigners = [
                {...defaultSigner, id: uuidv4()},
                {...defaultSigner, id: uuidv4()},
                {...defaultSigner, type: 'witness', id: uuidv4()},
                {...defaultSigner, type: 'witness', id: uuidv4()}
            ];
        } else {
            currentSigners = initialSigners.map(initialSigner => {
                const signer = {...defaultSigner, ...initialSigner};
                if(!signer.id) signer.id = uuidv4();
                signer.fullName = signer.fullName ? signer.fullName.trim() : '';
                signer.email = signer.email ? signer.email.trim() : '';
                signer.documentation = signer.documentation ? signer.documentation.trim() : '';
                return signer;
            });
        }
        set_signers(currentSigners);
    };

    useEffect(() => {
        if(signDocumentViewOpen){
            set_positionSignaturesWindowOpen(false);
            setOriginalBase64String(null);
            set_base64String(null);
            set_boxes([]);
            getSigners();
            setLoadingDocument(true);
        }
    }, [signDocumentViewOpen]);

    useEffect(() => {
        if(signDocumentViewDocument){
            const documentFiles = signDocumentViewDocument.files.map(file => {
                const fileName = file.split(/\\|\//).pop();
                return { path: file, fileName };
            });
            setFiles(documentFiles);
            setAvailableFiles(documentFiles);
            setSelectedFiles([]);
        }
    }, [signDocumentViewDocument]);

    useEffect(() => {
        if(signDocumentViewOpen && eSignatureEnvelope){
            if(eSignatureEnvelope.documents[0]){
                getBase64String();
            }
            if(['UPDATE DOCUMENT', 'UPDATE TABS'].includes(eSignatureEnvelope.action)){

                if(eSignatureEnvelope.action === 'UPDATE TABS' && eSignatureEnvelope.tabs){
                    set_boxes(eSignatureEnvelope.tabs.filter(eSignatureTab => eSignatureTab.left && eSignatureTab.signer && eSignatureTab.top));
                }
                
                set_positionSignaturesWindowOpen(true);
            }
        }
    }, [signDocumentViewOpen, eSignatureEnvelope]);

    const getBase64String = async () => {
        await axios.post(SERVER_PATH + '/data/documents/base64', {document: eSignatureEnvelope.documents[0]})
        .then(res => {
            setOriginalBase64String(res.data);
            set_base64String(res.data);
        })
        .catch(err => {
            floatingAlert(err?.response?.data || ERROR_MESSAGE_UNKNOWN, 'error');
        });
        setLoadingDocument(false);
    };
    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };
    const positionSignatures = async () => {
        let allValid = true;
        let oneSigner = false;
        if(signers.length === 0){
            return toast(`É preciso especificar pelo menos um signatário.`);;
        }
        signers.forEach((signer, index) => {
            const signerName = signer.fullName ? `de ${signer.fullName}` : `do(a) signatário ${signer.email || (index + 1)}` ;
            if(!signer.fullName){
                toast(`É preciso preencher o nome completo ${signerName}.`);
                allValid = false;
                return;
            }
            if(!validateEmail(signer.email)){
                toast(`O endereço de e-mail ${signerName} não é válido.`);
                allValid = false;
                return;
            }
            if(signer.type !== 'observer'){
                oneSigner = true;
                if(signer.brazilian && !signer.documentation){
                    toast(`É preciso preencher o CPF ${signerName}.`);
                    allValid = false;
                    return;
                }
            }
        });
        if(!oneSigner){
            toast(`Todas as pessoas foram marcadas como observadoras. É preciso especificar pelo menos um signatário.`);
            allValid = false;
        }
        if(allValid){

            if(selectedFiles.length !== 0){
                setLoadingDocument(true);
                await axios.post(SERVER_PATH + '/data/documents/merge-pdfs-base64', {
                    base64String: originalBase64String,
                    files: selectedFiles
                })
                .then(res => {
                    set_base64String(res.data);
                    setTimeout(() => {
                        set_positionSignaturesWindowOpen(true);
                        setLoadingDocument(false);
                    }, 2000);
                })
                .catch(err => {
                    set_base64String(originalBase64String);
                    setTimeout(() => {
                        floatingAlert(err?.response?.data || ERROR_MESSAGE_UNKNOWN, 'error');
                        set_positionSignaturesWindowOpen(true);
                        setLoadingDocument(false);
                    }, 2000);
                });
            } else {
                set_base64String(originalBase64String);
                set_positionSignaturesWindowOpen(true);
            }
        }
    };

    const eSignatureSentCallback = async (data) => {
        let { base64String, documentData, eSignaturePlatform, eSignatureData, eSignatureTabs, signers } = data;

        if(eSignatureEnvelope?.action === 'UPDATE TABS'){
            eSignatureEnvelope.setDocuments(eSignatureData);
            if(eSignatureEnvelope.eSignatureCallback) eSignatureEnvelope.eSignatureCallback();
            setLoadingSignatures(false);
            toast('Posições de assinaturas alteradas.');
            return;
        }

        documentData = JSON.parse(documentData);
        const request = {
            base64String,
            [eSignatureEnvelope.documents[0].folder === 'proposals' ? 'proposalId' : 'parentDocumentId']: documentData.id,
            eSignatureEnvelope: eSignatureEnvelope,
            eSignaturePlatform,
            eSignaturePlatformId: eSignatureData.token || eSignatureData.envelopeId,
            eSignatureSigners: eSignatureData.signers || signers,
            eSignatureTabs,
            folderId: signDocumentViewFolderId,
            orderSignatures: !!documentData.orderSignatures
        };  
        try {
            let path = '/data/documents/sign/document/save';
            if(eSignatureEnvelope.documents[0].folder === 'proposals'){
                path = '/data/accounting/proposals/document/sign';
            }
            const updatedDocument = await serverRequest({
                path,
                method: 'post',
                data: request
            });
            eSignatureEnvelope.setDocuments(updatedDocument);
            if(eSignatureEnvelope.setSelectedDocument) eSignatureEnvelope.setSelectedDocument(updatedDocument);
            if(eSignatureEnvelope.eSignatureCallback) eSignatureEnvelope.eSignatureCallback();
            setLoadingSignatures(false);
            toast(eSignatureData.placeSignaturesError || `O documento foi enviado por e-mail para assinatura.`);
        } catch (error) {
            console.log(error);
            setLoadingSignatures(false);
            toast(error?.response?.data);
        }
    };

    const signDocument = async () => {
        setLoadingSignatures(true);
        set_loadingESignatureProgress(10);
        set_loadingESignatureStatus('Preparando o documento...');
        setTimeout(() => {
            set_loadingESignatureProgress(50);
            set_loadingESignatureStatus('Enviando para a plataforma de assinatura eletrônica...');
        }, 2000);
        try {
            const parseDocument = JSON.parse(eSignatureEnvelope.stringifiedDocument);
            let rubricas = [];

            boxes.forEach(box => {
                let rubrica = {
                    ...box,
                    page: signDocumentViewSignaturePlatform === 'docuSign' ? box.page : box.page - 1,
                    relative_position_bottom: box.pageRelative_position_bottom,
                    relative_position_left: box.pageRelative_position_left,
                    relative_size_x: 19.55, 
                    relative_size_y: 9.42,
                    docuSignPositionY: box.pageDocuSignPositionY,
                    docuSignPositionX: box.pageDocuSignPositionX,
                    type: box.type === 'signature' ? 'signature' : 'visto',
                    signer_token: box.signer
                };
                rubricas.push(rubrica);
            });
            parseDocument.orderSignatures = orderSignatures;
            const stringifiedDocument = JSON.stringify(parseDocument);
            try {
                let method = 'post';
                let path = '/data/documents/sign';
                if(eSignatureEnvelope?.action === 'UPDATE DOCUMENT'){
                    path = '/data/documents/sign/document/update';
                } else if(eSignatureEnvelope?.action === 'UPDATE TABS'){
                    method = 'put';
                    path = `/data/documents/${eSignatureEnvelope.selectedDocument._id}/versions/${eSignatureEnvelope.updatedVersionId}/signatures/tabs`;
                }
                const eSignatureResponse = await serverRequest({
                    path, method,
                    data: {
                        base64String,
                        clientFolder: signDocumentViewCompanyFolder,
                        eSignatureEnvelope,
                        projectFolder: signDocumentViewFolderFolder, //?TODO
                        documentData: stringifiedDocument,
                        documentDimensions,
                        documentName: signDocumentViewDocumentName,
                        folderId: signDocumentViewFolderId,
                        eSignaturePlatform: signDocumentViewSignaturePlatform, //?TODO
                        orderSignatures,
                        projectName: signDocumentViewFolderName, //?TODO
                        senderName: activeUser?.fullName,
                        senderEmail: activeUser?.email,
                        signers,
                        rubricas,

                        instructions: 'shouldNotAddOnePage'
                    }
                });
                set_loadingESignatureProgress(75);
                set_loadingESignatureStatus('Gravando informações na base de dados...');
                eSignatureSentCallback({
                    base64String,
                    documentData: stringifiedDocument,
                    eSignaturePlatform: signDocumentViewSignaturePlatform,
                    eSignatureData: eSignatureResponse, eSignatureTabs: rubricas,
                    signers
                });
            } catch (error) {
                setLoadingSignatures(false);
                toast(error, 'bottom');
            }
        } catch (error) {
            setLoadingSignatures(false);
            toast(error?.response?.data, 'bottom');
        }
    };

    const handleBackButtonClick = () => {
        if(['UPDATE DOCUMENT', 'UPDATE TABS'].includes(eSignatureEnvelope?.action)){
            hideSignDocumentView();
        } else {
            set_positionSignaturesWindowOpen(false);
        }
    }

    const handleClose = () => {
        hideSignDocumentView();
    };

    const platforms = {
        docuSign: <Link href="https://www.docusign.com/" target="_blank" underline="hover">DocuSign</Link>,
        zapSign: <Link href="https://zapsign.com.br/" target="_blank" underline="hover">ZapSign</Link>
    };
    
    return (
        <Dialog
            open={signDocumentViewOpen} onClose={handleClose}
            disableEscapeKeyDown={loadingSignatures}
            fullScreen
        >
            <AppBar style={{position: 'relative'}}>
                <Toolbar>
                    {
                        (positionSignaturesWindowOpen && !loadingDocument)
                        ?
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleBackButtonClick}
                            aria-label="close"
                            size="large"
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        :
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            size="large"
                        >
                            <ClearIcon />
                        </IconButton>
                    }
                    <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
                        Assinatura eletrônica
                    </Typography>
                    {
                        !loadingDocument &&
                        <>
                            {
                                positionSignaturesWindowOpen
                                ?
                                <>
                                    {
                                        !loadingSignatures &&
                                        <Button
                                            color="inherit"
                                            endIcon={<FontAwesomeIcon icon={faFileSignature} />}
                                            onClick={signDocument}
                                        >
                                            Enviar
                                        </Button>
                                    }
                                </>
                                :
                                <Button
                                    disabled={!base64String}
                                    color="inherit"
                                    endIcon={loadingDocument ? <CircularProgress size={16} /> : <ArrowForwardIcon />}
                                    onClick={positionSignatures}
                                >
                                    Continuar
                                </Button>
                            }
                        </>
                    }
                </Toolbar>
            </AppBar>
            <DialogContent>
                <Box mb={2}>
                    <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
                        <Grid>
                            <Typography variant="body1">A assinatura será realizada via {platforms[(eSignatureEnvelope?.eSignaturePlatform || signDocumentViewSignaturePlatform) || 'zapSign']}</Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box mb={2}>
                    <Typography variant="h2">{eSignatureEnvelope?.name || signDocumentViewDocumentName}</Typography>
                </Box>
                
                {
                    !positionSignaturesWindowOpen
                    ?
                    <Box>
                        <Files availableFiles={availableFiles} setAvailableFiles={setAvailableFiles} files={files} setFiles={setFiles} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} />
                        <Signers
                            defaultSigner={defaultSigner}
                            eSignaturePlatform={signDocumentViewSignaturePlatform}
                            orderSignatures={orderSignatures} setOrderSignatures={set_orderSignatures}
                            signers={signers} set_signers={set_signers}
                        />
                        <Box style={{textAlign: 'center'}}>
                            {
                                updateRequired
                                ? <FormHelperText error>Você deve atualizar o SOLIDA para enviar documentos para assinatura. Recarregue a página (no Windows, pressione Control + Shift + R ou Control + F5 ou Shift + F5  / no Mac, pressione Apple + R ou Command + R).</FormHelperText>
                                :
                                <Grid container spacing={1} alignItems="center" justifyContent="center">
                                    <Grid>
                                        <Button variant="text" onClick={handleClose}>Cancelar</Button>
                                    </Grid>
                                    <Grid>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={!base64String}
                                            startIcon={loadingDocument ? <CircularProgress size={16} /> : <ArrowForwardIcon />}
                                            onClick={positionSignatures}
                                        >Continuar</Button>
                                    </Grid>
                                </Grid>
                            }
                        </Box>
                    </Box>
                    :
                    <Box>
                        {
                            loadingDocument
                            ? <CenteredCircularProgress />
                            :
                            <PositionSignaturesWindow
                                base64String={base64String}
                                boxes={boxes} setBoxes={set_boxes}
                                handleBackButtonClick={handleBackButtonClick}
                                handleSignDocumentButtonClick={signDocument}
                                loadingESignatureProgress={loadingESignatureProgress}
                                loadingESignatureStatus={loadingESignatureStatus}
                                positions={boxes} setPositions={set_boxes}
                                selectedFiles={selectedFiles}
                                setDocumentDimensions={setDocumentDimensions}
                                signers={signers}
                            />
                        }
                    </Box>
                }
            </DialogContent>
        </Dialog>
    );
};

export default SignDocumentWindow;