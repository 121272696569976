import { memo } from 'react';
import { Box } from '@mui/material';
import TaskBox from './TaskBox';

const TasksList = memo(({
    children,

    boardId,
    innerRef,
    isActiveUserTask,
    isDraggingOver,
    tasks,
}) => {
    let backgroundColor = 'white';
    if (isDraggingOver) {
        backgroundColor = '#e8eaf6';
    }
    return (
        <div ref={innerRef}>
            {
                tasks &&
                <Box
                    sx={{
                        transition: 'background-color 0.2s ease',
                        borderRadius: '8px',
                        paddingBottom: '4px',
                    }}
                    style={{backgroundColor}}
                >
                    {
                        tasks
                        .map((task, taskIndex) => (
                            <TaskBox
                                key={task.uuid || task._id}
                                boardId={boardId}
                                index={taskIndex}
                                isActiveUserTask={isActiveUserTask}
                                task={task}
                            />
                        ))
                    }
                    {children}
                </Box>
            }
        </div>
    );
}, (prevProps, nextProps) => {
    return false; // props are not equal -> update the component
});

export default TasksList;