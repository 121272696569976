import { useEffect, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { ERROR_MESSAGE_CHANGES_UNDONE } from '../utils/constants';
import { useAppCtxAPI, useAppCtxActiveUser } from '../context/SystemContext';
import { useSocket } from '../context/SocketContext';
import { useClientCtxActiveClient } from '../context/ClientContext';
import { useDocumentsAPI, useDocumentsCtxChangeDocumentGroupView } from '../context/DocumentsContext';

const ChangeDocumentGroupWindow = ({projects, updateDocument}) => {
    const { toast, updateOneOperators } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const activeClient = useClientCtxActiveClient();
    const { hideChangeDocumentGroupView } = useDocumentsAPI();
    const { changeDocumentGroupViewAnchorEl, changeDocumentGroupViewOpen, changeDocumentGroupViewSelectedDocument } = useDocumentsCtxChangeDocumentGroupView();
    const [groupOptions, set_groupOptions] = useState([]);
    const [selectedGroup, set_selectedGroup] = useState('');
    const {createSocketConnection} = useSocket();
    const socket = createSocketConnection();

    useEffect(() => {
        if(changeDocumentGroupViewOpen && changeDocumentGroupViewSelectedDocument){
            const documentProjectId = changeDocumentGroupViewSelectedDocument.project || changeDocumentGroupViewSelectedDocument.client;
            const documentProject = projects.find(documentProject => documentProject._id === documentProjectId);
            if(documentProject && documentProject.useGroups){
                const projectGroups = documentProject.groups.map(documentProjectGroup => ({value: documentProjectGroup.id, label: documentProjectGroup.name}));
                set_groupOptions(projectGroups);
                set_selectedGroup(changeDocumentGroupViewSelectedDocument.groupId)
            } else {
                handleClose();
            }
        }
    }, [changeDocumentGroupViewOpen, changeDocumentGroupViewSelectedDocument]);

    const handle_selectedGroupChange = async (newValue) => {
        set_selectedGroup(newValue);
        handleClose();
        const prevDocument = {...changeDocumentGroupViewSelectedDocument};
        const updates = { groupId: newValue };
        const updatedDocument = {...changeDocumentGroupViewSelectedDocument, ...updates};
        updateDocument(updatedDocument);
        try {
            await updateOneOperators('Documents', changeDocumentGroupViewSelectedDocument._id, updates);
            socket.emit('DOCUMENT GROUP CHANGED', {
                clientId: activeClient._id, workspaceName: activeClient.name,
                actionUserId: activeUser._id, actionUserName: activeUser.fullName,
                documentGroupId: newValue,
                documentName: changeDocumentGroupViewSelectedDocument.name,
                updatedDocument
            });
        } catch (error) {
            updateDocument(prevDocument);
            toast(ERROR_MESSAGE_CHANGES_UNDONE);
        }
    };

    const handleClose = () => {
        hideChangeDocumentGroupView();
    };

    return (
        <Menu
            keepMounted
            anchorEl={changeDocumentGroupViewAnchorEl}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            open={changeDocumentGroupViewOpen}
            onClose={handleClose}
        >
            {
                groupOptions
                .map((groupOption) => (
                    <MenuItem key={groupOption.value}
                        onClick={() => handle_selectedGroupChange(groupOption.value)}
                        selected={groupOption.value === selectedGroup}
                    >
                        <Typography>{groupOption.label}</Typography>
                    </MenuItem>
                ))
            }
        </Menu>
    );
};

export default ChangeDocumentGroupWindow;