import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
    useClearanceCtxAPI,
    useClearanceCtxClearanceQuestionsListFilters,
    useClearanceCtxClearanceQuestionsListFiltersMenu,
    useClearanceCtxClearanceQuestionsListFiltersMenuOptions
} from '../../../../context/ClearanceContext';

const FiltersMenu = () => {
    const { setState: setClearanceCtxState, showClearanceQuestionsListFiltersMenu } = useClearanceCtxAPI();
    const filters = useClearanceCtxClearanceQuestionsListFilters();
    const { clearanceQuestionsListFiltersMenuAnchorEl, clearanceQuestionsListFiltersMenuOpen, clearanceQuestionsListFiltersMenuOptionId } = useClearanceCtxClearanceQuestionsListFiltersMenu();
    const filtersMenuOptions = useClearanceCtxClearanceQuestionsListFiltersMenuOptions();

    const handleMenuOptionClick = (newValue) => {
        setClearanceCtxState('clearanceQuestionsListFilters', (prevState) => ({...prevState, [clearanceQuestionsListFiltersMenuOptionId]: newValue}));
        handleClose();
    };

    const handleClose = () => {
        showClearanceQuestionsListFiltersMenu(false);
    };

    return (
        <Menu
            id="simple-menu"
            anchorEl={clearanceQuestionsListFiltersMenuAnchorEl}
            keepMounted
            open={clearanceQuestionsListFiltersMenuOpen}
            onClose={handleClose}
        >
            {
                clearanceQuestionsListFiltersMenuOptionId &&
                filtersMenuOptions[clearanceQuestionsListFiltersMenuOptionId].map((option, optionIndex) => (
                    <MenuItem
                        key={optionIndex}
                        onClick={() => handleMenuOptionClick(option.value)}
                        selected={option.value === filters[clearanceQuestionsListFiltersMenuOptionId]}
                    >{option.label}</MenuItem>
                ))
            }
        </Menu>
    );
}

export default FiltersMenu;