import Avatar from '@mui/material/Avatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useAppCtxAPI, useAppCtxOperatorsMenu } from '../context/SystemContext';
import { useOperatorCtxOperators } from '../pages/Operator/context/OperatorContext';
import { updateStateChangeArrayItemWith_id } from '../utils/common';
import { ERROR_MESSAGE_CHANGES_UNDONE } from '../utils/constants';

const ChangeOperatorMenu = ({collection, operatorIdKey, setDocuments, operatorChangedCallback}) => {
    const { showOperatorsMenu, toast, updateOneOperators } = useAppCtxAPI();
    const operators = useOperatorCtxOperators();
    const { operatorsMenuAnchorEl, operatorsMenuOpen, operatorsMenuSelectedDocument } = useAppCtxOperatorsMenu();

    const handleOperatorChangeClick = async (operatorId, operatorName) => {
        handleClose();
        const documentCopy = {...operatorsMenuSelectedDocument};
        const newValue = {...operatorsMenuSelectedDocument, [operatorIdKey]: operatorId };
        updateStateChangeArrayItemWith_id(newValue, setDocuments);
        try {
            await updateOneOperators(collection, operatorsMenuSelectedDocument._id, { [operatorIdKey]: operatorId });
            if(operatorChangedCallback) operatorChangedCallback(operatorId, operatorsMenuSelectedDocument.subject, operatorsMenuSelectedDocument._id, operatorName);
        } catch (error) {
            console.log(error);
            toast(ERROR_MESSAGE_CHANGES_UNDONE);
            updateStateChangeArrayItemWith_id(documentCopy, setDocuments);
        }
    };

    const handleClose = () => {
        showOperatorsMenu(false);
    };

    return (
        <Menu
            anchorEl={operatorsMenuAnchorEl}
            open={operatorsMenuOpen}
            onClose={handleClose}
        >
            {
                operators.map((operator, index) => (
                    <MenuItem key={index} onClick={() => handleOperatorChangeClick(operator._id, operator.fullName)}>
                        <ListItemIcon>
                            <Avatar style={{height: 32, width: 32}} alt={operator.screenName} src={`/uploads/avatars/${operator.img}`} />
                        </ListItemIcon>
                        <ListItemText primary={operator.screenName} />
                    </MenuItem>
                ))
            }
        </Menu>
    );
}

export default ChangeOperatorMenu;