import { useEffect, useCallback } from 'react';
import authService from '../../services/authService';
import { SERVER_PATH } from '../../utils/constants';
import { useAppCtxAPI } from '../../context/SystemContext';

const shouldNotLoginModules = ['senha', 'ficha', 'documento', 'proposta'];
const shouldNotRedirectModules = ['clearance', 'formulario'];
const shouldRedirectToLogin = ['advocacia', 'cliente', 'juridico'];

function Auth({children}){
    const { handleNavigate, setActiveUser } = useAppCtxAPI();
    
    const initAuth = useCallback(async () => {
        const pathname = window.location.pathname;
        const module = pathname.split('/')[1];
        
        if(shouldRedirectToLogin.includes(module)){
            return handleNavigate('/');
        }

        if(!shouldNotLoginModules.includes(module)){
            let activeUser = null;
            try {
                activeUser = await authService.isAuthenticated(SERVER_PATH);
            } catch (error) {
                activeUser = null;
            }
            setActiveUser(activeUser);
            if(!shouldNotRedirectModules.includes(module)){
                if(activeUser){
                    if(activeUser.selectedClient && activeUser.workspaceShortName){
                        if(pathname === '/'){
                            return handleNavigate(`/${activeUser.workspaceShortName}/${activeUser.type >= 2 ? 'juridico' : 'pastas'}` );
                        } else {
                            const secondModule = pathname.split('/')[2];
                            if(secondModule === 'juridico'){
                                if(activeUser.type <= 1) return handleNavigate(`/${activeUser.workspaceShortName}/pastas`);
                            } else if(secondModule === 'pastas'){
                                if(activeUser.type >= 2) return handleNavigate(`/${activeUser.workspaceShortName}/juridico`);
                            }
                            return handleNavigate(pathname);
                        }
                    } else {
                        return handleNavigate('/paineis');
                    }
                }
                handleNavigate('/');
            }
        }
    }, []);

    useEffect(() => {
        initAuth();
    }, [initAuth]);

    return (
        children
    );
}

export default Auth;