import React, { useEffect, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import AlertDialog from '../components/AlertDialog';
import { ERROR_MESSAGE_CHANGES_UNDONE } from '../utils/constants';
import { useAppCtxAPI, useAppCtxActiveUser } from '../context/SystemContext';
import { useSocket } from '../context/SocketContext';
import { useClientCtxActiveClient } from '../context/ClientContext';
import { useDocumentsAPI, useDocumentsCtxChangeDocumentFolderView } from '../context/DocumentsContext';

const ChangeDocumentFolderMenu = ({projects, updateDocument}) => {
    const { toast, updateOneOperators } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const activeClient = useClientCtxActiveClient();
    const { hideChangeDocumentFolderView } = useDocumentsAPI()
    const { changeDocumentFolderViewAnchorEl, changeDocumentFolderViewOpen, changeDocumentFolderViewSelectedDocument } = useDocumentsCtxChangeDocumentFolderView();
    const [folderOptions, setFolderOptions] = useState([]);
    const [clickedFolder, setClickedFolder] = useState(null);
    const [selectedFolderId, setSelectedFolderId] = useState('');
    const [confirmActionDialogOpen, setConfirmActionDialogOpen] = useState(false);
    const {createSocketConnection} = useSocket();
    const socket = createSocketConnection();

    useEffect(() => {
        if(projects){
            const foundFolders = projects.filter(foundFolder => foundFolder.client).map(foundFolder => ({value: foundFolder._id, label: foundFolder.name, folderCompanyId: foundFolder.client}));
            setFolderOptions(foundFolders);
        }
    }, [projects]);

    useEffect(() => {
        if(changeDocumentFolderViewOpen && changeDocumentFolderViewSelectedDocument){
            setSelectedFolderId(changeDocumentFolderViewSelectedDocument.project);
        }
    }, [changeDocumentFolderViewOpen, changeDocumentFolderViewSelectedDocument]);

    const handleDocumentFolderOptionClick = (newFolder) => {
        handleClose();
        setClickedFolder(newFolder);
        setConfirmActionDialogOpen(true);
    };

    const handleDocumentFolderChange = async () => {
        setConfirmActionDialogOpen(false)
        const newFolderId = clickedFolder.value
        setSelectedFolderId(newFolderId);
        const prevDocument = {...changeDocumentFolderViewSelectedDocument};
        const updates = {project: newFolderId, client: clickedFolder.folderCompanyId};
        const updatedDocument = {...changeDocumentFolderViewSelectedDocument, ...updates};
        updateDocument(updatedDocument);
        try {
            await updateOneOperators('Documents', changeDocumentFolderViewSelectedDocument._id, updates);
            socket.emit('DOCUMENT FOLDER CHANGED', {
                clientId: activeClient._id, workspaceName: activeClient.name,
                actionUserId: activeUser._id, actionUserName: activeUser.fullName,
                documentFolderId: newFolderId,
                documentName: changeDocumentFolderViewSelectedDocument.name,
                updatedDocument
            });
        } catch (error) {
            updateDocument(prevDocument);
            toast(ERROR_MESSAGE_CHANGES_UNDONE);
        }
    };

    const handleClose = () => {
        hideChangeDocumentFolderView();
    };

    return (
        <React.Fragment>
            <Menu
                keepMounted
                anchorEl={changeDocumentFolderViewAnchorEl}
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                open={changeDocumentFolderViewOpen}
                onClose={handleClose}
            >
                {
                    folderOptions
                    .map((folderOption) => (
                        <MenuItem key={folderOption.value}
                            onClick={() => handleDocumentFolderOptionClick(folderOption)}
                            selected={folderOption.value === selectedFolderId}
                        >
                            <Typography>{folderOption.label}</Typography>
                        </MenuItem>
                    ))
                }
            </Menu>
            <AlertDialog
                open={confirmActionDialogOpen} onClose={() => setConfirmActionDialogOpen(false)}
                text={`Você quer alterar a pasta do documento "${changeDocumentFolderViewSelectedDocument?.name}" para ${clickedFolder?.label}?`}
                okButtonOnClick={handleDocumentFolderChange}
                cancelButtonOnClick={() => {
                    setConfirmActionDialogOpen(false);
                    handleClose();
                }}
            />
        </React.Fragment>
    );
};

export default ChangeDocumentFolderMenu;