import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import DatePicker from './DatePicker';
import moment from 'moment';
import { ERROR_MESSAGE_UNKNOWN, ERROR_MESSAGE_MANDATORY_FIELD_EMPTY, SERVER_PATH, SUCCESS_MESSAGE_SAVED } from '../utils/constants';
import { useAppCtxAPI, useAppCtxLoading } from '../context/SystemContext';
import { useDocumentsAPI, useDocumentsCtxAddDocumentAlert } from '../context/DocumentsContext';

const AddAlertWindow = ({data}) => {
    const descriptionRef = useRef(null);
    const { setList, setDocsAlerts } = data;
    const { setLoading, toast } = useAppCtxAPI();
    const loading = useAppCtxLoading();
    const { hideAddDocumentAlertView } = useDocumentsAPI();
    const { addDocumentAlertViewOpen, documentAlertSelectedDocument: selectedDocument } = useDocumentsCtxAddDocumentAlert();
    const [notificationName, set_notificationName] = useState('');
    const [notificationDate, set_notificationDate] = useState(null);

    useEffect(() => {
        try {
            if(descriptionRef.current){
                descriptionRef.current.focus();
            }    
        } catch (error) {
            console.error(error);
        }
        if(selectedDocument?.alertId){
            set_notificationName(selectedDocument.alertDescription);
            set_notificationDate(moment(selectedDocument.alertDate));
        } else {
            set_notificationName('');
            set_notificationDate(null);
        }
    }, [addDocumentAlertViewOpen]);

    const handleSubmit = async () => {
        if(!notificationName || !notificationDate){
            toast(ERROR_MESSAGE_MANDATORY_FIELD_EMPTY);
        } else {
            handleClose();
            setLoading(true);
            let postData = {
                doc: selectedDocument._id,
                description: notificationName,
                alert: notificationDate.format('YYYY-MM-DD')
            };
            if(selectedDocument.alertId) postData.currentAlert = selectedDocument.alertId;

            axios.post(SERVER_PATH + '/data/client/docs-notifications/save', postData)
            .then(res => {
                const data = res.data;
                if(setList){
                    setList(prevState => {
                        let index = prevState.findIndex(i => i._id === data._id);
                        return index >= 0 ? [...prevState.slice(0, index), {...prevState[index], description: data.description, alert: data.alert}, ...prevState.slice(index + 1)] : prevState;
                    });
                }
                if(setDocsAlerts){
                    setDocsAlerts(prevState => [...prevState, data]);
                }
                setLoading(false);
                toast(SUCCESS_MESSAGE_SAVED);
            })
            .catch(err => {
                toast(ERROR_MESSAGE_UNKNOWN);
            });
        }
    };
    const handleClose = () => {
        hideAddDocumentAlertView();
    };
    
    return (
        <Dialog open={addDocumentAlertViewOpen} onClose={handleClose}>
            <DialogTitle noWrap>{selectedDocument?.name} | Novo alerta</DialogTitle>
            <DialogContent>
                <DialogContentText>Você quer cadastrar um alerta para esse contrato? Essa ação não notificará os revisores do documento. O objetivo do alerta é enviar um lembrete por e-mail, na data indicada a seguir, aos próprios gestores e equipe de profissionais com acesso a esta pasta (por exemplo: alerta sobre o prazo de vigência). Se você quiser enviar imediatamente uma solicitação aos revisores do documento, saia desta janela e clique no botão SINALIZAR.</DialogContentText>
                <Box mt={2}>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid xs={12} sm={6} md={8}>
                            <TextField
                                label="Descrição"
                                variant="standard"
                                fullWidth
                                InputProps={{ref: descriptionRef}}
                                placeholder="Sua descrição"
                                value={notificationName}
                                onChange={(e) => set_notificationName(e.target.value)}
                            />
                        </Grid>
                        <Grid xs>
                            <DatePicker
                                placeholder="DD/MM/AAAA"
                                minDate={moment().startOf('day').add(1, 'day')} value={notificationDate} onChange={(date) => set_notificationDate(date)}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="text"
                // disabled={saving}
                onClick={handleClose}
                >Cancelar</Button>
                <Button
                    disabled={loading || !notificationDate || !notificationName}
                    // disabled={saving || loading || !notificationDate || !notificationName}
                    // loading={saving}
                    variant="contained" color="primary"
                    onClick={handleSubmit}
                    endIcon={<CheckIcon />}
                >Ok</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddAlertWindow;