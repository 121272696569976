import { useState } from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ClearIcon from '@mui/icons-material/Clear';
import EventIcon from '@mui/icons-material/Event';
import FlagIcon from '@mui/icons-material/Flag';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import FolderIcon from '@mui/icons-material/Folder';
import PeopleIcon from '@mui/icons-material/People';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import SettingsIcon from '@mui/icons-material/Settings';
import TitleIcon from '@mui/icons-material/Title';
import AlertDialog from '../../../../components/AlertDialog';
import DeleteRecordMenuItem from '../../../../components/DeleteRecordMenuItem';
import FormDialog from '../../../../components/FormDialogUncontrolled';
import { useAppCtxAPI, useAppCtxActiveUser } from '../../../../context/SystemContext';
import { useSocket } from '../../../../context/SocketContext';
import { useClientCtxActiveClient } from '../../../../context/ClientContext';
import { useDocumentsAPI, useDocumentsCtxActionMenu } from '../../../../context/DocumentsContext';
import { useOperatorCtxProjects } from '../../context/OperatorContext';
import { serverRequest, flagDocument } from '../../../../utils/common';
import { SPECIAL_STRINGS_EMPTY, SPECIAL_STRINGS_REVIEW, SPECIAL_STRINGS_SPECIAL } from '../../../../utils/constants';

const ActionMenu = (props) => {
    const { deleteDocument, updateDocument } = props;
    const { handleNavigate, setLoading, toast } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const activeClient = useClientCtxActiveClient();
    const projects = useOperatorCtxProjects();
    const {createSocketConnection} = useSocket();
    const socket = createSocketConnection();
    const { dispatch: dispatchDocuments, setActionMenuOpen, showAddDocumentAlertView, showChangeDocumentGroupView, showChangeDocumentFolderView, showChangeDocumentNameView, showDeleteDocumentView, showFlagDocumentView } = useDocumentsAPI();
    const { actionMenuAnchorEl, actionMenuOpen, actionMenuSelectedDocument: selectedDocument } = useDocumentsCtxActionMenu();
    const [generateDocumentDialogOpen, setGenerateDocumentDialogOpen] = useState(false);
    const [updateDocumentSignaturesDialogOpen, setUpdateDocumentSignatures] = useState(false);

    const handle_editNameButtonClick = () => {
        handleClose();
        showChangeDocumentNameView(selectedDocument);
    };
    const handle_deleteButtonClick = () => {
        handleClose();
        showDeleteDocumentView(selectedDocument);
    };
    const handleFlagDocDialogOpen = () => {
        handleClose();
        showFlagDocumentView(selectedDocument);
    };
    const handle_flagButtonClick = async (flag) => {
        handleClose();
        flagDocument(flag, '', activeUser, selectedDocument, updateDocument, socket, setLoading, toast);
    };

    const handle_openFormClick = () => {
        dispatchDocuments({type: 'SET FORM DOCUMENT', payload: selectedDocument});
        // set_openForm(selectedDocument);
        handleClose();
        handleNavigate(`/${activeClient.shortName}/juridico/documentos/formulario`);
    };
    const handle_openTemplateClick = () => {
        handleClose();
        handleNavigate(`/${activeClient.shortName}/juridico/documentos/matrizes/documento?id=${selectedDocument.template}`);
    };
    const handle_createNotificationClick = () => {
        handleClose();
        showAddDocumentAlertView(selectedDocument);
    };
    const handleGenerateAgainClick = () => {
        setGenerateDocumentDialogOpen(true);
    };
    const handleGenerateDocumentConfirm = async () => {
        setGenerateDocumentDialogOpen(false);
        handleClose();
        updateDocument({...selectedDocument, loading: true});
        try {
            const data = await serverRequest({path: `/data/documents/generate`, method: 'post', data: {documentId: selectedDocument._id}});
            if(data.updatedDocument){
                const updatedDocument = data.updatedDocument;
                return updateDocument({...updatedDocument, loading: false});
            }
            updateDocument({...selectedDocument, loading: false});
            toast('Erro: Não foi possível gerar um novo documento.');
        } catch (error) {
            updateDocument({...selectedDocument, loading: false});
            toast(error);
        }
    };

    const handleUpdateSignatureStatus = () => {
        setUpdateDocumentSignatures(true);
    };
    const handleUpdateDocumentSignaturesConfirm = async (eSignaturePlatformId) => {
        setUpdateDocumentSignatures(false);
        handleClose();
        const postData = {
            documentId: selectedDocument._id,
            eSignaturePlatform: 'zapSign',
            eSignaturePlatformId
        };
        try {
            const data = await serverRequest({path: `/data/documents/sign/update`, method: 'post', data: postData});
            updateDocument(data);
        } catch (error) {
            toast(error);
        }
    };

    const handleGetSignedDocumentFile = async () => {
        try {
            const data = await serverRequest({path: `/data/documents/${selectedDocument._id}/signatures/file` });
            updateDocument(data);
        } catch (error) {
            toast(error);
        }
    };

    const handleChangeDocumentFolderViewOpenButtonClick = (e) => {
        handleClose();
        showChangeDocumentFolderView(e.target, selectedDocument);
    };
    
    const handleChangeDocumentGroupViewOpenButtonClick = (e) => {
        handleClose();
        showChangeDocumentGroupView(e.target, selectedDocument);
    };

    let useGroups = false;
    if(selectedDocument){
        const currentFolder = projects.find(project => project._id === selectedDocument.project);
        if(currentFolder && currentFolder.useGroups){
            useGroups = true;
        }
    }

    const handleClose = () => {
        setActionMenuOpen(false);
    };
    
    return (
        <>
            <Menu
                anchorEl={actionMenuAnchorEl}
                open={actionMenuOpen}
                onClose={handleClose}
            >
                <MenuItem onClick={handle_createNotificationClick}>
                    <ListItemIcon>
                        <EventIcon />
                    </ListItemIcon>
                    <ListItemText primary="Criar alerta" />
                </MenuItem>
                <MenuItem onClick={() => handle_editNameButtonClick()}>
                    <ListItemIcon>
                        <TitleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Renomear" />
                </MenuItem>
                {
                    (selectedDocument?.flag !== 1) &&
                    <MenuItem onClick={handleFlagDocDialogOpen}>
                        <ListItemIcon>
                            <FlagIcon />
                        </ListItemIcon>
                        <ListItemText primary="Sinalizar" />
                    </MenuItem>
                }
                {
                    (!!selectedDocument?.flag) &&
                    <MenuItem onClick={() => handle_flagButtonClick(0)}>
                        <ListItemIcon>
                            <FlagIcon />
                        </ListItemIcon>
                        <ListItemText primary="Desmarcar sinalização" />
                    </MenuItem>
                }
                <MenuItem onClick={() => handle_openFormClick()}>
                    <ListItemIcon>
                        <QuestionAnswerIcon />
                    </ListItemIcon>
                    <ListItemText primary="Abrir formulário" />
                </MenuItem>
                {
                    (activeClient?.modules?.includes('templates') && ![...SPECIAL_STRINGS_EMPTY, ...SPECIAL_STRINGS_REVIEW, ...SPECIAL_STRINGS_SPECIAL].includes(selectedDocument?.template)) &&
                    <MenuItem onClick={() => handle_openTemplateClick()}>
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Alterar matriz" />
                    </MenuItem>
                }
                {
                    (!Array.isArray(selectedDocument?.documents) || selectedDocument?.documents.length <= 1) &&
                    <MenuItem onClick={() => handleGenerateAgainClick()}>
                        <ListItemIcon>
                            <FlashOnIcon />
                        </ListItemIcon>
                        <ListItemText primary="Gerar novamente" />
                    </MenuItem>
                }
                {
                    (activeUser?.type >= 9) &&
                    <MenuItem onClick={handleChangeDocumentFolderViewOpenButtonClick}>
                        <ListItemIcon>
                            <FolderIcon />
                        </ListItemIcon>
                        <ListItemText primary="Alterar pasta" />
                    </MenuItem>
                }
                {
                    (useGroups) &&
                    <MenuItem onClick={handleChangeDocumentGroupViewOpenButtonClick}>
                        <ListItemIcon>
                            <PeopleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Alterar grupo" />
                    </MenuItem>
                }
                {
                    activeUser?.type >= 9 &&
                    <MenuItem onClick={handleUpdateSignatureStatus}>
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Atualizar situação de assinatura" />
                    </MenuItem>
                }
                {
                    activeUser?.type >= 9 &&
                    <MenuItem onClick={handleGetSignedDocumentFile}>
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Atualizar documento assinado com certificado" />
                    </MenuItem>
                }
                <MenuItem onClick={() => handle_deleteButtonClick()}>
                    <ListItemIcon>
                        <ClearIcon />
                    </ListItemIcon>
                    <ListItemText primary="Cancelar" />
                </MenuItem>
                <DeleteRecordMenuItem collection="Documents" deleteDocument={deleteDocument} document={selectedDocument} confirmActionFunction={handleClose} />
            </Menu>

            <AlertDialog
                open={generateDocumentDialogOpen} onClose={() => setGenerateDocumentDialogOpen(false)}
                text={`Tem certeza? Isso substituirá o documento atual.`}
                okButtonOnClick={handleGenerateDocumentConfirm}
            />
            <FormDialog
                open={updateDocumentSignaturesDialogOpen} onClose={() => setUpdateDocumentSignatures(false)}
                title="Atualizar situação do processo de assinatura eletrônica"
                text={`Qual é o token da ZapSign? (Essa funcionalidade não está disponível para DocuSign)`}
                onConfirm={handleUpdateDocumentSignaturesConfirm}
            />
        </>
    );
}

export default ActionMenu;