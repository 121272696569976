import { useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { useAppCtxActiveUser } from '../context/SystemContext';
import { useSocketCtxOnlineUsers } from '../context/SocketContext';
import { useClientCtxActiveClient } from '../context/ClientContext';
import { useOnlineUsersCtxAPI, useOnlineUsersCtx } from '../context/OnlineUsersContext';
import { useOperatorCtxUsers } from '../pages/Operator/context/OperatorContext';
import Tooltip from './Tooltip';

const OnlineUsers = () => {
    const activeUser = useAppCtxActiveUser();
    const onlineUsersList = useSocketCtxOnlineUsers();
    const activeClient = useClientCtxActiveClient();
    const { setOnlineOperators, setOnlineUsers } = useOnlineUsersCtxAPI();
    const { onlineOperators } = useOnlineUsersCtx();
    const { users } = useOperatorCtxUsers();

    useEffect(() => {
        if(activeClient && users && onlineUsersList){
            const currentOnlineUsers = onlineUsersList.filter(user => user.clientId == activeClient._id);
            const currentOnlineOperatorsIds = [];
            currentOnlineUsers.forEach(onlineUser => {
                const onlineUserId = onlineUser._id.toString();
                if(onlineUser.type !== 1 && !currentOnlineOperatorsIds.includes(onlineUserId)) currentOnlineOperatorsIds.push(onlineUserId);
            });
            const currentOnlineOperators = currentOnlineOperatorsIds.map((uniqueUserId) => users.find(user => user._id === uniqueUserId));
            setOnlineUsers(currentOnlineUsers);
            setOnlineOperators(currentOnlineOperators);
        }
    }, [activeClient, users, onlineUsersList]);

    return (
        <Tooltip
            text={`Online: ${onlineOperators.filter(operator => operator._id !== activeUser?._id).map(i => i.screenName).join(', ')}`}
        >
        <AvatarGroup max={12}>
            {
                onlineOperators
                .filter(operator => operator._id !== activeUser?._id)
                .map((user, index) => (
                    <Avatar
                        key={index}
                        style={{ height: 32, width: 32 }}
                        alt={user?.fullName}
                        src={`/uploads/avatars/${user?.img}`}
                    />
                ))
            }
        </AvatarGroup>
        </Tooltip>
    );
};

export default OnlineUsers;