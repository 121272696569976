import moment from 'moment';
import { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { serverRequest } from '../utils/common';
import { APP_USER_ID } from '../utils/constants';
import { useAppCtxAPI, useAppCtxActiveUser, useAppCtxVersion } from './SystemContext'; 
import { useSocketCtxAPI, useSocket, useSocketCtxConnectedAt } from './SocketContext';
import { useClearanceCtxAPI, useClearance } from './ClearanceContext';

const ClientCtxAPI = createContext();
const ClientCtxAccountBalance = createContext();
const ClientCtxActiveClient = createContext();
const ClientCtxActiveProjects = createContext();
const ClientContext = createContext();

const defaultState = {
    accountBalance: 0,
    accountBalanceUpdatedAt: null,
    accountBalanceUpdatedBy: '',
    activeClient: null,
    activeProjects: [],
    fundBalance: 0,
    fundBalanceUpdatedAt: null,
    fundBalanceUpdatedBy: '',
    lists: [],
    modules: [],
    userTemplateFields: [],
    yearGoal: null
};

const reducer = (state, action) => {
    const { payload, type } = action;

    switch (type) {
        case 'SET ACCOUNT BALANCE':
            return { ...state, accountBalance: payload.accountBalance, accountBalanceUpdatedAt: payload.accountBalanceUpdatedAt, accountBalanceUpdatedBy: payload.accountBalanceUpdatedBy };
        case 'SET ACCOUNT BALANCE UPDATED AT':
            return { ...state, accountBalanceUpdatedAt: payload };
        case 'SET ACCOUNT BALANCE UPDATED AT':
            return { ...state, accountBalanceUpdatedBy: payload };
        case 'SET ACTIVE CLIENT':
            return { ...state, activeClient: payload };
        case 'SET ACTIVE PROJECTS':
            return { ...state, activeProjects: payload };
        case 'SET FUND BALANCE':
            return { ...state, fundBalance: payload.fundBalance, fundBalanceUpdatedAt: payload.fundBalanceUpdatedAt, fundBalanceUpdatedBy: payload.fundBalanceUpdatedBy };
        case 'SET FUND BALANCE UPDATED AT':
            return { ...state, fundBalanceUpdatedAt: payload };
        case 'SET FUND BALANCE UPDATED BY':
            return { ...state, fundBalanceUpdatedBy: payload };
        case 'SET LISTS':
            return { ...state, lists: payload };
        case 'SET MODULES':
            return { ...state, modules: payload };
        case 'SET USER TEMPLATE FIELDS':
            return { ...state, userTemplateFields: payload };
        case 'SET YEAR GOAL':
            return { ...state, yearGoal: payload };
        default: return state;
    }
};

const ClientProvider = ({children}) => {
    const { floatingAlert, getAppBackEndVersion, setAppCtxActiveClient } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const { frontendVersion } = useAppCtxVersion();
    const { setState: setClearanceCtxState } = useClearanceCtxAPI();
    const { setShouldUpdateClient } = useSocketCtxAPI();
    const { shouldUpdateClient, createSocketConnection } = useSocket();
    const socket = createSocketConnection();
    const socketConnectedAt = useSocketCtxConnectedAt();
    
    const [lists, setLists] = useState([]);
    const [modules, setModules] = useState([]);
    const [userTemplateFields, setUserTemplateFields] = useState([]);
    const [yearGoal, setYearGoal] = useState(null);

    const [state, dispatch] = useReducer(reducer, {...defaultState});

    const setActiveProjects = (payload) => {
        dispatch({ type: 'SET ACTIVE PROJECTS', payload });
    };

    const api = useMemo(() => {

        const setAccountBalance = (accountBalance) => {
            dispatch({ type: 'SET ACCOUNT BALANCE', payload: { accountBalance } });
        };

        const setAccountBalanceUpdatedAt = (payload) => {
            dispatch({ type: 'SET ACCOUNT BALANCE UPDATED AT', payload });
        };

        const setAccountBalanceUpdatedBy = (payload) => {
            dispatch({ type: 'SET ACCOUNT BALANCE UPDATED AT', payload });
        };

        const setActiveClient = (payload) => {
            dispatch({ type: 'SET ACTIVE CLIENT', payload });
        };

        const setFundBalance = (fundBalance) => {
            dispatch({ type: 'SET FUND BALANCE', payload: { fundBalance } });
        };

        const setFundBalanceUpdatedAt = (payload) => {
            dispatch({ type: 'SET FUND BALANCE UPDATED AT', payload });
        };
        
        const setFundBalanceUpdatedBy = (payload) => {
            dispatch({ type: 'SET FUND BALANCE UPDATED BY', payload });
        };

        const setLists = (payload) => {
            dispatch({ type: 'SET LISTS', payload });
        };

        const setModules = (payload) => {
            dispatch({ type: 'SET MODULES', payload });
        };

        const setUserTemplateFields = (payload) => {
            dispatch({ type: 'SET USER TEMPLATE FIELDS', payload });
        };

        const setYearGoal = (payload) => {
            dispatch({ type: 'SET YEAR GOAL', payload });
        };

        return {
            dispatch,
            setAccountBalance, setAccountBalanceUpdatedAt, setAccountBalanceUpdatedBy, setActiveClient, setActiveProjects,
            setFundBalance, setFundBalanceUpdatedAt, setFundBalanceUpdatedBy,
            setLists,
            setModules,
            setUserTemplateFields,
            setYearGoal
        };
    }, []);

    const loadClient = async () => {
        console.log(`Carregando CLIENTE em ${moment().format('L LTS')}`);
        try {
            const currentClient = await serverRequest({path: '/data/clients/one'});
            if(activeUser._id === APP_USER_ID){
                if(!currentClient.modules) currentClient.modules = [];
                if(!currentClient.modules.includes('templates')) currentClient.modules.push('templates');
            }
            dispatch({ type: 'SET ACTIVE CLIENT', payload: currentClient });
        } catch (error) {
            floatingAlert(error);
        }
    };
    
    useEffect(() => {
        if(shouldUpdateClient){
            loadClient();
        }
    }, [socketConnectedAt]);

    useEffect(() => {
        const clientUpdatedSocketEventName = 'CLIENT UPDATED';
        const clientAccountBalanceUpdatedSocketEventName = 'CLIENT ACCOUNT BALANCE UPDATED';
        const clientFundBalanceUpdatedSocketEventName = 'CLIENT FUND BALANCE UPDATED';
        const clientListsUpdatedSocketEventName = 'CLIENT LISTS UPDATED';
        const clientModulesUpdatedSocketEventName = 'CLIENT MODULES UPDATED';
        const clientUserTemplateFieldsUpdatedSocketEventName = 'CLIENT USER TEMPLATE FIELDS UPDATED';
        const clientYearGoalUpdatedSocketEventName = 'CLIENT YEAR GOAL UPDATED';

        const clientUpdatedSocketEvent = (data) => {
            if(data._id === state.activeClient._id){
                dispatch({ type: 'SET ACTIVE CLIENT', payload: data });
            }
        };
        
        const clientAccountBalanceUpdatedSocketEvent = (data) => {
            if(data.updatedClient._id === state.activeClient._id){
                dispatch({ type: 'SET ACCOUNT BALANCE', payload: {
                    accountBalance: data.updatedClient.accountBalance || 0,
                    accountBalanceUpdatedAt: data.updatedClient.accountBalanceUpdatedAt || null,
                    accountBalanceUpdatedBy: data.updatedClient.accountBalanceUpdatedBy || ''
                } });
            }
        };
        
        const clientFundBalanceUpdatedSocketEvent = (data) => {
            if(data.updatedClient._id === state.activeClient._id){
                dispatch({ type: 'SET FUND BALANCE', payload: {
                    fundBalance: data.updatedClient.fundBalance || 0,
                    fundBalanceUpdatedAt: data.updatedClient.fundBalanceUpdatedAt || null,
                    fundBalanceUpdatedBy: data.updatedClient.fundBalanceUpdatedBy || ''
                } });
            }
        };
        
        const clientListsUpdatedSocketEvent = (data) => {
            if(data.updatedClient._id === state.activeClient._id){
                setLists(data.updatedClient.lists || []);
            }
        };
        
        const clientModulesUpdatedSocketEvent = (data) => {
            if(data.updatedClient._id === state.activeClient._id){
                setModules(data.updatedClient.modules || []);
            }
        };

        const clientUserTemplateFieldsUpdatedSocketEvent = (data) => {
            if(data.updatedClient._id === state.activeClient._id){
                if(data.updatedClient.userCreatedFields){
                    const sortedUserCreatedFields = data.updatedClient.userCreatedFields.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                    setUserTemplateFields(sortedUserCreatedFields);
                }
            }
        };

        const clientYearGoalUpdatedSocketEvent = (data) => {
            if(data.updatedClient._id === state.activeClient._id){
                setYearGoal(data.updatedClient.yearGoal);
            }
        };

        socket.on(clientUpdatedSocketEventName, clientUpdatedSocketEvent);
        socket.on(clientAccountBalanceUpdatedSocketEventName, clientAccountBalanceUpdatedSocketEvent);
        socket.on(clientFundBalanceUpdatedSocketEventName, clientFundBalanceUpdatedSocketEvent);
        socket.on(clientListsUpdatedSocketEventName, clientListsUpdatedSocketEvent);
        socket.on(clientModulesUpdatedSocketEventName, clientModulesUpdatedSocketEvent);
        socket.on(clientUserTemplateFieldsUpdatedSocketEventName, clientUserTemplateFieldsUpdatedSocketEvent);
        socket.on(clientYearGoalUpdatedSocketEventName, clientYearGoalUpdatedSocketEvent);
        return () => {
            socket.off(clientUpdatedSocketEventName, clientUpdatedSocketEvent);
            socket.off(clientAccountBalanceUpdatedSocketEventName, clientAccountBalanceUpdatedSocketEvent);
            socket.off(clientFundBalanceUpdatedSocketEventName, clientFundBalanceUpdatedSocketEvent);
            socket.off(clientListsUpdatedSocketEventName, clientListsUpdatedSocketEvent);
            socket.off(clientModulesUpdatedSocketEventName, clientModulesUpdatedSocketEvent);
            socket.off(clientUserTemplateFieldsUpdatedSocketEventName, clientUserTemplateFieldsUpdatedSocketEvent);
            socket.off(clientYearGoalUpdatedSocketEventName, clientYearGoalUpdatedSocketEvent);
        };
    }, []);

    useEffect(() => {
        if(state.activeClient){
            if(state.activeClient.userCreatedFields){
                const sortedUserCreatedFields = state.activeClient.userCreatedFields.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                setUserTemplateFields(sortedUserCreatedFields);
            }
            setModules(state.activeClient.modules || []);
            setLists(state.activeClient.lists || []);
            dispatch({ type: 'SET ACCOUNT BALANCE', payload: {
                accountBalance: state.activeClient.accountBalance || 0,
                accountBalanceUpdatedAt: state.activeClient.accountBalanceUpdatedAt || null,
                accountBalanceUpdatedBy: state.activeClient.accountBalanceUpdatedBy || ''
            } });
            dispatch({ type: 'SET FUND BALANCE', payload: {
                fundBalance: state.activeClient.fundBalance || 0,
                fundBalanceUpdatedAt: state.activeClient.fundBalanceUpdatedAt || null,
                fundBalanceUpdatedBy: state.activeClient.fundBalanceUpdatedBy || ''
            } });
            setYearGoal(state.activeClient.yearGoal);
            setActiveProjects(state.activeClient.activeProjects || []);
        }
        setAppCtxActiveClient(state.activeClient);
        setClearanceCtxState('clearanceCtxActiveClient', state.activeClient);
        return () => {
            setAppCtxActiveClient(null);
            setClearanceCtxState('clearanceCtxActiveClient', null);
        };
    }, [state.activeClient]);

    useEffect(() => {
        if(shouldUpdateClient && socketConnectedAt && activeUser && state.activeClient){
            setShouldUpdateClient(false);
            const userName = activeUser.fullName;
            const userImage = activeUser.img || '';
            const location = window.location.pathname;
            const locationTimestamp = new Date();

            socket.emit('USER CONNECTED', {
                _id: activeUser._id, clientId: state.activeClient._id, type: activeUser.type, userName, userImage, location, locationTimestamp,
                appFrontEndVersion: frontendVersion
            });
            getAppBackEndVersion();
        }
    }, [socketConnectedAt, activeUser, state.activeClient]);

    const getListById = useCallback((listId) => {
        let currentList;
        if(state.activeClient){
            let currentLists = state.activeClient.lists;
            if(currentLists) currentList = currentLists.find(list => list.shortName === listId || list._id == listId);
        }
        return currentList;
    }, [state.activeClient]);

    const getListItemById = (listId, itemId) => {
        let currentItem;
        let currentList = getListById(listId);
        if(currentList){
            currentList = currentList.list;
            currentItem = currentList.find(item => item.id === itemId);
        }
        return currentItem;
    };

    const getListItemKeyById = (listId, itemId) => {
        let key = '';
        let currentItem = getListItemById(listId, itemId);
        if(currentItem) key = currentItem.key;
        return key; 
    };

    const clientCtxAccountBalanceValue = useMemo(() => ({
            accountBalance: state.accountBalance, accountBalanceUpdatedAt: state.accountBalanceUpdatedAt, accountBalanceUpdatedBy: state.accountBalanceUpdatedBy,
            fundBalance: state.fundBalance, fundBalanceUpdatedAt: state.fundBalanceUpdatedAt, fundBalanceUpdatedBy: state.fundBalanceUpdatedBy,
    }), [state.accountBalance, state.accountBalanceUpdatedAt, state.accountBalanceUpdatedBy, state.fundBalance, state.fundBalanceUpdatedAt, state.fundBalanceUpdatedBy]);

    const clientContextValue = useMemo(() => ({
        getListById,
        getListItemKeyById,
        lists, setLists,
        loadClient,
        modules, setModules,
        userTemplateFields, setUserTemplateFields,
        yearGoal, setYearGoal
}), [getListById, getListItemKeyById, lists, loadClient, modules, userTemplateFields, setUserTemplateFields, yearGoal]);

    if(!state.activeClient) return null;
    
    return (
        <ClientCtxAPI.Provider value={api}>
        <ClientCtxActiveClient.Provider value={state.activeClient}>
        <ClientCtxActiveProjects.Provider value={state.activeProjects}>
        <ClientCtxAccountBalance.Provider value={clientCtxAccountBalanceValue}>
            <ClientContext.Provider value={clientContextValue}>
                {children}
            </ClientContext.Provider>
        </ClientCtxAccountBalance.Provider>
        </ClientCtxActiveProjects.Provider>
        </ClientCtxActiveClient.Provider>
        </ClientCtxAPI.Provider>
    );
};

const useClientCtxAPI = () => useContext(ClientCtxAPI);
const useClient = () => useContext(ClientContext);
const useClientCtxAccountBalance = () => useContext(ClientCtxAccountBalance);
const useClientCtxActiveClient = () => useContext(ClientCtxActiveClient);
const useClientCtxActiveProjects = () => useContext(ClientCtxActiveProjects);

export {
    ClientProvider,
    useClientCtxAPI,
    useClient,
    useClientCtxAccountBalance,
    useClientCtxActiveClient,
    useClientCtxActiveProjects
};