import { validateEmail } from './common';
import { CPF_REGEXP } from './constants';

const checkDocumentVersion = ({action, eSignatureCallback, eSignaturePlatform, folder, selectedDocument, setSelectedDocument, selectedVersionId, setDocuments, setErrorDescription, updatedVersion}) => {
    const documentVersion = selectedVersionId ? selectedDocument.documents.find(scannedDocumentVersion => scannedDocumentVersion._id === selectedVersionId) : selectedDocument.documents[selectedDocument.documents.length - 1];
    if(documentVersion){
        let { fileFormat, serverFile, link } = documentVersion;
        if(!fileFormat || !serverFile){
            let versionType = documentVersion.type;
            if(versionType === 'download'){
                let fileExtension = documentVersion.link.split('.').pop();
                if(fileExtension === 'pdf'){
                    fileFormat = 'pdf';
                    serverFile = true;
                    link = documentVersion.link;
                }
            } else if(documentVersion.googleDoc){
                let isGoogleDocUrl = /docs\.google\.com\/.*?[-\w]{25,}/.test(documentVersion.googleDoc);
                if(isGoogleDocUrl){
                    fileFormat = 'googleDoc';
                    serverFile = false;
                    link = documentVersion.googleDoc;
                }
            }
        }
        if(!fileFormat || serverFile === undefined || !link){
            if(setErrorDescription) setErrorDescription('O formato do documento não é válido para assinatura.');
            return false;
        } else {
            const payload = {
                action,
                    eSignaturePlatform,
                    name: selectedDocument.name,
                    documentFolderId: selectedDocument.project,
                    eSignatureCallback,
                    selectedDocument, setSelectedDocument,
                    setDocuments,
                    updatedVersionId: updatedVersion?._id,
                    updatedVersionEnvelopeId: updatedVersion?.eSignaturePlatformId,
                    documents: [
                        {
                            id: selectedVersionId,
                            folder: folder || 'documents',
                            fileFormat,
                            link,
                            serverFile
                        }
                    ]
            };
            return payload;
        }
    }
    return false;
};

const checkIfSignersValid = (signers) => {
    let allValid = true;
    let message = '';
    signers.forEach((signer, index) => {
        if(!signer.fullName){
            allValid = false;
            message = `É preciso preencher o nome completo do(a) signatário ${index + 1}.`;
            return;
        }
        if(!validateEmail(signer.email)){
            allValid = false;
            message = `O endereço de e-mail do(a) signatário ${index + 1} não é válido.`;
            return;
        }
        if(signer.brazilian && !signer.documentation){
            allValid = false;
            message = `É preciso preencher o CPF do(a) signatário ${index + 1}.`;
            return;
        }
    });
    return { allValid, message };
};

const getSigners = (projects, templates, documentItem) => {
    const documentForm = documentItem.form;
    const documentProjectId = documentItem.project || documentItem.client;
    const documentProject = projects.find(project => project._id === documentProjectId);
    const documentTemplate = documentItem.template;

    let currentSigners = [];

    let folderSigners = [];
    if(!documentProject.client || documentProject.useClientESignatureSigners){ // is Client
        if(documentProject.signers && documentProject.signers.length >= 1){
            folderSigners = documentProject.signers;
        }
    } else { // is Project
        if(documentProject.signers && documentProject.signers.length >= 1){
            folderSigners = documentProject.signers;
        } else {
            const companyId = documentItem.client;
            if(projects){
                const company = projects.find(p => p._id == companyId);
                if(company && company.signers && company.signers.length >= 1){
                    folderSigners = company.signers;
                }
            }
        }
    }
    if(folderSigners.length >= 1){
        currentSigners = folderSigners.map(signer => ({
            fullName: signer.name || '',
            documentation: signer.taxpayerNumber || '',
            email: signer.email || '',
            type: signer.type === 'party' ? (signer.name && signer.email && signer.taxpayerNumber && signer.mobile ? 'contratante' : 'party') : signer.type,
            mobile: signer.name && signer.email && signer.taxpayerNumber && signer.mobile ? signer.mobile : '',
            locked: signer.name && signer.email && signer.taxpayerNumber && signer.mobile ? true : false
        }));

        let currentSupplier = getSupplierSigner(templates, documentItem, documentTemplate, documentForm);
        currentSigners.push(currentSupplier);

        let witnesses = currentSigners.filter(signer => signer.type === 'witness');
        let witnessesCount = witnesses.length;
        if(witnessesCount === 0){
            currentSigners.push({type: 'witness'}, {type: 'witness'});
        } else if(witnessesCount === 1){
            currentSigners.push({type: 'witness'});
        }

        return currentSigners;
    }

    let currentSignerName, currentSignerTaxpayerNumber, currentSignerEmail, currentSignerMobile, currentSignerOrder;
    let projectInfo = documentProject.info || {};
    if(!documentProject.client || documentProject.useClientESignatureSigners){ // is Client
        currentSignerName = projectInfo.signerName || projectInfo.representative || '';
        currentSignerTaxpayerNumber = projectInfo.signerTaxpayerNumber || projectInfo.representativeTaxpayerNumber || '';
        currentSignerEmail = projectInfo.signerEmail || projectInfo.representativeEmail || '';
        currentSignerMobile = projectInfo.signerMobile || '';
        currentSignerOrder = projectInfo.signerOrder || undefined;
    } else { // is Project
        let { signerName, signerTaxpayerNumber, signerEmail, signerMobile, signerOrder } = projectInfo;
        if(signerName){
            currentSignerName = signerName || '';
            currentSignerTaxpayerNumber = signerTaxpayerNumber || '';
            currentSignerEmail = signerEmail || '';
            currentSignerMobile = signerMobile || '';
            currentSignerOrder = signerOrder || undefined;
        } else {
            const companyId = documentItem.client;
            if(projects){
                const company = projects.find(p => p._id == companyId);
                if(company){
                    let companyInfo = company.info || {};
                    currentSignerName = companyInfo.signerName || companyInfo.representative || '';
                    currentSignerTaxpayerNumber = companyInfo.signerTaxpayerNumber || companyInfo.representativeTaxpayerNumber || '';
                    currentSignerEmail = companyInfo.signerEmail || companyInfo.representativeEmail || '';
                    currentSignerMobile = companyInfo.signerMobile || '';
                }
            }
        }
    }
    let currentSigner = {
        fullName: currentSignerName,
        documentation: currentSignerTaxpayerNumber,
        email: currentSignerEmail,
        type: currentSignerName && currentSignerEmail && currentSignerMobile ? 'contratante' : 'party',
        mobile: currentSignerName && currentSignerEmail && currentSignerMobile ? currentSignerMobile : '',
        locked: currentSignerName && currentSignerEmail && currentSignerMobile ? true : false,
        order: currentSignerOrder
    }
    if(currentSignerOrder === 'first') currentSigners.push(currentSigner);

    let currentSupplier = getSupplierSigner(templates, documentItem, documentTemplate, documentForm);

    let { witness1Name, witness1Email, witness1TaxpayerNumber, witness1Mobile, witness1Order } = projectInfo;

    let currentWitness1 = {
        fullName: witness1Name || '',
        documentation: witness1TaxpayerNumber || '',
        email: witness1Email || '',
        type: 'witness',
        mobile: witness1Name && witness1Email && witness1TaxpayerNumber && witness1Mobile ? witness1Mobile : '',
        locked: witness1Name && witness1Email && witness1TaxpayerNumber && witness1Mobile ? true : false
    }
    if(witness1Order === 'first') currentSigners.push(currentWitness1);

    let { witness2Name, witness2Email, witness2TaxpayerNumber, witness2Mobile, witness2Order } = projectInfo;

    let currentWitness2 = {
        fullName: witness2Name || '',
        documentation: witness2TaxpayerNumber || '',
        email: witness2Email || '',
        type: 'witness',
        mobile: witness2Name && witness2Email && witness2TaxpayerNumber && witness2Mobile ? witness2Mobile : '',
        locked: witness2Name && witness2Email && witness2TaxpayerNumber && witness2Mobile ? true : false,
    }
    if(witness2Order === 'first') currentSigners.push(currentWitness2);

    if(!currentSignerOrder) currentSigners.push(currentSigner);
    
    currentSigners.push(currentSupplier);

    if(!witness1Order) currentSigners.push(currentWitness1);

    if(!witness2Order) currentSigners.push(currentWitness2);

    if(currentSignerOrder === 'last') currentSigners.push(currentSigner);

    return currentSigners;
};
const getSupplierSigner = (templates, documentItem, documentTemplate, documentForm) => {
    let supplierName = '', supplierEmail = '', supplierId = '';
    const template = templates.find(t => t._id == documentTemplate);
    if(template){
        const templateForm = template.form;
        if(templateForm){
            let assinedFieldQuestionsRepresentative = templateForm.filter(q => q.assignedField === 4);
            let assinedFieldQuestionsRepresentativeId = templateForm.filter(q => q.assignedField === 5);
            let assinedFieldQuestionsName = templateForm.filter(q => q.assignedField === 1);
            let assinedFieldQuestionsId = templateForm.filter(q => q.assignedField === 2);
            let assinedFieldQuestionsEmail = templateForm.filter(q => q.assignedField === 'signerEmail' || q.assignedField === 'email');
            try {
                const userForm = JSON.parse(documentForm);
                if(assinedFieldQuestionsRepresentative.length >= 1){
                    let question = assinedFieldQuestionsRepresentative[0];
                    if(userForm[question.name]){
                        supplierName = userForm[question.name].input;
                    }
                } else if(assinedFieldQuestionsName.length >= 1){
                    let question = assinedFieldQuestionsName[0];
                    if(userForm[question.name]){
                        supplierName = userForm[question.name].input;
                    }
                }
                if(assinedFieldQuestionsRepresentativeId.length >= 1){
                    let question = assinedFieldQuestionsRepresentativeId[0];
                    if(userForm[question.name]){
                        if(CPF_REGEXP.test(userForm[question.name].input)) supplierId = userForm[question.name].input;
                    }
                } else if(assinedFieldQuestionsId.length >= 1){
                    let question = assinedFieldQuestionsId[0];
                    if(userForm[question.name]){
                        supplierId = userForm[question.name].input;
                    }
                }
                if(assinedFieldQuestionsEmail.length >= 1){
                    let question = assinedFieldQuestionsEmail[0];
                    if(userForm[question.name]){
                        supplierEmail = userForm[question.name].input;
                    }
                }
            } catch (error) {
                //
            }
        }
    }

    if(!supplierName && documentItem.signerName) supplierName = documentItem.signerName;
    if(!supplierEmail && documentItem.signerEmail) supplierEmail = documentItem.signerEmail;
    
    let currentSupplier = {
        fullName: supplierName,
        documentation: supplierId,
        email: supplierEmail,
        type: 'party'
    }
    return currentSupplier;
};

const storeStringifiedDocument = ({eSignatureEnvelope = {}, proposalType, selectedDocument, selectedVersionId, type}) => {
    const payload = {
        ...eSignatureEnvelope,
        stringifiedDocument: JSON.stringify({
            id: selectedDocument._id,
            versionId: selectedVersionId,
            type: type || 'document',
            projectId: selectedDocument.project,
            proposalType
        })
    };
    return payload;
}

export {
    checkDocumentVersion,
    checkIfSignersValid,
    getSigners,
    getSupplierSigner,
    storeStringifiedDocument
}