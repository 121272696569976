import { Box, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Autocomplete from '../../../components/Autocomplete';
import FavoriteProjects from '../../../components/FavoriteProjects';
import Select from '../../../components/Select';
import PendingDocumentsTask from './PendingDocumentsTask';
import TaskBoard from './TaskBoard';
import TasksList from './TasksList';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useAppCtxActiveUser } from '../../../context/SystemContext';
import { useOperatorCtxProjects } from '../context/OperatorContext';
import { useTasksCtxAPI, useTasksCtxActiveUserTasks, useTasksCtxFilters, useTasksCtxTasksPendingDocuments, useTasksCtxTeamTasks } from '../../../context/TasksContext';
import { sortByKey } from '../../../utils/filters';

const UsersGrid = ({ layout, projectOptions, setProjectOptions }) => {
    const theme = useTheme();
    const screenXsOrSmaller = useMediaQuery(theme.breakpoints.down('sm'));
    const activeUser = useAppCtxActiveUser();
    const { handleTaskDragEnd, setFilters, sortTasks } = useTasksCtxAPI();
    const tasksPendingDocuments = useTasksCtxTasksPendingDocuments();
    const filters = useTasksCtxFilters();
    const teamTasks = useTasksCtxTeamTasks();
    const activeUserTasks = useTasksCtxActiveUserTasks()
    const projects = useOperatorCtxProjects();

    const handleFavoriteClick = (type, folderId) => {
        if(type === 'clients'){
            let client = projects.find(i => i._id == folderId);
            handleClientChange(client);
            setFilters(prevState => ({...prevState, project: 'all'}));
        } else {
            let project = projects.find(i => i._id == folderId);
            if(project){
                let client = projects.find(i => i._id == project.client);
                if(client){
                    handleClientChange(client);
                    setFilters(prevState => ({...prevState, project: folderId}));
                }
            }
        }
    };
    const handleClientChange = (newValue) => {
        if(newValue){
            const currentProjectOptions = projects.filter(project => project.client == newValue._id).map(project => ({value: project._id, label: project.name}));
            setProjectOptions([{value: 'all', label: 'Todos'}, ...currentProjectOptions.sort(sortByKey('label'))]);
            setFilters(prevState => ({...prevState, client: newValue, project: 'all'}));
        } else {
            setProjectOptions([]);
            setFilters(prevState => ({...prevState, client: null, project: ''}));
        }
    };

    if(layout === 'personal' && screenXsOrSmaller){
        return (
            <DragDropContext onDragEnd={handleTaskDragEnd}>
                <Grid container spacing={1} alignItems="flex-start" justifyContent="center" style={{height: '100%'}}>
                    <Grid item xs container spacing={1} alignItems="flex-start" style={{height: '100%'}}>
                        <Grid item style={{height: '100%', width: `100%`}}>
                            <TaskBoard
                                user={{ img: activeUser.img, screenName: activeUser.screenName }}
                                userNumberOfPendingDocuments={(tasksPendingDocuments[activeUser._id] || 0).toString()}
                            >
                                <Droppable droppableId={'activeUserUnreadTasks'} isDropDisabled>
                                    {(provided, snapshot) => (
                                        <TasksList
                                            boardId={'activeUserUnreadTasks'}
                                            innerRef={provided.innerRef}
                                            isActiveUserTask
                                            isDraggingOver={snapshot.isDraggingOver}
                                            tasks={activeUserTasks.activeUserUnreadTasks}
                                            
                                            {...provided.droppableProps}
                                        >
                                            {provided.placeholder}
                                        </TasksList>
                                    )}
                                </Droppable>
                                <Droppable droppableId={'activeUserHighPriorityTasks'}>
                                    {(provided, snapshot) => (
                                        <TasksList
                                            boardId={'activeUserHighPriorityTasks'}
                                            innerRef={provided.innerRef}
                                            isActiveUserTask
                                            isDraggingOver={snapshot.isDraggingOver}
                                            tasks={activeUserTasks.activeUserHighPriorityTasks}
                                            
                                            {...provided.droppableProps}
                                        >
                                            {provided.placeholder}
                                        </TasksList>
                                    )}
                                </Droppable>
                                <Droppable droppableId={'activeUserMediumPriorityTasks'}>
                                    {(provided, snapshot) => (
                                        <TasksList
                                            boardId={'activeUserMediumPriorityTasks'}
                                            innerRef={provided.innerRef}
                                            isActiveUserTask
                                            isDraggingOver={snapshot.isDraggingOver}
                                            tasks={activeUserTasks.activeUserMediumPriorityTasks}
                                            
                                            {...provided.droppableProps}
                                        >
                                            {provided.placeholder}
                                        </TasksList>
                                    )}
                                </Droppable>
                                <Droppable droppableId={'activeUserLowPriorityTasks'}>
                                    {(provided, snapshot) => (
                                        <TasksList
                                            boardId={'activeUserLowPriorityTasks'}
                                            innerRef={provided.innerRef}
                                            isActiveUserTask
                                            isDraggingOver={snapshot.isDraggingOver}
                                            tasks={activeUserTasks.activeUserLowPriorityTasks}
                                            
                                            {...provided.droppableProps}
                                        >
                                            {provided.placeholder}
                                        </TasksList>
                                    )}
                                </Droppable>
                            </TaskBoard>
                        </Grid>
                    </Grid>
                </Grid>
            </DragDropContext>
        );
    }

    if(layout === 'team' && teamTasks){
        return (
            <DragDropContext onDragEnd={handleTaskDragEnd}>
                <Grid container spacing={1} alignItems="flex-start" justifyContent="center" style={{height: '100%'}}>
                    <Grid item xs container spacing={1} alignItems="flex-start" wrap="nowrap" style={{height: '100%', overflowX: 'auto'}}>
                        <Grid item style={{height: '100%', width: `${(100 / (teamTasks.length + 1))}%`, minWidth: '100px'}}>
                            <TaskBoard
                                user={{ img: activeUser.img, screenName: activeUser.screenName }}
                                userNumberOfPendingDocuments={(tasksPendingDocuments[activeUser._id] || 0).toString()}
                            >
                                <Droppable droppableId={'activeUserUnreadTasks'} isDropDisabled>
                                    {(provided, snapshot) => (
                                        <TasksList
                                            boardId={'activeUserUnreadTasks'}
                                            innerRef={provided.innerRef}
                                            isActiveUserTask
                                            isDraggingOver={snapshot.isDraggingOver}
                                            tasks={activeUserTasks.activeUserUnreadTasks}
                                            
                                            {...provided.droppableProps}
                                        >
                                            {provided.placeholder}
                                        </TasksList>
                                    )}
                                </Droppable>
                                <Droppable droppableId={'activeUserHighPriorityTasks'}>
                                    {(provided, snapshot) => (
                                        <TasksList
                                            boardId={'activeUserHighPriorityTasks'}
                                            innerRef={provided.innerRef}
                                            isActiveUserTask
                                            isDraggingOver={snapshot.isDraggingOver}
                                            tasks={activeUserTasks.activeUserHighPriorityTasks}
                                            
                                            {...provided.droppableProps}
                                        >
                                            {provided.placeholder}
                                        </TasksList>
                                    )}
                                </Droppable>
                                <Droppable droppableId={'activeUserMediumPriorityTasks'}>
                                    {(provided, snapshot) => (
                                        <TasksList
                                            boardId={'activeUserMediumPriorityTasks'}
                                            innerRef={provided.innerRef}
                                            isActiveUserTask
                                            isDraggingOver={snapshot.isDraggingOver}
                                            tasks={activeUserTasks.activeUserMediumPriorityTasks}
                                            
                                            {...provided.droppableProps}
                                        >
                                            {provided.placeholder}
                                        </TasksList>
                                    )}
                                </Droppable>
                                <Droppable droppableId={'activeUserLowPriorityTasks'}>
                                    {(provided, snapshot) => (
                                        <TasksList
                                            boardId={'activeUserLowPriorityTasks'}
                                            innerRef={provided.innerRef}
                                            isActiveUserTask
                                            isDraggingOver={snapshot.isDraggingOver}
                                            tasks={activeUserTasks.activeUserLowPriorityTasks}
                                            
                                            {...provided.droppableProps}
                                        >
                                            {provided.placeholder}
                                        </TasksList>
                                    )}
                                </Droppable>
                            </TaskBoard>
                        </Grid>
                        {
                            teamTasks.map((user) => (
                                <Grid item key={user._id} style={{height: '100%', width: `${(100 / (teamTasks.length + 1))}%`, minWidth: '100px'}}>
                                    <Droppable droppableId={user._id}>
                                        {(provided, snapshot) => {
                                            const userNumberOfPendingDocuments = (tasksPendingDocuments[user._id] || 0).toString();
                                            return (
                                                <TaskBoard
                                                    boardId={user._id}
                                                    innerRef={provided.innerRef}
                                                    isDraggingOver={snapshot.isDraggingOver}
                                                    tasks={user.tasks.sort(sortTasks)}
                                                    user={{ img: user.img, screenName: user.screenName }}
                                                    userNumberOfPendingDocuments={userNumberOfPendingDocuments}

                                                    {...provided.droppableProps}
                                                >
                                                    {provided.placeholder}
                                                </TaskBoard>
                                            )
                                        }}
                                    </Droppable>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Grid>
            </DragDropContext>
        );
    }

    return (
        <DragDropContext onDragEnd={handleTaskDragEnd}>
            {
                activeUser &&
                <Container maxWidth="lg" style={{height: '100%'}}>
                    <Grid container direction="column" spacing={1} justifyContent="center" style={{height: '100%'}}>
                        <Grid item xs container direction="row" spacing={1} style={{height: '100%'}}>
                            <Grid item xs={12} sm={3}
                                style={{
                                    height: '100%',
                                }}
                            >
                                <Droppable droppableId={'activeUserHighPriorityTasks'}>
                                    {(provided, snapshot) => (
                                        <TaskBoard
                                            boardId={'activeUserHighPriorityTasks'}
                                            innerRef={provided.innerRef}
                                            isActiveUserTask
                                            isDraggingOver={snapshot.isDraggingOver}
                                            tasks={activeUserTasks.activeUserHighPriorityTasks}
                                            title={`FAZER HOJE (${activeUserTasks.activeUserHighPriorityTasks.length})`}
                                            
                                            {...provided.droppableProps}
                                        >
                                            {provided.placeholder}
                                        </TaskBoard>
                                    )}
                                </Droppable>
                            </Grid>
                            <Grid item xs={12} sm={3} style={{height: '100%'}}>
                                <Droppable droppableId={'activeUserMediumPriorityTasks'}>
                                    {(provided, snapshot) => (
                                        <TaskBoard
                                            boardId={'activeUserMediumPriorityTasks'}
                                            innerRef={provided.innerRef}
                                            isActiveUserTask
                                            isDraggingOver={snapshot.isDraggingOver}
                                            tasks={activeUserTasks.activeUserMediumPriorityTasks}
                                            title={`FAZER LOGO (${activeUserTasks.activeUserMediumPriorityTasks.length})`}
                                            
                                            {...provided.droppableProps}
                                        >
                                            {provided.placeholder}
                                        </TaskBoard>
                                    )}
                                </Droppable>
                            </Grid>
                            <Grid item xs={12} sm={3} style={{height: '100%'}}>
                                <Droppable droppableId={'activeUserLowPriorityTasks'}>
                                    {(provided, snapshot) => (
                                        <TaskBoard
                                            boardId={'activeUserLowPriorityTasks'}
                                            innerRef={provided.innerRef}
                                            isActiveUserTask
                                            isDraggingOver={snapshot.isDraggingOver}
                                            tasks={activeUserTasks.activeUserLowPriorityTasks}
                                            title={`EM ESPERA (${activeUserTasks.activeUserLowPriorityTasks.length})`}
                                            
                                            {...provided.droppableProps}
                                        >
                                            {provided.placeholder}
                                        </TaskBoard>
                                    )}
                                </Droppable>
                            </Grid>
                            <Grid item xs={12} sm={3} style={{height: '100%'}}>
                                <Box style={{
                                    backgroundColor: 'white',
                                    height: '100%',
                                    transition: 'background-color 0.2s ease',
                                    borderRadius: 8,
                                    position: 'relative',
                                    padding: 8,
                                    overflowX: 'hidden',
                                    overflowY: 'auto',
                                    '&::WebkitScrollbar': {
                                        width: 3
                                    },
                                }}>
                                    {/* <UserBox user={{ img: activeUser.img, screenName: activeUser.screenName }} /> */}
                                    {
                                        ((tasksPendingDocuments[activeUser._id] || 0).toString()) &&
                                        <PendingDocumentsTask userNumberOfPendingDocuments={(tasksPendingDocuments[activeUser._id] || 0).toString()} />
                                    }
                                    {
                                        (activeUserTasks.activeUserUnreadTasks && activeUserTasks.activeUserUnreadTasks.length >= 1) &&
                                        <Box mb={2}>
                                            <Box mt={0.5} mb={1}>
                                                <Typography variant="h6" noWrap>NÃO LIDAS ({activeUserTasks.activeUserUnreadTasks.length})</Typography>
                                            </Box>
                                            <Droppable droppableId={'activeUserUnreadTasks'} isDropDisabled>
                                                {(provided, snapshot) => (
                                                    <TasksList
                                                        boardId={'activeUserUnreadTasks'}
                                                        innerRef={provided.innerRef}
                                                        isActiveUserTask
                                                        isDraggingOver={snapshot.isDraggingOver}
                                                        tasks={activeUserTasks.activeUserUnreadTasks}
                                                        
                                                        {...provided.droppableProps}
                                                    >
                                                        {provided.placeholder}
                                                    </TasksList>
                                                )}
                                            </Droppable>
                                        </Box>
                                    }
                                    <Box mb={1}>
                                        <Typography variant="h6" noWrap>FILTRAR</Typography>
                                    </Box>
                                    <Box mb={1}>
                                        <FavoriteProjects handleFavoriteClick={handleFavoriteClick} />
                                    </Box>
                                    {
                                        projects &&
                                        <Box mb={1}>
                                            <Grid container spacing={1} alignItems="center" justifyContent="center">
                                                <Grid item xs>
                                                    <Autocomplete
                                                        label="Empresa"
                                                        options={projects.filter(project => !project.client).sort(sortByKey('name')).map((mappedFolder, mappedFolderIndex) => ({...mappedFolder, name: `${mappedFolderIndex + 1}) ${mappedFolder.name}`}))} 
                                                        getOptionLabel={(option) => option.name}
                                                        value={filters.client} onChange={(e, newValue) => handleClientChange(newValue)}
                                                    />
                                                </Grid>
                                                {
                                                    filters.client &&
                                                    <Grid item xs={6}>
                                                        <Select
                                                            label="Pasta"
                                                            options={projectOptions}
                                                            value={filters.project} onChange={(e) => setFilters(prevState => ({...prevState, project: e.target.value}))}
                                                        />
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Box>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            }
        </DragDropContext>
    );
};

export default UsersGrid;