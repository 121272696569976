import React from 'react';
import Box from '@mui/material/Box';

const Circle = ({size}) => {
    let style = {};
    if(size){
        style.height = size;
        style.width = size;
    }
    return (
        <Box
            sx={{
                height: '25px',
                width: '25px',
                backgroundColor: theme => theme.palette.secondary.main,
                borderRadius: '50%',
                display: 'inline-block'
            }}
            style={style}></Box>
    );
};

export default Circle;