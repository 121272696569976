// verificar se precisa do "templates aqui"
import React, { useState, useEffect } from 'react';
import { Collapse } from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { useTheme } from '@mui/material/styles';
import ActionMenu from '../components/ActionMenu';
import AddAlertWindow from '../../../../components/AddAlertWindow';
import ChangeDocumentFolderMenu from '../../../../components/ChangeDocumentFolderMenu';
import ChangeDocumentGroupWindow from '../../../../components/ChangeDocumentGroupWindow';
import ChangeStarredInfoPopOver from '../../../Client/Docs/components/ChangeStarredInfoPopOver';
import DeleteDocDialog from '../../../../components/DeleteDocDialog';
import DocFormView from '../../../../components/DocFormView';
import DocumentAlertsPopOver from '../../../../components/DocumentAlertsPopOver';
import DocumentListItem from '../../../../components/DocumentListItem';
import FlagDocumentWindow from '../../../../components/Documents/FlagDocumentWindow';
import HistoryPopOver from '../../../../components/HistoryPopOver';
import LoaderEllipsis from '../../../../components/LoaderEllipsis';
import NewStarredInfoPopOver from '../../../../components/NewStarredInfoPopOver';
import Pagination from '../../../../components/Pagination';
import SetDocumentPendingDialog from '../../../../components/Documents/SetDocumentPendingDialog';
import SignDocumentWindow from '../../../../components/eSignature/SignDocumentWindow';
import UpdateNameDialog from '../../../../components/UpdateNameDialog';
import ViewBox from '../../../../components/ViewBox';
import { useAppCtxAPI, useAppCtxActiveUser } from '../../../../context/SystemContext';
import { useSocket } from '../../../../context/SocketContext';
import { useDocumentsAPI, useDocumentsCtxSelectedDocument, useDocumentsCtxTemplates } from '../../../../context/DocumentsContext';
import { useOperatorCtxProjects, useOperatorCtxUsers } from '../../context/OperatorContext';
import CommentWindow from '../../../../components/CommentWindow';
import { getGroupNameById, updateStateChangeArrayItemWith_id, updateStateDeleteArrayItemBy_id, getDocumentLastCommentIfHuman, getFolderCustomDocumentFields, getGroupsByFolderId } from '../../../../utils/common';
import { onDocumentDeletedEvent, onDocumentFlaggedEvent, onDocumentGroupChangedEvent, onDocumentNameChangedEvent, onDocumentSentEvent, onDocumentSignedEvent } from '../../../../utils/socket-events';

function OperatorDocumentsPage({
    children,
    currentPage,
    documentAlerts, setDocumentAlerts,
    documentsCount,
    filterDocuments,
    handlePageClick,
    loading, setLoading,
    visibleDocuments, setVisibleDocuments,
    floatingNotification
}){
    const theme = useTheme();
    const { floatingAlert, toast } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const { setActionMenuAnchorEl, setSelectedDocument, showCommentView, showFlagDocumentView } = useDocumentsAPI();
    const selectedDocument = useDocumentsCtxSelectedDocument();
    const templates = useDocumentsCtxTemplates()
    const projects = useOperatorCtxProjects();
    const { users } = useOperatorCtxUsers();
    const {createSocketConnection} = useSocket();
    const socket = createSocketConnection();
    const [changeStarredInfoList, set_changeStarredInfoList] = useState([]);
    const [alertMessage, setAlertMessage] = useState({});
    const [isSetDocumentPendingDialogOpen, setSetDocumentPendingDialogOpen] = useState(false);

    const deleteDocumentWithMessage = (payload) => {
        setVisibleDocuments(prevState => {
            if(prevState){
                const foundDocumentIndex = prevState.findIndex(foundDocument => foundDocument._id === payload.data);
                if(foundDocumentIndex !== -1){
                    if(typeof payload.message.setMessage === 'function' && payload.message.ifFound) payload.message.setMessage(payload.message.ifFound);
                    return [...prevState.slice(0, foundDocumentIndex), ...prevState.slice(foundDocumentIndex + 1)];
                }
            }
            return prevState;
        });
    };
    
    const updateDocumentWithMessage = (payload) => {
        setVisibleDocuments(prevState => {
            if(prevState){
                const foundDocumentIndex = prevState.findIndex(foundDocument => foundDocument._id === payload.data._id);
                if(foundDocumentIndex !== -1){
                    if(typeof payload.message.setMessage === 'function' && payload.message.ifFound) payload.message.setMessage(payload.message.ifFound);
                    return [...prevState.slice(0, foundDocumentIndex), {...payload.data, updatedAt: new Date()}, ...prevState.slice(foundDocumentIndex + 1)];
                }
            }
            return prevState;
        });
    };

    useEffect(() => {
        const documentDeletedEventName = 'DOCUMENT DELETED';
        const documentFlaggedEventName = 'DOCUMENT FLAGGED';
        const documentGroupChangedSocketEventName = 'DOCUMENT GROUP CHANGED';
        const documentNameChangedSocketEventName = 'DOCUMENT NAME CHANGED';
        const documentReviewFinishedEventName = 'DOCUMENT REVIEW FINISHED';
        const documentSentSocketEventName = 'DOCUMENT SENT';
        const documentSignedSocketEventName = 'DOCUMENT SIGNED';

        const handleDocumentDeletedEvent = (data) => onDocumentDeletedEvent(data, deleteDocumentWithMessage, floatingAlert);
        const handleDocumentFlaggedEvent = (data) => onDocumentFlaggedEvent(data, updateDocumentWithMessage, floatingAlert);
        const handleDocumentGroupChangedEvent = (data) => onDocumentGroupChangedEvent({...data, activeUserId: activeUser._id}, updateDocumentWithMessage, toast);
        const handleDocumentNameChangedEvent = (data) => onDocumentNameChangedEvent(data, updateDocumentWithMessage, toast);
        const handleDocumentSentEvent = (data) => onDocumentSentEvent(data, updateDocumentWithMessage, floatingAlert);
        const handleDocumentSignedEvent = (data) => onDocumentSignedEvent(data, updateDocumentWithMessage, floatingAlert);

        const handleDocumentReviewedFinishedEvent = (data) => {
            //update
        }

        socket.on(documentDeletedEventName, handleDocumentDeletedEvent);
        socket.on(documentFlaggedEventName, handleDocumentFlaggedEvent);
        socket.on(documentGroupChangedSocketEventName, handleDocumentGroupChangedEvent);
        socket.on(documentNameChangedSocketEventName, handleDocumentNameChangedEvent);
        socket.on(documentSentSocketEventName, handleDocumentSentEvent);
        socket.on(documentReviewFinishedEventName, handleDocumentReviewedFinishedEvent);
        socket.on(documentSignedSocketEventName, handleDocumentSignedEvent);
        return () => {
            socket.off(documentDeletedEventName, handleDocumentDeletedEvent);
            socket.on(documentFlaggedEventName, handleDocumentFlaggedEvent);
            socket.off(documentGroupChangedSocketEventName, handleDocumentGroupChangedEvent);
            socket.off(documentNameChangedSocketEventName, handleDocumentNameChangedEvent);
            socket.off(documentSentSocketEventName, handleDocumentSentEvent);
            socket.off(documentReviewFinishedEventName, handleDocumentReviewedFinishedEvent);
            socket.off(documentSignedSocketEventName, handleDocumentSignedEvent);
        };
    }, []);

    useEffect(() => {
        setAlertMessage(floatingNotification);
    }, [floatingNotification]);
    
    const handle_actionMenuOpen = (e, clickedDocument) => {
        setActionMenuAnchorEl(e.target, clickedDocument);
    };
    
    const handleSetMessage = (message) => {
        setAlertMessage({message});
    };

    const handleReadComment = (comment) => {
        showCommentView(comment);
    };

    const handleFlagDocumentButtonClick = (clickedDocument) => {
        showFlagDocumentView(clickedDocument);
    };

    const deleteDocument = (data) => {
        updateStateDeleteArrayItemBy_id(data, setVisibleDocuments);
    };

    const updateDocument = (data) => {
        updateStateChangeArrayItemWith_id(data, setVisibleDocuments);
    };

    if(!filterDocuments) filterDocuments = (item) => {
        return true;
    }

    return (
        <ViewBox message={alertMessage}>

            {children}
            
            {
                loading &&
                <LoaderEllipsis primary />
            }
            <Collapse in={!loading} timeout={1000}>
                <Box>
                    {
                        handlePageClick &&
                        <Pagination count={documentsCount} page={currentPage} perPage={25} onChange={(e, page) => handlePageClick(page)} my={0.5} />
                    }
                    <List id="docs-list" dense>
                        {
                            visibleDocuments
                            .filter(filterDocuments)
                            .map((doc) => {
                                let useGroups = false;
                                let groupName = '';
                                if(projects){
                                    let currentProject = projects.find(project => project._id === (doc.project || doc.client));
                                    if(currentProject && currentProject.useGroups){
                                        useGroups = true;
                                        if(useGroups){
                                            let groupId = doc.groupId;
                                            if(groupId){
                                                let folderGroups = getGroupsByFolderId(doc.project || doc.client, projects);
                                                groupName = getGroupNameById(groupId, folderGroups);
                                            } else {
                                                groupName = 'Nenhum';
                                            }
                                        }
                                    }
                                }

                                const { documentComment, documentNoHtmlComment } = getDocumentLastCommentIfHuman(doc);

                                let companyName = '', folderName = '', documentFolder, folderCustomDocumentFields = [];
                                if(projects){
                                    if(doc.project){
                                        documentFolder = projects.find(project => project._id === doc.project);
                                        if(documentFolder){
                                            folderName = documentFolder.name;
                                            const companyId = documentFolder.client;
                                            if(companyId){
                                                const documentCompany = projects.find(project => project._id === companyId);
                                                if(documentCompany){
                                                    companyName = documentCompany.name;
                                                }
                                            }
                                            folderCustomDocumentFields = getFolderCustomDocumentFields(documentFolder);
                                        }
                                    } else if(doc.client) {
                                        const documentCompany = projects.find(project => project._id === doc.client);
                                        if(documentCompany) companyName = documentCompany.name;
                                    }
                                }

                                const boxStyle = doc.flag === 2 ? {border: `2px solid ${theme.palette.secondary.main}`} : undefined;

                                return (
                                    <DocumentListItem
                                        key={doc._id}
                                        activeUserIsManager
                                        activeUserIsOperator
                                        boxStyle={boxStyle}
                                        comment={documentComment}
                                        companyName={companyName}
                                        docsAlerts={documentAlerts}
                                        documentFolder={documentFolder}
                                        eSignature
                                        folderCustomDocumentFields={folderCustomDocumentFields}
                                        folderName={folderName}
                                        groupName={groupName}
                                        handleActionMenuOpen={handle_actionMenuOpen}
                                        handleFlagDocumentButtonClick={handleFlagDocumentButtonClick}
                                        listDocument={doc}
                                        noHtmlComment={documentNoHtmlComment}
                                        projects={projects}
                                        setChangeStarredInfoList={set_changeStarredInfoList}
                                        setDocuments={setVisibleDocuments}
                                        setSelectedDocument={setSelectedDocument}
                                        showCompany
                                        templates={templates}
                                        updateDocument={updateDocument}
                                        useGroups={useGroups}
                                        users={users}
                                    />
                                )
                            })
                        }
                    </List>
                    {
                        handlePageClick &&
                        <Pagination count={documentsCount} page={currentPage} perPage={25} onChange={(e, page) => handlePageClick(page)} my={0.5} />
                    }
                </Box>
            </Collapse>

            <ActionMenu 
                deleteDocument={deleteDocument}
                updateDocument={updateDocument}
            />
            <HistoryPopOver
                handleReadComment={handleReadComment}
                updateDocument={updateDocument}
                users={users}
            />
            <DocFormView />
            <FlagDocumentWindow
                activeUserIsOperator
                updateDocument={updateDocument}
            />
            <DeleteDocDialog updateDocument={updateDocument} />
            <UpdateNameDialog updateDocument={updateDocument} />
            <ChangeDocumentFolderMenu
                projects={projects}
                updateDocument={updateDocument}
            />
            <ChangeDocumentGroupWindow
                projects={projects}
                updateDocument={updateDocument}
            />
            <ChangeStarredInfoPopOver
                list={changeStarredInfoList}
                data={{set_changeStarredInfoList, setDocs: setVisibleDocuments}}
            />
            <NewStarredInfoPopOver
                list={changeStarredInfoList}
                data={{setDocs: setVisibleDocuments}}
            />
            <AddAlertWindow data={{setDocsAlerts: setDocumentAlerts}} />
            <DocumentAlertsPopOver availableDocumentAlerts={documentAlerts} />
            <CommentWindow users={users} />

            {
                projects &&
                <SignDocumentWindow />
            }
            <SetDocumentPendingDialog
                open={isSetDocumentPendingDialogOpen} setOpen={setSetDocumentPendingDialogOpen}
                selectedDocument={selectedDocument}
                setDocuments={setVisibleDocuments}
                loading={loading}
                setLoading={setLoading}
                handleSetMessage={handleSetMessage}
            />
        </ViewBox>
    );
}
export default OperatorDocumentsPage;