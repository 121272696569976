import { useEffect, useState } from 'react';
import { useAppCtxVersion } from '../context/SystemContext';

const useAppVersion = () => {
    const { backendVersion, frontendVersion } = useAppCtxVersion();
    const [updateRequired, setUpdateRequired] = useState(false);

    useEffect(() => {
        setUpdateRequired(backendVersion && backendVersion > frontendVersion);
    }, [backendVersion, frontendVersion]);

    return updateRequired;
};

export default useAppVersion;