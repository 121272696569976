import Main from './Main';
import { ManagementProvider } from '../../context/ManagementContext';

const Management = () => {
    return (
        <ManagementProvider>
            <Main />
        </ManagementProvider>
    );
}

export default Management;