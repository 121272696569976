import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import DetailedFilesList from '../DetailedFilesList';
import RiskLevel from './RiskLevel';
import SectionTitle from '../SectionTitle';
import ViewBox from '../ViewBox';
import { CLEARANCE_RISK_OPTIONS } from '../../utils/constants';

const RiskApprovalRequired = ({level, settings}) => {
    if(settings[level]){
        const riskLevel = CLEARANCE_RISK_OPTIONS.find(option => option.value === level);
        const numberOfApprovals = settings[level];
        return (
            <Box>
                <Typography variant="body1"><span style={{color: riskLevel.color}}>&#11044;</span>&nbsp;{riskLevel.label} &rarr; {numberOfApprovals} aprovaç{numberOfApprovals === 1 ? 'ão' : 'ões'} exigida{numberOfApprovals === 1 ? '' : 's'}</Typography>
            </Box>
        );
    }
    return null;
};

const ClearanceGuidelines = ({ folderClearanceInformation }) => {
    const theme = useTheme();
    const [showFiles, setShowFiles] = useState(false);
    const [files, setFiles] = useState(false);
    const screenXsOrSmaller = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if(folderClearanceInformation){
            if(folderClearanceInformation.clearanceGuidelinesUrl || (folderClearanceInformation.clearanceFiles && folderClearanceInformation.clearanceFiles.length >= 1)){
                let currentFiles = folderClearanceInformation.clearanceFiles.map(file => file.path);
                if(folderClearanceInformation.clearanceGuidelinesUrl) currentFiles.unshift({
                    name: 'Diretrizes gerais de clearance',
                    path: folderClearanceInformation.clearanceGuidelinesUrl,
                    format: 'googleDoc'
                });
                setFiles(currentFiles);
                setShowFiles(true);
            }
            
        }
    }, [folderClearanceInformation]);
    return (
        <ViewBox>
            <Box mb={3}>
                <SectionTitle title={`Níveis de risco`} />
                <Box mb={2}>
                    {
                        CLEARANCE_RISK_OPTIONS.map(riskLevel => (
                            <React.Fragment key={riskLevel.value}>
                                <Grid container spacing={1} alignItems="center" wrap={screenXsOrSmaller ? undefined : 'nowrap'}>
                                    <Grid item xs={12} sm="auto">
                                        <RiskLevel riskLevel={{label: riskLevel.label, color: riskLevel.color}} textVariant="body1" />
                                    </Grid>
                                    {
                                        !screenXsOrSmaller &&
                                        <Grid item>
                                            <Typography variant="body1">&rarr;</Typography>
                                        </Grid>
                                    }
                                    <Grid item>
                                        <Typography variant="body1">{riskLevel.description}</Typography>
                                    </Grid>
                                </Grid>
                                <Divider />
                            </React.Fragment>
                        ))
                    }
                </Box>
            </Box>

            {
                folderClearanceInformation &&
                <Box>
                    {
                        folderClearanceInformation.clearanceRequireApproval &&
                        <Box mb={3}>
                            <SectionTitle title="Aprovações" />
                            <Box mb={2}>
                                <RiskApprovalRequired level="one" settings={folderClearanceInformation.clearanceNumberOfApprovalsRequired} />
                                <RiskApprovalRequired level="two" settings={folderClearanceInformation.clearanceNumberOfApprovalsRequired} />
                                <RiskApprovalRequired level="three" settings={folderClearanceInformation.clearanceNumberOfApprovalsRequired} />
                                <RiskApprovalRequired level="four" settings={folderClearanceInformation.clearanceNumberOfApprovalsRequired} />
                            </Box>
                            <Box mb={2}>
                                <Box mb={1}>
                                    <Typography variant="h6">Autoridades que podem aprovar riscos</Typography>
                                </Box>
                                {
                                    folderClearanceInformation.clearanceApprovalAuthorities.map((authority, authorityIndex) => {
                                        const riskLevel = CLEARANCE_RISK_OPTIONS.find(option => option.value === authority.maximumRiskLevel);
                                        return (
                                            <Box key={authorityIndex}>
                                                <Grid container spacing={1} alignItems="flex-end">
                                                    <Grid item>
                                                        <Typography variant="body1">&bull; {authority.type === 'group' ? 'Grupo ' : ''}{authority.authorityName || authority.authorityId} aprova anotações com risco <span style={{color: riskLevel.color}}>&#11044;</span>&nbsp;{riskLevel.label} ou menor</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        )
                                    })
                                }
                            </Box>
                        </Box>
                    }
                    {
                        showFiles &&
                        <Box mb={3}>
                            <SectionTitle title="Arquivos" />
                            <Box mb={2}>
                                <DetailedFilesList list={files} />
                            </Box>
                        </Box>
                    }
                </Box>
            }
        </ViewBox>
    );
};

export default ClearanceGuidelines;