import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import AddOrSubtractDial from '../../../../../components/AddOrSubtractDial';
import Autocomplete from '../../../../../components/Autocomplete';
import CurrencyInput from '../../../Accounting/components/CurrencyInput';
import Dialog from '../../../../../components/Dialog/Dialog';
import LoaderEllipsis from '../../../../../components/LoaderEllipsis';
import Select from '../../../../../components/Select';
import Switch from '../../../../../components/Switch';
import { useAppCtxAPI, useAppCtxActiveUser } from '../../../../../context/SystemContext';
import { useClient, useClientCtxActiveClient } from '../../../../../context/ClientContext';
import { useDocumentsAPI } from '../../../../../context/DocumentsContext';
import { useOperatorCtxUsers } from '../../../context/OperatorContext';
import { useOperatorTemplatesCtxAPI, useOperatorTemplatesCtxChangeTemplateView } from '../context/TemplatesContext';
import { getUserNameById, serverRequest } from '../../../../../utils/common';
import { FIRST_CLIENT_ID } from '../../../../../utils/constants';
import { sortByKey } from '../../../../../utils/filters';
import moment from 'moment';

const TemplateWindow = () => {
    const { floatingAlert, toast } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const { lists } = useClient();
    const activeClient = useClientCtxActiveClient();
    const { dispatch: dispatchDocuments } = useDocumentsAPI();
    const { users } = useOperatorCtxUsers();
    const { hideChangeTemplateView } = useOperatorTemplatesCtxAPI();
    const { changeTemplateViewOpen, changeTemplateViewClickedTemplate, changeTemplateViewFoundTemplate: selectedTemplate } = useOperatorTemplatesCtxChangeTemplateView();
    const defaultSkipOperatorIfNotFreeStyleException = {
        id: '',
        questionId: '',
        questionName: '',
        userInput: ''
    };
    const defaultTemplateState = {
        averageNumberOfMinutesRequiredForDocumentDelivery: 15,
        clientId: '',
        checkCorporationId: false,
        description: '',
        logo: false,
        name: '',
        skipGoogleDoc: false,
        skipOperator: false,
        skipOperatorIfNotFreeStyle: false,
        skipOperatorIfNotFreeStyleDelay: 10,
        skipOperatorIfNotFreeStyleExceptions: []
    };
    const [templateState, setTemplateState] = useState(defaultTemplateState);
    const [templateFormQuestions, setTemplateFormQuestions] = useState([]);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if(changeTemplateViewOpen && selectedTemplate){
            setTemplateState({
                ...selectedTemplate
            });
            const templateForm = selectedTemplate.form;
            const currentTemplateFormQuestions = templateForm.filter(templateFormQuestion => templateFormQuestion.type !== 'heading').map(templateFormQuestion => ({label: templateFormQuestion.name, value: templateFormQuestion.name}));
            setTemplateFormQuestions(currentTemplateFormQuestions);
        }
    }, [changeTemplateViewOpen, selectedTemplate]);

    const changeMinimumNumberOfMinutesRequiredForDocumentDelivery = (minutes) => {
        let newValue = (templateState.averageNumberOfMinutesRequiredForDocumentDelivery || 15) + minutes;
        if(newValue <= 15) newValue = 15;
        setTemplateState(prevState => ({...prevState, averageNumberOfMinutesRequiredForDocumentDelivery: newValue}));
    };

    const changeSkipOperatorIfNotFreeStyleDelay = (minutes) => {
        let newValue = (templateState.skipOperatorIfNotFreeStyleDelay || 10) + minutes;
        if(newValue <= 10) newValue = 10;
        setTemplateState(prevState => ({...prevState, skipOperatorIfNotFreeStyleDelay: newValue}));
    };

    const handleSkipOperatorsIfNotFreeStyleExceptionQuestionChange = (newValue, exceptionIndex) => {
        setTemplateState(prevState => ({
            ...prevState,
            skipOperatorIfNotFreeStyleExceptions: [
                ...prevState.skipOperatorIfNotFreeStyleExceptions.slice(0, exceptionIndex),
                {
                    ...prevState.skipOperatorIfNotFreeStyleExceptions[exceptionIndex],
                    questionName: newValue
                },
                ...prevState.skipOperatorIfNotFreeStyleExceptions.slice(exceptionIndex + 1)
            ]
        }));
    };

    const handleSkipOperatorsIfNotFreeStyleExceptionDelete = (exceptionIndex) => {
        setTemplateState(prevState => ({
            ...prevState,
            skipOperatorIfNotFreeStyleExceptions: [
                ...prevState.skipOperatorIfNotFreeStyleExceptions.slice(0, exceptionIndex),
                ...prevState.skipOperatorIfNotFreeStyleExceptions.slice(exceptionIndex + 1)
            ]
        }));
    };
    
    const handleSkipOperatorsIfNotFreeStyleExceptionInputChange = (newValue, exceptionIndex) => {
        setTemplateState(prevState => ({
            ...prevState,
            skipOperatorIfNotFreeStyleExceptions: [
                ...prevState.skipOperatorIfNotFreeStyleExceptions.slice(0, exceptionIndex),
                {
                    ...prevState.skipOperatorIfNotFreeStyleExceptions[exceptionIndex],
                    userInput: newValue
                },
                ...prevState.skipOperatorIfNotFreeStyleExceptions.slice(exceptionIndex + 1)
            ]
        }));
    };

    const handleSubmit = async () => {
        setSaving(true);
        try {
            const data = await serverRequest({path: `/data/templates/one/${selectedTemplate._id}/update`, method: 'post', data: {
                averageNumberOfMinutesRequiredForDocumentDelivery: templateState.averageNumberOfMinutesRequiredForDocumentDelivery,
                checkCorporationId: templateState.checkCorporationId,
                description: templateState.description,
                logo: templateState.logo,
                name: templateState.name,
                skipGoogleDoc: templateState.skipGoogleDoc,
                skipOperator: templateState.skipOperator,
                skipOperatorIfNotFreeStyle: templateState.skipOperatorIfNotFreeStyle,
                skipOperatorIfNotFreeStyleDelay: templateState.skipOperatorIfNotFreeStyleDelay,
                skipOperatorIfNotFreeStyleExceptions: templateState.skipOperatorIfNotFreeStyleExceptions
            }});
            const updatedTemplate = data.updatedTemplate;
            dispatchDocuments({type: 'UPDATE TEMPLATE', payload: updatedTemplate});
            toast(`Você alterou a matriz ${templateState.name}.`);
            setTemplateState(defaultTemplateState);
            handleClose();
        } catch (error) {
            floatingAlert(error, 'error');
        }
        setSaving(false);
    };

    const handleClose = () => {
        hideChangeTemplateView();
    };

    const optionsDisabled = activeClient?._id === FIRST_CLIENT_ID && templateState.clientId !== FIRST_CLIENT_ID && activeUser?.type < 6;

    return (
        <Dialog
            // maxWidth="lg"
            onClose={handleClose}
            onSaveClick={handleSubmit}
            open={changeTemplateViewOpen}
            saveButtonDisabled={!templateState.name}
            saving={saving}
            title={selectedTemplate?.name || ''}
        >

            <Collapse in={!changeTemplateViewClickedTemplate || !selectedTemplate}>
                <LoaderEllipsis primary />
            </Collapse>
            <Collapse in={(!!changeTemplateViewClickedTemplate && !!selectedTemplate)}>
                <Box>
                    {
                        selectedTemplate?.updatedAt &&
                        <Box mb={3}>
                            <Typography variant="body1">Última alteração: {moment(selectedTemplate.updatedAt).format('L LTS')}{selectedTemplate.updatedBy ? ` por ${getUserNameById(users, selectedTemplate.updatedBy)}` : ''}</Typography>
                        </Box>
                    }
                    <Box mb={3}>
                        <Box mb={2}>
                            <Typography variant="h4">Dados gerais</Typography>
                        </Box>
                        <Box mb={1}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="standard"
                                        label="Nome da matriz"
                                        fullWidth
                                        required
                                        value={templateState.name}
                                        onChange={(e) => setTemplateState(prevState => ({...prevState, name: e.target.value}))} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="standard"
                                        label="Descrição"
                                        fullWidth
                                        value={templateState.description}
                                        InputLabelProps={{ shrink: !!templateState.description }}
                                        onChange={(e) => setTemplateState(prevState => ({...prevState, description: e.target.value}))} />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box mb={3}>
                        <Box mb={2}>
                            <Typography variant="h4">Tempo mínimo para revisão</Typography>
                        </Box>
                        <Box mb={1}>
                            <AddOrSubtractDial
                                subtractDisabled={(templateState.clientId !== FIRST_CLIENT_ID && activeUser?.type < 6) || !templateState.averageNumberOfMinutesRequiredForDocumentDelivery || templateState.averageNumberOfMinutesRequiredForDocumentDelivery === 15}
                                subtractOnClick={() => changeMinimumNumberOfMinutesRequiredForDocumentDelivery(-15)}
                                number={templateState.averageNumberOfMinutesRequiredForDocumentDelivery || 15}
                                addDisabled={optionsDisabled}
                                addOnClick={() => changeMinimumNumberOfMinutesRequiredForDocumentDelivery(15)}
                            />
                        </Box>
                    </Box>
                    <Box mb={3}>
                        <Box mb={2}>
                            <Typography variant="h4">Opções</Typography>
                        </Box>
                        <Box mb={1}>
                            <Switch
                                disabled={optionsDisabled}
                                label={`Mostrar logo da pasta ou da empresa${templateState.logo ? '' : '?'}`}
                                noWrap
                                checked={templateState.logo}
                                onChange={(e) => setTemplateState(prevState => ({...prevState, logo: e.target.checked}))}
                            />
                            <Switch
                                disabled={optionsDisabled}
                                label={`Gerar PDF ao invés de documento editável${templateState.skipGoogleDoc ? '' : '?'}`}
                                noWrap
                                checked={templateState.skipGoogleDoc}
                                onChange={(e) => setTemplateState(prevState => ({...prevState, skipGoogleDoc: e.target.checked}))}
                            />
                            <Switch
                                disabled={optionsDisabled}
                                label={`Disponibilizar o documento ao(à) solicitante imediatamente${templateState.skipOperator ? '' : '?'}`}
                                noWrap
                                checked={templateState.skipOperator}
                                onChange={(e) => setTemplateState(prevState => ({...prevState, skipOperator: e.target.checked}))}
                            />
                            <Switch
                                disabled={optionsDisabled}
                                label={`Verificar se o CNPJ indicado no formulário é compatível com o objeto do contrato${templateState.checkCorporationId ? '' : '?'}`}
                                noWrap
                                checked={templateState.checkCorporationId}
                                onChange={(e) => setTemplateState(prevState => ({...prevState, checkCorporationId: e.target.checked}))}
                            />
                            <Switch
                                disabled={optionsDisabled}
                                label={`Disponibilizar o documento ao(à) solicitante automaticamente caso o formulário não contenha respostas de texto livre${templateState.skipOperatorIfNotFreeStyle ? '' : '?'}`}
                                noWrap
                                checked={templateState.skipOperatorIfNotFreeStyle}
                                onChange={(e) => setTemplateState(prevState => ({...prevState, skipOperatorIfNotFreeStyle: e.target.checked}))}
                            />
                        </Box>
                    </Box>
                    {
                        templateState.skipOperatorIfNotFreeStyle &&
                        <Box mb={3}>
                            <Box mb={2}>
                                <Typography variant="h4">Tempo para envio automático de documentos com formulários sem respostas de texto livre</Typography>
                            </Box>
                            <Box mb={1}>
                                <AddOrSubtractDial
                                    subtractDisabled={templateState.skipOperatorIfNotFreeStyleDelay === 10}
                                    subtractOnClick={() => changeSkipOperatorIfNotFreeStyleDelay(-10)}
                                    number={templateState.skipOperatorIfNotFreeStyleDelay || 10}
                                    addDisabled={optionsDisabled}
                                    addOnClick={() => changeSkipOperatorIfNotFreeStyleDelay(10)}
                                />
                            </Box>
                        </Box>
                    }
                    {
                        templateState.skipOperatorIfNotFreeStyle &&
                        <Box mb={3}>
                            <Box mb={2}>
                                <Typography variant="h4">Exceções ao envio automático de documentos com formulários sem respostas de texto livre</Typography>
                                <Typography variant="body2">Se o formulário for preenchido com qualquer uma das respostas a seguir, o documento não será enviado automaticamente.</Typography>
                            </Box>
                            {
                                selectedTemplate &&
                                <Box mb={1}>
                                    {
                                        templateState.skipOperatorIfNotFreeStyleExceptions.map((skipOperatorIfNotFreeStyleException, skipOperatorIfNotFreeStyleExceptionIndex) => {
                                            let foundQuestion, foundQuestionType, foundQuestionOptions = [], currentList = [];
                                            if(skipOperatorIfNotFreeStyleException.questionName){
                                                const templateForm = selectedTemplate.form;
                                                foundQuestion = templateForm.find(templateFormQuestion => templateFormQuestion.name === skipOperatorIfNotFreeStyleException.questionName)
                                                if(foundQuestion){
                                                    foundQuestionType = foundQuestion.type;
                                                    if(foundQuestion.options) foundQuestionOptions = foundQuestion.options.map(option => ({...option, value: option.choice}));
                                                    if(foundQuestionType === 'list'){
                                                        const typeId = foundQuestion.typeId;
                                                        const foundList = lists.find(list => list._id === typeId || list.shortName === typeId);
                                                        if(foundList){
                                                            currentList = foundList.list;
                                                            foundQuestionOptions = foundList.list.sort(sortByKey('key')).map(option => option.id);
                                                        }
                                                    }
                                                }
                                            }
                                            return (
                                                <Box key={skipOperatorIfNotFreeStyleExceptionIndex}>
                                                    <Grid container spacing={1} alignItems="flex-start" wrap="nowrap" style={{width: '100%'}}>
                                                        <Grid item style={{width: '34%'}}>
                                                            <Select
                                                                options={templateFormQuestions}
                                                                value={skipOperatorIfNotFreeStyleException.questionName}
                                                                onChange={(e) => handleSkipOperatorsIfNotFreeStyleExceptionQuestionChange(e.target.value, skipOperatorIfNotFreeStyleExceptionIndex)}
                                                            />
                                                        </Grid>
                                                        <Grid item style={{width: 'calc(100% - 34% - 56px)'}}>
                                                            {
                                                                foundQuestionType === 'radio'
                                                                ?
                                                                <Select
                                                                    options={foundQuestionOptions}
                                                                    value={skipOperatorIfNotFreeStyleException.userInput || 'select'}
                                                                    onChange={(e) => handleSkipOperatorsIfNotFreeStyleExceptionInputChange(e.target.value, skipOperatorIfNotFreeStyleExceptionIndex)}
                                                                />
                                                                :
                                                                foundQuestionType === 'list'
                                                                ?
                                                                <Autocomplete
                                                                    options={foundQuestionOptions}
                                                                    getOptionLabel={(option) => {
                                                                        const foundListItem = currentList.find(listItem => listItem.id === option);
                                                                        if(foundListItem) return foundListItem.key;
                                                                        return '';
                                                                    }}
                                                                    value={skipOperatorIfNotFreeStyleException.userInput}
                                                                    onChange={(e, newValue) => handleSkipOperatorsIfNotFreeStyleExceptionInputChange(newValue, skipOperatorIfNotFreeStyleExceptionIndex)}
                                                                />
                                                                :
                                                                foundQuestionType === 'currency'
                                                                ?
                                                                <Box>
                                                                    <CurrencyInput
                                                                        fullWidth
                                                                        value={skipOperatorIfNotFreeStyleException.userInput || 0}
                                                                        onChange={(e, newValue) => handleSkipOperatorsIfNotFreeStyleExceptionInputChange(newValue, skipOperatorIfNotFreeStyleExceptionIndex)}
                                                                    />
                                                                    <Typography variant="body2">O documento não será enviado se a resposta do formulário for maior ou igual ao valor acima.</Typography>
                                                                </Box>
                                                                :
                                                                <TextField
                                                                    variant="standard"
                                                                    fullWidth
                                                                    value={skipOperatorIfNotFreeStyleException.userInput}
                                                                    onChange={(e) => handleSkipOperatorsIfNotFreeStyleExceptionInputChange(e.target.value, skipOperatorIfNotFreeStyleExceptionIndex)} />
                                                            }
                                                        </Grid>
                                                        <Grid item style={{width: 56}}>
                                                            <IconButton
                                                                onClick={() => handleSkipOperatorsIfNotFreeStyleExceptionDelete(skipOperatorIfNotFreeStyleExceptionIndex)}
                                                                size="large">
                                                                <ClearIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            );
                                        })
                                    }
                                    <Grid container justifyContent="flex-end">
                                        <Grid item>
                                            <Button startIcon={<AddIcon />} onClick={() => setTemplateState(prevState => ({...prevState, skipOperatorIfNotFreeStyleExceptions: [...prevState.skipOperatorIfNotFreeStyleExceptions, defaultSkipOperatorIfNotFreeStyleException]}))}>Nova exceção</Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            }
                        </Box>
                    }
                </Box>
            </Collapse>

        </Dialog>
    );
};

export default TemplateWindow;