import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppCtxAPI, useAppCtxActiveUser } from '../../context/SystemContext';
import { FolderUserProvider } from './context/ClientContext';
import Main from './Main';

function Client(){
    const { handleNavigate } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const params = useParams();
    const workspaceId = params.workspaceId;

    useEffect(() => {
        if(activeUser && workspaceId){
            if(activeUser.workspaceShortName !== workspaceId){
                handleNavigate(`/paineis`);
            }
        }
    }, [activeUser]);

    return (
        <FolderUserProvider>
            <Main />
        </FolderUserProvider>
    );
}

export default Client;