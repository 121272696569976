import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import Autocomplete from './Autocomplete';
import CurrencyInput from '../pages/Operator/Accounting/components/CurrencyInput';
import Select from './Select';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import update from 'immutability-helper';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

const PaymentScheduleQuestion = (props) => {
    const { initialValue } = props;
    const questionOptions = [
        { choice: 'uponAgreementExecuted', label: '100% na assinatura do contrato (à vista)' },
        { choice: 'uponServicesCompleted', label: '100% na conclusão dos serviços' },
        { choice: 'date', label: '100% em data específica (especificar data)' },
        { choice: 'instalments', label: 'Em parcelas' },
        { choice: 'tbd', label: 'A combinar' },
        { choice: 'other', label: 'Outro' },
        { choice: 'completeLater', label: 'Preencher depois' }
    ];
    const defaultValue = { choice: 'uponServicesCompleted', date: null, other: '', instalmentsType: 'amount', rows: [] };
    const [value, setValue] = useState(defaultValue);
    const defaultRow = {
        type: 'amount',
        amount: 0,
        percent: 0,
        time: 'date',
        date: null,
        month: null,
        other: ''
    };

    useEffect(() => {
        setValue(initialValue ? {...defaultValue, ...initialValue} : defaultValue);
    }, [initialValue]);

    useEffect(() => {
        if(props.onChange) props.onChange(props.question.name, value);
    }, [value]);

    const handleChangeChoice = (newValue) => {
        setValue(prevState => {
            let rows = [];
            if(newValue === 'instalments' && prevState.rows.length === 0) rows = [{...defaultRow, id: uuidv4()}];
            return ({...prevState, choice: newValue, rows});
        });
    };

    const handleChangeKey = (key, newValue) => {
        setValue(prevState => {
            if(key === 'date') newValue = newValue.toDate();
            if(key === 'instalmentsType') prevState.rows = prevState.rows.map(row => ({...row, amount: 0, percent: 0}));
            return ({...prevState, [key]: newValue});
        });
    };
    
    const handleChange = (rowIndex, key, newValue) => {
        if(key === 'date' || key === 'month') newValue = newValue.toDate();
        setValue(prevState => ({
            ...prevState,
            rows: [...prevState.rows.slice(0, rowIndex), {...prevState.rows[rowIndex], [key]: newValue}, ...prevState.rows.slice(rowIndex + 1)]
        }));
    };
    const handleDeleteRowButtonClick = (rowIndex) => {
        setValue(prevState => ({
            ...prevState,
            rows: [...prevState.rows.slice(0, rowIndex), ...prevState.rows.slice(rowIndex + 1)]
        }));
    };

    const handleAddRowButtonClick = () => {
        setValue(prevState => ({
            ...prevState,
            rows: [...prevState.rows, {...defaultRow, id: uuidv4()}]
        }));
    };

    const handleScheduleRowDragEnd = (data) => {
        setValue(prevState => ({
            ...prevState,
            rows: update(prevState.rows, {
                $splice: [
                    [data.source.index, 1],
                    [data.destination.index, 0, prevState.rows[data.source.index]],
                ],
            }),
        }));
    };
    
    return (
        <Box
            onBlur={props.onBlur}
            onFocus={props.onFocus}
        >
            <RadioGroup
                value={value.choice}
                onChange={(e) => handleChangeChoice(e.target.value)}
            >
                <Grid container spacing={1} alignItems="center">
                    {
                        questionOptions.map((option, optionIndex) => (
                            <Grid key={optionIndex}>
                                <FormControlLabel
                                    value={option.choice}
                                    control={<Radio />}
                                    label={option.label}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            </RadioGroup>
            {
                value.choice === 'date' &&
                <Box mt={1}>
                    <Box mb={1}>
                        <Divider />
                    </Box>
                    <DatePicker
                        format="DD/MM/YYYY" autoOk
                        value={moment(value.date)}
                        onChange={(date) => handleChangeKey('date', date)}
                        invalidDateMessage="Formato de data inválido"
                        renderInput={(props) => <TextField placeholder="Data" {...props} /> }
                    />
                </Box>
            }
            {
                value.choice === 'other' &&
                <Box mt={1}>
                    <Box mb={1}>
                        <Divider />
                    </Box>
                    <TextField
                        variant="standard"
                        fullWidth
                        multiline
                        placeholder="Sua resposta"
                        value={value.other}
                        onChange={(e) => handleChangeKey('other', e.target.value)} />
                </Box>
            }
            {
                value.choice === 'instalments' &&
                <Box mt={1}>
                    <Box mb={1}>
                        <Divider />
                    </Box>
                    <RadioGroup
                        value={value.instalmentsType}
                        onChange={(e) => handleChangeKey('instalmentsType', e.target.value)}
                    >
                        <Grid container spacing={1} alignItems="center">
                            {
                                [
                                    {choice: 'amount', label: 'Especificar valores'},
                                    {choice: 'percent', label: 'Especificar percentuais'}
                                ].map((option, optionIndex) => (
                                    <Grid key={optionIndex}>
                                        <FormControlLabel
                                            value={option.choice}
                                            control={<Radio />}
                                            label={option.label}
                                        />
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </RadioGroup>

                    <DragDropContext onDragEnd={handleScheduleRowDragEnd}>
                        <Droppable droppableId={'form-payment-schedule-question-droppable-rows-container'}>
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>

                                    {
                                        value.rows.map((row, rowIndex) => {
                                            return (
                                                <Draggable
                                                    key={row.id.toString()}
                                                    draggableId={row.id.toString()}
                                                    index={rowIndex}
                                                    isDragDisabled={false}
                                                >
                                                    {(provided) => (
                                                        <div ref={provided.innerRef} {...provided.draggableProps}>

                                                            <Box>
                                                                <Grid container spacing={1} alignItems="center"
                                                                    sx={{
                                                                        borderRadius: '8px',
                                                                        '&:hover': {
                                                                            backgroundColor: theme => theme.palette.action.hover
                                                                        }
                                                                    }}
                                                                >
                                                                    <Grid>
                                                                        <IconButton size="small" disableRipple {...provided.dragHandleProps}>
                                                                            <DragHandleIcon />
                                                                        </IconButton>
                                                                    </Grid>
                                                                    <Grid xs container spacing={1} alignItems="center">
                                                                        <Grid xs={12} sm={3} md={2} container justifyContent="center">
                                                                            <Grid>
                                                                                <Typography variant="body1">{rowIndex + 1}ª parcela</Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid xs={12} sm={3} md={2}>
                                                                            {
                                                                                value.instalmentsType === 'percent'
                                                                                ?
                                                                                <Grid container spacing={1} alignItems="center">
                                                                                    <Grid xs>
                                                                                        <CurrencyInput 
                                                                                            decimalPlaces={1}
                                                                                            currencySymbol="" decimalCharacter="," digitGroupSeparator="."
                                                                                            textAlign="right"
                                                                                            placeholder="Percentual"
                                                                                            value={row.amount}
                                                                                            onChange={(e, newValue) => handleChange(rowIndex, 'amount', newValue)}
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid>
                                                                                        <Typography variant="body1">%</Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                :
                                                                                <CurrencyInput
                                                                                    placeholder="Valor"
                                                                                    value={row.amount}
                                                                                    onChange={(e, newValue) => handleChange(rowIndex, 'amount', newValue)}
                                                                                />
                                                                            }
                                                                        </Grid>
                                                                        <Grid xs={12} sm={3} md={4}>
                                                                            <Select
                                                                                options={[
                                                                                    {value: 'uponAgreementExecuted', label: 'Na assinatura do contrato'},
                                                                                    {value: 'uponServicesCompleted', label: 'Na conclusão dos serviços'},
                                                                                    {value: 'date', label: 'Especificar uma data'},
                                                                                    {value: 'month', label: 'Especificar um mês'},
                                                                                    {value: 'tbd', label: 'A combinar'},
                                                                                    {value: 'other', label: 'Outro'},
                                                                                    {value: 'completeLater', label: 'Preencher depois'},
                                                                                ]}
                                                                                value={row.time}
                                                                                onChange={(e) => handleChange(rowIndex, 'time', e.target.value)}
                                                                            />
                                                                        </Grid>
                                                                        {
                                                                            row.time === 'date' &&
                                                                            <Grid xs={12} sm={3} md={2}>
                                                                                <DatePicker
                                                                                    format="DD/MM/YY" autoOk
                                                                                    value={moment(row.date)}
                                                                                    onChange={(date) => handleChange(rowIndex, 'date', date)}
                                                                                    invalidDateMessage="Formato de data inválido"
                                                                                    renderInput={(props) => <TextField placeholder="Data" {...props} /> }
                                                                                />
                                                                            </Grid>
                                                                        }
                                                                        {
                                                                            row.time === 'month' &&
                                                                            <Grid xs={12} sm={3} md={2}>
                                                                                <DatePicker
                                                                                    openTo="year"
                                                                                    views={['year', 'month']}
                                                                                    autoOk
                                                                                    value={moment(row.month)}
                                                                                    onChange={(date) => handleChange(rowIndex, 'month', date)}
                                                                                    invalidDateMessage="Formato de data inválido"
                                                                                    renderInput={(props) => <TextField placeholder="Mês" {...props} /> }
                                                                                />
                                                                            </Grid>
                                                                        }
                                                                        {
                                                                            row.time === 'other' &&
                                                                            <Grid xs={12} sm={3} md={4}>
                                                                                <Autocomplete
                                                                                    freeSolo
                                                                                    placeholder="Escolha ou digite sua resposta"
                                                                                    options={[
                                                                                        'No início da fase de preparação',
                                                                                        'Na conclusão da fase de preparação',
                                                                                        'No início da fase de pré-produção',
                                                                                        'Na conclusão da fase de pré-produção',
                                                                                        'No início da fase de gravação',
                                                                                        'Na conclusão da fase de gravação',
                                                                                        'No início da fase de pós-produção',
                                                                                        'Na conclusão da fase de pós-produção',
                                                                                    ]}
                                                                                    getOptionLabel={(option) => option}
                                                                                    value={row.other}
                                                                                    onChange={(e, newValue) => handleChange(rowIndex, 'other', newValue)}
                                                                                />
                                                                            </Grid>
                                                                        }
                                                                    </Grid>
                                                                    <Grid>
                                                                        <IconButton size="small" onClick={() => handleDeleteRowButtonClick(rowIndex)}><ClearIcon /></IconButton>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>

                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })
                                    }
                                    
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>

                    <Grid container justifyContent="flex-end">
                        <Grid>
                            <Button startIcon={<AddIcon />} onClick={handleAddRowButtonClick}>Adicionar parcela</Button>
                        </Grid>
                    </Grid>
                </Box>
            }
        </Box>
    );
}

export default PaymentScheduleQuestion;