import { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Button, Collapse, Container, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CreateIcon from '@mui/icons-material/Create';
import ChangeLogoButton from '../../components/ChangeLogoButton';
import Dialog from '../../../../../components/Dialog/Dialog';
import LoaderEllipsis from '../../../../../components/LoaderEllipsis';
import ResponsiveButton from '../../../../../components/ResponsiveButton/ResponsiveButton';
import Select from '../../../../../components/Select';
import { BRAZILIAN_STATES, ERROR_MESSAGE_UNKNOWN, SERVER_PATH } from '../../../../../utils/constants';
import { useAppCtxAPI, useAppCtxActiveUser } from '../../../../../context/SystemContext';
import { useClientCtxActiveClient } from '../../../../../context/ClientContext';
import { useOperatorCtxAPI, useOperatorCtxProjects, useOperatorCtxRefs } from '../../../context/OperatorContext';
import { useOperatorCompaniesCtxAPI, useOperatorCompaniesCtxChangeCompanyView } from '../../../../../context/OperatorCompaniesContext';
import { useOperatorFoldersCtxAPI } from '../../../../../context/OperatorFoldersContext';

const DataDrawer = ({data}) => {
    const { setFloatingNotification } = data;
    const { toast } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const activeClient = useClientCtxActiveClient();
    const { addProject, updateProject } = useOperatorCtxAPI();
    const projects = useOperatorCtxProjects();
    const { shouldEmitUpdatedProjects, updatedProject } = useOperatorCtxRefs();
    const { addVisibleCompany, hideChangeCompanyView, setChangeCompanyViewFoundCompany, updateVisibleCompany } = useOperatorCompaniesCtxAPI();
    const { changeCompanyViewOpen, changeCompanyViewClickedCompany, changeCompanyViewFoundCompany: selectedProject } = useOperatorCompaniesCtxChangeCompanyView();
    const { showFolderSignatureView } = useOperatorFoldersCtxAPI();

    const [saving, setSaving] = useState(false);

    const brazilianStatesSelectOptions = BRAZILIAN_STATES.map(i => ({value: i, label: i}));

    const defaultCompanyStateInfo = {
        type: 'PJ',
        fullName: '',
        taxpayerNumber: '',
        address: '',
        city: '',
        state: '',
        postCode: '',
        representative: '',
        representativeEmail: '',
        representativePosition: '',
        representativeTaxpayerNumber: '',
        jurisdiction: ''
    };
    const defaultCompanyState = {
        info: defaultCompanyStateInfo,
        name: '',
        logoAlignment: 'right',
        folder: '',
        shortName: '',
        minimumUserLevel: 'all',
        transferFilesToGoogleDriveFolderById: ''
    };
    const [companyState, set_companyState] = useState(defaultCompanyState);
    
    useEffect(() => {
        if(!selectedProject){
            clearForm();
        } else {
            let currentCompanyStateInfo = {...defaultCompanyStateInfo, ...selectedProject.info};
            // let info = selectedProject.info;
            // if(info){
            //     currentCompanyStateInfo.city = info.city || '';
            //     currentCompanyStateInfo.state = info.state || '';
            //     set_type(info.type || '');
            //     set_fullName(info.fullName);
            //     set_taxpayerNumber(info.taxpayerNumber);
            //     set_representative(info.representative);
            //     set_representativeTaxpayerNumber(info.representativeTaxpayerNumber);
            //     set_representativePosition(info.representativePosition);
            //     set_representativeEmail(info.representativeEmail);
            //     set_ancine(info.ancine);
            //     set_address(info.address);
            //     set_postCode(info.postCode);
            //     set_jurisdiction(info.jurisdiction);
            //     set_comments(info.comments);
            // } else {
            //     clearInfo();
            // }

            // let signers = [];
            
            set_companyState(prevState => ({
                ...prevState,
                folder: selectedProject.folder || '',
                info: currentCompanyStateInfo,
                name: selectedProject.name || '',
                logoAlignment: selectedProject.logoAlignment || 'right',
                minimumUserLevel: selectedProject.minimumUserLevel || 'all',
                shortName: selectedProject.shortName || '',
                transferFilesToGoogleDriveFolderById: selectedProject.transferFilesToGoogleDriveFolderById || ''
            }));
        
        }
    }, [selectedProject]);

    useEffect(() => {
        if(projects && !shouldEmitUpdatedProjects.current && updatedProject.current && selectedProject && updatedProject.current._id === selectedProject._id){
            setChangeCompanyViewFoundCompany(updatedProject.current);
        }
    }, [projects]);

    const handleChangeSignatureButtonPress = () => {
        showFolderSignatureView(selectedProject);
    };

    const setCompanyStateField = (field, newValue) => {
        set_companyState(prevState => ({...prevState, [field]: newValue}));
    };
    
    const setCompanyStateInfo = (field, newValue) => {
        set_companyState(prevState => ({...prevState, info: {...prevState.info, [field]: newValue}}));
    };

    const handleSubmit = async () => {
        setSaving(true);
        
        const clientInfo = {
            ...companyState.info
        };

        let clientData = {
            clientId: activeClient._id,
            name: companyState.name,
            shortName: companyState.shortName,
            areas: [],
            folder: companyState.folder,
            info: clientInfo,
            logoAlignment: companyState.logoAlignment,
            signers: companyState.signers,
            currentClient: selectedProject,
            transferFilesToGoogleDriveFolderById: companyState.transferFilesToGoogleDriveFolderById
        }
        if(selectedProject){
            clientData.info = {...selectedProject.info, ...clientInfo};
        }

        if(companyState.minimumUserLevel !== 'all') clientData.minimumUserLevel = companyState.minimumUserLevel;

        await axios.post(SERVER_PATH + '/data/operator/folders/clients/save', clientData)
        .then(res => {
            updatedProject.current = res.data;
            if(selectedProject){
                updateVisibleCompany(res.data);
                updateProject(res.data);
            } else {
                addVisibleCompany(res.data);
                addProject(res.data);
            }
            setChangeCompanyViewFoundCompany(res.data);
            toast('As informações foram salvas.');
            handleClose();
        })
        .catch(err => {
            setFloatingNotification({message: err?.response?.data || ERROR_MESSAGE_UNKNOWN, severity: 'error'});
        });
        setSaving(false);
    }
    const clearForm = () => {
        set_companyState(defaultCompanyState);
    };

    const handleClose = () => {
        hideChangeCompanyView();
    };

    return (
        <Dialog
            buttons={[
                (activeClient && activeClient.modules.includes('documents')) &&
                <ResponsiveButton
                    variant="outlined" color="primary" size="small"
                    startIcon={<CreateIcon />}
                    onClick={handleChangeSignatureButtonPress}
                    label="Signatários"
                />
            ]}
            maxWidth="lg"
            onClose={handleClose}
            onSaveClick={handleSubmit}
            open={changeCompanyViewOpen}
            saveButtonDisabled={!companyState.name}
            saving={saving}
            title={!!changeCompanyViewClickedCompany && !!selectedProject ? selectedProject.name : !changeCompanyViewClickedCompany && !selectedProject ? 'Nova empresa' : ''}
        >

            <Container maxWidth="sm">
                <Collapse in={!!changeCompanyViewClickedCompany && !selectedProject}>
                    <LoaderEllipsis primary />
                </Collapse>
                <Collapse in={(!changeCompanyViewClickedCompany && !selectedProject) || (!!changeCompanyViewClickedCompany && !!selectedProject)}>
                    <Box>
                        {
                            selectedProject &&
                            <Box mb={3}>
                                <Box mb={1}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs></Grid>
                                        <Grid item>
                                            <ChangeLogoButton selectedProject={selectedProject} setSelectedProject={setChangeCompanyViewFoundCompany} />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        }

                        <Box mb={2}>
                            <Box mb={1}>
                                <Typography variant="h5">Dados gerais</Typography>
                            </Box>
                            <Box mb={1}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={8}>
                                        <TextField
                                            variant="standard"
                                            label="Nome da empresa"
                                            fullWidth
                                            required
                                            value={companyState.name}
                                            onChange={(e) => setCompanyStateField('name', e.target.value)} />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Select
                                            label="Tipo" value={companyState.info.type} onChange={(e) => setCompanyStateInfo('type', e.target.value)}
                                            options={[{value: 'PF', label: 'Pessoa física'}, {value: 'PJ', label: 'Pessoa jurídica'}]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <TextField
                                            variant="standard"
                                            label={companyState.info.type === 'PF' ? 'Nome completo' : 'Razão social'}
                                            fullWidth
                                            value={companyState.info.fullName}
                                            onChange={(e) => setCompanyStateInfo('fullName', e.target.value)} />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            variant="standard"
                                            label={companyState.info.type === 'PF' ? 'CPF' : 'CNPJ'}
                                            fullWidth
                                            value={companyState.info.taxpayerNumber}
                                            onChange={(e) => setCompanyStateInfo('taxpayerNumber', e.target.value)} />
                                    </Grid>
                                    {
                                        companyState.info.type === 'PJ' &&
                                        <>
                                            <Grid item xs={12} sm={8} md={6}>
                                                <TextField
                                                    variant="standard"
                                                    label="Representante legal"
                                                    fullWidth
                                                    value={companyState.info.representative}
                                                    onChange={(e) => setCompanyStateInfo('representative', e.target.value)} />
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={2}>
                                                <TextField
                                                    variant="standard"
                                                    label="CPF"
                                                    fullWidth
                                                    value={companyState.info.representativeTaxpayerNumber}
                                                    onChange={(e) => setCompanyStateInfo('representativeTaxpayerNumber', e.target.value)} />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={2}>
                                                <TextField
                                                    variant="standard"
                                                    label="Cargo"
                                                    fullWidth
                                                    value={companyState.info.representativePosition}
                                                    onChange={(e) => setCompanyStateInfo('representativePosition', e.target.value)} />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={2}>
                                                <TextField
                                                    variant="standard"
                                                    label="E-mail"
                                                    fullWidth
                                                    value={companyState.info.representativeEmail}
                                                    onChange={(e) => setCompanyStateInfo('representativeEmail', e.target.value)} />
                                            </Grid>
                                        </>
                                    }
                                    <Grid item xs={12} sm={6} md={5}>
                                        <TextField
                                            variant="standard"
                                            label="Endereço"
                                            fullWidth
                                            value={companyState.info.address}
                                            onChange={(e) => setCompanyStateInfo('address', e.target.value)} />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={2}>
                                        <TextField
                                            variant="standard"
                                            label="Cidade"
                                            fullWidth
                                            value={companyState.info.city}
                                            onChange={(e) => setCompanyStateInfo('city', e.target.value)} />
                                    </Grid>
                                    <Grid item xs={12} sm={2} md={1}>
                                        <Select
                                            label="UF" value={companyState.info.state} onChange={(e) => setCompanyStateInfo('state', e.target.value)}
                                            options={brazilianStatesSelectOptions}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2}>
                                        <TextField
                                            variant="standard"
                                            label="CEP"
                                            fullWidth
                                            value={companyState.info.postCode}
                                            onChange={(e) => setCompanyStateInfo('postCode', e.target.value)} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2}>
                                        <TextField
                                            variant="standard"
                                            label="Foro"
                                            fullWidth
                                            value={companyState.info.jurisdiction}
                                            onChange={(e) => setCompanyStateInfo('jurisdiction', e.target.value)} />
                                    </Grid>
                                    {
                                        selectedProject?.logo &&
                                        <Grid item xs={12} sm={6} md={2}>
                                            <Select
                                                label="Posição da logo"
                                                options={[{value: 'left', label: 'Esquerda'}, {value: 'center', label: 'Centro'}, {value: 'right', label: 'Direita'}]}
                                                value={companyState.logoAlignment} onChange={(e) => set_companyState(prevState => ({...prevState, logoAlignment: e.target.value}))}
                                            />
                                        </Grid>
                                    }
                                </Grid>
                            </Box>
                        </Box>
                        
                        {
                            activeUser && (activeUser.type >= 9 || ['owner'].includes(activeUser.role)) &&
                            <Box mb={2}>
                                <Box mb={1}>
                                    <Typography variant="h5">Configurações adicionais</Typography>
                                </Box>
                                <Box mb={1}>
                                    <Grid container spacing={1}>
                                        {/* <Grid item xs={12}>
                                            <TextField
                                                label="Registro na Ancine (se for o caso)" fullWidth
                                                value={ancine} onChange={(e) => set_ancine(e.target.value)}
                                            />
                                        </Grid> */}
                                        <Grid item xs={12} sm={6} container spacing={1} alignItems="flex-end" wrap="nowrap">
                                            <Grid item xs>
                                                <Select
                                                    label="Visibilidade"
                                                    options={[
                                                        {value: 'all', label: 'Todos os advogados'},
                                                        {value: 5, label: 'Apenas para os sócios quotistas'}
                                                    ]}
                                                    value={companyState.minimumUserLevel}
                                                    onChange={(e) => setCompanyStateField('minimumUserLevel', e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={6} container spacing={1} alignItems="flex-end" wrap="nowrap">
                                            <Grid item xs>
                                                <TextField
                                                    variant="standard"
                                                    label="URL amigável"
                                                    fullWidth
                                                    value={companyState.shortName}
                                                    onChange={(e) => setCompanyStateField('shortName', e.target.value)} />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                variant="standard"
                                                label="ID para transferência de arquivos para Google Drive"
                                                fullWidth
                                                value={companyState.transferFilesToGoogleDriveFolderById}
                                                onChange={(e) => setCompanyStateField('transferFilesToGoogleDriveFolderById', e.target.value)} />
                                        </Grid>

                                    </Grid>
                                </Box>
                            </Box>
                        }
                    </Box>
                </Collapse>
            </Container>

        </Dialog>
    );
}

export default DataDrawer;