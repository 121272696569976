import MenuItem from '@mui/material/MenuItem';
import Account from '../../components/Account';
import { useAppCtxAPI } from '../../context/SystemContext';
import { useClientCtxActiveClient } from '../../context/ClientContext';

const OperatorAccount = ({size}) => {
    const { handleNavigate } = useAppCtxAPI();
    const activeClient = useClientCtxActiveClient();
    
    return (
        <Account size={size}> 
            <MenuItem onClick={() => handleNavigate(`/${activeClient.shortName}/juridico/preferencias`)}>Preferências</MenuItem>
        </Account>
    );
};

export default OperatorAccount;