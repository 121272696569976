import { useRef, useEffect } from 'react';
import { Avatar } from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import MovieOutlinedIcon from '@mui/icons-material/MovieOutlined';
import moment from 'moment';

const classes = {
    icon: {
        backgroundColor: theme => theme.palette.grey[200],
        color: theme => theme.palette.grey[800]
    },
    notification: {
        backgroundColor: '#bbdefb',
        borderRadius: '8px',
        transition: 'background-color 2s ease-out'
    },
    notificationRead: {
        backgroundColor: 'transparent',
    }
};

function Notifications({
    isOpen, setOpen,
    notifications,
    popOverRef
}){
    const itemRefs = useRef({});

    function scrollToBottom(){
        if(itemRefs.current){
            let foundNotification = null;
            notifications.forEach(notification => {
                if(!notification.read){
                    if(foundNotification){
                        if(notification.createdAt < foundNotification.createdAt) foundNotification = notification;
                    } else {
                        foundNotification = notification;
                    }
                }
            });
            if(foundNotification){
                const element = itemRefs.current[foundNotification._id];
                if(element) element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }

    useEffect(() => {
        if(isOpen){
            scrollToBottom();
        }
    }, [isOpen]);

    function handleClose(){
        setOpen(false);
    }

    const iconsMap = {
        clearance: MovieOutlinedIcon,
        documents: DescriptionOutlinedIcon,
        message: MessageOutlinedIcon,
        task: AssignmentOutlinedIcon
    }

    return (
        <Popover
            PaperProps={{style: {maxWidth: 599}}}
            open={isOpen}
            onClose={handleClose}
            anchorEl={popOverRef.current}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            keepMounted
        >
            <Box p={2}>
                <Box mb={2}>
                    <Typography variant="h6">Notificações</Typography>
                </Box>
                {
                    notifications.length >= 1
                    ?
                    <List>
                        {
                            notifications
                            .sort((a, b) => a.createdAt > b.createdAt ? 1 : a.createdAt < b.createdAt ? -1 : 0)
                            .map((notification) => {
                                const Icon = iconsMap[notification.type];
                                return (
                                    <ListItem
                                        key={notification._id}
                                        ref={el => (itemRefs.current[notification._id] = el)}
                                        sx={[classes.notification, notification.read && classes.notificationRead]}
                                    >
                                        <ListItemAvatar>
                                            <Avatar sx={classes.icon}>
                                                <Icon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={notification.title || 'Notificação'}
                                            primaryTypographyProps={{variant: 'subtitle2'}}
                                            secondary={<Typography variant="body2">{notification.description} {moment(notification.createdAt).fromNow()}</Typography>}
                                        />
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                    :
                    <Box>
                        <Typography variant="body1">Você não tem notificações novas.</Typography>
                    </Box>
                }
            </Box>
        </Popover>
    );
}

export default Notifications;