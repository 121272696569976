import { createContext, useContext, useMemo, useReducer, useEffect } from 'react';
import { serverRequest } from '../utils/common';
import { FIRST_CLIENT_ID } from '../utils/constants';
import { useAppCtxActiveUser } from './SystemContext';
import { useClientCtxActiveClient } from './ClientContext';

const OperatorUsersCtxAPI = createContext();
const OperatorCtxChangeUserView = createContext();
const OperatorCtxUserTypeOptions = createContext();
const OperatorCtxVisibleUsers = createContext();

const defaultState = {
    changeUserViewOpen: false,
    changeUserViewClickedUser: null,
    changeUserViewFoundUser: null,
    changeUserViewUpdatedAt: null,
    isLoadingVisibleUsers: true,
    userTypeOptions: [],
    visibleUsers: [],
    visibleUsersUpdatedAt: null
};

const reducer = (state, action) => {
    const { payload, type } = action;
    
    switch (type) {
        case 'HIDE CHANGE USER VIEW':
            return { ...state, changeUserViewOpen: false, changeUserViewClickedUser: null };
        case 'SET CHANGE USER VIEW CLICKED USER':
            return { ...state, changeUserViewClickedUser: payload };
        case 'SET CHANGE USER VIEW FOUND USER':
            return { ...state, changeUserViewFoundUser: payload, changeUserViewUpdatedAt: new Date() };
        case 'SET STATE':
            return { ...state, [payload.key]: payload.value };
        case 'SET VISIBLE USERS':
            return { ...state, visibleUsers: payload, visibleUsersUpdatedAt: new Date() };
        case 'SET VISIBLE USERS LOADING':
            return { ...state, isLoadingVisibleUsers: payload };
        case 'SHOW ADD USER VIEW':
            return { ...state, changeUserViewOpen: true, changeUserViewClickedUser: null, changeUserViewFoundUser: null };
        case 'SHOW CHANGE USER VIEW':
            return { ...state, changeUserViewOpen: true, changeUserViewClickedUser: payload.clickedUser, changeUserViewFoundUser: null };
        case 'UPDATE VISIBLE USER':
            if(state.visibleUsers){
                const visibleUserIndex = state.visibleUsers.findIndex(i => i._id === payload._id);
                if(visibleUserIndex !== -1){
                    return {
                        ...state,
                        visibleUsers: [...state.visibleUsers.slice(0, visibleUserIndex), {...payload, updatedAt: new Date()}, ...state.visibleUsers.slice(visibleUserIndex + 1)],
                        visibleUsersUpdatedAt: new Date()
                    };
                }
            }
            return state;
        default: return state;
    }
    
};

const OperatorUsersProvider = ({children}) => {
    const activeUser = useAppCtxActiveUser();
    const activeClient = useClientCtxActiveClient();

    const [state, dispatch] = useReducer(reducer, {...defaultState});
    const api = useMemo(() => {

        const hideChangeUserView = (payload) => {
            dispatch({type: 'HIDE CHANGE USER VIEW', payload});
        };

        const setChangeUserViewClickedUser = (clickedUser) => {
            dispatch({type: 'SET CHANGE USER VIEW CLICKED USER', payload: clickedUser});
        };

        const setChangeUserViewFoundUser = (selectedUser) => {
            dispatch({type: 'SET CHANGE USER VIEW FOUND USER', payload: selectedUser});
        };

        const setState = (key, value) => {
            dispatch({ type: 'SET STATE', payload: { key, value } });
        };

        const setVisibleUsers = (payload) => {
            dispatch({type: 'SET VISIBLE USERS', payload});
        };

        const setVisibleUsersLoading = (payload) => {
            dispatch({type: 'SET VISIBLE USERS LOADING', payload});
        };
        
        const showAddUserView = (payload) => {
            dispatch({type: 'SHOW ADD USER VIEW', payload});
        };
        
        const showChangeUserView = (clickedUser) => {
            dispatch({type: 'SHOW CHANGE USER VIEW', payload: { clickedUser }});
        };

        const updateVisibleUser = (payload) => {
            dispatch({type: 'UPDATE VISIBLE USER', payload});
        };

        return {
            dispatch,
            hideChangeUserView,
            setChangeUserViewClickedUser,
            setChangeUserViewFoundUser,
            setState,
            setVisibleUsers,
            setVisibleUsersLoading,
            showAddUserView,
            showChangeUserView,
            updateVisibleUser
        };
    }, []);

    const fetchUser = async () => {
        try {
            const data = await serverRequest({path: '/data/users/one', method: 'post', data: { userId: state.changeUserViewClickedUser._id }})
            dispatch({ type: 'SET CHANGE USER VIEW FOUND USER', payload: data });
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if(state.changeUserViewClickedUser){
            fetchUser();
        } else {
            dispatch({ type: 'SET CHANGE USER VIEW FOUND USER', payload: null });
        }
    }, [state.changeUserViewClickedUser]);

    useEffect(() => {
        if(activeUser && activeClient){
            let currentUserTypeOptions = [];
            if(activeClient._id === FIRST_CLIENT_ID){
                currentUserTypeOptions = [
                    {value: 1, label: 'Cliente'},
                    {value: 2, label: 'Administrativo (leitura)'},
                    {value: 3, label: 'Administrativo'},
                    {value: 4, label: 'Advogado(a)'},
                    {value: 5, label: 'Sócio(a)'},
                    {value: 6, label: 'Sócio(a) Administrador(a)'}
                ];
            } else {
                currentUserTypeOptions = [
                    {value: 1, label: 'Solicitante'},
                    {value: 4, label: 'Jurídico'},
                    {value: 6, label: 'Jurídico (Gerente)'}
                ];
            }
            if(activeUser.type >= 9) currentUserTypeOptions.push({value: 9, label: 'Administrador do sistema'});
            dispatch({ type: 'SET STATE', payload: { key: 'userTypeOptions', value: currentUserTypeOptions } });
        }
    }, [activeUser, activeClient]);
    
    const operatorUsersCtxChangeUserViewValue = useMemo(() => ({
        changeUserViewOpen: state.changeUserViewOpen,
        changeUserViewClickedUser: state.changeUserViewClickedUser,
        changeUserViewFoundUser: state.changeUserViewFoundUser,
        changeUserViewUpdatedAt: state.changeUserViewUpdatedAt
    }), [state.changeUserViewOpen, state.changeUserViewUpdatedAt]);

    const operatorUsersCtxVisibleUsersValue = useMemo(() => ({
        isLoadingVisibleUsers: state.isLoadingVisibleUsers,
        visibleUsers: state.visibleUsers,
        visibleUsersUpdatedAt: state.visibleUsersUpdatedAt
    }), [state.isLoadingVisibleUsers, state.visibleUsersUpdatedAt]);

    return (
        <OperatorUsersCtxAPI.Provider value={api}>
        <OperatorCtxChangeUserView.Provider value={operatorUsersCtxChangeUserViewValue}>
        <OperatorCtxUserTypeOptions.Provider value={state.userTypeOptions}>
        <OperatorCtxVisibleUsers.Provider value={operatorUsersCtxVisibleUsersValue}>
            {children}
        </OperatorCtxVisibleUsers.Provider>
        </OperatorCtxUserTypeOptions.Provider>
        </OperatorCtxChangeUserView.Provider>
        </OperatorUsersCtxAPI.Provider>
    );
};

const useOperatorUsersCtxAPI = () => useContext(OperatorUsersCtxAPI);
const useOperatorCtxChangeUserView = () => useContext(OperatorCtxChangeUserView);
const useOperatorCtxUserTypeOptions = () => useContext(OperatorCtxUserTypeOptions);
const useOperatorCtxVisibleUsers = () => useContext(OperatorCtxVisibleUsers);

export {
    OperatorUsersProvider,
    useOperatorUsersCtxAPI,
    useOperatorCtxChangeUserView,
    useOperatorCtxUserTypeOptions,
    useOperatorCtxVisibleUsers
};