import { Button, IconButton, Tooltip } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const ResponsiveButton = (props) => {
    const theme = useTheme();
    const maxButtonSize = useMediaQuery(theme.breakpoints.up('md'));

    if(maxButtonSize){
        return (
            <Button {...props}>{props.label}</Button>
        );
    }
    return (
        <Tooltip title={props.label}>
            <span>
                <IconButton {...props} size="large">{props.startIcon}</IconButton>
            </span>
        </Tooltip>
    );
};

export default ResponsiveButton;