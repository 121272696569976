// import { memo } from 'react';
import { Grid, TableRow } from '@mui/material';

const classes = {
    tableRow: {
        transition: 'background-color .3s',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: theme => theme.palette.action.hover
        }
    },
};

const TinyTableBodyRow = ({
    onClick, onContextMenu, row,
    TableBodyCell
}) => {
// const TinyTableBodyRow = memo(({
//     onClick, onContextMenu, row,
//     TableBodyCell
// }) => {
    return (
        <TableRow
            sx={[classes.tableRow, ...(row.className || [])]}
            onClick={(e) => onClick(e, row)}
            onContextMenu={(e) => onContextMenu(e, row)}
            style={row.highlight ? {backgroundColor: row.highlightColor || '#fff9c4'} : {}}
        >
            {
                row.columns.map((column, columnIndex) => (
                    <TableBodyCell key={columnIndex}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs container justifyContent={column.justify || 'flex-start'}>
                                <Grid item>{column.content}</Grid>
                            </Grid>
                            {
                                column.endContent &&
                                <Grid item>{column.endContent}</Grid>
                            }
                        </Grid>
                    </TableBodyCell>
                ))
            }
        </TableRow>
    );
}
// }, (prevProps, nextProps) => {
//     if(nextProps.row.updatedAt !== prevProps.row.updatedAt || nextProps.updatedAt !== prevProps.updatedAt){
//         return false;
//     }
//     return true;
// });

export default TinyTableBodyRow;