import React, { useState } from 'react';
import { TextField } from '@mui/material';

const DocumentNameField = ({addDocumentForm, checkSubmitButtonDisabled}) => {
    const [documentName, setDocumentName] = useState('');
    const handleChange = (newValue) => {
        setDocumentName(newValue);
        addDocumentForm.current.documentName = newValue;

        checkSubmitButtonDisabled();
    };
    return (
        <TextField
            variant="standard"
            fullWidth
            label="Nome do documento"
            value={documentName}
            onChange={(e) => handleChange(e.target.value)} />
    );
};

export default DocumentNameField;