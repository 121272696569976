import { useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import axios from 'axios';
import moment from 'moment';
import DashboardBox from '../../../components/DashboardBox';
import { getUserImageById, getUserNameById } from '../../../utils/common';
import { SERVER_PATH } from '../../../utils/constants';
import { useAppCtxAPI, useAppCtxActiveUser, useAppCtxLoading } from '../../../context/SystemContext';
import { useSocket } from '../../../context/SocketContext';
import { useClientCtxActiveClient } from '../../../context/ClientContext';
import { useOperatorCtxAPI, useOperatorCtxChat, useOperatorCtxChatMessages, useOperatorCtxUsers } from '../context/OperatorContext';

const classes = {
    box: {
        width: '100%',
        display: 'flex'
    },
    message: {
        flex: 1,
        marginTop: '8px',
        padding: '8px',
        borderRadius: '8px',
        backgroundColor: theme => theme.palette.background.default
    }
};

function Chat(){
    const { setLoading } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const loading = useAppCtxLoading();
    const activeClient = useClientCtxActiveClient();
    const {createSocketConnection} = useSocket();
    const socket = createSocketConnection();
    const { setState: setOperatorCtxState, addChatMessage, deleteChatMessage, updateChatMessage } = useOperatorCtxAPI();
    const { newChatMessageEventName } = useOperatorCtxChat();
    const messages = useOperatorCtxChatMessages();
    const { users } = useOperatorCtxUsers();

    useEffect(() => {
        if(messages.length >= 1){
            const chatBox = document.getElementById('chat-box');
            if(chatBox) chatBox.scrollTop = chatBox.scrollHeight;
        }
    }, [messages, users]);

    const markMessagesAsRead = () => {
        let unreadMessages = [];
        messages.forEach((message, index) => {
            let readBy = message.readBy || [];
            if(!readBy.includes(activeUser._id)){
                unreadMessages.push(message._id);
                readBy.push(activeUser._id);
                updateChatMessage({ _id: message._id, readBy });
                setOperatorCtxState('numberOfUnreadMessages', 0);
                axios.post(SERVER_PATH + `/data/messages/mark-read`, { unreadMessages })
                .then(res => {
                    //
                })
                .catch(err => {
                    //
                });
            }
        });
        
    };

    const handle_chatKeyPress = (e) => {
        if(e.key === 'Enter'){
            sendMessage(e.target.value, activeUser._id);
            e.target.value = '';
        }
    };
    const sendMessage = (messageBody, activeUserId) => {
        let newMessage = {
            timestamp: new Date(), user: activeUserId, clientId: activeClient._id,
            message: messageBody, readBy: [activeUserId]
        };
        addChatMessage(newMessage);
        socket.emit(newChatMessageEventName, {
            body: newMessage,
            senderId: socket.id,
            clientId: activeClient._id
        });
    };
    const deleteMessage = (message, index) => {
        setLoading(true);
        axios.post(SERVER_PATH + `/data/delete`, {collection: 'Messages', id: message._id})
        .then(res => {
            setLoading(false);
            deleteChatMessage(message._id);
        });
    };

    const ChatAvatar = ({userId, pl, pr}) => {
        return (
            <Box pt={1} pl={pl || 0} pr={pr || 0}>
                <Avatar style={{height: 32, width: 32}} alt={getUserNameById(users, userId)} src={getUserImageById(users, userId) ? `/uploads/avatars/${getUserImageById(users, userId)}` : undefined}>{getUserNameById(users, userId).substring(0, 1)}</Avatar>
            </Box>
        );
    };

    return (
        <Box
            onClick={markMessagesAsRead}
        >
            <DashboardBox p="0" id="chat-box" style={{height: 208}}>
                {
                    !users
                    ? <Skeleton variant="rectangular" animation="wave" height="100%" />
                    :
                    <Box p={1}>
                        {
                            messages.sort((a, b) => (a.timestamp > b.timestamp) ? 1 : ((b.timestamp > a.timestamp) ? -1 : 0))
                            .map((message, index) => (
                                <Box
                                    key={index} sx={classes.box}
                                >
                                    {
                                        message.user !== activeUser?._id &&
                                        <ChatAvatar userId={message.user} pr={1} />
                                    }
                                    <Box sx={classes.message}>
                                        <Box mb={1}>
                                            <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                                                <Grid item xs={4}>
                                                    <Chip color="primary" size="small" label={getUserNameById(users, message.user)} />
                                                </Grid>
                                                <Grid item xs container justifyContent="flex-end">
                                                    {
                                                        (!message.readBy || !message.readBy.includes(activeUser?._id)) &&
                                                        <Typography component="span" variant="body2" color="error" style={{fontSize: 10}}>Não lida.&nbsp;</Typography>
                                                    }
                                                    <Typography component="span" color="textPrimary" style={{fontSize: 10}}>{`${moment(message.timestamp).format('L')} | ${moment(message.timestamp).fromNow()}`}</Typography>
                                                </Grid>
                                                {
                                                    (activeUser?.type >= 6 && message._id) &&
                                                    <Grid item>
                                                        <IconButton disabled={loading} size="small" onClick={() => deleteMessage(message, index)}><ClearIcon /></IconButton>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Box>
                                        <Typography variant="body2" color="textPrimary">{message.message}</Typography>
                                    </Box>
                                    {
                                        message.user === activeUser?._id &&
                                        <ChatAvatar userId={message.user} pl={1} />
                                    }
                                </Box>
                            ))
                        }
                    </Box>
                }
            </DashboardBox>
            <TextField
                variant="standard"
                disabled={loading}
                fullWidth
                placeholder="Pressione enter para enviar"
                onKeyPress={handle_chatKeyPress} />
        </Box>
    );
}

export default Chat;