import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useAppCtxAPI, useAppCtxActiveUser } from '../../context/SystemContext';
import { serverRequest } from '../../utils/common';

const classes = {
    root: {
        backgroundColor: theme => theme.palette.background.default,
        height: '100%',
        padding: '24px'
    },
    button: {
        color: theme => theme.palette.text.primary,
        cursor: 'pointer',
        padding: '12px 24px',
        textAlign: 'center',
        textDecoration: 'none',
        textTransform: 'uppercase',
        '&:hover, &.active': {
            backgroundColor: theme => theme.palette.background.dark,
            color: '#f5f5f5'
        }
    }
};

function Dashboards(){
    const { handleNavigate, setLoadingSystem, updateActiveUser } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const [workspaces, setWorkspaces] = useState([]);
    const clientManagementID = 'CLIENT MANAGEMENT';

    useEffect(() => {
        setLoadingSystem(false);
    }, []);

    useEffect(() => {
        if(activeUser){
            setWorkspaces(activeUser.clientOptions || []);
        }
    }, [activeUser]);

    const handleClientClick = async (clientId) => {
        setLoadingSystem(true);
        if(clientId === clientManagementID){
            try {
                const data = await serverRequest({path: '/auth/management'});
                if(data.allowClientManagement){
                    handleNavigate('/admin/clientes');
                }
            } catch (error) {
                
            }
            setLoadingSystem(false);
        } else {
            try {
                const data = await serverRequest({path: '/auth/client', method: 'post', data: { clientId }});
                clientId = data.clientId;
                const userType = data.type;
                if(clientId){
                    updateActiveUser({
                        selectedClient: clientId,
                        type: userType,
                        workspaceShortName: data.workspaceShortName
                    });
                    
                    const path = `/${data.workspaceShortName}/${userType >= 2 ? 'juridico' : 'pastas'}`;
                    
                    setTimeout(() => {
                        handleNavigate(path);
                    }, 2000);
                }
            } catch (error) {
                setLoadingSystem(false);
            }
        }
    };
    
    return (
        <Box sx={classes.root}>
            <Container maxWidth="xs">
                <Box style={{textAlign: 'center'}} mb={2}>
                    <img src={'/SOLIDA-500.png'} style={{height: 80}} alt="SOLIDA" />
                </Box>
                <Grid container spacing={1} justifyContent="center">
                    {
                        activeUser.allowClientManagement &&
                        <Grid item xs={12}>
                            <Paper sx={classes.button} onClick={() => handleClientClick(clientManagementID)}>
                                <Grid container spacing={1}>
                                    <Grid item></Grid>
                                    <Grid item xs>
                                        <Typography variant="body2">Administrar clientes</Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    }
                    {
                        workspaces
                        .sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0)
                        .map((client) => (
                            <Grid key={client._id} item xs={12}>
                                <Paper
                                    sx={classes.button}
                                    onClick={() => handleClientClick(client._id)}
                                >
                                    <Typography variant="body2">{client.name}</Typography>
                                </Paper>
                            </Grid>
                        ))
                    }
                </Grid>
            </Container>
        </Box>
    );
}

export default Dashboards;