import { useEffect, useMemo, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import { Box, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ChangeCompanyView from './components/DataDrawer';
import ChangeProjectSignersWindow from '../../../../components/ChangeProjectSignersWindow';
import Table2 from '../../../../components/Table2/Table';
import Table2CustomTopToolbar from '../../../../components/Table2/components/Table2CustomTopToolbar';
import ViewBox from '../../../../components/ViewBox';
import { useOperatorCtxCompanies } from '../../context/OperatorContext';
import { useOperatorCompaniesCtxAPI, useOperatorCompaniesCtxVisibleCompanies } from '../../../../context/OperatorCompaniesContext';
import { sortByKey } from '../../../../utils/filters';

const RowChangeAction = ({row}) => {
    const { showChangeCompanyView } = useOperatorCompaniesCtxAPI();

    const handleClick = () => {
        const selectedUser = row.original;
        showChangeCompanyView(selectedUser);
    };

    return (
        <IconButton size="small" onClick={handleClick}>
            <EditIcon />
        </IconButton>
    )
}

const Companies = () => {
    const companies = useOperatorCtxCompanies();
    const { setVisibleCompanies, setVisibleCompaniesLoading, showAddCompanyView } = useOperatorCompaniesCtxAPI();
    const { isLoadingVisibleCompanies, visibleCompanies } = useOperatorCompaniesCtxVisibleCompanies();
    const [floatingNotification, setFloatingNotification] = useState({ message: '', severity: '' });
    
    useEffect(() => {
        if(companies){
            setVisibleCompaniesLoading(false);
        }
    }, [companies]);

    useEffect(() => {
        if(companies){
            const currentCompanies = companies.sort(sortByKey('name')).map(currentCompany => {
                if(!currentCompany.info) currentCompany.info = {};
                return currentCompany;
            });
            setVisibleCompanies(currentCompanies);
        }
    }, [companies]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'name',
                enableClickToCopy: true,
                filterVariant: 'text',
                header: 'Empresa',
            },
            {
                id: 'companyType',
                accessorFn: (row) => {
                    let currentCompanyType = '';
                    if(row.info.type === 'PF'){
                        currentCompanyType = 'Pessoa física';
                    } else if(row.info.type === 'PJ'){
                        currentCompanyType = 'Pessoa jurídica';
                    }
                    return currentCompanyType;
                },
                filterVariant: 'text',
                header: 'Tipo',
                grow: false,
                size: 85,
            },
            {
                id: 'companyCity',
                accessorFn: (row) => {
                    let currentCompanyCity = '';
                    if(row.info.city) currentCompanyCity = row.info.city;
                    if(row.info.state) currentCompanyCity += `${row.info.city ? '/' : ''}${row.info.state}`;
                    return currentCompanyCity;
                },
                filterVariant: 'text',
                header: 'Cidade',
            },
        ],
        [],
    );

    const renderTopToolbar = ({ table }) => {
        
        const handleAddCompanyButtonClick = () => {
            showAddCompanyView();
        };
    
        return (
            <Table2CustomTopToolbar
                buttons={[
                    <Button variant="contained" minWidth={169.33} onClick={handleAddCompanyButtonClick} startIcon={<AddIcon />}>Nova empresa</Button>,
                ]}
                table={table}
            />
        );
    };

    const renderRowActions = ({ row }) => (
        <Box>
            <RowChangeAction row={row} />
        </Box>
    );

    return (
        <ViewBox message={floatingNotification} additionalSx={[{ height: '100%' }]}>
            
            <Table2
                columns={columns}
                data={visibleCompanies || []}
                enablePagination={false}
                enableRowVirtualization
                initialStateDensity="comfortable"
                isLoading={isLoadingVisibleCompanies}
                renderRowActions={renderRowActions}
                renderTopToolbar={renderTopToolbar}
            />

            <ChangeCompanyView
                data={{
                    setFloatingNotification
                }}
            />

            <ChangeProjectSignersWindow setFloatingNotification={setFloatingNotification} />

        </ViewBox>
    );
}

export default Companies;