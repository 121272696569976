import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DescriptionIcon from '@mui/icons-material/Description';
import { formEffectTypes } from '../../../utils/constants';
import { useDocumentEditor } from '../../../pages/Operator/Docs/Templates/DocumentEditor/context/DocumentEditorContext';

const displayOptions = [
    {value: 'singleLine', label: 'Mostrar em texto corrido'},
    {value: 'lowerAlphaList', label: 'Mostrar em lista'},
];

const ListOption = ({label, onClick}) => {
    return (
        <Box>
            <Divider />
            <Box
                sx={{
                    '&:hover': {
                        backgroundColor: theme => theme.palette.action.hover
                    }
                }}
                mb={0.5} mt={0.5}
            >
                <Grid container alignItems="center" justifyContent="flex-end" spacing={1}>
                    <Grid xs>
                        <Typography variant="body2">{label}</Typography>
                    </Grid>
                    <Grid>
                        <IconButton size="small" onClick={onClick}><DescriptionIcon /></IconButton>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

const EditorEffect = (props) => {
    const { selectedEffect } = props;
    const { handle_effectRadioOutputButtonClick } = useDocumentEditor();
    const displayId = selectedEffect.display;
    const displayOption = displayOptions.find(displayOption => displayOption.value === displayId);

    const handleListOptionClick = (key) => {
        handle_effectRadioOutputButtonClick(selectedEffect.id, key);
    };

    return (
        <Box>
            <Box mb={2}>
                <Typography variant="h3">{selectedEffect.label || selectedEffect.question}</Typography>
            </Box>
            <Box mb={1}>
                <Typography variant="body1"><strong>Tipo:</strong> {formEffectTypes.find(formEffectType => formEffectType.value === selectedEffect.type).label}</Typography>
            </Box>
            <Box mb={1}>
                <Typography variant="body1"><strong>Pergunta:</strong> {selectedEffect.question}</Typography>
            </Box>
            {
                displayOption &&
                <Box mb={1}>
                    <Typography variant="body1">- {displayOption.label}</Typography>
                </Box>
            }
            <ListOption
                label="Diárias de pré-produção: sem limite"
                onClick={() => handleListOptionClick('preProductionSpecifyLimitNone')}
            />
            <ListOption
                label="Diárias de pré-produção: especificar limite, sem valor da diária extra"
                onClick={() => handleListOptionClick('preProductionSpecifyLimitSpecifyAdditionalServicesFeeNone')}
            />
            <ListOption
                label="Diárias de pré-produção: especificar limite e valor da diária extra"
                onClick={() => handleListOptionClick('preProductionSpecifyLimitSpecifyAdditionalServicesFee')}
            />
            <ListOption
                label="Diárias de filmagem: sem limite"
                onClick={() => handleListOptionClick('principalPhotographySpecifyLimitNone')}
            />
            <ListOption
                label="Diárias de filmagem: especificar limite, sem valor da diária extra"
                onClick={() => handleListOptionClick('principalPhotographySpecifyLimitSpecifyAdditionalServicesFeeNone')}
            />
            <ListOption
                label="Diárias de filmagem: especificar limite e valor da diária extra"
                onClick={() => handleListOptionClick('principalPhotographySpecifyLimitSpecifyAdditionalServicesFee')}
            />
            <ListOption
                label="Diárias de pós-produção: sem limite"
                onClick={() => handleListOptionClick('postProductionSpecifyLimitNone')}
            />
            <ListOption
                label="Diárias de pós-produção: especificar limite, sem valor da diária extra"
                onClick={() => handleListOptionClick('postProductionSpecifyLimitSpecifyAdditionalServicesFeeNone')}
            />
            <ListOption
                label="Diárias de pós-produção: especificar limite e valor da diária extra"
                onClick={() => handleListOptionClick('postProductionSpecifyLimitSpecifyAdditionalServicesFee')}
            />
            <ListOption
                label="A combinar"
                onClick={() => handleListOptionClick('tbd')}
            />
            <ListOption
                label="Outro"
                onClick={() => handleListOptionClick('other')}
            />
        </Box>
    )
};

export default EditorEffect;