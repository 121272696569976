import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import update from 'immutability-helper';
import { v4 as uuidv4 } from 'uuid';

const MovablePropertyLeaseDetailsQuestion = (props) => {
    const { initialValue } = props;
    const questionOptions = [
        { choice: 'specify', label: 'Especificar bens locados' },
        { choice: 'attachList', label: 'Quero anexar uma lista detalhando os bens (tipos, modelos, quantidades e outras características) depois de receber o contrato' },
    ];
    const defaultRow = {
        description: '',
        quantity: 1
    };
    const defaultValue = { choice: 'specify', rows: [] };
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        const currentDefaultValue = {...defaultValue, rows: [{...defaultRow, id: uuidv4()}]};
        setValue(initialValue ? {...currentDefaultValue, ...initialValue} : currentDefaultValue);
    }, [initialValue]);

    useEffect(() => {
        if(props.onChange) props.onChange(props.question.name, value);
    }, [value]);

    const handleChange = (rowIndex, key, newValue) => {
        setValue(prevState => ({
            ...prevState,
            rows: [...prevState.rows.slice(0, rowIndex), {...prevState.rows[rowIndex], [key]: newValue}, ...prevState.rows.slice(rowIndex + 1)]
        }));
    };
    const handleDeleteRowButtonClick = (rowIndex) => {
        setValue(prevState => ({
            ...prevState,
            rows: [...prevState.rows.slice(0, rowIndex), ...prevState.rows.slice(rowIndex + 1)]
        }));
    };

    const handleAddRowButtonClick = () => {
        setValue(prevState => ({
            ...prevState,
            rows: [...prevState.rows, {...defaultRow, id: uuidv4()}]
        }));
    };

    const handleRowDragEnd = (data) => {
        setValue(prevState => ({
            ...prevState,
            rows: update(prevState.rows, {
                $splice: [
                    [data.source.index, 1],
                    [data.destination.index, 0, prevState.rows[data.source.index]],
                ],
            }),
        }));
    };
    
    return (
        <Box
            onBlur={props.onBlur}
            onFocus={props.onFocus}
        >
            <RadioGroup
                value={value.choice}
                onChange={(e) => setValue(prevState => ({...prevState, choice: e.target.value}))}
            >
                <Grid container spacing={1} alignItems="center">
                    {
                        questionOptions.map((option, optionIndex) => (
                            <Grid key={optionIndex}>
                                <FormControlLabel
                                    value={option.choice}
                                    control={<Radio />}
                                    label={option.label}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            </RadioGroup>
            {
                value.choice === 'specify' &&
                <Box mt={1}>
                    <Box mb={1}>
                        <Divider />
                    </Box>
                    <Box mb={1}>
                        <Typography variant="body2">Cada linha deve conter a descrição dos bens (tipo, modelo e outras características) e a respectiva quantidade</Typography>
                    </Box>
                    <DragDropContext onDragEnd={handleRowDragEnd}>
                        <Droppable droppableId={`form-movable-property-lease-details-question-droppable-rows-container`}>
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>

                                    {
                                        value.rows.map((row, rowIndex) => {
                                            return (
                                                <Draggable
                                                    key={row.id.toString()}
                                                    draggableId={row.id.toString()}
                                                    index={rowIndex}
                                                    isDragDisabled={false}
                                                >
                                                    {(provided) => (
                                                        <div ref={provided.innerRef} {...provided.draggableProps}>

                                                            <Box>
                                                                <Grid container spacing={1} alignItems="flex-end"
                                                                    sx={{
                                                                        borderRadius: '8px',
                                                                        '&:hover': {
                                                                            backgroundColor: theme => theme.palette.action.hover
                                                                        },
                                                                    }}
                                                                >
                                                                    <Grid>
                                                                        <IconButton size="small" disableRipple {...provided.dragHandleProps}>
                                                                            <DragHandleIcon />
                                                                        </IconButton>
                                                                    </Grid>
                                                                    <Grid xs container spacing={1} alignItems="center">
                                                                        <Grid xs>
                                                                            <TextField
                                                                                variant="standard"
                                                                                fullWidth
                                                                                placeholder="Descrição"
                                                                                value={row.description}
                                                                                onChange={(e) => handleChange(rowIndex, 'description', e.target.value)} />
                                                                        </Grid>
                                                                        <Grid xs={12} sm={3} md={1}>
                                                                            <TextField
                                                                                variant="standard"
                                                                                fullWidth
                                                                                placeholder="Quantidade"
                                                                                type="number"
                                                                                value={row.quantity}
                                                                                onChange={(e) => handleChange(rowIndex, 'quantity', e.target.value)} />
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid>
                                                                        <IconButton size="small" onClick={() => handleDeleteRowButtonClick(rowIndex)}><ClearIcon /></IconButton>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>

                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })
                                    }
                                    
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>

                    <Grid container justifyContent="flex-end">
                        <Grid>
                            <Button startIcon={<AddIcon />} onClick={handleAddRowButtonClick}>Adicionar bens</Button>
                        </Grid>
                    </Grid>
                </Box>
            }
        </Box>
    );
}

export default MovablePropertyLeaseDetailsQuestion;