import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

const CurrencyInput = (props) => {

    const formatCurrency = (number) => {
        return new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 }).format(number);
    };

    const getNewValue = (e) => {
        let newValue = e.target.value;
        if(newValue){
            newValue = parseFloat((newValue.replace('.', '').replace(',', '').replace(/\D/g, '')));
            e.target.value = formatCurrency(newValue);
        }
        return { event: e, newValue };
    };
    
    const handleChange = (e) => {
        const { event, newValue } = getNewValue(e);
        if(props.onChange) props.onChange(event, newValue / 100);
    };

    const handleBlur = (e) => {
        if(props.onBlur){
            const { event, newValue } = getNewValue(e);
            props.onBlur(event, newValue / 100);
        }
    };

    const moneyMask = (value) => {
        if(value){
            if(typeof value === 'string'){
                value = value.replace('.', '').replace(',', '').replace(/\D/g, '');
                value = parseFloat(value);
            }
            return formatCurrency(value);
        }
        return '';
    }      

    return (
        <TextField
            {...props}
            variant="standard"
            value={moneyMask(props.value)} onChange={handleChange}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                )
            }}
            onBlur={handleBlur}
        />
    )
};
// const CurrencyInput = (props) => (
//     <CurrencyTextField
//         currencySymbol="R$" decimalCharacter="," digitGroupSeparator="."
//         textAlign="left"
//         {...props}
//     />
// );

export default CurrencyInput;