import Typography from '@mui/material/Typography';

const list = {
    createEffect: ({ effectSettings, newEffectId, templateEffects }) => {
        let foundEffect;
        const newEffect = {
            id: newEffectId,
            type: effectSettings.type,
            typeId: effectSettings.typeId,
            typeSpecifics: effectSettings.typeSpecifics,
            question: effectSettings.question || effectSettings.label || '',
            label: `${effectSettings.label || effectSettings.question} - Lista (${effectSettings.typeSpecifics === 'key' ? 'Nome' : (effectSettings.typeSpecifics === 'value' ? 'Descrição' : 'Personalizado')})`
        };
        if(effectSettings.typeSpecifics !== 'custom'){
            foundEffect = templateEffects.find(templateEffect => templateEffect.type === effectSettings.type && templateEffect.typeId === effectSettings.typeId && templateEffect.typeSpecifics === effectSettings.typeSpecifics);
        } else {
            newEffect.options = [];
        }
        return { foundEffect, newEffect };
    },
    newEffectWindowHandleEffectTypeChange: ({listOptions}) => {
        const data = {};
        data.question = '';
        if(listOptions.length >= 1){
            data.typeId = listOptions[0].value;
        }
        data.typeSpecifics = 'key';
        return data;
    },
    setEffectOutput: ({ documentEditorValue, selectedEffect, selectedEffectOutput }) => {
        let index = selectedEffect.options.findIndex(option => option.option === selectedEffectOutput.optionIndex);
        if(index >= 0){
            selectedEffect.options[index] = {...selectedEffect.options[index], output: documentEditorValue};
        } else {
            if(documentEditorValue) selectedEffect.options = [...selectedEffect.options, {option: selectedEffectOutput.optionIndex, output: documentEditorValue}]
        }
        return selectedEffect;
    },
    getSelectedEffectOutput: ({ selectedEffect, selectedEffectOutput }) => {
        const selectedOption = selectedEffect.options.find(scannedOption => scannedOption.option === selectedEffectOutput.optionIndex);
        let newValue = selectedOption?.output || '';
        return newValue;
    },
    getOutputsContent: (data) => {
        let content = '';
        if(data){
            const { scannedEffect } = data;
            if(scannedEffect){
                if(scannedEffect.typeSpecifics === 'custom'){
                    scannedEffect.options.forEach((scannedEffectOption) => {
                        content += scannedEffectOption.output || '';
                    });
                }
            }
        }
        return content;
    },
    getFormEditorQuestion: (data) => {
        if(data){
            const { effect, question, activeClient } = data;
            const formEditorQuestion = {...question};
            const listId = formEditorQuestion.typeId;
            let currentList;
            const currentLists = activeClient.lists;
            if(currentLists) currentList = currentLists.find(list => list.shortName === listId || list._id == listId);
            if(currentList){
                currentList = currentList.list;
                formEditorQuestion.options = currentList.map(listItem => ({choice: listItem.key, label: listItem.key}));
            }
            return formEditorQuestion;
        }
        return false;
    },
    checkValidation: (data) => {
        let questionValid = false;
        if(data){
            questionValid = true;
            const { input, userNotAwareOfCorporationIdActivityError, userNotAwareOfMEIActivityError } = data;
            if(!input || userNotAwareOfCorporationIdActivityError || userNotAwareOfMEIActivityError) questionValid = false;
        }
        return questionValid;
    }
};

export {
    list
}