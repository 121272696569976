import { useCallback, useEffect } from 'react';
import { Collapse, List } from '@mui/material';
import PendingDocumentListItem from '../PendingDocumentListItem';
import { useAppCtxAPI, useAppCtxActiveUser } from '../../../../../../context/SystemContext';
import { useSocket } from '../../../../../../context/SocketContext';
import { useClientCtxActiveClient } from '../../../../../../context/ClientContext';
import { useDocumentsAPI, useDocumentsCtxLoadingPendingDocuments, useDocumentsCtxPendingDocuments, useDocumentsCtxPendingDocumentsReviewer } from '../../../../../../context/DocumentsContext';
import { useOperatorCtxOperators } from '../../../../context/OperatorContext';
import { getDocumentLastCommentIfHuman, getGroupNameById, getGroupsByFolderId, getUserNameById } from '../../../../../../utils/common';

const PendingDocumentsList = (props) => {
    const { filters, setFilters, saving, selectedClient, selectedProject, setNumberOfVisibleDocuments } = props;
    const { handleNavigate } = useAppCtxAPI();
    const activeUser = useAppCtxActiveUser();
    const { createSocketConnection } = useSocket();
    const socket = createSocketConnection();
    const activeClient = useClientCtxActiveClient();
    const {
        dispatch: dispatchDocuments,
        setActionMenuAnchorEl, showDocumentHistoryView, setReviewerMenuAnchorEl, setSelectedDocument, setSelectedDocuments,
        showCancelScheduledSendDocumentView, showSubmitDocumentView
    } = useDocumentsAPI(); 
    const loadingPendingDocuments = useDocumentsCtxLoadingPendingDocuments();
    const pendingDocuments = useDocumentsCtxPendingDocuments();
    const selectedReviewer = useDocumentsCtxPendingDocumentsReviewer()
    const operators = useOperatorCtxOperators();

    useEffect(() => {
        setFilters({
            companyId: selectedClient,
            folderId: selectedProject,
            operatorId: selectedReviewer
        });
    }, [selectedClient, selectedProject, selectedReviewer]);

    const filterUser = (sentBy) => {
        if(selectedReviewer === '~all;'){
            return true;
        } else if(selectedReviewer === '~none;'){
            return !sentBy || !operators.map(operator => operator._id).includes(sentBy);
        } else {
            return sentBy === selectedReviewer;
        }
    };

    const filterClient = (clientId) => {
        if(selectedClient === 'all'){
            return true;
        } else {
            return clientId === selectedClient;
        }
    };

    const filterProject = (projectId) => {
        if(selectedProject === 'all'){
            return true;
        } else if(selectedProject === 'none'){
            return projectId === '';
        } else {
            return projectId === selectedProject;
        }
    };

    const filterItems = (item) => {
        const filterText = () => {
            if(filters.text){
                const textFields = `${item.name}`;
                return textFields.toUpperCase().indexOf(filters.text.toUpperCase()) !== -1;
            }
            return true;
        };                          

        return !item.deleted && (!item.sent || item.flag === 2 || item.flag === 1) && filterText() && filterUser(item.sentBy) && filterClient(item.client) && filterProject(item.project);
    };
    
    useEffect(() => {
        if(pendingDocuments){
            const currentNumberOfVisibleDocuments = pendingDocuments.filter(filterItems);
            setNumberOfVisibleDocuments(currentNumberOfVisibleDocuments.length);
        }
    }, [pendingDocuments, filters]);
    
    useEffect(() => {
        socket.emit('GET SELECTED PENDING DOCUMENTS', { clientId: activeClient._id });
    }, []);
    
    const updateSelectedPendingDocumentsEventName = 'update selected pending documents';

    useEffect(() => {
        function onGotSelectedPendingDocuments(data){
            const { updatedSelectedDocuments } = data;
            setSelectedDocuments(updatedSelectedDocuments);
        }

        socket.on(updateSelectedPendingDocumentsEventName, onGotSelectedPendingDocuments);
        return () => {
            socket.off(updateSelectedPendingDocumentsEventName, onGotSelectedPendingDocuments);
            socket.emit('remove selected pending documents user', { clientId: activeClient._id });
            setSelectedDocuments([]);
        };
    }, []);

    const handle_clickedProject = (clientId, projectId) => {
        handleNavigate(`/${activeClient.shortName}/juridico/documentos/pesquisar?c=${clientId}&p=${projectId}`);
    };
    
    const handleMenuOpen = (e, clickedDocument) => {
        setReviewerMenuAnchorEl(e.target, clickedDocument);
    };
    
    const handle_actionMenuOpen = (e, clickedDocument) => {
        setActionMenuAnchorEl(e.target, clickedDocument);
    };
    
    const handle_updateDocSentButtonClick = (clickedDocument) => {
        showSubmitDocumentView(clickedDocument);
    };
    
    const getCurrentDocumentLastDate = useCallback((currentDocument) => {
        let currentDocumentLastDate = currentDocument.timestamp;
        if(currentDocument.sent && !!currentDocument.flag) currentDocumentLastDate = currentDocument.flaggedAt;
        return currentDocumentLastDate;
    }, []);

    const handleDocumentHistoryButtonPress = (event, clickedDocument) => {
        showDocumentHistoryView({
            left: event.clientX - 2,
            top: event.clientY - 4,
        }, clickedDocument, true, true, clickedDocument.project);
    };

    const handleCancelScheduledSendButtonPress = (clickedDocument) => {
        showCancelScheduledSendDocumentView(clickedDocument);
    };

    const updatePendingDocument = (data) => {
        dispatchDocuments({type: 'UPDATE PENDING DOCUMENT', payload: data});
    };

    const handleDocumentClick = (selectedDocument) => {
        socket.emit(updateSelectedPendingDocumentsEventName, {
            clientId: activeClient._id,
            userId: activeUser._id,
            userImg: activeUser.img,
            userName: activeUser.screenName,
            selectedDocumentId: selectedDocument._id
        });
    };

    const memoizedGetDocumentLastCommentIfHuman = useCallback((data) => getDocumentLastCommentIfHuman(data), [getDocumentLastCommentIfHuman]);
    
    if(!!pendingDocuments){
        return (
            <Collapse in={!loadingPendingDocuments} timeout={1000}>
                <List dense>
                    {
                        pendingDocuments
                        .filter(filterItems) 
                        .sort((a, b) => {
                            if(a.flag === 1 && b.flag !== 1) return 1;
                            if(a.flag !== 1 && b.flag === 1) return -1;
                            let aDate = getCurrentDocumentLastDate(a);
                            let bDate = getCurrentDocumentLastDate(b);
                            return (aDate > bDate) ? 1 : ((bDate > aDate) ? -1 : 0);
                        })
                        .map((pendingDocument) => (
                            <PendingDocumentListItem key={pendingDocument._id}
                                {
                                    ...{
                                        getCurrentDocumentLastDate,
                                        getGroupNameById,
                                        getGroupsByFolderId,
                                        getUserNameById,
                                        handle_actionMenuOpen,
                                        handle_clickedProject,
                                        handle_updateDocSentButtonClick,
                                        handleCancelScheduledSendButtonPress,
                                        handleDocumentHistoryButtonPress,
                                        handleMenuOpen,
                                        pendingDocument,
                                        saving,
                                        updatePendingDocument
                                    }
                                }
                                getDocumentLastCommentIfHuman={memoizedGetDocumentLastCommentIfHuman}
                                handleDocumentClick={handleDocumentClick}
                                pendingDocumentId={pendingDocument._id}
                                pendingDocumentName={pendingDocument.name}
                                setSelectedDocument={setSelectedDocument}
                            />
                        ))
                    }
                </List>
            </Collapse>
        );
    }

    return null;
};

export default PendingDocumentsList;