import React from 'react';
import { Avatar, Grid, IconButton, ListItem, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useAppCtxAPI } from '../context/SystemContext';

const classes = {
    icon: {
        color: theme => theme.palette.primary.main,
        backgroundColor: '#f5f6fa'
    }
};

const SidenavMobileMenuButton = ({children, badge, icon, endIcon, label, path, didNavigateAction, willNavigateAction, onClick}) => {
    const { handleNavigate } = useAppCtxAPI();

    const handleClick = () => {
        if(onClick){
            onClick();
        } else {
            handleNavigate(path, didNavigateAction, willNavigateAction);    
        }
    };

    return (
        <ListItem button onClick={handleClick}>
            <Grid container spacing={1} alignItems="center">
                {
                    icon &&
                    <Grid item>
                        <Avatar sx={classes.icon}>{icon}</Avatar>
                    </Grid>
                }
                <Grid item xs container spacing={1} alignItems="center">
                    <Grid item>
                        {label}
                    </Grid>
                    {
                        badge &&
                        <Grid item>
                            <Typography variant="body2" color="secondary">{badge}</Typography>
                        </Grid>
                    }
                    {
                        children &&
                        <Grid item>
                            {children}
                        </Grid>
                    }
                </Grid>
                {
                    endIcon !== null &&
                    <Grid item>
                        <IconButton edge="end" size="large">{endIcon ? endIcon : <ChevronRightIcon />}</IconButton>
                    </Grid>
                }
            </Grid>
        </ListItem>
    );
};

export default SidenavMobileMenuButton;