import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete from '@mui/material/Autocomplete';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function MultipleAutocomplete({disabled, error, getOptionLabel, id, inputValue, label, onInputChange, onChange, options, placeholder, required, value}) {
  
    return (
      <Autocomplete multiple disableCloseOnSelect
        disabled={disabled}
        inputValue={inputValue}
        onInputChange={onInputChange}
        value={value}
        onChange={onChange}
        id={id}
        fullWidth
        options={options}
        getOptionLabel={getOptionLabel}
        renderInput={(params) => (
          <TextField {...params} label={label} placeholder={placeholder} required={required} error={error} variant="standard" margin="none" />
        )}
        renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
              />
              {getOptionLabel(option)}
            </li>
          )
        }
      />
    );
}

export default MultipleAutocomplete;