import ClearanceItemsListPage from '../../../components/clearance/ClearanceItemsListPage';

const Reports = ({approvalAuthorities, authorityRiskLevel, initialClearanceFolderInformation, initialSelectedTemplate, numberOfApprovalsRequired, selectedFolder, selectedFolderUsers}) => {
    
    return (
        <ClearanceItemsListPage
            approvalAuthorities={approvalAuthorities}
            authorityRiskLevel={authorityRiskLevel}
            hideReports={initialClearanceFolderInformation?.hideReports}
            initialClearanceFolderInformation={initialClearanceFolderInformation}
            initialSelectedTemplate={initialSelectedTemplate}
            numberOfApprovalsRequired={numberOfApprovalsRequired}
            restrictedUserFolder={selectedFolder}
            // projects={projects}
            // users={users}
        />
    );
};

export default Reports;