import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Select from '../../../components/Select';

const NewEffect = ({ handleChange, setDisabled, specialEffects }) => {
    const [value, setValue] = useState({ special: '' });

    useEffect(() => {
        handleChange(value);
        if(setDisabled){
            let isDisabled = false;
            if(!value.special) isDisabled = true;
            setDisabled(isDisabled);
        }
    }, [value]);

    if(specialEffects){
        return (
            <Box>
                <Box mb={1}>
                    <Typography variant="h6">Dados</Typography>
                </Box>
                <Select
                    options={specialEffects}
                    value={value.special || 'select'} onChange={(e) => setValue(prevState => ({...prevState, special: e.target.value}))}
                />
            </Box>
        );
    }
    return null;
};

export default NewEffect;