import { useEffect, useState } from 'react';

const useTalents = (selectedFolder) => {
    const [talentModuleActive, setTalentModuleActive] = useState(false);

    useEffect(() => {
        let currentTalentModuleActive = false;
        if(selectedFolder){
            currentTalentModuleActive = selectedFolder.modules?.includes('talents') || false;
        }
        setTalentModuleActive(currentTalentModuleActive);
    }, [selectedFolder]);

    return talentModuleActive;
};

export default useTalents;