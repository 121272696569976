import { createContext, useContext, useState } from 'react';

const FormEditorContext = createContext();

const FormEditorProvider = ({children}) => {
    const [clickedQuestion, set_clickedQuestion] = useState(null);
    const [newTitleDialogOpen, set_newTitleDialogOpen] = useState(false);
    const [updateTitleDialogOpen, set_updateTitleDialogOpen] = useState(false);
    const [updateDescriptionDialogOpen, set_updateDescriptionDialogOpen] = useState(false);
    const [deleteTitleDialogOpen, setDeleteTitleDialogOpen] = useState(false);
    const [assignFieldMenuOpen, setAssignFieldMenuOpen] = useState(false);
    const [assignFieldMenuAnchorEl, setAssignFieldMenuAnchorEl] = useState(false);
    const [orderingForm, set_orderingForm] = useState(true);
    const [corporationIdQuestions, setCorporationIdQuestions] = useState([]);
    
    return (
        <FormEditorContext.Provider
            value={{
                clickedQuestion, set_clickedQuestion,
                corporationIdQuestions, setCorporationIdQuestions,
                newTitleDialogOpen, set_newTitleDialogOpen, updateTitleDialogOpen, set_updateTitleDialogOpen, updateDescriptionDialogOpen, set_updateDescriptionDialogOpen,
                deleteTitleDialogOpen, setDeleteTitleDialogOpen, assignFieldMenuOpen, setAssignFieldMenuOpen, assignFieldMenuAnchorEl, setAssignFieldMenuAnchorEl,
                orderingForm, set_orderingForm
            }}
        >
            {children}
        </FormEditorContext.Provider>
    );
};

const useFormEditor = () => {
    const context = useContext(FormEditorContext);
    return context;
};

export {
    FormEditorProvider,
    useFormEditor
};