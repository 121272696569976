import React from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const SideBarSectionTitle = ({title, showSection, setShowSection}) => {
    const handleShowSection = () => {
        setShowSection(prevState => !prevState);
    };
    return (
        <Box
            sx={{
                marginLeft: '8px',
                marginTop: '8px',
                marginBottom: '16px',
            }}
        >
            <Grid container spacing={1} alignItems="center">
                {
                    setShowSection &&
                    <Grid item>
                        <IconButton onClick={handleShowSection} size="large">{showSection ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
                    </Grid>
                }
                <Grid item xs>
                    <Typography variant="h2">{title}</Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SideBarSectionTitle;